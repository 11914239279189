interface UserProfileInterface {
  userUuid: string | null;
  name: string | null;
  email: string | null;
}

export interface UserProfileState {
  isBusy: boolean;
  error: object | null;
  user: UserProfileInterface | null;
}

export const initialUserProfileState: UserProfileState = {
  isBusy: false,
  error: null,
  user: {
    userUuid: null,
    name: null,
    email: null
  }
};
