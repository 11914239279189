import React from 'react';
import checkOff from '../../../images/test/checkOff/check-off@3x.png';
import checkOn from '../../../images/test/checkOn/check-on@3x.png';
import { Logger } from '../../../logger';
import * as styles from './Checkbox.scss';

export interface CheckboxProps {
  id: number | string;
  row: object;
  selectType: string;
  checked: boolean;
  className: string;
  onClick: Function;
}

export const Checkbox = (props: CheckboxProps) => {
  const { id, row, checked, className, onClick } = props;
  return (
    <input
      type={'image'}
      onClick={e => {
        const { shiftKey } = e;
        e.stopPropagation();
        const keyOnly = id.toString().replace(/^select-/, '');
        onClick(keyOnly, shiftKey, row);
      }}
      src={checked ? checkOn : checkOff}
      onChange={() => {
        Logger.info('Change Clicked');
      }}
      className={className || styles.input}
    />
  );
};
