import exports from 'queries/exports/getAllExportsQuery.gql';
import logger from 'utils/logger';
import { ConferenceExportDeletion } from 'interfaces/ConferenceExport';
import { AllExports, Download, ExportType } from 'interfaces/Downloads';
import { getClient } from 'nucleus-apollo-client';
import { Global } from 'state/Global';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import exportActions from 'actions/exportActions';
import { currentUserSelectors, nucleusConstants, proxyActions } from '@lifesize/nucleus';
import { Logger } from 'logger';
import { DIRECTORY_EXPORT_TOPIC } from 'constants/wampConstants';
import { selectSelectedConferenceExports, selectSelectedDirectoryExports } from 'selectors/exportSelectors';
import { selectAccountPermissions, usePermissionsService } from 'selectors/permissionsSelector';
import { fullDownloadPermissions } from 'constants/exportConstants';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { PermissionTypes } from 'constants/permissionsConstants';

export const thunks = {
  handleExportDeletion: (deleteResult: ConferenceExportDeletion) => async (dispatch: Function, getState: () => Global) => {
    const selectionArray = [
      ...selectSelectedConferenceExports(getState()),
      ...selectSelectedDirectoryExports(getState()),
    ];
    const removedResults = _difference(selectionArray, _get(deleteResult, 'failedDeletions', []));
    return dispatch(exportActions.handleConferenceExportDeletion(removedResults));
  },
  getExports: (type?: ExportType) => async (dispatch: Function, getState: () => Global) => {
    try {
      const store = getState();
      const permissions = selectAccountPermissions(store);
      const downloadPermissions = usePermissionsService(store) ? {
        [ExportType.MEETING]: permissions[ROLE_PERMISSION.VIEW_MEETINGS],
        [ExportType.ROOM]: permissions[ROLE_PERMISSION.VIEW_ROOMS],
        [ExportType.GROUP]: permissions[ROLE_PERMISSION.VIEW_USERS],
        [ExportType.CONFERENCE]: permissions[ROLE_PERMISSION.VIEW_PAST_MEETINGS] || permissions[ROLE_PERMISSION.VIEW_MODERATOR_PAST_MEETINGS]
      } : fullDownloadPermissions;

      // Export type or all exportTypes are not allowed
      if ((type && !downloadPermissions[type]) || !permissions[PermissionTypes.ANY_DOWNLOADS]) {
        return dispatch(exportActions.setExportError('No exports permitted.'));
      }

      const mutationVariables: { type?: string } = {};

      const client = await getClient(dispatch);
      dispatch(exportActions.setExportsLoading());
      const mutationOptions = {
        mutation: exports,
        fetchPolicy: 'no-cache',
        variables: mutationVariables
      } as unknown as {
        mutation: string;
        fetchPolicy: string;
        variables: {}
      };
      const response: AllExports = await client.mutate(mutationOptions);
      const unmappedExports = response?.data?.getAllExports;
      const mappedExports = unmappedExports.map((download: Download) => ({...download, exportId: `${download.id},${download.type}`}));
      dispatch(exportActions.setExports(mappedExports));
    } catch (e) {
      logger.log(`Unable to get conference exports: ${e}`);
      return dispatch(exportActions.setExportError(e));
    }
  },
  subscribeToExports: () => {
    return async (dispatch: Function, getState: () => Global) => {
      const state = getState();
      const groupId = _get(currentUserSelectors.myInfo(state), 'groupId');
      if (!groupId) { return; }
      try {
        const topic = DIRECTORY_EXPORT_TOPIC;
        dispatch({ type: nucleusConstants.WAMP_ADD_CONFERENCE_TOPIC, payload: topic });
        dispatch(proxyActions.subscribe(topic));
      } catch (err) {
        Logger.info(`Unable to subscribe to export topic: ${JSON.stringify(err)}`);
      }
    };
  },
};

export default thunks;
