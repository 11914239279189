import { createSelector } from 'reselect';
import { Global } from 'state/Global';
/**
 * Selector to the search state
 */
const mergeStateSelector = (state: Global) => state.app?.mergeAccount;

const mergeRequestSelector = createSelector(
  mergeStateSelector,
  (state) => state?.request
);

const mergeRequestLoadingSelector = createSelector(
  mergeStateSelector,
  (state) => state?.loading
);

const mergeRequestErrorSelector = createSelector(
  mergeStateSelector,
  (state) => state?.error
);

export {
  mergeRequestSelector,
  mergeRequestLoadingSelector,
  mergeRequestErrorSelector
};
