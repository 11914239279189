import React from 'react';
import { useMutation } from 'react-apollo';
import convertToPaidMutation from 'queries/modals/convertToPaidMutation.gql';
import intl from 'react-intl-universal';
import { ConfirmModal } from 'components/Modals/ConfirmModal/ConfirmModal';
import { ApolloError } from 'apollo-client';

interface Props {
  closeModal: () => void;
  getDashLicenses: () => void;
  deviceUUID: string;
  serialNumber: string;
}

const getErrorMessage = (error: ApolloError) => {
  let errMessage;
  switch (error.message) {
    case 'GraphQL error: device already paired to PAID license':
      errMessage = intl.get('alreadyPaidLicense');
      break;
    case 'GraphQL error: no PAID licenses available to consume.':
      errMessage = intl.get('noPaidLicenseAvailable');
      break;
    case 'GraphQL error: no license pairing exists for device.':
      errMessage = intl.get('noLicensePairingForDevice');
      break;
    default:
      errMessage = intl.get('tryUpgradeAgain');
  }
  return errMessage;
};

const ConvertToPaidModal = (props: Props) => {
  const { getDashLicenses, closeModal, deviceUUID, serialNumber} = props;
  const [ convertTrialToPaid, { loading, error }] = useMutation(convertToPaidMutation);

  return (
    <ConfirmModal
      confirmTitle={intl.get('confirmConvertToPaid')}
      confirmButtonText={intl.get('upgrade')}
      isBusy={loading}
      errorMessage={error && getErrorMessage(error)}
      closeModal={closeModal}
      confirmAction={async () => {
        try {
          await convertTrialToPaid({
            variables: {
              deviceUUID: deviceUUID,
              serialNumber: serialNumber
            }
          });
          getDashLicenses();
          closeModal();
        } catch (e) {
          // console.log('error', e);
        }
      }}
    />
  );
};

export default ConvertToPaidModal;
