import moment from 'moment';
import { getEndDate, getStartDate } from 'utils/date-time-utils';
import { OLD_DASHBOARD_END, OLD_DASHBOARD_START } from 'constants/dashboard';

export interface DashboardState {
  startDate: string;
  endDate: string;
  startDateMoment: moment.Moment;
  endDateMoment: moment.Moment;
}

// The starting point of date range picker
export const defaultDateRange = {
  startDate: getStartDate(moment(), -14),
  endDate: getEndDate(moment(), 0)
};

// The default "old" date ranges
export const defaultOldDateRange = {
  startDate: getStartDate(moment(OLD_DASHBOARD_START, 'MM-DD-YYYY')),
  endDate: getEndDate(moment(OLD_DASHBOARD_END, 'MM-DD-YYYY'))
};

export const initialDashboardState: DashboardState = {
  startDate: defaultOldDateRange.startDate.format('YYYY-MM-DD'),
  endDate: defaultOldDateRange.endDate.format('YYYY-MM-DD'),
  startDateMoment: defaultDateRange.startDate,
  endDateMoment: defaultDateRange.endDate,
};
