import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import ControllerTabComponent from 'components/RoomSystem/ControllerTab/ControllerTabComponent';
import {
  generateURLForPhoneHD,
  isCustomizable,
  isTabletDevice,
  isPhoneHD,
  canConnectDevice,
  isConnectSupported,
  isConnectEnabled,
  showAppsButton
} from 'utils/roomSystemUtils';
import { actions as ModalActions } from 'actions/modalActions';
import { ModalTypes } from 'constants/modalConstants';
import { RoomSystem, LinkedDevice } from 'typings/types';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import { Global } from 'state/Global';
import { Logger } from 'logger';
import { DeviceRegistration } from 'constants/alexaIntegrationConstants';
import { selectAlexaDevices } from 'selectors/alexaIntegrationSelector';
import alexaIntegrationThunks from 'thunks/alexaIntegrationThunks';
import { setDeviceCustomizationUrl } from 'thunks/deviceCustomizationThunks';

interface Props {
  roomSystem: RoomSystem;
}

interface StateProps {
  tablet?: LinkedDevice;
  alexaIntegration: boolean;
  lifesizeConnect: boolean;
  thirdPartyApps: boolean;
}

interface DispatchProps {
  openHdPhoneCustomization: (alexaIntegration: boolean, lifesizeConnect: boolean, thirdPartyApps: boolean) => void;
}

const getTablet = _memoize((linkedDevices: LinkedDevice[]) => {
  return linkedDevices.find((device: LinkedDevice) => isTabletDevice(device.type || ''));
});

const mapStateToProps = (state: Global, ownProps: Props) => {
  const { roomSystem } = ownProps;
  const linkedDevices = _get(ownProps, 'roomSystem.linkedDevices.records', []);
  const tablet = getTablet(linkedDevices);
  const hasConnectedTablet = !!_get(tablet, 'UUID', null);
  const canDisconnectTablet = (_get(tablet, 'UUID') && _get(ownProps, 'roomSystem.serialNumber'));
  if (hasConnectedTablet && !canDisconnectTablet) {
    Logger.info('ERROR: Room system has connected tablet but CAN NOT disconnect due to missing properties.');
  }
  const alexaIntegrationDeviceState = selectAlexaDevices(state)[_get(ownProps, 'roomSystem.serialNumber')];
  const alexaIntegration = _get(alexaIntegrationDeviceState, 'state') === DeviceRegistration.CONNECTED;
  const supportsAlexaIntegration = canConnectDevice(roomSystem);

  return {
    tablet,
    hasConnectedTablet,
    canConnectTablet: canConnectDevice(roomSystem),
    canDisconnectTablet,
    isCustomizable: isCustomizable(roomSystem) && isPhoneHD(roomSystem?.phoneModelNumber),
    lifesizeConnect: isConnectSupported(roomSystem) && isConnectEnabled(roomSystem),
    thirdPartyApps: showAppsButton(roomSystem),
    alexaIntegration,
    hasAlexaIntegration: supportsAlexaIntegration
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: Props) => ({
  openConnectTablet: () => {
    dispatch(ModalActions.openModal({ modalType: ModalTypes.CONNECT_TABLET, payload: ownProps.roomSystem }));
  },
  getDevice: async () => dispatch(alexaIntegrationThunks.getDeviceRegistration(_get(ownProps, 'roomSystem.serialNumber'))),
  openHdPhoneCustomization: (alexaIntegration: boolean, lifesizeConnect: boolean, thirdPartyApps: boolean) => {
    const deviceName = _get(ownProps, 'roomSystem.displayName', intl.get('lifeSizePhoneHd'));

    const features = {
      thirdPartyButtons: true,
      alexaIntegration,
      lifesizeConnect,
      thirdPartyApps
    };

    const url = generateURLForPhoneHD([_get(ownProps, 'roomSystem.dialstring')], deviceName, features);
    if (url.length) {
      dispatch(setDeviceCustomizationUrl(url));
      dispatch(ModalActions.openModal({ modalType: ModalTypes.DEVICE_CUSTOMIZATION }));
    }
  }
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: Props) => (
  {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    openHdPhoneCustomization: () =>
      dispatchProps.openHdPhoneCustomization(stateProps?.alexaIntegration, stateProps?.lifesizeConnect, stateProps?.thirdPartyApps)
  }
);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ControllerTabComponent);
