export const CALL_HISTORY_URL = '/drilldown/callhistory2';
export const ROOM_TOTALS_URL = '/drilldown/usersVideos/rooms2';
export const USER_TOTALS_URL = '/drilldown/usersVideos/users2';
export const LIVE_STREAM_URL = '/drilldown/livestream';

export const NEW_DASHBOARD_ROUTE = '/dashboard/new';
export const NEW_DASHBOARD = `${NEW_DASHBOARD_ROUTE}/:tab(pastMeetings|liveMeetings)?`;
export const OLD_DASHBOARD = '/dashboard/old';

export default {
  CALL_HISTORY_URL,
  ROOM_TOTALS_URL,
  USER_TOTALS_URL,
  LIVE_STREAM_URL
};

export const OLD_DASHBOARD_START = '05-17-2019';
export const OLD_DASHBOARD_END = '05-31-2019';
