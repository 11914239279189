import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { DWHLiveStreamViewerType } from 'interfaces/Dashboard';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import _get from 'lodash/get';
import { RowInfo } from 'react-table';
import { ExportName } from 'actions/exportToCsvActions';
import LiveStreamsToolbar from 'containers/Meetings/LiveStreamsTab/LiveStreamsToolbarContainer';
import styles from './LiveStreamDetails.scss';

interface Props {
  data: DWHLiveStreamViewerType[];
  error: string | null;
  fetchData: Function;
  isFetching: boolean;
}

const LiveStreamDetailsViewers = (props: Props) => {
  const { data, error, isFetching, fetchData } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData().then(() => {
      setLoading(false);
    });
  },        []);

  const isLoading = isFetching || loading;

  const columnConfig = [
    {
      Header: intl.get('name'),
      accessor: 'viewerName',
    }, {
      Header: intl.get('tableHeaderRole'),
      accessor: 'role',
    },
    {
      Header: intl.get('joinTime'),
      accessor: 'joinTimeStamp',
      Cell: (row: RowInfo) => {
        const { joinTime } = row.original;
        return joinTime;
      }
    },
    {
      Header: intl.get('leaveTime'),
      accessor: 'leaveTimeStamp',
      Cell: (row: RowInfo) => {
        const { leaveTime } = row.original;
        return leaveTime;
      },
    }
  ];

  return (
    <ListViewLayout
      hideHeader={true}
      error={_get(error, 'message', undefined)}
    >
      <div className={styles.infiniteContainer}>
        <div className="toolbar" key="toolbar" data-test="toolbar">
          <LiveStreamsToolbar
            activeTab={ExportName.LIVE_STREAM_VIEWERS}
            data={data}
          />
        </div>
        {isLoading ? <LoadingIndicator/> : <InfiniteTable
          columnConfig={columnConfig}
          sortable={false}
          data={data}
          defaultSort={[
            {
              id: 'joinTime',
              desc: true
            }
          ]}
          getTdProps={() => ({
            style: {
              alignItems: 'flex-start',
              paddingTop: '16px',
              paddingBottom: '16px',
              lineHeight: '12px'
            }
          })}
          getTheadTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          getTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          keyField={'viewerName'}
          hasMoreRecords={false}
          rowHeightOverride={44}
        />}
      </div>
    </ListViewLayout>
  );
};

export default LiveStreamDetailsViewers;
