import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _memoize from 'lodash/memoize';
import _toUpper from 'lodash/toUpper';
import intl from 'react-intl-universal';
import compareVersions from 'compare-versions';
import {
  GICON_DEVICES,
  GICON_SUPPORTED_REBOOT_VERSION,
  GEMS_DEVICES,
  GEMS_SUPPORTED_REBOOT_VERSION,
  CUSTOMIZABLE_ICON_TYPES,
  DELETED,
  ICON_DESCRIPTION,
  ICON_TYPES,
  LS_220,
  MUSTANGHD,
  PairingStatus,
  RoomSystemUsageType,
  SUPPORTED_PHONES,
  UNAVAILABLE,
  UNKNOWN,
  GEMS_SUPPORTED_RESET_VERSION,
  ICON_SUPPORTED_RESET_PASSWORD_VERSION,
  GEMS_SUPPORTED_UPLOAD_VERSION,
  GICON_SUPPORTED_UPLOAD_VERSION,
  LIFESIZE_CONNECT_MIN_VERSION,
  ConnectionType
} from 'constants/roomSystemConstants';
import { DeleteRoomSystemsMutation, LinkedDeviceCollection, Maybe, RoomSystem } from 'typings/types';
import { nucleusConstants } from '@lifesize/nucleus';
import { remotingConstants } from '@lifesize/clients.remoting';
import Records = DeleteRoomSystemsMutation.Records;
import { DeviceSubscriptionState } from 'constants/deviceSubscriptionConstants';

const DUPLICATE_SYSTEM_NAME_ERROR = 'DUPLICATE_SYSTEM_NAME_ERROR';
const OTHER_SYSTEM_NAME_ERROR = 'OTHER_SYSTEM_NAME_ERROR';

const hasIpAddress = _memoize((remoteIP: Maybe<string>) => {
  return !!remoteIP;
});

/**
 * If the actual word, 'None' comes back for the
 * remoteIP value in data request,
 * treat is as an invalid IP address.
 * Used to determine if user can click on link to IP address
 * @param remoteIP
 */
const isValidIp = _memoize((remoteIP: Maybe<string>) => {
  // @ts-ignore
  return hasIpAddress(remoteIP) && _toUpper(remoteIP) !== 'NONE';
});

const isOfflineStatus = _memoize((availabilityStatus: Maybe<string>) => {
  if (availabilityStatus) {
    const offlineStatuses = [
      DELETED,
      UNKNOWN,
      UNAVAILABLE,
    ];
    return _includes(offlineStatuses, _toUpper(availabilityStatus));
  }
  return true;
});

const isRoomSystemOffline = (roomSystem: RoomSystem) => {
  if (roomSystem && roomSystem.availability && roomSystem.availability.status) {
    return isOfflineStatus(roomSystem.availability.status);
  }
  return true;
};

/**
 * Returns true if one (or all) selected items have a PairingStatus 'complete' or 'default'
 * Returns false if multiple selections, and not all match this criteria
 * @param selectionArray - The collection of current user selected items
 * @param roomSystems - The room systems table data provider
 */
const canUnpair = (selectionArray: string[], roomSystems: RoomSystem[]) => {
  if (!selectionArray.length) {
    return false;
  }
  const selectedRoomSystems = _filter(roomSystems, (roomSystem: RoomSystem) => {
    return (roomSystem.serialNumber && selectionArray.indexOf(roomSystem.serialNumber) !== -1) && isPaired(roomSystem.pairingStatus);
  });

  return !!selectedRoomSystems.length && (selectedRoomSystems.length === selectionArray.length);
};

/**
 * Returns true if one (or all) selected items have a PairingStatus is 'deleted'
 * Returns false if multiple selections, and not all match this criteria
 * @param selectionArray - The collection of current user selected items
 * @param roomSystems - The room systems table data provider
 */
const canDelete = (selectionArray: string[], roomSystems: RoomSystem[]) => {
  if (selectionArray.length === 0) {
    return false;
  }
  const selectedRoomSystems = _filter(roomSystems, (roomSystem: RoomSystem) => {
    return (roomSystem.serialNumber && selectionArray.indexOf(roomSystem.serialNumber) !== -1) &&
      (roomSystem.pairingStatus === PairingStatus.DELETED);
  });

  return !!selectedRoomSystems.length && (selectedRoomSystems.length === selectionArray.length);
};

const isCustomizable = (roomSystem: RoomSystem) => {
  // Customizable icons are 'COMPLETE' (paired) or 'DEFAULT' (Paired from CSS)
  // Icons that are 'DELETED' (unpaired), cannot be customized
  return roomSystem.phoneModelNumber !== ''
    && roomSystem.serialNumber
    && CUSTOMIZABLE_ICON_TYPES.has(_toUpper(roomSystem.platform || ''))
    && (roomSystem.pairingStatus !== PairingStatus.DELETED);
};

/***
 * Only returns the items in the selection that can be customized.
 * @param selectionArray - The collection of current user selected items
 * @param roomSystems - The room systems table data provider
 */
const getCustomizableCollectionFromSelection
  = (selectionArray: string[], roomSystems: RoomSystem[]) => {
    if (selectionArray.length === 0) {
      return [];
    }

    return _filter(roomSystems, (roomSystem: RoomSystem) => {

      if (!roomSystem.platform) {
        return false;
      }
      if (selectionArray.indexOf(roomSystem.serialNumber) === -1) {
        return false;
      }

      return isCustomizable(roomSystem);
    });

  };

/**
 * If the selection array, and the collection of customizable items
 * are the same length, return true.
 * @param selectionArray
 * @param customizableCollection
 */
const areOnlyCustomizableItemsSelected
  = (selectionArray: string[], customizableCollection: RoomSystem[]) => {
    return (!!selectionArray.length && selectionArray.length === customizableCollection.length);
  };

/**
 * If all of the selected, and currently filtered items
 * are able to be customized, return true
 * @param selectionArray - The collection of current user selected items
 * @param visibleDirectoryList - The room systems table data provider
 */
const canCustomize = (selectionArray: string[], visibleDirectoryList: RoomSystem[]) => {
  if (visibleDirectoryList && !!visibleDirectoryList.length) {

    // Return true only if all of the items in the collection are customizable
    // @ts-ignore
    const customizableCollection: RoomSystem[]
      = getCustomizableCollectionFromSelection(selectionArray, visibleDirectoryList);
    return areOnlyCustomizableItemsSelected(selectionArray, customizableCollection);
  }
  return false;
};

const generateURLForPhoneHD =
  (dialStringArray: string[], systemName: string, features: object) => {
    const language = intl.getInitOptions().currentLocale || 'en';

    const showCal = 1;

    if (dialStringArray.length) {
      const url = new URL('https://phonehd.' + process.env.REACT_APP_DOMAIN);
      url.searchParams.append('language', language);
      url.searchParams.append('iconName', systemName);
      url.searchParams.append('iconIDs', dialStringArray.join(','));
      url.searchParams.append('showCal', showCal.toString());
      url.searchParams.append('r', Date.now().toString());
      url.searchParams.append('features', JSON.stringify(features));

      return url.toString();
    }

    return '';
  };

/**
 * Memoized text helper functions to help with table rendering of repetitive data
 */
const getSystemNameDisplayText = _memoize((name: Maybe<string>) => {
  if (!name) {
    return intl.get('name-not-avail');
  }
  return name;
});

const getOnlineStatusDisplayText = _memoize((regStatus: Maybe<string>) => {
  if (regStatus) {
    return isOfflineStatus(regStatus) ? intl.get('offline') : intl.get('online');
  }
  return intl.get('offline');
});

const getUsageDisplayText = (roomSystem: RoomSystem) => {
  let text = intl.get('unpaired');
  let { pairingStatus, usageType } = roomSystem;
  if (isPaired(_toUpper(String(pairingStatus)))) {
    switch (_toUpper(String(usageType))) {
      case RoomSystemUsageType.SHARED:
        text = intl.get('conferenceRoom');
        break;
      case RoomSystemUsageType.PERSONAL:
        text = intl.get('individual');
        break;
      default:
        // unsupported usage type
        text = '';
    }
  }
  return text;
};

const isPlatformIcon = _memoize((platform: Maybe<string>) => {
  if (platform && platform.length) {
    return ICON_TYPES.has(_toUpper(platform).trim());
  }
  return false;
});

const isValidFirmwareVersion = (firmware: Maybe<string>) => {
  return !!firmware && _toUpper(firmware) !== 'NONE';
};

const getFirmwareVersion = (data: RoomSystem) => {
  // firmware can have an optional prefix (Ex: LS_RM4_1.0.0 returns 1.0.0)
  return data ? (data.firmware || '').replace(/^LS_RM\d+_/, '') : '';
};

const isPlatformDash = _memoize((platform: Maybe<string>) => {
  return platform && platform.length ? _toUpper(platform) === RoomSystemUsageType.GRAPHITE : false;
});

const getVideoTypeDisplayText = _memoize((platform: Maybe<string>) => {
  let name = platform;
  if (!platform) {
    name = '';
  } else if (isPlatformIcon(platform)) {
    name = ICON_DESCRIPTION[platform.trim()];
  } else if (isPlatformDash(platform)) {
    name = 'Lifesize Dash';
  }
  return name;
});

const getControllerTypeCondensedText = (roomSystem: RoomSystem) => {
  const { phoneModelNumber, linkedDevices, pairingStatus } = roomSystem;
  if (pairingStatus === PairingStatus.DELETED) {
    return '';
  }
  const phoneConnected = isPhoneConnected(phoneModelNumber);
  const tabletConnected = isTabletLinked(linkedDevices);
  let name = [];
  if (phoneConnected && (_toUpper(String(phoneModelNumber)) === MUSTANGHD)) {
    name.push(intl.get('lifeSizePhoneHd'));
  }
  if (tabletConnected) {
    name.push(intl.get('tabletApp'));
  }
  return name.join(' - ');
};

const isControllerConnected = (phoneModelNumber: Maybe<string>, linkedDevices: Maybe<LinkedDeviceCollection>) => {
  return (isTabletLinked(linkedDevices) || isPhoneConnected(phoneModelNumber));
};

const isTabletLinked = (linkedDevices: Maybe<LinkedDeviceCollection>) => {
  const records = _get(linkedDevices, 'records', []);
  return !!records.find((record: Records) => {
    const type = _get(record, 'type');
    if (!type) { return false; }
    return type === remotingConstants.pairing.linkedDeviceTypes.gemsTablet || type === remotingConstants.pairing.linkedDeviceTypes.tablet;
  });
};

const isPhoneConnected = (phoneModelNumber: Maybe<string>) => {
  return SUPPORTED_PHONES.has(_toUpper(String(phoneModelNumber)));
};

const isPaired = (pairingStatus: Maybe<string>) => {
  // If pairing done with CSS, device may be online and paired even if pairing status is default
  return (pairingStatus === PairingStatus.COMPLETE || pairingStatus === PairingStatus.DEFAULT);
};

const isPhoneHD = (phoneModelNumber: Maybe<string>) => {
  return phoneModelNumber && _toUpper(phoneModelNumber) === MUSTANGHD;
};

/**
 * Returns false if device is dash or LS_220 (or not specified, empty string)
 * Memoized, since it's being hammered when loading a large Room System list
 */
const hasCalendarCapability = _memoize((platform: Maybe<string>) => {
  if (isPlatformDash(platform)) {
    return true;
  }
  return platform && platform.length && platform !== LS_220;
},                                     (...args) => {
  return JSON.stringify(args);
});

const getIPAddress = (data: RoomSystem): string => {
  if (data) {
    return data.remoteIP ? data.remoteIP : '';
  }
  return '';
};

const canConnectDevice = (roomSystem: RoomSystem) => {
  const allowedPlatforms = new Set([
    nucleusConstants.PLATFORM_ICON_900,
    nucleusConstants.PLATFORM_ICON_700,
    nucleusConstants.PLATFORM_ICON_500,
    nucleusConstants.PLATFORM_ICON_300
  ]);
  return (isPaired(roomSystem.pairingStatus) && allowedPlatforms.has(roomSystem.platform || ''));
};

const isTabletDevice = (type: string) => [
  remotingConstants.pairing.linkedDeviceTypes.tablet,
  remotingConstants.pairing.linkedDeviceTypes.gemsTablet
].includes(type);

const isPlatformGems = (platform: Maybe<string>) => GEMS_DEVICES.includes(platform || '');

const isPlatformGIcon = (platform: Maybe<string>) => GICON_DEVICES.includes(platform || '');

const getUpgradeManagementPlatform = (platform: Maybe<string>) => {
  return isPlatformGems(platform) ? 'gems' : 'icon';
};

function isRebootSupported(roomSystem: RoomSystem) {
  const { platform, firmware } = roomSystem;
  let rebootSupported: Boolean = false;
  if (platform && firmware && isValidFirmwareVersion(firmware)) {
    if (isPlatformGIcon(platform)) {
      const isValid = compareVersions.validate(firmware);
      if (isValid) {
        rebootSupported = compareVersions.compare(firmware, GICON_SUPPORTED_REBOOT_VERSION, '>');
      }
    } else if (isPlatformGems(platform)) {
      const firmwareArray = firmware.split('_');
      const incomingVersion = _get(firmwareArray, '2', '');
      const isValid = compareVersions.validate(incomingVersion);
      if (isValid) {
        rebootSupported = compareVersions.compare(incomingVersion, GEMS_SUPPORTED_REBOOT_VERSION, '>');
      }
    }
  }
  return rebootSupported;
}

function isUploadSupported(roomSystem: RoomSystem) {
  const { platform, firmware } = roomSystem;
  let uploadSupported: Boolean = false;
  if (platform && firmware && isValidFirmwareVersion(firmware)) {
    if (isPlatformGIcon(platform)) {
      const isValid = compareVersions.validate(firmware);
      if (isValid) {
        uploadSupported = compareVersions.compare(firmware, GICON_SUPPORTED_UPLOAD_VERSION, '>');
      }
    } else if (isPlatformGems(platform)) {
      const firmwareVersion = getFirmwareVersion(roomSystem);
      const isValid = compareVersions.validate(firmwareVersion);
      if (isValid) {
        uploadSupported = compareVersions.compare(firmwareVersion, GEMS_SUPPORTED_UPLOAD_VERSION, '>');
      }
    }
  }
  return uploadSupported;
}

function isResetPasswordSupported(roomSystem: RoomSystem) {
  const { platform, serialNumber } = roomSystem;
  if (!serialNumber) {
    return false;
  }
  const firmwareVersion = getFirmwareVersion(roomSystem);
  if (!compareVersions.validate(firmwareVersion)) {
    return false;
  }
  const gemsDevice = isPlatformGems(platform);
  const iconDevice = isPlatformIcon(platform);

  return isPaired(roomSystem.pairingStatus) && (gemsDevice || iconDevice ?
    compareVersions.compare(firmwareVersion, gemsDevice ? GEMS_SUPPORTED_RESET_VERSION : ICON_SUPPORTED_RESET_PASSWORD_VERSION, '>=')
    : false);
}

function isResetSupported(roomSystem: RoomSystem) {
  const { platform } = roomSystem;
  return isPlatformGIcon(platform) || isPlatformGems(platform);
}

function isConnectSupported(roomSystem: RoomSystem) {
  const { platform } = roomSystem;
  const firmwareVersion = getFirmwareVersion(roomSystem);
  if (compareVersions.validate(firmwareVersion)) {
    return isPlatformGems(platform) && compareVersions(firmwareVersion, LIFESIZE_CONNECT_MIN_VERSION) >= 0;
  }
  return false;
}

function isConnectEnabled(roomSystem: RoomSystem) {
  const connectInfo = roomSystem?.connectInfo;
  return !!connectInfo?.connectType && connectInfo?.connectType !== ConnectionType.NONE && connectInfo?.connectType !== ConnectionType.MARS_HARDWARE && connectInfo?.connectType !== ConnectionType.MARS_SOFTWARE;
}

function isMarsEnabled(roomSystem: RoomSystem) {
  const connectInfo = roomSystem?.connectInfo;
  return !!connectInfo?.connectType && connectInfo?.connectType !== ConnectionType.NONE && connectInfo?.connectType !== ConnectionType.HARDWARE && connectInfo?.connectType !== ConnectionType.SOFTWARE;
}

function showAppsButton(roomSystem: RoomSystem) {
  const connectInfo = roomSystem?.connectInfo;
  return connectInfo?.tpAppsEnabled && connectInfo?.chromeInstalled;
}

function channelToDisplayText(channel: string) {
  return intl.get(`upgradeManagementChannel${channel}`);
}

const mapData = _memoize((dataRow: RoomSystem) =>
  ({
  ...dataRow,
  usageText: getUsageDisplayText(dataRow),
  controllerText: getControllerTypeCondensedText(dataRow),
  videoType: dataRow.pairingStatus !== PairingStatus.DELETED ? getVideoTypeDisplayText(dataRow.platform) : undefined,
  extension: dataRow.extension === 'undefined' ? undefined : dataRow.extension,
  dialstring: dataRow.dialstring === 'undefined' ? undefined : dataRow.dialstring
  })
);

const transformRoomSystemData = (listData: RoomSystem | RoomSystem[]): RoomSystem | RoomSystem[] => {
  if (Array.isArray(listData)) {
    return listData.map(mapData);
  } else {
    return mapData(listData);
  }
};

const roomSystemSupportsDSS = (roomSystem: RoomSystem) => {
  const { platform, pairingStatus } = roomSystem;
  const platformSupportsDSS = isPlatformGems(platform) || isPlatformGIcon(platform);
  const paired = isPaired(_toUpper(String(pairingStatus)));
  return platformSupportsDSS && paired;
};

const hasKnownDeviceSubscription = (licenseStatus?: DeviceSubscriptionState) => {
  return !!licenseStatus && [DeviceSubscriptionState.CURRENT, DeviceSubscriptionState.EXPIRED].includes(licenseStatus);
};

export {
  showAppsButton,
  isConnectSupported,
  isConnectEnabled,
  isMarsEnabled,
  isRebootSupported,
  isUploadSupported,
  isResetSupported,
  isCustomizable,
  isTabletDevice,
  isPhoneHD,
  canUnpair,
  canCustomize,
  canDelete,
  areOnlyCustomizableItemsSelected,
  generateURLForPhoneHD,
  getSystemNameDisplayText,
  getOnlineStatusDisplayText,
  getUsageDisplayText,
  isPlatformIcon,
  isValidFirmwareVersion,
  getFirmwareVersion,
  isPlatformDash,
  isPlatformGems,
  isPlatformGIcon,
  getUpgradeManagementPlatform,
  getVideoTypeDisplayText,
  getControllerTypeCondensedText,
  isControllerConnected,
  isTabletLinked,
  isPaired,
  hasCalendarCapability,
  getIPAddress,
  isValidIp,
  isOfflineStatus,
  isRoomSystemOffline,
  canConnectDevice,
  isResetPasswordSupported,
  DUPLICATE_SYSTEM_NAME_ERROR,
  OTHER_SYSTEM_NAME_ERROR,
  channelToDisplayText,
  transformRoomSystemData,
  roomSystemSupportsDSS,
  hasKnownDeviceSubscription
};
