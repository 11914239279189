import {
  recordingActions as nucleusRecordingActions,
  recordingsSelectors
} from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from 'actions/modalActions';
import ShareRecording from 'components/Modals/MeetingShareRecordingModal/MeetingShareRecording';
import { MeetingShareProperties } from 'interfaces/VideoShareProperties';
import logger from 'utils/logger';

const mapStateToProps = (state: Global) => {
  const meetingRecordingShareProperties = recordingsSelectors.meetingShareProperties(state);
  return {
    meetingRecordingShareProperties
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  refreshMeetingUrls: async (id: string) => {
    try {
      await dispatch(nucleusRecordingActions.setMeetingShareProperties(id, { 'refreshurls': [] }));
      await dispatch(nucleusRecordingActions.getMeetingShareProperties(id));
    } catch (e) {
      logger.info(`Unable to refresh meeting urls: ${JSON.stringify(e)}`);
    }
  },
  setMeetingShareProperties: async (id: string, props: MeetingShareProperties) => {
    try {
      await dispatch(nucleusRecordingActions.setMeetingShareProperties(id, props));
      await dispatch(nucleusRecordingActions.getMeetingShareProperties(id));
    } catch (e) {
      logger.info(`Unable to set meeting share properties: ${JSON.stringify(e)}`);
    }
    dispatch(actions.closeModal());
  },
  getMeetingShareProperties: async (uuid: string) => {
    await dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getMeetingShareProperties(uuid)));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareRecording);
