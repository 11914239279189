import { createSelector } from 'reselect';
import { Global } from '../state/Global';

/**
 * Direct selector to the recordings state domain
 */
const selectSingleRecordingState = (state: Global) => state.app?.recordingsSingle || '';

export const selectRecordingId = createSelector(
  selectSingleRecordingState,
  (recording) => {
    return recording.recordingId;
  }
);
