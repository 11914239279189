import React from 'react';
import { Role } from 'interfaces/Role';
import listUsersQuery from 'queries/users/listUsersQuery.gql';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import intl from 'react-intl-universal';
import useListHooks from 'hooks/useListHooks';
import UsersHeader from 'components/Users/Users/UsersHeader';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import { hasMoreRecords } from 'utils/directoryUtils';
import { useMiddleware } from 'hooks/useMiddleware';
import { nucleusConstants } from '@lifesize/nucleus';
import { SortingRule } from 'react-table';
import { getSortingRule } from 'utils/listUtils';

export interface Props {
  role: Role;
}

const roleMembersColumnConfig = () =>
  [{
    Header: intl.get('name'),
    accessor: 'displayName',
    minWidth: 150
  },
  {
    Header: intl.get('tableHeaderEmail'),
    accessor: 'email',
    minWidth: 150
  },
  ];

export const RoleMembers = (props: Props) => {
  const {
    loading,
    data,
    error,
    refetch,
    filterString,
    setFilterString,
    setSort,
    fetchMoreRows
  } = useListHooks(listUsersQuery, {}, { role: props?.role?.id, includeRoles: false });

  useMiddleware(nucleusConstants.USER_SERVICE_DELETE_LIST_SUCCESS, () => refetch());
  useMiddleware(nucleusConstants.USER_SERVICE_DELETE_SUCCESS, () => refetch());

  const onFetchData: (args: { sorted: Array<SortingRule> }) => void = async ({
                                                                               sorted: [column],
                                                                             }) => {
    if (!data) {
      return;
    }

    const newSort = await getSortingRule(column);
    if (newSort) {
      setSort(newSort);
    }
  };

  return (
    <ListViewLayout
      hideHeader={true}
      error={error?.message}
      isFetching={false}
      numberOfPages={0}
    >
      <InfiniteTable
        columnConfig={roleMembersColumnConfig()}
        data={data?.directoryList?.records || []}
        defaultSort={[
          {
            id: 'displayName',
            desc: false
          },
        ]}
        enableSelect={true}
        fetchMore={fetchMoreRows}
        hasMoreRecords={hasMoreRecords(data)}
        keyField={'key'}
        loading={loading}
        onFetchData={onFetchData}
        renderHeader={() => (
          <UsersHeader
            onFilterStringChange={setFilterString}
            hideToolbar={true}
          />
        )}
        sortable={true}
        style={{ minHeight: 500 }}
        noDataText={filterString ? intl.get('noSearchRoleUsers') : intl.get('noAssignedRoleUsers')}
      />
    </ListViewLayout>
  );
};
