import { Reducer } from 'redux';
import { getRoles } from 'thunks/roleThunks';
import { initialRoleState, RoleState } from 'state/RoleState';

const roleReducer: Reducer<RoleState> =
  (state = initialRoleState, action) => {
    switch (action.type) {
      case getRoles.fulfilled.type:
        return {
          roles: action.payload,
          loading: false,
          error: false
        };
      case getRoles.pending.type:
        return {
          ...state,
          loading: true,
          error: false
        };
      case getRoles.rejected.type:
        return {
          ...state,
          loading: false,
          error: true
        };
      default:
        return state;
    }
  };

export default roleReducer;
