import React from 'react';
import intl from 'react-intl-universal';
import { LiveMeetingType } from 'interfaces/Dashboard';
import { formatUnixTimeStampForDisplay } from 'utils/date-time-utils';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import styles from './LiveMeetingsTab.scss';
import blurStyles from 'components/BlurredCell/BlurredCell.scss';
import Icon from 'components/Common/Icon';
import { RowInfo } from 'react-table';
import { RouteComponentProps } from 'react-router-dom';
import { SortDirection } from 'constants/queryConstants';
import { featureSelectors } from '@lifesize/nucleus';
import {
  LIVE_MEETING_ROUTE,
  LIVE_MEETING_REFRESH_TIME,
  LIVE_MEETING_PARTICIPANTS,
} from 'constants/liveMeetings';
import ClickableCell from 'components/ClickableCell/ClickableCell';
import listActiveMeetingsQuery from 'queries/meetings/listActiveMeetingsQuery.gql';
import _orderBy from 'lodash/orderBy';
import { selectFilterString } from 'selectors/filterSelectors';
import { selectSort } from 'selectors/sortSelectors';
import { selectUserActive } from 'selectors/userStateSelector';
import { useDispatch, useSelector } from 'react-redux';
import { SetSortInterface } from 'interfaces/Tables';
import actions from 'actions/sortActions';
import { accountSelectors } from '@lifesize/nucleus';
import { ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS } from 'constants/accountFeatureConstants';
import { selectEcommerceLoading, selectEcommerceSupported } from 'selectors/ecommerceSelector';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import EcommercePrompt from 'components/EcommercePrompt/EcommercePrompt';
import { PLAN_TYPE_HOST_PLUS } from 'constants/ecommerceConstants';
import { BlurModal } from 'components/BlurModal/BlurModal';
import classnames from 'classnames';
import { demoLiveStreamSelector } from 'selectors/liveStreamsSelector';
import blurredCell from 'components/BlurredCell/BlurredCell';
import { FEATURE_FLAG_WAITING_ROOM } from 'constants/featureFlags';

interface FetchArgs {
  sorted: Array<{ id: string, desc: string }>;
}

const LiveMeetingsTab = (props: RouteComponentProps) => {
  const search = useSelector(selectFilterString);
  const sort = useSelector(selectSort);
  const isActive = useSelector(selectUserActive);
  const dispatch = useDispatch();
  const setSort = (newSort: SetSortInterface) => {
    dispatch(actions.setSort(newSort));
  };

  const {
    history,
  } = props;

  const {loading, error, data} = useQuery(listActiveMeetingsQuery, {
    variables: {search},
    fetchPolicy: 'network-only',
    pollInterval: isActive ? LIVE_MEETING_REFRESH_TIME : 0
  });

  const accountFeatureLiveMeetings = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS);
  const isEcommerceSupported = useSelector(selectEcommerceSupported);
  const ecommerceAccountLoading = useSelector(selectEcommerceLoading);
  const blurDashboard = useSelector(demoLiveStreamSelector);
  const showWaitingRoom = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_WAITING_ROOM);

  if (ecommerceAccountLoading) {
    return (
      <>
        {blurDashboard && <BlurModal modalBody={intl.get('upgradeLiveMeetings')} plan={PLAN_TYPE_HOST_PLUS} />}
        <LoadingIndicator />;
      </>
    );
  }

  if (!accountFeatureLiveMeetings && isEcommerceSupported && !blurDashboard) {
    return (
      <EcommercePrompt
        buttonId="btnLiveMeetingsUpgrade"
        message={intl.get('liveMeetingsUpgradeMessage')}
        extraParams={{
          plan: PLAN_TYPE_HOST_PLUS
        }}
      />
    );
  }

  const {sortBy, order} = sort || {sortBy: 'startTime', order: 'desc'};
  const rawConferences = !loading ? _get(data, 'liveMeetings.conferences', []) : [];
  const formattedOrder = order === SortDirection.DESC ? 'desc' : 'asc';
  const conferences = _orderBy(
    rawConferences.map(
      (conferenceItem: LiveMeetingType) => ({
        ...conferenceItem,
        isRecording: conferenceItem.conferenceInfo.recording.isRecording,
        isPresenting: conferenceItem.conferenceInfo.presentation.isPresenting
      })
    ),
    // This function produces an array of orders to sort by: first by the user-chosen sort, then by the chosen order
    [sortBy], [formattedOrder]
  );

  const onFetchData = (args: FetchArgs) => {
    const column = args.sorted[0];
    if (!column || !data) {
      return;
    }
    setSort({
      sortBy: column.id,
      order: column.desc ? SortDirection.DESC : SortDirection.ASC,
    });
  };

  return (
    <>
      {blurDashboard && <BlurModal modalBody={intl.get('upgradeLiveMeetings')} plan={PLAN_TYPE_HOST_PLUS} />}
      <ListViewLayout
        hideHeader={true}
        isFetching={false}
        numberOfPages={0}
        error={_get(error, 'message', undefined)}
      >
        <InfiniteTable
          columnConfig={
            [{
              Header: blurredCell(intl.get('name'), blurDashboard),
              accessor: 'meetingName',
              Cell: (row: RowInfo) => {
                const {meetingName} = row.original;
                if (blurDashboard) {
                  return blurredCell(meetingName, blurDashboard);
                }
                return (
                  <ClickableCell
                    id={`liveMeeting-${meetingName}`}
                    title={meetingName}
                    isClickable={true}
                    displayText={meetingName}
                    clickHandler={() => {
                      history.push(`${LIVE_MEETING_ROUTE}/${row.original.conferenceId}/${LIVE_MEETING_PARTICIPANTS}`);
                    }}
                  />
                );
              }
            }, {
              Header: blurredCell(intl.get('extension'), blurDashboard),
              accessor: 'extension',
              className: blurDashboard ? blurStyles.blurred : undefined
            }, {
              Header: blurredCell(intl.get('startTime'), blurDashboard),
              accessor: 'startedAt',
              Cell: (row) => row.value ? formatUnixTimeStampForDisplay(row.value) : '',
              className: blurDashboard ? blurStyles.blurred : undefined
            }, {
              Header: blurredCell(intl.get('participants'), blurDashboard),
              accessor: 'activeParticipants',
              className: blurDashboard ? blurStyles.blurred : undefined
            }, {
              Header: showWaitingRoom ? blurredCell(intl.get('waitingRoom'), blurDashboard) : blurredCell(intl.get('locked'), blurDashboard),
              maxWidth: 100,
              id: showWaitingRoom ? 'waitingRoom' : 'locked',
              headerClassName: styles.narrowColumn,
              className: classnames(styles.narrowColumn, blurDashboard ? blurStyles.blurred : undefined),
              accessor: d => showWaitingRoom ? d?.value : d?.conferenceInfo?.locked,
              Cell: d => showWaitingRoom ? <>{d.original.numWaitingRoomUsers !== null ? d.original.numWaitingRoomUsers : '-'}</> : <>{!!d.value && <Icon classes={['icon-meeting-locked', styles.locked]}/>}</>
            }, {
              Header: blurredCell(intl.get('recording'), blurDashboard),
              maxWidth: 200,
              headerClassName: styles.narrowColumn,
              className: classnames(styles.narrowColumn, blurDashboard ? blurStyles.blurred : undefined),
              accessor: 'isRecording',
              Cell: (row) => <>{!!row.value && <Icon classes={['icon-record', styles.recording]}/>}</>
            }, {
              Header: blurredCell(intl.get('presenting'), blurDashboard),
              maxWidth: 200,
              headerClassName: styles.narrowColumn,
              className: classnames(styles.narrowColumn, blurDashboard ? blurStyles.blurred : undefined),
              accessor: 'isPresenting',
              Cell: (row) => <>{!!row.value && <Icon classes={['icon-share-screen', styles.presenting]}/>}</>
            }]
          }

          data={conferences}
          enableSelect={false}
          // @ts-ignore
          NoDataComponent={blurDashboard ? () => <div /> : undefined}
          noDataText={intl.get('noLiveMeetings')}
          defaultSort={[
            {
              id: 'startTime',
              desc: true
            }
          ]}
          getTdProps={() => ({
            style: {
              alignItems: 'flex-start',
              paddingTop: '16px',
              paddingBottom: '16px',
              lineHeight: '12px'
            }
          })}
          getTheadTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          getTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          keyField={'startTime'}
          loading={loading}
          sortable={true}
          renderHeader={() => (
            <div className={styles.spacer}/>
          )}
          rowHeightOverride={44}
          onFetchData={onFetchData}
        />
      </ListViewLayout>
    </>
  );
};

export default withRouter(LiveMeetingsTab);
