import { proxyActions } from '@lifesize/nucleus';
import { deviceManagementTopic } from 'constants/deviceManagementConstants';
import { servicesActions } from '@lifesize/nucleus';
import { getUrlParameter, redirectToLogin } from 'utils/url-utils';
import { fetchRecordingsAuth } from 'actions/recordingsAuthActions';
import { setImpersonationSessionActive } from './impersonationActions';
import { fetchSsoStatus } from './ssoActions';
import { remotingActions } from '@lifesize/clients.remoting';
import logger from 'utils/logger';
import exportThunks from 'thunks/exportThunks';

export const initializeClient = () => (dispatch: Function, getState: Function) => {
  dispatch(fetchSsoStatus());
  dispatch(remotingActions.pairing.subscribeToLinking());
  dispatch(proxyActions.subscribe(deviceManagementTopic));
  dispatch(exportThunks.subscribeToExports());
  dispatch(fetchRecordingsAuth());
};

const removeTokenFromUrl = () => {
  const currentUrl = new URL(document.location.href);
  currentUrl.searchParams.delete('a');
  currentUrl.searchParams.delete('jwt');
  window.history.replaceState({}, document.title, currentUrl.href);
};

export const login = () => async (dispatch: Function) => {
  // Dispatch login action
  const impersonationToken = getUrlParameter('impersonationToken'); // Obtain impersonation token from url parameter.
  const aParam = getUrlParameter('a'); // Obtain a cookie from url parameter
  let jwt: string | undefined = impersonationToken || aParam;
  if (!jwt || !jwt.length) { jwt = undefined; }
  try {
    await dispatch(servicesActions.connect(jwt));
    removeTokenFromUrl();
  } catch (e) {
    handleLoginError(e);
  }

  // If we used an impersonation token, need to dispatch an action to identify the session as impersonation
  if (impersonationToken) {
    dispatch(setImpersonationSessionActive());
  }
};

export const handleLoginError = (error: string) => {
  logger.info(error);
  redirectToLogin();
};
