import React, { useEffect, useState } from 'react';
import { PrivateRoutes } from 'components/Router/Router';
import { LIVE_MEETING_INACTIVITY_TIMEOUT } from 'constants/liveMeetings';
import IdleTimer from 'react-idle-timer';
import useEventListener from 'hooks/useEventListener';
import { selectAccountPermissions, selectDefaultRoute } from 'selectors/permissionsSelector';
import { useDispatch, useSelector } from 'react-redux';
import { featureSelectors, networkActions, servicesSelectors } from '@lifesize/nucleus';
import { actions as UserStateActions } from 'actions/userStateActions';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import { actions as CalendarActions } from 'actions/calendarSubscriptionActions';
import { LoadingAnimation } from '@lifesize/react-components/dist/LoadingAnimation';
import { handleLoginError, initializeClient, login } from 'actions/initClientActions';
import { selectIsImpersonating } from 'selectors/impersonationSelectors';
import { getRequest } from 'actions/mergeAccountActions';
import { demoDashboardSelector, hideDashboard, hideMeetings, hideRooms, hideUsers } from 'selectors/accountSelectors';
import recordingActions from 'actions/recordingsActions';
import { FEATURE_FLAG_REMOVE_OLD_DASHBOARD, FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { PermissionTypes } from 'constants/permissionsConstants';
import { ROLE_PERMISSION } from 'interfaces/Role';

const handleCalendarMessage = _memoize((event: Event, dispatch: Function) => {
  // there are a lot of 'messages' firing, so filter out the ones we don't want here
  const winId = _get(event, 'data.win_id');
  if (winId) {
    dispatch(CalendarActions.handleCalendarMessage(event));
  }
});

const App = () => {
  const dispatch = useDispatch();
  const setActive = (active: boolean) => {
    dispatch(UserStateActions.setActivity(active));
  };

  useEventListener('offline', () => dispatch(networkActions.offline()));
  useEventListener('online', () => dispatch(networkActions.online()));
  useEventListener('message', (event: Event) => handleCalendarMessage(event, dispatch));

  const isConnected = useSelector(servicesSelectors.selectConnected);
  const accountPermissions = useSelector(selectAccountPermissions);
  const isImpersonating = useSelector(selectIsImpersonating);
  const hideDashboardRoute = useSelector(hideDashboard) || !accountPermissions[PermissionTypes.ANY_DASHBOARD];
  const hideUsersRoute = useSelector(hideUsers) || !accountPermissions[ROLE_PERMISSION.VIEW_USERS];
  const hideMeetingsRoute = useSelector(hideMeetings) || !accountPermissions[ROLE_PERMISSION.VIEW_MEETINGS];
  const hideRoomsRoute = useSelector(hideRooms) || !accountPermissions[ROLE_PERMISSION.VIEW_ROOMS];
  const hideDownloadsRoute = !accountPermissions[PermissionTypes.ANY_DOWNLOADS];
  const showDemoDashboard = useSelector(demoDashboardSelector) && !!accountPermissions[PermissionTypes.ANY_DASHBOARD];
  const removeOldDashboard = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_REMOVE_OLD_DASHBOARD);
  const showRolesTab = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);

  const showRecordings = accountPermissions[PermissionTypes.ANY_RECORDINGS];
  const showSettings = accountPermissions[ROLE_PERMISSION.VIEW_OWN_ACCOUNT_GENERAL_SETTINGS];
  const showRoles = accountPermissions[ROLE_PERMISSION.VIEW_ROLES];

  const defaultRoute = useSelector(selectDefaultRoute);

  const [ isInitialized, setIsInitialized ] = useState(false);
  const [ showLoader, setShowLoader ] = useState(true);

  useEffect(
    () => {
      const handleLogin = async () => {
        try {
          await dispatch(login());
        } catch (error) {
          handleLoginError(error);
        }
      };
      handleLogin();
    },
    []
  );

  useEffect(
    () => {
      if (isConnected && isInitialized) {
        setShowLoader(false);
      }
    },
    [isConnected, isInitialized]
  );

  const getInitialData = async () => {
    await Promise.allSettled([
      dispatch(initializeClient()),
      dispatch(getRequest()),
      dispatch(recordingActions.getVcCookie(isImpersonating))
    ]);
    setIsInitialized(true);
  };
  useEffect(
    () => {
      if (isConnected) {
        // Do any initial data calls needed
        getInitialData();
      }
    },
    [isConnected]
  );

  if (showLoader) {
    return <LoadingAnimation />;
  }

  const routesConfig = {
    defaultRoute,
    hideDashboardRoute,
    hideUsersRoute,
    hideMeetingsRoute,
    hideRoomsRoute,
    hideDownloadsRoute,
    showDemoDashboard,
    showRolesTab,
    removeOldDashboard,
    showRecordings,
    showRoles,
    showSettings
  };

  return (
    <>
      {PrivateRoutes(routesConfig)}
      <IdleTimer
        element={document}
        onActive={() => setActive(true)}
        onIdle={() => setActive(false)}
        debounce={250}
        timeout={LIVE_MEETING_INACTIVITY_TIMEOUT}
      />
    </>
  );
};

export default App;
