import React from 'react';
import * as styles from './RoleManagement.scss';
import intl from 'react-intl-universal';
import {
  Permission,
  Role,
  ROLE_PERMISSION_TYPE,
} from 'interfaces/Role';
import { RoleDropdown } from './RoleDropdown';

export interface Props {
  role: Role;
  setting: Permission;
  disabled: boolean;
}

export const RoleRow = (props: Props) => {
  const {
    setting,
    role,
    disabled
  } = props;

  const { title, view, edit } = setting;
  const selectedView = view?.find(option => option.selected)?.label;
  const disableView = disabled;
  let disableEdit = selectedView === ROLE_PERMISSION_TYPE.NONE || disabled;

  return (
    <div className={styles.roleRow}>
      <div>{intl.get(title)}</div>
      <div>
        {view && <RoleDropdown
          role={role}
          options={view}
          disabled={disableView}
        />}
      </div>
      <div>
        {edit && <RoleDropdown
          role={role}
          options={edit}
          disabled={disableEdit}
        />}
      </div>
    </div>
  );
};
