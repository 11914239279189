import { SortingRule } from 'react-table';
import { SortDirection } from 'constants/queryConstants';
import { Sort } from 'interfaces/Meeting';

export const getSortingRule = async (column: SortingRule) => {
  if (!column) { return null; }
  const newSort: Sort = {
    propertyName: column.id,
    direction: column.desc ? SortDirection.DESC : SortDirection.ASC,
  };
  return newSort;
};
