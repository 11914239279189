import ExportButton from 'components/Common/ExportButton';
import ToolBarButton from 'components/Common/ToolbarButton';
import React from 'react';
import { DataValue } from 'react-apollo';
import intl from 'react-intl-universal';
import { RoomSystem } from 'typings/types';
import useExport from 'hooks/useExport';
import { ExportType } from 'interfaces/Downloads';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  data: DataValue<RoomSystem>;
  canDelete: boolean;
  canUnpair: boolean;
  canCustomize: boolean;
  hasSelected: boolean;
  confirmRemoveFromCloud: () => void;
  confirmDeleteFromCloud: () => void;
  openHdPhonePreferences: () => void;
  selectionArray: Array<string>;
}

const RoomSystemsToolBar = (
  {
    canDelete,
    canUnpair,
    canCustomize,
    hasSelected,
    confirmRemoveFromCloud,
    confirmDeleteFromCloud,
    openHdPhonePreferences,
  }: Props) => {

  const { handleExportData, loading } = useExport(ExportType.ROOM);
  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  return (
    <div className="toolbar" data-test="toolbar">
      <div>
        <ExportButton
          disabled={loading}
          isExporting={loading}
          onExportClick={handleExportData}
        />
        <ToolBarButton
          clickHandler={openHdPhonePreferences}
          disabledTooltipText={canEditRoomSystems ? intl.get('tooltipNoneSelectedRoomSystems') : intl.get('cannotModifyRooms')}
          isEnabled={canCustomize && canEditRoomSystems}
          label={intl.get('customizeSelectedPhones')}
          showWarningText={!canCustomize && hasSelected}
          warningTooltipText={intl.get('tooltipPhoneHD')}
        />
        <ToolBarButton
          clickHandler={confirmRemoveFromCloud}
          disabledTooltipText={canEditRoomSystems ? intl.get('tooltipNoneSelectedRoomSystems') : intl.get('cannotModifyRooms')}
          isEnabled={canUnpair && canEditRoomSystems}
          label={intl.get('removeFromCloud')}
          showWarningText={!canUnpair && hasSelected}
          warningTooltipText={intl.get('tooltipUnpair')}
        />
        <ToolBarButton
          clickHandler={confirmDeleteFromCloud}
          disabledTooltipText={canEditRoomSystems ? intl.get('tooltipNoneSelectedRoomSystems') : intl.get('cannotModifyRooms')}
          isEnabled={canDelete && canEditRoomSystems}
          label={intl.get('delete')}
          showWarningText={!canDelete && hasSelected}
          tooltipIsOnRight={true}
          warningTooltipText={intl.get('tooltipDelete')}
        />
      </div>
    </div>
  );
};

export default RoomSystemsToolBar;
