import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const userProfileState = (state: Global) => state.app?.userProfile;
const userProfileNucleus = (state: Global) => state.nucleus;

const selectUserProfile = createSelector(
  userProfileState,
  (state) => state?.user
);

const selectGroupUUID = createSelector(
  userProfileNucleus,
  (state) => state?.userProfile?.groupUuid
);

export {
  selectUserProfile, selectGroupUUID
};
