import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import Icon from 'components/Common/Icon';
import { RouteComponentProps } from 'react-router';
import classnames from 'classnames';
import _compact from 'lodash/compact';
import _isNumber from 'lodash/isNumber';
import _get from 'lodash/get';
import _invert from 'lodash/invert';
import { Tab, TabProps } from 'semantic-ui-react';
import DashboardTab from 'containers/DashboardNew/DashboardTab/DashboardTabContainer';
import PastMeetingsTab from 'containers/DashboardNew/PastMeetingsTab/PastMeetingsTabContainer';
import LiveMeetingsTab from 'components/DashboardNew/LiveMeetingsTab/LiveMeetingsTab';
import LiveMeetingsMenuItem from 'containers/DashboardNew/LiveMeetingsTab/LiveMeetingsMenuItemContainer';
import * as styles from './Dashboard.scss';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import { LIVE_MEETING_TAB } from 'constants/liveMeetings';
import { PAST_MEETING_TAB } from 'constants/pastMeetings';
import { NEW_DASHBOARD_ROUTE } from 'constants/dashboard';
import EcommerceActions from 'actions/ecommerceActions';
import { useDispatch, useSelector } from 'react-redux';
import { accountSelectors, featureSelectors } from '@lifesize/nucleus';
import {
  FEATURE_FLAG_ENABLE_PAST_MEETINGS,
  FEATURE_FLAG_ROLE_MANAGEMENT
} from 'constants/featureFlags';
import {
  ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS,
  ACCOUNT_FEATURE_PAST_MEETINGS_ENABLED
} from 'constants/accountFeatureConstants';
import { selectEcommerceSupported } from 'selectors/ecommerceSelector';
import { demoDashboardSelector } from 'selectors/accountSelectors';
import { demoLiveStreamSelector } from 'selectors/liveStreamsSelector';
import { demoPastMeetingsSelector } from 'selectors/pastMeetingSelectors';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const generateSupportedIndices = (canViewDashboard: boolean, canViewPastMeetings: boolean, canViewLiveMeetings: boolean) => {
  let tabIndex = -1;
  const supportedIndices = {};

  if (canViewDashboard) {
    supportedIndices[NEW_DASHBOARD_ROUTE] = tabIndex += 1;
  }

  if (canViewLiveMeetings) {
    supportedIndices[LIVE_MEETING_TAB] = tabIndex += 1;
  }

  if (canViewPastMeetings) {
    supportedIndices[PAST_MEETING_TAB] = tabIndex + 1;
  }

  return supportedIndices;
};

const getTitle = (blurDashboard: boolean) => {
  return (
    <>
      <Icon classes={['icon-home']} />
      <span>{intl.get('dashboard')}</span>
      {blurDashboard && <span className={styles.paidFeaturePill}>{intl.get('paidFeature')}</span>}
    </>
  );
};

const NewDashboard = (props: RouteComponentProps<void> ) => {
  const { match, history } = props;
  const featureFlagPastMeetings = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ENABLE_PAST_MEETINGS);
  const accountFeaturePastMeetings = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_PAST_MEETINGS_ENABLED);
  const accountFeatureLiveMeetings = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS);
  const ecommerceSupported = useSelector(selectEcommerceSupported);
  const blurDashboard = useSelector(demoDashboardSelector);
  const blurPastMeetings = useSelector(demoPastMeetingsSelector);
  const blurLiveMeetings = useSelector(demoLiveStreamSelector);
  const useRolePermissions = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const permissions = useSelector(selectAccountPermissions);
  const viewPastMeetingsPermission = useRolePermissions ? permissions[ROLE_PERMISSION.VIEW_PAST_MEETINGS] || permissions[ROLE_PERMISSION.VIEW_MODERATOR_PAST_MEETINGS] : true;
  const viewLiveMeetingsPermission = useRolePermissions ? permissions[ROLE_PERMISSION.VIEW_LIVE_MEETINGS] || permissions[ROLE_PERMISSION.VIEW_MODERATOR_LIVE_MEETINGS] : true;

  const canViewDashboard = useRolePermissions ? permissions[ROLE_PERMISSION.VIEW_DASHBOARD] : true;
  const canViewPastMeetings = featureFlagPastMeetings && viewPastMeetingsPermission && (accountFeaturePastMeetings || ecommerceSupported || blurPastMeetings);
  const canViewLiveMeetings = viewLiveMeetingsPermission && (accountFeatureLiveMeetings || ecommerceSupported || blurLiveMeetings);

  const supportedIndices = generateSupportedIndices(canViewDashboard, canViewPastMeetings, canViewLiveMeetings);
  const activeIndexToKey = _invert(supportedIndices);

  const [selectedTab, setSelectedTab] = React.useState(supportedIndices[_get(match, 'url')] || 0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(EcommerceActions.getEcommerceAccount({}));
  },        []);

  const panes = _compact([
    (canViewDashboard && {
      menuItem: {
        key: NEW_DASHBOARD_ROUTE,
        content: intl.get('dashboard')
      },
      render: () => {
        return (
          <Tab.Pane attached={false} id={'dashboardWidget'}>
            <DashboardTab
              {...props}
            />
          </Tab.Pane>
        );
      }
    }),
    (canViewLiveMeetings && {
      menuItem: {
        key: LIVE_MEETING_TAB,
        children: <LiveMeetingsMenuItem />
      },
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <LiveMeetingsTab />
          </Tab.Pane>
        );
      }
    }),
    (canViewPastMeetings && {
      menuItem: {
        key: PAST_MEETING_TAB,
        content: intl.get('pastMeetings'),
        'data-test': 'pastMeetings'
      },
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <PastMeetingsTab />
          </Tab.Pane>
        );
      }
    })
  ]);

  const handleTabChange = (event: React.MouseEvent<HTMLDivElement>, tabData: TabProps) => {
    const activeIndex = tabData.activeIndex || 0;
    const url = activeIndexToKey[activeIndex];
    history.push(url);
    if (_isNumber(tabData.activeIndex)) {
      setSelectedTab(tabData.activeIndex);
    }
  };

  return (
    <div className={styles.container}>
      <ListViewLayoutHeader title={getTitle(blurDashboard)} />
      <div className={classnames(styles.tabsContainer, 'tabset')}>
        <Tab
          activeIndex={selectedTab}
          menu={{secondary: true, pointing: true}}
          panes={panes}
          onTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default withRouter(NewDashboard);
