import React from 'react';
import intl from 'react-intl-universal';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import _get from 'lodash/get';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import styles from './MeetingParticipantSummary.scss';
import { MeetingParticipantDetails, MediaStat } from 'interfaces/MeetingParticipant';
import { ApolloError } from 'apollo-client';
import { MeetingParticipantProperties } from 'components/MeetingParticipant/MeetingParticipantProperties/MeetingParticipantProperties';

interface Props {
  mediaStats: Array<MediaStat>;
  error?: ApolloError;
  participantLeft?: boolean;
  meetingParticipant: MeetingParticipantDetails;
}

const MeetingParticipantSummary = (props: Props) => {
  const {
    meetingParticipant,
    mediaStats,
    participantLeft,
    error,
  } = props;

  return (
    <ListViewLayout
      isFetching={false}
      numberOfPages={0}
      error={_get(error, 'message', undefined)}
      headerComponent={
        <>
          {meetingParticipant && <MeetingParticipantProperties
            meetingParticipant={meetingParticipant}
          />}
        </>
      }
    >
      <div className={styles.participantSummary}>
        <div className={styles.summaryTitle}>
          {intl.get('summaryOfCallStats')}
        </div>
        <InfiniteTable
          columnConfig={
            [{
              Header: ' ',
              accessor: 'label',
            }, {
              Header: intl.get('bitrate'),
              accessor: 'bitrate',
            }, {
              Header: intl.get('loss'),
              accessor: 'lostPacketsPercentage',
            }, {
              Header: intl.get('jitter'),
              accessor: 'jitterMs',
            }, {
              Header: intl.get('frameRate'),
              accessor: 'frameRate',
            }, {
              Header: intl.get('codec'),
              accessor: 'codec',
            }, {
              Header: intl.get('resolution'),
              accessor: 'resolution'
            }]
          }
          data={mediaStats}
          getTdProps={() => ({
            style: {
              alignItems: 'flex-start',
              paddingTop: '16px',
              paddingBottom: '16px',
              lineHeight: '12px'
            }
          })}
          getTheadTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          getTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          keyField={'label'}
          manual={true}
          sortable={false}
          hasMoreRecords={false}
          rowHeightOverride={44}
          noDataText={participantLeft ? intl.get('noParticipantData') : undefined}
        />
      </div>
    </ListViewLayout>
  );
};

export { MeetingParticipantSummary };
