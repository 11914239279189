import { FormikValues } from 'formik';
import _get from 'lodash/get';
import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import * as styles from './MeetingRecordingShareSettings.scss';
import Icon from 'components/Common/Icon';
import { Tooltip } from 'components/tooltip/Tooltip';
import RecordingViewers from 'components/RecordingViewers/RecordingViewers';
import { VideoShareViewer } from 'interfaces/VideoShareProperties';
import { SHARE_WITH } from 'constants/recordingsConstants';

interface FieldValue {
  id: string;
  name: string;
}

interface Props {
  values: FormikValues;
  setFieldValue(field: string, value: FieldValue): void;
  shareField: string;
  viewersField: string;
  disabled?: boolean;
}

const MeetingRecordingShareSettings = (props: Props) => {
  const {setFieldValue, values, viewersField, shareField, disabled} = props;

  const getTooltip = (currentSelection: string) => {
    switch (currentSelection) {
      case SHARE_WITH.MEETING_OWNER:
        return intl.get(`meetingWhoCanViewRecordingTooltip-${currentSelection}`);
      case SHARE_WITH.VIEWERS:
        return intl.get(`meetingWhoCanViewRecordingTooltip-${currentSelection}`);
      case SHARE_WITH.PUBLIC:
        return intl.get(`meetingWhoCanViewRecordingTooltip-${currentSelection}`);
      default:
        return intl.get('meetingWhoCanViewRecordingTooltip-default');
    }
  };

  const shareWithOptions = [
    {
      text: intl.get('meetingOwner'),
      value: SHARE_WITH.MEETING_OWNER
    },
    {
      text: intl.get('meetingParticipants'),
      value: SHARE_WITH.MEETING_PARTICIPANTS
    },
    {
      text: intl.get('specificUsers'),
      value: SHARE_WITH.VIEWERS
    },
    {
      text: intl.get('entireAccount'),
      value: SHARE_WITH.PUBLIC
    }
  ];

  const shareFieldId = _get(values, `${shareField}.id`);

  return (
    <div className={styles.container}>
      <div className={styles.formGroupHorizontal}>
        <label className="label">{intl.get('whoCanViewRecording')}</label>
        {!disabled || _get(values, `${shareField}.id`) !== 'viewers' && (
          <Dropdown
            disabled={disabled}
            className={disabled ? styles.readOnlyMeetingViewSetting : styles.shareWithInput}
            closeOnBlur={true}
            fluid={false}
            name={shareField}
            onChange={(evt, selection) => {
              const option = shareWithOptions.find(o => o.value === selection.value);
              if (option) {
                // @ts-ignore
                setFieldValue(shareField, {
                  id: option.value,
                  name: option.text
                });
              }
            }}
            options={shareWithOptions}
            selection={true}
            value={shareFieldId}
          />
        )}
        <Tooltip text={getTooltip(shareFieldId)}>
          <Icon classes={['icon-info', styles.iconStyle]} />
        </Tooltip>
      </div>
      {shareFieldId === SHARE_WITH.VIEWERS &&
        <RecordingViewers
          viewers={_get(values, viewersField, [])}
          handleChange={
            (selectedViewers: Array<VideoShareViewer>) => {
              // @ts-ignore
              setFieldValue(viewersField, selectedViewers);
            }
          }
          disabled={disabled}
        />
      }
    </div>
  );
};

export default MeetingRecordingShareSettings;
