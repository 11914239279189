export interface CalendarSubscriptionState {
  subscriptionEmail: string | null;
  isBusy: boolean;
  subscriptionLookupFailed: boolean;
  unsubscribeFailed: boolean;
}

export const initialCalendarSubscriptionState: CalendarSubscriptionState = {
  subscriptionEmail: null,
  isBusy: false,
  subscriptionLookupFailed: false,
  unsubscribeFailed: false
};
