import { remotingConstants } from '@lifesize/clients.remoting';
import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import _get from 'lodash/get';
import listRoomsQuery from 'queries/roomSystems/listRoomsQuery.gql';
import { Logger } from 'logger';
import { getClient } from '../nucleus-apollo-client';
import { ListRoomsQuery } from 'typings/types';

const pairingMiddleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) =>  async (action: Action) => {
  const result = next(action);
  if (action.type === remotingConstants.pairing.actions.WAMP_SUBSCRIPTION_LINKING_EVENT) {
    const client = await getClient(store.dispatch);
    const serialNumber = _get(action, 'payload.deviceUUID');
    const eventType = _get(action, 'payload.eventType');
    const linkType = _get(action, 'payload.linkType');
    const linkedUUID = _get(action, 'payload.linkedUUID');

    if (eventType === 'DEVICE_LINKED') {
      // add device
      const device: ListRoomsQuery._Records = {
        UUID: linkedUUID,
        deviceUUID: serialNumber,
        linkedDeviceUUID: linkedUUID,
        type: linkType,
        linkCreateTime: new Date().getTime().toString(), // We don't receive this from the pairing service
        __typename: 'LinkedDevice'
      };
      try {
        const listRoomSystemsData = client.readQuery({ query: listRoomsQuery }) as ListRoomsQuery.Query;
        if (listRoomSystemsData && listRoomSystemsData.directoryListRoomSystem && listRoomSystemsData.directoryListRoomSystem.records) {
          let writeFlag = false;
          const mappedRecords = listRoomSystemsData.directoryListRoomSystem.records.map((record: ListRoomsQuery.Records) => {
            if (record.serialNumber === serialNumber) {
              writeFlag = true;
              if (!record.linkedDevices) {
                record.linkedDevices = {
                  records: []
                };
              }
              record.linkedDevices.records.push(device);
            }
            return record;
          });
          if (writeFlag) {
            listRoomSystemsData.directoryListRoomSystem.records = mappedRecords;
            client.writeQuery({ query: listRoomsQuery, data: listRoomSystemsData });
          }
        }
      } catch (e) {
        // not found, apollo throws an error if readQuery fails
        Logger.info(`Pairing Middleware: Device Linked: Did not find room system to update\n${JSON.stringify(action)}`);
      }
    } else if (eventType === 'DEVICE_UNLINKED') {
      // remove device
      try {
        const listRoomSystemsData = client.readQuery({ query: listRoomsQuery }) as ListRoomsQuery.Query;
        if (listRoomSystemsData && listRoomSystemsData.directoryListRoomSystem && listRoomSystemsData.directoryListRoomSystem.records) {
          let writeFlag = false;
          const mappedRecords = listRoomSystemsData.directoryListRoomSystem.records.map((record: ListRoomsQuery.Records) => {
            if (record.serialNumber === serialNumber && record.linkedDevices && record.linkedDevices.records.length > 0) {
              writeFlag = true;
              record.linkedDevices.records = record.linkedDevices.records.filter((r) => {
                return r && r.UUID !== linkedUUID;
              });
            }
            return record;
          });
          if (writeFlag) {
            listRoomSystemsData.directoryListRoomSystem.records = mappedRecords;
            client.writeQuery({ query: listRoomsQuery, data: listRoomSystemsData });
          }
        }
      } catch (e) {
        // not found, apollo throws an error if readQuery fails
        Logger.info(`Pairing Middleware: Device Unlinked: Did not find room system to update\n${JSON.stringify(action)}`);
      }
    }
  }
  return result;
};

export default pairingMiddleware as Middleware;
