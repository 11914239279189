import React from 'react';
import intl from 'react-intl-universal';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  formatUnixTimeOfDayForDisplay,
  secondsToMilliseconds,
  secondsToHms
} from 'utils/date-time-utils';
import * as styles from './LiveMeetingParticipantPage.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useInterval } from 'hooks/useInterval';
import {
  LIVE_MEETING_PARTICIPANTS,
  LIVE_MEETING_ROUTE,
  LIVE_MEETING_SLOW_REFRESH_TIME,
  LIVE_MEETING_TAB
} from 'constants/liveMeetings';
import {
  PAST_MEETING_PARTICIPANT_ROUTE,
} from 'constants/pastMeetings';
import { MeetingParticipantSummary } from 'components/MeetingParticipant/MeetingParticipantSummary/MeetingParticipantSummary';
import { MediaStat, MeetingParticipantDetails } from 'interfaces/MeetingParticipant';
import { useQuery } from 'react-apollo';
import ListViewTabLayout from 'components/ListViewTabLayout/ListViewTabLayout';
import CallGraphsComponent from 'components/CallGraphs/CallGraphsComponent';
import liveMeetingParticipantQuery from 'queries/liveMeetings/liveMeetingParticipantQuery.gql';
import _get from 'lodash/get';
import { flattenCallStats } from 'utils/callStatUtils';
import { selectUserActive } from 'selectors/userStateSelector';
import { selectLocale } from 'selectors/languageSelectors';
import { useSelector } from 'react-redux';
import { getShortMeetingName } from 'utils/meetingsUtils';

export const LiveMeetingParticipantPage = (props: RouteComponentProps) => {
  const [useTickDuration, setUseTickDuration] = React.useState(false);
  const isActive = useSelector(selectUserActive);
  const locale = useSelector(selectLocale);
  const conferenceId = _get(props, 'match.params.id', '');
  const callId = _get(props, 'match.params.callId', '');

  const { loading, error, data } = useQuery(liveMeetingParticipantQuery, {
    variables: { conferenceId, callId },
    fetchPolicy: 'network-only',
    pollInterval: isActive ? LIVE_MEETING_SLOW_REFRESH_TIME : 0
  });

  const liveMeetingParticipant: MeetingParticipantDetails = _get(data, 'liveMeetingParticipant');
  const extension = _get(data, 'liveMeetingParticipant.extension', '');
  const startedAt = _get(data, 'liveMeetingParticipant.startedAt');
  const endedAt = _get(data, 'liveMeetingParticipant.endedAt', null);
  const endTime = endedAt ? endedAt : Math.floor(Date.now() / 1000);
  const ongoingDuration = startedAt ? Math.floor(Date.now() / 1000) - startedAt : 0;
  const duration = startedAt && endedAt ?  endTime - startedAt : ongoingDuration;
  const meetingName = _get(data, 'liveMeetingParticipant.meetingName', '');
  const displayName = _get(data, 'liveMeetingParticipant.displayName', '');
  const userExists = !!liveMeetingParticipant;
  const sourceIP = _get(data, 'liveMeetingParticipant.sourceIP', '');
  const mediaStatData = _get(data, 'liveMeetingParticipant.mediaStats');
  const mediaStats: Array<MediaStat> = mediaStatData ? flattenCallStats(mediaStatData, locale) : [];
  const graphs = _get(data, 'liveMeetingParticipant.graphs');

  const [tickDuration, setTickDuration] = React.useState(duration);

  useInterval(() => {
    if (startedAt) {
      setUseTickDuration(true);
      if (!endedAt) {
        const newDuration = startedAt ? Math.floor(Date.now() / 1000) - startedAt : 0;
        setTickDuration(newDuration);
      } else {
        setTickDuration(duration);
      }
    }
  },          1000, [startedAt, endedAt, duration]);

  if (!loading && !userExists) {
    return <Redirect to={`${PAST_MEETING_PARTICIPANT_ROUTE}/${conferenceId}/${callId}`} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.structure}>
      <ListViewTabLayout
        title={displayName}
        breadcrumbs={(
          <React.Fragment>
            <Link key="liveMeetingsBreadcrumb" to={{ pathname: LIVE_MEETING_TAB }}>
              {intl.get('liveMeetings')}
            </Link>
            {' > '}
            <Link key="liveMeetingsBreadcrumb" to={{pathname: `${LIVE_MEETING_ROUTE}/${conferenceId}/${LIVE_MEETING_PARTICIPANTS}` }}>
              {getShortMeetingName(meetingName)}
            </Link>
            {' > '}
            {displayName}
          </React.Fragment>
        )}
        subtitle={(
          <div>
            <div className={styles.flexCol}>
              <ul className={styles.headerList}>
                <li>{intl.get('extensionWithColon')} {extension}</li>
                <li>{intl.get('sourceIPwithColon')} {sourceIP}</li>
              </ul>
              <ul className={styles.headerList}>
                <li>{intl.get('joinTimeWithColon')} {formatUnixTimeOfDayForDisplay(secondsToMilliseconds(startedAt))}</li>
                <li>{intl.get('leaveTimeWithColon')} {!!endedAt && formatUnixTimeOfDayForDisplay(secondsToMilliseconds(endedAt))}</li>
                <li>{intl.get('durationWithColon')} {secondsToHms(useTickDuration ? tickDuration : duration)}</li>
              </ul>
            </div>
          </div>
        )}
        panes={[
          {
            menuItem: (
              {
                key: 'summaryTab',
                content: (
                  intl.get('summary')
                )
              }),
            render: () => (
              <MeetingParticipantSummary
                participantLeft={!!endedAt}
                meetingParticipant={liveMeetingParticipant}
                error={error}
                mediaStats={mediaStats}
              />
            )
          },
          {
            menuItem: (
              {
                key: 'callGraphsTab',
                content: (
                  intl.get('callGraphs')
                )
              }),
            render: () => (
              <CallGraphsComponent
                graphs={graphs}
              />
            )
          }
        ]}
      />
    </div>
  );
};

export default LiveMeetingParticipantPage;
