import React from 'react';
import SearchBar from '../../../components/Search/SearchBar';
import GroupContactsToolbar from 'containers/Users/GroupContactsToolbarContainer';

interface Props {
  onFilterStringChange: (filterValue: string) => void;
}

const GroupContactsHeader = ({ onFilterStringChange }: Props) => {
  return (
    <>
      <SearchBar setFilterString={onFilterStringChange} key="searchbar" />
      <GroupContactsToolbar key="toolbar" />
    </>
  );
};

export default GroupContactsHeader;
