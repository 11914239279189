import { connect } from 'react-redux';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import actions from 'actions/drilldownActions';
import { Global } from 'state/Global';
import { parseRoomSystemUsageDetailsForExport } from 'utils/exportUtils';
import { exportToCsv } from 'utils/csv-utils';
import intl from 'react-intl-universal';
import UsageDetails from 'components/Drilldowns/UsersAndVideo/UsageDetails';

function mapStateToProps(state: Global) {
  const usersData = dataWarehouseSelectors.usageDetailsForRoom(state);
  const records = usersData.data;

  const handleExportToCsv = () => {
    const exportData = [];
    for (let record of records) {
      exportData.push(parseRoomSystemUsageDetailsForExport(record));
    }
    exportToCsv(intl.get('csvExportUsageDetailsRoom'), exportData);
  };

  return {
    error: usersData.error,
    isFetching: usersData.isFetching,
    records,
    handleExportToCsv
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchUserData: (config: object) => {
    dispatch(actions.handleFetchUsageDetailsRoom(config));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageDetails);
