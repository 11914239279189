import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const resetPasswordState = (state: Global) => state.app?.resetPassword;

const selectResetPasswordState = createSelector(
  resetPasswordState,
  (state) => {
    return state ? state : {
      isResettingPassword: false,
      isPasswordReset: false,
      resetPasswordFailure: false
    };
  }
);

export {
  selectResetPasswordState
};
