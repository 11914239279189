import React from 'react';
import classes from './EcommercePrompt.scss';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import { buildEcommerceUrl } from 'utils/ecommerceUtils';
import { useSelector } from 'react-redux';
import { authSelectors } from '@lifesize/nucleus';

interface Props {
  buttonId: string;
  message: string;
  extraParams?: object;
}

const EcommercePrompt = (props: Props) => {
  const { buttonId, message, extraParams } = props;
  const jwt = useSelector(authSelectors.getPrimaryJwt);

  return (
    <div className={classes.upgradeNotificationContainer}>
      <span className={classes.prompt}>
        {message}
      </span>
      <Button
        id={buttonId}
        name={buttonId}
        onClick={() => window.location.href = buildEcommerceUrl(jwt, extraParams)}
      >
        {intl.get('upgrade')}
      </Button>
    </div>
  );
};

export default EcommercePrompt;
