import React from 'react';
import Icon from 'components/Common/Icon';
import styles from './ModalComponent.scss';
import { ModalFooter, Props as FooterProps } from './ModalFooter';
import classnames from 'classnames';
import { useClickOutsideHandler } from 'hooks/useClickOutsideHandler';

interface Props {
  closeModal?: () => void;
  children: string | JSX.Element | JSX.Element[];
  isSaveButtonDisabled?: boolean;
  isSaveButtonBusy?: boolean;
  useSaveAsFormSubmit?: boolean;
  hideFooter?: boolean;
  hideSaveButton?: boolean;
  hideCloseButton?: boolean;
  onSave?: (payload?: object) => void;
  onCancel?: (payload?: object) => void;
  titleText?: string;
  closeText?: string;
  saveText?: string;
  className?: string;
  errorMessage?: string;
}

interface ComponentProps extends Props, FooterProps {}

const ModalComponent = (props: ComponentProps) => {
  const {
    closeModal,
    titleText,
    hideFooter,
    className,
    errorMessage,
    children
  } = props;

  const modalRef = useClickOutsideHandler(closeModal);

  return (
      <div className={styles.container}>
        <div className={classnames(styles.content, className)} ref={modalRef}>
          <button
              className={styles.closeButton}
              id="closeButton"
              name="btnClose"
              onClick={() => closeModal && closeModal()}
              role="button"
              type="button"
          >
            <Icon classes={['icon-cancel']} />
          </button>
          {
            titleText &&
            <div className={styles.title}>{titleText}</div>
          }
          <div className={styles.childrenContainer}>
            {children}
          </div>

          {!hideFooter && <ModalFooter {...props} />}

          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}

        </div>
      </div>
  );
};

export default ModalComponent;
