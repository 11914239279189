import * as React from 'react';
import SearchBar from 'components/Search/SearchBar';
import DateRangePickerComponent from 'containers/DateRangePicker/DateRangePickerContainer2';
import ExportButton from '../Common/ExportButton';

interface Props {
  handleExportData: () => void;
  isExporting: boolean;
  className?: string;
  setSearch: (search: string) => void;
  initialSearch?: string;
}

const ReportHeader = (props: Props) => {
  const { handleExportData, isExporting, setSearch, initialSearch, className } = props;
  return (
    <div className={className}>
      <SearchBar
        key="searchbar"
        setFilterString={setSearch}
        initialValue={initialSearch}
      />
      <div className="toolbar" key="toolbar" data-test="toolbar">
        <div>
          <ExportButton
            data-test="toolbarBtn.exportAll"
            disabled={false}
            isExporting={isExporting}
            onExportClick={handleExportData}
          />
          <DateRangePickerComponent />
        </div>
    </div>
  </div>
);
};

export default ReportHeader;
