import { createSelector } from 'reselect';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import { selectFilterString } from '../selectors/filterSelectors';
import { DWHUserCallsResultType } from '../interfaces/Dashboard';
import _toUpper from 'lodash/toUpper';
import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';

const filteredUserCalls = createSelector(
  selectFilterString,
  dataWarehouseSelectors.usersGroupByCallsPersonal,
  (search: string, dataToFilter: Array<DWHUserCallsResultType>): Array<DWHUserCallsResultType> => {
    const parsedData = _get(dataToFilter, 'data.data', []);
    if (search) {
      return parsedData.filter((value: DWHUserCallsResultType) => {
        return _toUpper(value.name).includes(_toUpper(search));
      });
    }
    if (parsedData.length) {
      return _sortBy(parsedData, (o) => -o.totalMinutes);
    }
    return parsedData;
  }
);

const filteredRoomSystemCalls = createSelector(
  selectFilterString,
  dataWarehouseSelectors.usersGroupByCallsRoom,
  (search: string, dataToFilter: Array<DWHUserCallsResultType>): Array<DWHUserCallsResultType> => {
    const parsedData = _get(dataToFilter, 'data.data', []);

    if (search) {
      return parsedData.filter((value: DWHUserCallsResultType) => {
        return _toUpper(value.name).includes(_toUpper(search));
      });
    }

    if (parsedData.length) {
      return _sortBy(parsedData, (o) => -o.totalMinutes);
    }

    return parsedData;
  }
);

export {
  filteredUserCalls,
  filteredRoomSystemCalls,
};
