import React from 'react';
import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import { nucleusConstants } from '@lifesize/nucleus';
import { Contact } from 'interfaces/Contact';
import { formatIsoUtcDateTimeForDisplayDate } from 'utils/date-time-utils';
import ClickableCell from 'components/ClickableCell/ClickableCell';
import { mapRoleName } from 'utils/permissionsUtils';

const usersTabColumnConfig = (editUser: (contact: Contact) => void, useRoles: boolean) => {
    return [
      {
        Header: intl.get('name'),
        id: 'displayName',
        Cell: (row: RowInfo) => {
          const { displayName } = row.original;
          return (
            <ClickableCell
              id={`users-${escape(displayName)}`}
              title={intl.get('name')}
              isClickable={true}
              displayText={displayName}
              clickHandler={() => {
                editUser(row.original);
              }}
            />
          );
        },
      }, useRoles ? {
        Header: intl.get('role'),
        id: 'roles',
        Cell: (row: RowInfo) => {
          const { roles } = row.original;
          return roles ? mapRoleName(roles[0]) : '';
        }
      } : {
        Header: intl.get('consoleAccess'),
        id: 'userType',
        Cell: (row: RowInfo) => {
          const { userType, loading, displayName } = row.original;
          if (!loading && displayName) {
            if (userType === nucleusConstants.USER_ROLE_ADMIN) {
              return intl.get('yesAdmin');
            }
            if (userType === nucleusConstants.USER_ROLE_SUPER_USER) {
              return intl.get('yes');
            }
            return intl.get('no');
          }
          return null;
        }
      }, {
        Header: intl.get('tableHeaderEmail'),
        accessor: 'email',
        id: 'email',
      }, {
        Header: intl.get('extension'),
        accessor: 'dialstring',
        id: 'extension'
      }, {
        Header: intl.get('created'),
        accessor: 'createdAt',
        id: 'createdAt',
        Cell: (row: RowInfo) => {
          const { createdAt } = row.original;
          if (createdAt) {
            return formatIsoUtcDateTimeForDisplayDate(createdAt);
          }
          return null;
        }
      }, {
        Header: intl.get('lastCall'),
        id: 'lastCallDate',
        Cell: (row: RowInfo) => {
          const { lastCallDate } = row.original;
          if (lastCallDate) {
            return formatIsoUtcDateTimeForDisplayDate(lastCallDate);
          }
          return null;
        }
      }
    ];
};

export {
  usersTabColumnConfig
};
