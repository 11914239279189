import { createSelector } from 'reselect';
import { parseDataForDisplay } from 'utils/liveStreamUtils';
import moment, { Moment } from 'moment';
import { DWHLiveStreamResultType } from 'interfaces/Dashboard';
import _get from 'lodash/get';
import * as dashboardSelectors from './dashboardSelectors';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import _toUpper from 'lodash/toUpper';
import { Global } from 'state/Global';

/**
 * Selector to the liveStreamHistory state
 */
const selectLiveStreamHistory = (state: Global) => state.app?.liveStreamHistory;

const selectLiveStreamHistoryNameFilter = createSelector(
  selectLiveStreamHistory,
  (liveStreamHistory) => liveStreamHistory.nameFilter
);

const currentOldLiveStreamsSelector = createSelector(
  selectLiveStreamHistoryNameFilter,
  dashboardSelectors.selectStartDateMoment,
  dashboardSelectors.selectEndDateMoment,
  dataWarehouseSelectors.streamsByList,
  (search: string, startDate: Moment, endDate: Moment, dataToFilter: Array<DWHLiveStreamResultType>): Array<DWHLiveStreamResultType> => {
    const parsedData = parseDataForDisplay(_get(dataToFilter, 'data.data', []));
    return parsedData.filter((value: DWHLiveStreamResultType) => {
        const eventDate = moment(value.startDate);
        if (eventDate < startDate || eventDate > endDate) {
          return false;
        }
        if (search) {
          return _toUpper(value.streamName).includes(_toUpper(search));
        }
        return true;
      });
    }
);

export {
  currentOldLiveStreamsSelector
};
