import { createSelector } from 'reselect';
import { Global } from 'state/Global';
import { accountSelectors } from '@lifesize/nucleus';
import { isFreemium } from './accountSelectors';
import { selectEcommerceSupported } from 'selectors/ecommerceSelector';
import { ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS } from 'constants/accountFeatureConstants';

const appLiveStreamsState = (state: Global) => state.app?.liveStreams;

export const liveStreamsSelector = createSelector(
  appLiveStreamsState,
  (state) => state
);

export const demoLiveStreamSelector = createSelector(
  accountSelectors.getAccountFeature,
  selectEcommerceSupported,
  isFreemium,
  (getAccountFeature, isEcommerceSupported, isAccountFreemium) =>
    !getAccountFeature(ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS) &&
    (isAccountFreemium || isEcommerceSupported)
);
