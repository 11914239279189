import React, { useEffect, useState } from 'react';
import _orderBy from 'lodash/orderBy';
import intl from 'react-intl-universal';
import { RowInfo, SortingRule } from 'react-table';
import {
  exportsErrorSelector,
  exportsLoadingSelector,
  exportsSelector
} from 'selectors/exportSelectors';
import DownloadsToolbar from './DownloadsToolbar';
import Icon from 'components/Common/Icon';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { Download, ExportStatus, DirectoryExportStatus, ExportType } from 'interfaces/Downloads';
import { formatUnixDateForDisplay } from 'utils/date-time-utils';
import { getSortingRule } from 'utils/listUtils';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import { SortDirection } from 'constants/queryConstants';
import { Sort } from 'interfaces/Meeting';
import { DeleteDownloadModal } from './DeleteDownloadModal';
import { useDispatch, useSelector } from 'react-redux';
import exportThunks from 'thunks/exportThunks';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import downloadNotificationActions from 'actions/downloadNotificationActions';
import { getDownloadNotificationCount } from 'selectors/downloadNotificationSelectors';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import updateDirectoryExport from 'queries/directoryExports/updateDirectoryExport.gql';
import exportActions from 'actions/exportActions';

const Downloads = () => {
  // We only want to show the loading indicator initially. Future loads should be silent.
  const downloadNotificationCount = useSelector(getDownloadNotificationCount);
  const dataLoading = useSelector(exportsLoadingSelector);
  const error = useSelector(exportsErrorSelector);
  const data: Download[] = useSelector(exportsSelector);
  const permissions = useSelector(selectAccountPermissions);
  const modifiableExportTypes = {
    [ExportType.MEETING]: permissions[ROLE_PERMISSION.MODIFY_MEETINGS],
    [ExportType.ROOM]: permissions[ROLE_PERMISSION.MODIFY_ROOMS],
    [ExportType.GROUP]: permissions[ROLE_PERMISSION.MODIFY_USERS],
    [ExportType.CONFERENCE]: permissions[ROLE_PERMISSION.VIEW_PAST_MEETINGS] || permissions[ROLE_PERMISSION.VIEW_MODERATOR_PAST_MEETINGS]
  };

  const [initialLoad, setInitialLoad] = useState(true);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateExport] = useMutation(updateDirectoryExport);
  const loading = initialLoad;

  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(downloadNotificationActions.dismissDownloadNotification());
      dispatch(exportThunks.getExports());
    },
    []
  );

  useEffect(
    () => {
      dispatch(downloadNotificationActions.clearDownloadCounter());
    },
    [downloadNotificationCount]
  );

  const [sort, setSort] = useState<Sort>(
    {
      propertyName: 'generationStartedAt',
      direction: SortDirection.DESC
    }
  );

  useEffect(
    () => {
      if (dataLoading) {
        setInitialLoadComplete(true);
      }
      if (!dataLoading && initialLoadComplete) {
        setInitialLoad(false);
      }
    },
    [dataLoading, initialLoadComplete]
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  const downloadsPageTitle = () => (
    <React.Fragment>
      <Icon classes={['icon-download']} />
      <span>{intl.get('downloadsPageTitle')}</span>
    </React.Fragment>
  );
  /*
   Method to be used for checking if the presingned URL is exipred
  */ 
  // const validateCSV = (downloadURL: string) => {
  //  const url = new URL(downloadURL);
  //  const date = moment(url.searchParams.get('X-Amz-Date'));
  //  return (date.valueOf() + (Number(url.searchParams.get('X-Amz-Expires')) * 1000)) > moment().valueOf();
  // };

  const handleRowClick = async (row: RowInfo) => {
    // Check download URL
    const variables = {
      downloadId:  row.original.id,
      exportedBy: row.original.generatedBy
    };
    const updatedExport = await updateExport({
      variables
    });
    const updatedSignedURL = updatedExport?.data?.updateDirectoryExport?.downloadUrl;
    if (!!updatedSignedURL) {
      const link = document.createElement('a');
      link.href = updatedSignedURL;
      link.setAttribute('download', row.original.filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(exportActions.setUpdatedExports({downloadId: row.original.id, downloadUrl: updatedSignedURL}));
    }
  };

  const onFetchData: (args: { sorted: Array<SortingRule> }) => void = async ({
    sorted: [column],
  }) => {
    if (!data) {
      return;
    }

    const newSort = await getSortingRule(column);
    if (newSort) {
      setSort(newSort);
    }
  };

  const sortedData = _orderBy(data, sort.propertyName, sort.direction === SortDirection.ASC ? 'asc' : 'desc');

  return (
    <React.Fragment>
      {showDeleteModal && <DeleteDownloadModal closeModal={() => setShowDeleteModal(false)} />}
      <ListViewLayout
        hideHeader={true}
        isFetching={false}
        numberOfPages={0}
        error={(error && error.message) || undefined}
      >
        <ListViewLayoutHeader title={downloadsPageTitle()} />
        <DownloadsToolbar openDeleteModal={() => setShowDeleteModal(true)} />
        <InfiniteTable
          columnConfig={
            [{
              Header: intl.get('name'),
              accessor: 'filename',
              minWidth: 150,
              Cell: (row: RowInfo) => {
                const { filename, downloadUrl, type } = row.original;
                if (!downloadUrl) {
                  return <span>{filename}</span>;
                } else if (type !== ExportType.CONFERENCE) {
                  return (
                    <a
                      onClick={() => handleRowClick(row)}
                      id={`download-${filename}`}
                    >
                      {filename}
                    </a>
                  );
                } else {
                  return (
                    <a
                      href={downloadUrl}
                      id={`download-${filename}`}
                    >
                      {filename}
                    </a>
                  );
                }
              }
            }, {
              Header: intl.get('status'),
              accessor: 'status',
              Cell: (row) => {
                switch (row.value) {
                  case ExportStatus.SUCCESS:
                  case DirectoryExportStatus.COMPLETED:
                    return intl.get('success');
                  case ExportStatus.ERROR:
                  case DirectoryExportStatus.FAILED:
                    return intl.get('error');
                  case ExportStatus.IN_PROGRESS:
                  case DirectoryExportStatus.IN_PROGRESS:
                    return intl.get('inProgress');
                  default:
                    return intl.get('unknown');
                }
              }
            }, {
              Header: intl.get('type'),
              accessor: 'type',
              Cell: (row) => {
                switch (row.value) {
                  case ExportType.USER:
                    return intl.get('users');
                  case ExportType.MEETING:
                    return intl.get('meetingsTab');
                  case ExportType.ROOM:
                    return intl.get('roomSystems');
                  case ExportType.GROUP:
                    return intl.get('contactsTab');
                  case ExportType.CONFERENCE:
                    return intl.get('pastMeetings');
                  case ExportType.RECORDING:
                    return intl.get('recording');
                  default:
                    return intl.get('unknown');
                }
              }
            }, {
              Header: intl.get('dateRange'),
              accessor: 'startTimestamp',
              Cell: (row) => {
                const { endTimestamp } = row.original;
                const startedTime = row.value ? formatUnixDateForDisplay(row.value) : undefined;
                const endedTime = endTimestamp ? formatUnixDateForDisplay(endTimestamp) : undefined;
                const dateRange = [startedTime, startedTime && endedTime && ' - ', endedTime];
                return dateRange.join('');
              },
            }, {
              Header: intl.get('exportedBy'),
              accessor: 'generatedBy'
            }, {
              Header: intl.get('exportedOn'),
              id: 'generationStartedAt',
              accessor: (row) => {
                return new Date(row.generationStartedAt);
              },
              Cell: (row) => {
                const { generationStartedAt } = row.original;
                if (!generationStartedAt) {
                  return '';
                }
                return row.value ? formatUnixDateForDisplay(row.value) : undefined;
              }
            }]
          }
          data={sortedData}
          enableSelect={true}
          noDataText={intl.get('noDownloads')}
          defaultSort={[
            {
              id: 'generationStartedAt',
              desc: true
            }
          ]}
          getTdProps={() => ({
            style: {
              alignItems: 'flex-start',
              paddingTop: '16px',
              paddingBottom: '16px',
              lineHeight: '12px'
            }
          })}
          getTheadTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          getTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          keyField={'exportId'}
          loading={loading}
          sortable={true}
          onFetchData={onFetchData}
          renderHeader={() => (
            <div />
          )}
          toggleFilter={(key: string) => {
            const exportType = key.split(',')[1];
            if (!exportType) { return true; }
            return modifiableExportTypes[exportType];
          }}
          rowHeightOverride={44}
        />
      </ListViewLayout>
    </React.Fragment>
  );
};

export default Downloads;
