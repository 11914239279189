import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import React from 'react';
import * as dashStyles from './Dashboard.scss';
import * as styles from './Tables/Tables.scss';

export interface Props {
  title: string;
  divId: string;
  isWide?: boolean;
}

const DashboardLoader: React.FunctionComponent<Props> = (props) => {

  const {
    title,
    divId,
    isWide
  } = props;
  return (
    <div className={isWide ? `${styles.container} ${styles.wide}` : styles.container} id={divId}>
      <div className={dashStyles.title}>
        {title}
      </div>
      <LoadingIndicator/>
    </div>
  );
};

export { DashboardLoader };
