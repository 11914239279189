import { Store } from 'redux';
import * as autobahn from 'autobahn-browser';
import { createReduxStore, createRtcMiddleware } from '@lifesize/nucleus';
import createReducer from './reducers';
import combineMiddleware from './middleware';
import { Global } from './state/Global';

export default function configureStore(): Store<Global> {
  return createReduxStore(
    createReducer(),
    combineMiddleware(),
    {},
    null,
    autobahn,
    createRtcMiddleware(),
  ) as Store<Global>;
}
