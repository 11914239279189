import updateUserProfileMutation from 'queries/profile/updateUserProfileMutation.gql';
import { Field, Form, Formik, FormikActions, FormikComputedProps, FormikHandlers, FormikState, } from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _memoize from 'lodash/memoize';
import { Logger } from 'logger';
import React from 'react';
import { Mutation } from 'react-apollo';
import intl from 'react-intl-universal';
import * as Yup from 'yup';
import ResetPassword from '../../../containers/ResetPassword/ResetPasswordContainer';
import { isEmailInDomainFilter } from '../../../utils/domainFilterUtils';
import { convertLocaleFromISO, convertLocaleToISO } from '../../../utils/languageUtils';
import LanguageSelect from '../../Common/LanguageSelect';
import { ModalFooter } from '../../Modal/ModalComponent/ModalFooter';
import * as formStyles from 'components/Modals/ModalForms.scss';
import classnames from 'classnames';

interface Props extends FormikState<object>,
  FormikComputedProps<object>,
  FormikHandlers,
  FormikActions<object> {
  userInfo: object;
  localeCode: string;
  isSSO: boolean;
  updateLocale: (localeCode: string) => void;
  closeModal: () => void;
  isResettingPassword: boolean;
  domainsFilter: string;
  groupDomains: string;
  resetPasswordHandler: () => void;
}

interface UpdateProfileOptions {
  variables: {
    user: {
      uuid: string;
      displayName: string;
      email: string;
    }

  };

}

/**
 * Cached to evaluate only once
 */
const myProfileTabSchema = _memoize((isSSO: boolean) => {
  if (!isSSO) {
    return Yup.object().shape({
      name: Yup.string().trim().required(intl.get('nameRequired'))
        .min(2, intl.get('nameMinLength'))
        .max(32, intl.get('nameMaxLength')),
      email: Yup.string()
        .email(intl.get('emailInvalid'))
        .required(intl.get('emailRequired')),
      localeCode: Yup.string()
    });
  }
  // Only require name validation if not SSO
  return Yup.object().shape({
    name: Yup.string().trim().required(intl.get('nameRequired'))
      .min(2, intl.get('nameMinLength'))
      .max(32, intl.get('nameMaxLength'))
  });
});

const errorStyle = _memoize((hasError: boolean, isSubmitting: boolean) => {
  if (hasError && !isSubmitting) {
    return formStyles.error;
  }
  return '';
},                          (...args) => {
  // Key for memoization
  return JSON.stringify(args);
});

const ProfileModalForm = (props: Props) => {
  const {
    closeModal,
    domainsFilter,
    groupDomains,
    isResettingPassword,
    isSSO,
    localeCode,
    updateLocale,
    userInfo,
    resetPasswordHandler,
  } = props;

  return (
    <Mutation mutation={updateUserProfileMutation}>
      {(updateUserProfile: (update: UpdateProfileOptions) => void) => (

        <Formik
          enableReinitialize={true}
          initialValues={{
            userUuid: _get(userInfo, 'UUID'),
            name: _get(userInfo, 'displayName'),
            email: _get(userInfo, 'email'),
            localeCode: convertLocaleFromISO(props.localeCode)
          }}
          validationSchema={myProfileTabSchema(isSSO)}
          validate={(function (values: object) {
            // Using non ES6 context to gain access to 'this' scope
            const newEmail = _get(values, 'email', '').toLowerCase();
            // @ts-ignore
            const originalEmail = _get(this, 'initialValues.email', '').toLowerCase();
            if (newEmail && originalEmail !== newEmail) {

              // Required email validation
              const isNewEmailPopulated = newEmail.length > 0;
              if (!isNewEmailPopulated) {
                return {
                  email: intl.get('emailRequired')
                };
              }

              // Email format validation
              // Email regex directly from Yup validator
              const rEmail = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i; // eslint-disable-next-line
              const isValidEmail = rEmail.test(newEmail);
              if (!isValidEmail) {
                return {
                  email: intl.get('emailInvalid')
                };
              }

              // Domain filters validation
              if (domainsFilter.length) {
                // Make sure the new email domain is allowed
                // Get the existing domains from the group settings (if it exists),
                // so the user can edit the name on their email, w/o changing the domain
                if (!isEmailInDomainFilter(newEmail, domainsFilter)
                  || (groupDomains && groupDomains.length && !isEmailInDomainFilter(newEmail, groupDomains))) {
                  return {
                    email: intl.get('domainFilterRestrictionError')
                  };
                }
              }
            }
            return {};
          })}
          onSubmit={async function (values: object, { setSubmitting, setFieldError }: FormikActions<object>) {
            const originalName = _get(userInfo, 'displayName', '');
            const originalEmail = _get(userInfo, 'email', '').trim().toLowerCase();
            // @ts-ignore
            const originalLocale = _get(this, 'initialValues.localeCode', '');
            // New values
            const uuid = _get(values, 'userUuid');
            const displayName = _get(values, 'name', '').trim();
            const email = _get(values, 'email', '').trim().toLowerCase();
            const locale = _get(values, 'localeCode');
            try {
              if (originalName !== displayName || originalEmail !== email) {
                await updateUserProfile({
                  variables: {
                    user: {
                      uuid,
                      displayName,
                      email
                    }
                  }
                });
              }
              if (originalLocale !== locale) {
                // Now persist the user language to local storage
                await updateLocale(convertLocaleToISO(locale));
              }

              closeModal();
            } catch (e) {
              if (e.message) {
                if (e.message.toLowerCase().trim() === 'graphql error: emailexisterror') {
                  setFieldError('email', intl.get('duplicateEmailError', { value: email }));
                  Logger.info(`Failure change email to an existing value:  ${email}`);
                } else if (e.message.toLowerCase().trim() === 'graphql error: displaynameexisterror') {
                  setFieldError('name', intl.get('duplicateUserNameError', { value: displayName }));
                  Logger.info(`Failure change name to an existing value:  ${displayName}`);
                } else {
                  // Unexpected error
                  setFieldError('general', intl.get('updateUserProfileError'));
                }
              }
              Logger.info(`Failure to update user profile: ${e}`);
              setSubmitting(false);
            }
          }}
          render={({
            dirty,
            errors,
            handleBlur,
            handleChange,
            handleReset,
            handleSubmit,
            initialValues,
            isSubmitting,
            isValid,
            isValidating,
            setFieldTouched,
            submitCount,
            touched,
            values,
          }) => {
            const nameErrors = _get(errors, 'name');
            const emailErrors = _get(errors, 'email');
            const generalError = _get(errors, 'general');
            return (
              <div className={formStyles.formControl}>
                <Form>
                  <div className={classnames(formStyles.formGroup, formStyles.vertical, formStyles.hasValidation, errorStyle(!_isEmpty(nameErrors), isSubmitting))}>
                    <label className={formStyles.labelLarge}>{intl.get('name')}</label>
                    <div className={formStyles.inputContainer}>
                      <Field
                        className={formStyles.input}
                        name="name"
                        placeholder={intl.get('name')}
                        type="text"
                      />
                      <ul className={formStyles.inputMessages}>
                        {touched && errors && nameErrors ? (
                          <li className={formStyles.error}>{_get(errors, 'name')}</li>
                        ) : null}
                      </ul>
                    </div>
                  </div>

                  {!isSSO && (
                    <div className={classnames(formStyles.formGroup, formStyles.vertical, formStyles.hasValidation, errorStyle(!_isEmpty(emailErrors), isSubmitting))}>
                      <label className={formStyles.labelLarge}>{intl.get('inputLabelEmail')}</label>
                      <div className={formStyles.inputContainer}>
                        <Field
                          className={formStyles.input}
                          name="email"
                          placeholder={intl.get('inputLabelEmail')}
                          type="text"
                        />
                        <ul className={formStyles.inputMessages}>
                          {touched && errors && emailErrors ? (
                            <li className={formStyles.error}>{emailErrors}</li>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  )}

                  {!isSSO && (
                    <div className={classnames(formStyles.formGroup, formStyles.vertical)}>
                      <div className={formStyles.inputContainer}>
                        <ResetPassword onClickHandler={resetPasswordHandler} />
                      </div>
                    </div>
                  )}

                  <div className={classnames(formStyles.formGroup, formStyles.vertical)}>
                    <label className={formStyles.labelLarge}>{intl.get('selectLanguage')}</label>
                    <div className={formStyles.inputContainer}>
                      <Field
                        name="localeCode"
                        render={() => (
                          <LanguageSelect
                            initialValues={initialValues}
                            touched={touched}
                            errors={errors}
                            dirty={dirty}
                            values={values}
                            handleSubmit={handleSubmit}
                            handleBlur={handleBlur}
                            handleReset={handleReset}
                            handleChange={handleChange}
                            isValid={isValid}
                            isSubmitting={isSubmitting}
                            isValidating={isValidating}
                            submitCount={submitCount}
                            className={formStyles.input}
                            fieldName={'localeCode'}
                            hideOkSaveCancel={true}
                            onChange={handleChange('localeCode')}
                            persistedValue={localeCode}
                            resetValue={_get(values, 'localeCode')}
                            setFieldTouched={setFieldTouched}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <ModalFooter
                    isSaveButtonDisabled={!dirty || !isValid}
                    useSaveAsFormSubmit={true}
                    closeModal={closeModal}
                    closeText={intl.get('cancel')}
                    isSaveButtonBusy={dirty && isSubmitting && !isResettingPassword}
                  />

                  {!generalError &&
                    <div className={classnames(formStyles.generalError, formStyles.center)}>
                      <div className={formStyles.error}>{generalError}</div>
                    </div>
                  }
                </Form>
              </div>
            );
          }}
        />
      )}
    </Mutation>
  );
};

export default ProfileModalForm;
