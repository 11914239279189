import React, { useState } from 'react';
import ExportButton from 'components/Common/ExportButton';
import { ParticipantEntry } from 'interfaces/Dashboard';
import * as styles from './ParticipantsToolbar.scss';
import { parseParticipantDataForExport } from 'utils/exportUtils';
import { exportToCsv } from 'utils/csv-utils';
import intl from 'react-intl-universal';

// props passed in
export interface Props {
  data: ParticipantEntry[];
  meetingName: string;
}

const ParticipantsToolbar = (props: Props) => {
  const { data, meetingName } = props;
  const [isExporting, setExporting] = useState(false);
  const handleExportToCsv = async () => {
    setExporting(true);
    exportToCsv(intl.get('meetingParticipants'), parseParticipantDataForExport(data, meetingName));
    setExporting(false);
  };

  return (
    <div className={styles.container}>
      <ExportButton
        disabled={false}
        isExporting={isExporting}
        onExportClick={handleExportToCsv}
        tooltipProps={{ isOnRight: true }}
      />
    </div>
  );
};

export default ParticipantsToolbar;
