import {
  accountSelectors,
  contactActions,
  contactCRUDSelectors,
  currentUserSelectors,
  groupSettingsActions,
  vcApiActions,
  nucleusConstants,
  recordingActions as nucleusRecordingActions,
  recordingsAuthSelectors,
} from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';
import { Global } from 'state/Global';
import { actions } from 'actions/modalActions';
import { MeetingsModal } from 'components/Modals/MeetingsModal/MeetingsModal';
import { selectPayload } from 'selectors/modalSelector';
import { ListMeetingsQuery, Meeting } from 'typings/types';
import MeetingInlineFragment = ListMeetingsQuery.MeetingInlineFragment;
import listAllMeetingsForCountQuery from 'queries/meetings/listAllMeetingsForCountQuery.gql';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { MEETING_TYPES } from 'constants/meetingConstants';
import { ACCOUNT_SETTING_RECORDING_ENABLED, ACTIVE_FEATURE_STATUS } from 'constants/accountSettingsConstants';
import getAccountSettings = accountSelectors.getAccountSettings;

const errorMessage = (isDuplicateName: boolean, requestedMeetingName: string | undefined, isAdd: boolean) => {
  if (isDuplicateName) {
    if (requestedMeetingName) {
      return intl.get('duplicateMeetingNameError', { value: requestedMeetingName });
    }
    return intl.get('duplicateMeetingError');
  }
  if (isAdd) {
    return intl.get('addMeetingFailed');
  }
  return intl.get('editMeetingFailed');
};

const mapStateToProps = (state: Global, ownProps: { meetingsUsed: number }) => {
  const selectedMeeting = selectPayload(state) as MeetingInlineFragment;
  const saveContactState = contactCRUDSelectors.saveContactSelector(state);
  const recordingsAuthSettings = recordingsAuthSelectors.vcSettingsSelector(state) || {};
  const isRecordingEnabled = getAccountSettings(state)[ACCOUNT_SETTING_RECORDING_ENABLED] ?? false;
  accountSelectors.getAccountFeature(state)(nucleusConstants.ACCOUNT_FEATURE_RECORDING);
  const hasRecordingFeature =
    isRecordingEnabled && ACTIVE_FEATURE_STATUS[_get(recordingsAuthSettings.data, 'recordingStatus')];
  const errors = saveContactState.errors;
  const baseReturn = {
    data: selectedMeeting,
    isBusy: false,
  };

  if (errors) {
    const isDuplicateError = _isArray(errors)
      ? _get(errors[0], 'type') === nucleusConstants.VALIDATE_DUPLICATE
      : _get(errors, 'errorDescription') === 'DisplayNameExistError';

    const requestedMeetingName = _isArray(errors) ? _get(errors[0], 'context.value') : _get(errors, 'context.value');

    return {
      ...baseReturn,
      errorMessage: errorMessage(isDuplicateError, requestedMeetingName, _get(ownProps, 'isAdd', false))
    };
  }

  return {
    ...baseReturn,
    canCreateOneTimeMeeting:
      accountSelectors.getAccountFeature(state)(nucleusConstants.ACCOUNT_FEATURE_ONE_TIME_MEETING),
    currentUser: currentUserSelectors.myInfo(state),
    errorMessage: null,
    isBusy: false,
    hasRecordingFeature,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async () => {
    await dispatch(contactActions.resetContactErrorState());
    dispatch(actions.closeModal());
  },
  fetchData: async () => {
    await dispatch(groupSettingsActions.getGroupSettings());
  },
  setStreamingSettings: (id: string, settings: Object) => dispatch(vcApiActions.setStreamingSettings(id, settings)),
  setMeetingShareProperties: async (id: string, props: object) => await dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.setMeetingShareProperties(id, props)))
});

const composed = compose(
  graphql(listAllMeetingsForCountQuery, {
    props: props => {
      const records = _get(props, 'data.directoryList.records', []);
      const permanentMeetings = records.filter((o: Meeting) => o.type === MEETING_TYPES.NORMAL);
      return ({
        meetingsUsed: (permanentMeetings) ? permanentMeetings.length : 0,
        meetingsLoading: _get(props, 'data.loading', true),
      });

    },
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(MeetingsModal);

export const AddMeetingModal = defaultProps({ isAdd: true })(composed);
export const EditMeetingModal = defaultProps({ isAdd: false })(composed);
export { composed as MeetingsModal };
