import React from 'react';
import DateRangePickerComponent from '../../../containers/DateRangePicker/DateRangePickerContainer';
import ExportButton from '../../Common/ExportButton';

interface Props {
  onExportClick: () => void;
  fetchData: Function;
}

const DrilldownHeader = ({ fetchData, onExportClick }: Props) => {
  return (
    <div className="toolbar" data-test="toolbar">
      <div>
        <ExportButton
          disabled={false}
          onExportClick={onExportClick}
        />
        <DateRangePickerComponent
          fetchData={fetchData}
        />
      </div>
    </div>
  );
};

export default DrilldownHeader;
