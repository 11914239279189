import intl from 'react-intl-universal';
import * as Yup from 'yup';
import { nucleusConstants } from '@lifesize/nucleus';
import { MEETING_NAME_MAX_LENGTH } from 'constants/meetingConstants';

const pinIsNumber = (val: string) => !val || /^\d+$/.test(val);
const pinMinMax = (val: string) => !val || (val.length > 3 && val.toString().length < 11);

/**
 * Validation rules for Meetings form fields
 * Cached to evaluate only once
 */
const meetingsModalSchema = (requirePasscodes?: boolean) => {
  const pinRequirement = () => requirePasscodes ?
    Yup.mixed().required(intl.get('passcodeRequiredError'))
      .test('isNumber', intl.get('passCodeNumbersOnly'), pinIsNumber)
      .test('minmaxlen', intl.get('passCodeMinMaxLength'), pinMinMax) :
    Yup.mixed().notRequired()
      .test('isNumber', intl.get('passCodeNumbersOnly'), pinIsNumber)
      .test('minmaxlen', intl.get('passCodeMinMaxLength'), pinMinMax);

  return () => Yup.object().shape({
    meetingType: Yup.string().required('A radio option is required').oneOf([nucleusConstants.MEETING_TYPE_ONE_TIME, nucleusConstants.MEETING_TYPE_VMR]),
    // *** General Tab fields
    name: Yup.string().trim().required(intl.get('meetingNameRequired'))
      .max(MEETING_NAME_MAX_LENGTH, intl.get('meetingNameMaxLength', { maxLength: MEETING_NAME_MAX_LENGTH })),
    pin: pinRequirement(),
    description: Yup.string().trim().notRequired()
      .max(250, intl.get('meetingDescriptionMaxLength')),
    // ***  Roles Tab fields
    owner: Yup.string().required(intl.get('meetingOwnerRequired')),
    moderator: Yup.string().required(intl.get('meetingModeratorRequired')),
    streamingPermissionsPasscode: Yup.mixed().when('streamingPermissions', {
      is: (val) => val === 'pin',
      then: Yup.mixed().required(intl.get('streamPassCodeMinMaxLength'))
        .test('isNumber', intl.get('passCodeNumbersOnly'), val => !val
          || val.search(/^\d+$/i) !== -1)
        .test('minmaxlen', intl.get('streamPassCodeMinMaxLength'), val => !val
          || ((val.toString().length > 5 && val.toString().length < 11))),
    }),
    hiddenMeeting: Yup.boolean(),
  });
};

export {
  meetingsModalSchema
};
