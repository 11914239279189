import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const subscriptionInfo = (state: Global) => state.app?.calendarSubscription;

const calendarSubscriptionSelector = createSelector(
  subscriptionInfo,
  (r) => r
);

export {
  calendarSubscriptionSelector
};
