export interface PermissionsState {
  accountRecordings: boolean;
  accountStreaming: boolean;
  isAdmin: boolean;
  isSuperUser: boolean;
  isUser: boolean;
  viewDashboard: boolean;
  viewMeetings: boolean;
  viewProfile: boolean;
  viewRecordings: boolean;
  viewRoomSystems: boolean;
  viewSettings: boolean;
  viewStreaming: boolean;
  viewUsers: boolean;
}

const useProductionSettings = process.env.REACT_APP_IS_PRODUCTION === 'true';

export const initialPermissionsState: PermissionsState = {
  accountRecordings: false,
  accountStreaming: false,
  isAdmin: false,
  isSuperUser: false,
  isUser: true,
  viewDashboard: false,
  viewMeetings: false,
  viewProfile: false,
  viewRecordings: true,
  viewRoomSystems: useProductionSettings,
  viewSettings: useProductionSettings,
  viewStreaming: false,
  viewUsers: false,
};
