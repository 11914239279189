import React, { useState } from 'react';
import intl from 'react-intl-universal';
import * as styles from 'components/Settings/Sections.scss';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import useSettingsChange from 'hooks/useSettingsChange';
import { ACCOUNT_SETTING_AUTO_RECORD } from 'constants/accountSettingsConstants';
import { useSelector } from 'react-redux';
import { autoRecordMeetingsSetting } from 'selectors/accountSelectors';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const AutoRecord = () => {
  const autoRecord = useSelector(autoRecordMeetingsSetting);
  const [ settingsChangeError, setSettingsChangeError ] = useState(false);
  const [ autoRecordState, setAutoRecordState ] = useState(autoRecord);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const changeSettings = useSettingsChange();
  return (
    <section className={styles.container} data-test="settingsSection.autoRecord">
      <article>
        <h6 className={styles.title}>{intl.get('autoRecord')}</h6>
        <ToggleButton
          id="toggleAutoRecord"
          label={intl.get('autoRecordLabel')}
          leftLabel={false}
          hidenow={false}
          topLabel={false}
          disabled={!canEditSettings}
          field={{
            name: 'autoRecord',
            value: autoRecordState,
            onChange: () => {
              if (!canEditSettings) { return; }
              return changeSettings(
                ACCOUNT_SETTING_AUTO_RECORD,
                setAutoRecordState,
                autoRecordState,
                setSettingsChangeError
              );
            },
            onBlur: () => { return; }
          }}
        />
        {settingsChangeError && <div className={styles.error}>{intl.get('meetingSettingFailure')}</div>}
      </article>
    </section>
  );
};

export default AutoRecord;
