import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import actions, { RecordingsActionType } from 'actions/recordingsActions';
import { UploadStatusResponse } from 'interfaces/Recording';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { UploadRecordingStatus, UPLOAD_MONITORING_POLLING_INTERVAL } from 'constants/recordingsConstants';

const uploadRecordingMonitorMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return (action: PayloadAction<string, UploadStatusResponse[]>) => {
        if (action.type === RecordingsActionType.RECORDINGS_ADD_PROCESSING_UPLOAD) {
          setTimeout(
            () => store.dispatch(actions.getUploadsStatus()),
            UPLOAD_MONITORING_POLLING_INTERVAL
          );
        }
        if (action.type === RecordingsActionType.RECORDINGS_SET_UPLOADS_STATUS) {
          if (action.payload.filter(upload => [UploadRecordingStatus.PROCESSING].includes(upload.status)).length) {
            setTimeout(
              () => store.dispatch(actions.getUploadsStatus()),
              UPLOAD_MONITORING_POLLING_INTERVAL
            );
          }
        }
        return next(action);
      };
    };

export default (uploadRecordingMonitorMiddleware as Middleware);
