import { createAction } from '@lifesize/typescript-react-redux-utils';

export const DRILLDOWN_FETCH_CALL_HISTORY = 'DRILLDOWN_FETCH_CALL_HISTORY';

export const actions = {
  handleFetchCallHistory: () =>
    createAction(DRILLDOWN_FETCH_CALL_HISTORY),
};

export default actions;
