import React from 'react';
import { ModalTypes } from 'constants/modalConstants';
import { ModalContainers } from 'constants/modalContainers';

interface Props {
  modalType?: ModalTypes;
  modalIsOpen?: boolean;
  modalProps: object;
}

const ModalConductor = ({modalType, modalIsOpen, modalProps}: Props) => {
  if (modalType) {
    const ModalToShow = ModalContainers[modalType];
    return <ModalToShow {...modalProps} />;
  }
  return null;
};

export default ModalConductor;
