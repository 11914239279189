import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import { formatIsoUtcDateTimeForDisplayDate } from 'utils/date-time-utils';

const mergeRequestsColumnConfig = () => {
  return [{
    Header: intl.get('tableHeaderEmail'),
    accessor: 'sourceAdminEmail'
  }, {
    Header: intl.get('status'),
    accessor: 'status',
    Cell: (row: RowInfo) => {
      return intl.get('pending');
    }
  }, {
    Header: intl.get('usersInAccount'),
    accessor: 'sourceUserCount'
  }, {
    Header: intl.get('date'),
    accessor: 'createdAt',
    Cell: (row: RowInfo) => {
      const { createdAt } = row.original;
      if (createdAt) {
        return formatIsoUtcDateTimeForDisplayDate(createdAt);
      }
      return null;
    }
  }];
};

export {
  mergeRequestsColumnConfig
};
