import React  from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import ExportButton from 'components/Common/ExportButton';
import ToolBarButton from 'components/Common/ToolbarButton';
import { ExportType } from 'interfaces/Downloads';
import useExport from 'hooks/useExport';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  addMeetingModalOpen: () => void;
  deleteMeetings: (arg: Array<string>) => void;
  selectionArray: Array<string>;
}

const MeetingsToolbar = (props: Props) => {
  const { addMeetingModalOpen, selectionArray, deleteMeetings } = props;
  const { handleExportData, loading } = useExport(ExportType.MEETING);
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  return (
    <div>
      <ExportButton
        data-test="toolbarBtn.exportAll"
        disabled={false}
        isExporting={loading}
        onExportClick={handleExportData}
      />
      <Button
        data-test="toolbarBtn.createMeeting"
        disabled={!canEditMeetings}
        id="btnAddMeeting"
        name="btnAddMeeting"
        onClick={() => {
          if (canEditMeetings) {
            addMeetingModalOpen();
          }
        }}
        role="button"
        type="button"
      >
        {intl.get('createMeeting')}
      </Button>
      <ToolBarButton
        clickHandler={() => { deleteMeetings(selectionArray); }}
        data-test="toolbarBtn.deleteMeeting"
        disabledTooltipText={canEditMeetings ? intl.get('tooltipMeetingDisabledText') : intl.get('cannotModifyMeetings')}
        isEnabled={selectionArray.length > 0 && canEditMeetings}
        label={intl.get('delete')}
        tooltipIsOnRight={true}
        warningTooltipText={''}
      />
    </div>
  );
};

export default MeetingsToolbar;
