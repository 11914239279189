import React, { SyntheticEvent, useState } from 'react';
import intl from 'react-intl-universal';
import * as styles from 'components/Settings/Sections.scss';
import * as passcodeStyles from './WaitingRoomSetting.scss';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { useDispatch, useSelector } from 'react-redux';
import { waitingRoom } from 'selectors/accountSelectors';
import { setMeetingSettings } from 'thunks/groupSettingsThunks';
import { useMount } from 'hooks/useMount';
import _get from 'lodash/get';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const WaitingRoomSetting = () => {
  const meetingsWaitingRoomSetting = useSelector(waitingRoom);
  const [ waitingRoomState, setWaitingRoomSettingState ] = useState(meetingsWaitingRoomSetting);

  const [ settingsChangeError, setSettingsChangeError ] = useState(false);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const dispatch = useDispatch();
  const isMounted = useMount();
  return (
    <section className={styles.container} data-test="settingsSection.chat">
      <article>
        <h6 className={styles.title}>{intl.get('waitingRoom')}</h6>
        <ToggleButton
          classNameLabel={''}
          id={'waitingRoom'}
          label={intl.get('waitingRoomSetting')}
          leftLabel={false}
          hidenow={false}
          topLabel={false}
          disabled={!canEditSettings}
          field={{
            name: 'waitingRoom',
            value: waitingRoomState,
            onChange: async (newValue: SyntheticEvent) => {
              const currentState = waitingRoomState;
              setWaitingRoomSettingState(!currentState);
              const result = await dispatch(setMeetingSettings({ 'waitingRoom': !meetingsWaitingRoomSetting }));
              if (_get(result, 'type') !== String(setMeetingSettings.fulfilled)) {
                if (isMounted.current) {
                  setSettingsChangeError(true);
                  setWaitingRoomSettingState(currentState);
                }
              }
            },
            onBlur: () => {return; }
          }}
        />
        {settingsChangeError && <div className={passcodeStyles.error}>{intl.get('waitingRoomSettingFailure')}</div>}
      </article>
    </section>
  );
};

export default WaitingRoomSetting;
