import { connect } from 'react-redux';
import { actions } from '../../actions/modalActions';
import TabletInfoModal from '../../components/Modals/TabletInfoModal/TabletInfoModal';
import { ModalTypes } from '../../constants/modalConstants';
import * as modalSelector from '../../selectors/modalSelector';
import { Global } from '../../state/Global';
import { RoomSystem } from '../../typings/types';

const mapStateToProps = (state: Global) => {
  const roomSystem = modalSelector.selectPayload(state) as RoomSystem;
  return {
    roomSystem: roomSystem
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    closeModal: () => {
      dispatch(actions.closeModal());
    },
    openDisconnectDashConfirm: (payload: RoomSystem) => {
      dispatch(actions.closeModal());
      dispatch(actions.openModal({modalType: ModalTypes.TABLET_DELETE, payload: payload}));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabletInfoModal);
