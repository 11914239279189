import Icon from 'components/Common/Icon';
import TooltipFixedPosition from 'components/TooltipFixedPosition/TooltipFixedPosition';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { LOCATION } from 'constants/tooltipFixedPositionConstants';
import SingleRecordingInterface from 'interfaces/SingleRecording';
import { formatDateAtTimeFromUnix } from 'utils/date-time-utils';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import styles from './SingleRecordingFrame.scss';
import { RECORDINGS_TABS } from 'constants/recordingsConstants';

export const SingleRecordingFrame = (props: SingleRecordingInterface) => {
  const {
    addToWatchList,
    canUserModifyRecording,
    confirmDelete,
    isOnWatchList,
    notFound,
    notAuthorized,
    openEditModal,
    openShareModal,
    recordingId,
    removeFromWatchList,
    singleRecordingData,
    fetchSingleRecording,
    isFetching,
    isAddingToWatchList
  } = props;

  // waitingOnFetch is to prevent using stale singleRecordingData from being used on mount.
  // Using the stale data results in double view OR view increment of previous recording (GOLD-3208)
  const [waitingOnFetch, setWaitingOnFetch] = useState<boolean>(true);
  const [videoWidth, setVideoWidth] = useState<string>('100%');
  const recordingFrame = useRef<HTMLDivElement>(null);

  const updateDimensions = () => {
    if (!recordingFrame.current) {
      return;
    }

    const newHeight = recordingFrame.current.offsetHeight;
    const newWidth = newHeight * (16 / 9);
    if (newWidth > 100) {
      setVideoWidth(`${newWidth}px`);
    }
  };

  const measuredRecordingFrame = useCallback(
    node => {
      if (node !== null) {
        updateDimensions();
      }
    },
    []
  );

  const fetchRecording = async (id: string) => {
    await fetchSingleRecording(id);
    setWaitingOnFetch(false);
  };
  useEffect(
    () => {
      if (recordingId && recordingId.length) {
        fetchRecording(recordingId);
      }
    },
    [recordingId]
  );

  useEffect(
    () => {
      window.addEventListener('resize', updateDimensions);
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    },
    []
  );

  if (waitingOnFetch || isFetching || isAddingToWatchList) {
    return (
      <div className={styles.container}>
        <LoadingIndicator />
      </div>
    );
  }

  if (notFound || notAuthorized) {
    return (
      <div className={styles.container}>
        <div className={styles.messageOnly}>
          <Icon classes={['icon-record']} />
          <h4 className="stacked">
            {notFound ? intl.get('recordingNotFound') : intl.get('recordingNoAccess')}
          </h4>
        </div>
      </div>
    );
  }

  if (!singleRecordingData || Object.keys(singleRecordingData).length === 0) {
    return <React.Fragment />;
  }

  const {
    can_wlater, date_created= 0, default_meeting, download_url,
    iframe, meeting, meeting_id = '', name, owner, views, description
  }
    = singleRecordingData;

  return (
    <div className={styles.container} ref={measuredRecordingFrame}>
      <div className={styles.videoFrame} ref={recordingFrame} style={{ width: videoWidth }}>
        <iframe src={iframe} scrolling="no" allowFullScreen={true} />
      </div>
      <div className={styles.name}>
        {name}
      </div>
      {description && (
        <div className={styles.descriptionContainer}>
          {description}
        </div>
      )}
      <div className={styles.timeAndViewsContainer}>
        <div>
          {formatDateAtTimeFromUnix(date_created * 1000)}
        </div>
        <div className={styles.verticalDivider} />
        <div className={styles.viewsContainer}>
          <div className={styles.eye} />
          {views}
        </div>
      </div>
      <div className={styles.horizontalDivider} />
      <div className={styles.videoDetails}>
        <div className={styles.ownerAndVmr}>
          <div>{owner}</div>
          {meeting_id !== default_meeting && (
            <div className={styles.vmrLinkContainer}>
              <Link
                to={{
                  pathname: RECORDINGS_TABS.RECORDINGS_FEED,
                  state: {
                    meetingId: meeting_id,
                    meetingName: meeting
                  }
                }}
              >
                {meeting}
              </Link>
            </div>
          )}
        </div>

        <div className={styles.buttonBar}>
          {
            canUserModifyRecording &&
            <div className={styles.button}>
              <button
                onClick={() => {
                  openEditModal(recordingId);
                }}
                name="btnEdit"
                role="button"
                type="button"
                data-test="singleRec.editBtn"
              >
                <Icon classes={['icon-edit']} />
              </button>
              <TooltipFixedPosition
                label={intl.get('editRecordingTitle')}
                location={LOCATION.TOP}
                offset={{ top: -5 }}
              />
            </div>
          }

          {
            canUserModifyRecording &&
            <div className={styles.button}>
              <a href={`${download_url}`} target="_blank">
                <button name="btnDownload" role="button" type="button" data-test="singleRec.downloadBtn">
                  <Icon classes={['icon-download']} />
                </button>
                <TooltipFixedPosition
                  label={intl.get('download')}
                  location={LOCATION.TOP}
                  offset={{ top: -5 }}
                />
              </a>
            </div>
          }

          {
            canUserModifyRecording &&
            <div className={styles.button}>
              <button name="btnDelete" onClick={confirmDelete} role="button" type="button" data-test="singleRec.deleteBtn">
                <Icon classes={['icon-trash']} />
              </button>
              <TooltipFixedPosition
                label={intl.get('deleteRecording')}
                location={LOCATION.TOP}
                offset={{ top: -5 }}
              />
            </div>
          }

          {<div className={styles.verticalDivider} style={{ height: '24px' }} />}

          {
            canUserModifyRecording &&
            <div className={styles.button}>
              <button
                name="btnShare"
                onClick={() => {
                  openShareModal(recordingId);
                }}
                role="button"
                type="button"
                data-test="singleRec.shareBtn"
              >
                <Icon classes={['icon-share-alt']} />
              </button>
              <TooltipFixedPosition
                label={intl.get('share')}
                location={LOCATION.TOP}
                offset={{ top: -5 }}
              />
            </div>
          }

          {can_wlater && (
            <div className={styles.button}>
              <button
                name="btnRemove"
                onClick={() => {
                  if (addToWatchList && recordingId) {
                    isOnWatchList ? removeFromWatchList(recordingId) : addToWatchList(recordingId);
                  }
                }}
                role="button"
                type="button"
                data-test="singleRec.addBtn"
              >
                <Icon classes={[isOnWatchList ? 'icon-zoom-out' : 'icon-zoom-in']} />
              </button>
              <TooltipFixedPosition
                label={intl.get(isOnWatchList ? 'removeFromWatchList' : 'addToWatchList')}
                location={LOCATION.LEFT}
                offset={{ top: -5 }}
              />
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default { SingleRecordingFrame };
