import { Reducer } from 'redux';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { ModalActionType, ModalProps } from 'constants/modalConstants';
import { ModalState, initialModalState } from 'state/Modal';

const modalReducer: Reducer<ModalState> =
  (state: ModalState = initialModalState, action: PayloadAction<string, ModalProps>) => {
  const { modalType, payload } = action?.payload || {};
  switch (action.type) {
      case ModalActionType.MODAL_OPEN:
        return {
          ...state,
          modalType,
          payload,
          pendingClosedModal: false,
        };
      case ModalActionType.MODAL_CLOSE:
        return {
          ...state,
          modalType: undefined,
          pendingClosedModal: false,
        };
      case ModalActionType.MODAL_PENDING_CLOSE:
        return {
          ...state,
          pendingClosedModal: true
        };
      default:
        return state;
    }
  };

export default modalReducer;
