export const getDomain = () => {
  return process.env.REACT_APP_DOMAIN;
};

export const getLifesizeDomain = () => {
  return process.env.REACT_APP_DOMAIN?.replace('cloud', '');
};

export const redirectToLogin = () => {
  const logoutTarget = {
    url: process.env.REACT_APP_LOGIN_URL,
  };
  const logoutUrl = logoutTarget.url + encodeURIComponent(window.location.href);
  window.location.href = logoutUrl;
};

export const redirectToSignUp = () => {
  const redirectUrl = new URL('https://signup.' + getLifesizeDomain());
  window.location.href = redirectUrl.href;
};

export const redirectToLogout = () => {
  const logoutTarget = {
    url: process.env.REACT_APP_LOGOUT_URL,
    target: window.location.protocol + '//' + window.location.host,
  };
  const logoutUrl = logoutTarget.url + encodeURIComponent(logoutTarget.target);
  window.location.href = logoutUrl;
};

export const getUrlParameter = (name: string) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
