import { recordingActions as nucleusRecordingActions } from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import { AnyAction, Middleware, MiddlewareAPI, Store } from 'redux';
import { SEARCH_CLOUD_MEETINGS } from '../actions/searchActions';
import { ThunkDispatch } from 'redux-thunk';

/**
 * Does elastic search lookup of VC meetings that can be associated with a recording
 */
const searchCloudUsersMiddleware =
  (store: MiddlewareAPI) => {
    return (next: ThunkDispatch<Store, Middleware, AnyAction>) => {
      return (action: PayloadAction<string, object>) => {
        if (action.type !== SEARCH_CLOUD_MEETINGS) {
          return next(action);
        }
        return next(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.searchCloudMeetings(action.payload || '')));
      };
    };
  };

export default (searchCloudUsersMiddleware as Middleware);
