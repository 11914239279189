import { Checkbox, Form as uiForm } from 'semantic-ui-react';
import * as styles from 'components/Settings/Sections.scss';
import { featureSelectors, nucleusConstants } from '@lifesize/nucleus';
import intl from 'react-intl-universal';
import * as screen_main_only from 'images/screen_main_only.svg';
import * as screen_main_plus_4 from 'images/screen_main_plus_4.svg';
import * as screen_main_plus_7 from 'images/screen_main_plus_7.svg';
import * as screen_main_plus_12 from 'images/screen_main_plus_12.svg';
import * as screen_gallery_plus_7 from 'images/screen_gallery_plus_7.svg';
import * as screen_gallery_plus_9 from 'images/screen_gallery_plus_9.svg';
import * as screen_gallery_plus_16 from 'images/screen_gallery_plus_16.svg';
import * as screen_gallery_plus_25 from 'images/screen_gallery_plus_25.svg';
import * as screen_tiles_2 from 'images/screen_tiles_2.svg';
import * as screen_tiles_3 from 'images/screen_tiles_3.svg';
import * as screen_tiles_4 from 'images/screen_tiles_4.svg';
import React from 'react';
import { useSelector } from 'react-redux';
import { sfuAccount } from 'selectors/accountSelectors';
import {
  ACTIVE_SPEAKER_IMAGES,
  GALLERY_IMAGES,
  LEGACY_IMAGES,
  PANEL_VIEW_IMAGES,
  SFU_LAYOUT_TYPES
} from 'constants/sfuConstants';
import LayoutOption from './MeetingLayoutOption';
import { FEATURE_FLAG_ADVANCED_SFU_LAYOUTS } from 'constants/featureFlags';

export interface Props {
  setFieldValue: (key: string, value: number) => void;
  meetingLayout: number;
}

const MeetingLayoutSchema = ({setFieldValue, meetingLayout}: Props) => {
  const sfuEnabled = useSelector(sfuAccount);
  const showAdvancedSfuLayouts = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ADVANCED_SFU_LAYOUTS);

  if (sfuEnabled) {
    if (showAdvancedSfuLayouts) {
      return (
        <>
          <LayoutOption
            images={LEGACY_IMAGES}
            layoutType={SFU_LAYOUT_TYPES.LEGACY}
            setFieldValue={setFieldValue}
            meetingLayout={meetingLayout}
            label={intl.get('classicView')}
          />
          <LayoutOption
            images={GALLERY_IMAGES}
            layoutType={SFU_LAYOUT_TYPES.GALLERY}
            setFieldValue={setFieldValue}
            meetingLayout={meetingLayout}
            label={intl.get('galleryView')}
          />
          <LayoutOption
            images={ACTIVE_SPEAKER_IMAGES}
            layoutType={SFU_LAYOUT_TYPES.SPEAKER}
            setFieldValue={setFieldValue}
            meetingLayout={meetingLayout}
            label={intl.get('activeSpeaker')}
          />
          <LayoutOption
            images={PANEL_VIEW_IMAGES}
            layoutType={SFU_LAYOUT_TYPES.PANEL}
            setFieldValue={setFieldValue}
            meetingLayout={meetingLayout}
            label={intl.get('panelView')}
          />
        </>
      );
    }

    return (
      <>
        <uiForm.Field className={styles.optionGroup}>
          <Checkbox
            checked={meetingLayout === nucleusConstants.MEETING_LAYOUT_TYPES.DEFAULT}
            label={intl.get('naturalProgression')}
            name="meetingLayout"
            onChange={() => setFieldValue('meetingLayout', nucleusConstants.MEETING_LAYOUT_TYPES.DEFAULT)}
            radio={true}
            value={nucleusConstants.MEETING_LAYOUT_TYPES.DEFAULT}
          />
        </uiForm.Field>
        <div className={styles.layoutImages}>
          <img src={screen_main_only}/>
          <img src={screen_main_plus_4}/>
          <img src={screen_main_plus_7}/>
          <img src={screen_main_plus_12}/>
        </div>
        <uiForm.Field className={styles.optionGroup}>
          <Checkbox
            checked={meetingLayout === nucleusConstants.MEETING_LAYOUT_TYPES.ALTERNATIVE}
            label={intl.get('gallery')}
            name="meetingLayout"
            onChange={() => setFieldValue('meetingLayout', nucleusConstants.MEETING_LAYOUT_TYPES.ALTERNATIVE)}
            radio={true}
            value={nucleusConstants.MEETING_LAYOUT_TYPES.ALTERNATIVE}
          />
        </uiForm.Field>
        <div className={styles.layoutImages}>
          <img src={screen_main_only}/>
          <img src={screen_tiles_2}/>
          <img src={screen_tiles_3}/>
          <img src={screen_tiles_4}/>
          <img src={screen_gallery_plus_7}/>
          <img src={screen_gallery_plus_9}/>
          <img src={screen_gallery_plus_16}/>
          <img src={screen_gallery_plus_25}/>
        </div>
      </>
    );
  }

  return (
    <>
      <uiForm.Field className={styles.optionGroup}>
        <Checkbox
          checked={meetingLayout === nucleusConstants.MEETING_LAYOUT_TYPES.DEFAULT}
          label={intl.get('inputLabelOption1')}
          name="meetingLayout"
          onChange={() => setFieldValue('meetingLayout', nucleusConstants.MEETING_LAYOUT_TYPES.DEFAULT)}
          radio={true}
          value={nucleusConstants.MEETING_LAYOUT_TYPES.DEFAULT}
        />
      </uiForm.Field>
      <div className={styles.layoutImages}>
        <img src={screen_main_only}/>
        <img src={screen_main_plus_4}/>
        <img src={screen_main_plus_7}/>
        <img src={screen_main_plus_12}/>
      </div>
      <uiForm.Field className={styles.optionGroup}>
        <Checkbox
          checked={meetingLayout === nucleusConstants.MEETING_LAYOUT_TYPES.ALTERNATIVE}
          label={intl.get('inputLabelOption2')}
          name="meetingLayout"
          onChange={() => setFieldValue('meetingLayout', nucleusConstants.MEETING_LAYOUT_TYPES.ALTERNATIVE)}
          radio={true}
          value={nucleusConstants.MEETING_LAYOUT_TYPES.ALTERNATIVE}
        />
      </uiForm.Field>
      <div className={styles.layoutImages}>
        <img src={screen_main_only}/>
        <img src={screen_tiles_2}/>
        <img src={screen_tiles_3}/>
        <img src={screen_tiles_4}/>
        <img src={screen_main_plus_7}/>
        <img src={screen_main_plus_12}/>
      </div>
    </>
  );
};

export default MeetingLayoutSchema;
