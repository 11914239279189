import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import * as styles from './RoleManagement.scss';
import { RoleRow } from './RoleRow';
import { Role, ROLE_PERMISSION } from 'interfaces/Role';
import { isAdminRole, mapPermissionsToSettings } from 'utils/permissionsUtils';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';

export interface Props {
  role: Role;
}

export const RoleManagement = (props: Props) => {
  const { role } = props;
  const { permissions } = role;

  const isAdmin = isAdminRole(role);
  const canEditRoles = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROLES];

  const settings = useMemo(
    () => {
      if (permissions) {
        return mapPermissionsToSettings(permissions);
      }
      return null;
    },
    [permissions]
  );

  return (
    <div className={styles.container}>
      <h4 className={styles.header}>{intl.get('accountManagement')}</h4>
      {settings && <div className={styles.roleTable}>
        <div className={styles.roleRow}>
          <div />
          <div>{intl.get('view')}</div>
          <div>{intl.get('edit')}</div>
        </div>
        {settings.map((setting) => (
          <RoleRow
            role={role}
            key={setting.title}
            setting={setting}
            disabled={isAdmin || !canEditRoles}
          />
        ))}
      </div>}
    </div>
  );
};
