import { ToastId } from 'react-toastify';

export interface DownloadNotificationInterface {
  notificationId: ToastId | undefined;
  pendingDownloads: Set<string>;
  downloadCounter: number;
}

export const initialState: DownloadNotificationInterface = {
  notificationId: undefined,
  pendingDownloads: new Set(),
  downloadCounter: 0
};

export default initialState;
