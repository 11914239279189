import { createSelector } from 'reselect';
import { Global } from 'state/Global';

/**
 * Direct selector to the drilldown state domain
 */
const selectSelectionState = (state: Global) => state.app?.selection;

const selectSelection = createSelector(
  selectSelectionState,
  (selection) => selection?.selection
);

const selectIsSelectAll = createSelector(
  selectSelectionState,
  (selection) => selection?.selectAll
);

export {
  selectSelectionState,
  selectSelection,
  selectIsSelectAll,
};
