import React, { useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest, createRequest, deleteRequest } from 'actions/mergeAccountActions';
import * as formStyles from 'components/Forms.scss';
import * as styles from 'components/Settings/Sections.scss';
import { Form, Formik, Field } from 'formik';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import _get from 'lodash/get';
import { useMount } from 'hooks/useMount';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import MergeAccountsConfirmationModal from './RequestMergeAccountsConfirmationModal/RequestMergeAccountsConfirmationModal';
import MergeAccountsCancelConfirmationModal from './RequestMergeAccountsCancelConfirmationModal/RequestMergeAccountsCancelConfirmationModal';
import {
  mergeRequestLoadingSelector,
  mergeRequestSelector,
  mergeRequestErrorSelector
} from 'selectors/mergeAccountSelectors';

export interface ConfirmationObject {
  email: string;
  setSubmitting: (value: boolean) => void;
  resetForm: (values?: object) => void;
}

const getSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email(intl.get('emailInvalid'))
      .required(intl.get('emailRequired'))
  });
};

const MergeAccountsComponent = () => {
  const dispatch = useDispatch();
  const isMounted = useMount();
  const loading = useSelector(mergeRequestLoadingSelector);
  const mergeRequest = useSelector(mergeRequestSelector);
  const hasServiceError = useSelector(mergeRequestErrorSelector);
  const [isMergeConfirmOpen, setIsMergeConfirmOpen] = useState(false);
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);
  const [confirm, setConfirm] = useState<ConfirmationObject | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(false);

  async function fetchMergeRequest() {
    await dispatch(getRequest());
  }

  const handleSend = async (confirmObj: ConfirmationObject) => {
    setError(null);
    setIsMergeConfirmOpen(false);
    const result = await dispatch(createRequest(confirmObj.email));
    if (_get(result, 'type') === createRequest.fulfilled.type && _get(result, 'payload.statusCode') < 300) {
      // successful merge request
      fetchMergeRequest();
    } else {
      // failed merge request
      setError(intl.get('mergeAccountsFailure'));
      confirmObj.resetForm({
        email: confirmObj.email
      });
      confirmObj.setSubmitting(false);
      setConfirm(null);
    }
  };

  const handleClose = (confirmObj: ConfirmationObject) => {
    confirmObj.setSubmitting(false);
    setConfirm(null);
    setIsMergeConfirmOpen(false);
  };

  const handleCancel = async (yesDelete: boolean) => {
    setIsBusy(true);
    setIsCancelConfirmOpen(false);
    if (yesDelete) {
      await dispatch(deleteRequest());
      fetchMergeRequest();
      if (isMounted.current) {
        setIsBusy(false);
      }
    }
  };

  const getContent = () => {
    if (loading) {
      return <LoadingIndicator/>;
    }
    if (hasServiceError) {
      return (
        <div className={styles.description}>
          {intl.get('mergeAccountsServiceDown')}
        </div>
      );
    }
    if (mergeRequest) {
      return (
        <>
          <div className={styles.description}>
            {intl.get('mergeAccountsSuccess', { email: _get(mergeRequest, 'targetAdminEmail') })}
          </div>
          <div className={styles.formSection}>
            <div className={styles.buttonContainer}>
              <Button
                onClick={() => setIsCancelConfirmOpen(true)}
                role="button"
                type="button"
                loading={isBusy}
              >
                {intl.get('cancelRequest')}
              </Button>
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles.description}>
          {intl.get('mergeAccountsDescription')}
        </div>
        <Formik
          validationSchema={getSchema()}
          enableReinitialize={true}
          initialValues={{
            email: ''
          }}
          onSubmit={(formData, { setSubmitting, resetForm }) => {
            setConfirm({
              email: formData.email,
              setSubmitting,
              resetForm
            });
            setIsMergeConfirmOpen(true);
          }}
          render={({dirty, errors, isSubmitting, isValid, touched}) => (
            <Form>
              <div className={classnames(styles.formSection, styles.horizontal)}>
                <div className={styles.inputContainer}>
                  <Field
                    className={styles.inputEmail}
                    type="text"
                    name="email"
                    placeholder={intl.get('mergeAccountsEmailPlaceholder')}
                  />
                  {touched && errors && touched.email && errors.email && (
                    <div className={formStyles.messageContainer}>
                      <div className={formStyles.error}>{errors.email}</div>
                    </div>
                  )}
                  {!dirty && error && (
                    <div className={formStyles.messageContainer}>
                      <div className={formStyles.error}>
                        {error}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    data-test="sectionBtnSubmit"
                    disabled={!dirty || isSubmitting || !isValid}
                    name="btnSubmitMergeAccounts"
                    role="button"
                    type="submit"
                    loading={isSubmitting}
                  >
                    {intl.get('mergeAccountsSendRequest')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
      </>
    );
  };

  return (
    <section className={styles.container}>
      <article>
        <h6 className={classnames(styles.title, styles.headerRow)} data-test="sectionTitle">
          {intl.get('mergeAccounts')}
        </h6>
        {getContent()}
        {isMergeConfirmOpen && confirm &&
          <MergeAccountsConfirmationModal
            confirm={confirm}
            onSend={handleSend}
            onClose={handleClose}
          />
        }
        {isCancelConfirmOpen && <MergeAccountsCancelConfirmationModal handleResponse={handleCancel} />}
      </article>
    </section>
  );
};

export default MergeAccountsComponent;
