import React from 'react';
import styles from './Badge.scss';

interface Props {
  className?: string;
  count: number;
}

const Badge = (props: Props) => {
  const { className, count = 0 } = props;
  const completeClassName = className ? `${styles.badge} ${className}` : styles.badge;
  return (
    <div className={completeClassName}>
      <span>{count > 99 ? '99+' : count}</span>
      </div>
  );
};

export default Badge;
