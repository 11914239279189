import classnames from 'classnames';
import Stripe from 'stripe';
import * as styles from '../Sections.scss';
import intl from 'react-intl-universal';
import _get from 'lodash/get';
import { formatUnixDateForDisplay, secondsToMilliseconds } from 'utils/date-time-utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelectors, featureSelectors } from '@lifesize/nucleus';
import { selectSubscription } from 'selectors/ecommerceSelector';
import { LabelData } from './LabelData';
import { handlePortalRedirect } from 'utils/ecommerceUtils';
import { Logger } from 'logger';
import { Loader } from 'semantic-ui-react';
import { FEATURE_FLAG_BILLING_PORTAL } from 'constants/featureFlags';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export interface Props {
  setIsCancelConfirmOpen: (setting: boolean) => void;
  setIsReactivateConfirmOpen: (setting: boolean) => void;
}

const getSubscriptionPlanName = (subscription: Stripe.Subscription | undefined) => {
  return _get(subscription, 'plan.nickname')
    ? _get(subscription, 'plan.nickname')
    : _get(subscription, 'items.data[0].plan.nickname');
};

export const EcommerceDetails = (props: Props) => {
  const { setIsCancelConfirmOpen, setIsReactivateConfirmOpen } = props;
  const jwt = useSelector(authSelectors.getPrimaryJwt);
  const subscription = useSelector(selectSubscription);
  const [ portalUrlLoading, setPortalUrlLoading ] = useState(false);
  const [ portalError, setPortalError ] = useState(false);
  const isSubscriptionCanceled = subscription?.cancel_at;
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const showCustomerBillingPortal = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_BILLING_PORTAL);

  return (
    <article>
      <h6 className={classnames(styles.title, styles.headerRow)} data-test="sectionTitle">
        {intl.get('subscriptionDetails')}
      </h6>
      <div className={styles.flexSubscriptionRow}>
        <LabelData
          label={intl.get('subscriptionPlan')}
        >
          {getSubscriptionPlanName(subscription)}
        </LabelData>
        <LabelData
          label={intl.get('status')}
        >
          {intl.get(subscription?.status || 'trialing')}
        </LabelData>
        <LabelData
          label={intl.get('nextBillingDate')}
        >
          {isSubscriptionCanceled ? intl.get('not-applicable') : formatUnixDateForDisplay(secondsToMilliseconds(subscription?.current_period_end || 0))}
        </LabelData>
        <div className={styles.subscriptionButtons}>
          {isSubscriptionCanceled ? (
            canEditSettings && <button
              id="btnReactivateSubscription"
              name="btnReactivateSubscription"
              onClick={() => setIsReactivateConfirmOpen(true)}
              type="button"
              className={styles.buttonLink}
            >
              {intl.get('reactivateSubscription')}
            </button>
          ) : (
            canEditSettings && <button
              id="btnCancelSubscription"
              name="btnCancelSubscription"
              onClick={() => setIsCancelConfirmOpen(true)}
              type="button"
              className={styles.buttonLink}
            >
              {intl.get('cancelSubscription')}
            </button>
          )}
          {showCustomerBillingPortal && canEditSettings && <button
            id="btnCustomerBillingPortal"
            name="btnCustomerBillingPortal"
            onClick={async () => {
              try {
                setPortalError(false);
                setPortalUrlLoading(true);
                await handlePortalRedirect(jwt);
                setPortalUrlLoading(false);
              } catch (e) {
                Logger.info(`Unable to generate portal redirect url: ${e}`);
                setPortalError(true);
                setPortalUrlLoading(false);
              }
            }}
            type="button"
            className={styles.buttonLink}
          >
            {portalUrlLoading ? <Loader size={'mini'} active={true} inline={true} style={{ minWidth: '150px' }}/> : intl.get('viewEditBillingDetails')}
          </button>}
          {portalError && <div className={styles.portalError}>{intl.get('billingPortalError')}</div>}
        </div>
      </div>
      {isSubscriptionCanceled && (
        <div className={styles.subscriptionCanceledStatement}>
          {intl.get('subscriptionHasBeenCanceled', {
            date: formatUnixDateForDisplay(secondsToMilliseconds(subscription?.cancel_at || 0))
          })}
        </div>
      )}
    </article>
  );
};
