import React, { SyntheticEvent } from 'react';
import intl from 'react-intl-universal';
import { Field, Formik, Form } from 'formik';
import get from 'lodash/get';
import * as styles from 'components/Settings/Sections.scss';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

// props passed in
export interface OwnProps {
  chatEnabled: boolean;
  updateSection: Function;
}

const Chat = (props: OwnProps) => {
  const { updateSection, chatEnabled } = props;
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const onSubmit = (formData: object, callback: Function) => {
    updateSection(get(formData, 'enableChat'), callback);
  };

  return (
    <section className={styles.container} data-test="settingsSection.chat">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitleChat')}</h6>
        <Formik
          enableReinitialize={true}
          initialValues={{
            enableChat: chatEnabled,
          }}
          onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => setSubmitting(false))}
          render={({dirty, errors, handleChange, submitForm, touched, values}) => (
            <Form>
              <div className={styles.formSection}>
                <Field
                  component={ToggleButton}
                  name="enableChat"
                  id="enableChat"
                  label={intl.get('inputLabelToggleChat')}
                  value={values.enableChat}
                  disabled={!canEditSettings}
                  onChange={async (e: SyntheticEvent) => {
                    if (!canEditSettings) { return; }
                    // important: await handleChange() before submitForm() (or submitForm() will not fire)
                    await handleChange({ target: { name: 'enableChat', value: get(e.target, 'checked') }});
                    submitForm();
                  }}
                  {...{ ['data-test']: 'settingsSectionToggle.chat' }}
                />
              </div>
            </Form>
          )}
        />
      </article>
    </section>
  );
};

export default Chat;
