import React from 'react';
import Icon from 'components/Common/Icon';
import * as styles from './SummaryBox.scss';

interface Props {
  dataPoint: string;
  label: string;
  type: 'calls' | 'minutes' | 'active' | 'avg';
}

const iconClass = {
  active: 'icon-meeting',
  avg: 'icon-audio-call-pstn',
  calls: 'icon-video',
  minutes: 'icon-clock-o'
};

const SummaryBox = ({ dataPoint, type, label }: Props) => {
  return (
    <div className={`${styles.container} ${styles[type]}`}>
      <div className={styles.dataPoint}>
        {dataPoint}
      </div>
      <div className="stacked">
        {label}
      </div>
      <Icon classes={[iconClass[type], styles.icon]} />
    </div>
  );
};

export default SummaryBox;
