import React, { SyntheticEvent, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Dropdown } from 'semantic-ui-react';
import classnames from 'classnames';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { Tooltip } from 'components/tooltip/Tooltip';
import CopyToClipboard from 'components/CopyToClipboardButton/CopyToClipboardButton';
import Icon from 'components/Common/Icon';
import { permissions } from 'constants/ViewingPermissionConstants';
import { MEETING_FORM_FIELD } from '../FormFields';
import * as formStyles from 'components/Modals/ModalForms.scss';
import * as styles from './StreamingTab.scss';
import { dataWarehouseSelectors, vcApiActions } from '@lifesize/nucleus';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useMount } from 'hooks/useMount';
import { StreamProps } from 'interfaces/Meeting';
import _get from 'lodash/get';
import uuid4 from 'uuid4';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';
import { Field } from 'formik';
import { selectAccountPermissions } from '../../../../selectors/permissionsSelector';
import { ROLE_PERMISSION } from '../../../../interfaces/Role';

interface Props {
  isSubmitting: boolean;
  setFieldValue: (streamingSettings: object, keepOpen?: boolean) => void;
  resetState: Function;
  passcodeError?: string | false;
  uuid?: string;
}

interface ToggleProps {
  type: string;
  value: number | boolean;
  onChange: (newValue: boolean) => void;
  label: string;
  classNameLabel?: string;
  disabled?: boolean;
}

const ToggleComponent = (props: ToggleProps) => {
  const { type, value, onChange, classNameLabel, label, disabled } = props;
  return (
    <ToggleButton
      classNameLabel={classNameLabel ? classNameLabel : styles.toggleLabelLarge}
      id={type}
      label={label}
      leftLabel={true}
      hidenow={false}
      topLabel={false}
      disabled={disabled}
      field={{
        name: MEETING_FORM_FIELD.STREAMING_ENABLED,
        value: value,
        onChange: (newValue: SyntheticEvent) => {
          onChange(_get(newValue.target, 'checked'));
        },
        onBlur: () => {return; }
      }}
    />
  );
};

const getEmbedCode = (url: string) => {
  return `<iframe width=\"560\" height=\"315\" src=\"${url}\" frameborder=\"0\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>`;
};

const StreamingTab = (props: Props) => {
  const { uuid, setFieldValue, resetState, passcodeError, isSubmitting } = props;
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  const permissionsOptions = [
    { key: permissions.PUBLIC, value: permissions.PUBLIC, text: intl.get('public') },
    { key: permissions.PIN, value: permissions.PIN, text: intl.get('private') },
    { key: permissions.GROUP, value: permissions.GROUP, text: intl.get('groupOnly') }
  ];

  const dispatch = useDispatch();
  const isMounted = useMount();
  const [loading, setLoading] = useState(true);
  const [refreshingStreamingToken, setRefreshingStreamingToken] = useState(false);
  const [refreshingEmbedCode, setRefreshingEmbedCode] = useState(false);

  const streamsSettingsState = useSelector(dataWarehouseSelectors.streamSettings);
  const selectorSettings = _pick(streamsSettingsState, [
    MEETING_FORM_FIELD.STREAMING_ENABLED,
    MEETING_FORM_FIELD.STREAMING_PERMISSIONS,
    MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE,
    MEETING_FORM_FIELD.STREAMING_QUESTIONS_AUTO_APPROVE,
    MEETING_FORM_FIELD.STREAMING_QUESTIONS_ENABLED,
    MEETING_FORM_FIELD.STREAMING_TOKEN,
    MEETING_FORM_FIELD.STREAMING_URL,
    MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN,
    MEETING_FORM_FIELD.STREAMING_EMBED_URL
  ]);

  const [streamSettings, setStreamSettings] = useState<StreamProps>({
    [MEETING_FORM_FIELD.STREAMING_ENABLED]: false,
    [MEETING_FORM_FIELD.STREAMING_PERMISSIONS]: permissions.PUBLIC,
    [MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE]: '',
    [MEETING_FORM_FIELD.STREAMING_QUESTIONS_AUTO_APPROVE]: false,
    [MEETING_FORM_FIELD.STREAMING_QUESTIONS_ENABLED]: false,
    [MEETING_FORM_FIELD.STREAMING_TOKEN]: uuid4(),
    [MEETING_FORM_FIELD.STREAMING_URL]: '',
    [MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN]: uuid4(),
    [MEETING_FORM_FIELD.STREAMING_EMBED_URL]: '',
  });

  const refreshStreamingToken = async () => {
    if (!uuid || !canEditMeetings) { return; }
    setRefreshingStreamingToken(true);
    await dispatch(vcApiActions.setStreamingSettings(uuid, {...selectorSettings, [MEETING_FORM_FIELD.STREAMING_TOKEN]: uuid4()}));
  };

  const refreshStreamingEmbedToken = async () => {
    if (!uuid || !canEditMeetings) { return; }
    setRefreshingEmbedCode(true);
    await dispatch(vcApiActions.setStreamingSettings(uuid, {...selectorSettings, [MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN]: uuid4()}));
  };

  const getInputProperties = (settings: StreamProps) => {
    return _omit(settings, [
      MEETING_FORM_FIELD.STREAMING_TOKEN,
      MEETING_FORM_FIELD.STREAMING_URL,
      MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN,
      MEETING_FORM_FIELD.STREAMING_EMBED_URL
    ]);
  };

  const handleChange = (newStreamSettings: StreamProps, keepOpen: boolean = false) => {
    if (!canEditMeetings) { return; }
    setStreamSettings(newStreamSettings);
    const isDirty = !_isEqual(getInputProperties(newStreamSettings), getInputProperties(selectorSettings));
    if (isDirty) {
      setFieldValue(newStreamSettings, keepOpen);
    } else {
      resetState();
    }
  };

  useEffect(() => {
    const getStreamingSettings = async () => {
      try {
        if (uuid) {
          await dispatch(vcApiActions.getStreamingSettings(uuid));
          if (isMounted.current) {
            setLoading(false);
          }
        }
      } catch (e) {
        setLoading(false);
      }
    };

    getStreamingSettings();
  },        [uuid]);

  useEffect(
    () => {
      // Reset the url and token to selector state if there's a new token
      if (!loading) {
        setStreamSettings({
          ...streamSettings,
          [MEETING_FORM_FIELD.STREAMING_TOKEN]: selectorSettings[MEETING_FORM_FIELD.STREAMING_TOKEN],
          [MEETING_FORM_FIELD.STREAMING_URL]: selectorSettings[MEETING_FORM_FIELD.STREAMING_URL],
          [MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN]: selectorSettings[MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN],
          [MEETING_FORM_FIELD.STREAMING_EMBED_URL]: selectorSettings[MEETING_FORM_FIELD.STREAMING_EMBED_URL],
        });
        setRefreshingStreamingToken(false);
        if (selectorSettings[MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN]) {
          setRefreshingEmbedCode(false);
        } else if (selectorSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS] === permissions.PUBLIC) {
          // If no token is set, then set embed token
          refreshStreamingEmbedToken();
        }
      }
    },
    [
      loading,
      selectorSettings[MEETING_FORM_FIELD.STREAMING_TOKEN],
      selectorSettings[MEETING_FORM_FIELD.STREAMING_EMBED_TOKEN],
      selectorSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS]
    ]
  );

  useEffect(() => {
    // Reset to selector state on initial load
    if (!loading) {
      setStreamSettings(selectorSettings);
      setRefreshingStreamingToken(false);
      resetState();
    }
  },        [loading]);

  const getEmbedContent = () => {
    // display embed code
    if (streamSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS] === permissions.PUBLIC
      && selectorSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS] === permissions.PUBLIC) {
      const embedCode = getEmbedCode(streamSettings[MEETING_FORM_FIELD.STREAMING_EMBED_URL]);
      return (
        <dd className={styles.embedDdContainer}>
          {refreshingEmbedCode
            ? <LoadingIndicator />
            : (
              <div className={styles.embedCodeContainer}>
                {embedCode}
              </div>
            )
          }
          <div className={styles.buttonContainer}>
            <Tooltip text={intl.get('embedCopyCode')}>
              <CopyToClipboard text={embedCode} disabled={refreshingEmbedCode} />
            </Tooltip>
            {canEditMeetings && <Tooltip text={intl.get('embedRefreshCode')}>
              <button
                className={styles.button}
                disabled={refreshingEmbedCode}
                name="btnRefreshEmbedCode"
                onClick={refreshStreamingEmbedToken}
                role="button"
                type="button"
              >
                <Icon classes={['icon-loader']}/>
              </button>
            </Tooltip>}
          </div>
        </dd>
      );
    }
    // if permissions is dirty and set to public
    if (streamSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS] === permissions.PUBLIC
      && selectorSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS] !== permissions.PUBLIC) {
      return (
        <dd className={styles.embedDdContainer}>
          {intl.get('embedOnlyForPublicStreamsSelectSave')}
        </dd>
      );
    }
    // if permissions is not public
    return (
      <dd className={styles.embedDdContainer}>
        {intl.get('embedOnlyForPublicStreams')}
      </dd>
    );
  };

  const getStreamingLinkAndEmbedContent = () => {
    if (streamSettings[MEETING_FORM_FIELD.STREAMING_ENABLED]) {
      if (streamSettings[MEETING_FORM_FIELD.STREAMING_URL]) {
        return (
          <div className={formStyles.formGroup}>
            <dl className={styles.streamingLink}>
              <dt>{intl.get('streamingLink')}</dt>
              <dd>
                {refreshingStreamingToken
                  ? <LoadingIndicator />
                  : <a href={streamSettings[MEETING_FORM_FIELD.STREAMING_URL]} target="_blank">{streamSettings[MEETING_FORM_FIELD.STREAMING_URL]}</a>
                }
                <div className={styles.buttonContainer}>
                  <Tooltip text={intl.get('copyLink')}>
                    <CopyToClipboard text={streamSettings[MEETING_FORM_FIELD.STREAMING_URL]} disabled={refreshingStreamingToken} />
                  </Tooltip>
                  {canEditMeetings && <Tooltip text={intl.get('refreshLink')}>
                    <button
                      className={styles.button}
                      disabled={refreshingStreamingToken}
                      name="btnRefresh"
                      onClick={refreshStreamingToken}
                      role="button"
                      type="button"
                    >
                      <Icon classes={['icon-loader']}/>
                    </button>
                  </Tooltip>}
                </div>
              </dd>
              <dt>{intl.get('embed')}</dt>
              {getEmbedContent()}
            </dl>
          </div>
        );
      }
      if (isSubmitting) {
        return <LoadingIndicator />;
      }
    }
    return null;
  };

  if (loading) {
    return (
      <div className={classnames(styles.container, styles.loadingContainer)}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={styles.container}>

      {/* Logical group 1 */}
      <div className={formStyles.logicalGroup}>
        <div className={formStyles.formGroup}>
          <ToggleComponent
            type={MEETING_FORM_FIELD.STREAMING_ENABLED}
            label={intl.get('enableLiveStreaming')}
            value={streamSettings[MEETING_FORM_FIELD.STREAMING_ENABLED]}
            disabled={!canEditMeetings}
            onChange={(newValue: boolean) => {
              handleChange({...streamSettings, [MEETING_FORM_FIELD.STREAMING_ENABLED]: newValue}, !streamSettings[MEETING_FORM_FIELD.STREAMING_URL]);
            }}
          />
        </div>
        {getStreamingLinkAndEmbedContent()}
      </div>

      {(streamSettings[MEETING_FORM_FIELD.STREAMING_ENABLED]) &&
      <>
        {/* Logical group 2 */}
        <div className={formStyles.logicalGroup}>
          <div className={formStyles.formGroup}>
            <ToggleComponent
              label={intl.get('enableQuestions')}
              type={MEETING_FORM_FIELD.STREAMING_QUESTIONS_ENABLED}
              value={streamSettings[MEETING_FORM_FIELD.STREAMING_QUESTIONS_ENABLED]}
              disabled={!canEditMeetings}
              onChange={(newValue: boolean) => {
                handleChange({...streamSettings, [MEETING_FORM_FIELD.STREAMING_QUESTIONS_ENABLED]: newValue});
              }}
            />
          </div>

          <div className={formStyles.formGroup}>
            <ToggleComponent
              label={intl.get('autoApproveQuestions')}
              classNameLabel={styles.toggleLabelMedium}
              type={MEETING_FORM_FIELD.STREAMING_QUESTIONS_AUTO_APPROVE}
              value={streamSettings[MEETING_FORM_FIELD.STREAMING_QUESTIONS_AUTO_APPROVE]}
              disabled={!canEditMeetings}
              onChange={(newValue: boolean) => {
                handleChange({...streamSettings, [MEETING_FORM_FIELD.STREAMING_QUESTIONS_AUTO_APPROVE]: newValue});
              }}
            />
          </div>
        </div>

        {/* Logical group 3 */}
        <div className={formStyles.logicalGroup}>
          <div className={formStyles.formGroup}>
              <label className={classnames(formStyles.labelLarge, formStyles.labelStrong)}>{intl.get('Permissions')}</label>
          </div>
          <div className={classnames(formStyles.formGroup, styles.shortGroup)}>
            <label className={formStyles.labelMedium}>{intl.get('viewingPermissions')}</label>
            <div className={formStyles.inputContainer}>
              <Dropdown
                className={formStyles.input}
                closeOnBlur={true}
                fluid={false}
                name={MEETING_FORM_FIELD.STREAMING_PERMISSIONS}
                disabled={!canEditMeetings}
                onChange={(e, { value }: { value: permissions }) => {
                  handleChange({...streamSettings, [MEETING_FORM_FIELD.STREAMING_PERMISSIONS]: value}, value === permissions.PUBLIC);
                }}
                options={permissionsOptions}
                selection={true}
                value={streamSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS]}
              />
            </div>
          </div>

          {(streamSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS] === permissions.PIN) &&
          <div className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
            <label className={formStyles.labelMedium}>{intl.get('passCode')}<span className={styles.footnote}>*</span></label>
            <div className={formStyles.inputContainer}>
              <input
                className={formStyles.input}
                type="text"
                name={MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE}
                disabled={!canEditMeetings}
                onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange({...streamSettings, [MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE]: value });
                }}
                value={streamSettings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE]}
              />
              <ul className={formStyles.inputMessages}>
                {passcodeError && (
                  <li className={formStyles.error}>{passcodeError}</li>
                )}
              </ul>
            </div>
          </div>
          }
        </div>
      </>
      }
      <Field
        type="hidden"
        name={MEETING_FORM_FIELD.STREAMING_STATE}
        value={undefined}
      />
      <Field
        type="hidden"
        name={MEETING_FORM_FIELD.STREAMING_PERMISSIONS}
        value={undefined}
      />
      <Field
        type="hidden"
        name={MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE}
        value={undefined}
      />
    </div>
  );
};

export default StreamingTab;
