import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _get from 'lodash/get';
import RoomSystem from 'components/RoomSystem/RoomSystem';
import { canConnectDevice, isPlatformIcon, transformRoomSystemData } from 'utils/roomSystemUtils';
import getRoomSystemQuery from 'queries/roomSystems/getRoomSystemQuery.gql';

interface ComponentProps {
  match: {
    params: {
      id: string
    };
  };
}

export default withRouter(compose(
  connect(
    () => ({}),
    () => ({}),
  ),
  graphql(getRoomSystemQuery, {
    options: (props: ComponentProps) => {
      return {
        fetchPolicy: 'network-only',
        variables : { serialNumber: props.match.params.id }
      };
    },
    // tslint:disable-next-line
    props: ({ data, ownProps }: any) => {
      const hasMonitoringData = !!_get(data, 'getRoomSystem.deviceMonitoring.metrics');
      const hasMaintenanceTab = isPlatformIcon(_get(data, 'getRoomSystem.platform'));
      const roomSystem = _get(data, 'getRoomSystem');
      const mappedRoomSystem = roomSystem ? transformRoomSystemData(roomSystem) : roomSystem;
      const mappedData = {
        ...data,
        getRoomSystem: mappedRoomSystem,
      };
      return {
        ...ownProps,
        data: mappedData,
        displayName: _get(data, 'getRoomSystem.displayName', ''),
        roomSystem: mappedRoomSystem,
        serialNumber: _get(ownProps.match, 'params.id'),
        hasMaintenanceTab,
        canConnectTabletToIcon: canConnectDevice(_get(data, 'getRoomSystem', {})),
        hasMonitoringData,
        deviceMonitoring: _get(data, 'getRoomSystem.deviceMonitoring'),
      };
    },
  }),
)(RoomSystem));
