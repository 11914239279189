import React, { useEffect, useState } from 'react';
import ReactTable, { Column, CellInfo, SortingRule, RowInfo } from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import compact from 'lodash/compact';
import TableNoData from 'components/Common/TableNoData';
import { Checkbox } from 'components/RoomSystems/Checkbox/Checkbox';
import { getTableBody } from './TableBody';
import classes from './InfiniteTable.scss';
import classnames from 'classnames';
import _has from 'lodash/has';
import { getLoaderCellRenderer } from 'components/Common/ScrollMessage/ScrollMessageRenderer';
import { genericColumnConfig, genericListData } from 'components/InfiniteTable/GenericColumnConfig';
import { actions as selectionActions } from '../../actions/selectionActions';
import { useDispatch } from 'react-redux';

const CheckboxTable = checkboxHOC(ReactTable);

// props from Redux Store (mapStateToProps)
export interface StateProps {
  allRowsSelected: boolean;
  hasSearchString: boolean; // ...otherProps
  pageNumber: number; // ...otherProps
  selectionArray: Array<string>;
}

// props passed in
export interface OwnProps {
  NoDataComponent?: React.ReactType;
  noDataText?: string;
  columnConfig: Array<Column>;
  data: object[]; // The array of data to render.
  defaultSort?: SortingRule[];
  enableSelectAll?: boolean; // If true, table will have select all checkbox in header
  /**
   * Function to call to return the next 'page' of results for the table.
   * This needs to return a promise so that Infinite table can correctly track
   * fetch state of the request.
   * TODO: Currently this is bound at construction, so if you're dynamically
   * changing this method after the initial mount, we'll need to update this
   * component.
   */
  fetchMore?: () => Promise<{}>;
  getTdProps?: (args: {}) => void;
  getTheadTrProps?: (args: {}) => void;
  getTrProps?: (args: {}) => void;
  hasMoreRecords?: boolean;
  keyField: string; // The field on each row to use as a unique key.
  loading?: boolean;
  onFetchData?: (args: {}) => void;
  renderHeader?: (selectionArray: Array<string>) => React.ReactNode;
  rowHeightOverride?: number;
  enableSelect: boolean;
  sortable?: boolean;
  manual?: boolean;
  usePageSize?: boolean;
  SubComponent?: (row: RowInfo) => React.ReactNode | Element;
  onExpandedChange?: Function;
  expanded?: object;
  style?: object;
  children?: string | JSX.Element | JSX.Element[];
  toggleFilter?: (key: string) => boolean;
}

type Props = StateProps & OwnProps;
let defaultComponent: React.ReactType;
const defaultRowHeight = 30;

const InfiniteTable = (props: Props) => {
  const {
    rowHeightOverride,
    data,
    fetchMore,
    NoDataComponent,
    noDataText,
    allRowsSelected,
    children,
    columnConfig,
    defaultSort,
    enableSelect = false,
    enableSelectAll,
    hasMoreRecords,
    keyField,
    loading,
    onFetchData = () => { return ; },
    renderHeader,
    selectionArray,
    sortable,
    getTrProps,
    manual,
    style,
    usePageSize,
    toggleFilter,
    ...otherProps
  } = props;

  const [hideLoading, setHideLoading] = useState(false);
  const dispatch = useDispatch();

  const toggleSelection = (key: string) => {
    if (!toggleFilter || toggleFilter(key)) {
      dispatch(selectionActions.toggleSelection(key as unknown as number));
    }
  };

  const resetSelection = () => {
    dispatch(selectionActions.resetSelection());
  };

  const toggleAll = (dataUUIDs: string[]) => {
    dispatch(selectionActions.toggleAll(dataUUIDs));
  };

  const checkTableHeight = (tableBody: HTMLDivElement) => {
    const rowHeight = rowHeightOverride || defaultRowHeight;
    const shouldHideLoading = data.length * 1.2 <= Math.ceil(tableBody.clientHeight / rowHeight);
    setHideLoading(shouldHideLoading);
  };

  const isSelected = (key: string): boolean => selectionArray.indexOf(key) > -1;

  const tableData = loading ? genericListData(50) : data;
  const loadingColumnConfig = genericColumnConfig(columnConfig);
  const tableColumnConfig = loading ? loadingColumnConfig : columnConfig;
  let hideLoadingWithOverride = manual || hideLoading;

  useEffect(
    () => {
        defaultComponent = getTableBody(
          fetchMore,
          rowHeightOverride,
          checkTableHeight,
        );
    },
    []
  );

  useEffect(
    () => {
      return () => {
        resetSelection();
      };
    },
    []
  );

  const checkboxProps = {
    isSelected,
    selectAll: allRowsSelected,
    selectType: enableSelectAll ? 'checkbox' : 'radio', // I would like a better way to do this
    // @ts-ignore
    toggleAll: () => toggleAll(tableData.map((obj, index) => obj[keyField] ? obj[keyField] : index)),
    toggleSelection
  };
  const columns = tableColumnConfig.map((col, index) => {
    const toReturn = {
      ...col,
      headerClassName: classnames(col.headerClassName, classes.tableHeaderTitle),
      className: classnames(col.className, classes.tableCell)
    };
    if (!hideLoadingWithOverride) {
      toReturn.Cell = (originalCell: CellInfo) => {
        if (_has(originalCell.original, 'loading')) {
          if (index === Math.floor(tableColumnConfig.length / 2)) { // If it's the middle column
            return getLoaderCellRenderer(originalCell); // Show the loading symbol
          } else {
            return ''; // other columns are blank
          }
        } else {
          // If not, we need to make sure we're getting the original rendered output
          if (col.Cell) { // Did they provide their own cell renderer?
            if (typeof col.Cell === 'function') { // If it was a function, call it
              const renderer = col.Cell as (originalCell: CellInfo) => string;
              return renderer(originalCell);
            } else { // If it was something other than a function, just return that
              return col.Cell;
            }
          } else {
            return originalCell.value; // Otherwise return the traditional value
          }
        }
      };
    }
    return toReturn;
  });
  // Last item in array used by table to display loading status (unless there is no tableData, then show NoDataComponent)
  let dataProvider;
  if (hideLoadingWithOverride) {
    dataProvider = (tableData && tableData.length) || hasMoreRecords ? [...tableData] : [];
  } else {
    dataProvider = (tableData && tableData.length) || hasMoreRecords ? [...tableData, ...[{ loading: hasMoreRecords }]] : [];
  }

  const noDataComponent = (componentProps: Object) => {
    return NoDataComponent ? <NoDataComponent {...componentProps} /> : <TableNoData text={noDataText} {...componentProps} />;
  };

  const tableProps = {
    NoDataComponent: noDataComponent,
    TbodyComponent: defaultComponent,
    className: compact([tableData && tableData.length && '-highlight', classes.tableContainer]).join(' '),
    columns: columns,
    data: dataProvider,
    defaultPageSize: tableData.length,
    defaultSorted: defaultSort,
    manual: !manual,
    onFetchData: onFetchData,
    showPagination: false,
    style: { flexGrow: 1 },
    sortable: sortable,
    getTrProps: getTrProps,
    ...otherProps,
    ...enableSelect && {
      SelectInputComponent: Checkbox,
      keyField: keyField,
      showPagination: false,
      ...checkboxProps
    }
  };

  if (usePageSize) {
    /* tslint:disable */
    tableProps['pageSize'] = tableData.length;
    /* tslint:enable */
  }

  const TableComponent = enableSelect ? CheckboxTable : ReactTable;
  return (
    <>
      {renderHeader && renderHeader(selectionArray)}
      <TableComponent
        {...tableProps}
        style={style}
      >
        {children}
      </TableComponent>
    </>
  );
};

// https://blog.neufund.org/why-we-have-banned-default-exports-and-you-should-do-the-same-d51fdc2cf2ad
export { InfiniteTable };
