import { createSelector } from 'reselect';
import { Global } from '../state/Global';

/**
 * Direct selector to the language state domain
 */
const selectLanguage = (state: Global) => state.app?.language;

/**
 * Select the language locale
 */
const selectLocale = createSelector(
  selectLanguage,
  (language) => language.locale
);

export {
  selectLocale
};
