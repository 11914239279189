import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from '../../actions/modalActions';
import UnPairRoomSystemsModal from '../../components/Modals/UnPairRoomSystems/UnPairRoomSystems';
import { selectSelection } from '../../selectors/selectionSelectors';

const mapStateToProps = (state: Global) => {
  return {
    selectionArray: selectSelection(state)
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async () => {
    dispatch(actions.closeModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UnPairRoomSystemsModal);
