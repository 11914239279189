import React, { useEffect, useState } from 'react';
import * as styles from './Settings.scss';
import intl from 'react-intl-universal';
import { Tab } from 'semantic-ui-react';
import Icon from 'components/Common/Icon';
import { TabProps } from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import _memoize from 'lodash/memoize';
import _invert from 'lodash/invert';
import { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import GeneralSettingsContainer from 'containers/Settings/GeneralSettingsContainer';
import AccountDetailsTabContainer from 'containers/Settings/AccountDetailsTabContainer';
import { IconSettingsContainer } from 'containers/Settings/IconSettingsContainer';
import MeetingSettingsTab from './MeetingSettingsTab/MeetingSettings';
import Sso from 'components/Settings/SsoTab/Sso';
import MergeRequests from 'components/Settings/MergeRequestsTab/MergeRequests';
import Downloads from 'components/Settings/DownloadsTab/Downloads';
import { accountSelectors } from '@lifesize/nucleus';
import { ACCOUNT_FEATURE_DEVICE_SETTINGS_ENABLED, ACCOUNT_FEATURE_SSO } from 'constants/accountFeatureConstants';
import EcommerceActions from 'actions/ecommerceActions';
import { useDispatch, useSelector } from 'react-redux';
import Badge from 'components/Badge/Badge';
import { MergeRequest, MergeRequestResponse } from 'interfaces/MergeRequest';
import { dispatchProxyApiCall } from 'utils/nucleusProxyUtils';
import { Methods } from 'interfaces/HTTP';
import logger from 'utils/logger';
import { useMount } from 'hooks/useMount';
import { selectShowUpgradeOptions } from 'selectors/accountSelectors';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import ConnectPlusGatewayContainer from 'containers/Settings/ConnectPlusGatewayContainer';
import { selectConnectPlusGatewayActive } from 'selectors/connectPlusGatewaySelectors';
import { getClient } from 'nucleus-apollo-client';
import getCalendarServiceFeature from 'queries/calendar/getCalendarServiceFeatureQuery.gql';
import CalendarAction from 'actions/calendarServiceAction';
import { selectGroupUUID } from 'selectors/userProfileSelector';
import { CalendarServiceResposne } from 'interfaces/CalendarService';

const getTitle = _memoize(() => {
  return (
    <>
      <Icon classes={['icon-settings']} />
      <span>{intl.get('accountSettings')}</span>
    </>
  );
});

enum settingsRoutes {
  SETTINGS = '/settings',
  GROUP = '/settings/group',
  MEETING = '/settings/meeting',
  DEVICES = '/settings/icon',
  DETAILS = '/settings/details',
  DOWNLOADS = '/settings/downloads',
  SSO = '/settings/sso',
  MERGE = '/settings/merge',
  CONNECTPLUS = '/settings/connectPlusGateway'
}

const tabs = [
  {
    'tab': '',
    index: 0
  },
  {
    'tab': 'details',
    index: 0
  },
  {
    'tab': 'group',
    index: 1
  },
  {
    'tab': 'meeting',
    index: 2
  },
  {
    'tab': 'icon',
    index: 3
  },
  {
    'tab': 'downloads',
    index: 4
  },
  {
    'tab': 'sso',
    index: 5
  },
  {
    'tab': 'merge',
    index: 6
  },
  {
    'tab': 'connectPlusGateway',
    index: 7
  }
];

const getInitialTab: (match: {}) => number = (match) => {
  return tabs.find((tabGroup) => tabGroup.tab = _get(match, 'params.tab', 'details'))?.index || 0;
};

const createSupportedIndices = (hasDeviceSettings: boolean, showSsoTab: boolean, showMergeTab: boolean) => {
  let tabIndex = 0;
  const supportedIndices = {
    [settingsRoutes.SETTINGS]: tabIndex,
  };
  supportedIndices[settingsRoutes.DETAILS] = tabIndex;
  supportedIndices[settingsRoutes.GROUP] = tabIndex += 1;
  supportedIndices[settingsRoutes.MEETING] = tabIndex += 1;
  if (hasDeviceSettings) {
    supportedIndices[settingsRoutes.DEVICES] = tabIndex += 1;
  }

  supportedIndices[settingsRoutes.DOWNLOADS] = tabIndex += 1;

  if (showSsoTab) {
    supportedIndices[settingsRoutes.SSO] = tabIndex += 1;
  }

  if (showMergeTab) {
    supportedIndices[settingsRoutes.MERGE] = tabIndex += 1;
  }

  supportedIndices[settingsRoutes.CONNECTPLUS] = tabIndex += 1;

  return supportedIndices;
};

const Settings = (props: RouteComponentProps<{}>) => {
  const { match, history } = props;
  const [selectedTab, setSelectedTab] = useState(getInitialTab(match));
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const hasDeviceSettings = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_DEVICE_SETTINGS_ENABLED);
  const hasSsoAddOn = useSelector(accountSelectors.getAccountFeatures)[ACCOUNT_FEATURE_SSO];
  const showUpgradeOptions = useSelector(selectShowUpgradeOptions);
  const showSsoTab = hasSsoAddOn || showUpgradeOptions;
  const showMergeTab = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const connectPlusGatewayTabActive = useSelector(selectConnectPlusGatewayActive);
  const userGroupUUID = useSelector(selectGroupUUID);
  const supportedIndices = createSupportedIndices(hasDeviceSettings, showSsoTab, showMergeTab);
  const activeIndexToKey = _invert(supportedIndices);

  const dispatch = useDispatch();
  const isMounted = useMount();

  const [mergeData, setMergeData] = useState<null | MergeRequest[]>(null);
  const [mergeLoading, setMergeLoading] = useState(true);
  const [mergeError, setMergeError] = useState<boolean>(false);
  const [getMergeUpdates, setMergeGetUpdates] = useState(true);

  useEffect(
    () => {
      const getMergeRequests = async () => {
        try {
          await dispatchProxyApiCall(
            dispatch,
            {},
            {},
            'lifesize.user.merge',
            'merge',
            Methods.GET,
            (response: MergeRequestResponse) => {
              if (!isMounted.current) { return; }
              setMergeData(response.body);
              setMergeLoading(false);
            },
            (response: MergeRequestResponse) => {
              if (!isMounted.current) { return; }
              logger.error(response.errorDescription || 'No merge request data found');
              setMergeError(true);
              setMergeLoading(false);
            }
          );
        } catch (e) {
          if (!isMounted.current) { return; }
          setMergeError(true);
          setMergeLoading(false);
        }
      };

      if (showMergeTab && getMergeUpdates) {
        getMergeRequests();
        setMergeGetUpdates(false);
      }
    },
    [getMergeUpdates]
  );

  useEffect(
    () => {
      dispatch(EcommerceActions.getEcommerceAccount({}));
      try {
        getClient(dispatch)
          .then((client) => {
            const mutationVariables: { groupUUID?: String } = { groupUUID: userGroupUUID };
            const mutationOptions = {
              mutation: getCalendarServiceFeature,
              fetchPolicy: 'no-cache',
              variables: mutationVariables
            };
            client.mutate(mutationOptions)
              .then((response: CalendarServiceResposne) => {
                dispatch(CalendarAction.handleCalendarServiceFeature(response.data.calendarServiceFeatureByGroupUUID));
              });
          });
      } catch (e) {
        return;
      }
    },
    []
  );

  const getCurrentIndex = () => {
    const tab = history.location.pathname || '/settings';
    return supportedIndices[tab];
  };

  const handleTabChange = (event: React.MouseEvent<HTMLDivElement>, tabData: TabProps) => {
    const activeIndex = tabData.activeIndex || 0;
    const url = activeIndexToKey[activeIndex];
    history.push(url);
    if (_isNumber(tabData.activeIndex) && selectedTab !== tabData.activeIndex) {
      setSelectedTab(tabData.activeIndex);
    }
  };

  const getTabPanes = () =>
    _compact([
      {
        menuItem: (
          {
            key: 'accountDetailsTab',
            content: (
              intl.get('accountDetailsTabTitle')
            ),
            ['data-test']: 'tabItem.details'
          }
        ),
        render: () => (
          <Tab.Pane key={settingsRoutes.DETAILS} attached={false} className="contentBody">
            <AccountDetailsTabContainer />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          {
            key: 'generalSettingsTab',
            content: (
              intl.get('cloudSettingsTabTitle')
            ),
            ['data-test']: 'tabItem.general'
          }),
        render: () => (
          <Tab.Pane key={settingsRoutes.SETTINGS} attached={false} className="contentBody">
            <GeneralSettingsContainer />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          {
            key: 'meetingSettingsTab',
            content: (
              intl.get('meetingSettings')
            ),
            ['data-test']: 'tabItem.meeting'
          }),
        render: () => (
          <Tab.Pane key={settingsRoutes.MEETING} attached={false} className="contentBody">
            <MeetingSettingsTab />
          </Tab.Pane>
        )
      },
      hasDeviceSettings && {
        menuItem: (
          {
            key: 'deviceSettingsTab',
            content: (
              intl.get('iconSettingsTabTitle')
            ),
            ['data-test']: 'tabItem.device'
          }
        ),
        render: () => (
          <Tab.Pane key={settingsRoutes.DEVICES} attached={false} className="contentBody">
            <IconSettingsContainer />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          {
            key: 'downloadsTab',
            content: (
              intl.get('downloadsTabTitle')
            ),
            ['data-test']: 'tabItem.downloads'
          }
        ),
        render: () => (
          <Tab.Pane key={settingsRoutes.DOWNLOADS} attached={false} className="contentBody">
            <Downloads />
          </Tab.Pane>
        )
      },
      (showSsoTab) && {
        menuItem: (
          {
            key: 'ssoTab',
            content: (
              intl.get('SsoTabTitle')
            ),
            ['data-test']: 'tabItem.sso'
          }
        ),
        render: () => (
          <Tab.Pane key={settingsRoutes.SSO} attached={false} className="contentBody">
            <Sso />
          </Tab.Pane>
        )
      },
      (showMergeTab) && {
        menuItem: (
          {
            key: 'mergeRequestsTab',
            content: (
              <div className={styles.mergeTitle}>
                <span>{intl.get('mergeRequestsTitle')}</span>
                {!!mergeData?.length && <Badge count={mergeData.length} />}
              </div>
            ),
            ['data-test']: 'tabItem.merge'
          }
        ),
        render: () => (
          <Tab.Pane key={settingsRoutes.MERGE} attached={false} className={styles.mergeContainer}>
            <MergeRequests data={mergeData} loading={mergeLoading} error={mergeError} setGetUpdates={setMergeGetUpdates} />
          </Tab.Pane>
        )
      },
      {
        menuItem: (
          {
            key: 'connectPlusTab',
            content: (
              'Connect Plus Gateway'
            ),
            disabled: !connectPlusGatewayTabActive,
            ['data-test']: 'tabItem.connectPlus'
          }
        ),
        render: () => (
          <Tab.Pane key={settingsRoutes.CONNECTPLUS} attached={false} className="contentBody disabled">
            <ConnectPlusGatewayContainer />
          </Tab.Pane>
        )
      }
    ]);

  return (
    <div className={styles.container}>
      {!canEditSettings && <div className={styles.noEditBanner}>{intl.get('settingsNotEditable')}</div>}
      <ListViewLayoutHeader title={getTitle()} />
      <div className={classnames(styles.tabsContainer, 'tabset')}>
        <Tab
          id={'tabContainer'}
          className={styles.tab}
          activeIndex={getCurrentIndex()}
          menu={{ secondary: true, pointing: true, ['data-test']: 'tabMenu' }}
          panes={getTabPanes()}
          onTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default Settings;
