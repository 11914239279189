import { connect } from 'react-redux';
import {
  accountSelectors,
  groupSettingsActions,
  pairedDevicesActions,
  pairedDevicesSelectors,
  currentUserSelectors,
  featureSelectors
} from '@lifesize/nucleus';
import _get from 'lodash/get';
import _round from 'lodash/round';
import { ACCOUNT_FEATURE_PURCHASED_USERS, ACCOUNT_FEATURE_MAX_MEETINGS } from 'constants/accountFeatureConstants';
import AccountDetails from 'components/Settings/AccountDetailsTab/AccountDetails';
import { selectIsImpersonating } from 'selectors/impersonationSelectors';
import { Global } from 'state/Global';
import { ACTIVE_FEATURE_STATUS } from 'constants/accountSettingsConstants';
import { isFreemiumOrTrial } from 'selectors/accountSelectors';
import { isAdminUser } from 'utils/userProfileUtils';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const mapDispatchToProps = (dispatch: Function) => ({
  fetchData: () =>
    Promise.all([
      dispatch(pairedDevicesActions.getDashLicenses()),
      dispatch(groupSettingsActions.getGroupSettings()),
    ]),
});

const getHours = (recordingEnabled: boolean, time: number) => {
  if (!recordingEnabled) {
    return null;
  }
  if (time) {
    return _round(time / 3600, 2);
  }
  return 0;
};

const mapStateToProps = (state: Global) => {
  const isImpersonating = selectIsImpersonating(state);
  const vcRecordingData = _get(state, 'nucleus.recordings.recordingsAuth.data', undefined);
  const recordingEnabled = ACTIVE_FEATURE_STATUS[vcRecordingData.recordingStatus];
  const recordingHoursData = recordingEnabled ? vcRecordingData : { duration: null, duration_used: null };
  const isFreemiumOrTrialAccount = isFreemiumOrTrial(state);
  const currentUser = currentUserSelectors.myInfo(state);
  const usePermissions = featureSelectors.getFeatureFlag(state)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const canEditSettings = selectAccountPermissions(state)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const isAdmin = usePermissions ? canEditSettings : isAdminUser(currentUser);
  const dashLicenses = _get(
    pairedDevicesSelectors.pairedDevicesSelector(state),
    'licenseCountObject',
  ) || {
    totalLicenseCount: null,
    pairedLicenseCount: null,
  };

  return {
    usersTotal: accountSelectors.getAccountFeature(state)(ACCOUNT_FEATURE_PURCHASED_USERS) || null,
    dashLicenses: {
      used: dashLicenses.pairedLicenseCount,
      total: dashLicenses.totalLicenseCount,
    },
    isImpersonating,
    meetingsTotal: accountSelectors.getAccountFeature(state)(ACCOUNT_FEATURE_MAX_MEETINGS) || 0,
    canMergeAccounts: isAdmin && isFreemiumOrTrialAccount,
    recordingHours: {
      total: getHours(recordingEnabled, recordingHoursData.duration),
      used: getHours(recordingEnabled, recordingHoursData.duration_used)
    },
    status: _get(state, 'nucleus.account.status', '...'),
    connectPlusGatewayStatus: _get(state, 'app.calendarService.connectPlusGatewayStatus')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
