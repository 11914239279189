import _get from 'lodash/get';
import { currentUserSelectors, nucleusConstants } from '@lifesize/nucleus';
import { Dispatch, MiddlewareAPI } from 'redux';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';

/**
 * To capture analytics the user.
 * @param store
 * @returns {function(*): function(*=): *}
 */
const analysticsMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: PayloadAction<string, string | object>) => {
  if (action.type === nucleusConstants.INITIALIZE_SERVICES_SUCCESS) {
    const userProfile = currentUserSelectors.myInfo(store.getState());
    const userId = _get(userProfile, 'userUUID');
    const accountId = _get(userProfile, 'groupId');
    const role = _get(userProfile, 'role');
    const email = _get(userProfile, 'email');
    if (window.aptrinsic) {
      window.aptrinsic('identify', { id: userId, email, accountId, role }, { id: accountId, name: accountId });
    }
  }
  return next(action);
};

export default analysticsMiddleware;
