import React from 'react';
import { SemanticShorthandItem, Tab, TabPaneProps } from 'semantic-ui-react';
import { TabProps } from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab';
import _isNumber from 'lodash/isNumber';
import classnames from 'classnames';
import * as styles from './ListViewTabLayout.scss';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import { withRouter } from 'react-router-dom';
import _invert from 'lodash/invert';
import { RouteComponentProps } from 'react-router';
import _get from 'lodash/get';

export interface Pane {
  content?: SemanticShorthandItem<TabPaneProps>;
  menuItem?: string | { key: string; content: string | JSX.Element; };
  render?: () => React.ReactNode;
}

interface Props {
  title: string | JSX.Element;
  breadcrumbs: string | JSX.Element | JSX.Element[];
  subtitle: string | JSX.Element;
  panes: Array<Pane>;
  indexKeys?: {[index: string]: number};
}

export const ListViewTabLayout = (props: RouteComponentProps<{}> & Props) => {
  const { indexKeys, match, history } = props;

  let defaultTab = 0;
  let activeIndexToKey = _invert(indexKeys || []);
  if (indexKeys) {
    defaultTab = indexKeys[_get(match, 'url')] || 0;
  }
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const {
    title,
    breadcrumbs,
    subtitle,
    panes
  } = props;

  React.useEffect(
    () => {
      setSelectedTab(defaultTab);
    },
    [defaultTab]
  );

  function onTabChange(event: React.MouseEvent<HTMLDivElement>, tabProps: TabProps) {
    const { activeIndex = 0 } = tabProps;

    if (indexKeys) {
      const url = activeIndexToKey[activeIndex];
      history.push(url);
    }

    if (_isNumber(activeIndex) && selectedTab !== activeIndex) {
      setSelectedTab(activeIndex);
    }
  }

  return (
    <div className={styles.container}>
      <ListViewLayoutHeader
        title={title}
        breadcrumbs={breadcrumbs}
        subtitle={subtitle}
        key={'header'}
      />
      <Tab
        className={classnames(styles.tabsContainer, 'tabset')}
        activeIndex={selectedTab}
        key={'tabSet'}
        menu={{secondary: true, pointing: true}}
        onTabChange={onTabChange}
        panes={panes}
      />
    </div>
  );
};

export default withRouter(ListViewTabLayout);
