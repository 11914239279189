import { proxyActions } from '@lifesize/nucleus';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Methods } from 'interfaces/HTTP';
import { createAction } from '@lifesize/typescript-react-redux-utils';
import {
  MERGE_REQUEST_URI,
  MERGE_SERVICE,
  SET_MERGE_REQUEST_STATUS
} from 'constants/mergeAccountConstants';
import mergeThunks from 'thunks/mergeThunks';

export const setMergeRequestStatus = (result: {}) => {
  return createAction( SET_MERGE_REQUEST_STATUS, result );
};

export const getRequest = createAsyncThunk(
  'mergeAccounts-getRequest',
  async (_, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      MERGE_SERVICE,
      MERGE_REQUEST_URI,
      Methods.GET
    ));
    const result = JSON.parse(res);
    await thunkAPI.dispatch(setMergeRequestStatus(result?.body));
    if (result?.body) {
      await thunkAPI.dispatch(mergeThunks.subscribeToMerge());
    }
    return result;
  }
);

export const createRequest = createAsyncThunk(
  'mergeAccounts-createRequest',
  async (email: string, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      MERGE_SERVICE,
      MERGE_REQUEST_URI,
      Methods.POST,
      {
        email
      }
    ));
    return JSON.parse(res);
  }
);

export const deleteRequest = createAsyncThunk(
  'mergeAccounts-deleteRequest',
  async (_, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      MERGE_SERVICE,
      MERGE_REQUEST_URI,
      Methods.DELETE
    ));
    await thunkAPI.dispatch(mergeThunks.unsubscribeToMerge());
    return JSON.parse(res);
  }
);
