import { iconActions, nucleusConstants } from '@lifesize/nucleus';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import requestPromise from 'request-promise';
import {
  setFilename,
  setFileTypeError,
  setIsCustomWallPaperEnabled,
  setFileDimensionError,
  setFileSizeError,
  clearErrors,
  setURL
} from '../actions/wallpaperActions';
import { ACCEPTABLE_MIME_TYPES, ON_DROP, } from '../constants/wallpaperConstants';
import { Methods } from 'interfaces/HTTP';

export interface PreSignedURLType {
  preSignedUrl: string;
  imageUrl: string;
  thumbnailImageUrl: string;
  preSignedUrlExpiry: number;
}
const maxWidth = 3840;
const maxHeight = 2160;

const fileIsValidSize = (file: File) => {
  return (file.size < 6000000);
};

const fileIsValidType = (file: File) => {
  return (ACCEPTABLE_MIME_TYPES.includes(file.type));
};

const fileIsValidDimensions = (image: HTMLImageElement) => {
  if (image.width <= maxWidth) {
    return true;
  } else if (image.height <= maxHeight) {
    return true;
  }
  return false;
};

const reader = new FileReader();

const OnFileDropMiddleware =
  (store: MiddlewareAPI) =>
    (nextDispatch: Dispatch) =>
        // tslint:disable
      (action: PayloadAction<string, any>) => {
        if (action.type !== ON_DROP && action.type !== nucleusConstants.GET_UPLOAD_WALLPAPER_URL_SUCCESS) {
          nextDispatch(action);
        }

        if (action.type === ON_DROP) {
          const acceptedFiles = action.payload as File[];
          acceptedFiles.forEach((file) => {
            if (!fileIsValidSize(file)) {
              nextDispatch(setFilename(file.name));
              nextDispatch(setFileSizeError(true));
            }
            if (!fileIsValidType(file)) {
              nextDispatch(setFilename(file.name));
              nextDispatch(setFileTypeError(true));
            }
            if (fileIsValidType(file) && fileIsValidSize(file)) {
              reader.onloadend = (evt: ProgressEvent) => {
                const image = new Image();
                image.src = reader?.result as string;
                image.onload = function () {
                  if (!fileIsValidDimensions(image)) {
                    nextDispatch(setFilename(file.name));
                    nextDispatch(setFileDimensionError(true));
                  } else {
                    // @ts-ignore
                    nextDispatch(clearErrors());
                    nextDispatch(iconActions.getUploadWallpaperUrl());
                  }
                };
              };
              reader.readAsDataURL(file);
            }
          });
        }

        if (action.type === nucleusConstants.GET_UPLOAD_WALLPAPER_URL_SUCCESS) {
          const readerBuffer = reader?.result as string;
          const buffer = Buffer.from(readerBuffer.split(',')[1], 'base64');
          const result = action.payload.body as PreSignedURLType;

          if (result && result.preSignedUrl) {
            requestPromise(
              result.preSignedUrl, {
                method: Methods.PUT,
                body: buffer
              })
              .then(() => {
                let counter = 0;
                const checkImageUpload = () => {
                  requestPromise(
                    result.imageUrl,
                    {
                      method: Methods.GET
                    }
                  )
                    .then((res) => {
                      nextDispatch(setURL(result.imageUrl));
                      nextDispatch(setIsCustomWallPaperEnabled(true));
                      clearInterval(checkImageInterval);
                    })
                    .catch((error) => {
                      counter += 1;
                      if (counter >= 60) {
                        clearInterval(checkImageInterval);
                        alert('There was an issue with uploading the image');
                      }
                    });
                  /* tslint:enable */
                };
                const checkImageInterval =
                  setInterval(
                    checkImageUpload,
                    1000
                  );
              }
              )
              .catch((error) => {
                if (error) {
                  alert('There was an issue with uploading the image');
                  return;
                }
              });
          }
        }
      };

export default (OnFileDropMiddleware as Middleware);
