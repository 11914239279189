import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import { Localization, localizationUtils } from '@lifesize/clients.localization';
import { FormikComputedProps, FormikHandlers, FormikState } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _set from 'lodash/set';
import OkResetWaitButtonGroup from './OkResetWaitButtonGroup';
import _isString from 'lodash/isString';
import { getLocaleOptions } from 'utils/languageUtils';

interface Props extends FormikState<object>,
  FormikComputedProps<object>,
  FormikHandlers {
  className?: string;
  fieldName: string; // this maps to the name attribute on the form field
  hideOkSaveCancel?: boolean;
  onChange: (localeCodeCode: string) => void;
  persistedValue?: string; // The current value stored
  resetValue?: string; // Value used to trigger re-draw
  setFieldTouched(field: string, isTouched?: boolean, shouldValidate?: boolean): void;
}

const isDirty = (dirty: boolean, touched: object, originalText: string, displayedValue: string) => dirty && (originalText !== displayedValue);

const LanguageSelect = (props: Props) => {
  const {
    values,
    fieldName,
    persistedValue,
    onChange,
    setFieldTouched,
    className,
    dirty,
    errors,
    handleSubmit,
    hideOkSaveCancel,
    isSubmitting,
    touched
  } = props;
  const value = _get(values, fieldName);
  const localeCode = localizationUtils.getLanguageId(value);
  const localization = new Localization({ id: localeCode });

  const [displayedValue, setDisplayedValue] = useState(localization.language || 'en'); // The current value in UI
  const [originalValue, setOriginalValue] = useState(localization.language || 'en'); // The value used for reset

  useEffect(() => {
    // Make sure the saved value is the one used to compare for dirty state
    if (_isString(persistedValue) && persistedValue !== originalValue) {
      setOriginalValue(persistedValue);
    }
  },        [persistedValue, originalValue]);

  /**
   * Optional callback to onBlur will be called with the new state value after update
   * @param event
   * @param data
   */
  const setSelected = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    onChange(data.value as string);
    setDisplayedValue(data.value as string);
    if (originalValue !== displayedValue) {
      setFieldTouched(fieldName, true, false);
    }
  };

  const handleReset = () => {
    if (displayedValue !== originalValue) {
      _set(values, fieldName, originalValue);
      setDisplayedValue(originalValue);
      setFieldTouched(fieldName, false);
    }
  };

  const isThisTouched = _get(touched, fieldName, false);
  return (
    <>
      <Dropdown
        className={className}
        closeOnBlur={true}
        fluid={false}
        noResultsMessage={intl.get('noResultsFound')}
        onChange={setSelected}
        options={getLocaleOptions()}
        placeholder={intl.get('selectLanguage')}
        search={false}
        selection={true}
        value={displayedValue}
      />
      {
        !hideOkSaveCancel && isDirty(dirty, touched, originalValue, displayedValue) && isThisTouched &&
        <OkResetWaitButtonGroup
          isSubmitting={isSubmitting}
          hasErrors={!_isEmpty(errors)}
          isAssociatedFieldTouched={isThisTouched}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
        />
      }
    </>
  );
};

export default LanguageSelect;
