import { Reducer } from 'redux';
import {
  IS_CUSTOM_ICON_WALLPAPER_ENABLED,
  SET_URL,
  TYPE_ERROR,
  SET_FILENAME,
  DIMENSION_ERROR,
  SIZE_ERROR,
  SET_MD5
} from 'constants/wallpaperConstants';
import { nucleusConstants } from '@lifesize/nucleus';
import { WallPaperPanelType } from 'interfaces/Wallpaper';
import { initialWallPaperPanelState } from 'state/Wallpaper';

const WallPaperPanelReducer: Reducer<WallPaperPanelType> =
  (state: WallPaperPanelType = initialWallPaperPanelState, action): WallPaperPanelType => {
    switch (action.type) {
      case IS_CUSTOM_ICON_WALLPAPER_ENABLED:
        if (action.payload === false) {
          return {
            ...state,
            isEnabled: action.payload,
            hasTypeError: false,
            hasSizeError: false,
            hasDimensionsError: false
          };
        }
        return {
          ...state,
          isEnabled: action.payload
        };
      case SET_URL:
        return { ...state, imagePreviewURL: action.payload };
      case TYPE_ERROR:
        return { ...state, hasTypeError: action.payload };
      case DIMENSION_ERROR:
        return { ...state, hasDimensionsError: action.payload };
      case SIZE_ERROR:
        return { ...state, hasSizeError: action.payload };
      case SET_FILENAME:
        return { ...state, filename: action.payload };
      case SET_MD5:
        return { ...state, md5Sum: action.payload };
      case nucleusConstants.GET_EXISTING_WALLPAPER_URL_SUCCESS:
        if (action?.payload?.body?.imageUrl) {
          return {
            ...state,
            imagePreviewURL: action.payload.body.imageUrl,
            isEnabled: true
          };
        }
        return state;
      default:
        return state;
    }
  };

export default WallPaperPanelReducer;
