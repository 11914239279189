/**
 * Interpret an http response from fetch for success or failure
 * @param {object} response - Response object from fetch
 * @throws {Object} If status of response is >= 400
 * @return response parameter untouched
 */
export function checkHttpStatus(response: Response) {
  if (response.status >= 400) {
    const error = {
      message: response.statusText,
      response: {
        status: response.status,
        statusText: response.statusText,
        url: response.url
      }
    };

    throw error;
  }
  return response;
}
