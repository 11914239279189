import { Reducer } from 'redux';
import {
 UserProfileActionType
} from 'actions/userProfileActions';
import { UserProfileState, initialUserProfileState } from 'state/UserProfileState';

const userProfileReducer: Reducer<UserProfileState> =
  (state = initialUserProfileState, action) => {
    switch (action.type) {
      case UserProfileActionType.FETCH_USER_PROFILE:
        return {
          ...state,
          isBusy: true,
          error: null
        };
      case UserProfileActionType.FETCH_USER_PROFILE_SUCCESS:
        return {
          ...state,
          isBusy: false,
          user: action.payload,
          error: null
        };
      case UserProfileActionType.FETCH_USER_PROFILE_FAILURE:
        return {
          ...state,
          isBusy: false,
          user: null,
          error: action.payload
        };
      default:
        return state;
    }
  };

export default userProfileReducer;
