import React from 'react';
import intl from 'react-intl-universal';
import { RoomSystem } from '../../../typings/types';
import Modal from '../../Modal/ModalComponent/ModalComponent';
import * as styles from './TabletInfoModal.scss';

interface Props {
  roomSystem: RoomSystem;
  closeModal: () => void;
  openDisconnectDashConfirm: (payload: object) => void;
}

export const TabletInfoModal = ({
                                  closeModal,
                                  openDisconnectDashConfirm,
                                  roomSystem

                                }: Props) => {
  return (
    <Modal
      className={styles.container}
      titleText={intl.get('tablet')}
      closeModal={closeModal}
      saveText={intl.get('disconnect')}
      hideSaveButton={false}
      onSave={
        () => {
          openDisconnectDashConfirm(roomSystem);
        }
      }
      hideCloseButton={true}
    >
      <div className={styles.child}>
        {/*
        // As per TI-1038 - Hide these until all data from tablet available
        <div className={styles.formGroup}>
          <label>{intl.get('deviceName')}: </label>
          <div className={styles.textField}>{systemName}</div>
        </div>
        <div className={styles.formGroup}>
          <label>{intl.get('deviceType')}: </label>
          <div className={styles.textField}>{roomSystem.controllerText}</div>
        </div>

        <div className={styles.formGroup}>
          <label>{intl.get('version')}: </label>
          <div className={styles.textField}>{roomSystem.currentFirmwareVersion}</div>
        </div>
        <div className={styles.formGroup}>
          <label>{intl.get('batteryLife')}: </label>
          <div className={styles.textField}>{roomSystem.currentFirmwareVersion}</div>
        </div>
        */}

      </div>
    </Modal>
  );
};

export default TabletInfoModal;
