import React from 'react';
import { Tab } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import {
  formatUnixTimeOfDayForDisplay,
  secondsToMilliseconds,
  secondsToHms
} from 'utils/date-time-utils';
import { GeoLocation, ModeratorEntry, ParticipantEntry } from 'interfaces/Dashboard';
import * as styles from './LiveMeetingDetails.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import LiveMeetingParticipantList from 'containers/LiveMeetings/LiveMeetingParticipantListContainer';
import { GeoDistribution } from 'components/GeoDistribution/GeoDistribution';
import Icon from 'components/Common/Icon';
import { useInterval } from 'hooks/useInterval';
import {
  LIVE_MEETING_PARTICIPANTS,
  LIVE_MEETING_REFRESH_TIME,
  LIVE_MEETING_TAB,
  LIVE_MEETING_GEO
} from 'constants/liveMeetings';
import { useQuery } from 'react-apollo';
import ListViewTabLayout from 'components/ListViewTabLayout/ListViewTabLayout';
import _compact from 'lodash/compact';
import {
  PAST_MEETING_PARTICIPANTS,
  PAST_MEETING_ROUTE
} from 'constants/pastMeetings';
import liveMeetingByUUIDQuery from 'queries/liveMeetings/liveMeetingByUUIDQuery.gql';
import { useSelector } from 'react-redux';
import { selectUserActive } from 'selectors/userStateSelector';
import _get from 'lodash/get';
import { LiveMeetingData } from 'interfaces/LiveMeeting';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { QueryControls } from '@apollo/react-hoc';
import { accountSelectors } from '@lifesize/nucleus';
import { ACCOUNT_FEATURE_ENABLE_GEO_DISTRIBUTION } from 'constants/accountFeatureConstants';

const indexKeys = {
  [LIVE_MEETING_PARTICIPANTS]: 0,
  [LIVE_MEETING_GEO]: 1,
};

const panes = (
  conferenceId: string,
  data: QueryControls<LiveMeetingData>,
  geoDistribution: Array<GeoLocation>,
  showGeoDistribution: boolean,
) => {
  const paneList =
    [
      {
        menuItem: (
          {
            key: 'participantsTab',
            content: (
              intl.get('participants')
            )
          }),
        render: () => (
          <Tab.Pane key={'0'} attached={false}>
            <LiveMeetingParticipantList
              conferenceId={conferenceId}
              data={data}
            />
          </Tab.Pane>
        )
      }
    ];

  if (showGeoDistribution) { paneList.push({
    menuItem: (
      {
        key: 'geoDistributionTab',
        content: (
          intl.get('geoDistribution')
        )
      }),
    render: () => (
      <Tab.Pane key={'1'} attached={false}>
        <GeoDistribution geoDistribution={geoDistribution}/>
      </Tab.Pane>
    )
  });
  }

  return _compact(paneList);
};

const getGeoDistribution = (data: LiveMeetingData) => {
  const participants = _get(data, 'liveMeetingByUUID.participants', []);
  const participantsToMap = _filter(participants,
                                    (participant: ParticipantEntry) => _get(participant, 'geoLocation.latitude') && _get(participant, 'geoLocation.longitude'));
  return _map(participantsToMap, (participant) => {
    const { geoLocation, endedAt, displayName } = participant;
    const { latitude, longitude } = geoLocation;
    return { latitude, longitude, displayName, isActive: !endedAt };
  });
};

export const LiveMeetingDetails = (props: RouteComponentProps) => {
  const isActive = useSelector(selectUserActive);
  const enableGeoDistribution = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_ENABLE_GEO_DISTRIBUTION);

  const {loading, data} = useQuery(liveMeetingByUUIDQuery, {
    variables: { conferenceId: _get(props, 'match.params.id') },
    fetchPolicy: 'network-only',
    pollInterval: isActive ? LIVE_MEETING_REFRESH_TIME : 0
  });

  const moderators = _get(data, 'liveMeetingByUUID.moderators', [])
    .reduce(
      (acc: Array<string>, cur: ModeratorEntry) => {
        if (cur.displayName && cur.displayName.length) {
          acc.push(cur.displayName);
        }
        return acc;
      },
      []
    )
    .sort();

  const startedAt = _get(data, 'liveMeetingByUUID.startedAt');
  const duration = startedAt ? Math.floor(Date.now() / 1000) - startedAt : 0;
  const geoDistribution = getGeoDistribution(data);
  
  const uuid = _get(props, 'match.params.id', null);
  const conferenceType = _get(data, 'liveMeetingByUUID.conferenceType');
  const conferenceId = _get(props, 'match.params.id');
  const isRecording = _get(data, 'liveMeetingByUUID.conferenceInfo.recording.isRecording', false);
  const extension = _get(data, 'liveMeetingByUUID.extension');
  const activeParticipants = _get(data, 'liveMeetingByUUID.activeParticipants');
  const totalParticipants = _get(data, 'liveMeetingByUUID.totalParticipants');
  const meetingName = _get(data, 'liveMeetingByUUID.meetingName');
  const meetingExists = _get(data, 'liveMeetingByUUID');
  const showGeoDistribution = geoDistribution.length && enableGeoDistribution;
  
  const [tickDuration, setTickDuration] = React.useState(duration);
  const [useTickDuration, setUseTickDuration] = React.useState(false);

  useInterval(() => {
    if (startedAt) {
      setUseTickDuration(true);
      const newDuration = startedAt ? Math.floor(Date.now() / 1000) - startedAt : 0;
      setTickDuration(newDuration);
    }
  },          1000, [startedAt, duration]);

  if (!loading && !meetingExists) {
    return <Redirect to={`${PAST_MEETING_ROUTE}/${uuid}/${PAST_MEETING_PARTICIPANTS}`} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const row1 = [];
  row1.push(`${intl.get('extensionWithColon')} ${extension}`);
  row1.push(`${intl.get('meetingTypeWithColon')} ${conferenceType}`);
  if (isRecording) {
    row1.push(<><Icon classes={['icon-record', styles.recording]}/> {intl.get('recording')}</>);
  }
  row1.push(`${moderators.length > 1 ? intl.get('moderatorsWithColon') : intl.get('moderatorWithColon')} ${moderators.join(', ')}`);

  const row2 = [
    `${intl.get('activeParticipantsWithColon')} ${activeParticipants}`,
    `${intl.get('totalParticipantsWithColon')} ${totalParticipants}`,
    `${intl.get('startTimeWithColon')} ${formatUnixTimeOfDayForDisplay(secondsToMilliseconds(startedAt))}`,
    `${intl.get('durationWithColon')} ${secondsToHms(useTickDuration ? tickDuration : duration)}`
  ];

  const headerRows = [row1, row2];
  return (
    <ListViewTabLayout
      title={meetingName}
      breadcrumbs={(
        <React.Fragment>
          <Link key="liveMeetingsBreadcrumb" to={{ pathname: LIVE_MEETING_TAB }}>
            {intl.get('liveMeetings')}
          </Link>
          {' > '}
          {meetingName}
        </React.Fragment>
      )}
      subtitle={(
        <div>
          <div className={styles.flexCol}>
            {headerRows.map((row, rowIndex) => {
              return (
                <div key={`row${rowIndex}`}>
                  {row
                    .map((item, index) => <span key={`item${index}`}>{item}</span>)
                    .reduce((acc, x, i) => acc === null ? x : <>{acc}<span key={`sep${i}`} className={styles.separator}>|</span>{x}</>, null)
                  }
                </div>
              );
            })}
          </div>
        </div>
      )}
      panes={panes(conferenceId, data, geoDistribution, showGeoDistribution)}
      indexKeys={indexKeys}
    />
  );
};

export default LiveMeetingDetails;
