// Replace this with some library at some point.
class LoggerClass {

  _isDev = () => {
    return (process.env.NODE_ENV === 'development');
  }

  info = (message: string) => {
    if (!this._isDev()) {
      return;
    }
    // tslint:disable-next-line
    console.log(message);
  }
}
const Logger = new LoggerClass();

export { Logger };
