import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import SearchBar from 'components/Search/SearchBar';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import RoomSystemsToolBar from 'containers/RoomSystems/RoomSystemsToolbarContainer';
import { RoomSystem } from 'typings/types';
import { Sort } from 'interfaces/Meeting';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { RouteComponentProps } from 'react-router';
import { roomSystemColumnConfig } from './RoomSystemColumnConfig';
import useListHooks from 'hooks/useListHooks';
import listRoomsQuery from 'queries/roomSystems/listRoomsQuery.gql';
import { SortDirection } from 'constants/queryConstants';
import { SortingRule } from 'react-table';
import { getSortingRule } from 'utils/listUtils';
import { transformRoomSystemData } from 'utils/roomSystemUtils';
import { NoPermissionsBanner } from 'components/NoPermissionsBanner/NoPermissionsBanner';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_HIDE_DEVICE_SUBSCRIPTION } from 'constants/featureFlags';

interface Props {
  selectionArray: Array<string>;
  resetExportState: () => void;
}

const getTitle = _memoize(() => {
  return (
    <>
      <Icon classes={['icon-room-system']} />
      <span>{intl.get('roomSystems')}</span>
    </>
  );
});

const RoomSystemsComponent = (props: RouteComponentProps<void> & Props) => {
  const defaultSort: Sort = {
    propertyName: 'systemName',
    direction: SortDirection.ASC
  };
  const defaultGraphQLSort: Sort = {
    propertyName: '',
    direction: SortDirection.ASC
  };
  const initialSort = _get(props, 'location.state.sort', defaultSort);
  const initialFilterString = _get(props, 'location.state.filterString');
  // Keep sort locally because for room systems, client performs sorting...not GraphQL
  const [sort, setSort] = useState<Sort>(initialSort);
  const { selectionArray } = props;

  const {
    loading,
    data,
    error,
    filterString,
    setFilterString,
  } = useListHooks(listRoomsQuery, { sort: defaultGraphQLSort, filterString: initialFilterString });

  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];
  const directoryListRoomSystem = _get(data, 'directoryListRoomSystem');
  let listData = (!loading && directoryListRoomSystem)
    ? directoryListRoomSystem.records as unknown as RoomSystem[]
    : [];

  if (listData && listData.length) {
    listData = listData.filter((rs) => rs.serialNumber);
    listData = transformRoomSystemData(listData) as RoomSystem[];
  }

  const isDeviceSubscriptionHidden: boolean = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_HIDE_DEVICE_SUBSCRIPTION);

  const onFetchData: (args: { sorted: Array<SortingRule> }) => void = async ({
                                                                               sorted: [column],
                                                                             }) => {
    if (!data) {
      return;
    }

    const newSort = await getSortingRule(column);
    if (newSort) {
      setSort(newSort);
    }
  };

  return (
    <>
      {!canEditRoomSystems && <NoPermissionsBanner text={intl.get('cannotModifyRooms')} />}
      <ListViewLayout
        title={getTitle()}
        isFetching={false}
        error={_get(error, 'message')}
        numberOfPages={0}
      >
        <InfiniteTable
          columnConfig={roomSystemColumnConfig(sort, filterString, isDeviceSubscriptionHidden)}
          data={listData}
          defaultSort={[
            {
              id: _get(sort, 'propertyName', ''),
              desc: _get(sort, 'direction') === 'DESC'
            },
          ]}
          loading={loading}
          keyField={'serialNumber'}
          renderHeader={() => (
            <>
              <SearchBar
                setFilterString={(newString) => {
                  setSort({ propertyName: newString.length ? '' : 'systemName', direction: SortDirection.ASC });
                  setFilterString(newString);
                }}
                initialValue={initialFilterString}
              />
              <RoomSystemsToolBar
                data={data}
                selectionArray={selectionArray}
              />
            </>
          )}
          rowHeightOverride={48}
          sortable={true}
          onFetchData={onFetchData}
          manual={true}
          enableSelect={canEditRoomSystems}
          getTrProps={() => {
            return {
              style: {
                height: '48px'
              }
            };
          }}
          usePageSize={true}
        />
      </ListViewLayout>
    </>
  );
};

export default RoomSystemsComponent;
