import React from 'react';
import intl from 'react-intl-universal';
import { Field, Form, Formik } from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _memoize from 'lodash/memoize';
import * as formStyles from 'components/Modals/ModalForms.scss';
import { ModalFooter } from 'components/Modal/ModalComponent/ModalFooter';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import UploadFileComponent from 'components/UploadFile/UploadFileComponent';
import { Logger } from 'logger';
import classnames from 'classnames';
import { MAX_UPLOAD_FILE_SIZE, UploadRecordingStatus, acceptedRecordingUploadTypes } from 'constants/recordingsConstants';
import { FormInput, S3UploadCredentials, UploadStatusResponse } from 'interfaces/Recording';
import * as Yup from 'yup';
import * as styles from './UploadRecordingComponent.scss';
import { useMount } from 'hooks/useMount';
import { getS3UploadCredentials } from 'utils/uploadRecordingUtils';

const axios = require('axios');

const errorStyle = _memoize((isTouched: boolean, hasError: boolean, isSubmitting: boolean) => {
  if (isTouched && hasError && !isSubmitting) {
    return formStyles.error;
  }
  return '';
},                          (...args) => {
  // Key for memoization
  return JSON.stringify(args);
});

/**
 * Cached to evaluate only once
 */
const uploadRecordingSchema = _memoize(() => {
  // Only require name validation if not SSO
  return Yup.object().shape({
    title: Yup.string().trim().required(intl.get('titleRequired'))
      .min(2, intl.get('videoTitleMinLength'))
      .max(255, intl.get('videoTitleMaxLength')),
    videoFile: Yup.mixed().required(intl.get('uploadRequired'))
  });
});

interface Props {
  closeModal: (s3Credentials: S3UploadCredentials | null) => void;
  upload: (s3Credentials: S3UploadCredentials, values: FormInput, handleUploadProgress: Function) => void;
  getUploadsStatus: () => void;
  addProcessingUpload: (status: UploadStatusResponse) => void;
}

interface AxiosUploadProgress {
  loaded: number;
  total: number;
}

const UploadRecordingComponent = (props: Props) => {
  const [ uploadStatus, setUploadStatus ] = React.useState('');
  const [ isCancelled, setIsCancelled ] = React.useState(false);
  const [ s3Credentials, setS3Credentials ] = React.useState(null);
  const [ cancelTokenSource ] = React.useState(axios.CancelToken.source());
  const { closeModal, addProcessingUpload } = props;
  const isMounted = useMount();

  const handleUploadProgress = (p: AxiosUploadProgress) => {
    if (isMounted.current && p.loaded !== undefined && p.total > 0) {
      setUploadStatus(intl.get('uploadStatusUploadingWithProgress', {
        progress: `${Math.round(p.loaded * 100 / p.total)}%`
      }));
    }
  };

  const handleCancel = () => {
    setIsCancelled(true);
    if (s3Credentials) {
      cancelTokenSource.cancel('Upload cancelled');
    }
    closeModal(s3Credentials);
  };

  return (
    <Modal
      className={formStyles.containerLarge}
      closeModal={handleCancel}
      closeText={intl.get('cancel')}
      hideFooter={true}
      saveText={intl.get('save')}
      titleText={intl.get('uploadRecording')}
    >
      <div className={styles.container}>
        <Formik
          initialValues={{
            title: '',
          }}
          validationSchema={uploadRecordingSchema()}
          onSubmit={async (values: { title: string, videoFile?: File }, { setSubmitting, setFieldError }) => {
            try {
              setUploadStatus(intl.get('uploadStatusUploading'));
              const credentials = await getS3UploadCredentials(values);
              if (isMounted.current && credentials) {
                setS3Credentials(credentials);
                if (!isCancelled && credentials.s3UploadUrl) {
                  // Upload file contents to S3
                  await axios.request( {
                    cancelToken: cancelTokenSource.token,
                    method: 'put',
                    url: credentials.s3UploadUrl,
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    data: values.videoFile,
                    onUploadProgress: handleUploadProgress
                  });
                  await addProcessingUpload({
                    title: values.title,
                    status: UploadRecordingStatus.PROCESSING,
                    uploadUuid: credentials.uploadUuid
                  });
                  closeModal(null);
                }
              }
            } catch (e) {
              // tslint:disable-next-line
              Logger.info(`File Upload Error: ${e.toString()}`);
              if (isMounted.current) {
                if (e.message !== 'Upload cancelled') {
                  setFieldError('general', intl.get('uploadError'));
                  setS3Credentials(null);
                }
                setUploadStatus('');
                setSubmitting(false);
              }
            }
          }}
          render={({
             dirty,
             errors,
             isSubmitting,
             touched,
             setFieldValue
           }) => {
            const titleErrors = _get(errors, 'title');
            const videoFileErrors = _get(errors, 'videoFile');
            const generalError = _get(errors, 'general');
            return (
              <div className={formStyles.formControl}>
                <Form>
                  <div className={formStyles.formGroup}>
                    <div className={styles.uploaderContainer}>
                      <UploadFileComponent
                        fieldName="videoFile"
                        sizeLimit={MAX_UPLOAD_FILE_SIZE}
                        typeLimit={acceptedRecordingUploadTypes}
                        multiple={false}
                        className={styles.dropzone}
                        onChange={(file: File) => {
                          setFieldValue('title', file.name);
                        }}
                        fileSizeError={intl.get('uploadVideoFileSizeError')}
                      />
                      <div className={styles.helper}>
                        {intl.get('uploadVideoHelper')}
                      </div>
                      <div className={styles.errorContainer}>
                        {errors && videoFileErrors && (
                          <div className={styles.error}>
                            {_get(errors, 'videoFile')}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classnames(formStyles.formGroup, formStyles.hasValidation, errorStyle(!!touched.title, !_isEmpty(titleErrors), isSubmitting))}>
                    <label className={formStyles.labelLarge}>{intl.get('title')}</label>
                    <div className={classnames(formStyles.inputContainer, formStyles.large)}>
                      <Field
                        className={formStyles.input}
                        name="title"
                        placeholder={intl.get('title')}
                        type="text"
                      />
                      <ul className={formStyles.inputMessages}>
                        {touched && touched.title && errors && titleErrors &&
                          <li className={formStyles.error}>{_get(errors, 'title')}</li>
                        }
                      </ul>
                    </div>
                  </div>
                  <ModalFooter
                    isSaveButtonDisabled={!dirty}
                    useSaveAsFormSubmit={true}
                    closeModal={handleCancel}
                    closeText={intl.get('cancel')}
                    saveText={intl.get('upload')}
                    isSaveButtonBusy={dirty && isSubmitting}
                  />
                  <div className={styles.statusContainer}>
                    {generalError ? (
                      <div className={classnames(formStyles.generalError, formStyles.center)}>
                        <div className={formStyles.error}>{generalError}</div>
                      </div>
                    ) : (
                      <div className={styles.uploadStatus}>{uploadStatus}</div>
                    )}
                  </div>
                </Form>
              </div>
            );
          }}
        />
      </div>
    </Modal>
  );
};

export default UploadRecordingComponent;
