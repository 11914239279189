import React from 'react';
import intl from 'react-intl-universal';
import classes from './TableNoData.scss';

interface Props {
  text?: string;
}

class TableNoData extends React.PureComponent<Props> {
  render(): React.ReactNode {
    return (
      <div className={classes.noDataContainer}>
        <div className={classes.noDataContent}>
          {this.props.text || intl.get('noDataFound')}
          <span className={['icon-data', classes.iconContainer].join(' ')} />
        </div>
      </div>
    );
  }
}

export default TableNoData;
