import { Reducer } from 'redux';
import { MeetingsActionType } from 'actions/meetingsActions';
import { initialLiveStreamsState, LiveStreamsState } from 'state/LiveStreams';

const liveStreamsReducer: Reducer<LiveStreamsState> =
  (state = initialLiveStreamsState, action) => {
    switch (action.type) {
      case MeetingsActionType.LIVE_STREAMS_MEETING_NAMES_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: null
        };
      case MeetingsActionType.LIVE_STREAMS_MEETING_NAMES_SUCCESS:
        return {
          ...state,
          isFetching: false,
          error: null,
          data: action.payload || []
        };
      case MeetingsActionType.LIVE_STREAMS_MEETING_NAMES_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: action.payload
        };
      default:
        return state;
    }
  };

export default liveStreamsReducer;
