import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { compose, defaultProps } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from 'selectors/languageSelectors';
import { DEFAULT_LOCALE } from 'constants/languageConstants';
import { localizationActions } from '@lifesize/nucleus';

const locales = {
  'cs': require('@lifesize/strings/admin/cs.json'),
  'de': require('@lifesize/strings/admin/de.json'),
  'en': require('@lifesize/strings/admin/en.json'),
  'es': require('@lifesize/strings/admin/es.json'),
  'fi': require('@lifesize/strings/admin/fi.json'),
  'fr': require('@lifesize/strings/admin/fr.json'),
  'it': require('@lifesize/strings/admin/it.json'),
  'ja': require('@lifesize/strings/admin/ja.json'),
  'ko': require('@lifesize/strings/admin/ko.json'),
  'nl': require('@lifesize/strings/admin/nl.json'),
  'no': require('@lifesize/strings/admin/no.json'),
  'pl': require('@lifesize/strings/admin/pl.json'),
  'pt': require('@lifesize/strings/admin/pt.json'),
  'ru': require('@lifesize/strings/admin/ru-RU.json'),
  'sv': require('@lifesize/strings/admin/sv.json'),
  'zh': require('@lifesize/strings/admin/zh.json'),
  'zh-Hant': require('@lifesize/strings/admin/zh-Hant.json'),
};

const localeDataUrl = process.env.REACT_APP_LOCALE_DATA;

const commonLocaleDataUrls = {
  'cs': `${localeDataUrl}en.js`,
  'de': `${localeDataUrl}de.js`,
  'en': `${localeDataUrl}en.js`,
  'es': `${localeDataUrl}es.js`,
  'fi': `${localeDataUrl}en.js`,
  'fr': `${localeDataUrl}fr.js`,
  'it': `${localeDataUrl}it.js`,
  'ja': `${localeDataUrl}ja.js`,
  'ko': `${localeDataUrl}ko.js`,
  'nl': `${localeDataUrl}nl.js`,
  'no': `${localeDataUrl}en.js`,
  'pl': `${localeDataUrl}pl.js`,
  'pt': `${localeDataUrl}pt.js`,
  'sv': `${localeDataUrl}sv.js`,
  'zh': `${localeDataUrl}zh.js`,
};

export const withIntl = (BaseComponent: React.ComponentType<{}>) => {

  return (props: {}) => {
    const locale = useSelector(selectLocale);
    const dispatch = useDispatch();
    const [initDone, setInitDone] = useState(false);

    useEffect(
      () => {
        const initialize = async () => {
          dispatch(localizationActions.setLocalization(locale));
          await intl.init({
            currentLocale: locale || DEFAULT_LOCALE,
            commonLocaleDataUrls: commonLocaleDataUrls,
            locales,
            fallbackLocale: DEFAULT_LOCALE
          });

          setInitDone(true);
        };
        initialize();
      },
      []
    );

    return (
      <>
        {initDone && <BaseComponent {...props} />}
      </>
    );
  };
};

export const withIntlTest = compose(
  defaultProps({locale: 'en'}),
  withIntl,
);
