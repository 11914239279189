import React, { useEffect } from 'react';
import styles from './Pagination.scss';
import { Pagination } from '@lifesize/react-components/dist/Pagination';

export interface Props {
  setPageNumber: (data: { selected: number }) => void;
  numOfPages: number;
  forcePage?: number;
  initialPage?: number;
}

export const PaginationComponent = (props: Props) => {
  const { 
    setPageNumber,
    forcePage,
    initialPage,
    numOfPages
  } = props;

  useEffect(
    () => () => setPageNumber({ selected: 0 }),
    []
  );

  return (
    <Pagination
      activeClassName={styles.activePage}
      breakClassName={styles.breakStyle}
      containerClassName={styles.paginationContainer}
      disabledClassName={styles.disabled}
      forcePage={(forcePage) ? forcePage : undefined}
      initialPage={(initialPage) ? initialPage : undefined}
      nextClassName={styles.next}
      onPageChange={setPageNumber}
      pageCount={numOfPages}
      previousClassName={styles.prev}
    />
  );
};

export default PaginationComponent;
