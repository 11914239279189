import Can from 'components/Can/CanComponent';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountSelectors, featureSelectors, groupSettingsActions } from '@lifesize/nucleus';
import { recordingEnabledSetting } from 'selectors/accountSelectors';
import MeetingInvites from 'containers/Settings/MeetingInvitesContainer';
import MeetingLayouts from 'components/Settings/MeetingSettingsTab//MeetingLayouts';
import PhoneNumbers from 'components/Settings/MeetingSettingsTab//PhoneNumbers';
import InactiveMeetings from 'components/Settings/MeetingSettingsTab//InactiveMeetings';
import RequireMeetingPasscodes
  from 'components/Settings/MeetingSettingsTab/RequireMeetingPasscodes/RequireMeetingPasscodes';
import WaitingRoomSetting
    from 'components/Settings/MeetingSettingsTab/WaitingRoomSetting/WaitingRoomSetting';
import MeetingCreationSettings
  from 'components/Settings/MeetingSettingsTab/MeetingCreationSettings/MeetingCreationSettings';
import AutoRecord from 'components/Settings/MeetingSettingsTab/AutoRecord/AutoRecord';
import { ACCOUNT_FEATURE_PERMANENT_MEETING } from 'constants/accountFeatureConstants';
import { FEATURE_FLAG_WAITING_ROOM } from 'constants/featureFlags';
import { FEATURE_FLAG_AUTO_RECORD } from 'constants/featureFlags';
import { ROLE_PERMISSION } from 'interfaces/Role';

export const MeetingSettings = () => {
  const dispatch = useDispatch();
  const accountFeatures = useSelector(accountSelectors.getAccountFeatures);
  const showPermanentMeetingsSection = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_PERMANENT_MEETING);
  const showWaitingRoomSettingSection = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_WAITING_ROOM);
  const isRecordingEnabled = useSelector(recordingEnabledSetting);
  const showAutoRecord = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_AUTO_RECORD) && isRecordingEnabled;
  const [loading, setLoading] = useState(true);

  const hasPSTNAudio = accountFeatures.pstnNumbers !== 'NONE';

  useEffect(() => {
    const asyncFetchData = async () => {
      await dispatch(groupSettingsActions.getGroupSettings());
      setLoading(false);
    };
    asyncFetchData();
  },        []);

  if (loading) {
    return <LoadingIndicator/>;
  }

  return (
    <>
      {
        [
          hasPSTNAudio && <PhoneNumbers key="phoneNumbers"/>,
          <Can key="canViewMeetings" userAccess={[ROLE_PERMISSION.VIEW_MEETINGS]}>
            <MeetingInvites key="meetingInvites"/>
          </Can>,
          showWaitingRoomSettingSection && <WaitingRoomSetting key="waitingRoomSetting" />,
          <RequireMeetingPasscodes key="requireMeetingPasscodes" />,
          <MeetingLayouts key="meetingLayouts" />,
          showPermanentMeetingsSection && <MeetingCreationSettings key="allowPermanentMeetings" />,
          showAutoRecord && <AutoRecord key="autoRecord" />,
          <InactiveMeetings key="inactiveMeetings"/>,
        ]
      }
    </>
  );
};

export default MeetingSettings;
