import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import _memoize from 'lodash/memoize';
import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Tab } from 'semantic-ui-react';
import { RouteComponentProps } from 'react-router';
import * as styles from './Recordings.scss';
import {
  RecordingsFeedWithFeedAll,
  RecordingsFeedWithMyRecordings,
  RecordingsFeedWithWatchLater,
} from 'containers/Recordings/RecordingsContainer';
import Icon from 'components/Common/Icon';
import { RECORDINGS_TABS } from 'constants/recordingsConstants';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import { RecordingListingParamsType } from 'interfaces/Recording';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { useSelector } from 'react-redux';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { getRouteForIndex, handleTabChange } from 'utils/tabUtils';

const generateSupportedIndices = (canViewAllRecordings: boolean, canViewOwnRecordings: boolean) => {
  let tabIndex = -1;
  const supportedIndices = {};

  if (canViewAllRecordings) {
    supportedIndices[RECORDINGS_TABS.RECORDINGS_FEED] = tabIndex += 1;
  }

  if (canViewOwnRecordings) {
    supportedIndices[RECORDINGS_TABS.MY_RECORDINGS] = tabIndex += 1;
  }

  supportedIndices[RECORDINGS_TABS.WATCHLIST] = tabIndex + 1;

  return supportedIndices;
};

export const Recordings = (props: RouteComponentProps<void>) => {
  const permissions = useSelector(selectAccountPermissions);
  const canViewAllRecordings = permissions[ROLE_PERMISSION.VIEW_OWN_ACCOUNT_RECORDINGS];
  const canViewOwnRecordings = permissions[ROLE_PERMISSION.VIEW_OWN_RECORDINGS];

  const keyToActiveIndex = generateSupportedIndices(canViewAllRecordings, canViewOwnRecordings);
  const getCurrentIndex = (tab: string = RECORDINGS_TABS.RECORDINGS_FEED) => keyToActiveIndex[tab] || 0;
  const { history } = props;
  const [selectedTab, setSelectedTab] = useState(getCurrentIndex(history.location.pathname));

  const initialListingParams: RecordingListingParamsType = _get(props, 'location.state', {});
  useEffect(
    () => {
      setSelectedTab(getCurrentIndex(history.location.pathname));
    },
    [history.location.pathname]
  );

  const getTitle = _memoize((tab: number) => {
    const tabName = getRouteForIndex(keyToActiveIndex, tab, RECORDINGS_TABS.WATCHLIST);
    if (tabName === RECORDINGS_TABS.RECORDINGS_FEED) {
      return (
        <>
          <Icon classes={['icon-record']} />
          <span>{intl.get('headerRecordingsFeed')}</span>
        </>
      );
    }
    if (tabName === RECORDINGS_TABS.MY_RECORDINGS) {
      return (
        <>
          <Icon classes={['icon-record']} />
          <span>{intl.get('headerMyRecordings')}</span>
        </>
      );
    }
    return (
      <>
        <Icon classes={['icon-record']} />
        <span>{intl.get('headerRecordingsWatch')}</span>
      </>
    );
  });

  const panes = canViewOwnRecordings || canViewAllRecordings ? [
    {
      menuItem: (
        {
          key: RECORDINGS_TABS.WATCHLIST,
          content: (<div data-test="watchListRecordings">{intl.get('headerRecordingsWatch')}</div>)
        }),
      render: () => (
        <Tab.Pane key={'0'} attached={false}>
          <RecordingsFeedWithWatchLater initialListingParams={initialListingParams} />
        </Tab.Pane>
      )
    }
  ] : [];

  if (canViewOwnRecordings) {
    panes.unshift({
      menuItem: (
        {
          key: RECORDINGS_TABS.MY_RECORDINGS,
          content: (<div data-test="myRecordings">{intl.get('headerMyRecordings')}</div>)
        }),
      render: () => (
        <Tab.Pane key={'0'} attached={false}>
          <RecordingsFeedWithMyRecordings initialListingParams={initialListingParams} />
        </Tab.Pane>
      )
    });
  }

  if (canViewAllRecordings || canViewOwnRecordings) {
    panes.unshift({
      menuItem: (
        {
          key: RECORDINGS_TABS.RECORDINGS_FEED,
          content: (<div data-test="recordingsFeed">{intl.get('headerRecordingsFeed')}</div>)
        }),
      render: () => (
        <Tab.Pane key={'0'} attached={false}>
          <RecordingsFeedWithFeedAll initialListingParams={initialListingParams} />
        </Tab.Pane>
      )
    });
  }

  return (
    <div className={styles.container}>
      <ListViewLayoutHeader title={getTitle(selectedTab)} />
      <div className="tabset">
        <Tab
          activeIndex={getCurrentIndex(history.location.pathname)}
          menu={{secondary: true, pointing: true}}
          panes={panes}
          onTabChange={(_, { activeIndex }) => handleTabChange({
            selectedTab, setSelectedTab, keyToActiveIndex, activeIndex, push: history.push
          })}
        />
      </div>
    </div>
  );
};

export default withRouter(Recordings);
