import { dataWarehouseSelectors } from '@lifesize/nucleus';
import Icon from 'components/Common/Icon';
import TableNoData from 'components/Common/TableNoData';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _matchesProperty from 'lodash/matchesProperty';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DWHCallHistoryResponse, DWHCallHistoryResponseType, } from '../../../interfaces/Dashboard';
import { Global } from '../../../state/Global';
import { formatMinutesForDisplay, formatStringDateTimeForDisplay } from '../../../utils/date-time-utils';
import { hashCodeFromString } from '../../../utils/string-utils';
import ListViewComponent from '../../ListView/ListViewComponent';
import * as dashStyles from '../Dashboard.scss';
import { DashboardLoader } from '../DashboardLoader';
import * as styles from './Tables.scss';
import { TableTitle } from './TableTitle';
import { sortData, ViewName } from './TableUtils';

interface Props {
  callHistoryData: DWHCallHistoryResponse;
}

/**
 * Need to take in an array of DWHCallHistoryResponseType,
 * parse it into an array of DWHConferenceType
 */
const parseCallHistoryDataForDisplay = (data: Array<DWHCallHistoryResponseType>): Array<DWHCallHistoryResponseType> => {
  const returnArray: DWHCallHistoryResponseType[] = [];
  for (let call of data) {
    // Create a unique id from a hash of the name and date
    const conferenceHash = hashCodeFromString(call.conferenceName + call.startDate);
    const item = _find(returnArray, _matchesProperty('hash', conferenceHash.toString()));
    if (item) {
      // Increment the count if we've see this conference before
      item.participantsCount++;
    } else if (returnArray.length < 6) { // prevent returnArray from getting too large on huge datasets.
      // Create a new entry
      const type: DWHCallHistoryResponseType = {
        groupId: call.groupId,
        conferenceId: call.conferenceId,
        extension: call.extension,
        conferenceName: call.conferenceName,
        startDate: call.startDate,
        durationMinutes: call.durationMinutes,
        participantsCount: 1,
        hash: conferenceHash.toString()
      };
      returnArray.push(type);
    }
  }
  return returnArray;
};

export const CallHistoryTable = ({callHistoryData}: Props) => {
  const divId = 'callHistory';
  if (!callHistoryData || callHistoryData.isFetching) {
    return (
      <DashboardLoader divId={divId} title={intl.get('callHistory')}/>
    );
  }

  const data = _get(callHistoryData, 'data.data', []) as Array<DWHCallHistoryResponseType>;
  if (!callHistoryData.isFetching && data) {
    // If provider is an empty array,
    // 'No results found' will display in the middle of the grid @see ListViewComponent
    const parsedData = data.length > 0 ? parseCallHistoryDataForDisplay(data) : [];
    return (
      <div className={styles.container} id={divId}>
        <TableTitle title={intl.get('callHistory')}/>
        <ListViewComponent
          sortable={false}
          data={parsedData}
          columns={
            [{
              Header: intl.get('name'),
              accessor: 'conferenceName'
            }, {
              Header: intl.get('date'),
              accessor: 'startDateFormatted',
                Cell: (row) => {
                  return formatStringDateTimeForDisplay(row.original.startDate);
                }
            }, {
              Header: intl.get('duration'),
              accessor: 'durationMinutes', // Needed to sort numerically
              Cell: (row) => {
                return formatMinutesForDisplay(row.original.durationMinutes);
              }
            }, {
              Header: intl.get('participants'),
              accessor: 'participantsCount'
            }]
          }
          showPagination={false}
          minRows={5}
          maxRows={5}
          pageNumber={0}
        />
        <div className={styles.footer}>
          <Link id="view_all" to={'/drilldown/callhistory'}>
            <span>{intl.get('viewAll')}</span>
            <Icon classes={['icon-back']} rotate={180} />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container} id={divId}>
      <div className={dashStyles.title}>
        {intl.get('callHistory')}
      </div>
      <div className={dashStyles.noResultsContainer}>
        <TableNoData/>
      </div>
    </div>
  );
};

export default CallHistoryTable;

function mapStateToProps(state: Global) {
  return {
    callHistoryData: sortData(dataWarehouseSelectors.conferences(state), ViewName.CallHistory),
  };
}

const mapDispatchToProps = (dispatch: Function) => ({});

export const CallHistoryTableContainer = connect(mapStateToProps, mapDispatchToProps)(CallHistoryTable);
