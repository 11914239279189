import { meetingsActions, meetingsSelectors, nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';
import _has from 'lodash/has';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from 'actions/modalActions';
import MaxInactiveDaysExpirationModal
  from 'components/Modals/MaxInactiveDaysExpirationModal/MaxInactiveDaysExpirationModal';
import { selectPayload } from 'selectors/modalSelector';

const mapStateToProps = (state: Global, ownProps: object) => {
  const modalPayload = selectPayload(state);
  let maxInactiveDaysNucleusState;
  const isOTM = _get(ownProps, 'meetingType') === nucleusConstants.MEETING_TYPE_ONE_TIME;
  if (isOTM) {
    maxInactiveDaysNucleusState = meetingsSelectors.maxInactiveDaysOTMSelector(state);
  } else {
    maxInactiveDaysNucleusState = meetingsSelectors.maxInactiveDaysSelector(state);
  }
  if (_has(maxInactiveDaysNucleusState, 'error')) {
    // Error condition
    return {
      errorMessage: intl.get('updateMaxInactiveDaysFailed'),
      isBusy: false
    };
  }

  return {
    errorMessage: null,
     isBusy: _get(
      maxInactiveDaysNucleusState,
      isOTM ? 'isMaxInactiveDaysOTMBusy' : 'isMaxInactiveDaysBusy',
      false,
    ),
    itemsToBeDeleted: _get(modalPayload, 'itemsToBeDeleted'),
    requestedNumberOfDays: _get(modalPayload, 'requestedNumberOfDays'),
    cancelCallback: _get(modalPayload, 'cancelCallback')
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: object) => ({

  cancelAction: () => {
    // Tell MaxInactiveDays component to not change the # of days by callings it's reset() handler
    const cancelCallback = _get(ownProps, 'cancelCallback', () => null);
    cancelCallback();
    dispatch(actions.closeModal());
  },

  confirmAction: async (payload: object) => {
    // Set the new # of inactive days
    const numberOfDays = _get(payload, 'requestedNumberOfDays', 0);
    if (_get(ownProps, 'meetingType') === nucleusConstants.MEETING_TYPE_ONE_TIME) {
      await dispatch(meetingsActions.putMeetingsMaxInactiveDaysOTM(numberOfDays));
    } else {
      await dispatch(meetingsActions.putMeetingsMaxInactiveDays(numberOfDays));
    }
    dispatch(actions.closeModal());
  },
  closeModal: () => {
    dispatch(actions.closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MaxInactiveDaysExpirationModal);
