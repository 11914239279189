import _get from 'lodash/get';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import intl from 'react-intl-universal';
import { DWHCallDataResultType, DWHResponse } from '../../../interfaces/Dashboard';
import { sortCallDataByDateAsc } from '../../../utils/callDataUtils';
import { generateCallChartConfig } from '../../../utils/highChartsConfigs';
import { DashboardLoader } from '../DashboardLoader';
import { DashboardNoResults } from '../DashboardNoResults';
import * as styles from './Charts.scss';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import { connect } from 'react-redux';
import { Global } from '../../../state/Global';

interface Props {
  callDataByDays: DWHResponse;
}

const getConfigForChart = (callDataByDays: DWHResponse, title: string, yAxisTitle: string) => {
  let config = null;
  const data = _get(callDataByDays, 'data.data', null) as Array<DWHCallDataResultType> | null;
  if (data) {
    const sortedData = sortCallDataByDateAsc(data);
    const categories = sortedData.map((result) => {
      // @ts-ignore
      return result.period;
    });
    const series =  sortedData.map((result) => {
      return result.totalCalls;
    });
    config = generateCallChartConfig(categories, series, '#4c87b9', title, yAxisTitle);
  }
  return config;
};

const CallsPerDay = ({callDataByDays}: Props) => {
  const divId = 'callsPerDay';
  if (callDataByDays.isFetching) {
    return (
      <DashboardLoader divId={divId} title={intl.get('callsPerDay')}/>
    );
  }
  const config =
    getConfigForChart(callDataByDays, intl.get('callsPerDay'), intl.get('calls'));
  if (config) {
    return (
      <div className={styles.container} id={divId}>
        <ReactHighcharts
          isPureConfig={true}
          neverReflow={true}
          config={config}
        />
      </div>
    );
  }

  return (
    <DashboardNoResults divId={divId} title={intl.get('callsPerDay')}/>
  );
};

export default CallsPerDay;

function mapStateToProps(state: Global) {
  return {
    callDataByDays: dataWarehouseSelectors.callsByDaysDevicesSelector(state),
  };
}

const mapDispatchToProps = (dispatch: Function) => ({});

export const CallsPerDayContainer = connect(mapStateToProps, mapDispatchToProps)(CallsPerDay);
