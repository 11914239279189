import { Field, Form } from 'formik';
import * as styles from './RoomSystemDisplayName.scss';
import * as formStyles from 'components/Modals/ModalForms.scss';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { RoomSystemUsageType } from 'constants/roomSystemConstants';
import React from 'react';
import OkResetWaitButtonGroup from 'components/Common/OkResetWaitButtonGroup';
import _isEmpty from 'lodash/isEmpty';
import { DUPLICATE_SYSTEM_NAME_ERROR, OTHER_SYSTEM_NAME_ERROR } from 'utils/roomSystemUtils';
import { RoomSystemDisplayNameFormProps } from 'interfaces/RoomSystems';

// With HTML formatting for line wrap
const getNameError = (error: string, duplicate: string = '') => {
  if (error === DUPLICATE_SYSTEM_NAME_ERROR) {
    return intl.getHTML('duplicateRoomSystemNameError', { value: duplicate });
  }
  if (error === OTHER_SYSTEM_NAME_ERROR) {
    return intl.getHTML('nameRoomSystemError');
  }
  return error;
};

const RoomSystemDisplayNameForm = (props: RoomSystemDisplayNameFormProps) => {
  const { values, errors, touched, submitForm, handleReset, roomSystem, loading } = props;
  return (
    <Form className={styles.nameForm}>
      <div className={styles.formContainer}>
        <div className={formStyles.logicalGroup}>
          {/*Name*/}
          <div className={classnames(formStyles.formGroup, (roomSystem.usageType !== RoomSystemUsageType.PERSONAL) && formStyles.hasValidation)}>
            {roomSystem.usageType !== RoomSystemUsageType.PERSONAL
              ? (
                <div className={classnames(formStyles.inputContainer, styles.inputContainer)}>
                  <div className={styles.inputWithButtons}>
                    <Field
                      className={formStyles.input}
                      type="text"
                      name="displayName"
                      onKeyPress={(evt: React.KeyboardEvent) => {
                        if (evt.which === 13) {
                          submitForm();
                        }
                      }}
                    />
                    {(roomSystem.displayName !== values.displayName) &&
                    <div className={styles.buttonContainer}>
                      <OkResetWaitButtonGroup
                        isSubmitting={loading}
                        hasErrors={!_isEmpty(errors)}
                        isAssociatedFieldTouched={true}
                        handleReset={handleReset}
                        handleSubmit={submitForm}
                      />
                    </div>
                    }
                  </div>
                  <ul className={classnames(formStyles.inputMessages, styles.errorMessages)}>
                    {touched && errors.displayName && (
                      <li className={formStyles.error}>{getNameError(errors.displayName.toString(), values.displayName || '')}</li>
                    )}
                  </ul>
                </div>
              )
              : (
                <div className={classnames(formStyles.inputContainer, styles.inputContainer, styles.nonEditable)}>
                  <div className={formStyles.settingValue}>{roomSystem.displayName}</div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </Form>
  );
};

export default RoomSystemDisplayNameForm;
