import { connect } from 'react-redux';
import { actions } from 'actions/modalActions';
import { AlexaDisconnectModal } from 'components/Modals/AlexaDisconnectModal/AlexaDisconnectModal';
import alexaIntegrationThunks from 'thunks/alexaIntegrationThunks';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async () => {
    dispatch(actions.closeModal());
  },
  disconnectAlexa: () => ( dispatch(alexaIntegrationThunks.disconnectAlexa()) )
});

export default connect(mapStateToProps, mapDispatchToProps)(AlexaDisconnectModal);
