import React, { useEffect } from 'react';
import Dashboard from '@lifesize/react-components/dist/Dashboard';
import { featureSelectors } from '@lifesize/nucleus';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import moment, { Moment } from 'moment';
import Icon from 'components/Common/Icon';
import { getNewDWHDateRangePresets, isOutsideRange } from 'utils/date-time-utils';
import { DWHLiveStreamResultType, DWHLiveStreamResultTypeV2 } from 'interfaces/Dashboard';
import { CALL_HISTORY_URL, LIVE_STREAM_URL, OLD_DASHBOARD, ROOM_TOTALS_URL, USER_TOTALS_URL } from 'constants/dashboard';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import * as styles from './DashboardTab.scss';
import { Query } from 'react-apollo';
import LiveStreamsHistoryQuery from 'queries/dashboard/LiveStreamsHistoryQuery.gql';
import _get from 'lodash/get';
import { liveStreamV2Cutoff, parseV2DataForDisplay } from 'utils/liveStreamUtils';
import { useSelector } from 'react-redux';
import { demoDashboardSelector } from 'selectors/accountSelectors';
import { BlurModal } from 'components/BlurModal/BlurModal';
import { FEATURE_FLAG_REMOVE_OLD_DASHBOARD } from 'constants/featureFlags';

// We use memo with a deep compare to prevent unnecessary rerenders of the dashboard component.
const DashboardTab = React.memo((props: RouteComponentProps<void> & Props) => {
  const {
    currentLocale,
    dateStart,
    dateEnd,
    onDateChange,
    liveStream,
    history,
    showCallHistoryWidget,
    fetchListData,
    hasOldData,
    hasNewData,
    showLiveStreaming
  } = props;
  const [oldDataFetched, setOldDataFetched] = React.useState(false);
  const blurDashboard = useSelector(demoDashboardSelector);
  const removeOldDashboard = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_REMOVE_OLD_DASHBOARD);

  useEffect(() => {
    if (hasOldData && !oldDataFetched && !removeOldDashboard) {
      setOldDataFetched(true);
      fetchListData();
    }
  },        [hasOldData, removeOldDashboard]);

  const cutOffDate = moment(liveStreamV2Cutoff);
  const variables = {
    startTime: props.dateStart > cutOffDate ? props.dateStart.format('X').toString() : cutOffDate.format('X').toString(),
    endTime: props.dateEnd > cutOffDate ? props.dateEnd.format('X').toString() : cutOffDate.format('X').toString(),
  };

  return (
    <div className={styles.container}>
      <div className={styles.dashboardSpace}>
        {hasNewData ?
          <Query query={LiveStreamsHistoryQuery} variables={{ ...variables }} fetchPolicy="network-only">
            {({ data, loading, error }) => {
              const liveStreams = _get(data, 'liveStreams.data', []);
              let newLiveStream = liveStream ? {...liveStream} : undefined;

              if (showLiveStreaming) {
                const parsedData = parseV2DataForDisplay(liveStreams);
                let liveStreamHistory = [...parsedData, ..._get(liveStream, 'data.liveStreamHistory')];
                liveStreamHistory = liveStreamHistory.sort((a: DWHLiveStreamResultTypeV2, b: DWHLiveStreamResultTypeV2) => b.startDate - a.startDate);
                liveStreamHistory.length = Math.min(liveStreamHistory.length, 5);

                newLiveStream = {
                  liveStreamHistoryUrl: LIVE_STREAM_URL,
                  loading: liveStream.loading || loading,
                  data: {
                    liveStreamHistory
                  },
                  error: (liveStream.error || error) && `${liveStream.error} ${error}`
                };
              }

              return (
                <>
                  {blurDashboard && <BlurModal modalBody={intl.get('upgradeDashboard')} />}
                  <Dashboard
                    currentLocale={currentLocale}
                    dateStart={dateStart}
                    dateEnd={dateEnd}
                    handleRangeChange={onDateChange}
                    dateRangePresets={getNewDWHDateRangePresets(() => history.push(OLD_DASHBOARD), removeOldDashboard)}
                    callHistoryUrl={showCallHistoryWidget ? CALL_HISTORY_URL : undefined}
                    roomTotalsUrl={ROOM_TOTALS_URL}
                    userTotalsUrl={USER_TOTALS_URL}
                    isOutsideRange={isOutsideRange}
                    liveStream={showLiveStreaming ? newLiveStream : undefined}
                    blurDashboard={blurDashboard}
                  />
                </>
              );
            }}
          </Query>
          :
          <Dashboard
            currentLocale={currentLocale}
            dateStart={dateStart}
            dateEnd={dateEnd}
            handleRangeChange={onDateChange}
            dateRangePresets={getNewDWHDateRangePresets(() => history.push(OLD_DASHBOARD))}
            callHistoryUrl={showCallHistoryWidget ? CALL_HISTORY_URL : undefined}
            roomTotalsUrl={ROOM_TOTALS_URL}
            userTotalsUrl={USER_TOTALS_URL}
            isOutsideRange={isOutsideRange}
            liveStream={showLiveStreaming ? liveStream : undefined}
          />
        }
      </div>
      {!blurDashboard && !removeOldDashboard && <div className={styles.linkToAltDashboard}>
        <Link to={OLD_DASHBOARD}>
          <Icon classes={['icon-recents']} />
          <span>{intl.get('dashboardLinkOldData')}</span>
        </Link>
      </div>}
    </div>
  );
},                              (prevProps, nextProps) => {
  const otherProps = _omit(prevProps, 'loading');
  const otherNextProps = _omit(nextProps, 'loading');
  // Smooth out the number of rerenders by only comparing when the state goes from loading to not loading
  if ((prevProps.loading && !nextProps.loading || ( otherProps.liveStream.loading && !otherNextProps.liveStream.loading ))) { return false; }
  return _isEqual(otherProps, otherNextProps);
});

interface LiveStreamProps {
  liveStreamHistoryUrl: string;
  loading: boolean;
  data: {
    liveStreamHistory: Array<DWHLiveStreamResultType>
  };
  error: string;
}

interface Props {
  currentLocale: string;
  dateStart: Moment;
  dateEnd: Moment;
  liveStream: LiveStreamProps;
  fetchListData: () => void;
  hasOldData: boolean;
  hasNewData: boolean;
  showLiveStreaming: boolean;
  loading: boolean;
  onDateChange: (dateStart: Moment, dateEnd: Moment) => void;
  showCallHistoryWidget: boolean;
}

export default DashboardTab;
