import {
  MOV_FILE_TYPE,
  MP4_FILE_TYPE,
  WMV_FILE_TYPE,
  HEVC_FILE_TYPE,
  ONE_GB,
} from './fileConstants';

export enum RecordingLimits {
  RECORDINGS_FEED = -1,
  MY_RECORDINGS_FEED = -1,
  WATCH_LIST_FEED = -1,
  MY_RECORDINGS = 5,
  WATCH_LATER = 5,
  PAGE_SIZE = 12
}

export enum RecordingRequestObjectFeed {
  FEED_ALL = 'all',
  FEED_WATCH_LATER = 'wlater',
  FEED_MY_RECORDINGS = 'owned',
}

export enum RecordingRequestObjectSort {
  SORT_DATE = 'date',
  SORT_VIEWS = 'view'
}

export enum RecordingRequestObjectTimeLine {
  TIMELINE_ANY = 'any',
  TIMELINE_DAY = 'day',
  TIMELINE_WEEK = 'week',
  TIMELINE_MONTH = 'month',
}

export const RECORDING_NAME_MAX_LENGTH = 224;

export const UNKNOWN_MEETING_NAME = 'translate-this';

export const MAX_UPLOAD_FILE_SIZE = 5 * ONE_GB;

export const UPLOAD_MONITORING_POLLING_INTERVAL = 3000;

export enum UploadRecordingStatus {
  UPLOADING = 'Uploading',
  PROCESSING = 'Processing',
  FAILED = 'Failed',
  COMPLETED = 'Completed'
}

export const SHARE_WITH = {
  MEETING_OWNER: 'private',
  MEETING_PARTICIPANTS: 'default',
  VIEWERS: 'viewers',
  PUBLIC: 'public'
};

export const UPLOAD_API_ENDPOINT_SINGLETON = '/upload';
export const UPLOAD_API_ENDPOINT_LIST = '/uploads';

export enum RECORDINGS_TABS {
  RECORDINGS_FEED = '/recordings/recordingsFeed',
  MY_RECORDINGS = '/recordings/myRecordings',
  WATCHLIST = '/recordings/watchListRecordings'
}

export const acceptedRecordingUploadTypes = [
  MP4_FILE_TYPE,
  MOV_FILE_TYPE,
  WMV_FILE_TYPE,
  HEVC_FILE_TYPE
];
