import { createSelector } from 'reselect';
import { Global } from 'state/Global';

/**
 * Get config URL from
 */
const devicePreferencesState = (state: Global) => state.app?.deviceCustomization;

const selectConfigUrl = createSelector(
  devicePreferencesState,
  (state) => state?.url || ''
);

export {
  selectConfigUrl
};
