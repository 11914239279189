import React from 'react';
import intl from 'react-intl-universal';
import Modal from '../../Modal/ModalComponent/ModalComponent';
import * as styles from './ConfirmModal.scss';

interface Props {
  closeModal: () => void;
  confirmAction: (payload?: object) => void;
  confirmTitle: string;
  confirmButtonText?: string;
  closeMessage?: string;
  errorMessage?: string;
  data?: object;
  isBusy: boolean;
}

const ConfirmModal = ({
                        closeModal,
                        confirmAction,
                        confirmButtonText,
                        confirmTitle,
                        data,
                        isBusy,
                        closeMessage,
                        errorMessage
                      }: Props) => {
  return (
    <Modal
      className={styles.container}
      onSave={
        () => {
          confirmAction(data ? data : undefined);
        }
      }
      closeModal={closeModal}
      saveText={confirmButtonText ? confirmButtonText : intl.get('modalOk')}
      closeText={closeMessage ? closeMessage : intl.get('cancel')}
      isSaveButtonBusy={isBusy}
      isSaveButtonDisabled={isBusy}
      hideSaveButton={false}
      errorMessage={errorMessage}
    >
      <div className={styles.message}>{confirmTitle}</div>

    </Modal>
  );
};

export { ConfirmModal };
