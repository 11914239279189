import { createAction } from '@lifesize/typescript-react-redux-utils';

export enum CsvActionType {
  FETCH_CSV_DATA_START =  'FETCH_CSV_DATA_START',
  FETCH_CSV_DATA_FINISH =  'FETCH_CSV_DATA_FINISH',
  FETCH_CSV_DATA_RESET =  'FETCH_CSV_DATA_RESET',
  DO_EXPORT = 'DO_EXPORT'
}

export enum ExportName {
  ROOM_SYSTEMS = 'ROOM_SYSTEMS',
  MEETINGS = 'MEETINGS',
  USERS = 'USERS',
  GROUP_CONTACTS = 'GROUP_CONTACTS',
  LIVE_STREAM_QUESTIONS = 'LIVE_STREAM_QUESTIONS',
  LIVE_STREAM_VIEWERS = 'LIVE_STREAM_VIEWERS'
}

export const actions = {
  fetchCsvDataStart: () =>
    createAction(CsvActionType.FETCH_CSV_DATA_START),
  fetchCsvDataFinish: () =>
    createAction(CsvActionType.FETCH_CSV_DATA_FINISH),
  fetchCsvDataReset: () =>
    createAction(CsvActionType.FETCH_CSV_DATA_RESET),
  doCsvExport: (query: ExportName, responseHandler: Function) =>
    createAction(CsvActionType.DO_EXPORT, {
      query,
      responseHandler: (response: {}) => responseHandler(response)
    })
};

export default actions;
