import React, { useState } from 'react';
import intl from 'react-intl-universal';
import styles from './DeleteFreemiumAccount.scss';
import DeleteAccountConfirmationModal from './DeleteAccountConfirmationModal';

const DeleteFreemiumAccount = () => {
  const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);

  return (
    <article className={styles.deleteRow}>
      <button
        id="btnDeleteAccount"
        data-test="settingsSection.deleteAccount"
        name="btnDeleteAccount"
        className={styles.deleteButton}
        onClick={() => setShowConfirmationModal(true)}
        role="button"
        type="button"
      >
        {intl.get('deleteAccount')}
      </button>
      {showConfirmationModal && <DeleteAccountConfirmationModal closeModal={() => setShowConfirmationModal(false)} />}
    </article>
  );
};

export default DeleteFreemiumAccount;
