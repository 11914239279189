import React from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { RecordingDataItem } from 'interfaces/RecordingDataItem';
import { RecordingListingParamsType } from 'interfaces/Recording';
import * as styles from './RecordingsListItem.scss';
import { secondsToHms } from '../../utils/date-time-utils';

interface Props {
  recording: RecordingDataItem;
  showAdditionalDetails?: boolean;
  listingParams: RecordingListingParamsType;
}

export const RecordingsListItem = ({ recording, showAdditionalDetails, listingParams }: Props) => {
  const to = {
    pathname: `/singleRecording/${recording.id}`,
    state: {
      ...listingParams
    }
  };
  return (
    <div className={`${styles.container}`}>
      <Link
        className={styles.dataContainer}
        to={to}
      >
        <img
          className={styles.previewImage}
          src={recording.thumbnails.medium}
        />
      </Link>
      <Link
        className={styles.dataContainer}
        to={to}
      >
        <span className={styles.title}>
          {recording.name}
        </span>
      </Link>
      <div className={`${styles.dateTime} ${styles.dataContainer}`}>
        {
          intl.get(
            'doNotTranslateDate',
            { dateObj: new Date(recording.date_created * 1000) }
          )
        }
        &nbsp;
        {
          intl.get(
            'doNotTranslateTime',
            { timeObj: new Date(recording.date_created * 1000) }
          )
        }
      </div>
      {(showAdditionalDetails) ? <div className={styles.dataContainer}>{recording.owner}</div> : null}
      {
        (showAdditionalDetails) ?
          <div className={styles.dataContainer}>
            {
              secondsToHms(recording.duration_seconds)
            }
          </div> : null
      }
    </div>
  );
};

export default RecordingsListItem;
