import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { ChildDataProps, Query, QueryResult, useLazyQuery } from 'react-apollo';
import _get from 'lodash/get';
import { RouteComponentProps } from 'react-router-dom';
import { SortingRule } from 'react-table';
import { DWHPastConferenceType, DWHPastMeetingsQueryResponseType } from 'interfaces/Dashboard';
import { SortDirection } from 'constants/queryConstants';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import ReportHeader from '../ReportHeader';
import pastMeetingsColumnConfig from './PastMeetingsColumnConfig';
import PastMeetingsTabQuery from 'queries/pastMeetings/PastMeetingsTabQuery.gql';
import RequestConferenceExportQuery from 'queries/conferenceExports/requestConferenceExportQuery.gql';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { accountSelectors } from '@lifesize/nucleus';
import { ACCOUNT_FEATURE_PAST_MEETINGS_ENABLED } from 'constants/accountFeatureConstants';
import { selectEcommerceLoading, selectEcommerceSupported } from 'selectors/ecommerceSelector';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import EcommercePrompt from 'components/EcommercePrompt/EcommercePrompt';
import { PLAN_TYPE_HOST_PLUS } from 'constants/ecommerceConstants';
import { SetSortInterface } from 'interfaces/Tables';
import downloadNotificationActions from 'actions/downloadNotificationActions';
import exportThunks from 'thunks/exportThunks';
import { useMount } from 'hooks/useMount';
import moment from 'moment';
import { demoDashboardSelector } from 'selectors/accountSelectors';
import { BlurModal } from 'components/BlurModal/BlurModal';
import * as styles from './PastMeetingsTab.scss';
import { ExportType } from 'interfaces/Downloads';

interface Props extends RouteComponentProps {
  isFetching: boolean;
  startTimestamp: number;
  endTimestamp: number;
  searchString: string;
  pageNumber: number;
  fetchMoreRows: Function;
  listData: Array<DWHPastConferenceType>;
}

type CallHistoryResponse = {
  pastMeetings: DWHPastMeetingsQueryResponseType;
};

let cursor = false;

const PastMeetingsTab = (props: ChildDataProps<Props, CallHistoryResponse>) => {
  const defaultSort: SetSortInterface = {
    sortBy: 'startedAt',
    order: SortDirection.DESC
  };
  const initialSort = _get(props, 'location.state.sort', defaultSort);
  const initialFilterString = _get(props, 'location.state.search');
  const blurDashboard = useSelector(demoDashboardSelector);
  const dispatch = useDispatch();
  const isMounted = useMount();
  const [sort, setSort] = useState<SetSortInterface>(initialSort);
  const [search, setSearch] = useState(initialFilterString);

  const [requestExport, {loading: isExporting, data: requestData}] = useLazyQuery(
    RequestConferenceExportQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(
    () => () => {dispatch(downloadNotificationActions.dismissDownloadNotification()); },
    []
  );

  if (!isExporting && requestData) {
    dispatch(downloadNotificationActions.createDownloadNotification());
    dispatch(downloadNotificationActions.addPendingDownloads([requestData.createConferenceExport.id]));
    dispatch(exportThunks.getExports(ExportType.CONFERENCE));
  }

  const accountFeaturePastMeetings = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_PAST_MEETINGS_ENABLED);
  const isEcommerceSupported = useSelector(selectEcommerceSupported);
  const ecommerceAccountLoading = useSelector(selectEcommerceLoading);

  if (ecommerceAccountLoading) {
    return (
      <>
        {blurDashboard && <BlurModal modalBody={intl.get('upgradePastMeetings')} />}
        <LoadingIndicator />
      </>
    );
  }

  if (!accountFeaturePastMeetings && isEcommerceSupported && !blurDashboard) {
    return (
      <EcommercePrompt
        buttonId="btnPastMeetingsUpgrade"
        message={intl.get('pastMeetingsUpgradeMessage')}
        extraParams={{
          plan: PLAN_TYPE_HOST_PLUS
        }}
      />
    );
  }

  const onFetchData = ({sorted: [column]}: { sorted: Array<SortingRule>}) => {
    if (!column || !isMounted.current) {
      return;
    }
    setSort({
      sortBy: column.id,
      order: column.desc ? SortDirection.DESC : SortDirection.ASC,
    });
  };

  const { sortBy, order } = sort;
  const { startTimestamp, endTimestamp } = props;
  const queryVariables = { sortBy, order, startTimestamp, endTimestamp, search };

  const handleExportData = () => {
    if (isMounted.current) {
      const startDate = moment(startTimestamp * 1000).format(intl.get('dateFormat'));
      const endDate = moment(endTimestamp * 1000).format(intl.get('dateFormat'));
      const pastMeetings = intl.get('pastMeetingFileName');
      const exportTitle = `${pastMeetings}_${startDate}_${endDate}.csv`;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      requestExport({ variables: { startTimestamp, endTimestamp, filename: exportTitle, timezone }});
    }
  };

  return (
    <Query query={PastMeetingsTabQuery} variables={{ ...queryVariables }} fetchPolicy="network-only">
      {({ loading, error, data, fetchMore }: QueryResult<object>) => {
        const listData = !loading ? _get(data, 'pastMeetings.conferences', []) : [];
        cursor = _get(data, 'pastMeetings.metadata.cursor', false);

        const fetchMoreRows = async (callback?: Function) => {
          // If directory list isn't set our initial load hasn't finished yet, so we
          // can't fetch more. We also bail if there are no more records to load.
          if (!cursor || !isMounted.current) {
            return {};
          }

          return fetchMore({
            variables: {
              cursor
            }, // Pass in the previous cursor to our query.
            // tslint:disable-next-line
            updateQuery: (prev: any, {fetchMoreResult}: any) => {
              // Merge our new results into previous cached results.
              if (_get(fetchMoreResult, 'errors', false)) {
                throw _get(fetchMoreResult, 'errors', false);
              }
              const previousDirectoryList = _get(prev, 'pastMeetings', {});
              const fetchMoreResultDirectoryList = _get(fetchMoreResult, 'pastMeetings', {});
              const conferences = [
                ..._get(previousDirectoryList, 'conferences', []),
                ..._get(fetchMoreResultDirectoryList, 'conferences', []),
              ];
              if (callback) {
                callback(conferences);
              }
              return {
                ...prev,
                pastMeetings: {
                  ...previousDirectoryList,
                  metadata: _get(fetchMoreResultDirectoryList, 'metadata', {}),
                  conferences,
                },
              };
            },
          });
        };

        return (
          <>
            {blurDashboard && <BlurModal modalBody={intl.get('upgradePastMeetings')} />}
            <ListViewLayout
              hideHeader={true}
              isFetching={false}
              numberOfPages={0}
              error={_get(error, 'message', undefined)}
            >
              <InfiniteTable
                columnConfig={pastMeetingsColumnConfig(sort, search, blurDashboard)}
                data={listData}
                enableSelect={false}
                defaultSort={[
                  {
                    id: sort.sortBy,
                    desc: sort.order === SortDirection.DESC
                  }
                ]}
                fetchMore={() => fetchMoreRows()}
                getTdProps={() => ({
                  style: {
                    alignItems: 'flex-start',
                    paddingTop: '16px',
                    paddingBottom: '16px',
                    lineHeight: '12px'
                  }
                })}
                getTheadTrProps={() => ({
                  style: {
                    paddingLeft: '5px'
                  }
                })}
                getTrProps={() => ({
                  style: {
                    paddingLeft: '5px'
                  }
                })}
                hasMoreRecords={cursor}
                keyField={'startedAt'}
                loading={loading}
                onFetchData={onFetchData}
                renderHeader={() => (
                  <ReportHeader
                    handleExportData={handleExportData}
                    isExporting={isExporting}
                    setSearch={setSearch}
                    initialSearch={search}
                    className={blurDashboard ? styles.blurred : undefined}
                  />
                )}
                rowHeightOverride={44}
              />
            </ListViewLayout>
          </>
        );
      }}
    </Query>
  );
};

export default withRouter(PastMeetingsTab);
