import React from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import { Tab } from 'semantic-ui-react';
import Icon from 'components/Common/Icon';
import { OnlineStatus } from 'components/RoomSystems/OnlineStatus/OnlineStatus';
import { UNKNOWN, BASE_ROOM_SYSTEM_ROUTE, MONITORING_LINE_ITEMS } from 'constants/roomSystemConstants';
import ListViewTabLayout from 'components/ListViewTabLayout/ListViewTabLayout';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import {
  getVideoTypeDisplayText,
  isPlatformIcon,
  getUsageDisplayText,
} from 'utils/roomSystemUtils';
import { RoomSystem, DeviceMonitoringStatus } from 'typings/types';
import RoomSystemDisplayName from './RoomSystemDisplayName/RoomSystemDisplayName';
import SettingsTabContainer from 'containers/RoomSystem/SettingsTabContainer';
import ControllerTabContainer from 'containers/RoomSystem/ControllerTabContainer';
import MaintenanceTabContainer from 'containers/RoomSystem/MaintenanceTabContainer';
import * as styles from './RoomSystem.scss';
import { NoPermissionsBanner } from 'components/NoPermissionsBanner/NoPermissionsBanner';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  hasMaintenanceTab: boolean;
  data: {
    loading: boolean;
    getRoomSystem: RoomSystem;
    refetch: Function;
  };
  displayName: string;
  roomSystem: RoomSystem;
  serialNumber: string;
  canConnectTabletToIcon: boolean;
  deviceMonitoring: DeviceMonitoringStatus;
  hasMonitoringData: boolean;
}

const RoomSystemComponent = (props: RouteComponentProps<void> & Props) => {
  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];
  const [displayName, setDisplayName] = React.useState(props.displayName);
  const { deviceMonitoring, hasMonitoringData, data } = props;
  const hasMonitoringError = hasMonitoringData
    ? MONITORING_LINE_ITEMS.some(item => _get(deviceMonitoring, `metrics[${item}].value`) === false)
    : false;

  React.useEffect(() => {
    setDisplayName(props.displayName);
  },              [props.displayName]);

  if (_get(props, 'data.loading')) {
    return <LoadingIndicator />;
  }
  const { roomSystem, serialNumber } = props;
  const extension = _get(roomSystem, 'extension');
  const indexKeys = {
    [`${BASE_ROOM_SYSTEM_ROUTE}/${serialNumber}/settings`]: 0,
    [`${BASE_ROOM_SYSTEM_ROUTE}/${serialNumber}/controller`]: 1,
    [`${BASE_ROOM_SYSTEM_ROUTE}/${serialNumber}/maintenance`]: 2
  };

  const panes = _compact([
    {
      menuItem: {
        key: 'settings',
        content: intl.get('settings')
      },
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <SettingsTabContainer roomSystem={roomSystem} />
          </Tab.Pane>
        );
      }
    },
    {
      menuItem: {
        key: 'controller',
        content: intl.get('controllerType')
      },
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <ControllerTabContainer
              refetch={data.refetch}
              roomSystem={roomSystem}
              linkedDevices={_get(roomSystem, 'linkedDevices.records', [])}
            />
          </Tab.Pane>
        );
      }
    },
    (props.hasMaintenanceTab && {
      menuItem: {
        key: 'maintenance',
        content: (
          <div className={styles.maintenanceTabContainer}>
            {intl.get('maintenance')}
            {hasMonitoringError && (
              <div className={styles.badge}>
                <Icon classes={['icon-exclamation']} />
              </div>
            )}
          </div>
        )
      },
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <MaintenanceTabContainer
              roomSystem={roomSystem}
              deviceMonitoring={deviceMonitoring}
              hasMonitoringData={hasMonitoringData}
            />
          </Tab.Pane>
        );
      }
    })
  ]);

  return (
    <div className={styles.container}>
      {!canEditRoomSystems && <NoPermissionsBanner text={intl.get('cannotModifyRooms')} />}
      <ListViewTabLayout
        title={<RoomSystemDisplayName roomSystem={{ ...roomSystem, displayName }} setDisplayName={setDisplayName} />}
        breadcrumbs={(
          <div>
            <Link
              key="roomSystemsBreadcrumb"
              to={{
                pathname: BASE_ROOM_SYSTEM_ROUTE,
                state: _get(props, 'location.state', {})
              }}
            >
              {intl.get('roomSystems')}
            </Link>
            {' > '}
            <span>
              {displayName}
            </span>
          </div>
        )}
        subtitle={(
          <div>
            <div className={styles.flexCol}>
              <ul className={styles.headerList}>
                <li><OnlineStatus regStatus={(roomSystem.availability) ? roomSystem.availability.status : UNKNOWN} /></li>
                {extension && <li>{intl.get('extensionWithColon')} {extension}</li>}
                <li>{intl.get('usageTypeWithColon')} {getUsageDisplayText(roomSystem)}</li>
                <li>{intl.get('deviceTypeWithColon')} {getVideoTypeDisplayText(_get(roomSystem, 'platform'))}</li>
                {!!(isPlatformIcon(_get(roomSystem, 'platform')) && _get(roomSystem, 'remoteIP')) && (
                  <li>
                    {intl.get('ipWithColon')} <a target="_blank" href={`https://${_get(roomSystem, 'remoteIP')}`}>{_get(roomSystem, 'remoteIP')}</a>
                  </li>
                )}
                {hasMonitoringData && (
                  <li>
                    {intl.get('healthStatusWithColon')} {hasMonitoringError ? intl.get('warning') : intl.get('good')}
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
        key={'header'}
        panes={panes}
        indexKeys={indexKeys}
      />
    </div>
  );
};

export default RoomSystemComponent;
