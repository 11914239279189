import { MUSTANGHD, TABLET } from '../../../constants/roomSystemConstants';
import intl from 'react-intl-universal';

export default class Controller {
  label: string;
  id: string;
  isClickable: boolean = false;
  modal: string;
  constructor(systemName: string, modalType: string, phoneModelNumber: string) {
    this.modal = modalType;
    this.id = `hd-prefs-${systemName}`;
    if (modalType === MUSTANGHD) {
      this.label = intl.get('lifeSizePhoneHd');
      this.isClickable =  !['None', '', null, undefined].includes(phoneModelNumber);
    } else if (modalType === TABLET) {
      this.label = intl.get('tabletApp');
      this.isClickable = true;
    }
  }
}
