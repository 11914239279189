import Icon from 'components/Common/Icon';
import React from 'react';
import * as styles from './Header.scss';
import { UserProfileButton } from './UserProfileButton';
import { useSelector } from 'react-redux';
import DownloadsButton from 'components/DownloadsButton/DownloadsButton';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { PermissionTypes } from 'constants/permissionsConstants';

const Header: React.FunctionComponent<{}> = () => {
  const permissions = useSelector(selectAccountPermissions);

  return (
    <header className={styles.container}>
      <div className={styles.rightSide}>
        {permissions[PermissionTypes.ANY_DOWNLOADS] && <DownloadsButton />}
        <a
          className={styles.walkmeLink}
          href={'http://lifesize.com/help/admin-console'}
          id="walkme"
          target={'none'}
        >
          <Icon classes={['icon-walkme']}/>
        </a>
        <UserProfileButton/>
      </div>

    </header>
  );
};

export { Header };
