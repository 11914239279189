import { recordingActions, recordingsSelectors } from '@lifesize/nucleus';
import { actions } from 'actions/modalActions';
import { ConfirmModal } from 'components/Modals/ConfirmModal/ConfirmModal';
import _get from 'lodash/get';
import { Logger } from 'logger';
import _isEmpty from 'lodash/isEmpty';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import * as singleRecordingSelectors from 'selectors/SingleRecordingSelectors';
import { Global } from 'state/Global';

const mapStateToProps = (state: Global) => {
  const recordingId = singleRecordingSelectors.selectRecordingId(state);
  const deleteRecordingState = recordingsSelectors.deleteRecording(state );
  const confirmTitle = intl.get('deleteRecordingConfirmation');
  const confirmButtonText = intl.get('delete');
  const closeText = intl.get('modalCancel');
  const errorMessage  = _isEmpty(deleteRecordingState.error) ? undefined : intl.get('deleteRecordingError') ;
  return {
    confirmTitle: confirmTitle,
    confirmButtonText: confirmButtonText,
    closeMessage: closeText,
    errorMessage: errorMessage,
    data: { recordingId },
    isBusy: deleteRecordingState.isDeleting
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  confirmAction: async (payload: object) => {
    try {
      await dispatch(recordingActions.resetRecordingsDeleteErrorState());
      const recordingId = _get(payload, 'recordingId');
      await dispatch(recordingActions.deleteRecording(recordingId));
    } catch (e) {
      Logger.info('There was an error deleting a recording ' + JSON.stringify(e));
      return;
    }
    dispatch(actions.closeModal());
    window.history.back();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
