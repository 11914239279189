import uuid4 from 'uuid4';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';

export class MiddlewareHookConnector {
  subscriptions = {};
  subscribe = (type: string, callback: Function) => {
    let id = uuid4();
    if (this.subscriptions[type]) {
      this.subscriptions[type].set(id, callback);
    } else {
      this.subscriptions[type] = new Map([[id, callback]]);
    }
    return () => {
      this.subscriptions[type].delete(id);
      if (this.subscriptions[type].size === 0) {
        delete this.subscriptions[type];
      }
    };
  }
  trigger = (action: PayloadAction<string, object>) => {
    const payloadSubscriptions = this.subscriptions[action.type];
    if (payloadSubscriptions) {
      payloadSubscriptions.forEach((subscription: Function) => {
        subscription(action.payload);
      });
    }
  }
}

const middlewareConnector = new MiddlewareHookConnector();

export default middlewareConnector;
