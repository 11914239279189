import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Logger } from 'logger';
import intl from 'react-intl-universal';
import { Dropdown } from 'semantic-ui-react';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { Tooltip } from 'components/tooltip/Tooltip';
import { useQuery } from '@apollo/react-hooks';
import getRoomSystemLifesizeConnectQuery from 'queries/roomSystems/getRoomSystemLifesizeConnectQuery.gql';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Icon from 'components/Common/Icon';
import { ButtonType, WaitButton } from 'components/Modal/ModalComponent/WaitButton';
import { ConnectionType, ICON_300 } from 'constants/roomSystemConstants';
import { updateDeviceConnectSettings } from 'actions/deviceManagementActions';
import { AnyAction, Dispatch } from 'redux';
import { ConnectInfo, RoomSystem } from 'typings/types';
import classes from '../SettingsTabComponent.scss';
import connectClasses from './ConnectComponent.scss';
import { isConnectEnabled, isMarsEnabled } from 'utils/roomSystemUtils';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_LIFESIZE_CONNECT_LINK } from 'constants/featureFlags';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface CurrentStateInterface extends ConnectInfo {
  marsEnable: boolean;
  enable: boolean;
}

interface Props {
  roomSystem: RoomSystem;
}
const clearMessage = (setHasError: (value: boolean) => void, setMessage: (value: string) => void) => {
  setHasError(false);
  setMessage('');
};
const isValid = (enable: boolean, marsEnable: boolean, connectionType: ConnectionType, numDisplays: number | undefined) => {
  if (enable || marsEnable) {
    return (connectionType === ConnectionType.SOFTWARE || connectionType === ConnectionType.MARS_SOFTWARE) ? true : !!numDisplays;
  }
  return true;
};

const getConnectionTypes = (roomSystem: RoomSystem, hasSoftwareOption: boolean) => {
  const options = [];
  if (hasSoftwareOption) {
    options.push({
      key: 1,
      value: ConnectionType.SOFTWARE,
      text: intl.get('lifesizeConnectConnectionTypePresentationPort'),
    });
  }

  if (roomSystem.platform !== ICON_300) {
    options.push(
      {
        key: 2,
        value: ConnectionType.HARDWARE,
        text: intl.get('lifesizeConnectConnectionTypeDisplay2Port'),
      }
    );
  }
  return options;
};

const getMarsConnectionTypes = (roomSystem: RoomSystem, hasSoftwareOption: boolean) => {
  const options = [];
  if (hasSoftwareOption) {
    options.push({
      key: 1,
      value: ConnectionType.MARS_SOFTWARE,
      text: intl.get('lifesizeConnectConnectionTypePresentationPort'),
    });
  }

  if (roomSystem.platform !== ICON_300) {
    options.push(
      {
        key: 2,
        value: ConnectionType.MARS_HARDWARE,
        text: intl.get('lifesizeConnectConnectionTypeDisplay2Port'),
      }
    );
  }
  return options;
};

const handleSubmit = async (
  setHasError: (value: boolean) => void,
  setMessage: (value: string) => void,
  setIsBusy: (value: boolean) => void,
  setCurrentState: (value: object) => void,
  dispatch: Dispatch<AnyAction>,
  roomSystem: RoomSystem,
  enable: boolean,
  marsEnable: boolean,
  connectionType: ConnectionType,
  numDisplays: number | undefined,
  tpAppsEnabled: boolean,
  canEditRoomSystems: boolean
) => {
  if (!canEditRoomSystems) { return; }
  clearMessage(setHasError, setMessage);
  setIsBusy(true);
  try {
    // @ts-ignore
    const resp: AnyAction = await dispatch(updateDeviceConnectSettings({
      serialNumber: roomSystem.serialNumber,
      lsConnect: {
        connectType: connectionType,
        displayNumber: numDisplays,
        tpAppsEnabled: tpAppsEnabled
      }
    }));
    if (resp.type === updateDeviceConnectSettings.fulfilled.type) {
      setCurrentState({
        enable,
        marsEnable,
        connectType: connectionType,
        displayNumber: numDisplays,
        tpAppsEnabled: tpAppsEnabled
      });
      setMessage(intl.get('changesSaved'));
    } else {
      Logger.info(`Error updating connect settings: ${resp}`);
      setHasError(true);
      setMessage(intl.get('lifesizeConnectSaveError'));
    }
  } catch (e) {
    Logger.info(`Error updating connect settings: ${e}`);
    setHasError(true);
    setMessage(intl.get('lifesizeConnectSaveError'));
  }
  setIsBusy(false);
};
const ConnectComponent = (props: Props) => {
  const { roomSystem } = props;
  const { loading, error, data } = useQuery(getRoomSystemLifesizeConnectQuery, {
    variables: { serialNumber: roomSystem.serialNumber },
    fetchPolicy: 'network-only'
  });
  const dispatch = useDispatch();
  const [hasError, setHasError] = useState(false);
  const [message, setMessage] = useState('');
  const [enable, setEnable] = useState(false);
  const [marsEnable, setMarsEnable] = useState(false);
  const [tpAppsEnabled, setTPAppsEnabled] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [connectionType, setConnectionType] = useState<ConnectionType>(ConnectionType.NONE);
  const [numDisplays, setNumDisplays] = useState<number>(0);
  const [currentState, setCurrentState] = useState<CurrentStateInterface>({
    enable: false,
    marsEnable: false,
    connectType: ConnectionType.NONE,
    displayNumber: 0,
    tpAppsEnabled: false,
    chromeInstalled: false
  });
  const hasSoftwareOption = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_LIFESIZE_CONNECT_LINK);
  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  useEffect(
    () => {
      if (!loading && !error && data) {
        const connectInfo = data.getRoomSystem?.connectInfo;
        const currentEnable = isConnectEnabled(data.getRoomSystem);
        const currentMarsEnable = isMarsEnabled(data.getRoomSystem);
        const currentConnectionType = connectInfo?.connectType || ConnectionType.NONE;
        const currentNumDisplays = connectInfo?.displayNumber || 0;
        const currentTPappEnabled = connectInfo?.tpAppsEnabled || false;
        const currentchromeInstalled = connectInfo?.chromeInstalled || false;
        setEnable(currentEnable);
        setMarsEnable(currentMarsEnable);
        setConnectionType(currentConnectionType);
        setNumDisplays(currentNumDisplays);
        setTPAppsEnabled(currentTPappEnabled);
        setCurrentState({
          enable: currentEnable,
          marsEnable: currentMarsEnable,
          connectType: currentConnectionType,
          displayNumber: currentNumDisplays,
          tpAppsEnabled: currentTPappEnabled,
          chromeInstalled: currentchromeInstalled
        });
      }
      if (error) {
        Logger.info(`Error retrieving connect settings: ${JSON.stringify(error)}`);
        setHasError(true);
        setMessage(intl.get('genericErrorMessage'));
      }
    },
    [loading, error, data]
  );
  const isDirty = currentState.enable !== enable
    || currentState.marsEnable !== marsEnable
    || currentState.connectType !== connectionType
    || currentState.displayNumber !== numDisplays
    || currentState.tpAppsEnabled !== tpAppsEnabled;

  return (
    <div className={classes.pane}>
      <div className={classes.contentContainer}>
        <div className={classes.paneTitle}>
          {intl.get('tpAppsEnabled')}
        </div>
        {loading ? <LoadingIndicator /> : (
          <div className={connectClasses.connectContentContainer}>
            <div className={connectClasses.connectContentColumn}>
              <div className={connectClasses.connectContentRow}>
                <ToggleButton
                  id="tpAppsEnabled"
                  classNameLabel={''}
                  leftLabel={false}
                  hidenow={false}
                  topLabel={false}
                  disabled={!canEditRoomSystems || marsEnable || currentState.connectType === ConnectionType.MARS_HARDWARE || currentState.connectType === ConnectionType.MARS_SOFTWARE}
                  label={intl.get('tpAppsEnabledToggleDescription')}
                  field={{
                    name: 'tpAppsEnable',
                    value: tpAppsEnabled,
                    onBlur: () => null,
                    onChange: () => {
                      if (!canEditRoomSystems) { return; }
                      clearMessage(setHasError, setMessage);
                      if (tpAppsEnabled) {
                        // toggle off
                        setTPAppsEnabled(false);
                      } else {
                        // toggle on - set to current values
                        setTPAppsEnabled(true);
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.paneTitle} data-test="connect">
          {intl.get('lifesizeConnectTitle')}
        </div>
        {loading ? <LoadingIndicator /> : (
          <div className={connectClasses.connectContentContainer}>
            <div className={connectClasses.connectContentColumn}>
              <div className={connectClasses.connectContentRow}>
                <ToggleButton
                  id="enableLifesizeConnect"
                  classNameLabel={''}
                  leftLabel={false}
                  hidenow={false}
                  topLabel={false}
                  disabled={!canEditRoomSystems || marsEnable || currentState.connectType === ConnectionType.MARS_HARDWARE || currentState.connectType === ConnectionType.MARS_SOFTWARE}
                  label={intl.get('lifesizeConnectToggleDescription')}
                  field={{
                    name: 'enableLifesizeConnect',
                    value: enable,
                    onBlur: () => null,
                    onChange: () => {
                      if (!canEditRoomSystems) { return; }
                      clearMessage(setHasError, setMessage);
                      if (enable) {
                        // toggle off
                        setConnectionType(ConnectionType.NONE);
                        setNumDisplays(0);
                      } else {
                        // toggle on - set to current values
                        setConnectionType(currentState.connectType as ConnectionType);
                        setNumDisplays(currentState.displayNumber || 0);
                      }
                      setEnable(!enable);
                    }
                  }}
                />
              </div>
              {!marsEnable && (
                <div className={`${connectClasses.connectContentRow} ${connectClasses.inputGroup}`}>
                  <label>{intl.get('lifesizeConnectConnectionTypeLabel')}</label>
                  <Dropdown
                    className={connectClasses.input}
                    closeOnBlur={true}
                    fluid={false}
                    name="connectionType"
                    disabled={!enable || !canEditRoomSystems || marsEnable}
                    onChange={(e, { value }) => {
                      if (!canEditRoomSystems) { return; }
                      clearMessage(setHasError, setMessage);
                      // @ts-ignore
                      setConnectionType(value);
                      setNumDisplays(0);
                    }}
                    options={getConnectionTypes(roomSystem, hasSoftwareOption)}
                    selection={true}
                    value={connectionType === ConnectionType.NONE ? '' : connectionType}
                    placeholder={intl.get('lifesizeConnectConnectionTypePlaceholder')}
                  />
                  <Tooltip
                    key="tooltip"
                    text={[(
                      <div key="connectPresentationPortTooltip" className={connectClasses.tooltipContainer}>
                        {hasSoftwareOption && <div>{intl.getHTML('lifesizeConnectPresentationPortTooltip')}</div>}
                        {roomSystem.platform !== ICON_300 && <div>{intl.getHTML('lifesizeConnectDisplay2PortTooltip')}</div>}
                      </div>
                    )]}
                    stylesWrapper={{ lineHeight: 1, marginLeft: '8px' }}
                  >
                    <Icon classes={['icon-info', connectClasses.tooltipButtonIcon]} />
                  </Tooltip>
                </div>
              )}
              {connectionType === ConnectionType.HARDWARE && !marsEnable && (
                <div className={`${connectClasses.connectContentRow} ${connectClasses.inputGroup}`}>
                  <label>{intl.get('lifesizeConnectNumOfDisplaysLabel')}</label>
                  <Dropdown
                    style={{ width: '100px', minWidth: '100px' }}
                    className={connectClasses.input}
                    closeOnBlur={true}
                    fluid={false}
                    name="numDisplays"
                    disabled={!canEditRoomSystems || marsEnable}
                    onChange={(e, { value }) => {
                      if (!canEditRoomSystems) { return; }
                      clearMessage(setHasError, setMessage);
                      setNumDisplays(Number(value));
                    }}
                    options={[
                      {
                        key: 1,
                        value: 1,
                        text: intl.get('one')
                      },
                      {
                        key: 2,
                        value: 2,
                        text: intl.get('two')
                      }
                    ]}
                    selection={true}
                    value={numDisplays}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.paneTitle} data-test="mars">
          {intl.get('marsTitle')}
        </div>
        {loading ? <LoadingIndicator /> : (
          <div className={connectClasses.connectContentContainer}>
            <div className={connectClasses.connectContentColumn}>
              <div className={connectClasses.connectContentRow}>
                <ToggleButton
                  id="enableMars"
                  classNameLabel={''}
                  leftLabel={false}
                  hidenow={false}
                  topLabel={false}
                  disabled={!canEditRoomSystems || enable || currentState.connectType === ConnectionType.HARDWARE || currentState.connectType === ConnectionType.SOFTWARE}
                  label={intl.get('marsToggleDescription')}
                  field={{
                    name: 'enableMars',
                    value: marsEnable,
                    onBlur: () => null,
                    onChange: () => {
                      if (!canEditRoomSystems) { return; }
                      clearMessage(setHasError, setMessage);
                      if (marsEnable) {
                        // toggle off
                        setConnectionType(ConnectionType.NONE);
                        setNumDisplays(0);
                      } else {
                        // toggle on - set to current values
                        setConnectionType(currentState.connectType as ConnectionType);
                        setNumDisplays(currentState.displayNumber || 0);
                      }
                      setMarsEnable(!marsEnable);
                    }
                  }}
                />
              </div>
              {!enable && (<div className={`${connectClasses.connectContentRow} ${connectClasses.inputGroup}`}>
                <label>{intl.get('lifesizeConnectConnectionTypeLabel')}</label>
                <Dropdown
                  className={connectClasses.input}
                  closeOnBlur={true}
                  fluid={false}
                  name="marsConnectionType"
                  disabled={!marsEnable || !canEditRoomSystems}
                  onChange={(e, { value }) => {
                    if (!canEditRoomSystems) { return; }
                    clearMessage(setHasError, setMessage);
                    // @ts-ignore
                    setConnectionType(value);
                    setNumDisplays(0);
                  }}
                  options={getMarsConnectionTypes(roomSystem, hasSoftwareOption)}
                  selection={true}
                  value={connectionType === ConnectionType.NONE ? '' : connectionType}
                  placeholder={intl.get('lifesizeConnectConnectionTypePlaceholder')}
                />
                <Tooltip
                  key="tooltip"
                  text={[(
                    <div key="marsPresentationPortTooltip" className={connectClasses.tooltipContainer}>
                      {hasSoftwareOption && <div>{intl.getHTML('marsPresentationPortTooltip')}</div>}
                      {roomSystem.platform !== ICON_300 && <div>{intl.getHTML('marsDisplay2PortTooltip')}</div>}
                    </div>
                  )]}
                  stylesWrapper={{ lineHeight: 1, marginLeft: '8px' }}
                >
                  <Icon classes={['icon-info', connectClasses.tooltipButtonIcon]} />
                </Tooltip>
              </div>
              )}
              {connectionType === ConnectionType.MARS_HARDWARE && !enable && (
                <div className={`${connectClasses.connectContentRow} ${connectClasses.inputGroup}`}>
                  <label>{intl.get('lifesizeConnectNumOfDisplaysLabel')}</label>
                  <Dropdown
                    style={{ width: '100px', minWidth: '100px' }}
                    className={connectClasses.input}
                    closeOnBlur={true}
                    fluid={false}
                    name="marsNumDisplays"
                    disabled={!canEditRoomSystems || enable}
                    onChange={(e, { value }) => {
                      if (!canEditRoomSystems) { return; }
                      clearMessage(setHasError, setMessage);
                      setNumDisplays(Number(value));
                    }}
                    options={[
                      {
                        key: 1,
                        value: 1,
                        text: intl.get('one')
                      },
                      {
                        key: 2,
                        value: 2,
                        text: intl.get('two')
                      }
                    ]}
                    selection={true}
                    value={numDisplays}
                  />
                </div>
              )}
            </div>
            <div className={connectClasses.connectButtonContainer}>
              <div className={classes.buttonContainer}>
                <WaitButton
                  buttonText={intl.get('update')}
                  id="updateConnect"
                  onSave={() => handleSubmit(
                    setHasError,
                    setMessage,
                    setIsBusy,
                    setCurrentState,
                    dispatch,
                    roomSystem,
                    enable,
                    marsEnable,
                    connectionType,
                    numDisplays,
                    tpAppsEnabled,
                    canEditRoomSystems
                  )}
                  isBusy={isBusy}
                  isSubmitButton={true}
                  isDisabled={!isValid(enable, marsEnable, connectionType, numDisplays) || !isDirty || !canEditRoomSystems}
                  type={ButtonType.button}
                />
              </div>
              {!!message && (
                <div className={`${connectClasses.apiMessage} ${hasError ? connectClasses.apiError : connectClasses.apiSuccess}`}>{message}</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectComponent;
