import { createSelector } from 'reselect';
import { accountSelectors } from '@lifesize/nucleus';
import { isFreemium } from './accountSelectors';
import { selectEcommerceSupported } from 'selectors/ecommerceSelector';
import { ACCOUNT_FEATURE_PAST_MEETINGS_ENABLED } from 'constants/accountFeatureConstants';

/**
 * Selector to the liveStreams state
 */

const demoPastMeetingsSelector = createSelector(
  accountSelectors.getAccountFeature,
  selectEcommerceSupported,
  isFreemium,
  (getAccountFeature, isEcommerceSupported, isAccountFreemium) =>
    !getAccountFeature(ACCOUNT_FEATURE_PAST_MEETINGS_ENABLED) &&
    (isAccountFreemium || isEcommerceSupported)
);

export {
  demoPastMeetingsSelector,
};
