import React from 'react';
import { useSelector } from 'react-redux';
import { servicesSelectors, currentUserSelectors } from '@lifesize/nucleus';
import { selectPermissionsObject } from 'selectors/permissionsSelector';

interface AuthProps {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isReconnecting: boolean;
}

export const withAuth = (BaseComponent: React.ComponentType<AuthProps>) => {
  return (props: {}) => {
    const currentUser = useSelector(currentUserSelectors.myInfo);
    const permissionsObject = useSelector(selectPermissionsObject);
    const isLoggedIn = useSelector(servicesSelectors.selectConnected);
    const isLoggingIn = useSelector(servicesSelectors.selectConnecting);
    const isReconnecting = useSelector(servicesSelectors.selectWillRetry);
    const withAuthProps = {
      isLoggedIn, isLoggingIn, isReconnecting, currentUser, permissionsObject
    };

    return <BaseComponent {...props} {...withAuthProps} />;
  };
};
