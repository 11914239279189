import React, { useState } from 'react';
import intl from 'react-intl-universal';
import * as formStyles from 'components/Modals/ModalForms.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './DeviceCustomization.scss';
import { useEventListener } from 'hooks/useEventListener';

interface Props {
  closeModal: () => void;
  url: string;
}

const SAVE_ERROR = 'SAVE_ERROR';

enum closeMessages {
  closeDialog = 'closeDialog',
  SAVE_SUCCESS = 'SAVE_SUCCESS'
}

const DeviceCustomization = (props: Props) => {
  const { closeModal, url } = props;
  const [ displayLoadingIndicator, setDisplayLoadingIndicator ] = useState(true);
  const [ hasErrorState, setErrorState ] = useState(false);

  const handleIFrameOnLoad = () => {
    setDisplayLoadingIndicator(false);
  };

  const handleIFrameMessage = (evt: MessageEvent) => {
    if (closeMessages[evt.data]) {
      closeModal();
      return;
    }
    if (evt.data === SAVE_ERROR) {
      setErrorState(true);
    }
  };

  useEventListener('message', handleIFrameMessage);

  return (
    <Modal
      useSaveAsFormSubmit={false}
      hideSaveButton={true}
      closeModal={closeModal}
      hideCloseButton={true}
      hideFooter={true}
      className={styles.overrideModalClass}
    >
      <div className={styles.container}>
        <div className="contentBody">
          <form
            name="configHomeScreenForm"
            className="form"
            role="form"
            noValidate={true}
          >
            <div className={styles.homeScreenBody}>
              <iframe
                onLoad={handleIFrameOnLoad}
                name="phoneHDFrame"
                id="phoneHDFrame"
                className={displayLoadingIndicator ? styles.hidden : ''}
                src={url}
                allowFullScreen={false}
              />
              {displayLoadingIndicator ?
                <LoadingIndicator />
                : <span/>}
            </div>
          </form>
          {hasErrorState ?
            <div id="saveError" className={formStyles.error}>{intl.get('errorSavingCustomization')}</div>
            : <span/>}
        </div>
      </div>

    </Modal>
  );
};

export default DeviceCustomization;
