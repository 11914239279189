import { Ecommerce } from '@lifesize/types';
import Stripe from 'stripe';

export interface EcommerceInterface {
  loading: boolean;
  account?: Ecommerce.Account;
  subscriptionLoading: boolean;
  subscription?: Stripe.Subscription;
  subscriptionError: boolean;
  subscriptionCancelBusy: boolean;
  subscriptionCancelError: boolean;
  subscriptionReactivateBusy: boolean;
  subscriptionReactivateError: boolean;
}

export const initialState: EcommerceInterface = {
  loading: false,
  subscriptionLoading: true,
  subscriptionError: false,
  subscriptionCancelBusy: false,
  subscriptionCancelError: false,
  subscriptionReactivateBusy: false,
  subscriptionReactivateError: false
};

export default initialState;
