import React from 'react';
import intl from 'react-intl-universal';
import classes from './DeviceSubscriptionStatusPill.scss';
import classNames from 'classnames';
import { Maybe } from 'typings/types';

interface Props {
  deviceIsActive?: boolean;
  licenseExpiration: Maybe<number>;
  showExpirationInDays: boolean;
  deviceSubscriptionExists: boolean;
}

interface PillProps {
  expiringDays: number;
  showExpirationInDays: boolean;
  deviceIsActive?: boolean;
  deviceSubscriptionExists: boolean;
}

const EXPIRATION_WARNING_DAYS = 90;

const getPillClass = (props: PillProps) => {
  const { deviceIsActive, expiringDays, deviceSubscriptionExists} = props;
  if (!deviceSubscriptionExists) {
    return classes.gray;
  }
  if (deviceIsActive) {
    if (expiringDays < 0) {
      return classes.red;
    }
    if (expiringDays <= EXPIRATION_WARNING_DAYS) {
      return classes.orange;
    }
    return classes.green;
  }
  return classes.red;
};

const getPillText = (props: PillProps) => {
  const { expiringDays, showExpirationInDays, deviceIsActive, deviceSubscriptionExists } = props;
  if (!deviceSubscriptionExists) {
    return intl.get('unknown').toUpperCase();
  }

  if (deviceIsActive) {
    if (expiringDays < 0) {
      return intl.get('expiredTitle');
    }

    if (expiringDays === 0) {
      return !showExpirationInDays ? intl.get('expiresSoon') : intl.get('expiringTodayTitle').toUpperCase();
    }

    if (expiringDays === 1) {
      return !showExpirationInDays ? intl.get('expiresSoon') : intl.get('expiringTomorrowTitle').toUpperCase();
    }

    if (expiringDays <= EXPIRATION_WARNING_DAYS) {
      return !showExpirationInDays ? intl.get('expiresSoon') : intl.get('expiringTitle', { days: expiringDays }).toUpperCase();
    }

    return intl.get('activeTitle');
  }
  return intl.get('expiredTitle');
};

const DeviceSubscriptionStatusPill = (props: Props) => {
  const {
    licenseExpiration,
  } = props;

  let expiringDays = -1;

  if (licenseExpiration) {
    const expirationDate = new Date(licenseExpiration);
    const now = new Date();
    const differenceInTime = expirationDate.getTime() - now.getTime();
    expiringDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));
  }

  return (
    <div
      className={classNames(classes.subscriptionPill, getPillClass({ ...props, expiringDays }))}
    >
      {getPillText({ ...props, expiringDays })}
    </div>
  );
};

export default DeviceSubscriptionStatusPill;
