import React from 'react';
import * as styles from './NoPermissionsBanner.scss';

export interface Props {
  text: string;
}

export const NoPermissionsBanner = ({ text }: Props) => {
  return <div className={styles.noEditBanner}>{text}</div>;
};
