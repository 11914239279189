import { connect } from 'react-redux';
import { actions } from 'actions/modalActions';
import { AlexaUnregisterModal } from 'components/Modals/AlexaUnregisterModal/AlexaUnregisterModal';
import alexaIntegrationThunks from 'thunks/alexaIntegrationThunks';
import { selectAlexaDevices } from 'selectors/alexaIntegrationSelector';
import { Global } from 'state/Global';

interface Props {
  serialNumber: string;
}

const mapStateToProps = (state: Global, ownProps: Props) => ({
  device: selectAlexaDevices(state)[ownProps.serialNumber]
});

const mapDispatchToProps = (dispatch: Function, ownProps: Props) => ({
  closeModal: async () => dispatch(actions.closeModal()),
  unregisterDevice: async () => dispatch(alexaIntegrationThunks.unregisterDevice(ownProps.serialNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlexaUnregisterModal);
