import { connect } from 'react-redux';
import PastMeetingsTab from 'components/DashboardNew/PastMeetingsTab/PastMeetingsTab';
import { selectPageNumber } from 'selectors/paginationSelectors';
import { Global } from 'state/Global';
import { selectDashboardState } from 'selectors/dashboardSelectors';

function mapStateToProps(state: Global) {
  const dashboardState = selectDashboardState(state);
  const pageNumber = selectPageNumber(state);

  const dateStart = new Date(dashboardState.startDateMoment.format('YYYY/MM/DD'));
  dateStart.setHours(0);
  dateStart.setMinutes(0);
  dateStart.setSeconds(0);
  const dateEnd = new Date(dashboardState.endDateMoment.format('YYYY/MM/DD'));
  dateEnd.setHours(23);
  dateEnd.setMinutes(59);
  dateEnd.setSeconds(59);
  return {
    startTimestamp: dateStart.getTime() / 1000,
    endTimestamp: dateEnd.getTime() / 1000,
    pageNumber
  };
}

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PastMeetingsTab);
