import { createAction } from '@lifesize/typescript-react-redux-utils';
import { DrilldownActionType } from '../constants/drilldownConstants';

export const DRILLDOWN_FETCH_USAGE_DETAILS_ROOM = 'DRILLDOWN_FETCH_USAGE_DETAILS_ROOM';
export const DRILLDOWN_FETCH_USAGE_DETAILS_USER = 'DRILLDOWN_FETCH_USAGE_DETAILS_USER';

export enum ViewName {
  CallHistory = 'CallHistory',
  LiveStreamHistory = 'LiveStreamHistory',
  UserCalls = 'UserCalls',
  RoomSystems = 'RoomSystems',
}

const actions = {
  handleFetchDrilldownVideoUsers: () =>
    createAction(DrilldownActionType.DRILLDOWN_FETCH_USERS_AND_VIDEO),
  handleFetchUsageDetailsRoom: (payload: object) =>
    createAction(DRILLDOWN_FETCH_USAGE_DETAILS_ROOM, payload),
  handleFetchUsageDetailsUser: (payload: object) =>
    createAction(DRILLDOWN_FETCH_USAGE_DETAILS_USER, payload),
};

export default actions;
