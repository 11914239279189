import { connect } from 'react-redux';
import FilterActions from '../../actions/filterActions';
import SearchBar from '../../components/Search/SearchBar';

export interface OwnPropsInterface {
  onChange?: Function;
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Function, ownProps: OwnPropsInterface) => ({
  setFilterString: async (filterString: string) => {
    await dispatch(FilterActions.setFilterString(filterString));
    if (ownProps.onChange) {
      ownProps.onChange();
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
