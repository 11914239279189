import { dataWarehouseSelectors } from '@lifesize/nucleus';
import { DWHCallHistoryResponseType } from 'interfaces/Dashboard';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _matchesProperty from 'lodash/matchesProperty';
import _toUpper from 'lodash/toUpper';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { actions as CallHistoryActions } from '../../actions/callHistoryActions';
import { actions as DashboardActions } from '../../actions/dashboardActions';
import { CallHistory } from '../../components/Drilldowns/CallHistory/CallHistory';
import { defaultRowCount } from '../../constants/listViewLayoutConstants';
import * as dashboardSelectors from '../../selectors/dashboardSelectors';
import { selectFilterString } from '../../selectors/filterSelectors';
import { selectPageNumber } from '../../selectors/paginationSelectors';
import { Global } from '../../state/Global';
import _sortBy from 'lodash/sortBy';

/**
 * Tally the # of participants for each conference
 */
export const parseDataForDisplay = (data: Array<DWHCallHistoryResponseType>): Array<DWHCallHistoryResponseType> => {
  const itemMap = new Map();
  for (let a = 0, l = data.length; a < l; ++a) {
    const call = data[a];
    const timestamp = new Date(call.startDate).getTime();
    const conferenceHash = `${call.conferenceId}${timestamp}`;

    if (itemMap.has(conferenceHash)) {
      const myItem = itemMap.get(conferenceHash);
      myItem.participants = myItem.participants || [];
      myItem.participants.push({ name: call.participantName, ext: call.extension || '-' });
      // Keep the count in sync with actual # of sub rows
      myItem.participantsCount = myItem.participants.length;
      itemMap.set(conferenceHash, myItem);
    } else {
      const type: DWHCallHistoryResponseType = {
        groupId: call.groupId,
        conferenceId: call.conferenceId,
        conferenceName: call.conferenceName,
        extension: call.extension,
        startDate: call.startDate,
        durationMinutes: call.durationMinutes,
        participantsCount: 1,
        hash: conferenceHash,
        participants: [{ name: call.participantName, ext: call.extension || '-' }],
        timestamp: timestamp
      };
      itemMap.set(conferenceHash, type);
    }
  }
  const returnArray = Array.from(itemMap.values());
  if (returnArray.length) {
    return _sortBy(returnArray, (o) => {
      return -(o.timestamp || 0);
    });
  }
  return [];
};

function mapStateToProps(state: Global) {
  const startDate = dashboardSelectors.selectStartDate;
  const endDate = dashboardSelectors.selectEndDate;
  const data = dataWarehouseSelectors.conferences;
  const pageNumber = selectPageNumber(state);

  const callHistoryData = createSelector(
    selectFilterString,
    data,
    (search: string, dataToFilter: Array<DWHCallHistoryResponseType>): Array<DWHCallHistoryResponseType> => {
      const parsedData = parseDataForDisplay(_get(dataToFilter, 'data.data', []));
      if (search) {
        return parsedData.filter((value: DWHCallHistoryResponseType) => {
          return _toUpper(value.conferenceName).includes(_toUpper(search));
        });
      }
      return parsedData;
    }
  )(state);

  return {
    title: intl.get('callHistory'),
    startDate: startDate(state),
    endDate: endDate(state),
    data: callHistoryData,
    isFetching: data(state).isFetching,
    error: data(state).data.error,
    totalResults: callHistoryData.length,
    numberOfPages: Math.ceil(callHistoryData.length / defaultRowCount),
    pageNumber: pageNumber,
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  setDateRange: (startDate: string, endDate: string) => {
    dispatch(DashboardActions.handleSetDateRange({ startDate: startDate, endDate: endDate }));
  },
  fetchListData: () => {
    dispatch(CallHistoryActions.handleFetchCallHistory());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CallHistory);
