import React from 'react';
import SearchBar from 'components/Search/SearchBar';
import MeetingsToolbar from 'components/Meetings/MeetingsTab/MeetingsToolbar';
import _pick from 'lodash/pick';

interface Props {
  addMeetingModalOpen: () => void;
  deleteMeetings: (arg: Array<string>) => void;
  selectionArray: Array<string>;
  onFilterStringChange: (filterValue: string) => void;
  error?: string;
}

const MeetingsHeader = (props: Props) => {
  const toolbarProps = _pick(props, [
    'addMeetingModalOpen',
    'deleteMeetings',
    'selectionArray',
  ]);
  return (
    <>
      <SearchBar setFilterString={props.onFilterStringChange} key="searchbar" />
      <div className="toolbar" key="toolbar" data-test="toolbar">
        <MeetingsToolbar {...toolbarProps}/>
      </div>
    </>
  );
};

export default MeetingsHeader;
