import React from 'react';
import { useSelector } from 'react-redux';
import { selectPayload } from 'selectors/modalSelector';
import { MeetingUserPayload } from 'interfaces/User';
import { currentUserSelectors } from '@lifesize/nucleus';
import ContactSearchSelection from 'components/ContactSearchSelection/ContactSearchSelection';
import intl from 'react-intl-universal';
import searchAllUsersQuery from 'queries/users/searchAllUsersQuery.gql';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { DummyField } from './DummyField';

export interface Props {
  isEditingExistingMeeting?: boolean;
  handleChange: Function;
}

export const OwnerField = (props: Props) => {
  const { handleChange, isEditingExistingMeeting} = props;
  const selectedMeeting = useSelector(selectPayload) as MeetingUserPayload;
  const { owner } = selectedMeeting;
  const currentUser = useSelector(currentUserSelectors.myInfo);
  const currentSelection = isEditingExistingMeeting ?
    { id: owner?.UUID || null, title: owner?.displayName || '' } :
    { id: currentUser?.id || null, title: currentUser?.name || '' };
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  if (!canEditMeetings) {
    return (
      <DummyField
        value={currentSelection.title}
      />
    );
  }

  return (
    <ContactSearchSelection
      placeholder={intl.get('meetingOwner')}
      onChange={(selection: object) => handleChange({ target: { name: 'owner', value: selection }})}
      currentSelection={currentSelection}
      query={searchAllUsersQuery}
      queryVariables={{ includeRoles: false }}
    />
  );
};
