import { connect } from 'react-redux';
import { accountSelectors } from '@lifesize/nucleus';
import Meetings from 'components/Meetings/Meetings';
import { ACCOUNT_FEATURE_STREAMING } from 'constants/accountFeatureConstants';
import { withRouter } from 'react-router';
import { Global } from 'state/Global';

function mapStateToProps(state: Global) {
  return {
    hasStreamingFeature: accountSelectors.getAccountFeature(state)(ACCOUNT_FEATURE_STREAMING)
  };
}

export default withRouter(connect(mapStateToProps)(Meetings));
