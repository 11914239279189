import { WallPaperPanelType } from 'interfaces/Wallpaper';

export const initialWallPaperPanelState: WallPaperPanelType = {
  isEnabled: false,
  imagePreviewURL: '',
  hasTypeError: false,
  hasDimensionsError: false,
  hasSizeError: false,
  filename: '',
  md5Sum: '',
};
