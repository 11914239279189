import React from 'react';
import intl from 'react-intl-universal';
import ToolBarButton from 'components/Common/ToolbarButton';
import { useSelector } from 'react-redux';
import { selectSelection } from 'selectors/selectionSelectors';
import Icon from 'components/Common/Icon';
import styles from './Downloads.scss';
import { LOCATION } from 'constants/tooltipFixedPositionConstants';
import TooltipFixedPosition from 'components/TooltipFixedPosition/TooltipFixedPosition';

interface Props {
  openDeleteModal: () => void;
}

const DownloadsToolbar = ({ openDeleteModal }: Props) => {
  const selectionArray = useSelector(selectSelection);

  return (
    <div className="toolbar" data-test="toolbar">
      <div className={styles.downloadControls}>
        <div className={styles.infoSection}>
          <TooltipFixedPosition
            location={LOCATION.LEFT}
            label={intl.get('exportedFilesDeleted', { days: 7 })}
          />
          <Icon classes={['icon-info', styles.downloadInfo]} />
        </div>
        <ToolBarButton
          clickHandler={openDeleteModal}
          data-test="toolbarBtn.deleteExport"
          isEnabled={selectionArray.length > 0}
          label={intl.get('delete')}
          tooltipIsOnRight={true}
          warningTooltipText={intl.get('exportedFilesDeleted', { days: 7 })}
          disabledTooltipText={intl.get('selectExportToDelete')}
        />
      </div>
    </div>
  );
};

export default DownloadsToolbar;
