import { MutableRefObject, useEffect, useRef } from 'react';

export function useMount(): MutableRefObject<boolean> {
  const isMounted = useRef(false);
  useEffect(
    () => {
      isMounted.current = true;
      return () => isMounted.current = false;
    },
    []
  );
  return isMounted;
}

export default {
  useMount
};
