import classnames from 'classnames';
import CommonIcon from 'components/Common/Icon';
import { ModalFooter } from 'components/Modal/ModalComponent/ModalFooter';
import * as formStyles from 'components/Modals/ModalForms.scss';
import SearchMeetings from 'containers/SearchCloudMeetings/SearchCloudMeetingsContainer';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { Field, Form, Formik } from 'formik';
import { VideoProperties } from 'interfaces/VideoProperties';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _memoize from 'lodash/memoize';
import React from 'react';
import intl from 'react-intl-universal';
import {
  areMeetingsIdentical,
  DEFAULT_MEETING_KEY,
  DEFAULT_MEETING_TITLE,
  recordingHasDefaultOwner
} from 'utils/recordingsUtils';
import { RECORDING_NAME_MAX_LENGTH } from 'constants/recordingsConstants';
import * as Yup from 'yup';
import searchAllUsersQuery from 'queries/users/searchAllUsersQuery.gql';
import ContactSearchSelection from 'components/ContactSearchSelection/ContactSearchSelection';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  closeModal: () => void;
  isBusy: boolean;
  data: VideoProperties | null;
  onSubmit: (args: object) => void;
  resetErrorState: () => void;
  meetingsAndOwners: [];
  currentUserName: string;
  meetingId: string;
  meetingName: string;
  error?: object;
}

/**
 * VC API accepts no title, and a description of 500 chars max
 */
const recordingSchema = _memoize(() => {
  return Yup.object().shape({
    name: Yup.string().min(0, intl.get('titleMinLength'))
      .max(RECORDING_NAME_MAX_LENGTH, intl.get('titleMaxLength', {maxLength: RECORDING_NAME_MAX_LENGTH})),
    description: Yup.string().max(500, intl.get('descriptionMaxLength')),
    meeting: Yup.string().min(1, intl.get('missingMeetingError')),
  });
});

const EditRecordingForm = (props: Props) => {
  const {
    data,
    isBusy,
    onSubmit,
    closeModal,
    meetingsAndOwners,
    currentUserName,
    resetErrorState,
    error,
    meetingId,
    meetingName
  } = props;

  const [recordingOwnerType, setRecordingOwnerType] = React.useState(recordingHasDefaultOwner(data) ? 'user' : 'meeting');
  const canModifyAccountRecordings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_RECORDINGS];

  return (
    <Formik
      initialValues={{
        name: _get(data, 'name', ''),
        owner: _get(data, 'owner', ''),
        meeting: _get(data, 'meeting', ''),
        description: _get(data, 'description', ''),
        default_meeting: _get(data, DEFAULT_MEETING_KEY, ''),
        dry_run: false
      }}
      validationSchema={recordingSchema}
      onSubmit={(values) => {
        const ownerFormValue = _get(values, 'owner');
        const meetingFormValue = _get(values, 'meeting', {});
        const meetingOriginalValue = _get(data, 'meeting', {});
        const meeting = {
          name: _get(values, 'meeting.name'),
          uuid: _get(values, 'meeting.uuid'),
        };

        // Calls handleSubmit passed in from wrapping component
        onSubmit({
          description: values.description || '',
          dry_run: !areMeetingsIdentical(meetingOriginalValue as object, meetingFormValue as object),
          meeting: meeting,
          name: values.name || '',
          owner: ownerFormValue
        });
      }}
      render={({ errors, touched, setFieldValue, setFieldError, values, resetForm }) => (
        <div className={formStyles.formControl}>
          <Form>

            <div className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
              <label className={formStyles.labelLarge}>{intl.get('titleLabel')}</label>
              <div className={classnames(formStyles.inputContainer, formStyles.large)}>
                <Field
                  className={formStyles.input}
                  name="name"
                  placeholder={intl.get('title')}
                  type="text"
                />
                <ul className={formStyles.inputMessages}>
                  {(touched.name && errors.name) && (
                    <li className={formStyles.error}>{errors.name}</li>
                  )}
                </ul>
              </div>
            </div>

            <div
              className={classnames(formStyles.formGroup, formStyles.hasValidation)}
              style={{ marginBottom: '20px' }}
            >
              <label
                className={formStyles.labelLarge}
                style={{ flex: '100px 0 0' }}
              >
                {intl.get('assignTo')}
              </label>
              <Dropdown
                className={''}
                closeOnBlur={true}
                fluid={false}
                onChange={(event: React.SyntheticEvent<HTMLElement>, dropdownData: DropdownProps) => {
                  const value = dropdownData.value;
                  if (value) {
                    if (value === 'meeting') {
                      setFieldValue('meeting', null);
                    } else {
                      setFieldValue('meeting', {
                        uuid: _get(data, DEFAULT_MEETING_KEY),
                        name: DEFAULT_MEETING_TITLE,
                      });
                    }
                    setRecordingOwnerType(value.toString());
                  }
                }}
                options={[
                  {
                    text: intl.get('meeting'),
                    value: 'meeting',
                    key: 'meeting',
                  },
                  {
                    text: intl.get('user'),
                    value: 'user',
                    key: 'user',
                  },
                ]}
                placeholder={intl.get('selectLanguage')}
                search={false}
                selection={true}
                value={recordingOwnerType}
              />
            </div>

            {/* Owner */}
            {(canModifyAccountRecordings && recordingOwnerType === 'user') &&
            <div id="ownerSearch" className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
              <label className={formStyles.labelLarge}>{intl.get('ownerLabel')}</label>
              <div className={classnames(formStyles.inputContainer, formStyles.large)}>
                <ContactSearchSelection
                  placeholder={intl.get('meetingOwner')}
                  onChange={({ id, title }) => {
                    if (data) {
                      setFieldValue('owner', {
                        uuid: id,
                        name: title
                      });
                    }
                  }}
                  currentSelection={{ id: _get(data, 'owner.uuid'), title: _get(data, 'owner.name')}}
                  query={searchAllUsersQuery}
                  queryVariables={{ includeRoles: false }}
                />
                <ul className={formStyles.inputMessages}>
                  {(touched.owner && errors.owner) && (
                    <li className={formStyles.error}>{errors.owner}</li>
                  )}
                </ul>
              </div>
            </div>
            }

            {/* Meeting */}
            {recordingOwnerType === 'meeting' && <div className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
              <label className={formStyles.labelLarge}>{intl.get('meetingLabel')}</label>
              <div className={classnames(formStyles.inputContainer, formStyles.large)}>
                <SearchMeetings
                  className={formStyles.searchInput}
                  showDefaultMeeting={false}
                  noResultsText={intl.get('noResults')}
                  defaultMeetingText={''}
                  onSearchCleared={resetErrorState}
                  onSearchSelectionMade={(uuid: string, name: string) => {
                    if (data) {
                      // Clear any errors, old values
                      resetForm(values);
                      resetErrorState();
                      if (isAdminOrSuperUser) {
                        setFieldValue('meeting', {
                          uuid,
                          name
                        });
                      } else {
                        // If the user is not the owner, warn that they cannot change the meeting
                        const match = _find(meetingsAndOwners, function (o: object) {
                          return _get(o, 'UUID') === uuid;
                        });
                        if (match) {
                          const selectedMeetingOwner = _get(match, 'owner.displayName');
                          const isCurrentUserOwnerOfSelected = match ? currentUserName === selectedMeetingOwner : false;

                          if (isCurrentUserOwnerOfSelected) {
                            setFieldValue('meeting', {
                              uuid,
                              name
                            });
                            return;
                          }
                          // User is not the owner
                          setFieldError('meeting', intl.get('notMeetingOwnerError'));
                          return;
                        }
                        setFieldValue('meeting', {
                          uuid,
                          name
                        });
                      }

                    }
                  }}
                  initialSelectionId={meetingId}
                  initialSelectionText={meetingName === DEFAULT_MEETING_TITLE ? '' : meetingName}
                  clearOnFocus={true}
                  icon={(<CommonIcon classes={['icon-search', formStyles.iconStyle]} />)}
                />
                <ul className={formStyles.inputMessages}>
                  {touched.meeting && errors.meeting ? (
                    <li className={formStyles.error}>{errors.meeting}</li>
                  ) : null}
                </ul>
              </div>
            </div>}

            {/* Description */}
            <div className={classnames(formStyles.formGroup, formStyles.vertical, formStyles.hasValidation)}>
              <label className={formStyles.labelLarge}>{intl.get('descriptionLabel')}</label>
              <div className={formStyles.inputContainer}>
                <Field
                  className={formStyles.textarea}
                  component="textarea"
                  name="description"
                />
                <ul className={formStyles.inputMessages}>
                  {touched.description && errors.description ? (
                    <li className={formStyles.error}>{errors.description}</li>
                  ) : null}
                </ul>
              </div>
            </div>

            <ModalFooter
              useSaveAsFormSubmit={true}
              closeModal={closeModal}
              isSaveButtonBusy={isBusy}
              isSaveButtonDisabled={!_isEmpty(errors)}
              saveText={intl.get('modalSave')}
              closeText={intl.get('cancel')}
            />

            {error &&
            <div className={classnames(formStyles.generalError, formStyles.center)}>
              <div className={formStyles.error}>{intl.get('editRecordingPropsFailure')}</div>
            </div>
            }

          </Form>
        </div>
      )}
    />
  );
};

export default EditRecordingForm;
