import React from 'react';
import intl from 'react-intl-universal';
import styles from './LiveMeetingsMenuItem.scss';
import _compact from 'lodash/compact';
import { useInterval } from 'hooks/useInterval';
import { ChildDataProps } from 'react-apollo';
import { DWHLiveMeetingsLengthQueryResponseType } from 'interfaces/Dashboard';
import { LIVE_MEETING_SLOW_REFRESH_TIME } from 'constants/liveMeetings';

interface Props {
  liveMeetingsLength: number;
  active: boolean;
  userIsActive: boolean;
}

type LiveMeetingsLengthResponse = {
  liveMeetings: DWHLiveMeetingsLengthQueryResponseType;
};

const LiveMeetingsMenuItem = (props: ChildDataProps<Props, LiveMeetingsLengthResponse>) => {
  const { liveMeetingsLength, active, userIsActive, data } = props;
  const { refetch } = data;

  const containerStyles = _compact([
    styles.liveMeetingsMenuItem,
    active ? styles.active : ''
  ]).join(' ');

  useInterval(() => {
    if (userIsActive) { refetch(); }
  },          LIVE_MEETING_SLOW_REFRESH_TIME, [userIsActive]);

  return (
    <div className={containerStyles}>
      <span>{intl.get('liveMeetings')}</span>
      {!!liveMeetingsLength && <span className={styles.meetingsPip}>{liveMeetingsLength}</span>}
    </div>
  );
};

export default LiveMeetingsMenuItem;
