import dpaFr from 'assets/dpa/1.1/fr.pdf';
import dpaIt from 'assets/dpa/1.1/it.pdf';
import dpaDe from 'assets/dpa/1.1/de.pdf';
import dpaEs from 'assets/dpa/1.1/es.pdf';
import dpaEn from 'assets/dpa/1.1/en.pdf';

export const amendmentDownload = (locale: string) => {
  switch (locale) {
    case 'fr':
      return dpaFr;
    case 'it':
      return dpaIt;
    case 'de':
      return dpaDe;
    case 'es':
      return dpaEs;
    default:
      return dpaEn;
  }
};
