import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from 'redux';
import {
  currentUserSelectors,
  nucleusConstants
} from '@lifesize/nucleus';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import downloadNotificationActions from 'actions/downloadNotificationActions';
import { DirectoryExportStatus } from 'interfaces/Downloads';
import exportThunks from 'thunks/exportThunks';

interface ExportNotification {
  exportedBy: string;
  downloadId: string;
  status: DirectoryExportStatus;
}

const ExportMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return (action: PayloadAction<string, ExportNotification>) => {
        if (action.type !== nucleusConstants.GALAXY_WAMP_SUBSCRIPTION_EVENT_CALL) {
          return next(action);
        }

        const state = store.getState();
        const userName = currentUserSelectors.myInfo(state)?.name;
        const downloadId = action?.payload?.downloadId;
        if (
          !userName ||
          action?.payload?.exportedBy !== userName ||
          !downloadId
        ) {
          return next(action);
        }

        const status = action?.payload?.status;

        switch (status) {
          case DirectoryExportStatus.COMPLETED:
            store.dispatch(downloadNotificationActions.removePendingDownloads([downloadId]));

            break;
          case DirectoryExportStatus.FAILED:
            store.dispatch(downloadNotificationActions.removeFailedDownloads([downloadId]));
            break;
          default:
            break;
        }

        store.dispatch(exportThunks.getExports() as unknown as AnyAction);
        return next(action);
      };
    };

export default (ExportMiddleware as Middleware);
