import React from 'react';
import intl from 'react-intl-universal';
import { defaultRowCount } from 'constants/listViewLayoutConstants';
import { DWHCallDetailsResponseType2 } from 'interfaces/Dashboard';
import { exportToCsv } from 'utils/csv-utils';
import { formatStringDateTimeForDisplay } from 'utils/date-time-utils';
import { parseUserUsageDrillDownDataForExport2 } from 'utils/exportUtils';
import ListViewComponent from 'components/ListView/ListViewComponent';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import DrilldownHeader from 'components/Drilldowns/Common/DrilldownHeader2';
import { Query, QueryResult } from 'react-apollo';
import CallDetailsQuery from 'queries/dashboard/CallDetailsQuery.gql';
import { Moment } from 'moment';
import _get from 'lodash/get';

// props from Redux Store (mapStateToProps)
export interface Props {
  breadcrumbs?: Array<JSX.Element>;
  dateStart: Moment;
  dateEnd: Moment;
  searchString: string;
  pageNumber: number;
  title: string | JSX.Element | undefined;
  selectedId: string;
  exportTitle: string;
}

class CallUsageDetails extends React.PureComponent<Props> {
  handleExportToCsv = (data: DWHCallDetailsResponseType2[]) => {
    const exportData = data.sort((a: DWHCallDetailsResponseType2, b: DWHCallDetailsResponseType2) => {
      return new Date(b.startDate).getTime() - new Date(a.startDate).getTime();
    }).map(parseUserUsageDrillDownDataForExport2);
    exportToCsv(this.props.exportTitle, exportData);
  }

  render() {
    const { breadcrumbs, title } = this.props;
    const queryVars = {
      dateStart: this.props.dateStart.format('YYYY-MM-DD'),
      dateEnd: this.props.dateEnd.format('YYYY-MM-DD'),
      uuid: this.props.selectedId
    };
    return (
      <Query query={CallDetailsQuery} variables={{ ...queryVars }} fetchPolicy="network-only">
        {({ loading, error, data, refetch }: QueryResult<object>) => {
          const records = _get(data, 'callDetailsTable', []);
          return (
            <ListViewLayout
              breadcrumbs={breadcrumbs}
              error={_get(error, 'message', undefined)}
              isFetching={loading}
              numberOfPages={Math.ceil(records.length / defaultRowCount)}
              title={title}
            >
              <DrilldownHeader
                fetchData={refetch}
                onExportClick={() => this.handleExportToCsv(records)}
              />

              {loading ? null :
                <ListViewComponent
                  data={records}
                  columns={[
                    {
                      Header: intl.get('tableHeaderName'),
                      accessor: 'participantName'
                    },
                    {
                      Header: intl.get('tableHeaderStarted'),
                      accessor: 'startDate',
                      Cell: (row) => formatStringDateTimeForDisplay(row.value)
                    },
                    {
                      Header: intl.get('tableHeaderDevice'),
                      accessor: 'deviceType'
                    },
                    {
                      Header: intl.get('tableHeaderSource'),
                      accessor: 'source'
                    },
                    /* DCM temporary removal
                    {
                      Header: intl.get('tableHeaderDestination'),
                      accessor: 'destination'
                    },
                    */
                    {
                      Header: intl.get('tableHeaderDuration'),
                      accessor: 'durationMinutes'
                    }
                  ]}
                  showPagination={false}
                  maxRows={defaultRowCount}
                  pageNumber={this.props.pageNumber}
                  defaultSorted={[{
                    id: 'startDate',
                    desc: true
                  }]}
                />
              }
            </ListViewLayout>
          );
        }}
      </Query>
    );
  }
}

export default CallUsageDetails;
