import _find from 'lodash/find';
import _flatMap from 'lodash/flatMap';
import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import intl from 'react-intl-universal';
import { DWHCallDataResultType, DWHResponse } from '../../../interfaces/Dashboard';
import { sortCallDataByDateAsc } from '../../../utils/callDataUtils';
import { generateStackedBarChartConfig } from '../../../utils/highChartsConfigs';
import { DashboardLoader } from '../DashboardLoader';
import { DashboardNoResults } from '../DashboardNoResults';
import * as styles from './Charts.scss';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import { connect } from 'react-redux';
import { Global } from '../../../state/Global';

const getCategoriesForChart = (data: DWHCallDataResultType[]) => {
  return data.map((result) => {
    return result.period;
  });
};

const getListOfDevicesForChart = (data: DWHCallDataResultType[]): string[] => {
  const allSubGroups = _flatMap(data, (result) => result.subGroups);
  return _uniq(allSubGroups.map((subGroup) => subGroup.name));
};

interface Props {
  callDataByDays: DWHResponse;
}

const getConfigForChart = (callDataByDays: DWHResponse, title: string, yAxisTitle: string) => {
  let config = null;
  let data = _get(callDataByDays, 'data.data', null) as DWHCallDataResultType[];
  if (data) {
    data = sortCallDataByDateAsc(data);
    // Categories are data points by date
    const categories = getCategoriesForChart(data);
    // Devices are one or more series type strings
    const devices = getListOfDevicesForChart(data);
    // Initialize an array to provide as the series to config
    const seriesArray = devices.map((device) => {
      return { name: device, data: [] };
    });

    data.map((result) => { // loop over every date

      devices.forEach((device) => {
        // Find a matching item in the series array for the device we are talking about
        const seriesObject = _find(seriesArray, (a) => a.name === device);

        if (result.subGroups.length) {
          // If a data point has sub group data, add the minutes to the seriesArray data point (for each device)
         const subGroup = _find(result.subGroups, (a) => a.name === device);
          // @ts-ignore
         seriesObject.data.push(_get(subGroup, 'totalMinutes', 0));
        } else {
          // If no sub-group data for that date, add zeros for each seriesArray data point (for each device)
          // @ts-ignore
          seriesObject.data.push(0);
        }
      });

    });
    config = generateStackedBarChartConfig(categories, seriesArray, title, yAxisTitle);
  }
  return config;
};

const CallsByDevice = ({ callDataByDays }: Props) => {
  const divId = 'callsByDevice';

  if (callDataByDays.isFetching) {
    return (
      <DashboardLoader divId={divId} title={intl.get('callsPerDevice')} isWide={true}/>
    );
  }

  if (callDataByDays.data && callDataByDays.data.data) {
    const config =
      getConfigForChart(
        callDataByDays,
        intl.get('callsPerDevice'),
        intl.get('minutes')
      );

    return (
      <div className={`${styles.container} ${styles.wide}`} id={divId}>
        <ReactHighcharts
          isPureConfig={true}
          neverReflow={true}
          config={config}
        />
      </div>
    );
  }

  return (
    <DashboardNoResults divId={divId} title={intl.get('callsPerDevice')} isWide={true}/>
  );

};

export default CallsByDevice;

function mapStateToProps(state: Global) {
  return {
    callDataByDays: dataWarehouseSelectors.callsByDaysDevicesSelector(state),
  };
}

const mapDispatchToProps = (dispatch: Function) => ({});

export const CallsByDeviceContainer = connect(mapStateToProps, mapDispatchToProps)(CallsByDevice);
