import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { actions, CsvActionType, ExportName } from 'actions/exportToCsvActions';
import _get from 'lodash/get';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import listAllGroupContactsQuery from 'queries/groupContacts/listAllGroupContactsQuery.gql';
import listAllRoomsQuery from 'queries/roomSystems/listAllRoomsQuery.gql';
import listAllUsersQuery from 'queries/users/listAllUsersQuery.gql';
import listAllMeetingsQuery from 'queries/meetings/listAllMeetingsQuery.gql';
import { getClient } from 'nucleus-apollo-client';
import { Logger } from 'logger';
import { MAX_PAGE_COUNT } from 'constants/exportConstants';

interface QueryParams {
  query: string;
  fetchPolicy: string;
  variables?: object;
}

const exportToCsvMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return async (action: PayloadAction<string, Function>) => {
        if (CsvActionType.DO_EXPORT !== action.type) {
          return next(action);
        }

        try {
          next(actions.fetchCsvDataStart());
          let query;
          let responseDataLocation;
          let extraParams = {};
          let recordsLocation = 'records';
          // @ts-ignore
          switch (action.payload.query) {
            case ExportName.USERS:
              query = listAllUsersQuery;
              responseDataLocation = 'data.directoryList';
              break;
            case ExportName.MEETINGS:
              query = listAllMeetingsQuery;
              extraParams.includeRoles = false;
              responseDataLocation = 'data.directoryList';
              break;
            case ExportName.GROUP_CONTACTS:
              query = listAllGroupContactsQuery;
              responseDataLocation = 'data.directoryList';
              break;
            // TODO - use once new DWH is ready
            // case ExportName.LIVE_STREAM_QUESTIONS:
            //   query = listAllGroupContactsQuery; // TODO - add correct query
            //   responseDataLocation = 'data.directoryList.records';
            //   break;
            // TODO - use once new DWH is ready
            // case ExportName.LIVE_STREAM_VIEWERS:
            //   query = listAllGroupContactsQuery; // TODO - add correct query
            //   responseDataLocation = 'data.directoryList.records';
            //   break;
            default: // default to room system export
              query = listAllRoomsQuery;
              responseDataLocation = 'data.directoryListRoomSystem';
              break;
          }
          const client = await getClient(store.dispatch);
          let data: object[] = [];
          let response = {};
          let queryParams: QueryParams = {
            query: query,
            fetchPolicy: 'no-cache',
            ...extraParams
          };
          let maxLoopCount = MAX_PAGE_COUNT;
          const baseLocation = responseDataLocation.split('.');
          do {
            try {
              const cursor = _get(response, baseLocation.concat(['cursor']), null);
              if (cursor) {
                queryParams.variables = {
                  cursor: cursor
                };
              }
              response = await client.query(queryParams);
              data = [ ...data, ..._get(response, baseLocation.concat([recordsLocation]), []) ];
              maxLoopCount -= 1;
            } catch (e) {
              // @ts-ignore
              Logger.info(`Error exporting ${action.payload.query}: ${JSON.stringify(e)}`);
              break;
            }
          } while (_get(response, baseLocation.concat(['hasMoreRecords'])) && maxLoopCount);
          // @ts-ignore
          const exportHandler: Function = action.payload.responseHandler as Function;
          exportHandler(data);
          next(actions.fetchCsvDataFinish());
        } catch (error) {
          next(actions.fetchCsvDataReset());
          const errorDescription = _get(error, 'errorDescription', JSON.stringify(error));
          console.warn('Export to .csv error', errorDescription);
        }
        return null;
      };
    };

export default (exportToCsvMiddleware as Middleware);
