import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import styles from './ModalComponent.scss';
import { ButtonType, WaitButton } from './WaitButton';

export interface Props {
  closeModal?: () => void;
  onSave?: (payload?: object) => void;
  onCancel?: (payload?: object) => void;
  closeText?: string;
  saveText?: string;
  isBusy?: boolean;
  isSaveButtonDisabled?: boolean;
  isSaveButtonBusy?: boolean;
  useSaveAsFormSubmit?: boolean;
  hideSaveButton?: boolean;
  hideCloseButton?: boolean;
}

const ModalFooter: React.FunctionComponent<Props> = (props) => {

  const {
    closeModal,
    closeText,
    saveText,
    onSave,
    onCancel,
    useSaveAsFormSubmit,
    isSaveButtonDisabled,
    hideSaveButton,
    hideCloseButton,
    isSaveButtonBusy
  } = props;
  return (
    <div className={styles.footer}>
      {hideCloseButton ? null :
        <Button
          className={styles.btnSecondary}
          data-test="modalFooterBtn.cancel"
          name="btnCloseModal"
          onClick={() => {
            // If an optional handler provided for onCancel - execute it
            if (onCancel) {
              onCancel();
              return;
            }
            // Default behavior
            if (closeModal) {
              closeModal();
            }
          }}
          role="button"
          type="button"
        >
          {closeText || intl.get('modalClose')}
        </Button>
      }
      {
        (!hideSaveButton && (onSave || useSaveAsFormSubmit))
          ?
          <WaitButton
            buttonText={saveText}
            className={styles.btnPrimary}
            data-test="modalFooterBtn.save"
            isBusy={!!isSaveButtonBusy}
            isDisabled={useSaveAsFormSubmit ? isSaveButtonDisabled : false}
            isSubmitButton={useSaveAsFormSubmit}
            onSave={onSave ? onSave : undefined}
            type={useSaveAsFormSubmit ? ButtonType.submit : ButtonType.button}
          />
          :
          null
      }
    </div>
  );
};

export { ModalFooter };
