import * as React from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import * as styles from './DownloadToasts.scss';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import downloadNotificationActions from 'actions/downloadNotificationActions';

export default () => {
  const dispatch = useDispatch();
  const handleCloseClick = () => dispatch(downloadNotificationActions.dismissDownloadNotification());
  return (
    <div className={styles.container}>
      <div className={classnames(styles.iconContainer, styles.failureContainer)}>
        <Icon classes={['icon-status-alert']}/>
      </div>
      <div className={styles.messageContainer}>{intl.get('fileDownloadError')}</div>
      <button className={styles.closeButton} onClick={handleCloseClick}>
        <Icon classes={['icon-cancel']} />
      </button>
    </div>
  );
};
