import { createAsyncThunk } from '@reduxjs/toolkit';
import { getClient } from 'nucleus-apollo-client';
import getAllRolesQuery from 'queries/roles/getAllRoles.gql';
import { Logger } from 'logger';

export const getRoles = createAsyncThunk(
  'roleManagement-getRoles',
  async (input: undefined, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    try {
      const client = await getClient(dispatch);
      const response = await client.query({
        query: getAllRolesQuery,
        fetchPolicy: 'network-only',
      });
      return response?.data?.getAllRoles || [];
    } catch (err) {
      Logger.info(`Unable to fetch roles: ${err}`);
    }
  }
);
