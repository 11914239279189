import React, { useState } from 'react';
import intl from 'react-intl-universal';
import ToolBarButton from 'components/Common/ToolbarButton';
import { useSelector } from 'react-redux';
import { selectSelection } from 'selectors/selectionSelectors';
import SearchBar from 'components/Search/SearchBar';
import * as styles from './MergeRequests.scss';
import MergeRequestModal from './MergeRequestModal/MergeRequestModal';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  onFilterStringChange: (sort: string) => void;
  onMergeSuccess: () => void;
}

const MergeRequestHeaderToolbar = (props: Props) => {
  const { onFilterStringChange, onMergeSuccess } = props;
  const selectionArray = useSelector(selectSelection);
  const hasSelected = !!selectionArray.length;
  const [ accept, setAccept ] = useState(true);
  const [ showMergeRequestModal, setShowMergeRequestModal ] = useState(false);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const onAcceptClick = () => {
    if (!canEditSettings) { return; }
    setAccept(true);
    setShowMergeRequestModal(true);
  };

  const onRejectClick = () => {
    if (!canEditSettings) { return; }
    setAccept(false);
    setShowMergeRequestModal(true);
  };

  return (
    <>
      <SearchBar setFilterString={onFilterStringChange} key="searchbar" />
      <div className="toolbar" data-test="toolbar">
        <div className={styles.infoBar}>
          <div>{intl.get('mergeInfo')}</div>
        </div>
        <div>
          <ToolBarButton
            clickHandler={onAcceptClick}
            isEnabled={hasSelected && canEditSettings}
            disabledTooltipText={intl.get('mergeSelectTooltip')}
            label={intl.get('accept')}
          />
          <ToolBarButton
            clickHandler={onRejectClick}
            disabledTooltipText={intl.get('mergeSelectTooltip')}
            isEnabled={hasSelected && canEditSettings}
            label={intl.get('decline')}
            tooltipIsOnRight={true}
          />
        </div>
      </div>
      { showMergeRequestModal &&
        <MergeRequestModal
            accept={accept}
            closeModal={() => setShowMergeRequestModal(false)}
            onMergeSuccess={onMergeSuccess}
        />
      }
    </>
  );
};

export default MergeRequestHeaderToolbar;
