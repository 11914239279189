import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import * as styles from './Toast.scss';
import { ReactText, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import NetworkConnectionToast from './NetworkConnectionToast';
import { servicesSelectors, networkSelectors } from '@lifesize/nucleus';
import { useSelector } from 'react-redux';

let networkIssueToastId: ReactText | null = null;

const networkFixed = () => {
  if (networkIssueToastId) {
    toast.dismiss(networkIssueToastId);
    networkIssueToastId = null;
  }
};

const networkIssue = () => {
  networkIssueToastId = toast(<NetworkConnectionToast/>, {
    autoClose: false,
    closeButton: false
  });
};

const ToastComponent = () => {
  const isOffline = useSelector(networkSelectors.isOffline);
  const isWampConnected = useSelector(servicesSelectors.selectConnected);
  const hasNetworkIssue = isOffline || !isWampConnected;

  useEffect(
    () => {
      if (hasNetworkIssue) {
        networkIssue();
      } else {
        networkFixed();
      }
    },
    [hasNetworkIssue]
  );

  return (
    <ToastContainer
      className={styles.toastContainer}
      toastClassName={styles.toast}
    />
  );
};

export default ToastComponent;
