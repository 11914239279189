import { AnyAction } from 'redux';
import _get from 'lodash/get';

function isSuccessfulAction(action: AnyAction, expectedType: string, pathToStatus: string = 'payload.statusCode') {
  return action.type === expectedType && _get(action, pathToStatus) < 300 && _get(action, pathToStatus) >= 200;
}

export {
  isSuccessfulAction
};
