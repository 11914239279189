import { createAction } from '@lifesize/typescript-react-redux-utils';
import {
  Actions,
  deviceHealthUploadUri,
  deviceHealthEndpoint,
  deviceHealthGetUploadStatusUri,
  deviceManagementConnectUri
} from 'constants/deviceManagementConstants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { proxyActions, nucleusConstants } from '@lifesize/nucleus';
import { Methods } from 'interfaces/HTTP';
import _get from 'lodash/get';

const actions = {
  rebootDevice: (payload: string) =>
    createAction(Actions.REBOOT_DEVICE, payload),
  rebootDeviceSuccess: (payload: string) =>
    createAction(Actions.REBOOT_DEVICE_SUCCESS, payload),
  rebootDeviceFailure: (payload: string) =>
    createAction(Actions.REBOOT_DEVICE_FAILURE, payload),
  resetDevice: (payload: string) =>
    createAction(Actions.RESET_DEVICE, payload),
  resetDeviceSuccess: (payload: string) =>
    createAction(Actions.RESET_DEVICE_SUCCESS, payload),
  resetDeviceFailure: (payload: string) =>
    createAction(Actions.RESET_DEVICE_FAILURE, payload),
  deviceCoronerStatus: (payload: Object) =>
    createAction(Actions.UPLOAD_CORONER_STATUS, payload),
};

export const triggerDeviceCoroner = createAsyncThunk(
  'deviceManagement-triggerDeviceCoroner',
  async (payload: string, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      deviceHealthEndpoint,
      deviceHealthUploadUri,
      Methods.POST,
      {
        serialNumber: payload
      }
    ));
    return JSON.parse(res);
  }
);

export const getDeviceCoronerStatus = createAsyncThunk(
  'deviceManagement-getCoronerStatus',
  async (serialNumber: string, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      deviceHealthEndpoint,
      deviceHealthGetUploadStatusUri,
      Methods.POST,
      {
        serialNumber: serialNumber
      }
    ));

    const response = JSON.parse(res);

    const status = _get(response, 'body.status');
    const lastCoroner = _get(response, 'body.lastCoroner');
    const payload = { serialNumber, status, lastCoroner };
    
    await thunkAPI.dispatch(actions.deviceCoronerStatus(payload));

    return response;
  }
);

export const updateDeviceConnectSettings = createAsyncThunk(
  'deviceManagement-putDeviceConnectSettings',
  async (payload: object, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      nucleusConstants.PAIRED_DEVICES_ENDPOINT,
      deviceManagementConnectUri,
      Methods.POST,
      payload
    ));
    return JSON.parse(res.body);
  }
);

export default actions;
