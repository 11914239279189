export const ICON_300 = 'ICON_300';
const ICON_400 = 'ICON_400';
const ICON_450 = 'ICON_450';
const ICON_500 = 'ICON_500';
const ICON_600 = 'ICON_600';
const ICON_700 = 'ICON_700';
const ICON_800 = 'ICON_800';
const ICON_900 = 'ICON_900';
export const LS_220 = 'LS_220';

export const ICON_TYPES = new Set([
  ICON_300,
  ICON_400,
  ICON_450,
  ICON_500,
  ICON_600,
  ICON_700,
  ICON_800,
  ICON_900,
  LS_220
]);

export const CUSTOMIZABLE_ICON_TYPES = new Set([
  ICON_300,
  ICON_400,
  ICON_450,
  ICON_500,
  ICON_600,
  ICON_700,
  ICON_800,
  ICON_900
]);

export const GICON_DEVICES = [ICON_400, ICON_450, ICON_600, ICON_800];
export const GEMS_DEVICES = [ICON_300, ICON_500, ICON_700, ICON_900];
export const GICON_SUPPORTED_REBOOT_VERSION = '4.4.0';
export const GEMS_SUPPORTED_REBOOT_VERSION = '3.5.3';
export const GEMS_SUPPORTED_RESET_VERSION = '3.6.0';
export const ICON_SUPPORTED_RESET_PASSWORD_VERSION = '4.4.5';
export const GEMS_SUPPORTED_UPLOAD_VERSION = '3.9.1';
export const GICON_SUPPORTED_UPLOAD_VERSION = '4.5.0';

export const MUSTANGHD = 'MUSTANGHD';
export const SUPPORTED_PHONES = new Set([
  MUSTANGHD,
]);

/** Maps DB key to display string */
export enum ICON_DESCRIPTION {
  LS_220 = '220',
  ICON_300 = 'Icon 300',
  ICON_400 = 'Icon 400',
  ICON_450 = 'Icon 450',
  ICON_500 = 'Icon 500',
  ICON_600 = 'Icon 600',
  ICON_700 = 'Icon 700',
  ICON_800 = 'Icon 800',
  ICON_900 = 'Icon 900'
}

export const UNKNOWN = 'UNKNOWN';
export const UNAVAILABLE = 'UNAVAILABLE';
export const SHARED = 'SHARED';
export const UNDEFINED = 'UNDEFINED';
export const DELETED = 'DELETED';
export const GRAPHITE_TABLET = 'graphite-tablet';
export const GEMS_TABLET = 'gems-tablet';
export const TABLET = 'TABLET';

export enum RoomSystemUsageType {
  SHARED = 'SHARED',
  PERSONAL = 'PERSONAL',
  GRAPHITE = 'GRAPHITE',  // Invalid?
}

export interface DeviceListType {
  usageType: string;
  serialNumber: string;
  enabled: boolean;
  platform: string;
  pairingToken: string;
  systemName: string;
  remoteIP: string;
  pairingTimeMillis?: number;
  currentFirmwareVersion?: string;
  timezoneOffsetInMinutes?: string;
  targetFirmwareVersion?: string;
  userExtension?: string;
  localIP?: string;
  regStatus?: string;
  phoneSerialNumber?: string;
  phoneModelNumber?: string;
  amsStatus?: string;
  enabledCopy?: string;
  videoType?: JSX.Element | string;
  audioType?: JSX.Element | string;
  pairingStatus: PairingStatus;
  usageText?: string;
  videoTypeText?: string;
  controllerText?: string;
  deviceUUID?: string;
  pin?: string; // TODO - What is the name of this new field?
  availability?: {
    status: string;
  };
}

export interface PairedDeviceDataType {
  RES: string;
  resultCount: number;
  totalResultCount: number;
  deviceList: Array<DeviceListType>;
}

export enum PairingStatus {
  COMPLETE = 'COMPLETE', // Paired
  DELETED = 'DELETED', // UnPaired
  DEFAULT = 'DEFAULT' // Paired from CSS
}

export const BASE_ROOM_SYSTEM_ROUTE = '/room-systems';

export const MONITORING_LINE_ITEMS = ['online', 'displayConnected', 'cameraConnected', 'micConnected'];

export interface DeviceChannelType {
  channel: string;
  version: string;
  locked?: boolean;
}

export const getReleaseNotesLink = (isGems: boolean) => isGems
  ? `https://community.lifesize.com/s/release-notes-icon-300-500-700`
  : `https://community.lifesize.com/s/release-notes-icon-400-450-600-800`;

export const STATUS_PAGE_URL = 'https://status.lifesizecloud.com/';

export const LIFESIZE_CONNECT_MIN_VERSION = '3.12.0';

export enum ConnectionType {
  NONE = 'NONE',
  HARDWARE = 'HARDWARE',
  SOFTWARE = 'SOFTWARE',
  MARS_HARDWARE = 'MARS_HARDWARE',
  MARS_SOFTWARE = 'MARS_SOFTWARE'
}
