import React from 'react';
import intl from 'react-intl-universal';
import ExportButton from 'components/Common/ExportButton';
import ToolBarButton from 'components/Common/ToolbarButton';
import useExport from 'hooks/useExport';
import { ExportType } from 'interfaces/Downloads';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  hasSelected: boolean;
  onDeleteUsersClick: (arg: string[]) => void;
  onInviteUserClick: () => void;
  selectionArray: string[];
  hideToolbar?: boolean;
}

const UsersHeaderToolbar = ({
                              hasSelected,
                              onDeleteUsersClick,
                              onInviteUserClick,
                              selectionArray,
                            }: Props) => {

  const modifyUserPermission = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_USERS];
  const rolesFeatureFlag = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const canModifyUsers = rolesFeatureFlag ? modifyUserPermission : true;

  const { handleExportData, loading } = useExport(ExportType.USER);

  return (
    <div className="toolbar" data-test="toolbar">
      <div>
        <ExportButton
          disabled={false}
          isExporting={loading}
          onExportClick={handleExportData}
        />
        <ToolBarButton
          clickHandler={onInviteUserClick}
          isEnabled={canModifyUsers}
          disabledTooltipText={canModifyUsers ? intl.get('tooltipMaxUsers') : intl.get('cannotModifyUsers')}
          label={intl.get('usersButtonLabelInvite')}
        />
        <ToolBarButton
          clickHandler={() => onDeleteUsersClick(selectionArray)}
          disabledTooltipText={canModifyUsers ? intl.get('tooltipNoneSelectedUsers') : intl.get('cannotModifyUsers')}
          isEnabled={hasSelected && canModifyUsers}
          label={intl.get('usersButtonLabelDelete')}
          tooltipIsOnRight={true}
        />
      </div>
    </div>
  );
};

export default UsersHeaderToolbar;
