import { User } from 'typings/types';
import { isAdminUser, isSameUser, isSuperUser } from './userProfileUtils';
import { PermissionSetting, Role, ROLE_PERMISSION, ROLE_PERMISSION_TYPE } from 'interfaces/Role';
import { parentPermissions } from 'constants/permissionsConstants';
import intl from 'react-intl-universal';

export const checkCanEditName = (selectedUser: User, currentUser: object) => {
  const isEditingMe = isSameUser(selectedUser, currentUser);
  if (isAdminUser(currentUser)) {
    // Admin should not be allowed to change their personal name from user settings modal.
    return !isEditingMe;
  }
  if (isSuperUser(currentUser)) {
    // If the selected item is admin user, or themselves, they cannot change
    if (isAdminUser(selectedUser) || isEditingMe) {
      return false;
    }
    // Otherwise they can reset anyone
    return true;
  }
  // Other users can change if it's their account
  return isEditingMe;
};

export const checkCanHideUser = (selectedUser: User, currentUser: object) => {
  return isAdminUser(currentUser) || (isSuperUser(currentUser) && !isAdminUser(selectedUser));
};

export const checkCanSetAdminAccess = (selectedUser: User, currentUser: object) => {
  const isEditingMe = isSameUser(selectedUser, currentUser);
  if (isAdminUser(currentUser)) {
    // Admin should not be allowed to change their personal admin access from user settings modal.
    return !isEditingMe;
  }
  if (isSuperUser(currentUser)) {
    // If the selected item is admin user, or themselves, they cannot change
    if (isAdminUser(selectedUser) || isEditingMe) {
      return false;
    }
    // Otherwise they can reset anyone
    return true;
  }
  // Other users can't change this
  return false;
};

export const checkCanResetPassword = (selectedUser: User, currentUser: object) => {
  const isEditingMe = isSameUser(selectedUser, currentUser);
  if (isAdminUser(currentUser)) {
    // Admin should not be allowed to reset their password from user settings modal.
    // They should use My Profile
    return !isEditingMe;
  }
  if (isSuperUser(currentUser)) {
    // If the selected item is admin user, or themselves, they cannot change
    if (isAdminUser(selectedUser) || isEditingMe) {
      return false;
    }
    // Otherwise they can reset anyone
    return true;
  }
  // Regular user cannot change password, unless it's their personal account
  return isEditingMe;
};

const none = () =>
  [
    {
      label: ROLE_PERMISSION_TYPE.NONE,
      setting: {},
      selected: true
    }
  ];

const allOrNone = (permission: ROLE_PERMISSION, selection: ROLE_PERMISSION_TYPE) =>
  [
    {
      label: ROLE_PERMISSION_TYPE.ALL,
      setting: {[permission]: true},
      selected: selection === ROLE_PERMISSION_TYPE.ALL
    },
    {
      label: ROLE_PERMISSION_TYPE.NONE,
      setting: {[permission]: false},
      selected: selection === ROLE_PERMISSION_TYPE.NONE
    }
  ];

const ownedNone = (allPermission: ROLE_PERMISSION, ownedPermission: ROLE_PERMISSION, selection: ROLE_PERMISSION_TYPE, ownType: ROLE_PERMISSION_TYPE) =>
  [
    {
      label: ROLE_PERMISSION_TYPE.ALL,
      setting: {[allPermission]: true},
      selected: false,
      disabled: true
    },
    {
      label: ownType,
      setting: {[allPermission]: false, [ownedPermission]: true},
      selected: selection === ownType
    },
    {
      label: ROLE_PERMISSION_TYPE.NONE,
      setting: {[allPermission]: false, [ownedPermission]: false},
      selected: selection === ROLE_PERMISSION_TYPE.NONE
    },
  ];

const allOwnedNone = (allPermission: ROLE_PERMISSION, ownedPermission: ROLE_PERMISSION, selection: ROLE_PERMISSION_TYPE, ownType: ROLE_PERMISSION_TYPE) =>
  [
    {
      label: ROLE_PERMISSION_TYPE.ALL,
      setting: {[allPermission]: true},
      selected: selection === ROLE_PERMISSION_TYPE.ALL
    },
    {
      label: ownType,
      setting: {[allPermission]: false, [ownedPermission]: true},
      selected: selection === ownType
    },
    {
      label: ROLE_PERMISSION_TYPE.NONE,
      setting: {[allPermission]: false, [ownedPermission]: false},
      selected: selection === ROLE_PERMISSION_TYPE.NONE
    },
  ];

export const mapPermissionsToSettings = (permissions: PermissionSetting) => {
  const generateAllorNonePermission = (permission: ROLE_PERMISSION) => {
    const parent = parentPermissions[permission];
    if (parent && !permissions[parent]) {
      return none();
    }
    const selection = permissions[permission] ? ROLE_PERMISSION_TYPE.ALL : ROLE_PERMISSION_TYPE.NONE;
    return allOrNone(permission, selection);
  };

  const generateAllOwnedNonePermission = (permission: ROLE_PERMISSION, ownPermission: ROLE_PERMISSION, ownType: ROLE_PERMISSION_TYPE = ROLE_PERMISSION_TYPE.OWNED) => {
    let selection: ROLE_PERMISSION_TYPE;
    const permissionParent = parentPermissions[permission];
    const permissionHasParent = permissionParent ? permissions[permissionParent] : true;

    const ownPermissionParent = parentPermissions[ownPermission];
    const ownPermissionHasParent = ownPermissionParent ? permissions[ownPermissionParent] : true;

    if (permissions[permission] && permissionHasParent) {
      selection = ROLE_PERMISSION_TYPE.ALL;
    } else if (permissions[ownPermission] && ownPermissionHasParent) {
      selection = ownType;
    } else {
      selection = ROLE_PERMISSION_TYPE.NONE;
    }

    if (permissionHasParent) {
      return allOwnedNone(permission, ownPermission, selection, ownType);
    }

    if (ownPermissionHasParent) {
      return ownedNone(permission, ownPermission, selection, ownType);
    }

    return none();
  };

  return [
    {
      title: 'dashboard',
      view: generateAllorNonePermission(ROLE_PERMISSION.VIEW_DASHBOARD),
    },
    {
      title: 'liveMeetings',
      view: generateAllOwnedNonePermission(ROLE_PERMISSION.VIEW_LIVE_MEETINGS, ROLE_PERMISSION.VIEW_MODERATOR_LIVE_MEETINGS, ROLE_PERMISSION_TYPE.OWN_MODERATING),
    },
    {
      title: 'pastMeetings',
      view: generateAllOwnedNonePermission(ROLE_PERMISSION.VIEW_PAST_MEETINGS, ROLE_PERMISSION.VIEW_MODERATOR_PAST_MEETINGS, ROLE_PERMISSION_TYPE.OWNED_MODERATED),
    },
    {
      title: 'users',
      view: generateAllorNonePermission(ROLE_PERMISSION.VIEW_USERS),
      edit: generateAllorNonePermission(ROLE_PERMISSION.MODIFY_USERS),
    },
    {
      title: 'roleManagement',
      view: generateAllorNonePermission(ROLE_PERMISSION.VIEW_ROLES),
      edit: generateAllorNonePermission(ROLE_PERMISSION.MODIFY_ROLES),
    },
    {
      title: 'roomSystems',
      view: generateAllorNonePermission(ROLE_PERMISSION.VIEW_ROOMS),
      edit: generateAllorNonePermission(ROLE_PERMISSION.MODIFY_ROOMS)
    },
    {
      title: 'meetings',
      view: generateAllorNonePermission(ROLE_PERMISSION.VIEW_MEETINGS),
      edit: generateAllorNonePermission(ROLE_PERMISSION.MODIFY_MEETINGS)
    },
    {
      title: 'recordings',
      view: generateAllOwnedNonePermission(ROLE_PERMISSION.VIEW_OWN_ACCOUNT_RECORDINGS, ROLE_PERMISSION.VIEW_OWN_RECORDINGS, ROLE_PERMISSION_TYPE.SHARED),
      edit: generateAllOwnedNonePermission(ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_RECORDINGS, ROLE_PERMISSION.MODIFY_OWN_RECORDINGS),
    },
    {
      title: 'accountSettings',
      view: generateAllorNonePermission(ROLE_PERMISSION.VIEW_OWN_ACCOUNT_GENERAL_SETTINGS),
      edit: generateAllorNonePermission(ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS),
    }
  ];
};

export const mapRoleName = (roleName: string) => {
  switch (roleName) {
    case 'LifesizeUser':
      return intl.get('roleUser');
    case 'AccountOwner':
      return intl.get('roleSuperUser');
    case 'AccountAdmin':
      return intl.get('roleAdmin');
    default:
      return roleName;
  }
};

export const mapRoleFromBase = (role: Role) => {
  const replacementName = mapRoleName(role?.id);
  let defaultDescription = role?.description || '';
  if (role?.id === 'LifesizeUser') {
    defaultDescription = intl.get('roleUserDescription');
  } else if (role?.id === 'AccountOwner') {
    defaultDescription = intl.get('roleSuperUserDescription');
  } else if (role?.id === 'AccountAdmin') {
    defaultDescription = intl.get('roleAdminDescription');
  }
  return {...role, name: replacementName, description: defaultDescription};
};

export const isAdminRole = (role: { id: string }) => role?.id === 'AccountAdmin';
