import { CalendarServiceActions } from 'actions/calendarServiceAction';
import {
    Reducer
} from 'redux';
import { CalendarServiceState, initialCalendarServiceState } from 'state/CalendarServiceState';
import { isTimestampGreaterThanCurrent } from 'utils/calenderServiceUtils';

const calendarServiceReducer: Reducer<CalendarServiceState> =
    (state = initialCalendarServiceState, action) => {
        switch (action.type) {
            case CalendarServiceActions.HANDLE_CALENDAR_SERVICE_FEATURE:
                if (!!action.payload) {
                    return {
                        ...state,
                        connectPlusGatewayStatus: isTimestampGreaterThanCurrent(action.payload.connect_gw)
                    };
                }
                return state;
            default:
                return state;
        }
    };

export default calendarServiceReducer;
