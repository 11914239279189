import React from 'react';
import intl from 'react-intl-universal';
import appStoreBadge from '@lifesize/ux-assets/images/admin/PNG/download-on-the-app-store-badge.png';
import googlePlayBadge from '@lifesize/ux-assets/images/admin/PNG/google-play-badge.png';
import { SimpleModal } from '../../Modal/SimpleModal/SimpleModal';
import modalStyles from '../../Modal/SimpleModal/SimpleModal.scss';
import * as styles from './ConnectTabletComponent.scss';
import TabletPairingCode from './TabletPairingCode';
import { RoomSystem } from 'typings/types';
import classnames from 'classnames';
import * as modalFormStyles from '../ModalForms.scss';

interface Props {
  appStoreUrl: string;
  googlePlayUrl: string;
  roomSystem: RoomSystem;
  remoteLinkingCode: string;
  generateLinkingCode: (platform: string, serialNumber: string) => void;
  closePendingSave: () => void;
}

const ConnectTabletComponent = (props: Props) => {
  const {
    closePendingSave,
    remoteLinkingCode,
    roomSystem,
    generateLinkingCode,
    appStoreUrl,
    googlePlayUrl
  } = props;

  return (
    <SimpleModal
      contentClassName={classnames(modalStyles.content, modalFormStyles.containerSmall)}
    >
      <div className={styles.tabletTabContainer}>
        <div className={styles.title}>{intl.get('connectTabletBy')}</div>
        <ol className={styles.listContainer}>
          <li>
            <div>{intl.get('downloadRoomControllerApp')}</div>
            <div className={styles.centerContainer}>
              <div className={styles.appStoreContainer}>
                <a href={appStoreUrl} target="_blank">
                  <object data={appStoreBadge} type="image/png" />
                </a>
              </div>
              <div className={styles.appStoreContainer}>
                <a href={googlePlayUrl} target="_blank">
                  <object data={googlePlayBadge} type="image/png" />
                </a>
              </div>
            </div>
          </li>
          <li>
            <div>{intl.get('scanOrEnterCode')}</div>
            <TabletPairingCode
              remoteLinkingCode={remoteLinkingCode}
              roomSystem={roomSystem}
              generateLinkingCode={generateLinkingCode}
              closePendingSave={closePendingSave}
            />
          </li>
        </ol>
      </div>
    </SimpleModal>
  );
};

export default ConnectTabletComponent;
