import classnames from 'classnames';
import Can from 'components/Can/CanComponent';
import Icon from 'components/Common/Icon';
import _memoize from 'lodash/memoize';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import { PermissionTypes } from 'constants/permissionsConstants';
import DateRangePickerContainer from 'containers/DateRangePicker/DateRangePickerContainer';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import { CallMinutesPerDayContainer } from './Charts/CallMinutesPerDay';
import { CallsByDeviceContainer } from './Charts/CallsByDevice';
import { CallsPerDayContainer } from './Charts/CallsPerDay';
import * as styles from './Dashboard.scss';
import { SummaryBoxContainer } from './SummaryBoxes/SummaryBoxContainer';
import { CallHistoryTableContainer } from './Tables/CallHistoryTable';
import { IconCallsContainer } from './Tables/IconCallsTable';
import { LiveStreamHistoryTableContainer } from './Tables/LiveStreamHistoryTable';
import { UserCallsContainer } from './Tables/UserCallsTable';
import { NEW_DASHBOARD } from 'constants/dashboard';

let hasBeenLoaded = false;

interface Props {
  fetchData: () => void;
}

const renderTitle = _memoize(() => {
  return (
    <>
      <Icon classes={['icon-home']}/>
      <span>{intl.get('dashboard')}</span>
    </>
  );
});

const Dashboard = (props: Props) => {
  const { fetchData } = props;
  useEffect(
    () => {
      if (!hasBeenLoaded) {
        fetchData();
        hasBeenLoaded = true;
      }
    },
    []
  );

  return (
    <div className={classnames(styles.container, styles.legacy)}>
      <ListViewLayoutHeader title={renderTitle()}/>
      <div className="toolbar" data-test="toolbar">
        <DateRangePickerContainer
          fetchData={fetchData}
        />
      </div>
      <div className={classnames(styles.chartsContainer, 'contentBody')}>
        <SummaryBoxContainer/>
        <CallMinutesPerDayContainer/>
        <CallsPerDayContainer/>
        <UserCallsContainer/>
        <IconCallsContainer/>
        <CallsByDeviceContainer/>
        <CallHistoryTableContainer/>
        <Can userAccess={[PermissionTypes.VIEW_STREAMING]}>
          <LiveStreamHistoryTableContainer/>
        </Can>
      </div>
      <div className={styles.linkToAltDashboard}>
        <Link to={NEW_DASHBOARD}>
          <Icon classes={['icon-return']} />
          <span>{intl.get('dashboardLinkReturn')}</span>
        </Link>
      </div>
    </div>
  );
};

export { Dashboard };
