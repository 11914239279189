import { createSelector } from 'reselect';
import { Global } from 'state/Global';

/**
 * Direct selector to the drilldown state domain
 */
const selectSortState = (state: Global) => state.app?.sort;

const selectSort = createSelector(
  selectSortState,
  (sort) => sort
);

export {
  selectSort,
};
