import { featureSelectors, nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';
import React from 'react';
import { SortingRule } from 'react-table';
import { UserField } from 'constants/userConstants';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import { hasMoreRecords } from 'utils/directoryUtils';
import { Contact } from 'interfaces/Contact';
import { ListUsersQuery, User } from 'typings/types';
import listUsersQuery from 'queries/users/listUsersQuery.gql';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import UsersHeader from './UsersHeader';
import { usersTabColumnConfig } from './UsersTabColumnConfig';
import useListHooks from 'hooks/useListHooks';
import { useMiddleware } from 'hooks/useMiddleware';
import { getSortingRule } from 'utils/listUtils';
import uuid4 from 'uuid4';
import { useSelector } from 'react-redux';
import { selectRoleState } from 'selectors/roleSelectors';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { selectAccountPermissions } from 'selectors/permissionsSelector';

interface Props {
  editUser: (contact: Contact) => void;
  currentUser: User;
}

const UsersTab = (props: Props) => {
  const {
    editUser,
    currentUser,
  } = props;

  const modifyUsersPermission = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_USERS];
  const rolesFeatureFlag = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const canModifyUsers = rolesFeatureFlag ? modifyUsersPermission : true;

  const queryVariables = { includeRoles: rolesFeatureFlag };

  const {
    loading,
    data,
    refetch,
    searchQuery,
    setFilterString,
    setSort,
    fetchMoreRows
  } = useListHooks(listUsersQuery, {}, queryVariables);

  const rolesLoading = useSelector(selectRoleState)?.loading;

  useMiddleware(nucleusConstants.USER_SERVICE_DELETE_LIST_SUCCESS, () => refetch());
  useMiddleware(nucleusConstants.USER_SERVICE_DELETE_SUCCESS, () => refetch());

  const onFetchData: (args: { sorted: Array<SortingRule> }) => void = async ({
                                                                               sorted: [column],
                                                                             }) => {
    if (!data) {
      return;
    }

    const newSort = await getSortingRule(column);
    if (newSort) {
      setSort(newSort);
    }
  };

  const directoryList = data?.directoryList || null;
  const currentUserUUID = _get(currentUser, 'userUuid');
  const listData = (!loading && directoryList ? directoryList.records : []).map((rec: object) => {
    // Current user can not be selected, setting up 'key' property to be UUID
    // The 'key' will be used for the checkbox table as the 'keyField'
    if (_get(rec, UserField.USER_TYPE) === nucleusConstants.USER_ROLE_ADMIN) {
      return {...rec};
    }
    if (_get(rec, UserField.UUID) !== currentUserUUID) {
      return {...rec, key: _get(rec, UserField.UUID, uuid4())};
    }
    return rec;
  });

  return (
    <ListViewLayout
      hideHeader={true}
      isFetching={false}
      numberOfPages={0}
    >
      <InfiniteTable
        columnConfig={usersTabColumnConfig(editUser, rolesFeatureFlag)}
        data={listData as ListUsersQuery.UserInlineFragment[]}
        defaultSort={!searchQuery ? [
          {
            id: 'displayName',
            desc: false
          },
        ] : []}
        enableSelect={canModifyUsers}
        fetchMore={fetchMoreRows}
        hasMoreRecords={hasMoreRecords(data)}
        keyField={'key'}
        loading={loading || rolesLoading}
        onFetchData={onFetchData}
        renderHeader={() => (
          <UsersHeader
            onFilterStringChange={setFilterString}
          />
        )}
        sortable={true}
      />
    </ListViewLayout>
  );
};

export default UsersTab;
