import { currentUserSelectors } from '@lifesize/nucleus';
import { remotingConstants } from '@lifesize/clients.remoting';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { actions } from 'actions/modalActions';
import * as modalSelector from 'selectors/modalSelector';
import { getPendingModalClose } from 'selectors/modalSelectors';
import { isTabletDevice } from 'utils/roomSystemUtils';
import _get from 'lodash/get';

interface User {
  uuid: string;
}

const modalMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return async (action: PayloadAction<string, User>) => {
        const state = store.getState();
        const dispatch = store.dispatch;
        switch (action.type) {
          case 'CONTACT_NOTIFICATION_UPDATED':
            if (action.payload.uuid === currentUserSelectors.myInfo(state).userUUID) {
              if (getPendingModalClose(state)) {
                dispatch(actions.closeModal());
              }
            }
            return next(action);
          case remotingConstants.pairing.actions.WAMP_SUBSCRIPTION_LINKING_EVENT:
            const serialNumber = _get(action, 'payload.deviceUUID');
            const eventType = _get(action, 'payload.eventType');
            const linkType = _get(action, 'payload.linkType');
            const pairingSerialNumber = _get(modalSelector.selectPayload(state), 'serialNumber');
            if (serialNumber === pairingSerialNumber
              && eventType === 'DEVICE_LINKED' // Update to nucleus constant after upgrade to nucleus v6
              && isTabletDevice(linkType)) {
              if (getPendingModalClose(state)) {
                dispatch(actions.closeModal());
              }
            }
            return next(action);
          default:
            return next(action);
        }
      };
    };
  };

export default (modalMiddleware as Middleware);
