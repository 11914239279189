/**
 * Subset of constants from react-dates (not exported by default)
 */
export const ISO_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_FORMAT = 'MMM D, YYYY';

export const START_DATE_ID = 'unique_start_date_id';
export const END_DATE_ID = 'unique_end_date_id';

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const INFO_POSITION_AFTER = 'after';

export const ANCHOR_RIGHT = 'right';
