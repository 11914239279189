import _debounce from 'lodash/debounce';
import React from 'react';
import { SortingRule } from 'react-table';
import { ListGroupContactsQuery } from 'typings/types';
import { SortDirection } from 'constants/queryConstants';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { hasMoreRecords } from 'utils/directoryUtils';
import { groupContactsTabColumnConfig } from './GroupContactsColumnConfig';
import GroupContactsHeader from './GroupContactsHeader';
import { Logger } from 'logger';
import { useMount } from 'hooks/useMount';
import useListHooks from 'hooks/useListHooks';
import { getSortingRule } from 'utils/listUtils';
import listGroupContactsQuery from 'queries/groupContacts/listGroupContactsQuery.gql';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ModalActions } from 'actions/modalActions';
import { ModalTypes } from 'constants/modalConstants';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';

const GroupContactsTab = () => {
  const dispatch = useDispatch();
  const isMounted = useMount();

  const {
    loading,
    data,
    searchQuery,
    setFilterString,
    setSort,
    fetchMoreRows
  } = useListHooks(
    listGroupContactsQuery,
    { sort: { propertyName: 'displayName', direction: SortDirection.ASC } },
  );

  const modifyUsersPermission = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_USERS];
  const rolesFeatureFlag = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const canModifyUsers = rolesFeatureFlag ? modifyUsersPermission : true;

  const onFetchData: (args: { sorted: Array<SortingRule> }) => void = async ({sorted: [column]}) => {
    if (!data) {
      return;
    }

    const newSort = await getSortingRule(column);
    if (newSort) {
      setSort(newSort);
    }
  };

  const onFilterStringChange: (filter: string) => void = _debounce(
    async (filter: string) => {
      try {
        setFilterString(filter);
      } catch (e) {
        Logger.info(`Error fetching more user records:  ${e.toString()}`);
      }
    },
    250);

  const directoryList = _get(data, 'directoryList', null);
  const listData = !loading && directoryList ? directoryList.records : [];

  return (
    <ListViewLayout
      hideHeader={true}
      isFetching={false}
      numberOfPages={0}
    >
      <InfiniteTable
        defaultSort={!searchQuery ? [
          {
            id: 'displayName',
            desc: false
          },
        ] : []}
        columnConfig={
          groupContactsTabColumnConfig(
            listData,
            (payload) => dispatch(ModalActions.openModal({ modalType: ModalTypes.EDIT_GROUP_CONTACT, payload })),
            canModifyUsers
          )
        }
        data={listData as ListGroupContactsQuery.GroupContactInlineFragment[]}
        fetchMore={() => {
          if (isMounted.current) {
            return fetchMoreRows();
          } else {
            return Promise.resolve({});
          }
        }}
        hasMoreRecords={hasMoreRecords(data)}
        keyField={'UUID'}
        loading={loading}
        onFetchData={onFetchData}
        enableSelect={canModifyUsers}
        renderHeader={() => (
          <GroupContactsHeader
            onFilterStringChange={onFilterStringChange}
          />
        )}
        sortable={true}
      />
    </ListViewLayout>
  );

};

export default GroupContactsTab;
