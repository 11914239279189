import { createAction } from '@lifesize/typescript-react-redux-utils';
import * as DownloadNotificationActionType from 'constants/downloadNotificationConstants';
import { ToastId } from 'react-toastify';

export const downloadNotificationActions = {
  setDownloadNotificationId: (id: ToastId) =>
    createAction(DownloadNotificationActionType.SET_DOWNLOAD_NOTIFICATION_ID, id),
  createDownloadNotification: () =>
    createAction(DownloadNotificationActionType.CREATE_DOWNLOAD_NOTIFICATION),
  clearDownloadCounter: () =>
    createAction(DownloadNotificationActionType.CLEAR_DOWNLOAD_COUNTER),
  dismissDownloadNotification: () =>
    createAction(DownloadNotificationActionType.DISMISS_DOWNLOAD_NOTIFICATION),
  addPendingDownloads: (pendingDownloadIds: string[]) =>
    createAction(DownloadNotificationActionType.ADD_PENDING_DOWNLOADS, pendingDownloadIds),
  removeFailedDownloads: (failedDownloadIds: string[]) =>
    createAction(DownloadNotificationActionType.REMOVE_FAILED_DOWNLOADS, failedDownloadIds),
  removePendingDownloads: (pendingDownloadIds: string[]) =>
    createAction(DownloadNotificationActionType.REMOVE_PENDING_DOWNLOADS, pendingDownloadIds)
};

export default downloadNotificationActions;
