import { connect } from 'react-redux';
import {
  accountSelectors,
  groupSettingsActions,
  groupSettingsSelectors,
  recordingsAuthActions,
  recordingsAuthSelectors,
} from '@lifesize/nucleus';
import { Global } from 'state/Global';
import GeneralSettings from 'components/Settings/GeneralSettingsTab/Settings';
import _get from 'lodash/get';
import {
  ACCOUNT_FEATURE_CHAT_ENABLED,
} from 'constants/accountFeatureConstants';
import { localizationConstants } from '@lifesize/clients.localization';

const mapDispatchToProps = (dispatch: Function) => ({
  fetchData: () =>
    Promise.all([dispatch(groupSettingsActions.getGroupSettings())]),
  updateChatEnabled: async (chatEnabled: boolean, callback: Function) => {
    await dispatch(groupSettingsActions.setChatEnabled(chatEnabled));
    callback();
  },
  updateDomainsFilter: async (domains: string, callback: Function) => {
    await dispatch(groupSettingsActions.setDomainsFilter(domains));
    callback();
  },
  updateGroupLocale: async (locale: string, callback: Function) => {
    await dispatch(groupSettingsActions.setGroupLocale(locale));
    callback();
  },
  updateRecording: async (recordingEnabled: boolean) => {
    await dispatch(groupSettingsActions.setRecording(recordingEnabled));
  },
  updateRecordingPublicSharing: async (recordingPublicSharing: boolean) => {
    await dispatch(
      recordingsAuthActions.setVCSettings({
        allow_public: recordingPublicSharing
      })
    );
  }
});

const mapStateToProps = (state: Global) => {
  const recordingsAuthSettings =
    recordingsAuthSelectors.vcSettingsSelector(state) || {};
  const groupSettings = groupSettingsSelectors.groupSettings(state) || {};
  // make sure the locale provided to the component is supported or undefined
  const groupLocale = !!localizationConstants.supportedLanguages.find(
    contact => contact.language === groupSettings.locale
  )
    ? groupSettings.locale
    : undefined;

  return {
    chatEnabled: groupSettings.chatEnabled,

    domainsFilter: groupSettings.domainsFilter || '', // the value may be null

    groupLocale,

    recordingEnabled: groupSettings.recordingEnabled,

    isFetchingRecordingSettings: recordingsAuthSettings.isFetching,

    recordingPublicSharing: _get(
      recordingsAuthSettings.data,
      'allow_public',
      false
    ),

    isChatFeatureEnabled: accountSelectors.getAccountFeature(state)(
      ACCOUNT_FEATURE_CHAT_ENABLED
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSettings);
