import React, { useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { groupSettingsSelectors, nucleusConstants } from '@lifesize/nucleus';
import { Button } from 'semantic-ui-react';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import * as styles from 'components/Settings/Sections.scss';
import MeetingLayoutSchema from './MeetingLayoutSchema';
import { setMeetingSettings } from 'thunks/groupSettingsThunks';
import { Logger } from 'logger';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const defaultLayout = nucleusConstants.MEETING_LAYOUT_TYPES.ALTERNATIVE;
const defaultOptionId = 0;

const MeetingLayouts = () => {
  const dispatch = useDispatch();
  const groupSettings = useSelector(groupSettingsSelectors.groupSettings);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const selectedLayout = groupSettings.layout;
  const formikRef = useRef<Formik | null>(null);
  const initialValues = formikRef?.current?.initialValues;
  const onSubmit = async (formData: object, callback: Function) => {
    if (!canEditSettings) { return; }
    const settingResult = await dispatch(setMeetingSettings({ mcuLayout : get(formData, 'meetingLayout') })) as unknown as { type: string };
    if (settingResult.type === setMeetingSettings.rejected.type) {
      Logger.info('Unable to change SFU setting.');
    }
    callback();
  };
  
  useEffect(
    () => {
      // if there is no meetingLayout selected, auto-select TILES so the form is 'dirty'
      if (formikRef.current && initialValues && initialValues.meetingLayout === defaultOptionId) {
        formikRef.current.setValues({
          meetingLayout: defaultLayout
        });
      }
    },
    [initialValues]
  );

  return (
    <section className={styles.container} data-test="settingsSection.layout">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitleMeetingLayouts')}</h6>
        <p className={styles.description}>{intl.get('settingsSectionDescriptionMeetingLayouts')}</p>
        <Formik
          ref={formikRef}
          enableReinitialize={true}
          initialValues={{
            meetingLayout: selectedLayout || defaultOptionId,
          }}
          onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => setSubmitting(false))}
          render={({dirty, isSubmitting, setFieldValue, values}) => (
            <Form>
              <div className={styles.formSection}>
              <MeetingLayoutSchema setFieldValue={setFieldValue} meetingLayout={values.meetingLayout} />
                <div className={styles.buttonContainer} style={{ marginTop: '9px' }}>
                  <Button
                    id="btnSubmitMeetingLayout"
                    data-test="sectionBtnSubmit"
                    disabled={isSubmitting || !dirty || !canEditSettings}
                    name="btnSubmitMeetingLayout"
                    role="button"
                    type="submit"
                  >
                    {intl.get('settingsButtonLabelUpdate')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
      </article>
    </section>
  );
};

export default MeetingLayouts;
