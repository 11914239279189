import React from 'react';
import intl from 'react-intl-universal';
import { DWHLiveStreamResultType, DWHLiveStreamResultTypeV2 } from 'interfaces/Dashboard';
import { exportToCsv } from 'utils/csv-utils';
import { parseLiveStreamDataForExport } from 'utils/exportUtils';
import moment, { Moment } from 'moment';
import { LiveStreamHistoryList } from './LiveStreamHistoryList';
import { Query } from 'react-apollo';
import { fetchMoreLiveStreamRows, liveStreamV2Cutoff, parseV2DataForDisplay } from 'utils/liveStreamUtils';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import LiveStreamsHistoryQuery from 'queries/dashboard/LiveStreamsHistoryQuery.gql';
import { RouteComponentProps } from 'react-router';
import { getBreadcrumbs } from 'utils/nav-utils';
import { MEETING_LIVE_STREAMS } from 'constants/liveStreamHistory';

export interface Props extends RouteComponentProps<void> {
  title: string;
  data: Array<DWHLiveStreamResultType>;
  error: string | undefined;
  isFetching: boolean;
  hasNewData: boolean;
  breadcrumbs: JSX.Element[];
  fetchListData: (startDate: Moment, meetingName?: string) => void;
  fetchMoreRows: (metadata: { cursor: string }, fetchMore: ( options: object ) => void) => Promise<object>;
  fetchMore: () => Promise<object>;
  refetch: (opts: object) => void;
  hasMoreRecords: boolean;
  startDate: Moment;
  endDate: Moment;
  meetingName: string;
  meetingId: string;
  singleMeeting: boolean;
}

const sortData = _memoize((liveStreams: Array<DWHLiveStreamResultTypeV2 | DWHLiveStreamResultType>) => liveStreams.sort((a: DWHLiveStreamResultTypeV2, b: DWHLiveStreamResultTypeV2) => b.startedAt - a.startedAt));
const parseData = _memoize((liveStreams: Array<DWHLiveStreamResultTypeV2>) => parseV2DataForDisplay(liveStreams));

const handleExportToCsv = (data: Array<DWHLiveStreamResultType | DWHLiveStreamResultTypeV2>) => {
  const exportData = [];
  const sort = data.sort(
    (a: DWHLiveStreamResultType, b: DWHLiveStreamResultType) => b.startDate - a.startDate);
  for (let stream of sort) {
    exportData.push(parseLiveStreamDataForExport(stream));
  }
  exportToCsv(intl.get('liveStreamHistory'), exportData);
};

const LiveStreamHistory = (props: Props) => {
  const { hasNewData, singleMeeting, startDate, endDate, meetingId, isFetching } = props;
  const oldData = props.data;
  let breadcrumbs: JSX.Element[] | undefined = undefined;

  if (singleMeeting) {
    breadcrumbs = getBreadcrumbs([
      {
        url: MEETING_LIVE_STREAMS,
        label: intl.get('navMeetings')
      },
      {
        label: intl.get('breadcrumbHistory')
      }
    ]);
  }

  if (hasNewData) {
    const cutOffDate = moment(liveStreamV2Cutoff);
    const variables = {
      startTime: startDate > cutOffDate ? startDate.format('X').toString() : cutOffDate.format('X').toString(),
      endTime: endDate.format('X').toString(),
      meetingUUID: meetingId || undefined,
    };

    return (
      <Query query={LiveStreamsHistoryQuery} variables={{ ...variables }} fetchPolicy="network-only">
        {({ data, refetch, loading, error, fetchMore }) => {
          const liveStreams = _get(data, 'liveStreams.data', []);
          const metadata = _get(data, 'liveStreams.metadata', {});
          const cursor = metadata.cursor;
          const parsedData = parseData(liveStreams);
          const blendedProps = {
            ...props,
            isFetching: loading || cursor || isFetching,
            error: _get(error, 'message') || (typeof error === 'string' && error) || undefined,
            data: sortData([...parsedData, ...oldData]),
            hasMoreRecords: cursor ? cursor : false,
            loading,
            refetch,
            fetchMore: async () => fetchMoreLiveStreamRows({ cursor: cursor }, fetchMore),
          };

          return (
            <LiveStreamHistoryList
              {...blendedProps}
              breadcrumbs={breadcrumbs}
              handleExportToCsv={handleExportToCsv}
            />
          );
        }}
      </Query>
    );
  } else {
    return (
      <LiveStreamHistoryList
        {...props}
        data={sortData(oldData)}
        breadcrumbs={breadcrumbs}
        handleExportToCsv={handleExportToCsv}
      />
    );
  }
};

export default LiveStreamHistory;
