import {
  currentUserSelectors,
  featureSelectors,
  recordingActions as nucleusRecordingActions,
  recordingsAuthActions,
  recordingsSelectors
} from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import { actions as ModalActions } from 'actions/modalActions';
import { SingleRecordingFrame } from 'components/Recordings/SingleRecording/SingleRecordingFrame';
import { ModalTypes } from 'constants/modalConstants';
import ShareProperties from 'interfaces/ShareProperties';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import * as singleRecordingSelectors from 'selectors/SingleRecordingSelectors';
import { Global } from 'state/Global';
import { canUserModifyRecording } from 'utils/recordingsUtils';
import { getUrlParameter } from 'utils/url-utils';
import SingleRecordingInterface from 'interfaces/SingleRecording';
import logger from 'utils/logger';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { modifyRecordingSelector } from 'selectors/recordingsSelectors';

const responseStatus = (singleRecordingState: SingleRecordingInterface) => {
  return _get(singleRecordingState, 'error.response.status', 0);
};

const recordingNotFound = (singleRecordingState: SingleRecordingInterface) => {
  const responseCode = responseStatus(singleRecordingState);
  const errMessage = _get(singleRecordingState, 'error.message', '');
  return responseCode === 404 || ['FAILED TO FETCH', 'NOT FOUND'].includes(errMessage.toUpperCase());
};

const recordingNotAuthorized = (singleRecordingState: SingleRecordingInterface) => {
  // statusText: "UNAUTHORIZED", "FORBIDDEN"
  return [401, 403].includes(responseStatus(singleRecordingState));
};

const mapStateToProps = (state: Global) => {
  const singleRecordingState = recordingsSelectors.singleRecording(state);
  const addToWatchListState = recordingsSelectors.addToWatchList(state);
  const shareProperties = recordingsSelectors.shareProperties(state);
  const shareUpdate = recordingsSelectors.shareUpdate(state);
  const recordingId = singleRecordingSelectors.selectRecordingId(state);
  const userProfile = currentUserSelectors.myInfo(state);
  const usePermissions = featureSelectors.getFeatureFlag(state)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const modifyRecordingAllowed = usePermissions ?
    modifyRecordingSelector(state) : canUserModifyRecording(userProfile, _get(singleRecordingState, 'data'));

  return {
    canUserModifyRecording: modifyRecordingAllowed,
    hasBeenAddedToWatchList: addToWatchListState.hasBeenAdded,
    hasUpdatingSharePropertiesCompleted: shareUpdate.hasShareInfoBeenUpdated,
    isAddingToWatchList: addToWatchListState.isAdding,
    isFetching: singleRecordingState.isFetching,
    isFetchingShareInfo: shareProperties.isFetching,
    isOnWatchList: _get(singleRecordingState, 'data.wlater_done', false),
    isUpdatingShareProperties: shareUpdate.isUpdating,
    notAuthorized: recordingNotAuthorized(singleRecordingState),
    notFound:  recordingNotFound(singleRecordingState),
    recordingId: recordingId,
    shareInfo: shareProperties.data,
    singleRecordingData: singleRecordingState.data,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  fetchSingleRecording: (UUID: string) => {
    const vcShareToken = getUrlParameter('authToken');
    if (!!UUID && vcShareToken) {
      dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.fetchSingleRecording(UUID, vcShareToken)));
    } else if (!!UUID.length) {
      dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.fetchSingleRecording(UUID)));
    }
  },
  addToWatchList: (arg: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.addToWatchList(arg)));
  },
  removeFromWatchList: (arg: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.removeFromWatchList(arg)));
  },
  getShareLinkCloudGroup: (arg: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getShareLinksForCloudGroup(arg)));
  },
  getShareLinkGlobal: (arg: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getShareLinksGlobal(arg)));
  },
  getShareProperties: (arg: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getShareProperties(arg)));
  },
  setShareProperties: (arg: string, shareProps: ShareProperties) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.setShareProperties(arg, shareProps)));
  },
  confirmDelete: () => {
    dispatch(ModalActions.openModal({ modalType: ModalTypes.RECORDINGS_CONFIRM_DELETE }));
  },
  openEditModal: async (recordingId: string) => {
    try {
      await dispatch(nucleusRecordingActions.getVideoProperties(recordingId));
      dispatch(ModalActions.openModal({ modalType: ModalTypes.EDIT_RECORDING_MODAL, payload: {} }));
    } catch (e) {
      logger.info(`Unable to open edit modal: ${JSON.stringify(e)}`);
    }
  },
  openShareModal: async (recordingId: string) => {
    await Promise.all([
      dispatch(nucleusRecordingActions.getShareProperties(recordingId)),
      dispatch(recordingsAuthActions.getVCSettings())
    ]).catch((error: string) => { logger.info(`Unable to open share modal: ${JSON.stringify(error)}`); });
    dispatch(ModalActions.openModal({ modalType: ModalTypes.SHARE_RECORDING_MODAL, payload: {} }));
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(SingleRecordingFrame);
