import React from 'react';
import { useInterval } from 'hooks/useInterval';
import { secondsToHms } from 'utils/date-time-utils';

interface Props {
  row: {
    original: {
      duration: number,
      startedAt: number
    }
  };
}
export const LiveMeetingParticipantDuration = (props: Props) => {
  let { duration, startedAt } = props.row.original;
  const calculateDuration = () => {
    if (startedAt && !duration) {
      return startedAt ? Math.floor(Date.now() / 1000) - startedAt : 0;
    } else {
      return duration || 0;
    }
  };
  const [tickDuration, setTickDuration] = React.useState(calculateDuration());
  useInterval(() => {
    setTickDuration(calculateDuration());
  },          1000, [startedAt, duration]);
  return <div>{secondsToHms(tickDuration)}</div>;
};

export default { LiveMeetingParticipantDuration };
