import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import * as styles from 'components/Settings/Sections.scss';
import moment from 'moment';
import SecurityAcceptModal from 'components/Settings/AccountDetailsTab/SecurityAcceptModal/SecurityAcceptModal';
import { getDpaStatus } from 'actions/logDpaActions';
import { DPA_VERSION } from 'constants/logDpaConstants';
import { DpaResponse } from 'interfaces/logDPA';
import SecurityAndPrivacyContent from './SecurityAndPrivacyContent';

const SecurityAndPrivacyPane = () => {
  const dispatch = useDispatch();
  const [showSecurityReviewModal, setShowSecurityReviewModal] = useState(false);
  const [userAccepted, setUserAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const [hasServiceError, setServiceError] = useState(false);

  useEffect(() => {
    const getDpaStatusFromApi = async () => {
      setLoading(true);
      const dpaResponse = await dispatch(getDpaStatus(DPA_VERSION)) as unknown as DpaResponse;
      if (dpaResponse?.payload?.statusCode < 300 && dpaResponse?.type === getDpaStatus.fulfilled.type) {
        const body = dpaResponse?.payload?.body;
        setUserAccepted(body?.status === 'accepted');
        setEmail(body?.email);
        const updatedAt = body?.updatedAt;
        if (updatedAt) {
          setDate(moment(updatedAt).format(intl.get('dateFormat')));
        }
      } else {
        setServiceError(true);
      }

      setLoading(false);
    };
    if (!showSecurityReviewModal) {
      getDpaStatusFromApi();
    }
  },        [showSecurityReviewModal]);

  return (
    <section className={styles.container}>
      <article>
        <h6 style={{ marginBottom: '21px' }} className={classnames(styles.title, styles.headerRow)} data-test="sectionTitle">
          {intl.get('securityAndPrivacyTitle')}
        </h6>
        <SecurityAndPrivacyContent
          loading={loading}
          hasServiceError={hasServiceError}
          userAccepted={userAccepted}
          email={email}
          setShowSecurityReviewModal={setShowSecurityReviewModal}
          date={date}
        />
        {showSecurityReviewModal &&
          <SecurityAcceptModal
              onClose={() => setShowSecurityReviewModal(false)}
          />
        }
      </article>
    </section>
  );
};

export default SecurityAndPrivacyPane;
