import { createAction } from '@lifesize/typescript-react-redux-utils';

export const SEARCH_MEETINGS = 'SEARCH_MEETINGS';
export const SEARCH_START = 'SEARCH_START';
export const SEARCH_COMPLETE = 'SEARCH_COMPLETE';
export const DIRECTORY_SEARCH_RESULTS = 'DIRECTORY_SEARCH_RESULTS';
export const SEARCH_CLOUD_USERS = 'SEARCH_CLOUD_USERS';
export const SEARCH_CLOUD_MEETINGS = 'SEARCH_CLOUD_MEETINGS';

const actions = {
  searchMeetings: (query: string) => {
    return createAction(SEARCH_MEETINGS, query);
  },
  searchCloudUsers: (query: string) => {
    return createAction(SEARCH_CLOUD_USERS, query);
  },
  searchCloudMeetings: (query: string) => {
    return createAction(SEARCH_CLOUD_MEETINGS, query);
  },
  searchStart: () => {
    return createAction(SEARCH_START);
  },
  searchComplete: () => {
    return createAction(SEARCH_COMPLETE);
  }
};

export default actions;
