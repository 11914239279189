import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import React from 'react';
import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import Icon from 'components/Common/Icon';
import * as styles from './ScrollMessage.scss';

interface Props {
  isLoading: boolean;
  isZeroIndex: boolean;
}

const getClassName = _memoize((isLoading: boolean) => {
  return isLoading ? styles.loading : styles.done;
});

const getLoadingElement = _memoize((isLoading: boolean, isZeroIndex: boolean) => {
    return (
      <div>
        <Icon classes={['icon-buddy-noTM']} />
        <div>{isZeroIndex ? intl.get('loading') : intl.get('loadingMore')}</div>
      </div>
    );
},                                 (...args) => {
  return args[0] + '' + args[1];
});

const getEndOfListElement = _memoize(() => {
  return <div>{intl.get('endOfList')}</div>;
});

const hasScrollBar = () => {
  const scrollContainer = document.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer');
  if (scrollContainer && scrollContainer[0]) {
    const tableContainer = document.getElementsByClassName('InfiniteTable-tableContainer');
    const containerHeight = tableContainer ? tableContainer[0].clientHeight : 0;
    const listHeight = scrollContainer[0].clientHeight;
    return listHeight >= containerHeight;
  }
  return false;
};

const getLoadingStatusElement = (isLoading: boolean, isZeroIndex: boolean) => {
  if (isLoading) {
    return getLoadingElement(isLoading, isZeroIndex);
  }
  if (hasScrollBar()) {
    return getEndOfListElement();
  }
  // If scroll bar not visible, don't display message
  return null;
};

/**
 * Toggles between loading and done content depending on isLoading state
 */
const ScrollMessageRenderer: React.FunctionComponent<Props> = (props) => {
  const {isLoading, isZeroIndex} = props;
  const contents = getLoadingStatusElement(isLoading, isZeroIndex);
  if (contents) {
    return (
      <div id="scrollMessage" className={[styles.container, getClassName(isLoading)].join(' ')}>
        {contents}
      </div>
    );
  }
  return null;
};

const getLoaderCellRenderer = _memoize((row: RowInfo) => {
    return (
      <ScrollMessageRenderer
        isLoading={_get(row.original, 'loading')}
        isZeroIndex={row.index === 0}
      />
    );
  }
  ,                                    (...args) => {
    return _get(args[0].original, 'loading') + '' + args[0].index;
  });

export {
  getLoaderCellRenderer
};
