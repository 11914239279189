import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import middlewareHookConnector from 'utils/middlewareHookConnector';

const HookMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return (action: PayloadAction<string, object>) => {
        middlewareHookConnector.trigger(action);
        return next(action);
      };
    };
  };

export default (HookMiddleware as Middleware);
