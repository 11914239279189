import _get from 'lodash/get';
import _set from 'lodash/set';
import { sortByStartDateDesc, sortByTotalMinutesDesc } from 'utils/sorting-defaults';

export enum ViewName {
  CallHistory = 'CallHistory',
  LiveStreamHistory = 'LiveStreamHistory',
  UserCalls = 'UserCalls',
  IconCalls = 'IconCalls'
}

// tslint:disable-next-line
export const sortData = (data: any, sort: ViewName) => {
  let results = _get(data, 'data.data');
  if (results) {
    switch (sort) {
      case ViewName.CallHistory:
      case ViewName.LiveStreamHistory:
        results = sortByStartDateDesc(results);
        _set(data, 'data.data', results);
        break;
      case ViewName.UserCalls:
      case ViewName.IconCalls:
        results = sortByTotalMinutesDesc(results);
        _set(data, 'data.data', results);
        break;
      default:
        return data;
    }
  }
  return data;
};
