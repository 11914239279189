import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import ReactTable, { RowInfo } from 'react-table';
import * as styles from './DrillDownTable.scss';

const CallHistoryDrillDownTable = (row: RowInfo) => {
  const participants = _get(row.original, 'participants', []);
  if (!!participants) {
    return (
      <div className={styles.container}>
        <ReactTable
          data={participants}
          defaultPageSize={participants.length}
          showPaginationBottom={false}
          showPaginationTop={false}
          resizable={false}
          columns={[
            {
              Header: intl.get('participant'),
              accessor: 'name'
            }, {
              Header: intl.get('extension'),
              accessor: 'ext'
            }
          ]}
        />
      </div>
      );
  }
  return null;
};

export { CallHistoryDrillDownTable };
