import Search from 'components/Search/Search';
import { recordingResultsPendingSelector, selectSearchResults } from 'selectors/recordingsSelectors';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _debounce from 'lodash/debounce';
import _memoize from 'lodash/memoize';
import { connect, useSelector } from 'react-redux';
import searchActions from 'actions/searchActions';
import recordingActions from 'actions/recordingsActions';
import { Global } from 'state/Global';
import { SearchInterface } from 'state/Search';
import { DEFAULT_MEETING_TITLE } from 'utils/recordingsUtils';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  defaultMeetingText: string;
  showDefaultMeeting?: boolean;
}

const mapMeetings = _memoize((meetings, prependDefault: boolean, defaultMeetingText: string) => {
  // Remove any items missing display text, uuid or bad uuid
  meetings = _filter(meetings, (item) => !!item.name && !!item.uuid && item.uuid.length === 36);
  meetings = _map(meetings, (cloudMeeting) => {
    const id = _get(cloudMeeting, 'uuid');
    return {
      title: _get(cloudMeeting, 'name'),
      id,
      key: id,
    } as SearchInterface;
  });

  // Get the default entry
  const defaultEntry = _filter(meetings, (cloudMeeting) => _get(cloudMeeting, 'title') === DEFAULT_MEETING_TITLE);
  const allNonDefaultEntries = _filter(meetings, (cloudMeeting) => _get(cloudMeeting, 'title') !== DEFAULT_MEETING_TITLE);
  // Sort by display text
  meetings = _sortBy(allNonDefaultEntries, [(a: SearchInterface) => (a.title || '').toUpperCase()]);

  // Prepend 'Default' (or none) to every list (if user can modify meetings)
  if (prependDefault && defaultEntry.length) {
    const id = _get(defaultEntry[0], 'id');
    meetings = _concat([{
      title: defaultMeetingText,
      key: id,
      id
    }],                meetings);
  }

  return meetings;
});

const mapStateToProps = (state: Global, ownProps: Props) => {
  let cloudMeetings = selectSearchResults(state);
  // Remove any items missing display text, uuid or bad uuid
  // const canModifyMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];
  // const prependDefault = !!(canModifyMeetings && ownProps.showDefaultMeeting);
  const prependDefault = false;
  const meetings = mapMeetings(cloudMeetings, prependDefault, ownProps.defaultMeetingText);

  return {
    results: meetings,
    isFetching: recordingResultsPendingSelector(state)
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  // This action is dispatched from inside this application - not Nucleus
  clearResults: () => {
    dispatch(recordingActions.clearSearchResults());
  },
  awaitResults: () => {
    dispatch(recordingActions.recordingPendingResults());
  },
  search: _debounce((arg: string) => {
    dispatch(searchActions.searchCloudMeetings(arg));
  },                500),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
