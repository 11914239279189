import { connect } from 'react-redux';
import UploadRecordingComponent from 'components/Modals/UploadRecording/UploadRecordingComponent';
import { actions as modalActions } from 'actions/modalActions';
import actions from 'actions/recordingsActions';
import { S3UploadCredentials, UploadStatusResponse } from 'interfaces/Recording';
import { cancelUpload } from 'utils/uploadRecordingUtils';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async (s3Credentials: S3UploadCredentials | null) => {
    if (s3Credentials && s3Credentials.uploadUuid) {
      // Cancel upload
      await cancelUpload(s3Credentials.uploadUuid);
    }
    dispatch(modalActions.closeModal());
  },
  addProcessingUpload: (status: UploadStatusResponse) => dispatch(actions.addProcessingUpload(status))
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(UploadRecordingComponent);
