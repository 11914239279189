export enum Actions {
  ALEXA_AUTH_ERROR = 'ALEXA_AUTH_ERROR',
  ALEXA_AUTH_SUCCESS = 'ALEXA_AUTH_SUCCESS',
  ALEXA_CREDENTIAL_SUCCESS = 'ALEXA_CREDENTIAL_SUCCESS',
  ALEXA_CREDENTIAL_FAILURE = 'ALEXA_CREDENTIAL_FAILURE',
  ALEXA_DISCONNECT_SUCCESS = 'ALEXA_DISCONNECT_SUCCESS',
  ALEXA_DISCONNECT_FAILURE = 'ALEXA_DISCONNECT_FAILURE',
  ALEXA_DEVICE_STATE_SUCCESS = 'ALEXA_DEVICE_STATE_SUCCESS',
  ALEXA_DEVICE_STATE_FAILURE= 'ALEXA_DEVICE_STATE_FAILURE',
  ALEXA_GET_PROFILE_SUCCESS = 'ALEXA_GET_PROFILE_SUCCESS',
  ALEXA_GET_PROFILE_FAILURE = 'ALEXA_GET_PROFILE_FAILURE',
  ALEXA_DEVICE_REMOVAL_SUCCESS = 'ALEXA_DEVICE_REMOVAL_SUCCESS',
}

export enum DeviceRegistration {
  TRYING = 'TRYING',
  FAILED = 'FAILED',
  REGISTERED = 'REGISTERED',
  CONNECTED = 'CONNECTED',
  UNKNOWN = 'UNKNOWN'
}

export enum AuthorizationState {
  READY = 'READY',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS'
}

export const manageAlexaProfilesLink = 'https://docs.aws.amazon.com/a4b/latest/ag/manage-profiles.html';

export const alexaIntegrationEndpoint = 'lifesize.alexaIntegration';
export const alexaCredentialsEndpoint = 'credentials/';
export const alexaRegisterEndpoint = 'register/';
export const alexaDeviceEndpoint = (serialNumber: string) => `${alexaRegisterEndpoint}${serialNumber}`;
export const alexaProfileEndpoint = 'profiles/';
export const alexaHelpPage = (localeCode: string) => `https://www.lifesize.com/${localeCode}/help/admin-console/account-settings/device-settings/alexa-for-business-authentication`;

export const GET_PROFILES_INTERVAL = 10000;
export const DEVICE_REGISTRATION_REFRESH_TIME = 2 * 1000;
export const DEVICE_REGISTRATION_MAX_ATTEMPTS = 20;
