import { nucleusConstants } from '@lifesize/nucleus';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import listAllMeetingOwnersQuery from 'queries/meetings/listAllMeetingOwnersQuery.gql';
import getMeetingsByUUID from 'queries/meetings/getMeetingsByUUIDQuery.gql';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { actions, MeetingsActionType } from 'actions/meetingsActions';
import { getClient } from '../nucleus-apollo-client';
import { LiveStream } from 'typings/types';

const meetingsMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return async (action: PayloadAction<string, object | LiveStream[]>) => {
        switch (action.type) {
          case MeetingsActionType.FETCH_MEETINGS_AND_OWNERS:
            try {
              const client = await getClient(store.dispatch);
              const response = await client.query({ query: listAllMeetingOwnersQuery, fetchPolicy: 'no-cache' });
              const data = _get(response, 'data.directoryList.records', []);
              return next(actions.fetchMeetingsAndOwnersSuccess(data));
            } catch (error) {
              const errorDescription = _get(error, 'errorDescription', JSON.stringify(error));
              console.warn('Get meetings and owners error', errorDescription);
            }
            return next(action);
          case nucleusConstants.DWH_ALL_STREAMS_SUCCESS:
            const partialData = _get(action, 'payload') || []; // [{ auth_type, is_live, is_published, link, meeting_id, viewer_count }]
            next(actions.fetchLiveStreamsMeetingNamesRequest());
            try {
              const meetingIds = _map(partialData, (liveStream: LiveStream) => { // using lodash to avoid typescript error
                return liveStream.meeting_id;
              });
              const client = await getClient(next);
              const response = await client.query({ query: getMeetingsByUUID, fetchPolicy: 'no-cache', variables: { uuids: meetingIds } });
              const nameData = _keyBy(_get(response, 'data.directoryByUUIDsWithFilter') || [], 'UUID');
              const completeData = _reduce(partialData, (result: LiveStream[], liveStream: LiveStream) => {
                const displayName = _get(nameData[liveStream.meeting_id], 'displayName');
                if (displayName) {
                  result.push({
                    ...liveStream,
                    displayName
                  });
                }
                return result;
              },                           []);
              next(actions.fetchLiveStreamsMeetingNamesSuccess(completeData));
            } catch (error) {
              const errorDescription = _get(error, 'errorDescription', JSON.stringify(error));
              console.warn('Fetch Live Streams Meeting Names Failure', errorDescription);
              next(actions.fetchLiveStreamsMeetingNamesFailure(errorDescription));
            }
            return next(action);
          default:
            return next(action);
        }
      };
    };
  };

export default (meetingsMiddleware as Middleware);
