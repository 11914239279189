import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import activeMeetingsLengthQuery from 'queries/meetings/activeMeetingsLengthQuery.gql';
import _get from 'lodash/get';
import LiveMeetingsMenuItem from 'components/DashboardNew/LiveMeetingsMenuItem/LiveMeetingsMenuItem';
import { Global } from 'state/Global';
import { selectUserActive } from 'selectors/userStateSelector';

const mapStateToProps = (state: Global) => ({
  userIsActive: selectUserActive(state)
});
const mapDispatchToProps = () => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(activeMeetingsLengthQuery, {
    options: () => {
      return {
        fetchPolicy: 'network-only'
      };
    },
    // tslint:disable-next-line
    props: ({ data, ownProps }: any) => {
      const liveMeetingsLength = _get(data, 'liveMeetings.totalRecordCount', 0);
      return {
        ...ownProps,
        data,
        liveMeetingsLength,
      };
    },
  }),
)(LiveMeetingsMenuItem);
