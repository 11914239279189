import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _pick from 'lodash/pick';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import listMeetingsQuery from 'queries/meetings/listMeetingsQuery.gql';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import MeetingInlineFragment = ListMeetingsQuery.MeetingInlineFragment;
import MeetingsHeader from './MeetingsHeader';
import React from 'react';
import { hasMoreRecords } from 'utils/directoryUtils';
import { ListMeetingsQuery } from 'typings/types';
import { meetingsTabColumnConfig } from './MeetingsTabColumnConfig';
import { SortingRule } from 'react-table';
import { getSortingRule } from 'utils/listUtils';
import useListHooks from 'hooks/useListHooks';
import { SortDirection } from 'constants/queryConstants';
import { Sort } from 'interfaces/Meeting';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  title: string;
  isFetching: boolean;
  deleteMeetings: (arg: Array<MeetingInlineFragment>) => void;
  addMeetingModalOpen: () => void;
  editMeeting: (contact: MeetingInlineFragment) => void;
  selectionArray: Array<string>;
  fetchMoreRows: () => Promise<{}>;
}

const MeetingsTab = (props: Props) => {
  const defaultGraphQLSort: Sort = {
    propertyName: 'displayName',
    direction: SortDirection.ASC
  };
  const { deleteMeetings, editMeeting, title } = props;
  const {
    loading,
    error,
    data,
    setFilterString,
    sort,
    setSort,
    fetchMoreRows
  } = useListHooks(listMeetingsQuery, { sort: defaultGraphQLSort });
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  const handleDeleteMeetings = (meetings: Array<string>) => {
    const directoryList = _get(data, 'directoryList');
    const records: Array<object | null> = directoryList ? _get(directoryList, 'records', []) : [];
    if (records.length) {
      // Get the full Contact object, as required by Nucleus, from selected uuid
      // @ts-ignore
      const requestedMeetings: Array<MeetingInlineFragment> = _filter(
        records,
        (arg: MeetingInlineFragment) => {
          return _includes(meetings, arg.uuid);
        },
      );
      if (requestedMeetings.length) {
        deleteMeetings(requestedMeetings);
      }
    }
  };

  const onFetchData: (args: { sorted: Array<SortingRule> }) => void = async ({
                                                                               sorted: [column],
                                                                             }) => {
    if (!data) {
      return;
    }

    const newSort = await getSortingRule(column);
    if (newSort) {
      setSort(newSort);
    }
  };

  const listData = !loading ? _get(data, 'directoryList.records', []) : [];
  const listViewProps = [ title, error ];
  const headerProps = _pick(props, [
    'addMeetingModalOpen'
  ]);

  return (
    <ListViewLayout
      hideHeader={true}
      isFetching={false}
      numberOfPages={0}
      {...listViewProps}
    >
      <InfiniteTable
        columnConfig={meetingsTabColumnConfig(editMeeting)}
        data={listData as MeetingInlineFragment[]}
        defaultSort={[
          {
            id: _get(sort, 'propertyName', ''),
            desc: _get(sort, 'direction') === 'DESC'
          },
        ]}
        enableSelect={canEditMeetings}
        fetchMore={fetchMoreRows}
        getTdProps={() => ({
          style: {
            alignItems: 'flex-start',
            paddingTop: '16px',
            paddingBottom: '16px',
            lineHeight: '12px'
          }
        })}
        getTheadTrProps={() => ({
          style: {
            paddingLeft: '5px'
          }
        })}
        getTrProps={() => ({
          style: {
            paddingLeft: '5px'
          }
        })}
        hasMoreRecords={hasMoreRecords(data)}
        keyField={'UUID'}
        loading={loading}
        onFetchData={onFetchData}
        renderHeader={(selectedRows = []) => (
          <MeetingsHeader
            deleteMeetings={handleDeleteMeetings}
            onFilterStringChange={(newString: string) => {
              setSort({ propertyName: newString.length ? '' : 'displayName', direction: SortDirection.ASC });
              setFilterString(newString);
            }}
            selectionArray={selectedRows}
            {...headerProps}
          />
        )}
        rowHeightOverride={50}
      />
    </ListViewLayout>
  );
};

export default MeetingsTab;
