import * as React from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import { ClipLoader } from 'react-spinners';
import * as styles from './Toast.scss';

export default () => {
  return (
    <div className={styles.container}>
      <div className={`${styles.iconContainer} ${styles.redIcon}`}><Icon classes={['icon-ic-alert']} /></div>
      <div className={styles.messageContainer}>{intl.get('toastNetworkIssue')}</div>
      <div className={styles.rightContainer}>
        <ClipLoader
          sizeUnit="px"
          size={17}
          color={'#ccc'}
        />
      </div>
    </div>
  );
};
