import { connect } from 'react-redux';
// import intl from 'react-intl-universal';
import RoomSystemsTable from '../../components/Drilldowns/UsersAndVideo/RoomSystemsTable';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import actions from '../../actions/drilldownActions';
import { Global } from '../../state/Global';
import { filteredRoomSystemCalls } from '../../selectors/datawarehouseSelectors';
import { defaultRowCount } from '../../constants/listViewLayoutConstants';
import { selectPageNumber } from '../../selectors/paginationSelectors';

function mapStateToProps(state: Global) {
  const roomData = dataWarehouseSelectors.usersGroupByCallsRoom(state);
  const filteredRoomSystems = filteredRoomSystemCalls(state);
  const pageNumber = selectPageNumber(state);
  return {
    isFetching: roomData.isFetching,
    error: roomData.error,
    numberOfPages: Math.ceil(filteredRoomSystems.length / defaultRowCount),
    listData: filteredRoomSystems,
    pageNumber: pageNumber,
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchListData: () => {
    dispatch(actions.handleFetchDrilldownVideoUsers());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomSystemsTable);
