import {
  DWHCallHistoryQueryMetadataType,
  DWHLiveStreamQuestionType,
  DWHLiveStreamResultType,
  DWHLiveStreamResultTypeV2,
  DWHLiveStreamViewerTypeV2
} from 'interfaces/Dashboard';
import {
  formatDateTimeFromUnix,
  formatElapsedTime,
  formatSecondsForDisplay,
  formatUnixDateAtTimeForDisplay,
  formatUnixDateTimeForDisplay,
  formatUnixTimeOfDayForDisplay,
  secondsToMilliseconds
} from './date-time-utils';
import { sortByStartDateDesc } from './sorting-defaults';
import intl from 'react-intl-universal';
import _get from 'lodash/get';

export const liveStreamV2Cutoff = '2019-12-01';

export const parseDataForDisplay = (data: Array<DWHLiveStreamResultType>): Array<DWHLiveStreamResultType> => {
  const returnArray: DWHLiveStreamResultType[] = [];
  if (data && data.length) {
    for (let call of data) {
      call.durationFormatted = formatSecondsForDisplay(call.durationSeconds);
      call.startDateFormatted = formatUnixDateAtTimeForDisplay(call.startDate);
      call.name = call.streamName;
      call.totalMinutes = Math.floor(call.durationSeconds / 60);
      call.v2Data = false;
      returnArray.push(call);
    }
    if (returnArray.length) {
      return sortByStartDateDesc(returnArray);
    }
  }
  return returnArray;
};

export const parseV2DataForDisplay = (data: Array<DWHLiveStreamResultTypeV2>): Array<DWHLiveStreamResultTypeV2> => {
  for (let call of data) {
    call.durationFormatted = formatSecondsForDisplay(call.durationSeconds);
    call.durationSecondsFormatted = formatElapsedTime(call.durationSeconds);
    call.startDateFormatted = formatUnixDateAtTimeForDisplay(call.startedAt * 1000);
    call.startDate = call.startedAt * 1000;
    call.viewerCount = call.viewers;
    call.name = call.meetingName || call.meetingUUID;
    call.streamName = call.meetingName || call.meetingUUID;
    call.totalMinutes = Math.max(Math.floor(call.durationSeconds / 60), 1);
    call.v2Data = true;
  }

  return data;
};

export const mapV2ViewerList = (data: Array<DWHLiveStreamViewerTypeV2>, startDate?: number) => {
  if (!data || !data.length) {
    return [];
  }
  for (let call of data) {
    call.viewerName = call.displayName;
    if (call.moderator) {
      call.role = intl.get('moderator');
    } else if (call.guest) {
      call.role = intl.get('guest');
    } else {
      call.role = intl.get('user');
    }
    const timeline = _get(call, ['timeline'], []);
    const joinTime = _get(timeline, ['0', '0']);
    const leaveTime = _get(timeline, [timeline.length - 1, '1']);
    if (startDate) {
      if (!isNaN(joinTime) && !call.joinTime) {
        const joinTimeStamp = parseInt(joinTime, 10) * 1000;
        call.joinTimeStamp = joinTimeStamp;
        call.joinTime = formatDateTimeFromUnix(startDate + joinTimeStamp);
      }
      if (!isNaN(leaveTime) && !call.leaveTime) {
        const leaveTimeStamp = parseInt(leaveTime, 10) * 1000;
        call.leaveTimeStamp = leaveTimeStamp;
        call.leaveTime = formatDateTimeFromUnix(startDate + leaveTimeStamp);
      }
    }
  }

  return data;
};

// Fetch additional live stream information from the query
export const fetchMoreLiveStreamRows = async (metadata: { cursor?: string }, fetchMore: (options: object) => {}) => {
  // Bail if there are no additional rows to fetch
  if (!metadata.cursor) {
    return {};
  }

  return fetchMore({
    variables: {cursor: metadata.cursor}, // Pass in the previous cursor to our query.
    // tslint:disable-next-line
    updateQuery: (prev: { metadata: DWHCallHistoryQueryMetadataType, liveStreams: DWHLiveStreamResultTypeV2 }, {fetchMoreResult}: { fetchMoreResult: DWHLiveStreamResultTypeV2 }) => {
      // Merge our new results into previous cached results.
      if (_get(fetchMoreResult, 'errors', false)) {
        throw _get(fetchMoreResult, 'errors', false);
      }
      const previousLiveStreamList = _get(prev, 'liveStreams.data', []);
      const fetchMoreResultLiveStreamList = _get(fetchMoreResult, 'liveStreams.data', []);

      return {
        ...prev,
        liveStreams: {
          ...prev.liveStreams,
          metadata: {
            ...prev.metadata,
            ..._get(fetchMoreResult, 'liveStreams.metadata', {})
          },
          data: [
            ...previousLiveStreamList,
            ...fetchMoreResultLiveStreamList
          ],
        },
      };
    },
  });
};

export const parseQuestionsForDisplay = (data: DWHLiveStreamQuestionType[]) => {
  return data.map(question => {
    return {
      ...question,
      timeFormatted: formatUnixTimeOfDayForDisplay(secondsToMilliseconds(question.time))
    };
  });
};

export const parseMetadataForDisplay = (data: DWHLiveStreamResultType) => {
  return {
    ...data,
    durationSecondsFormatted: formatElapsedTime(data.durationSeconds),
    startDateFormatted: formatUnixDateTimeForDisplay(data.startDate)
  };
};

export default {
  parseDataForDisplay,
  parseV2DataForDisplay,
  mapV2ViewerList,
  liveStreamV2Cutoff,
  fetchMoreLiveStreamRows,
  parseQuestionsForDisplay,
  parseMetadataForDisplay
};
