import { ModalTypes } from 'constants/modalConstants';
import ShareProperties from 'interfaces/ShareProperties';
import SingleRecordingInterface from 'interfaces/SingleRecording';

/**
 * Default request values to fetch a single recording
 */
export const initialSingleRecordingState: SingleRecordingInterface = {
  recordingId: '',
  singleRecordingData: null,
  getShareProperties: (recordingId: string) => { return; },
  getShareLinkCloudGroup: (recordingId: string) => { return; },
  getShareLinkGlobal: (recordingId: string) => { return; },
  addToWatchList: (recordingId: string) => { return; },
  removeFromWatchList: (recordingId: string) => { return; },
  fetchSingleRecording: (recordingId: string) => { return; },
  // Get the single recording
  isFetching: false,
  notAuthorized: false,
  notFound: false,
  // Delete single recording
  isDeleting: false,
  // Add to watch list
  isAddingToWatchList: false,
  hasBeenAddedToWatchList: false,
  // Get share info
  isFetchingShareInfo: false,
  shareInfo: null,
  // Update share info
  setShareProperties: (recordingId: string, shareProps: ShareProperties) => { return; },
  isUpdatingShareProperties: false,
  hasUpdatingSharePropertiesCompleted: false,
  // Get cloud links
  isFetchingLinksForCloudGroup: false,
  linksForCloudGroup: {},
  // Get global links
  isFetchingLinksGlobal: false,
  linksGlobal: {},
  setRecordingId: (id: string) => { return; },
  confirmDelete: () => { return; },
  openModal: (type: ModalTypes) => { return; },
  openEditModal: (recordingId: string) => { return; },
  isOnWatchList: false,
  canUserModifyRecording: false,
  openShareModal: (recordingId: string) => { return; },
};
