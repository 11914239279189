import { Reducer } from 'redux';
import {
  DRILLDOWN_FETCH_LIVE_STREAM_HISTORY,
  DRILLDOWN_FETCH_LIVE_STREAM_HISTORY_BY_NAME
} from 'actions/liveStreamActions';
import { LiveStreamHistoryState, initialLiveStreamHistoryState } from 'state/LiveStreamHistoryState';

const liveStreamHistoryReducer: Reducer<LiveStreamHistoryState> =
  (state: LiveStreamHistoryState = initialLiveStreamHistoryState, action) => {
    switch (action.type) {
      case DRILLDOWN_FETCH_LIVE_STREAM_HISTORY_BY_NAME:
        return { ...state, nameFilter: action.payload };
      case DRILLDOWN_FETCH_LIVE_STREAM_HISTORY:
        return { ...state, nameFilter: null };
      default:
        return state;
    }
  };

export default liveStreamHistoryReducer;
