import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _uniqueId from 'lodash/uniqueId';
import querystring from 'querystring';
import intl from 'react-intl-universal';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { actions, CalendarSubscriptionActions } from '../actions/calendarSubscriptionActions';
import { actions as modalActions } from '../actions/modalActions';
import { Methods } from 'interfaces/HTTP';

const calendarCallbacks = {};

const calendarSubscriptionMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return async (action: PayloadAction<string, string | object>) => {
        const intlUniversalOptions = intl.getInitOptions();
        const currentLocale = intlUniversalOptions.currentLocale;
        let queryString;
        switch (action.type) {
          case CalendarSubscriptionActions.GET_SUBSCRIPTION:
            next(action);
            // Fetch existing subscription
            queryString = querystring.stringify({ d: action.payload });
            await fetch(`${process.env.REACT_APP_CALENDAR_URL}/get_subscription?${queryString}`, {
              method: Methods.GET,
              credentials: 'include'
            }).then(async (res) => {
              const response = await (res).json();
              if (_isNil(response.calendar) || response.calendar === '') {
                next(actions.subscriptionNotFound());
              } else {
                next(actions.subscriptionFound(response.calendar));
              }
            }).catch(error => {
              // tslint:disable-next-line
              console.error('Error during calendar subscription lookup:', error);
              next(actions.subscriptionLookupError());
            });
            return;
          case CalendarSubscriptionActions.UNSUBSCRIBE:
            next(action);
            // Unsubscribe from existing subscription
            queryString = querystring.stringify({ d: action.payload, lang: currentLocale });
            await fetch(`${process.env.REACT_APP_CALENDAR_URL}/script_unlink?${queryString}`, {
              method: Methods.GET,
              credentials: 'include'
            }).then(async (res) => {
              await (res).json();
              next(actions.unsubscribeSuccess());
              next(modalActions.closeModal());
            }).catch(error => {
              // tslint:disable-next-line
              console.error('Error during calendar subscription unsubscribe:', error);
              next(actions.unsubscribeFailure());
            });
            return;
          case CalendarSubscriptionActions.SUBSCRIBE_REQUEST:
            const callbackId = _uniqueId('lsCalendarCb_');
            calendarCallbacks[callbackId] = (response: string) => {
              next(actions.subscribeSuccess(response));
            };
            window.open(`${process.env.REACT_APP_CALENDAR_URL}/start?d=${action.payload}&lang=${currentLocale}`, callbackId, 'width=800,height=600');
            return next(action);
          case CalendarSubscriptionActions.HANDLE_CALENDAR_MESSAGE:
            const event = action.payload;
            const winId = _get(event, 'data.win_id');
            calendarCallbacks[winId](_get(event, 'data.calendar'));
            return next(action);
          default:
            return next(action);
        }
      };
    };
  };

export default (calendarSubscriptionMiddleware as Middleware);
