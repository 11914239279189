import React from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import { Field } from 'formik';
import _get from 'lodash/get';
import Dropzone from 'react-dropzone';
import * as styles from './UploadFileComponent.scss';

interface Props {
  fieldName: string;
  sizeLimit: number;
  typeLimit: string[];
  multiple?: boolean;
  className?: string;
  maxWidth?: number;
  maxHeight?: number;
  onChange?: (file: File) => void;
  fileSizeError?: string;
}

const UploadFileComponent = (props: Props) => {
  const [isVideo, setIsVideo] = React.useState(false);
  const [isImage, setIsImage] = React.useState(false);
  const [preview, setPreview] = React.useState<string | ArrayBuffer | null>(null);
  const { fieldName, sizeLimit, typeLimit, maxHeight, maxWidth, onChange, fileSizeError, ...rest } = props;

  const hasSizeError = (file: File) => {
    return sizeLimit && file.size > sizeLimit;
  };
  const hasTypeError = (file: File) => {
    return typeLimit && !typeLimit.includes(file.type);
  };
  const isImageFile = (file: File) => {
    return file.type.startsWith('image');
  };
  const isVideoFile = (file: File) => {
    return file.type.startsWith('video');
  };
  const hasDimensionsError = (image: HTMLImageElement) => ((maxWidth && image.width > maxWidth) || (maxHeight && image.height > maxHeight));
  const getPreview = (value: object) => {
    if (preview) {
      if (isImage) {
        return <img src={preview} />;
      } else if (isVideo) {
        return <video src={preview} />;
      }
    }
    return (
      <div>
        {intl.get('uploadFileSelected', { filename: _get(value, 'file.name') })}
      </div>
    );
  };
  const handleReadingFile = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (isImageFile(file)) {
        if (maxWidth || maxHeight) {
          const image = new Image();
          image.src = reader.result;
          image.onload = function () {
            if (hasDimensionsError(image)) {
              // Dimension error
              throw Error(`${file.name} ${intl.get('dimensionError')}`);
            }
          };
        }
        setIsImage(true);
        setPreview(reader.result);
      } else if (isVideoFile(file)) {
        // Is video
        const blobURL = URL.createObjectURL(file);
        setIsVideo(true);
        setPreview(blobURL);
      }
    };
    if (isVideoFile(file)) {
      return reader.readAsArrayBuffer(file);
    }
    reader.readAsDataURL(file);
  };
  const handleDrop = (accepted: File[], setFieldValue: (field: string, value: object) => void, setFieldError: (field: string, message: string) => void) => {
    accepted.forEach((file) => {
      try {
        if (hasSizeError(file)) {
          // Size error
          throw Error(`${file.name} ${fileSizeError ? fileSizeError : intl.get('sizeError')}`);
        }
        if (!file.type) {
          throw Error(intl.get('missingTypeError', { fileName: file.name }));
        }
        if (hasTypeError(file)) {
          // Type error
          throw Error(intl.get('typeError', { fileName: file.name }));
        }
        handleReadingFile(file);
        setFieldValue(fieldName, file);
        if (onChange) {
          onChange(file);
        }
      } catch (e) {
        setFieldError(fieldName, e.message);
      }
    });
  };

  return (
    <Field name={fieldName} id={fieldName}>
      {({ field: { value }, form: { setFieldValue, setFieldError } }) => (
        <Dropzone
          onDrop={(accepted: File[]) => handleDrop(accepted, setFieldValue, setFieldError)}
          {...rest}
        >
          {value ? getPreview(value) : (
            <>
              <Icon classes={['icon-upload', styles.icon]} />
              {intl.get('dropUpload')}
              <div className={styles.browse}>
                {intl.get('dropBrowse')}
              </div>
            </>
          )}
        </Dropzone>
      )}
    </Field>
  );
};

export default UploadFileComponent;
