export type PermissionSetting = {
  [title: string]: boolean;
};

export interface Role {
  accountId: string;
  updatedAt: number;
  permissions: PermissionSetting;
  createdAt: number;
  baseRoleId: string;
  name: string;
  id: string;
  description?: string;
}

export interface PermissionSelection {
  label: string;
  setting: PermissionSetting;
  selected?: boolean;
  disabled?: boolean;
}

export interface Permission {
  title: string;
  view?: PermissionSelection[];
  edit?: PermissionSelection[];
}

export enum ROLE_PERMISSION_TYPE {
  'ALL' = 'all',
  'NONE' = 'none',
  'OWNED' = 'owned',
  'SHARED' = 'ownedShared',
  'OWN_MODERATING' = 'ownModerating',
  'OWNED_MODERATED' = 'ownedModerated'
}

export enum ROLE_PERMISSION {
  'ACCESS_ADMIN_CONSOLE' = 'ACCESS_ADMIN_CONSOLE',
  'CREATE_ACCOUNTS' = 'CREATE_ACCOUNTS',
  'MODERATE_OWN_ACCOUNT_CONFERENCES' = 'MODERATE_OWN_ACCOUNT_CONFERENCES',
  'MODIFY_ACCOUNTS' = 'MODIFY_ACCOUNTS',
  'MODIFY_GROUPCONTACTS' = 'MODIFY_GROUPCONTACTS',
  'MODIFY_HIDDEN_MEETINGS' = 'MODIFY_HIDDEN_MEETINGS',
  'MODIFY_MEETINGS' = 'MODIFY_MEETINGS',
  'MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS' = 'MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS',
  'MODIFY_OWN_ACCOUNT_RECORDINGS' = 'MODIFY_OWN_ACCOUNT_RECORDINGS',
  'MODIFY_OWN_MEETINGS' = 'MODIFY_OWN_MEETINGS',
  'MODIFY_OWN_RECORDINGS' = 'MODIFY_OWN_RECORDINGS',
  'MODIFY_OWN_ROOMS' = 'MODIFY_OWN_ROOMS',
  'MODIFY_ROLES' = 'MODIFY_ROLES',
  'MODIFY_ROOMS' = 'MODIFY_ROOMS',
  'MODIFY_USERS' = 'MODIFY_USERS',
  'RECORD_OWN_ACCOUNT_CONFERENCES' = 'RECORD_OWN_ACCOUNT_CONFERENCES',
  'VIEW_DASHBOARD' = 'VIEW_DASHBOARD',
  'VIEW_HIDDEN_MEETINGS' = 'VIEW_HIDDEN_MEETINGS',
  'VIEW_LIVE_MEETINGS' = 'VIEW_LIVE_MEETINGS',
  'VIEW_MEETINGS' = 'VIEW_MEETINGS',
  'VIEW_MODERATOR_LIVE_MEETINGS' = 'VIEW_MODERATOR_LIVE_MEETINGS',
  'VIEW_MODERATOR_PAST_MEETINGS' = 'VIEW_MODERATOR_PAST_MEETINGS',
  'VIEW_OWN_ACCOUNT_CONFERENCE_DETAILS' = 'VIEW_OWN_ACCOUNT_CONFERENCE_DETAILS',
  'VIEW_OWN_ACCOUNT_GENERAL_SETTINGS' = 'VIEW_OWN_ACCOUNT_GENERAL_SETTINGS',
  'VIEW_OWN_ACCOUNT_RECORDINGS' = 'VIEW_OWN_ACCOUNT_RECORDINGS',
  'VIEW_OWN_RECORDINGS' = 'VIEW_OWN_RECORDINGS',
  'VIEW_PAST_MEETINGS' = 'VIEW_PAST_MEETINGS',
  'VIEW_ROLES' = 'VIEW_ROLES',
  'VIEW_ROOMS' = 'VIEW_ROOMS',
  'VIEW_USERS' = 'VIEW_USERS',
}
