import React, { useEffect, useState } from 'react';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import * as styles from './MergeRequests.scss';
import intl from 'react-intl-universal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { SortingRule } from 'react-table';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import MergeRequestHeaderToolbar from './MergeRequestsHeader';
import _orderBy from 'lodash/orderBy';
import { MergeRequest } from 'interfaces/MergeRequest';
import { mergeRequestsColumnConfig } from './MergeRequestsColumnConfig';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  data: MergeRequest[] | null;
  loading: boolean;
  error: boolean;
  setGetUpdates: (set: boolean) => void;
}

const MergeRequests = (props: Props) => {
  const { data, loading, error, setGetUpdates } = props;
  const [ sort, setSort ] = useState<SortingRule>(
    {
      id: 'createdAt',
      desc: true
    }
  );
  const [ filteredData, setFilteredData ] = useState<null | MergeRequest[]>(null);
  const [ filterString, setFilterString ] = useState('');
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  useEffect(
    () => {
      const orderedData = _orderBy(data || [], [sort.id], [sort.desc ? 'desc' : 'asc']);
      if (!filterString.length || !orderedData.length) {
        setFilteredData(orderedData);
      } else {
        setFilteredData(orderedData.filter((mergeRequest: MergeRequest) => mergeRequest.sourceAdminEmail.includes(filterString)));
      }
    },
    [filterString, data, sort]
  );

  const onFetchData: (args: { sorted: Array<SortingRule> }) => void = async ({sorted: [column]}) => {
    setSort(column);
  };

  if (loading) {
    return (
      <LoadingIndicator/>
    );
  }

  return (
    <div className={styles.mergeRequestsContainer}>
      { error ?
        <div className={styles.errorContainer}>{intl.get('unableToLoadMergeRequests')}</div>
        :
        <ListViewLayout
          hideHeader={true}
          isFetching={false}
          numberOfPages={0}
        >
          <InfiniteTable
            data={filteredData ? filteredData : []}
            loading={loading}
            defaultSort={[sort]}
            noDataText={intl.get('noRequests')}
            columnConfig={mergeRequestsColumnConfig()}
            renderHeader={() => (
              <MergeRequestHeaderToolbar
                onFilterStringChange={setFilterString}
                onMergeSuccess={() => setGetUpdates(true)}
              />
            )}
            enableSelect={canEditSettings}
            enableSelectAll={canEditSettings}
            onFetchData={onFetchData}
            hasMoreRecords={false}
            keyField={'requestUUID'}
            sortable={true}
          />
        </ListViewLayout>
      }
    </div>
  );
};

export default MergeRequests;
