export interface ImpersonationState {
  activeSession: boolean;
  code: string | null;
  error: string | null;
}

export const initialImpersonationState: ImpersonationState = {
  activeSession: false,
  code: null,
  error: null,
};
