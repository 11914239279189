import React from 'react';
import { useSelector } from 'react-redux';
import { authSelectors } from '@lifesize/nucleus';
import intl from 'react-intl-universal';
import styles from './FreemiumUpgradeLink.scss';
import { Button } from 'semantic-ui-react';
import { buildEcommerceUrl } from 'utils/ecommerceUtils';
import { isFreemium } from 'selectors/accountSelectors';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const FreemiumUpgradeLink = () => {
  const isFreemiumAccount = useSelector(isFreemium);
  const jwt = useSelector(authSelectors.getPrimaryJwt);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  return (
    <div className={styles.container}>
      {isFreemiumAccount && (
        <div className={styles.freemiumInfo}>
          {intl.get('upgradePrompt')}
        </div>
      )}
      <div className={styles.buttonContainer}>
        <Button
          role="button"
          type="button"
          className={styles.link}
          href={buildEcommerceUrl(jwt)}
          disabled={!canEditSettings}
          {...{ ['data-test']: 'navLink.freemiumUpgrade' }}
        >
          {intl.get('upgrade')}
        </Button>
      </div>
    </div>
  );
};

export default FreemiumUpgradeLink;
