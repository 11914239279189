import React, { SyntheticEvent } from 'react';
import intl from 'react-intl-universal';
import { Field, Formik, Form } from 'formik';
import get from 'lodash/get';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import * as styles from 'components/Settings/Sections.scss';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

// props passed in
export interface Props {
  recordingEnabled: boolean;
  recordingPublicSharing: boolean;
  updateRecording: Function;
  updateRecordingPublicSharing: Function;
}

const RecordAndShare = (props: Props) => {
  const {
    recordingEnabled,
    recordingPublicSharing,
    updateRecordingPublicSharing,
    updateRecording
  } = props;

  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const onSubmit = (formData: { recordingPublicSharing?: boolean, recordingEnabled?: boolean }, callback: Function) => {
    if (formData?.recordingPublicSharing !== recordingPublicSharing) {
      updateRecordingPublicSharing(formData?.recordingPublicSharing);
    }
    if (formData?.recordingEnabled !== recordingEnabled) {
      updateRecording(formData?.recordingEnabled);
    }
    callback();
  };
  return (
    <section className={styles.container} data-test="settingsSection.record">
      <Formik
        initialValues={{
          recordingEnabled,
          recordingPublicSharing,
        }}
        onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => setSubmitting(false))}
        render={({handleChange, setFieldValue, submitForm, values}) => (
          <Form>
            <article>
              <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitleRecordAndShare')}</h6>
              <p className={styles.description}>{intl.get('settingsSectionDescriptionRecordAndShare')}</p>
              <div className={styles.formSection}>
                <Field
                  component={ToggleButton}
                  id="recordingEnabled"
                  label={intl.get('inputLabelAllowRecording')}
                  name="recordingEnabled"
                  disabled={!canEditSettings}
                  onChange={async (e: SyntheticEvent) => {
                    if (!canEditSettings) { return; }
                    const allowRecording = get(e.target, 'checked');
                    // important: await handleChange() before submitForm() (or submitForm() will not fire)
                    await handleChange({ target: { name: 'recordingEnabled', value: allowRecording }});
                    if (!allowRecording) {
                      await setFieldValue('recordingPublicSharing', false);
                    }
                    submitForm();
                  }}
                  value={values.recordingEnabled}
                  {...{ ['data-test']: 'settingsSectionToggle.record' }}
                />
              </div>
            </article>
            <article>
              <div className={styles.formSection}>
                <Field
                  component={ToggleButton}
                  disabled={!values.recordingEnabled || !canEditSettings}
                  id="recordingPublicSharing"
                  label={intl.get('inputLabelAllowPublicSharing')}
                  name="recordingPublicSharing"
                  onChange={async (e: SyntheticEvent) => {
                    if (!canEditSettings) { return; }
                    // important: await handleChange() before submitForm() (or submitForm() will not fire)
                    await handleChange({
                      target: { name: 'recordingPublicSharing', value: get(e.target, 'checked') }
                    });
                    submitForm();
                  }}
                  value={values.recordingPublicSharing}
                  {...{ ['data-test']: 'settingsSectionToggle.share' }}
                />
              </div>
            </article>
          </Form>
        )}
      />
    </section>
  );
};

export default RecordAndShare;
