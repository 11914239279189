import { createAction } from '@lifesize/typescript-react-redux-utils';

export const DRILLDOWN_FETCH_LIVE_STREAM_HISTORY = 'DRILLDOWN_FETCH_LIVE_STREAM_HISTORY';
export const DRILLDOWN_FETCH_LIVE_STREAM_HISTORY_BY_NAME = 'DRILLDOWN_FETCH_LIVE_STREAM_HISTORY_BY_NAME';

export const actions = {
  fetchLiveStreamHistoryByName: (name: string) =>
    createAction(DRILLDOWN_FETCH_LIVE_STREAM_HISTORY_BY_NAME, name),

  fetchLiveStreamHistory: () =>
    createAction(DRILLDOWN_FETCH_LIVE_STREAM_HISTORY),
};

export default actions;
