import { connect } from 'react-redux';
import { Global } from 'state/Global';
import AlexaSettings from 'components/AlexaSettings/AlexaSettings';
import { actions as ModalActions } from 'actions/modalActions';
import { ModalTypes } from 'constants/modalConstants';
import { Actions, AuthorizationState } from 'constants/alexaIntegrationConstants';
import alexaIntegrationThunks from 'thunks/alexaIntegrationThunks';
import {
  selectAuthenticationState,
  selectExternalId,
  selectIntegrationStatus,
  selectInvalidAuth,
  selectRoleArn
} from 'selectors/alexaIntegrationSelector';

const mapStateToProps = (state: Global) => {
  let authState = AuthorizationState.READY;
  if (selectIntegrationStatus(state) === Actions.ALEXA_AUTH_ERROR) {
    authState = AuthorizationState.FAILURE;
  } else if (selectIntegrationStatus(state) === Actions.ALEXA_AUTH_SUCCESS) {
    authState = AuthorizationState.SUCCESS;
  }
  return ({
    authenticated: selectAuthenticationState(state),
    roleArn: selectRoleArn(state),
    externalId: selectExternalId(state),
    invalid: selectInvalidAuth(state),
    authState
  });
};

const mapDispatchToProps = (dispatch: Function) => ({
  getCredentials: async () => dispatch(alexaIntegrationThunks.getAlexaCredentials()),
  authenticate: async (roleArn: string, externalId: string) => dispatch(alexaIntegrationThunks.authenticateAlexa(roleArn, externalId)),
  disconnect: (externalId: string) => {
    dispatch(ModalActions.openModal({ modalType: ModalTypes.ALEXA_DISCONNECT, payload: { externalId } }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AlexaSettings);
