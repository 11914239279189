import { createAction } from '@lifesize/typescript-react-redux-utils';

export enum CalendarSubscriptionActions {
  GET_SUBSCRIPTION = 'GET_SUBSCRIPTION',
  HANDLE_CALENDAR_MESSAGE = 'HANDLE_CALENDAR_MESSAGE',
  RESET_SUBSCRIPTION_STATE = 'RESET_SUBSCRIPTION_STATE',
  SUBSCRIBE_REQUEST = 'SUBSCRIBE_REQUEST',
  SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS',
  SUBSCRIPTION_FOUND = 'SUBSCRIPTION_FOUND',
  SUBSCRIPTION_LOOKUP_FAILURE = 'SUBSCRIPTION_LOOKUP_FAILURE',
  SUBSCRIPTION_NOT_FOUND = 'SUBSCRIPTION_NOT_FOUND',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  UNSUBSCRIBE_FAILURE = 'UNSUBSCRIBE_FAILURE',
  UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS',
}

export const actions = {
  getCalendarSubscription: (id: string) => createAction(CalendarSubscriptionActions.GET_SUBSCRIPTION, id),
  handleCalendarMessage: (event: Event) => createAction(CalendarSubscriptionActions.HANDLE_CALENDAR_MESSAGE, event),
  subscriptionFound: (results: object) => createAction(CalendarSubscriptionActions.SUBSCRIPTION_FOUND, results),
  subscriptionNotFound: () => createAction(CalendarSubscriptionActions.SUBSCRIPTION_NOT_FOUND),
  subscriptionLookupError: () => createAction(CalendarSubscriptionActions.SUBSCRIPTION_LOOKUP_FAILURE),
  subscribe: (id: string) => createAction(CalendarSubscriptionActions.SUBSCRIBE_REQUEST, id),
  subscribeSuccess: (calendarName: string) => createAction(CalendarSubscriptionActions.SUBSCRIBE_SUCCESS, calendarName),
  unsubscribe: (id: string) => createAction(CalendarSubscriptionActions.UNSUBSCRIBE, id),
  unsubscribeSuccess: () => createAction(CalendarSubscriptionActions.UNSUBSCRIBE_SUCCESS),
  unsubscribeFailure : () => createAction(CalendarSubscriptionActions.UNSUBSCRIBE_FAILURE),
  resetSubscriptionState: () => createAction(CalendarSubscriptionActions.RESET_SUBSCRIPTION_STATE),
};
