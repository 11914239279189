import { createAction } from '@lifesize/typescript-react-redux-utils';

export enum ResetPasswordActions {
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_MY_PASSWORD = 'RESET_MY_PASSWORD',
  RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL',
  CLEAR_RESET_PASSWORD_STATE = 'CLEAR_RESET_PASSWORD_STATE'
}

export const actions = {
  resetPassword: (uuid: string) => createAction(ResetPasswordActions.RESET_PASSWORD, uuid),
  resetMyPassword: () => createAction(ResetPasswordActions.RESET_MY_PASSWORD),
  resetPasswordStart: () => createAction(ResetPasswordActions.RESET_PASSWORD_BEGIN),
  resetPasswordFail: () => createAction(ResetPasswordActions.RESET_PASSWORD_FAIL),
  resetPasswordSuccess: () => createAction(ResetPasswordActions.RESET_PASSWORD_SUCCESS),
  clearResetPasswordState: () => createAction(ResetPasswordActions.CLEAR_RESET_PASSWORD_STATE)
};

export default actions;
