import { DashboardActionType } from 'actions/dashboardActions';
import { END_DATE, START_DATE } from 'components/DateRangePicker/date_range_constants';
import { Reducer } from 'redux';
import { DashboardState, initialDashboardState } from 'state/Dashboard';

const dashboardReducer: Reducer<DashboardState> =
  (state = initialDashboardState, action) => {
  const { endDate, startDate } = action?.payload || {};
  switch (action.type) {
      case DashboardActionType.DASHBOARD_SET_DATE_RANGE:
        return {
          ...state,
          [START_DATE]: startDate,
          [END_DATE]: endDate,
        };
      case DashboardActionType.DASHBOARD_SET_DATE_RANGE_MOMENT:
        return {
          ...state,
          startDateMoment: startDate,
          endDateMoment: endDate,
        };
      default:
        return state;
    }
  };

export default dashboardReducer;
