import React from 'react';
import intl from 'react-intl-universal';
import * as styles from './BlurModal.scss';
import * as diamond from 'images/diamond.svg';
import { useSelector } from 'react-redux';
import { authSelectors } from '@lifesize/nucleus';
import { buildEcommerceUrl } from 'utils/ecommerceUtils';

interface Props {
  modalBody: string;
  plan?: string;
}

export const BlurModal = ({ modalBody, plan }: Props) => {
  const jwt = useSelector(authSelectors.getPrimaryJwt);
  const extraParams = plan ? { plan } : undefined;

  return (
    <div className={styles.backdrop}>
      <div className={styles.container}>
        <img src={diamond} className={styles.logo} />
        <div className={styles.title}>{intl.get('unlockMoreFeatures')}</div>
        <div className={styles.body}>{modalBody}</div>
        <button
          className={styles.blurButton}
          onClick={() => {
            window.location.href = buildEcommerceUrl(jwt, extraParams);
          }}
          {...{ ['data-test']: 'navLink.freemiumUpgrade' }}
        >
          {intl.get('upgradeNow')}
        </button>
      </div>
    </div>
  );
};
