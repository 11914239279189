import { Reducer } from 'redux';
import { Actions } from 'constants/deviceCustomizationConstants';
import { DeviceCustomizationState, initialDeviceCustomizationState } from 'state/DeviceCustomizationState';

const deviceCustomizationReducer: Reducer<DeviceCustomizationState> =
  (state: DeviceCustomizationState = initialDeviceCustomizationState, action) => {
    switch (action.type) {
      case Actions.SET_DEVICE_CUSTOMIZATION_URL:
        return {
          ...state,
          url: action?.payload?.url
        };
      default:
        return state;
    }
  };

export default deviceCustomizationReducer;
