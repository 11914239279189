import phoneHdActions from 'actions/deviceCustomizationActions';
import { actions as ModalActions } from 'actions/modalActions';
import RoomSystemsToolBar from 'components/RoomSystems/RoomSystemsToolBar';
import { ModalTypes } from 'constants/modalConstants';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { selectSelection } from 'selectors/selectionSelectors';
import { Global } from 'state/Global';
import { canCustomize, canDelete, canUnpair } from 'utils/roomSystemUtils';

export interface OwnPropsInterface {
  selectionArray: Array<string>;
}

const getRecordsFromOwnProps = (ownProps: {}) => {
  const directoryList = _get(ownProps, 'data.directoryListRoomSystem');
  return _get(directoryList, 'records', []); // as
};

const mapStateToProps = (state: Global, ownProps: {}) => {
  const selectionArray = selectSelection(state);
  const visibleDirectoryList = getRecordsFromOwnProps(ownProps);
  return {
    hasSelected: !!selectionArray.length,
    canUnpair: canUnpair(selectionArray, visibleDirectoryList),
    canDelete: canDelete(selectionArray, visibleDirectoryList),
    canCustomize: canCustomize(selectionArray, visibleDirectoryList),
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: OwnPropsInterface) => ({
  confirmRemoveFromCloud: () => {
    dispatch(ModalActions.openModal({ modalType: ModalTypes.REMOVE_FROM_CLOUD }));
  },
  confirmDeleteFromCloud: () => {
    dispatch(ModalActions.openModal({ modalType: ModalTypes.DELETE_FROM_CLOUD }));
  },
  openHdPhonePreferences: () => {
    dispatch(phoneHdActions.openDeviceCustomizationModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomSystemsToolBar);
