import * as React from 'react';
import * as styles from './ToggleButton.scss';
import classnames from 'classnames';
import _omit from 'lodash/omit';
import { SyntheticEvent } from 'react';

export default ({
                  field: {name, value, onChange, onBlur},
                  id,
                  label,
                  classNameLabel,
                  hidenow,
                  leftLabel,
                  topLabel,
                  disabled,
                  ...props
                }: {
  id: string,
  label?: string | JSX.Element | JSX.Element[],
  classNameLabel?: string,
  hidenow: boolean,
  leftLabel: boolean,
  topLabel: boolean,
  field: { name: string, value: boolean | number, onChange: (newValue: SyntheticEvent) => void, onBlur: (newValue: SyntheticEvent) => void },
  disabled?: boolean
}) => {
  return (
    <label className={classnames(styles.container, leftLabel && styles.labelOnLeft, topLabel && styles.labelOnTop, disabled && styles.disabled)}>
      <div className={classnames(styles.toggle, disabled && styles.disabled)}>
        <input
          checked={!!value}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          type="checkbox"
          disabled={disabled}
          {..._omit(props, ['form'])}
        />
        <span id={`${id}-toggle`} className={hidenow ? styles.hideNow : styles.slider}/>
      </div>
      {label &&
      <div className={classnames(styles.label, classNameLabel)}>
        {label}
      </div>
      }
    </label>
  );
};
