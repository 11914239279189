import React from 'react';
import intl from 'react-intl-universal';
import classes from './DeviceSubscriptionPane.scss';
import classNames from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectLocale } from 'selectors/languageSelectors';
import { DeviceSubscription } from 'typings/types';
import { DeviceSubscriptionState } from 'constants/deviceSubscriptionConstants';
import { hasKnownDeviceSubscription } from 'utils/roomSystemUtils';
import DeviceSubscriptionStatusPill from 'components/DeviceSubscriptionStatusPill/DeviceSubscriptionStatusPill';

interface Props {
  deviceSubscription?: DeviceSubscription;
}

const DeviceSubscriptionPane = (props: Props) => {
  const { deviceSubscription } = props;
  let deviceIsActive = false;
  let deviceSubscriptionExists = false;
  let licenseExpirationTime: number | undefined | null = 0;
  let renewalDate;

  if (deviceSubscription) {
    const { licenseStatus, licenseExpiration } = deviceSubscription;
    licenseExpirationTime = licenseExpiration;
    deviceIsActive = licenseStatus === DeviceSubscriptionState.CURRENT;
    deviceSubscriptionExists = hasKnownDeviceSubscription(licenseStatus);
    renewalDate = licenseExpiration ? moment(licenseExpiration).format(intl.get('dateFormat')) : undefined;
  }

  const locale = useSelector(selectLocale);

  return (
    <div className={classNames(classes.container)}>
      <div>
        <h2 className={classes.settingsTitle}>{intl.get('deviceSubscription')}</h2>
        <span className={classes.instructions}>{intl.get('deviceSubscriptionInstructions')}</span>
      </div>
      <div className={classes.deviceSubscriptionDetails}>
        <div className={classes.summaryColumn}>
          <dl>
            <dt>{intl.get('subscriptionStatus')}</dt>
            <dd>
              <DeviceSubscriptionStatusPill
                deviceIsActive={deviceIsActive}
                licenseExpiration={licenseExpirationTime}
                deviceSubscriptionExists={deviceSubscriptionExists}
                showExpirationInDays={true}
              />
            </dd>
          </dl>
          <dl>
            <dt>{intl.get('typeTitle')}</dt>
            <dd>{intl.get('deviceSoftwareSubscription')}</dd>
          </dl>
        </div>
        <div className={classes.summaryColumn}>
          {renewalDate && <dl>
            <dt>{intl.get('renewalDate')}</dt>
            <dd>{renewalDate}</dd>
          </dl>}
          {deviceSubscriptionExists && <dl>
            <dt>
              <a
                target={'_blank'}
                href={`https://www.lifesize.com/${locale}/services/device-software-subscription`}
              >
                {intl.get('viewSubscriptionDetails')}
              </a>
            </dt>
          </dl>}
        </div>
      </div>
      {!deviceIsActive && deviceSubscriptionExists && <div className={classes.expiredText}>{intl.get('expiredSubscriptionText')}</div>}
      {!deviceSubscriptionExists && <div className={classes.expiredText}>{intl.get('noDeviceSubscriptionFound')}</div>}
    </div>
  );
};

export default DeviceSubscriptionPane;
