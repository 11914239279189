import * as React from 'react';
import { Tooltip as LightWeightTooltip } from 'react-lightweight-tooltip';

export enum ArrowPosition {
  left = 'left',
  right = 'right'
}

const styles = (
  {
    arrowPosition = 'center',
    isBottomTip = false,
    isOnRight = false,
    isRadioButtonTip = false,
    stylesWrapper = null
  }: {
    arrowPosition?: string,
    isBottomTip?: boolean,
    isOnRight?: boolean,
    isRadioButtonTip?: boolean,
    stylesWrapper?: object | null
  }) => {
  const styleObject = {
    wrapper: {
      cursor: 'pointer',
    },
    gap: {},
    // content: contains the message/text
    content: {
      backgroundColor: 'transparent',
      color: 'rgba(255, 255, 255, 0.8)',
      fontSize: '13px',
      lineHeight: 'normal',
      padding: '0px',
      pointerEvents: 'none'
    },
    // tooltip: contains the 'content' (above); absolutely positioned within the wrapper
    // note: bottom: '100%', left: '50%', position: 'absolute', and zIndex: '99' are added by the component
    tooltip: {
      backgroundColor: '#4b4f54',
      borderRadius: '4px',
      boxShadow: '0 5px 10px 0 #B3B3B3',
      height: 'auto',
      marginBottom: '10px',
      maxWidth: '300px',
      minWidth: '150px',
      padding: '4px 8px',
      textAlign: 'center',
      width: 'max-content', // IE11 does not support this, so tooltips in IE11 will fall back to minWidth
      pointerEvents: 'none'
    },
    arrow: {
      borderLeft: 'solid transparent 8px',
      borderRight: 'solid transparent 8px',
      borderTop: 'solid #4b4f54 8px',
      bottom: '-10px',
      height: '10px',
      left: 'calc(50% - 8px)',
      pointerEvents: 'none'
    },
  };
  if (isOnRight) { // align the tip with the wrapped element's right edge
    styleObject.tooltip.left = 'auto';
    styleObject.tooltip.right = '0px';
    styleObject.tooltip.transform = 'none';
    styleObject.arrow.left = '75%';
  }
  if (isBottomTip) { // position the tip below the wrapped element
    styleObject.arrow.borderBottom = 'solid #4b4f54 8px';
    styleObject.arrow.borderTop = 'none';
    styleObject.arrow.bottom = 'auto';
    styleObject.arrow.top = '-10px';
    styleObject.tooltip.bottom = 'auto';
    styleObject.tooltip.marginBottom = '0px';
    styleObject.tooltip.top = '100%';
    styleObject.tooltip.marginTop = '4px';
  }
  if (arrowPosition === ArrowPosition.left) {
    styleObject.arrow.left = '14px';
  }
  if (arrowPosition === ArrowPosition.right) {
    styleObject.arrow.left = 'auto';
    styleObject.arrow.right = '14px';
  }
  if (isRadioButtonTip) { // this is just a one-off to shift the entire tip over so the arrow aligns to match the design
    styleObject.tooltip.left = 'calc(50% - 8px)';
  }
  if (stylesWrapper) {
    styleObject.wrapper = {...styleObject.wrapper, ...stylesWrapper};
  }
  return styleObject;
};

interface Props {
  arrowPosition?: string;
  children?: JSX.Element;
  isBottomTip?: boolean;
  isOnRight?: boolean;
  isRadioButtonTip?: boolean;
  stylesWrapper?: object;
  text: string | JSX.Element | JSX.Element[];
}

export class Tooltip extends React.PureComponent<Props> {
  render() {
    const { arrowPosition, isBottomTip, isOnRight, isRadioButtonTip, stylesWrapper } = this.props;
    return (
      <LightWeightTooltip
        content={this.props.text}
        styles={styles({ arrowPosition, isBottomTip, isOnRight, isRadioButtonTip, stylesWrapper })}
      >
        {this.props.children}
      </LightWeightTooltip>
    );
  }
}

export default Tooltip;
