import { recordingActions as nucleusRecordingActions } from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import { AnyAction, Middleware, MiddlewareAPI, Store } from 'redux';
import { SEARCH_CLOUD_USERS } from 'actions/searchActions';
import { ThunkDispatch } from 'redux-thunk';

/**
 * Does elastic search lookup of VC users that can be associated with a recording
 */
const searchCloudUsersMiddleware =
  (store: MiddlewareAPI) => {
    return (next: ThunkDispatch<Store, Middleware, AnyAction>) => {
      return (action: PayloadAction<string, string>) => {
        if (action.type !== SEARCH_CLOUD_USERS) {
          return next(action);
        }
        // this request (search string) will be appended to the api url and should be encoded
        return next(
          recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.searchCloudUsers(encodeURIComponent(action.payload || '')))
        );
      };
    };
  };

export default (searchCloudUsersMiddleware as Middleware);
