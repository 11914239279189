import Icon from 'components/Common/Icon';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import React from 'react';
import intl from 'react-intl-universal';
import { Loader } from 'semantic-ui-react';
import * as styles from './ResetPassword.scss';

interface Props {
  isPasswordReset: boolean;
  isResettingPassword: boolean;
  resetPasswordFailure: boolean;
  onClickHandler: () => void;
}

const RESET_PASSWORD_BUTTON_ID = 'resetPasswordButton';

const inlineLoader = _memoize((isBusy: boolean) => {
  if (isBusy) {
    return (<Loader size={'mini'} active={true} inline={true} />);
  }
  return null;
});

const ResetPassword: React.FunctionComponent<Props>
  = ({ isResettingPassword, isPasswordReset, resetPasswordFailure, onClickHandler }) => {

    return (
      <div className={styles.resetPasswordContainer}>
        {isPasswordReset ? (
          <div className={styles.resetPasswordLabel}>
            {intl.get('userResetPasswordSuccess')}
          </div>
        ) : (
            <button
              id={RESET_PASSWORD_BUTTON_ID}
              className={styles.resetPasswordButton}
              disabled={isResettingPassword}
              name="btnResetPwd"
              onClick={onClickHandler}
              role="button"
              type="button"
            >
              <div className={styles.iconContainer}>
                <Icon classes={['icon-lock', styles.iconStyle]} />
              </div>
              <div className={styles.resetPasswordLabel}>
                {intl.get('userResetPassword')}
              </div>
            </button>
          )}
        {resetPasswordFailure && (
          <div className={styles.resetPasswordError}>
            {intl.get('userResetPasswordFailure')}
          </div>
        )}

        {inlineLoader(isResettingPassword)}
      </div>
    );
  };

export default ResetPassword;
