import React from 'react';
import intl from 'react-intl-universal';
import * as styles from './MissingConferenceError.scss';

interface Props {
  backRoute: string;
  breadcrumbs?: string | JSX.Element | JSX.Element[];
}

const MissingConferenceError = (props: Props) => {
  const { breadcrumbs } = props;
  return (
    <div className={styles.container}>
      { breadcrumbs &&
        <div className={styles.breadcrumbs}>{breadcrumbs}</div>
      }
      <div className={styles.error}>
        <span className={styles.errorTitle}>{intl.get('conferenceNotFound')}</span>
      </div>
    </div>
  );
};

export default MissingConferenceError;
