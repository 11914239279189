import classnames from 'classnames';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import LiveStreamsTab from 'containers/Meetings/LiveStreamsTab/LiveStreamsTabContainer';
import MeetingsTab from 'containers/Meetings/MeetingsTabContainer';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _invert from 'lodash/invert';
import _isNumber from 'lodash/isNumber';
import _memoize from 'lodash/memoize';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { RouteComponentProps } from 'react-router';
import { Tab, TabProps } from 'semantic-ui-react';
import Icon from 'components/Common/Icon';
import * as styles from './Meetings.scss';
import { MEETING_PATH } from 'constants/meetingConstants';
import { MEETING_LIVE_STREAMS } from 'constants/liveStreamHistory';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { NoPermissionsBanner } from 'components/NoPermissionsBanner/NoPermissionsBanner';

const keyToActiveIndex = {
  [MEETING_PATH]: 0,
  [MEETING_LIVE_STREAMS]: 1
};

const activeIndexToKey = _invert(keyToActiveIndex);

interface Props {
  hasStreamingFeature: boolean;
}

const getTitle = _memoize((selectedTab: number) => {
  if (selectedTab === 0) {
    return (
      <>
        <Icon classes={['icon-meeting']} />
        <span>{intl.get('meetingsTab')}</span>
      </>
    );
  }
  return (
    <>
      <Icon classes={['icon-room-system']} />
      <span>{intl.get('liveStreamTab')}</span>
    </>
  );
});

const panes = (hasStreamingFeature: boolean) => _compact([
  {
    menuItem: {
      key: MEETING_PATH,
      content: intl.get('meetingsTab')
    },
    render: () => {
      return (
        <Tab.Pane attached={false}>
          <MeetingsTab/>
        </Tab.Pane>
      );
    }
  },
  hasStreamingFeature && {
    menuItem: {
      key: MEETING_LIVE_STREAMS,
      content: intl.get('liveStreamTab')
    },
    render: () => {
      return (
        <Tab.Pane attached={false}>
          <LiveStreamsTab/>
        </Tab.Pane>
      );
    }
  }
]);

const getCurrentIndex = (tab: string = MEETING_PATH) => keyToActiveIndex[tab];

const Meetings = (props: RouteComponentProps<void> & Props) => {
  const { match, history, hasStreamingFeature } = props;
  const [selectedTab, setSelectedTab] = useState(_get(match, 'params.tab') === 'meet' ? 0 : 1);
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  const handleTabChange = (event: React.MouseEvent<HTMLDivElement>, tabData: TabProps) => {
    if (_isNumber(tabData.activeIndex) && selectedTab !== tabData.activeIndex) {
      setSelectedTab(tabData.activeIndex);
    }
    const activeIndex = tabData.activeIndex || 0;
    const url = activeIndexToKey[activeIndex];
    history.push(url);
  };

  return (
    <div className={styles.container}>
      {!canEditMeetings && <NoPermissionsBanner text={intl.get('cannotModifyMeetings')} />}
      <ListViewLayoutHeader title={getTitle(selectedTab)} />
      <div className={classnames(styles.tabsContainer, 'tabset')}>
        <Tab
          activeIndex={getCurrentIndex(history.location.pathname)}
          menu={{secondary: true, pointing: true}}
          panes={panes(hasStreamingFeature)}
          onTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default Meetings;
