import { actions as ModalActions } from 'actions/modalActions';
import UsersHeaderToolbar from 'components/Users/Users/UsersHeaderToolbar';
import { ModalTypes } from 'constants/modalConstants';
import { connect } from 'react-redux';
import { selectSelection } from 'selectors/selectionSelectors';
import { Global } from 'state/Global';

const mapStateToProps = (state: Global) => {
  const selectionArray = selectSelection(state);

  return {
    hasSelected: !!selectionArray.length,
    selectionArray
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onDeleteUsersClick: (users: string[]) => {
    if (users.length) {
      dispatch(
        ModalActions.openModal({
          modalType: ModalTypes.USERS_CONFIRM_DELETE
        }),
      );
    }
  },
  onInviteUserClick: () => {
    dispatch(
      ModalActions.openModal({
        modalType: ModalTypes.USER_INVITE
      }),
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersHeaderToolbar);
