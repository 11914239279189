import React, { useEffect, useState } from 'react';
import IconBackground from './IconBackground';
import IconAlerts from './IconAlerts';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import AlexaSettings from 'containers/AlexaSettings/AlexaSettingsContainer';
import SFUSettings from 'components/SFUSettings/SFUSettings';
import { useSelector } from 'react-redux';
import { sfuAccount } from 'selectors/accountSelectors';

// props from Redux Store (mapStateToProps)
export interface StateProps {
  alertNotificationsEmail: string;
  alertNotificationsEnabled: boolean;
  isCustomWallPaperEnabled: boolean;
  isUpdateEnabled: boolean;
  imagePreviewURL: string;
}

// props from Actions (mapDispatchToProps)
export interface DispatchProps {
  enableCustomBackground: Function;
  fetchData: Function;
  updateEventAlerts: Function;
  saveWallpaper: Function;
}

type Props = StateProps & DispatchProps;

const IconSettings = (props: Props) => {
  const {
    alertNotificationsEmail,
    alertNotificationsEnabled,
    isCustomWallPaperEnabled,
    enableCustomBackground,
    updateEventAlerts,
    saveWallpaper,
    imagePreviewURL,
    isUpdateEnabled
  } = props;

  const sfuEnabled = useSelector(sfuAccount);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    async function fetchCall() {
      await props.fetchData();
      if (isMounted) {
        setLoading(false);
      }
    }
    fetchCall();
    return () => { isMounted = false; };
  },        []);

  if (loading) {
    return <LoadingIndicator/>;
  }

  const sections = [
    (
      <IconAlerts
        key="iconAlerts"
        alertNotificationsEmail={alertNotificationsEmail}
        alertNotificationsEnabled={alertNotificationsEnabled}
        updateSection={updateEventAlerts}
      />
    ),
    (
      <IconBackground
        key="iconBackground"
        isCustomWallPaperEnabled={isCustomWallPaperEnabled}
        enableCustomBackground={enableCustomBackground}
        imagePreviewURL={imagePreviewURL}
        saveWallpaper={saveWallpaper}
        isUpdateEnabled={isUpdateEnabled}
      />
    ),
  ];

  if (sfuEnabled) {
    sections.push(<SFUSettings />);
  }

  sections.push(<AlexaSettings/>);

  return sections;
};

export default IconSettings;
