import React from 'react';
import { Link } from 'react-router-dom';
import { RowInfo } from 'react-table';
import intl from 'react-intl-universal';
import _get from 'lodash/get';

const liveStreamsTabColumnConfig = () =>
  [{
    Header: intl.get('meetingName'),
    accessor: 'displayName',
    Cell: (row: RowInfo) => {
      const value = _get(row, 'value');
      const meetingId = _get(row, 'original.meeting_id', '');
      return <Link to={`/meetings/livestreamByName/${encodeURIComponent(value)}/${meetingId}`}>{value}</Link>;
    },
    minWidth: 150
  }, {
    Header: intl.get('link'),
    accessor: 'link',
    minWidth: 500
  }, {
    Header: intl.get('tableHeaderPermissions'),
    accessor: 'auth_type',
    Cell: (row: RowInfo) => {
      switch (_get(row.original, 'auth_type')) {
        case 'group':
          return intl.get('group');
        case 'pin':
          return intl.get('private');
        case 'public':
        default:
          return intl.get('public');
      }
    }
  }, {
    Header: intl.get('live'),
    accessor: 'is_live',
    Cell: (row: RowInfo) => {
      return _get(row.original, 'is_live', 0) ? intl.get('yes') : intl.get('no');
    }
  }];

export {
  liveStreamsTabColumnConfig
};
