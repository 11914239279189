import { createAction } from '@lifesize/typescript-react-redux-utils';
import { SortActionType } from '../constants/sortConstants';

// Expexted payload = { sortBy: 'column', order: 'ASC' }
const actions = {
  setSort: (payload: object) =>
    createAction(SortActionType.SET_SORT, payload),
};

export default actions;
