import _get from 'lodash/get';
import _sortBy from 'lodash/sortBy';
import intl from 'react-intl-universal';
import { getMeetingTypeLabel } from 'utils/meetingsUtils';
import {
  DWHCallDetailsResponseType,
  DWHCallDetailsResponseType2,
  DWHCallHistoryResponseType,
  DWHCallHistoryPaginatedType,
  DWHLiveStreamQuestionType,
  DWHLiveStreamResultType,
  DWHLiveStreamViewerType,
  DWHUserCallsResultType,
  DWHPastConferenceType,
  DWHLiveStreamResultTypeV2,
  ParticipantEntry,
  DWHLiveStreamViewerTypeV2
} from 'interfaces/Dashboard';
import {
  GroupContact,
  ListMeetingsQuery,
  RoomSystem,
  User
} from 'typings/types';
import { exportToCsv } from './csv-utils';
import {
  formatIsoUtcDateTimeForDisplayDate,
  formatMinutesForDisplay,
  formatSecondsForDisplay,
  formatSecondsForExport,
  formatStringDateTimeForDisplay,
  formatUnixDateTimeForDisplay,
  formatUnixTimeStampForDisplay,
  secondsToMilliseconds
} from './date-time-utils';
import {
  getControllerTypeCondensedText,
  getFirmwareVersion,
  getIPAddress,
  getOnlineStatusDisplayText,
  getSystemNameDisplayText,
  getUsageDisplayText,
  getVideoTypeDisplayText,
  isPlatformIcon,
  isValidFirmwareVersion,
  roomSystemSupportsDSS
} from './roomSystemUtils';
import MeetingInlineFragment = ListMeetingsQuery.MeetingInlineFragment;
import { nucleusConstants } from '@lifesize/nucleus';
import { DeviceSubscriptionState } from 'constants/deviceSubscriptionConstants';
import { PairingStatus } from 'constants/roomSystemConstants';

const getStringForCsv = (data: User, key: string): string => {
  return (_get(data, key) || '').trim();
};

const cleanUpString = (value: string | null | undefined) => {
  if (value && value !== 'undefined' && value !== 'null') {
    return value.trim();
  }
  return '';
};

const getLicenseStatus = (data: RoomSystem) => {
  if (!roomSystemSupportsDSS(data)) {
    return '';
  }

  if (['Current', 'Expired'].includes(data.deviceSubscription.licenseStatus)) {
    return data.deviceSubscription.licenseStatus;
  }

  return DeviceSubscriptionState.EXPIRED;
};

const parseRoomSystemDataForExport = (data: RoomSystem, dssFeatureFlag?: boolean): object => {
  const isIcon = isPlatformIcon(data.platform);
  const isPaired = data.pairingStatus !== PairingStatus.DELETED;
  let object = {};
  object[intl.get('name')] = getSystemNameDisplayText(data.displayName);
  object[intl.get('status')] = getOnlineStatusDisplayText(
    _get(data, 'availability.status')
  );
  if (dssFeatureFlag) {
    object[intl.get('deviceSubscription')] = getLicenseStatus(data);
  }
  object[intl.get('extension')] = cleanUpString(data.dialstring);
  object[intl.get('usage')] = getUsageDisplayText(data);
  object[intl.get('videoType')] = isPaired ? getVideoTypeDisplayText(data.platform) : '';
  object[intl.get('controllerType')] = isPaired ? getControllerTypeCondensedText(data) : '';
  /* tslint:enable */
  object[intl.get('serialNum')] = isPaired ? cleanUpString(data.serialNumber) : '';
  object[intl.get('connectionDate')] = isPaired && data.pairingTimeMillis
    ? formatUnixDateTimeForDisplay(parseInt(data.pairingTimeMillis, 10))
    : '';
  object[intl.get('ipAddress')] = isPaired && isIcon ? getIPAddress(data) : '';
  object[intl.get('version')] =
    isPaired && isIcon && isValidFirmwareVersion(data.firmware)
      ? getFirmwareVersion(data)
      : '';
  // object[intl.get('dashVersion')] = isDash ? getFirmwareVersion(data) : '';
  // object[intl.get('tabletVersion')] = isDash ? getFirmwareVersion(data) : '';
  // object[intl.get('tabletPin')] = isDash ?  data.pin || '' : ''; // ***
  return object;
};

const handleExportRoomSystemsToCsv = (exportData: Array<RoomSystem>, dssFeatureFlag?: boolean) => {
  if (exportData && exportData.length) {
    exportToCsv(
      intl.get('roomSystems'),
      exportData.map((data) => parseRoomSystemDataForExport(data, dssFeatureFlag))
    );
  }
};

const parseMeetingDataForExport = (data: MeetingInlineFragment): object => {
  let object = {};
  object[intl.get('meetingName')] = cleanUpString(data.displayName);
  object[intl.get('meetingType')] =
    getMeetingTypeLabel(_get(data, 'type')) || intl.get('not-available');
  object[intl.get('passCode')] = cleanUpString(data.pin);
  object[intl.get('extension')] = cleanUpString(data.extension);
  object[intl.get('owner')] = cleanUpString(_get(data.owner, 'email', ''));
  object[intl.get('dateCreated')] =
    formatStringDateTimeForDisplay(_get(data, 'dateCreated') || '');
  object[intl.get('lastUsed')] =
    formatStringDateTimeForDisplay(_get(data, 'lastUsedAt') || '');
  return object;
};

const handleExportMeetingsToCsv = (
  exportData: Array<MeetingInlineFragment>
) => {
  if (exportData && exportData.length) {
    exportToCsv(
      intl.get('meetingsTab'),
      _sortBy(exportData, [
        (a: MeetingInlineFragment) => a.displayName.toUpperCase()
      ]).map(parseMeetingDataForExport)
    );
  }
};

const parseGroupContactsDataForExport = (data: GroupContact): object => {
  let object = {};
  object[intl.get('meetingName')] = cleanUpString(data.displayName);
  object[intl.get('callingDetails')] = cleanUpString(data.dialstring);
  return object;
};

const handleExportGroupContactsToCsv = (exportData: Array<GroupContact>) => {
  if (exportData && exportData.length) {
    exportToCsv(
      intl.get('contactsTab'),
      _sortBy(exportData, [
        (a: GroupContact) => a.displayName.toUpperCase()
      ]).map(parseGroupContactsDataForExport)
    );
  }
};

// TODO: update when new DWH is available
const parseLiveSteamQuestionsDataForExport = (
  data: DWHLiveStreamQuestionType
): object => {
  return {
    [intl.get('tableHeaderAuthor')]: cleanUpString(data.author),
    [intl.get('tableHeaderMessage')]: cleanUpString(data.message),
    [intl.get('tableHeaderTime')]: cleanUpString(data.timeFormatted)
  };
};

// TODO: update when new DWH is available
const handleExportLiveSteamQuestionsToCsv = (
  exportData: DWHLiveStreamQuestionType[]
) => {
  if (exportData && exportData.length) {
    exportToCsv(
      intl.get('csvExportLiveStreamQuestions'),
      _sortBy(exportData, [(a: DWHLiveStreamQuestionType) => a.time]).map(
        parseLiveSteamQuestionsDataForExport
      )
    );
  }
};

// TODO: update when new DWH is available
const parseLiveSteamViewersDataForExport = (
  data: DWHLiveStreamViewerTypeV2
): object => {
  return {
    [intl.get('tableHeaderName')]: cleanUpString(data.viewerName),
    [intl.get('tableHeaderRole')]: cleanUpString(data.role),
    [intl.get('joinTime')]: cleanUpString(data.joinTime),
    [intl.get('leaveTime')]: cleanUpString(data.leaveTime),
  };
};

// TODO: update when new DWH is available
const handleExportLiveSteamViewersToCsv = (
  exportData: DWHLiveStreamViewerType[]
) => {
  if (exportData && exportData.length) {
    exportToCsv(
      intl.get('csvExportLiveStreamViewers'),
      _sortBy(exportData, [
        (a: DWHLiveStreamViewerType) => a.viewerName.toUpperCase()
      ]).map(parseLiveSteamViewersDataForExport)
    );
  }
};

const getLastLoginDate = (pairedDevice: object) => {
  if (pairedDevice) {
    return formatIsoUtcDateTimeForDisplayDate(
      cleanUpString(_get(pairedDevice, 'lastLoginDate'))
    );
  }
  return '';
};

const hasConsoleAccess = (userType: string) => {
  if (
    userType &&
    (userType === nucleusConstants.USER_ROLE_ADMIN ||
      userType === nucleusConstants.USER_ROLE_SUPER_USER)
  ) {
    return intl.get('yes');
  }
  return intl.get('no');
};

const parseUserDataForExport = (data: User): object => {
  return {
    [intl.get('tableHeaderName')]: getStringForCsv(data, 'displayName'),
    [intl.get('tableHeaderConsoleAccess')]: hasConsoleAccess(
      getStringForCsv(data, 'userType')
    ),
    [intl.get('tableHeaderEmail')]: getStringForCsv(data, 'email'),
    [intl.get('tableHeaderExtension')]: getStringForCsv(data, 'dialstring'),
    [intl.get('tableHeaderCreated')]: formatIsoUtcDateTimeForDisplayDate(
      getStringForCsv(data, 'createdAt')
    ),
    [intl.get('tableHeaderLastCall')]: formatIsoUtcDateTimeForDisplayDate(
      getStringForCsv(data, 'lastCallDate')
    ),
    [intl.get('tableHeaderLastLogin')]: getLastLoginDate(
      data.pairedDevice || {}
    )
  };
};

const handleExportUsersToCsv = (exportData: Array<User>) => {
  if (exportData && exportData.length) {
    exportToCsv(
      intl.get('csvExportUsers'),
      _sortBy(exportData, [(a: User) => a.displayName.toUpperCase()]).map(
        parseUserDataForExport
      )
    );
  }
};

const parseRoomSystemUsageDetailsForExport = (
  data: DWHCallDetailsResponseType
): object => {
  return {
    [intl.get('tableHeaderName')]: cleanUpString(data.displayName),
    [intl.get('tableHeaderStarted')]: formatIsoUtcDateTimeForDisplayDate(
      data.startedOn
    ),
    [intl.get('tableHeaderDevice')]: cleanUpString(data.deviceAlias),
    [intl.get('tableHeaderSource')]: cleanUpString(data.sourceIP),
    [intl.get('tableHeaderDestination')]: cleanUpString(data.destinationIP),
    [intl.get('tableHeaderDuration')]: formatSecondsForExport(data.duration)
  };
};

const parseCallHistoryDataForExport = (
  data: DWHCallHistoryResponseType
): object => {
  let object = {};
  object[intl.get('name')] = cleanUpString(data.conferenceName);
  object[intl.get('date')] = formatStringDateTimeForDisplay(data.startDate);
  object[intl.get('duration')] = data.durationMinutes;
  object[intl.get('participants')] = data.participantsCount;
  return object;
};

const parseCallHistoryDataForExport2 = (
  data: DWHCallHistoryPaginatedType
): object => {
  let object = {};
  object[intl.get('name')] = cleanUpString(data.conferenceName);
  object[intl.get('date')] = formatStringDateTimeForDisplay(data.timestamp);
  object[intl.get('duration')] = data.duration;
  object[intl.get('participants')] = data.participantCount;
  return object;
};

const parseParticipantDataForExport = (
  data: Array<ParticipantEntry>,
  meetingName: string
): Array<object> => {
  return _sortBy(data, 'startedAt').map((entry) => {
    return ({
      [intl.get('name')]: entry.displayName,
      [intl.get('location')]: entry.location,
      [intl.get('joinTime')]: formatUnixDateTimeForDisplay(secondsToMilliseconds(entry.startedAt)),
      [intl.get('leaveTime')]: formatUnixDateTimeForDisplay(secondsToMilliseconds(entry.endedAt)),
      [intl.get('duration')]: formatMinutesForDisplay(entry.duration),
      [intl.get('tableHeaderDevice')]: _get(entry, 'endpointIdentity.product.name', ''),
      [intl.get('tableHeaderSource')]: entry.sourceIP,
      [intl.get('meetingName')]: meetingName,
      [intl.get('operatingSystem')]: _get(entry, 'endpointIdentity.platform.operatingSystem', ''),
      [intl.get('browser')]: _get(entry, 'endpointIdentity.platform.name', '')
    });
  });
};

const parsePastMeetingsDataForExport = (
  data: DWHPastConferenceType
): object => {
  let object = {};
  object[intl.get('nameOfMeeting')] = cleanUpString(data.meetingName);
  object[intl.get('meetingType')] = cleanUpString(data.conferenceType);
  object[intl.get('extension')] = cleanUpString(data.extension);
  object[intl.get('startTime')] = formatUnixTimeStampForDisplay(data.startedAt);
  object[intl.get('duration')] = data.duration;
  object[intl.get('totalParticipants')] = data.totalParticipants;
  // DCM: participant list is not available from API
  // object[intl.get('participants')] = data.participants.map(p => p.username).join(' | ');
  return object;
};

const parseLiveStreamDataForExport = (
  data: DWHLiveStreamResultType | DWHLiveStreamResultTypeV2
): object => {
  let object = {};
  object[intl.get('name')] = cleanUpString(data.streamName);
  object[intl.get('date')] = formatUnixDateTimeForDisplay(data.startDate || Date.now());
  object[intl.get('duration')] = formatSecondsForDisplay(data.durationSeconds);
  object[intl.get('viewers')] = data.viewerCount;
  return object;
};

const parseRoomSystemDrillDownDataForExport = (
  data: DWHUserCallsResultType
): object => {
  let object = {};
  object[intl.get('name')] = cleanUpString(data.name);
  object[intl.get('minutes')] = data.totalMinutes;
  object[intl.get('calls')] = data.totalCalls;
  return object;
};

const parseUserDrillDownDataForExport = (
  data: DWHUserCallsResultType
): object => {
  let object = {};
  object[intl.get('name')] = cleanUpString(data.name);
  object[intl.get('minutes')] = data.totalMinutes;
  object[intl.get('calls')] = data.totalCalls;
  return object;
};

const parseUserUsageDrillDownDataForExport = (
  data: DWHCallDetailsResponseType
): object => {
  return {
    [intl.get('tableHeaderName')]: cleanUpString(data.displayName),
    [intl.get('tableHeaderStarted')]: formatIsoUtcDateTimeForDisplayDate(
      data.startedOn
    ),
    [intl.get('tableHeaderDevice')]: cleanUpString(data.deviceAlias),
    [intl.get('tableHeaderSource')]: cleanUpString(data.sourceIP),
    [intl.get('tableHeaderDestination')]: cleanUpString(data.destinationIP),
    [intl.get('tableHeaderDuration')]: formatMinutesForDisplay(data.duration)
  };
};

const parseUserUsageDrillDownDataForExport2 = (
  data: DWHCallDetailsResponseType2
): object => {
  return {
    [intl.get('tableHeaderName')]: cleanUpString(data.participantName),
    [intl.get('tableHeaderStarted')]: formatStringDateTimeForDisplay(
      data.startDate
    ),
    [intl.get('tableHeaderDevice')]: cleanUpString(data.deviceType),
    [intl.get('tableHeaderSource')]: cleanUpString(data.source),
    [intl.get('tableHeaderDestination')]: cleanUpString(data.destination),
    [intl.get('tableHeaderDuration')]: data.durationMinutes
  };
};

const parse220CredentialsForExport = (data: object): object => {
  let object = {};
  object[
    intl.get('sipLdapName').defaultMessage('SIP/LDAP User Name')
  ] = cleanUpString(_get(data, 'extension'));
  object[intl.get('name')] = cleanUpString(_get(data, 'displayName'));
  object[intl.get('password').defaultMessage('Password')] = cleanUpString(
    _get(data, 'password')
  );
  return object;
};

export {
  handleExportUsersToCsv,
  handleExportRoomSystemsToCsv,
  handleExportMeetingsToCsv,
  handleExportGroupContactsToCsv,
  handleExportLiveSteamQuestionsToCsv,
  handleExportLiveSteamViewersToCsv,
  parseRoomSystemUsageDetailsForExport,
  parseCallHistoryDataForExport,
  parseCallHistoryDataForExport2,
  parsePastMeetingsDataForExport,
  parseLiveStreamDataForExport,
  parseRoomSystemDrillDownDataForExport,
  parseUserDrillDownDataForExport,
  parseUserUsageDrillDownDataForExport,
  parseUserUsageDrillDownDataForExport2,
  parse220CredentialsForExport,
  parseParticipantDataForExport
};
