import React from 'react';
import intl from 'react-intl-universal';
import { Link, Redirect } from 'react-router-dom';
import {
  formatUnixTimeOfDayForDisplay,
  secondsToMilliseconds,
  formatMinutesForDisplay,
  formatUnixDateForDisplay
} from 'utils/date-time-utils';
import * as styles from './PastMeetingParticipantPage.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import {
  PAST_MEETING_PARTICIPANTS,
  PAST_MEETING_ROUTE,
  PAST_MEETING_TAB
} from 'constants/pastMeetings';
import { MeetingParticipantSummary } from 'components/MeetingParticipant/MeetingParticipantSummary/MeetingParticipantSummary';
import CallGraphsComponent from 'components/CallGraphs/CallGraphsComponent';
import { MeetingParticipantData, MeetingParticipantDetails, MediaStats } from 'interfaces/MeetingParticipant';
import { GraphqlQueryControls } from 'react-apollo';
import ListViewTabLayout from 'components/ListViewTabLayout/ListViewTabLayout';
import { getShortMeetingName } from 'utils/meetingsUtils';

interface Props {
  extension: string;
  startedAt: number;
  endedAt: number;
  duration: number;
  meetingName: string;
  displayName: string;
  userExists: boolean;
  conferenceId: string;
  sourceIP: string;
  graphs: string;
  data: GraphqlQueryControls<MeetingParticipantData>;
  pastMeetingParticipant: MeetingParticipantDetails;
  mediaStats: Array<MediaStats>;
}

export const PastMeetingParticipantPage = (props: Props) => {
  const {
    data,
    extension,
    startedAt,
    endedAt,
    meetingName,
    conferenceId,
    sourceIP,
    displayName,
    duration,
    pastMeetingParticipant,
    graphs,
    mediaStats
  } = props;

  const {
    loading,
    error
  } = data;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!loading && !pastMeetingParticipant) {
    return <Redirect to={`${PAST_MEETING_ROUTE}/${conferenceId}/${PAST_MEETING_PARTICIPANTS}`} />;
  }

  return (
    <div className={styles.structure}>
      <ListViewTabLayout
        title={displayName}
        breadcrumbs={(
          <React.Fragment>
            <Link key="pastMeetingsBreadcrumb" to={{ pathname: PAST_MEETING_TAB }}>
              {intl.get('pastMeetings')}
            </Link>
            {' > '}
            <Link key="pastMeetingsBreadcrumb" to={{ pathname: `${PAST_MEETING_ROUTE}/${conferenceId}/${PAST_MEETING_PARTICIPANTS}` }}>
              {getShortMeetingName(meetingName)}
            </Link>
            {' > '}
            {displayName}
          </React.Fragment>
        )}
        subtitle={(
          <div>
            <div className={styles.flexCol}>
              <ul className={styles.headerList}>
                <li>{intl.get('extensionWithColon')} {extension}</li>
                <li>{intl.get('sourceIPwithColon')} {sourceIP}</li>
              </ul>
              <ul className={styles.headerList}>
                <li>{intl.get('dateWithColon')} {formatUnixDateForDisplay(secondsToMilliseconds(startedAt))}</li>
                <li>{intl.get('joinTimeWithColon')} {formatUnixTimeOfDayForDisplay(secondsToMilliseconds(startedAt))}</li>
                <li>{intl.get('leaveTimeWithColon')} {!!endedAt && formatUnixTimeOfDayForDisplay(secondsToMilliseconds(endedAt))}</li>
                <li>{intl.get('durationWithColon')} {formatMinutesForDisplay(duration)}</li>
              </ul>
            </div>
          </div>
        )}
        panes={[
          {
            menuItem: (
              {
                key: 'summaryTab',
                content: (
                  intl.get('summary')
                )
              }),
            render: () => (
              <MeetingParticipantSummary
                meetingParticipant={pastMeetingParticipant}
                error={error}
                mediaStats={mediaStats}
              />
            )
          },
          {
            menuItem: (
              {
                key: 'callGraphsTab',
                content: (
                  intl.get('callGraphs')
                )
              }),
            render: () => (
              <CallGraphsComponent
                graphs={graphs}
              />
            )
          }
        ]}
      />
    </div>
  );
};

export default { PastMeetingParticipantPage };
