import React from 'react';
import intl from 'react-intl-universal';
import classes from './CallGraphsComponent.scss';

interface Props {
  graphs: string;
}

const CallGraphsComponent = (props: Props) => {
  return (
    <div className={classes.container}>
      <div className={classes.pane}>
        {props.graphs ? (
          <div className={classes.iframeContainer}>
            <iframe src={props.graphs} scrolling="no" allowFullScreen={true} />
          </div>
        ) : (
          <div>
            {intl.get('callGraphsNotAvailableForParticipant')}
          </div>
        )}
      </div>
    </div>
  );
};

export default CallGraphsComponent;
