import { Global } from 'state/Global';
import { createSelector } from 'reselect';

const deviceManagementState = (state: Global, serialNumber: string) => {
  return state.app?.deviceManagement?.devices[serialNumber];
};

const deviceManagementCoronerState = (state: Global) => {
  return state.app?.deviceManagement?.coroner;
};

const deviceManagementCoronerSelector = (serialNumber: string) =>  createSelector(
  deviceManagementCoronerState,
  (coroner) => coroner[serialNumber]
);

export {
  deviceManagementState,
  deviceManagementCoronerState,
  deviceManagementCoronerSelector
};
