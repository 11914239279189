import React from 'react';
import QRCode from 'qrcode.react';
import _get from 'lodash/get';
import * as styles from './ConnectTabletComponent.scss';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { RoomSystem } from 'typings/types';

interface Props {
  roomSystem: RoomSystem;
  remoteLinkingCode: string;
  generateLinkingCode: (platform: string, serialNumber: string) => void;
  closePendingSave: () => void;
}

const TabletTabPairingCode = (props: Props) => {
  const { remoteLinkingCode } = props;
  React.useEffect(
    () => {
      const platform = _get(props.roomSystem, 'platform', null);
      const serialNumber = _get(props.roomSystem, 'serialNumber', null);
      if (platform && serialNumber) {
        props.generateLinkingCode(
          platform,
          serialNumber
        );
      }
    },
    []
  );
  React.useEffect(
    () => {
      if (props.remoteLinkingCode) {
        // will close modal if middleware witnesses a tablet linked event
        props.closePendingSave();
      }
    },
    [props.remoteLinkingCode]
  );

  if (remoteLinkingCode) {
    return (
      <div className={styles.centerContainer}>
        <QRCode size={90} value={remoteLinkingCode} className={styles.qrCode} />
        <div className={styles.linkingCodeContainer}>{remoteLinkingCode}</div>
      </div>
    );
  }
  return (
    <div className={styles.centerContainer}>
      <LoadingIndicator />
    </div>
  );
};

export default TabletTabPairingCode;
