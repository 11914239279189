import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import _get from 'lodash/get';
import { MeetingShareProperties } from 'interfaces/VideoShareProperties';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import ShareRecordingForm from './MeetingShareRecordingForm';
import { useMount } from 'hooks/useMount';

interface Props {
  closeModal: () => void;
  refreshMeetingUrls: (uuid: string) => void;
  getMeetingShareProperties: (uuid: string) => void;
  setMeetingShareProperties: (uuid: string, meetingShareProps: MeetingShareProperties) => void;
  meetingRecordingShareProperties: MeetingShareProperties;
  meetingId: string;
  meetingName: string;
}

const ShareRecordingModal = (props: Props) => {
  const {
    getMeetingShareProperties,
    setMeetingShareProperties,
    meetingId,
    refreshMeetingUrls,
    closeModal,
    meetingRecordingShareProperties,
  } = props;

  const [loading, setLoading] = useState(true);
  const isMounted = useMount();

  useEffect(
    () => {
      const awaitMeetingShareProperties = async () => {
        await getMeetingShareProperties(meetingId);
        if (isMounted.current) {
          setLoading(false);
        }
      };
      awaitMeetingShareProperties();
    },
    []
  );

  const handleSubmit = (meetingShareProps: MeetingShareProperties) => {
    setMeetingShareProperties(meetingId, meetingShareProps);
  };

  const handleRefreshUrl = async () => {
    setLoading(true);
    await refreshMeetingUrls(meetingId);
    if (isMounted.current) {
      setLoading(false);
    }
  };

  return (
    <Modal
      hideFooter={true}
      titleText={intl.get('meetingShareRecordingTitle')}
      closeModal={closeModal}
    >
      <ShareRecordingForm
        closeModal={closeModal}
        data={_get(meetingRecordingShareProperties, 'data', {})}
        meetingId={meetingId}
        isBusy={loading}
        onSubmit={handleSubmit}
        refreshMeetingUrls={handleRefreshUrl}
      />
    </Modal>
  );
};

export default ShareRecordingModal;
