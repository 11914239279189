import React from 'react';
import intl from 'react-intl-universal';
import ListViewComponent from '../../../ListView/ListViewComponent';
import ListViewLayout from '../../../ListViewLayout/ListViewLayout';
import { DWHPaginatedParticipantType } from '../../../../interfaces/Dashboard';
import styles from './ParticipantsTab.scss';

interface Props {
  participants: DWHPaginatedParticipantType[];
}

const ParticipantsTab = (props: Props) => {
  return (
    <div className={styles.container}>
      <ListViewLayout
        hideHeader={true}
        isFetching={false}
        numberOfPages={0}
      >
        <ListViewComponent
          data={props.participants}
          columns={
            [{
              Header: intl.get('tableHeaderName'),
              accessor: 'username'
            }, {
              Header: intl.get('extension'),
              accessor: 'extension'
            }]
          }
          showPagination={false}
          showAllResults={true}
          maxRows={1000}
          pageNumber={0}
        />
      </ListViewLayout>
    </div>
  );
};

export default ParticipantsTab;
