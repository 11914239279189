import React, { SyntheticEvent, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Field, Form, Formik, FormikValues } from 'formik';
import { Button } from 'semantic-ui-react';
import get from 'lodash/get';
import pick from 'lodash/pick';
import compact from 'lodash/compact';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { Tooltip } from 'components/tooltip/Tooltip';
import Icon from 'components/Common/Icon';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import * as styles from 'components/Settings/Sections.scss';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

// props passed in
export interface Props {
  customInviteShowAdditionalNumbers: boolean;
  customInviteShowConference: boolean;
  customInviteShowOtherWays: boolean;
  customInviteShowPin: boolean;
  customInviteShowSkype: boolean;
  hasPSTNAudio: boolean;
  hasSkypeForBusiness: boolean;
  hasOtherWays: boolean;
  hasShowConference: boolean;
  hasShowPin: boolean;
  invitationPreview: object;
  updateMeetingInvitePreview: Function;
  updateMeetingInvites: Function;
}

const getPasscodesLabel = (labelText: string, toolTipText: string) => {
  return [
    <span key="label">{labelText}</span>,
    (
      <Tooltip key="tooltip" text={toolTipText} stylesWrapper={{ lineHeight: 1, marginLeft: '8px' }}>
        <Icon classes={['icon-info', styles.tooltipButtonIcon]} />
      </Tooltip>
    )
  ];
};

const MeetingInvites = (props: Props) => {
  const {
    hasPSTNAudio,
    hasSkypeForBusiness,
    invitationPreview,
    hasOtherWays,
    hasShowConference,
    hasShowPin,
    updateMeetingInvitePreview
  } = props;

  const [wasSaved, setWasSaved] = useState(false);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const configKeys = compact([
    hasPSTNAudio && 'customInviteShowAdditionalNumbers',
    hasShowConference && 'customInviteShowConference',
    hasOtherWays && 'customInviteShowOtherWays',
    hasShowPin && 'customInviteShowPin',
    hasSkypeForBusiness && 'customInviteShowSkype',
    'language'
  ]);

  useEffect(() => {
    updateMeetingInvitePreview(pick(props, configKeys));
  },        []);

  const onChange = (target: object, handler: Function, values: FormikValues) => {
    if (!canEditSettings) { return; }
    const name = get(target, 'name');
    const value = get(target, 'checked');
    handler({ target: { name, value } });
    setWasSaved(false);
    updateMeetingInvitePreview({
      ...pick(values, configKeys),
      ...{ [name]: value }
    });
  };

  const onSubmit = (formData: object, callback: Function) => {
    props.updateMeetingInvites(pick(formData, configKeys), callback);
  };

  const previewError = get(invitationPreview, 'error');
  const previewErrorDesc = (typeof previewError === 'string') ? previewError : get(previewError, 'errorDescription');
  const previewErrorMessage = intl.get('errorGeneratingInvitePreview', { errorDesc: previewErrorDesc ? ` (${previewErrorDesc})` : '' });
  return (
    <section className={styles.container} data-test="settingsSection.invites">
      <Formik
        enableReinitialize={true}
        initialValues={pick(props, configKeys)}
        onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => {
          setWasSaved(true);
          setSubmitting(false);
        })}
        render={({ dirty, handleChange, isSubmitting, values }) => (
          <Form>
            <article>
              <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitleMeetingInvites')}</h6>
              <p className={styles.description}>{intl.get('settingsSectionDescriptionMeetingInvites')}</p>

              <div className={classnames(styles.formSection, styles.horizontal, styles.rowsOf2)}>
                {/* note: additional nesting is necessary for the desired layout/design */}
                {hasShowPin && <div>
                  <Field
                    component={ToggleButton}
                    id="customInviteShowPin"
                    label={getPasscodesLabel(intl.get('inputLabelShowMeetingPasscodes'), intl.get('tooltipShowMeetingPasscodes'))}
                    name="customInviteShowPin"
                    disabled={!canEditSettings}
                    onChange={(e: SyntheticEvent) => onChange(e.target, handleChange, values)}
                    value={values.customInviteShowPin}
                    {...{ ['data-test']: 'settingsSectionToggle.passcodes' }}
                  />
                </div>}

                {hasPSTNAudio && (
                  <div>
                    <Field
                      component={ToggleButton}
                      id="customInviteShowAdditionalNumbers"
                      label={<span>{intl.get('inputLabelShowAdditionalNumbers')}</span>}
                      name="customInviteShowAdditionalNumbers"
                      disabled={!canEditSettings}
                      onChange={(e: SyntheticEvent) => onChange(e.target, handleChange, values)}
                      value={values.customInviteShowAdditionalNumbers}
                      {...{ ['data-test']: 'settingsSectionToggle.additional' }}
                    />
                  </div>
                )}

                {hasSkypeForBusiness && (
                  <div>
                    <Field
                      component={ToggleButton}
                      id="customInviteShowSkype"
                      label={<span>{intl.get('inputLabelSkypeForBusiness')}</span>}
                      name="customInviteShowSkype"
                      disabled={!canEditSettings}
                      onChange={(e: SyntheticEvent) => onChange(e.target, handleChange, values)}
                      value={values.customInviteShowSkype}
                      {...{ ['data-test']: 'settingsSectionToggle.skype' }}
                    />
                  </div>
                )}

                {hasOtherWays && <div>
                  <Field
                    component={ToggleButton}
                    id="customInviteShowOtherWays"
                    label={<span>{intl.get('inputLabelOtherWaysToCall')}</span>}
                    name="customInviteShowOtherWays"
                    disabled={!canEditSettings}
                    onChange={(e: SyntheticEvent) => onChange(e.target, handleChange, values)}
                    value={values.customInviteShowOtherWays}
                    {...{ ['data-test']: 'settingsSectionToggle.other' }}
                  />
                </div>}

                {hasShowConference && <div>
                  <Field
                    component={ToggleButton}
                    id="customInviteShowConference"
                    label={<span>{intl.get('inputLabelCallingFromConference')}</span>}
                    name="customInviteShowConference"
                    disabled={!canEditSettings}
                    onChange={(e: SyntheticEvent) => onChange(e.target, handleChange, values)}
                    value={values.customInviteShowConference}
                    {...{ ['data-test']: 'settingsSectionToggle.conference' }}
                  />
                </div>}
              </div>

              <div className={styles.formSection}>
                <div className={styles.previewHeader}>
                  <div>
                    {intl.get('titlePreview')}
                  </div>
                  {dirty &&
                  <div className={styles.alert}>
                    {intl.getHTML('clickUpdateToSave')}
                  </div>
                  }
                </div>
                <pre className={styles.previewArea}>
                  {get(invitationPreview, 'isFetching') ? (
                    <LoadingIndicator />
                  ) : (get(invitationPreview, 'error') ? (
                      <div className="error">{previewErrorMessage}</div>
                    ) : (
                      get(invitationPreview, 'text')
                    )
                  )}
                </pre>
              </div>

              <div className={styles.formSection}>
                <div className={styles.buttonContainer}>
                  {(wasSaved && !dirty) && (
                    <div className={styles.confirmation}>{intl.getHTML('changesSaved')}</div>
                  )}
                  <Button
                    data-test="meetingSectionBtnSubmit"
                    disabled={isSubmitting || !dirty || !canEditSettings}
                    name="btnSubmitMeetingInvites"
                    role="button"
                    type="submit"
                  >
                    {intl.get('settingsButtonLabelUpdate')}
                  </Button>
                </div>
              </div>

            </article>
          </Form>
        )}
      />
    </section>
  );
};
export default MeetingInvites;
