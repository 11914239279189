import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { isAdminOrSuperUser } from './userProfileUtils';
import { MeetingShareProperties } from '../interfaces/VideoShareProperties';
import intl from 'react-intl-universal';

const DEFAULT_MEETING_KEY = 'default_meeting';
const DEFAULT_MEETING_TITLE = 'default-meeting';

const recordingHasDefaultOwner = (data: object | null) => {
  if (data) {
    const meetingUuid = _get(data, 'meeting.uuid');
    const defaultMeeting = _get(data, DEFAULT_MEETING_KEY);
    return meetingUuid && meetingUuid === defaultMeeting;
  }
  return false;
};

const areMeetingsIdentical = (meeting1: object, meeting2: object) => {
  return _isEqual(meeting1, meeting2);
};

/**
 * User can edit, share or delete a recording only if
 * they are an admin, super-user, or the recording owner
 */
const canUserModifyRecording = (userProfile: { name: string, userUUID: string, role: string }, recordingData: { owner: string, owner_uuid?: string }): boolean => {
  const isSuperUserOrAdmin = isAdminOrSuperUser(userProfile);
  if (isSuperUserOrAdmin) {
    return true;
  }

  if (!recordingData) {
    return false;
  }

  const { owner, owner_uuid } = recordingData;
  // If there is an owner UUID, compare that. If not, compare the owner names (old method).
  let isOwner;

  if (owner_uuid && owner_uuid.length) {
    isOwner = userProfile?.userUUID === owner_uuid;
  } else {
    isOwner = owner
      ? _get(userProfile, 'name').toUpperCase().trim() === owner.toUpperCase().trim()
      : false;
  }

  return isOwner;
};

const getMeetingShareSetting = (meetingProps: MeetingShareProperties) => {
  if (!meetingProps || Object.keys(meetingProps).length === 0) {
    return {
      id: 'default',
      name: intl.get('meetingParticipants')
    };
  }

  // Private
  if (!_get(meetingProps, 'share')) {
    return {
      id: 'private',
      name: intl.get('meetingOwner')
    };
  }

  // Entire Group
  if (_get(meetingProps, 'is_public')) {
    return {
      id: 'public',
      name: intl.get('entireAccount')
    };
  }

  // Meeting Participants
  if (_get(meetingProps, 'auto')) {
    return {
      id: 'default',
      name: intl.get('meetingParticipants')
    };
  }

  return {
    id: 'viewers',
    name: intl.get('specificUsers')
  };
};

const getMeetingAttributes = (selectedShare: string) => {
  const response: {
    share: boolean;
    is_public: boolean;
    auto: boolean;
    viewers?: []
  } = {
    share: true,
    is_public: false,
    auto: false
  };
  switch (selectedShare) {
    case 'private':
      response.share = false;
      response.is_public = false;
      response.auto = false;
      response.viewers = [];
      break;
    case 'public':
      response.share = true;
      response.is_public = true;
      response.auto = true;
      response.viewers = [];
      break;
    case 'default':
      response.share = true;
      response.is_public = false;
      response.auto = true;
      response.viewers = [];
      break;
    default:
      response.share = true;
      response.is_public = false;
      response.auto = false;
  }
  return response;
};

const getVideoShareSetting = (recordingProps: MeetingShareProperties) => {
  // Entire Group
  if (_get(recordingProps, 'is_public')) {
    return {
      id: 'public',
      name: intl.get('entireAccount')
    };
  }

  return {
    id: 'viewers',
    name: intl.get('specificUsers')
  };
};

const getVideoAttributes = (selectedShare: string) => {
  const response: {
    share: boolean;
    is_public: boolean;
    viewers?: []
  } = {
    share: true,
    is_public: false,
  };
  switch (selectedShare) {
    case 'public':
      response.share = true;
      response.is_public = true;
      response.viewers = [];
      break;
    default:
      response.share = true;
      response.is_public = false;
  }
  return response;
};

export {
  DEFAULT_MEETING_KEY,
  DEFAULT_MEETING_TITLE,
  recordingHasDefaultOwner,
  areMeetingsIdentical,
  canUserModifyRecording,
  getMeetingShareSetting,
  getMeetingAttributes,
  getVideoShareSetting,
  getVideoAttributes
};
