import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import * as dashStyles from './Dashboard.scss';
import * as styles from './DashboardNoResults.scss';
import * as tableStyles from './Tables/Tables.scss';

export interface Props {
  title: string;
  divId: string;
  isWide?: boolean;
}

const DashboardNoResults: React.FunctionComponent<Props> = (props) => {

  const {
    title,
    divId,
    isWide
  } = props;
  return (
    <div
      className={classnames(tableStyles.container, isWide && tableStyles.wide)}
      id={divId}
    >

      <div className={dashStyles.title}>
        {title}
      </div>

      <div className={styles.container}>
        <div className={styles.content}>
          {intl.get('noDataFound')}
          <span className={classnames('icon-data', styles.iconContainer)}/>
        </div>
      </div>

    </div>
  );
};

export { DashboardNoResults };
