import Icon from 'components/Common/Icon';
import CopyToClipboard from 'components/CopyToClipboardButton/CopyToClipboardButton';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import { Tooltip } from 'components/tooltip/Tooltip';
import * as styles from './InviteUserModal.scss';

interface Props {
  closeModal: () => void;
  getInvitationLink: (isRenew: boolean) => void;
  invitationToken: string;
  emailBody: string;
  subject: string;
}

const InviteUserModal = (props: Props) => {
  const { closeModal, getInvitationLink, invitationToken, emailBody, subject } = props;

  useEffect(
    () => {
      if (!invitationToken) {
        getInvitationLink(false);
      }
    },
    []
  );

  return (
    <Modal
      titleText={intl.get('inviteUser')}
      closeModal={closeModal}
      closeText={intl.get('cancel')}
    >
      <div className={styles.container}>
        <div className={styles.subTitle}>{intl.get('inviteUserSubtitle')}</div>
        <Button
          className={styles.btnSendInvite}
          disabled={!invitationToken}
          name="btnSendInvite"
          onClick={
            () => {
              window.open(
                `mailto: ?subject=${subject}&body=${emailBody}`,
                '_self'
              );
              closeModal();
            }
          }
          role="button"
          type="button"
        >
          {intl.get('sendInvitation')}
        </Button>
        <div className={styles.line}/>
        <div className={styles.cta}>{intl.get('inviteUsersCTA')}</div>
        <div className={styles.inviteLinkContainer}>
          <div className={styles.inviteLink}>
            <span>{(!invitationToken) ? '....' : invitationToken}</span>
          </div>
          <div className={styles.buttonContainer}>
            <Tooltip text={intl.get('copyToClipboard')}>
              <CopyToClipboard text={invitationToken} className={styles.buttonCopy} />
            </Tooltip>
            <Tooltip text={intl.get('refreshInvitation')}>
              <button onClick={() => getInvitationLink(true)} className={styles.button} name="btnRefresh" role="button" type="button">
                <Icon classes={['icon-loader']}/>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
