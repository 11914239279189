import Icon from 'components/Common/Icon';
import * as H from 'history';
import _get from 'lodash/get';
import _memoize from 'lodash/memoize';
import classnames from 'classnames';
import React, { useState, useEffect, MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSettingsActions, currentUserSelectors } from '@lifesize/nucleus';
import intl from 'react-intl-universal';
import { match } from 'react-router';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { PermissionTypes } from 'constants/permissionsConstants';
import { SIDE_NAV_WIDE_SETTING } from 'constants/userSettings';
import { withAuth } from 'utils/hocs/withAuth';
import Can from '../Can/CanComponent';
import styles from './Navbar.scss';
import { PAST_MEETING_PARTICIPANT_ROUTE, PAST_MEETING_ROUTE } from 'constants/pastMeetings';
import { LIVE_MEETING_PARTICIPANT_ROUTE, LIVE_MEETING_ROUTE } from 'constants/liveMeetings';
import { Global } from 'state/Global';
import { selectIsImpersonating } from 'selectors/impersonationSelectors';
import TooltipFixedPosition from 'components/TooltipFixedPosition/TooltipFixedPosition';
import { LOCATION } from 'constants/tooltipFixedPositionConstants';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { selectAccountPermissions } from 'selectors/permissionsSelector';

interface Props {
  isImpersonating: boolean;
  permissionsObject: object;
  isLoggedIn?: boolean;
}

const getLogo = _memoize((isSideNavWide) => {
  return (
    <div className={styles.logoContainer}>
      {isSideNavWide ? <Icon classes={['icon-lsLogo']}/> : <Icon classes={['icon-buddy']}/>}
    </div>
  );
});

const getEmptyNavBar = (isSideNavWide: boolean) => {
  return (
    <div className={styles.container}>
      <div className={styles.menuContainer} />
      {getLogo(isSideNavWide)}
    </div>
  );
};

const isRecordingsActive = _memoize((isSuperUser: boolean, pathName: string) => {
  if (isSuperUser) {
    return (pathName.toLowerCase().indexOf('recording') !== -1);
  }
  return (pathName === '/'
    || pathName.toLowerCase().indexOf('recording') !== -1);
},
                                    (...args) => {
  // Key for memoization
  return JSON.stringify(args);
});

const dashboardPaths = [
  '/dashboard',
  LIVE_MEETING_ROUTE,
  LIVE_MEETING_PARTICIPANT_ROUTE,
  PAST_MEETING_ROUTE,
  PAST_MEETING_PARTICIPANT_ROUTE
];

const isDashboardActive = (m: match<{}>, location: H.Location) => {
  return location.pathname === '/' || dashboardPaths.find((path) => {
    return location.pathname.startsWith(path);
  }) !== undefined;
};

const NavbarBase = (props: Props & RouteComponentProps<{}>) => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectAccountPermissions);
  const showRolesNav = !permissions[ROLE_PERMISSION.VIEW_USERS] && permissions[ROLE_PERMISSION.VIEW_ROLES];
  const isSideNavWide = useSelector(
    (state: Global) => _get(currentUserSelectors.myInfo(state), ['userSettings', SIDE_NAV_WIDE_SETTING], true)
  );
  const [startingX, setStartingX] = useState<number|null>(null);
  const isImpersonating = useSelector((state: Global) => selectIsImpersonating(state));
  const isLoggedIn = _get(props, 'isLoggedIn', false);
  const isSuperUser = _get(props, 'permissionsObject.isSuperUser', false);
  const SNAP_THRESHOLD = 20;

  const toggleSideNav = () => {
    dispatch(userSettingsActions.updateCurrentUserSettings(
      { [SIDE_NAV_WIDE_SETTING]: !isSideNavWide }
    ));
  };

  const handleDoubleClick = (e: MouseEvent) => {
    e.preventDefault();
    toggleSideNav();
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    setStartingX(e.screenX);
  };

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();

    // snap back to narrow or wide side nav
    endDrag();
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    let change;
    if (isSideNavWide) {
      if (!startingX || e.screenX > startingX) {
        return;
      }

      change = startingX - e.screenX;
    } else {
      if (!startingX || e.screenX < startingX) {
        return;
      }

      change = e.screenX - startingX;
    }
    // Snap to narrow or wide side nav
    if (change > SNAP_THRESHOLD) {
      toggleSideNav();
      endDrag();
    }
  };

  const endDrag = () => {
    setStartingX(null);
  };

  useEffect(
    () => {
      if (startingX !== null) {
        document.addEventListener('mousemove', handleMouseMove, false);
        document.addEventListener('mouseup', handleMouseUp, false);
      } else {
        document.removeEventListener('mousemove', handleMouseMove, false);
        document.removeEventListener('mouseup', handleMouseUp, false);
      }
      return () => {
        if (startingX !== null) {
          document.removeEventListener('mousemove', handleMouseMove, false);
          document.removeEventListener('mouseup', handleMouseUp, false);
        }
      };
    },
    [startingX]
  );

  if (!(isLoggedIn)) {
    return getEmptyNavBar(isSideNavWide);
  }

  return (
    <div className={classnames(styles.container, isSideNavWide && styles.wide)}>
      <div className={styles.menuContainer}>
        <Can
          userAccess={[PermissionTypes.ANY_DASHBOARD]}
        >
          <div className={styles.menuItem}>
            <NavLink
              activeClassName={styles.active}
              className={styles.navLink}
              exact={true}
              to={'/'}
              {...{ ['data-test']: 'navLink.dashboard' }}
              isActive={isDashboardActive}
            >
              <Icon classes={['icon-home']}/>
              {isSideNavWide && (
                <span>{intl.get('dashboard')}</span>
              )}
            </NavLink>
            {!isSideNavWide && (
              <TooltipFixedPosition
                label={intl.get('dashboard')}
                location={LOCATION.RIGHT}
              />
            )}
          </div>
        </Can>
        <Can
          userAccess={[ROLE_PERMISSION.VIEW_USERS]}
        >
          <div className={styles.menuItem}>
            <NavLink
              activeClassName={styles.active}
              className={styles.navLink}
              isActive={(m: match<{}>, location: H.Location) => location.pathname.startsWith('/users')}
              to={'/users/users'}
              {...{ ['data-test']: 'navLink.users' }}
            >
              <Icon classes={['icon-contact-profile']}/>
              {isSideNavWide && (
                <span>{intl.get('contacts')}</span>
              )}
            </NavLink>
            {!isSideNavWide && (
              <TooltipFixedPosition
                label={intl.get('contacts')}
                location={LOCATION.RIGHT}
              />
            )}
          </div>
        </Can>
        {showRolesNav && (
          <div className={styles.menuItem}>
            <NavLink
              activeClassName={styles.active}
              className={styles.navLink}
              isActive={(m: match<{}>, location: H.Location) => location.pathname.startsWith('/users')}
              to={'/users/roles'}
              {...{['data-test']: 'navLink.roles'}}
            >
              <Icon classes={['icon-contact-profile']}/>
              {isSideNavWide && (
                <span>{intl.get('roles')}</span>
              )}
            </NavLink>
            {!isSideNavWide && (
              <TooltipFixedPosition
                label={intl.get('roles')}
                location={LOCATION.RIGHT}
              />
            )}
          </div>
        )}
        <Can
          userAccess={[ROLE_PERMISSION.VIEW_ROOMS]}
        >
          <div className={styles.menuItem}>
            <NavLink
              activeClassName={styles.active}
              className={styles.navLink}
              to={'/room-systems'}
              {...{ ['data-test']: 'navLink.roomSystems' }}
            >
              <Icon classes={['icon-room-system']}/>
              {isSideNavWide && (
                <span>{intl.get('roomSystems')}</span>
              )}
            </NavLink>
            {!isSideNavWide && (
              <TooltipFixedPosition
                label={intl.get('roomSystems')}
                location={LOCATION.RIGHT}
              />
            )}
          </div>
        </Can>
        <Can
          userAccess={[ROLE_PERMISSION.VIEW_MEETINGS]}
        >
          <div className={styles.menuItem}>
            <NavLink
              activeClassName={styles.active}
              className={styles.navLink}
              isActive={(m: match<{}>, location: H.Location) => location.pathname.startsWith('/meetings')}
              to={'/meetings/meet'}
              {...{ ['data-test']: 'navLink.meetings' }}
            >
              <Icon classes={['icon-meeting']}/>
              {isSideNavWide && (
                <span>{intl.get('meetingsTab')}</span>
              )}
            </NavLink>
            {!isSideNavWide && (
              <TooltipFixedPosition
                label={intl.get('meetingsTab')}
                location={LOCATION.RIGHT}
              />
            )}
          </div>
        </Can>
        {!isImpersonating &&
          <Can
            userAccess={[PermissionTypes.ANY_RECORDINGS]}
          >
            <div className={styles.menuItem}>
              <NavLink
                activeClassName={styles.active}
                className={styles.navLink}
                isActive={() => isRecordingsActive(isSuperUser, location.pathname)}
                to={'/recordings'}
                {...{ ['data-test']: 'navLink.recordings' }}
              >
                <Icon classes={['icon-record']}/>
                {isSideNavWide && (
                  <span>{intl.get('recordings')}</span>
                )}
              </NavLink>
              {!isSideNavWide && (
                <TooltipFixedPosition
                  label={intl.get('recordings')}
                  location={LOCATION.RIGHT}
                />
              )}
            </div>
          </Can>
        }
        <Can
          userAccess={[ROLE_PERMISSION.VIEW_OWN_ACCOUNT_GENERAL_SETTINGS]}
        >
          <div className={styles.menuItem}>
            <NavLink
              activeClassName={styles.active}
              className={styles.navLink}
              isActive={(m: match<{}>, location: H.Location) => location.pathname.startsWith('/settings')}
              to={'/settings/details'}
              {...{ ['data-test']: 'navLink.accountSettings' }}
            >
              <Icon classes={['icon-settings']}/>
              {isSideNavWide && (
                <span>{intl.get('accountSettings')}</span>
              )}
            </NavLink>
            {!isSideNavWide && (
              <TooltipFixedPosition
                label={intl.get('accountSettings')}
                location={LOCATION.RIGHT}
              />
            )}
          </div>
        </Can>
      </div>
      {getLogo(isSideNavWide)}
      <div
        id="sideNavHandle"
        draggable={true}
        className={styles.navHandle}
        onMouseDown={handleMouseDown}
        onDoubleClick={handleDoubleClick}
      />
    </div>
  );
};

export const Navbar = compose<Props & RouteComponentProps<{}>, {}>(
  withRouter,
  withAuth
)(NavbarBase);
