import React from 'react';
import intl from 'react-intl-universal';
import _get from 'lodash/get';
import {
  DUPLICATE_SYSTEM_NAME_ERROR,
  OTHER_SYSTEM_NAME_ERROR,
} from 'utils/roomSystemUtils';
import _memoize from 'lodash/memoize';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import renameDeviceMutation from 'queries/roomSystems/renameRoomSystemMutation.gql';
import { Formik } from 'formik';
import { Logger } from 'logger';
import RoomSystemDisplayNameForm from './RoomSystemDisplayNameForm';
import { RoomSystemDisplayNameProps } from 'interfaces/RoomSystems';

const displayNameSchema = _memoize(() => {
  return Yup.object().shape({
    displayName: Yup.string().trim().required(intl.get('systemNameRequired'))
      .min(3, intl.get('systemNameMinLength'))
      .max(32, intl.get('systemNameMaxLength'))
  });
});

const RoomSystemDisplayName = (props: RoomSystemDisplayNameProps) => {
  const { roomSystem, setDisplayName } = props;

  return (
    <Mutation mutation={renameDeviceMutation}>
      {(renameRoomSystem, { loading }) => {
        return (
          <Formik
            enableReinitialize={true}
            initialValues={{
              serialNumber: _get(roomSystem, 'serialNumber'),
              displayName: _get(roomSystem, 'displayName')
            }}
            validationSchema={displayNameSchema}
            onSubmit={async (values, { resetForm, setFieldError }) => {
              try {
                const { serialNumber, displayName } = values;
                await renameRoomSystem(
                  {
                    variables: {
                      serialNumber,
                      systemName: displayName
                    }
                  });
                setDisplayName(displayName || '');
                resetForm(values);
              } catch (error) {
                if (error && error.graphQLErrors) {
                  const errorObj = error.graphQLErrors[0];
                  let errorMessage = _get(errorObj, 'message', '');
                  try {
                    errorMessage = JSON.parse(_get(errorObj, 'message', ''));
                  } catch (e) {
                    Logger.info(`Non-JSON error message received: ${errorMessage}`);
                  }
                  const errorCode = _get(errorMessage, 'errorCode', '');
                  if (errorCode.toUpperCase().trim() === 'SYSTEMNAMEEXISTERROR') {
                    setFieldError('displayName', DUPLICATE_SYSTEM_NAME_ERROR);
                  } else {
                    // Unexpected (other) error - log it.
                    Logger.info(`Error renaming a room system: ${JSON.stringify(errorMessage)}`);
                    setFieldError('displayName', OTHER_SYSTEM_NAME_ERROR);
                  }
                } else {
                  setFieldError('displayName', '');
                }
              }
            }}
            render={(formProps) => (
              <RoomSystemDisplayNameForm
                {...formProps}
                roomSystem={roomSystem}
                loading={loading}
              />
            )}
          />
        );
      }}
    </Mutation>
  );
};

export default RoomSystemDisplayName;
