import { createSelector } from 'reselect';
import { Global } from 'state/Global';
import { currentUserSelectors, recordingsSelectors } from '@lifesize/nucleus';
import moment from 'moment';
import _get from 'lodash/get';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { selectAccountPermissions } from './permissionsSelector';

/**
 * Direct selector to the recordings state domain
 */
const selectRecordingsState = (state: Global) => state.app?.recordings;
const selectRecordingFetching = (state: Global) => _get(state, 'nucleus.recordings.cloudInfo.isFetching');

const selectStartDate = createSelector(
  selectRecordingsState,
  (recordings) => {
    return recordings.startDate;
  }
);

const selectEndDate = createSelector(
  selectRecordingsState,
  (recordings) => {
    return recordings.endDate;
  }
);

const selectInitialRecordingDate = createSelector(
  recordingsSelectors.recordingsFeed,
  (feed) => {
    const createDate = feed?.data[feed?.data.length - 1]?.date_created;
    if (createDate) {
      return moment(createDate * 1000);
    } else {
      return undefined;
    }
  }
);

const selectIsGridLayout = createSelector(
  selectRecordingsState,
  (recordings) => recordings?.isGridLayout
);

const selectSearchResults = createSelector(
  selectRecordingsState,
  (recordings) => recordings?.searchResults
);

const recordingResultsPendingSelector = createSelector(
  selectRecordingsState,
  selectRecordingFetching,
  (recordings, isFetching) => recordings?.pending || isFetching
);

const getUploadsStatus = createSelector(
  selectRecordingsState,
  (recordings) => recordings?.uploadsStatus
);

const getUploadUpdatingStatus = createSelector(
  selectRecordingsState,
  (recordings) => recordings?.updating
);

const isFetchingVcCookie = createSelector(
  selectRecordingsState,
  (recordings) => {
    return recordings.isFetchingVcCookie;
  }
);
const isVcCookieSet = createSelector(
  selectRecordingsState,
  (recordings) => {
    return recordings.isVcCookieSet;
  }
);
const failedToFetchVcCookie = createSelector(
  selectRecordingsState,
  (recordings) => {
    return recordings.failedToFetchVcCookie;
  }
);

const modifyRecordingSelector = createSelector(
  recordingsSelectors.singleRecording,
  currentUserSelectors.myInfo,
  selectAccountPermissions,
  ({ data }, userInfo, accountPermissions) => {
    if (!data) { return false; }
    if (accountPermissions[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_RECORDINGS]) {
      return true;
    }

    const { owner, owner_uuid } = data;
    // If there is an owner UUID, compare that. If not, compare the owner names (old method).
    let isOwner;

    if (owner_uuid && owner_uuid.length) {
      isOwner = userInfo?.userUUID === owner_uuid;
    } else {
      isOwner = owner
        ? userInfo?.name.toUpperCase().trim() === owner.toUpperCase().trim()
        : false;
    }

    return isOwner && accountPermissions[ROLE_PERMISSION.MODIFY_OWN_RECORDINGS];
  }
);

export {
  selectStartDate,
  selectEndDate,
  selectInitialRecordingDate,
  selectIsGridLayout,
  selectSearchResults,
  recordingResultsPendingSelector,
  getUploadsStatus,
  getUploadUpdatingStatus,
  isFetchingVcCookie,
  isVcCookieSet,
  modifyRecordingSelector,
  failedToFetchVcCookie
};
