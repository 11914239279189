import { accountSelectors, featureSelectors } from '@lifesize/nucleus';
import { ACCOUNT_FEATURE_RECORDING_ENABLED, ACCOUNT_FEATURE_STREAMING } from 'constants/accountFeatureConstants';
import { ACCOUNT_SETTING_RECORDING_ENABLED } from 'constants/accountSettingsConstants';
import {
  FEATURE_FLAG_ROLE_MANAGEMENT
} from 'constants/featureFlags';
import { PermissionTypes } from 'constants/permissionsConstants';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { createSelector } from 'reselect';
import { Global } from 'state/Global';
import {
  demoDashboardSelector,
  hideDashboard,
  hideMeetings,
  hideRooms,
  hideUsers,
  isAdmin,
  isAdminOrSuperUser
} from './accountSelectors';
import { selectIsImpersonating } from './impersonationSelectors';
import getFeatureFlag = featureSelectors.getFeatureFlag;

/**
 * Direct selector to the permissions state domain
 */
const selectPermissionsState = (state: Global) => state.app?.permissions;
// TODO this should be replaced with a nucleus selector ASAP
const checkinPermissions = (state: { nucleus: { userProfile: { permissions: ROLE_PERMISSION[] }} }) => state.nucleus?.userProfile?.permissions;
export const usePermissionsService = (state: {}) => getFeatureFlag(state)(FEATURE_FLAG_ROLE_MANAGEMENT);

const permissionsFromServiceSelector = createSelector(
  checkinPermissions,
  usePermissionsService,
  (permissions, usePermissions) => usePermissions ? permissions : null
);

const selectPermissionsObject = createSelector(
  selectPermissionsState,
  (permissions) => permissions
);

const selectAccountPermissions = createSelector(
  selectPermissionsObject,
  permissionsFromServiceSelector,
  accountSelectors.getAccountSettings,
  accountSelectors.getAccountFeatures,
  selectIsImpersonating,
  isAdminOrSuperUser,
  isAdmin,
  hideDashboard,
  demoDashboardSelector,
  hideMeetings,
  hideUsers,
  hideRooms,
  (
    permissionsObject,
    servicePermissions,
    accountSettings,
    accountFeatures,
    isImpersonating,
    userIsSuperUserOrAdmin,
    userIsAdmin,
    shouldHideDashboard,
    demoDashboard,
    shouldHideMeetings,
    shouldHideUsers,
    shouldHideRooms
  ) => {
    let permissions = {...permissionsObject};

    const hasRecordingFeature = accountFeatures?.[ACCOUNT_FEATURE_RECORDING_ENABLED];
    const hasRecordingEnabled = accountSettings?.[ACCOUNT_SETTING_RECORDING_ENABLED] && !isImpersonating;

    permissions[PermissionTypes.VIEW_STREAMING] = accountFeatures?.[ACCOUNT_FEATURE_STREAMING] && !isImpersonating;

    if (servicePermissions) {
      permissions = {...permissions, ...servicePermissions};
      permissions[PermissionTypes.ACCOUNT_RECORDINGS] = hasRecordingFeature;

      const hasRecordingPermission =
        servicePermissions[ROLE_PERMISSION.VIEW_OWN_RECORDINGS] ||
        servicePermissions[ROLE_PERMISSION.VIEW_OWN_ACCOUNT_RECORDINGS];

      permissions[PermissionTypes.ANY_RECORDINGS] =
        hasRecordingPermission &&
        hasRecordingEnabled &&
        hasRecordingFeature;

      // Permission to view the main dashboard, live or past meetings
      permissions[PermissionTypes.ANY_DASHBOARD] =
        permissions[ROLE_PERMISSION.VIEW_DASHBOARD] ||
        permissions[ROLE_PERMISSION.VIEW_LIVE_MEETINGS] ||
        permissions[ROLE_PERMISSION.VIEW_PAST_MEETINGS] ||
        permissions[ROLE_PERMISSION.VIEW_MODERATOR_LIVE_MEETINGS] ||
        permissions[ROLE_PERMISSION.VIEW_MODERATOR_PAST_MEETINGS];

      // Permission to view Past Meetings, Users, Meetings, Group Contacts, or Room systems
      permissions[PermissionTypes.ANY_DOWNLOADS] =
        permissions[ROLE_PERMISSION.VIEW_PAST_MEETINGS] ||
        permissions[ROLE_PERMISSION.VIEW_USERS] ||
        permissions[ROLE_PERMISSION.VIEW_MEETINGS] ||
        permissions[ROLE_PERMISSION.VIEW_ROOMS];

      return permissions;
    }

    const adminShowDashboard = !shouldHideDashboard || demoDashboard;
    const adminShowMeetings = !shouldHideMeetings;
    const adminShowUsers = !shouldHideUsers;
    const adminShowRooms = !shouldHideRooms;

    permissions[PermissionTypes.ACCOUNT_RECORDINGS] = hasRecordingFeature;
    permissions[ROLE_PERMISSION.VIEW_OWN_RECORDINGS] = true;
    permissions[ROLE_PERMISSION.VIEW_ROLES] = false;
    permissions[PermissionTypes.ANY_RECORDINGS] = hasRecordingEnabled && hasRecordingFeature;

    if (userIsSuperUserOrAdmin) {
      permissions[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS] = true;
      permissions[ROLE_PERMISSION.VIEW_MEETINGS] = true;
      permissions[ROLE_PERMISSION.VIEW_USERS] = true;
      permissions[ROLE_PERMISSION.VIEW_ROOMS] = true;
      permissions[ROLE_PERMISSION.MODIFY_MEETINGS] = true;
      permissions[ROLE_PERMISSION.MODIFY_USERS] = true;
      permissions[ROLE_PERMISSION.MODIFY_ROOMS] = true;
      permissions[ROLE_PERMISSION.VIEW_OWN_ACCOUNT_RECORDINGS] = true;
      permissions[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_RECORDINGS] = true;
      permissions[PermissionTypes.ANY_DOWNLOADS] = true;
      permissions[ROLE_PERMISSION.VIEW_MODERATOR_PAST_MEETINGS] = true;
    }

    if (userIsAdmin) {
      permissions[PermissionTypes.ANY_DASHBOARD] = adminShowDashboard;
      permissions[ROLE_PERMISSION.VIEW_MEETINGS] = adminShowMeetings;
      permissions[ROLE_PERMISSION.VIEW_USERS] = adminShowUsers;
      permissions[ROLE_PERMISSION.VIEW_ROOMS] = adminShowRooms;
      permissions[ROLE_PERMISSION.MODIFY_MEETINGS] = adminShowMeetings;
      permissions[ROLE_PERMISSION.MODIFY_USERS] = adminShowUsers;
      permissions[ROLE_PERMISSION.MODIFY_ROOMS] = adminShowRooms;
    }

    return permissions;
  }
);

const selectDefaultRoute = createSelector(
  selectAccountPermissions,
  (accountPermissions) => {
    if (accountPermissions[PermissionTypes.ANY_DASHBOARD]) {
      return '/';
    }
    if (accountPermissions[ROLE_PERMISSION.VIEW_OWN_ACCOUNT_GENERAL_SETTINGS]) {
      return '/settings/details';
    }
    if (accountPermissions[PermissionTypes.ANY_RECORDINGS]) {
      return '/recordings';
    }
    if (accountPermissions[ROLE_PERMISSION.VIEW_MEETINGS]) {
      return '/meetings/meet';
    }
    if (accountPermissions[ROLE_PERMISSION.VIEW_USERS]) {
      return '/users/users';
    }
    if (accountPermissions[ROLE_PERMISSION.VIEW_ROOMS]) {
      return '/room-systems';
    }
    // TODO No permissions page
    return '/';
  }
);

export {
  selectPermissionsObject,
  selectDefaultRoute,
  selectAccountPermissions
};
