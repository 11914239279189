import * as React from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import * as styles from './DownloadToasts.scss';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import downloadNotificationActions from 'actions/downloadNotificationActions';
import classnames from 'classnames';
import { DOWNLOADS_PATH } from 'constants/downloadConstants';

const downloadCompletedToast = () => {
  const dispatch = useDispatch();
  const routerHistory = useHistory();
  const handleCloseClick = () => dispatch(downloadNotificationActions.dismissDownloadNotification());
  return (
    <div className={styles.container}>
      <button className={styles.fileReadyButton} onClick={(() => {routerHistory.push(DOWNLOADS_PATH); })}>
        <div className={classnames(styles.iconContainer, styles.successContainer)}>
          <Icon classes={['icon-check-on']}/>
        </div>
        <div className={styles.messageContainer}>{intl.get('fileReadyToDownload')}</div>
      </button>
      <button className={styles.closeButton} onClick={handleCloseClick}>
        <Icon classes={['icon-cancel']} />
      </button>
    </div>
  );
};

export default downloadCompletedToast;
