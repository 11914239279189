import {
  EXPORTS_LOADING,
  GET_EXPORT_ERROR,
  HANDLE_CONFERENCE_EXPORT_DELETION,
  SET_EXPORTS,
  UPDATE_EXPORTS
} from 'constants/downloadConstants';
import { Download, UpdatedExport } from 'interfaces/Downloads';
import { createAction } from '@lifesize/typescript-react-redux-utils';

export const exportActions = {
  handleConferenceExportDeletion: (removedResults: string[]) =>
    createAction(HANDLE_CONFERENCE_EXPORT_DELETION, removedResults),
  setExportsLoading: () =>
    createAction(EXPORTS_LOADING),
  setExports: (exports: Download[]) =>
    createAction(SET_EXPORTS, exports),
  setUpdatedExports: (updatedExport: UpdatedExport) => 
    createAction(UPDATE_EXPORTS, updatedExport),
  setExportError: (error: string) =>
    createAction(GET_EXPORT_ERROR, error),
};

export default exportActions;
