import { createSelector } from 'reselect';
import { Global } from '../state/Global';

/**
 * Direct selector to the dashboard state domain
 */
const selectModalState = (state: Global) => state.app?.modal;

const selectModalType = createSelector(
  selectModalState,
  (modal) => {
    return modal.modalType;
  }
);

const getPendingModalClose = createSelector(
  selectModalState,
  (modal) => {
    return modal.pendingClosedModal;
  }
);

export {
  selectModalType,
  getPendingModalClose,
  selectModalState
};
