import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classes from './SFUSettings.scss';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { accountSelectors } from '@lifesize/nucleus';
import { useDispatch, useSelector } from 'react-redux';
import { setMeetingSettings } from 'thunks/groupSettingsThunks';
import { Logger } from 'logger';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const SFUSettings = () => {
  const accountSettings = useSelector(accountSelectors.getAccountSettings);
  const [sfuEnabled, setSfuEnabled] = useState<boolean>(!!accountSettings?.sfuSettings?.dualThirdPartyDevice);
  const [error, setError] = useState<boolean>(false);
  const dispatch = useDispatch();
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  useEffect(
  () => {
      setSfuEnabled(accountSettings?.sfuSettings?.dualThirdPartyDevice);
    },
  [accountSettings?.sfuSettings?.dualThirdPartyDevice, setSfuEnabled]
  );

  return (
    <div className={classes.container}>
      <h2 className={classes.settingsTitle}>{intl.get('thirdPartyDevices')}</h2>
      <p className={classes.instructions}>
        {intl.get('thirdPartyDualStreamInstructions')}
      </p>
      <ToggleButton
        id={'sfuToggle'}
        label={intl.get('thirdPartyDualStreamToggle')}
        leftLabel={false}
        hidenow={false}
        topLabel={false}
        disabled={!canEditSettings}
        field={{
          name: 'sfuThirdPartyEnabled',
          value: sfuEnabled,
          onChange: async () => {
            if (!canEditSettings) { return; }
            const currentSfuValue = sfuEnabled;
            setSfuEnabled(!currentSfuValue);
            const settingResult = await dispatch(setMeetingSettings({ 'sfuSettings': { 'dualThirdPartyDevice': !currentSfuValue } })) as unknown as { type: string };
            if (settingResult.type === setMeetingSettings.rejected.type) {
              Logger.info('Unable to change SFU setting.');
              setSfuEnabled(currentSfuValue);
              setError(true);
            }
          },
          onBlur: () => {return; }
        }}
      />
      {error &&
        <div className={classes.error}>
          {intl.get('sfuSettingError')}
        </div>
      }
    </div>
  );
};

export default SFUSettings;
