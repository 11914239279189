import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const modalState = (state: Global) => state.app?.modal;

const selectPayload = createSelector(
  modalState,
  (modal) => modal.payload || {}
);

export {
  selectPayload
};
