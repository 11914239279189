import { currentUserSelectors } from '@lifesize/nucleus';
import { FormikActions, FormikComputedProps, FormikHandlers, FormikState } from 'formik';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { defaultProps } from 'recompose';
import { Tab } from 'semantic-ui-react';
import { actions } from 'actions/modalActions';
import { actions as resetPasswordActions } from 'actions/resetPasswordActions';
import { selectPayload } from 'selectors/modalSelector';
import { selectResetPasswordState } from 'selectors/resetPasswordSelector';
import { Global } from 'state/Global';
import { User } from 'typings/types';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as modalFormStyles from '../ModalForms.scss';
import { FormValues } from './FormFields';
import DevicesTab from './Tabs/DevicesTab';
import InfoTab from './Tabs/InfoTab';

interface Props extends FormikState<object>,
  FormikComputedProps<object>,
  FormikActions<object>,
  FormikHandlers {
  closeModal: () => void;
  currentUser: object;
  data: User;
  errors: FormValues;
  isAdd: boolean;
  isFetching: boolean;
  isPasswordReset: boolean;
  isResettingPassword: boolean;
  isSubmitting: boolean;
  resetPassword: (uuid: string) => string;
  resetPasswordFailure: boolean;
  touched: FormValues;
  values: FormValues;
  resetPasswordHandler: (uuid: string) => void;
}

const UserModal: React.FunctionComponent<Props> = (props) => {
  const {
    closeModal,
    currentUser,
    data,
    isAdd,
    resetPasswordHandler,
  } = props;

  const panes = [
    {
      menuItem: { key: 'infoTab', content: intl.get('userInfoTabTitle') },
      render: () => (
        <Tab.Pane key={'0'} attached={false}>
          <InfoTab
            closeModal={closeModal}
            currentUser={currentUser}
            selectedUser={data}
            resetPasswordHandler={resetPasswordHandler}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: { key: 'devicesTab', content: intl.get('userDevicesTabTitle') },
      render: () => (
        <Tab.Pane key={'1'} attached={false}>
          <DevicesTab
            closeModal={closeModal}
          />
        </Tab.Pane>
      )
    },
  ];
  return (
    <Modal
      className={modalFormStyles.containerSmall}
      closeModal={closeModal}
      hideFooter={true}
      titleText={isAdd ? intl.get('createUser') : intl.get('userDetails')}
    >
      <div>
        <Tab className={modalFormStyles.formControl} menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: Global) => {
  const selectedUser = selectPayload(state) as User;
  const resetPasswordState = selectResetPasswordState(state);
  return {
    data: selectedUser,
    currentUser: currentUserSelectors.myInfo(state),
    isResettingPassword: resetPasswordState.isResettingPassword,
    isPasswordReset: resetPasswordState.isPasswordReset,
    resetPasswordFailure: resetPasswordState.resetPasswordFailure
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  resetPasswordHandler: (uuid: string) => dispatch(resetPasswordActions.resetPassword(uuid))
});

const composed = connect(mapStateToProps, mapDispatchToProps)(UserModal);

export const AddUserModal = defaultProps({ isAdd: true })(composed);
export const EditUserModal = defaultProps({ isAdd: false })(composed);
export { composed as UserModal };
