import { Reducer } from 'redux';
import { CalendarSubscriptionActions } from 'actions/calendarSubscriptionActions';
import { CalendarSubscriptionState, initialCalendarSubscriptionState } from 'state/CalendarSubscriptionState';

const calendarSubscriptionReducer: Reducer<CalendarSubscriptionState> =
  (state = initialCalendarSubscriptionState, action) => {
    switch (action.type) {
      case CalendarSubscriptionActions.GET_SUBSCRIPTION:
      case CalendarSubscriptionActions.UNSUBSCRIBE:
        return { ...state, isBusy: true };
      case CalendarSubscriptionActions.SUBSCRIPTION_FOUND:
        return {
          ...state,
          subscriptionEmail: action.payload,
          isBusy: false
        };
      case CalendarSubscriptionActions.SUBSCRIPTION_NOT_FOUND:
      case CalendarSubscriptionActions.RESET_SUBSCRIPTION_STATE:
        return {
          ...state,
          subscriptionEmail: null,
          isBusy: false,
          subscriptionLookupFailed: false,
          unsubscribeFailed: false,
        };
      case CalendarSubscriptionActions.UNSUBSCRIBE_FAILURE:
        return {
          ...state,
          unsubscribeFailed: true,
          isBusy: false
        };
      case CalendarSubscriptionActions.SUBSCRIPTION_LOOKUP_FAILURE:
        return {
          ...state,
          subscriptionLookupFailed: true,
          isBusy: false
        };
      case CalendarSubscriptionActions.SUBSCRIBE_SUCCESS:
        return {
          ...state,
          subscriptionEmail: action.payload,
          isBusy: false
        };
      case CalendarSubscriptionActions.UNSUBSCRIBE_SUCCESS:
        return {
          ...state,
          subscriptionEmail: null,
          isBusy: false
        };
      default:
        return state;
    }
  };

export default calendarSubscriptionReducer;
