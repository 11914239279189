import classnames from 'classnames';
import _memoize from 'lodash/memoize';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Tab } from 'semantic-ui-react';
import Icon from 'components/Common/Icon';
import GroupContactsTab from 'components/Users/GroupContacts/GroupContactsTab';
import UsersTab from 'containers/Users/UsersTabContainer';
import UserRolesTab from 'components/UserRolesTab/UserRolesTab';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import * as styles from './Users.scss';
import { getRoles } from 'thunks/roleThunks';
import { useDispatch, useSelector } from 'react-redux';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { getRouteForIndex, handleTabChange } from 'utils/tabUtils';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { NoPermissionsBanner } from '../NoPermissionsBanner/NoPermissionsBanner';

enum USER_TABS {
  USERS = '/users/users',
  ROLES = '/users/roles',
  GROUP_CONTACTS = '/users/group'
}

const generateSupportedIndices = (includeUsers: boolean, includeRoles: boolean, includeGroupContacts: boolean) => {
  let tabIndex = -1;
  let tabs = {};

  if (includeUsers) {
    tabs[USER_TABS.USERS] = tabIndex += 1;
  }

  if (includeRoles) {
    tabs[USER_TABS.ROLES] = tabIndex += 1;
  }

  if (includeGroupContacts) {
    tabs[USER_TABS.GROUP_CONTACTS] = tabIndex + 1;
  }

  return tabs;
};

export interface Props {
  match: {
    params: {
      tab: string;
    };
  };
  history: {
    push: Function;
    location: {
      pathname: string;
    }
  };
}

const getTitle = _memoize((selectedTab: number, keyToActiveIndex: {}) => {
  let titleString: string = '';
  const tabName = getRouteForIndex(keyToActiveIndex, selectedTab, 'default');
  if (tabName === USER_TABS.USERS) {
    titleString = intl.get('usersTab');
  } else if (tabName === USER_TABS.ROLES) {
    titleString = intl.get('roleManagement');
  } else if (tabName === USER_TABS.GROUP_CONTACTS) {
    titleString = intl.get('contactsTab');
  }

  return (
    <>
      <Icon classes={['icon-contact-profile']} />
      <span>{titleString}</span>
    </>
  );
});

const getBannerText = _memoize((selectedTab: number, keyToActiveIndex: {}) => {
  const tabName = getRouteForIndex(keyToActiveIndex, selectedTab, 'default');
  if (tabName === USER_TABS.ROLES) {
    return intl.get('cannotModifyRoles');
  }

  if (tabName === USER_TABS.GROUP_CONTACTS) {
    return intl.get('cannotModifyGroupContacts');
  }

  return intl.get('cannotModifyUsers');
});

const UsersComponent = (props: Props) => {
  const { match, history } = props;
  const permissions = useSelector(selectAccountPermissions);
  const rolesFeatureFlag = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const canViewUsers = permissions[ROLE_PERMISSION.VIEW_USERS];
  const canEditUsers = permissions[ROLE_PERMISSION.MODIFY_USERS];
  const canViewRoles = rolesFeatureFlag && permissions[ROLE_PERMISSION.VIEW_ROLES];

  const keyToActiveIndex = generateSupportedIndices(canViewUsers, canViewRoles, true);
  const getCurrentIndex = () => {
    const tab = history.location.pathname || USER_TABS.USERS;
    return keyToActiveIndex[tab] || 0;
  };
  const routeTab = match?.params?.tab;
  const [selectedTab, setSelectedTab] = useState(getCurrentIndex());
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (rolesFeatureFlag) {
        dispatch(getRoles());
      }
    },
    [rolesFeatureFlag]
  );

  useEffect(
    () => {
      setSelectedTab(getCurrentIndex());
    },
    [routeTab]
  );

  const panes = [];

  if (canViewUsers) {
    panes.push({
      menuItem: (
        {
          key: 'usersTab',
          content: (<div data-test="userSubTab">{intl.get('usersTab')}</div>)
        }),
      render: () => (
        <Tab.Pane key={'0'} attached={false}>
          <UsersTab/>
        </Tab.Pane>
      )
    });
  }

  if (canViewRoles) {
    panes.push({
      menuItem: (
        {
          key: 'rolesTab',
          content: (<div data-test="roleManagementTab">{intl.get('roleManagement')}</div>)
        }),
      render: () => (
        <Tab.Pane key={'1'} attached={false}>
          <UserRolesTab />
        </Tab.Pane>
      )
    });
  }

  if (canViewUsers) {
    panes.push({
      menuItem: (
        {
          key: 'contactsTab',
          content: (
            <div data-test="groupContactsSubTab">{intl.get('contactsTab')}</div>
          )
        }
      ),
      render: () => (
        <Tab.Pane key={rolesFeatureFlag ? '2' : '1'} attached={false}>
          <GroupContactsTab />
        </Tab.Pane>
      )
    });
  }

  return (
    <div className={styles.container}>
      {!canEditUsers && <NoPermissionsBanner text={getBannerText(selectedTab, keyToActiveIndex)} />}
      <ListViewLayoutHeader title={getTitle(selectedTab, keyToActiveIndex)} />
      <div className={classnames(styles.tabsContainer, 'tabset')}>
        <Tab
          activeIndex={getCurrentIndex()}
          menu={{ secondary: true, pointing: true }}
          onTabChange={(_, { activeIndex }) => handleTabChange({
            selectedTab, setSelectedTab, keyToActiveIndex, activeIndex, push: history.push
          })}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default UsersComponent;
