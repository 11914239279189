import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import { Link } from 'react-router-dom';
import { DWHUserCallsResponse } from '../../../interfaces/Dashboard';
import ListViewComponent from '../../ListView/ListViewComponent';
import { DashboardLoader } from '../DashboardLoader';
import * as styles from './Tables.scss';
import { TableTitle } from './TableTitle';
import { sortData, ViewName } from './TableUtils';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import { connect } from 'react-redux';
import { Global } from '../../../state/Global';

interface Props {
  userCallData: DWHUserCallsResponse;
}

const UserCalls = ({userCallData}: Props) => {
  const divId = 'userCalls';
  if (userCallData.isFetching) {
    return (
      <DashboardLoader divId={divId} title={intl.get('headerUserTotals')}/>
    );
  } else {
    const data = _get(userCallData, 'data.data', []);
    return (
      <div className={styles.container} id={divId}>
        <TableTitle title={intl.get('headerUserTotals')}/>
        <ListViewComponent
          data={data}
          columns={
            [{
              Header: intl.get('name'),
              accessor: 'name'
            }, {
              Header: intl.get('minutes'),
              accessor: 'totalMinutes',
            }, {
              Header: intl.get('calls'),
              accessor: 'totalCalls'
            }]
          }
          showPagination={false}
          minRows={5}
          maxRows={5}
          pageNumber={0}
        />
        <div className={styles.footer}>
          <Link id="view_all" to={'/drilldown/usersVideos/users'}>
            <span>{intl.get('viewAll')}</span>
            <Icon classes={['icon-back']} rotate={180} />
          </Link>
        </div>
      </div>
    );
  }

};

export default UserCalls;

function mapStateToProps(state: Global) {
  return {
    userCallData: sortData(dataWarehouseSelectors.usersGroupByCallsPersonal(state), ViewName.UserCalls),
  };
}

const mapDispatchToProps = (dispatch: Function) => ({});

export const UserCallsContainer = connect(mapStateToProps, mapDispatchToProps)(UserCalls);
