import ListViewComponent from 'components/ListView/ListViewComponent';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import SearchBar from 'containers/Search/SearchBar';
import React from 'react';
import { liveStreamsTabColumnConfig } from './LiveStreamsTabColumnConfig';

interface Props {
  error: string;
  fetchListData: Function;
  isFetching: boolean;
  listData: Array<object>; // TODO - set type for array
  numberOfPages: number;
  pageNumber: number;
  pageSize: number;
}

const LiveStreamsTab = (props: Props) => {
  const { error, fetchListData, isFetching, listData, numberOfPages, pageNumber, pageSize } = props;
  return (
      <ListViewLayout
        error={error}
        fetchListData={fetchListData}
        hideHeader={true}
        isFetching={false}
        numberOfPages={numberOfPages}
      >
        <SearchBar key="searchbar" />
        <div className="contentBody">
          <ListViewComponent
            columns={liveStreamsTabColumnConfig()}
            data={listData || []}
            loading={isFetching}
            maxRows={pageSize}
            pageNumber={pageNumber}
            showPagination={false}
          />
        </div>
      </ListViewLayout>
    );
};

export default LiveStreamsTab;
