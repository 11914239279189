import React, { useEffect, useState } from 'react';
import * as styles from './ConnectPlusGateway.scss';
import _memoize from 'lodash/memoize';
import { Button } from 'semantic-ui-react';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import intl from 'react-intl-universal';

import * as Yup from 'yup';

import { Formik, Field, Form } from 'formik';

const AZURE_CONNECTION_STRING = 'connect_gw_azure_comm_connection_string';
const AZURE_USERNAME = 'connect_gw_azure_comm_username';
const AZURE_PASSWORD = 'connect_gw_azure_comm_password';

const MICROSOFT_TENANT_ID = 'connect_gw_msgraph_tenant_id';
const MICROSOFT_CLIENT_ID = 'connect_gw_msgraph_client_id';
const MICROSOFT_CLIENT_SECRET = 'connect_gw_msgraph_client_secret';

export interface StateProps {
    connectPlusSettings: object;
}

export interface DispatchProps {
    fetchData: Function;
    updateConnectPlusSettings: Function;
}

const connectPlusGatewaySchema = _memoize(() => {
    return Yup.object().shape({
        [AZURE_CONNECTION_STRING]: Yup.string().trim().required(intl.get('connectPlusAzureStringRequired')),
        [AZURE_USERNAME]: Yup.string().trim().required(intl.get('connectPlusMicrosoftUserNameRequired')),
        [AZURE_PASSWORD]: Yup.string().trim().required(intl.get('connectPlusMicrosoftPasswordRequired')),
        [MICROSOFT_TENANT_ID]: Yup.string().trim().required(intl.get('connectPlusApplicationTenantIdRequired')),
        [MICROSOFT_CLIENT_ID]: Yup.string().trim().required(intl.get('connectPlusApplicationClientIdRequired')),
        [MICROSOFT_CLIENT_SECRET]: Yup.string().trim().required(intl.get('connectPlusApplicationClientSecretRequired'))
    });
});

type Props = StateProps & DispatchProps;

export const ConnectPlusGateway = (props: Props) => {

    const [inputData, setInputData] = useState({});
    const [loading, setLoading] = useState(true);

    const {
        connectPlusSettings,
        fetchData,
        updateConnectPlusSettings
    } = props;

    const asyncFetchData = async () => {
        await fetchData();
        setLoading(false);
    };

    useEffect(() => {
        asyncFetchData();
    },        []);

    useEffect(() => {
        setInputData(connectPlusSettings);
    },        [connectPlusSettings]);

    return (
        <Formik
            key={JSON.stringify(inputData)}
            initialValues={inputData}
            validationSchema={connectPlusGatewaySchema}
            onSubmit={async (formData, actions) => {
                const newSettings = {
                    'connect_gw_azure_comm_connection_string': formData[AZURE_CONNECTION_STRING],
                    'connect_gw_azure_comm_username': formData[AZURE_USERNAME],
                    'connect_gw_azure_comm_password': formData[AZURE_PASSWORD],
                    'connect_gw_msgraph_tenant_id': formData[MICROSOFT_TENANT_ID],
                    'connect_gw_msgraph_client_id': formData[MICROSOFT_CLIENT_ID],
                    'connect_gw_msgraph_client_secret': formData[MICROSOFT_CLIENT_SECRET],

                };
                await updateConnectPlusSettings(newSettings);
                actions.setSubmitting(false);
            }}
            render={({ values, handleChange, errors, touched, dirty, isSubmitting }) => (
                <Form>
                    <section className={styles.container}>
                        {
                            loading ?
                                <LoadingIndicator />
                                :
                                <>
                                    <article>
                                        <div className={styles.inputContainer}>
                                            <h6>{`${intl.get('connectPlusAdminAutoBotHeading')}`}</h6>
                                            <div className={styles.inputLable}>
                                                <label>{`${intl.get('connectPlusAzureCommunicationStringLabel')}:`} </label>
                                                <Field
                                                    className={styles.input}
                                                    name={AZURE_CONNECTION_STRING}
                                                    type="text"
                                                />
                                                {(touched && errors && touched[AZURE_CONNECTION_STRING] && errors[AZURE_CONNECTION_STRING]) &&
                                                    <div className={styles.errorContainer}>
                                                        {errors[AZURE_CONNECTION_STRING]}
                                                    </div>
                                                }
                                            </div>
                                            <div className={styles.spacer}/>
                                            <div className={styles.inputLable}>
                                                <label>{`${intl.get('connectPlusApplicationClientId')}:`} </label>
                                                <Field
                                                    className={styles.input}
                                                    name={MICROSOFT_CLIENT_ID}
                                                    type="text"
                                                />
                                                {(touched && errors && touched[MICROSOFT_CLIENT_ID] && errors[MICROSOFT_CLIENT_ID]) &&
                                                    <div className={styles.errorContainer}>
                                                        {errors[MICROSOFT_CLIENT_ID]}
                                                    </div>
                                                }
                                            </div>
                                            <div className={styles.inputLable}>
                                                <label>{`${intl.get('connectPlusApplicationClientSecret')}:`} </label>
                                                <Field
                                                    className={styles.input}
                                                    name={MICROSOFT_CLIENT_SECRET}
                                                    type="password"
                                                />
                                                {(touched && errors && touched[MICROSOFT_CLIENT_SECRET] && errors[MICROSOFT_CLIENT_SECRET]) &&
                                                    <div className={styles.errorContainer}>
                                                        {errors[MICROSOFT_CLIENT_SECRET]}
                                                    </div>
                                                }
                                            </div>
                                            <div className={styles.inputLable}>
                                                <label>{`${intl.get('connectPlusApplicationTenantId')}:`} </label>
                                                <Field
                                                    className={styles.input}
                                                    name={MICROSOFT_TENANT_ID}
                                                    type="text"
                                                />
                                                {(touched && errors && touched[MICROSOFT_TENANT_ID] && errors[MICROSOFT_TENANT_ID]) &&
                                                    <div className={styles.errorContainer}>
                                                        {errors[MICROSOFT_TENANT_ID]}
                                                    </div>
                                                }
                                            </div>
                                            <div className={styles.spacer}/>
                                            <div className={styles.inputLable}>
                                                <label>{`${intl.get('connectPlusMicrosoftUserName')}:`} </label>
                                                <Field
                                                    className={styles.input}
                                                    name={AZURE_USERNAME}
                                                    type="text"
                                                />
                                                {(touched && errors && touched[AZURE_USERNAME] && errors[AZURE_USERNAME]) &&
                                                    <div className={styles.errorContainer}>
                                                        {errors[AZURE_USERNAME]}
                                                    </div>
                                                }
                                            </div>
                                            <div className={styles.inputLable}>
                                                <label>{`${intl.get('connectPlusMicrosoftPassword')}:`} </label>
                                                <Field
                                                    className={styles.input}
                                                    name={AZURE_PASSWORD}
                                                    type="password"
                                                />
                                                {(touched && errors && touched[AZURE_PASSWORD] && errors[AZURE_PASSWORD]) &&
                                                    <div className={styles.errorContainer}>
                                                        {errors[AZURE_PASSWORD]}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.buttonContainer}>
                                            <Button
                                                disabled={isSubmitting || !dirty}
                                                id="btnSubmit"
                                                name="btnSubmit"
                                                role="button"
                                                type="submit"
                                            >
                                                {intl.get('update')}
                                            </Button>
                                        </div>
                                    </article>
                                </>
                        }
                    </section>
                </Form>
            )}
        />
    );

};

export default ConnectPlusGateway;
