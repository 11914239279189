export interface LiveStreamsState {
  data: { displayName: string }[];
  error: string | null;
  isFetching: boolean;
}

export const initialLiveStreamsState: LiveStreamsState = {
  data: [],
  error: null,
  isFetching: false
};
