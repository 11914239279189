import { groupSettingsSelectors, mmsApiActions } from '@lifesize/nucleus';
import _get from 'lodash/get';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { actions } from 'actions/modalActions';
import { Global } from 'state/Global';
import InviteUserModal from 'components/Modals/UserModal/InviteUserModal';

const mapStateToProps = (state: Global) => {
  const groupSettings = groupSettingsSelectors.groupSettings(state) || {};
  const groupSettingsLocale = _get(groupSettings, 'locale');
  const token = _get(state.nucleus, 'account.token', '');
  let invitationToken = (token) ? process.env.REACT_APP_WELCOME_URL : '';
  if (!!groupSettingsLocale) {
    invitationToken += (token)  ? `/${token}?lang=${groupSettingsLocale}` : '';
  } else {
    invitationToken += (token)  ? `/${token}` : '';
  }
  const emailBody = encodeURIComponent(
    `${intl.get('inviteBody1')}\n\n${invitationToken}\n\n${intl.get('inviteBody2')}\n\n${intl.get('inviteBody3')} https://www.lifesize.com/app-help\n\n${intl.get('inviteBody4')}`
  );
  const subject = encodeURIComponent(intl.get('inviteSubject'));
  return {
    emailBody,
    subject,
    invitationToken,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  getInvitationLink: (isRenew: boolean) => {
    dispatch(mmsApiActions.getInvitationLink(isRenew));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserModal);
