import { Middleware, MiddlewareAPI, Dispatch, Action } from 'redux';
import { FETCH_SSO_STATUS } from '../actions/ssoActions';
import { currentUserSelectors, authActions, ssoSelectors, userSettingsSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { SSO } from '../interfaces/SSO';
import { ListMeetingsQuery } from '../typings/types';
import MeetingInlineFragment = ListMeetingsQuery.MeetingInlineFragment;

const SSOMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return (action: Action) => {
        if (FETCH_SSO_STATUS !== action.type) {
          return next(action);
        }
        const state = store.getState();
        const ssoState: SSO = ssoSelectors.ssoStatus(state);
        const userState = userSettingsSelectors.userSettings(state);
        const userProfile: MeetingInlineFragment = currentUserSelectors.myInfo(state);
        const email = _get(userProfile, 'email', _get(userState, 'email', null ));
        if (!ssoState.loginSupportType && email) {
          return store.dispatch(authActions.ssoEmailCheck(email, null));
        }
      };
    };

export default (SSOMiddleware as Middleware);
