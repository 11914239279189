import React from 'react';
import { Role } from 'interfaces/Role';
import { RoleManagement } from 'components/RoleManagement/RoleManagement';
import { MeetingFeatures } from 'components/MeetingFeatures/MeetingFeatures';
import { useSelector } from 'react-redux';

import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_MODERATE_ALL_MEETINGS, FEATURE_FLAG_RECORD_ALL_MEETINGS } from 'constants/featureFlags';

export interface Props {
  role: Role;
}

export const RoleSettings = (props: Props) => {
  const { role } = props;

  const hasModerateAllMeetingsSetting = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_MODERATE_ALL_MEETINGS);
  const hasRecordMeetingsSetting = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_RECORD_ALL_MEETINGS);

  const showMeetingFeatures = hasModerateAllMeetingsSetting || hasRecordMeetingsSetting;

  return (
    <div>
      <RoleManagement role={role} />
      {showMeetingFeatures &&
        (<MeetingFeatures
          role={role}
        />)
      }
    </div>
  );
};
