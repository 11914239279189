import { Reducer } from 'redux';
import { MeetingsActionType } from 'actions/meetingsActions';
import { MeetingsState, initialMeetingsState } from 'state/Meetings';

const meetingsReducer: Reducer<MeetingsState> =
  (state = initialMeetingsState, action) => {
    switch (action.type) {
      case MeetingsActionType.FETCH_MEETINGS_AND_OWNERS_COMPLETE:
        return { ...state, meetingsAndOwnersList: action.payload };
      default:
        return state;
    }
  };

export default meetingsReducer;
