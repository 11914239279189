import { nucleusConstants } from '@lifesize/nucleus';
import _memoize from 'lodash/memoize';
import intl from 'react-intl-universal';
import { MEETING_NAME_SHORT_BREAK } from 'constants/meetingConstants';

const isOneTimeMeeting = _memoize((meetingType: string) => {
  return meetingType ? meetingType.trim().toUpperCase() === nucleusConstants.MEETING_TYPE_ONE_TIME : false;
});

const getMeetingTypeLabel = _memoize((meetingType: string) => {
  if (meetingType) {
    return isOneTimeMeeting(meetingType) ? intl.get('meetingTypeOneTime') : intl.get('meetingTypePermanent');
  }
  return null;
});

const getShortMeetingName = (meetingName: string) => {
  return meetingName.length > MEETING_NAME_SHORT_BREAK ? `${meetingName.substring(0, MEETING_NAME_SHORT_BREAK - 3)}...` : meetingName;
};

export {
  isOneTimeMeeting,
  getMeetingTypeLabel,
  getShortMeetingName
};
