import { Reducer } from 'redux';
import { PaginationState, initialPaginationState } from 'state/Pagination';
import { PaginationActionType } from 'constants/paginationConstants';

const paginationReducer: Reducer<PaginationState> =
  (state = initialPaginationState, action) => {
    switch (action.type) {
      case PaginationActionType.PAGINATION_SET_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: action.payload
        };
      default:
        return state;
    }
  };

export default paginationReducer;
