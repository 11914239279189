import React from 'react';
import intl from 'react-intl-universal';
import styles from './CopyMeetingDetails.scss';
import {
  customInviteActions
} from '@lifesize/nucleus';
import CopyToClipboard from 'components/CopyToClipboardButton/CopyToClipboardButton';
import { useDispatch } from 'react-redux';
import logger from 'utils/logger';
import TooltipFixedPosition from 'components/TooltipFixedPosition/TooltipFixedPosition';
import { LOCATION } from 'constants/tooltipFixedPositionConstants';

interface Props {
  extension: string;
}

const MeetingsHeader = (props: Props) => {
  const { extension } = props;
  const dispatch = useDispatch();

  const getInviteText = async () => {
    try {
      const invite = await dispatch(customInviteActions.getExtensionInviteText(extension, 'email'));
      return invite?.body;
    } catch (e) {
      logger.info(`Unable to get custom meeting invite text: ${e}`);
      return '';
    }
  };

  return (
    <div className={styles.container}>
        <CopyToClipboard
          getTextOnClick={getInviteText}
        />
        <TooltipFixedPosition
          style={{
            bottom: 'auto',
            position: 'fixed',
            top: '3px',
            left: 'unset',
            right: '60px',
          }}
          location={LOCATION.LEFT}
          label={intl.get('copyMeetingDetails')}
        />
    </div>
  );
};

export default MeetingsHeader;
