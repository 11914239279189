import { connect } from 'react-redux';
import { actions } from 'actions/modalActions';
import ConnectTabletComponent from 'components/Modals/ConnectTablet/ConnectTabletComponent';
import * as modalSelector from 'selectors/modalSelector';
import { Global } from 'state/Global';
import { RoomSystem } from 'typings/types';
import { isPlatformDash } from 'utils/roomSystemUtils';
import { APP_STORE_ROOM_CONTROLLER_DOWNLOAD_LINK, GOOGLE_PLAY_ROOM_CONTROLLER_DOWNLOAD_LINK } from 'constants/downloadConstants';
import { remotingConstants, remotingActions, remotingSelectors } from '@lifesize/clients.remoting';

const mapStateToProps = (state: Global) => {
  const pairingState = remotingSelectors.pairing.pairingState(state);
  const roomSystem = modalSelector.selectPayload(state) as RoomSystem;
  return {
    roomSystem,
    appStoreUrl: APP_STORE_ROOM_CONTROLLER_DOWNLOAD_LINK,
    googlePlayUrl: GOOGLE_PLAY_ROOM_CONTROLLER_DOWNLOAD_LINK,
    remoteLinkingCode: pairingState.remoteLinkingCode
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    closePendingSave: () => {
      dispatch(actions.closePendingSave());
    },
    generateLinkingCode: (platform: string, serialNumber: string) => {
      dispatch(remotingActions.pairing.generateLinkingCode(
        isPlatformDash(platform)
          ? remotingConstants.pairing.linkedDeviceTypes.tablet
          : remotingConstants.pairing.linkedDeviceTypes.gemsTablet,
        platform,
        serialNumber
      ));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectTabletComponent);
