import React from 'react';
import { Tooltip } from 'components/tooltip/Tooltip';
import Icon from 'components/Common/Icon';
import * as meetingCreationStyles from './MeetingCreationSettings.scss';

export interface Props {
  label: string;
  tipLabel: string;
}

const SettingToggleTooltip = ({ label, tipLabel }: Props) => (
  <div>
    <span key="label">{label}</span>
      <Tooltip key="tooltip" text={tipLabel} stylesWrapper={{ lineHeight: 1, marginLeft: '8px' }}>
        <Icon classes={['icon-info', meetingCreationStyles.tooltipButtonIcon]} />
      </Tooltip>
  </div>
);

export default SettingToggleTooltip;
