import { createAction } from '@lifesize/typescript-react-redux-utils';
import { LiveStream } from 'typings/types';

export enum MeetingsActionType {
  LIVE_STREAMS_MEETING_NAMES_REQUEST = 'LIVE_STREAMS_MEETING_NAMES_REQUEST',
  LIVE_STREAMS_MEETING_NAMES_SUCCESS = 'LIVE_STREAMS_MEETING_NAMES_SUCCESS',
  LIVE_STREAMS_MEETING_NAMES_FAILURE = 'LIVE_STREAMS_MEETING_NAMES_FAILURE',
  ADD_MEETING = 'ADD_MEETING',
  FETCH_MEETINGS_AND_OWNERS = 'FETCH_MEETINGS_AND_OWNERS',
  FETCH_MEETINGS_AND_OWNERS_COMPLETE = 'FETCH_MEETINGS_AND_OWNERS_COMPLETE',
}

export const actions = {
  fetchLiveStreamsMeetingNamesRequest: () => createAction(MeetingsActionType.LIVE_STREAMS_MEETING_NAMES_REQUEST),
  fetchLiveStreamsMeetingNamesSuccess: (payload: LiveStream[]) => createAction(MeetingsActionType.LIVE_STREAMS_MEETING_NAMES_SUCCESS, payload),
  fetchLiveStreamsMeetingNamesFailure: (payload: string) => createAction(MeetingsActionType.LIVE_STREAMS_MEETING_NAMES_FAILURE, payload),
  handleAddMeeting: (payload: object) => createAction(MeetingsActionType.ADD_MEETING, payload),
  fetchMeetingsAndOwners: () => createAction(MeetingsActionType.FETCH_MEETINGS_AND_OWNERS),
  fetchMeetingsAndOwnersSuccess: (payload: object) => createAction(MeetingsActionType.FETCH_MEETINGS_AND_OWNERS_COMPLETE, payload)
};

export default actions;
