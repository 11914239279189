import * as styles from 'components/Dashboard/Tables/Tables.scss';
import React from 'react';

export interface Props {
  title: string;
}

const TableTitle: React.FunctionComponent<Props> = (props) => {
  return (
    <div className={styles.header}>
      {props.title}
    </div>
    );
};

export { TableTitle };
