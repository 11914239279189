import React from 'react';
import { Column } from 'react-table';
import classes from './InfiniteTable.scss';

export const genericColumnConfig = (columnConfig: Column[]): Column[] => {
  return columnConfig.map(column => {
    return {
      Header: column.Header,
      accessor: 'emptyCell',
      Cell: () => <div className={classes.emptyCell} />,
      sortable: false
    };
  });
};

export const genericListData = (rows: number = 50) => {
  return Array(rows).fill({ emptyCell: null });
};
