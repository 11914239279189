import { ROLE_PERMISSION } from 'interfaces/Role';

export enum PermissionTypes {
  ACCOUNT_RECORDINGS = 'accountRecordings', // account has recordingStatus === 'PAID' or 'TRIAL'
  ACCOUNT_STREAMING = 'accountStreaming',
  IS_ADMIN = 'isAdmin',
  IS_SUPER_USER = 'isSuperUser',
  IS_USER = 'isUser',
  VIEW_PROFILE = 'viewProfile',
  ANY_RECORDINGS = 'anyRecordings',
  ANY_DASHBOARD = 'anyDashboard',
  ANY_DOWNLOADS = 'anyDownloads',
  VIEW_STREAMING = 'viewStreaming',
}

export const parentPermissions = {
  [ROLE_PERMISSION.MODIFY_USERS]: ROLE_PERMISSION.VIEW_USERS,
  [ROLE_PERMISSION.MODIFY_ROLES]: ROLE_PERMISSION.VIEW_ROLES,
  [ROLE_PERMISSION.MODIFY_ROOMS]: ROLE_PERMISSION.VIEW_ROOMS,
  [ROLE_PERMISSION.MODIFY_OWN_ROOMS]: ROLE_PERMISSION.VIEW_ROOMS,
  [ROLE_PERMISSION.MODIFY_MEETINGS]: ROLE_PERMISSION.VIEW_MEETINGS,
  [ROLE_PERMISSION.MODIFY_OWN_MEETINGS]: ROLE_PERMISSION.VIEW_MEETINGS,
  [ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_RECORDINGS]: ROLE_PERMISSION.VIEW_OWN_ACCOUNT_RECORDINGS,
  [ROLE_PERMISSION.MODIFY_OWN_RECORDINGS]: ROLE_PERMISSION.VIEW_OWN_RECORDINGS,
  [ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS]: ROLE_PERMISSION.VIEW_OWN_ACCOUNT_GENERAL_SETTINGS,
};
