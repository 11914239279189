import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { defaultRowCount } from 'constants/listViewLayoutConstants';
import SearchBar from 'containers/Search/SearchBar';
import { DWHCallDetailsResponseType } from 'interfaces/Dashboard';
import { formatIsoUtcDateTimeForDisplayDate, formatSecondsForDisplay } from 'utils/date-time-utils';
import ListViewComponent from 'components/ListView/ListViewComponent';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import DrilldownHeader from 'components/Drilldowns/Common/DrilldownHeader';

// props from Redux Store (mapStateToProps)
export interface StateProps {
  breadcrumbs?: Array<JSX.Element>;
  error:  string;
  isFetching: boolean;
  records: DWHCallDetailsResponseType[];
  handleExportToCsv: Function;
}

// props from Actions (mapDispatchToProps)
export interface DispatchProps {
  fetchUserData: Function;
}

// props passed in
export interface OwnProps {
  title: string | JSX.Element | undefined;
  selectedId: string;
}

type Props = StateProps & DispatchProps & OwnProps;

const UsageDetails = (props: Props) => {
  const { breadcrumbs, error, fetchUserData, isFetching, records, title, selectedId, handleExportToCsv } = props;

  useEffect(
    () => {
      fetchUserData({
        selectedId: selectedId
      });
    },
    []
  );

  return (
    <ListViewLayout
      breadcrumbs={breadcrumbs}
      error={error}
      isFetching={isFetching}
      numberOfPages={1}
      title={title}
    >
      <SearchBar />

      <DrilldownHeader
        fetchData={fetchUserData}
        onExportClick={() => handleExportToCsv(records)}
      />

      <>
        {isFetching &&
        <ListViewComponent
            data={records || []}
            columns={[
              {
                Header: intl.get('tableHeaderName'),
                accessor: 'displayName'
              },
              {
                Header: intl.get('tableHeaderStarted'),
                accessor: 'startedOn',
                Cell: (row) => formatIsoUtcDateTimeForDisplayDate(row.value)
              },
              {
                Header: intl.get('tableHeaderDevice'),
                accessor: 'deviceAlias'
              },
              {
                Header: intl.get('tableHeaderSource'),
                accessor: 'sourceIP'
              },
              {
                Header: intl.get('tableHeaderDestination'),
                accessor: 'destinationIP'
              },
              {
                Header: intl.get('tableHeaderDuration'),
                accessor: 'duration',
                Cell: (row) => formatSecondsForDisplay(row.value)
              }
            ]}
            showPagination={false}
            maxRows={defaultRowCount}
          // @ts-ignore
            pageNumber={undefined}
        />
        }
      </>
    </ListViewLayout>
  );

};

export default UsageDetails;
