import { connect } from 'react-redux';
import { pairedDevicesActions } from '@lifesize/nucleus';
import { actions } from '../../actions/modalActions';
import ConvertToPaidModal from '../../components/Modals/ConvertToPaidModal/ConvertToPaidModal';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  getDashLicenses: () => {
    dispatch(pairedDevicesActions.getDashLicenses());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConvertToPaidModal);
