import { connect } from 'react-redux';
import { iconActions } from '@lifesize/nucleus';
import { createStructuredSelector } from 'reselect';
import CustomWallPaperUploader from 'components/WallPaperPanel/CustomWallPaperUploader';
import * as actions from 'actions/wallpaperActions';
import { setIsCustomWallPaperEnabled, clearErrors } from 'actions/wallpaperActions';

import {
  selectImagePreviewURL,
  selectHasTypeError,
  selectFilename,
  selectHasDimensionsError,
  selectIsCustomWallPaperEnabled,
  selectSizeError,
} from 'selectors/wallpaperSelectors';

const mapStateToProps = createStructuredSelector({
  imagePreviewURL: selectImagePreviewURL(),
  hasTypeError: selectHasTypeError(),
  hasDimensionsError: selectHasDimensionsError(),
  hasSizeError: selectSizeError(),
  filename: selectFilename(),
  isCustomWallPaperEnabled: selectIsCustomWallPaperEnabled(),
});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchExistingUrl: (url: string) =>
    dispatch(iconActions.getExistingWallpaperUrl()),
  clearErrors: () => {
    dispatch(clearErrors());
  },
  setURL: (url: string) =>
    dispatch(actions.setURL(url)),
  onDrop: (accepted: File[],
           rejected: File[],
           event: React.DragEvent<HTMLDivElement>) => {
    dispatch(setIsCustomWallPaperEnabled(true));
    dispatch(clearErrors());
    dispatch(actions.onDrop(accepted, rejected, event));
  }

});

export default connect(mapStateToProps, mapDispatchToProps)(CustomWallPaperUploader);
