import { MediaStat, MediaStats } from 'interfaces/MeetingParticipant';
import _memoize from 'lodash/memoize';
import _isNumber from 'lodash/isNumber';
import intl from 'react-intl-universal';

const emptyField = '----';

export const decorateMediaStat = (
  mediaStat: MediaStat,
  label: string,
  locale: string
) => {
  if (!mediaStat) {
    return null;
  }
  const formattedLostPacketPercentage = _isNumber(
    mediaStat.lostPacketsPercentage
  )
    ? (mediaStat.lostPacketsPercentage / 100).toLocaleString(locale, {
        style: 'percent',
        minimumFractionDigits: 2
      })
    : emptyField;

  let bitrate = mediaStat.bitrate;
  if (typeof bitrate === 'string') {
    bitrate = parseInt(bitrate, 0);
  }

  return {
    ...mediaStat,
    label,
    frameRate: mediaStat.frameRate || emptyField,
    codec: mediaStat.codec || emptyField,
    bitrate: Math.floor(bitrate / 1000) || emptyField,
    lostPacketsPercentage: formattedLostPacketPercentage,
    jitterMs: mediaStat.jitterMs || emptyField,
    maxJitterAvg: emptyField,
    resolution: mediaStat.resolution ?
      intl.get('resolutionFormat', { width: mediaStat.resolution.width, height: mediaStat.resolution.height }) :
      emptyField,
  };
};

export const flattenCallStats = _memoize(
  (mediaStats: MediaStats, locale: string = 'en') => {
    const flatStats: Array<MediaStat> = [];
    Object.keys(mediaStats).forEach(key => {
      const mediaStat = mediaStats[key];
      if (mediaStat) {
        const decoratedSent = decorateMediaStat(
          mediaStat.sent,
          intl.get(key + 'Sent'),
          locale
        );
        if (decoratedSent) {
          flatStats.push(decoratedSent);
        }
        const decoratedReceived = decorateMediaStat(
          mediaStat.received,
          intl.get(key + 'Received'),
          locale
        );
        if (decoratedReceived) {
          flatStats.push(decoratedReceived);
        }
      }
    });
    return flatStats;
  }
);
