import { connect } from 'react-redux';
import UsersTable from 'components/Drilldowns/UsersAndVideo/UsersTable2';
import RoomSystemsTable from 'components/Drilldowns/UsersAndVideo/RoomSystemsTable2';
import CallUsageDetails from 'components/Drilldowns/UsersAndVideo/CallDetails';
import { CallHistory } from 'components/Drilldowns/CallHistory/CallHistory2';
import { selectFilterString } from 'selectors/filterSelectors';
import { selectPageNumber } from 'selectors/paginationSelectors';
import { Global } from 'state/Global';
import { selectDashboardState } from 'selectors/dashboardSelectors';

function mapStateToProps(state: Global) {
  const dashboardState = selectDashboardState(state);
  const pageNumber = selectPageNumber(state);
  const searchString = selectFilterString(state);

  return {
    dateStart: dashboardState.startDateMoment,
    dateEnd: dashboardState.endDateMoment,
    searchString,
    pageNumber
  };
}

const mapDispatchToProps = () => ({});

export const UsersTableContainer = connect(mapStateToProps, mapDispatchToProps)(UsersTable);
export const RoomSystemsTableContainer = connect(mapStateToProps, mapDispatchToProps)(RoomSystemsTable);
export const CallHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(CallHistory);
export const CallDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(CallUsageDetails);
