import { connect } from 'react-redux';
import { Global } from 'state/Global';
import paginationActions from 'actions/paginationActions';
import PaginationComponent from 'components/Pagination/Pagination';
import { selectPageNumber } from 'selectors/paginationSelectors';

export interface OwnPropsInterface {
  numOfPages: number;
  onPageChange?: (arg: number) => void;
}

const mapStateToProps = (state: Global, ownProps: OwnPropsInterface) => {
  return {
    numOfPages: ownProps.numOfPages,
    pageNumber: selectPageNumber(state),
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: OwnPropsInterface) => ({
  setPageNumber: (arg: { selected: number }) => {
    dispatch(paginationActions.setPageNumber(arg.selected));
    if (ownProps.onPageChange) {
      ownProps.onPageChange(arg.selected);
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginationComponent);
