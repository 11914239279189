import React, { useState } from 'react';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { useSelector } from 'react-redux';
import { defaultHideOTMeetingsSetting } from 'selectors/accountSelectors';
import intl from 'react-intl-universal';
import useSettingsChange from 'hooks/useSettingsChange';
import SettingToggleTooltip from './SettingToggleTooltip';
import { ACCOUNT_SETTING_HIDE_ONE_TIME_MEETINGS } from 'constants/accountSettingsConstants';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export interface Props {
  setSettingsChangeError: Function;
}

const DefaultHideOTMeetingsButton = (props: Props) => {
  const { setSettingsChangeError } = props;
  const defaultHideOTMeetings = useSelector(defaultHideOTMeetingsSetting);
  const [ defaultHideOTMeetingsState, setDefaultHideOTMeetingsState ] = useState(defaultHideOTMeetings);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const changeSettings = useSettingsChange();

  return (
    <ToggleButton
      classNameLabel={''}
      id={ACCOUNT_SETTING_HIDE_ONE_TIME_MEETINGS}
      label={<SettingToggleTooltip label={intl.get('hideOneTimeMeetings')} tipLabel={intl.get('hideOneTimeMeetingsTip')} />}
      leftLabel={false}
      hidenow={false}
      topLabel={false}
      disabled={!canEditSettings}
      field={{
        name: ACCOUNT_SETTING_HIDE_ONE_TIME_MEETINGS,
        value: defaultHideOTMeetingsState,
        onChange: () => {
          if (!canEditSettings) { return; }
          return changeSettings(
            ACCOUNT_SETTING_HIDE_ONE_TIME_MEETINGS,
            setDefaultHideOTMeetingsState,
            defaultHideOTMeetingsState,
            setSettingsChangeError
          );
        },
        onBlur: () => { return; }
      }}
    />
  );
};

export default DefaultHideOTMeetingsButton;
