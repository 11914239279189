import { redirectToLogout } from 'utils/url-utils';
import { Action, Dispatch, Middleware } from 'redux';
import {
  nucleusConstants
} from '@lifesize/nucleus';

const AuthenticationMiddleware = () =>
  (next: Dispatch) => {
    return (action: Action) => {
      if (nucleusConstants.LOGOUT_FINISHED !== action.type) {
        return next(action);
      }

      const element = document.getElementById('root');
      if (element && element.parentNode) {
        element.parentNode.removeChild(element);
      }
      redirectToLogout();

      return next(action);
    };
  };

export default (AuthenticationMiddleware as Middleware);
