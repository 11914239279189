import React from 'react';
import intl from 'react-intl-universal';
import { Logger } from 'logger';
import Modal from '../../Modal/ModalComponent/ModalComponent';
import * as styles from './RebootDeviceModal.scss';

interface Props {
  isBusy: boolean;
  closeModal: () => void;
  reboot: (serialNumber: string) => void;
  serialNumber: string;
  error?: string;
}

export const RebootDeviceModal = (props: Props) => {
  const { isBusy, error, closeModal, reboot, serialNumber } = props;

  return (
    <Modal
      className={styles.container}
      onSave={
        async () => {
          try {
            await reboot(serialNumber);
            closeModal();
          } catch (e) {
            Logger.info(`Error submitting reboot command:  ${e.toString()}`);
          }
        }
      }
      closeModal={closeModal}
      saveText={intl.get('reboot')}
      closeText={intl.get('cancel')}
      isSaveButtonBusy={isBusy}
      isSaveButtonDisabled={isBusy}
      hideSaveButton={false}
      errorMessage={error}
    >
      <div>
        <div className={styles.title}>{intl.get('rebootTitle')}</div>
        <div className={styles.body}>{intl.get('rebootBody')}</div>
      </div>
    </Modal>
  );
};

export default RebootDeviceModal;
