import React from 'react';
import intl from 'react-intl-universal';
import { DWHCallHistoryQueryResponseType } from 'interfaces/Dashboard';
import { exportToCsv } from 'utils/csv-utils';
import { parseCallHistoryDataForExport2 } from 'utils/exportUtils';
import { formatStringDateTimeForDisplay } from 'utils/date-time-utils';
import { getBreadcrumbs } from 'utils/nav-utils';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { ChildDataProps } from 'react-apollo';
import _get from 'lodash/get';
import { Link } from 'react-router-dom';
import { RowInfo, SortingRule } from 'react-table';
import { SortDirection } from 'constants/queryConstants';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import CallHistoryHeader from './CallHistoryHeader';
import { Logger } from 'logger';
import { SetSort } from 'interfaces/Tables';

interface Props {
  isFetching: boolean;
  error: string;
  startTimestamp: number;
  endTimestamp: number;
  searchString: string;
  pageNumber: number;
  fetchMoreRows: Function;
  setSort: (sort: SetSort) => void;
}

interface State {
  isExporting: boolean;
}

type CallHistoryResponse = {
  paginatedCallHistory: DWHCallHistoryQueryResponseType;
};

class CallHistory extends React.Component<ChildDataProps<Props, CallHistoryResponse>, State> {
  readonly state = {
    isExporting: false
  };

  renderBreadcrumbs = () => {
    return getBreadcrumbs([
      {
        label: intl.get('dashboard')
      },
      {
        label: intl.get('callHistory')
      }
    ]);
  }

  handleExportToCsv = async () => {
    this.setState({
      isExporting: true
    });
    while (this.hasMoreRecords(this.props.data)) {
      try {
        await this.props.fetchMoreRows();
      } catch (e) {
        Logger.info(`Error exporting Call History: ${JSON.stringify(e)}`);
        break;
      }
    }
    const exportData = _get(this.props.data, 'paginatedCallHistory.records', [])
      .map(parseCallHistoryDataForExport2);
    exportToCsv(intl.get('callHistory'), exportData);
    this.setState({
      isExporting: false
    });
  }

  onFetchData: (args: { sorted: Array<SortingRule> }) => void = ({
                                                                   sorted: [column],
                                                                 }) => {
    if (!column || !this.props.data) {
      return;
    }
    this.props.setSort({
      sortBy: column.id,
      order: column.desc ? SortDirection.DESC : SortDirection.ASC,
    });
  }

  /**
   * Determine if more data records are still being loaded.
   * Defaults to true until data load is complete
   */
  hasMoreRecords = (data: object) => {
    return !!_get(data, 'paginatedCallHistory.metadata.cursor', false);
  }

  render() {
    const { isExporting } = this.state;
    const {
      error,
      data: { loading, paginatedCallHistory } = {
        loading: false,
        paginatedCallHistory: null,
      },
    } = this.props;
    const listData = !loading ? _get(paginatedCallHistory, 'records', []) : [];

    return (
      <ListViewLayout
        hideHeader={false}
        isFetching={false}
        numberOfPages={0}
        error={_get(error, 'message', undefined)}
        title={intl.get('callHistory')}
        breadcrumbs={this.renderBreadcrumbs()}
      >
        <InfiniteTable
          columnConfig={
            [{
              Header: intl.get('name'),
              accessor: 'conferenceName',
              Cell: (row: RowInfo) => {
                const {conferenceName} = row.original;
                return (
                  <Link
                    to={{
                      pathname: '/drilldown/conferenceDetails',
                      conference: row.original
                    }}
                  >
                    {conferenceName}
                  </Link>
                );
              }
            }, {
              Header: intl.get('date'),
              accessor: 'timestamp',
              Cell: (row) => formatStringDateTimeForDisplay(row.value),
            }, {
              Header: intl.get('duration'),
              accessor: 'duration'
            }, {
              Header: intl.get('participants'),
              accessor: 'participantCount'
            }]
          }
          data={listData}
          defaultSort={[
            {
              id: 'timestamp',
              desc: true
            }
          ]}
          fetchMore={() => this.props.fetchMoreRows()}
          getTdProps={() => ({
            style: {
              alignItems: 'flex-start',
              paddingTop: '16px',
              paddingBottom: '16px',
              lineHeight: '12px'
            }
          })}
          getTheadTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          getTrProps={() => ({
            style: {
              paddingLeft: '5px'
            }
          })}
          hasMoreRecords={this.hasMoreRecords(this.props.data)}
          keyField={'timestamp'}
          loading={loading}
          onFetchData={this.onFetchData}
          renderHeader={() => (
            <CallHistoryHeader
              handleExportData={this.handleExportToCsv}
              isExporting={isExporting}
            />
          )}
          rowHeightOverride={44}
        />
      </ListViewLayout>
    );
  }
}

export { CallHistory };
