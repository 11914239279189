import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import * as styles from 'components/Settings/Sections.scss';
import * as logo_microsoft_teams from '../../../images/logo_microsoft_teams.svg';
import * as logo_slack from '../../../images/logo_slack.svg';

const integrateSlackUrl = 'https://slack-integration.lifesizecloud.com/slack/installation';
const integrateTeamsUrl = 'https://www.lifesize.com/help/admin-console/chat-integration/microsoft-teams';
const readGuideSlackUrl = 'https://www.lifesize.com/help/admin-console/chat-integration/slack';
const readGuideTeamsUrl = 'https://www.lifesize.com/help/admin-console/chat-integration/microsoft-teams';

class Chat extends React.PureComponent {
  render() {
    return (
      <section className={styles.container} data-test="settingsSection.integrations">
        <article>
          <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitleChatIntegrations')}</h6>
          <div className={styles.integration}>
            <img src={logo_microsoft_teams} />
            <p className={styles.description}>
              <strong>Microsoft Teams</strong>&nbsp;
              {intl.get('settingsSectionIntegrationMicrosoftTeams', {
                command1: '“@Lifesize”'
              })}&nbsp;
              <a href={readGuideTeamsUrl} target="_blank">
                {intl.get('settingsLinkLabelReadTheGuide')}
              </a>
            </p>
            <Button
              id="btnChatAddToTeams"
              data-test="sectionBtn.addToTeams"
              name="btnChatAddToTeams"
              onClick={() => window.open(integrateTeamsUrl, '_blank')}
              role="button"
              type="button"
            >
              {intl.get('settingsButtonLabelAddToTeams')}
            </Button>
          </div>
        </article>
        <article>
          <div className={styles.integration}>
            <img src={logo_slack} />
            <p className={styles.description}>
              <strong>Slack</strong>&nbsp;
              {intl.getHTML('settingsSectionIntegrationSlack', {
                command1: '/lifesize',
                command2: '/lifesize join'
              })}&nbsp;
              <a href={readGuideSlackUrl} target="_blank">
                {intl.get('settingsLinkLabelReadTheGuide')}
              </a>
            </p>
            <Button
              data-test="sectionBtn.addToSlack"
              name="btnChatAddToSlack"
              onClick={() => window.open(integrateSlackUrl, '_blank')}
              role="button"
              type="button"
            >
              {intl.get('settingsButtonLabelAddToSlack')}
            </Button>
          </div>
        </article>
      </section>
    );
  }
}

export default Chat;
