import { connect } from 'react-redux';
import { Global } from 'state/Global';
import AlexaRegistrationSetting from 'components/AlexaRegistrationSetting/AlexaRegistrationSetting';
import { actions as ModalActions } from 'actions/modalActions';
import { ModalTypes } from 'constants/modalConstants';
import alexaIntegrationActions from 'actions/alexaIntegrationActions';
import alexaIntegrationThunks from 'thunks/alexaIntegrationThunks';
import { selectAlexaDevices } from 'selectors/alexaIntegrationSelector';
import { DeviceRegistration } from 'constants/alexaIntegrationConstants';
import { DeviceState } from 'interfaces/AlexaIntegration';

interface Props {
  serialNumber: string;
}

const defaultDeviceState = {
  profileArn: null,
  roomArn: null,
  deviceArn: null,
  state: DeviceRegistration.UNKNOWN
};

const mapStateToProps = (state: Global, ownProps: Props) => ({
  device: selectAlexaDevices(state)[ownProps.serialNumber] || defaultDeviceState
});

const mapDispatchToProps = (dispatch: Function, ownProps: Props) => ({
  openRegisterModal: async () => dispatch(ModalActions.openModal({ modalType: ModalTypes.ALEXA_REGISTER, payload: { serialNumber: ownProps.serialNumber } })),
  openUnregisterModal: async () => dispatch(ModalActions.openModal({ modalType: ModalTypes.ALEXA_UNREGISTER, payload: { serialNumber: ownProps.serialNumber } })),
  deviceTimeoutFailure: (device: DeviceState) => dispatch(alexaIntegrationActions.getDeviceStateFailure(ownProps.serialNumber)),
  getDevice: async () => dispatch(alexaIntegrationThunks.getDeviceRegistration(ownProps.serialNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlexaRegistrationSetting);
