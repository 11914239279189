import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { actions, UserProfileActionType } from 'actions/userProfileActions';
import getUserByUUID from 'queries/users/getUserProfileByUUIDQuery.gql';
import _get from 'lodash/get';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Logger } from '../logger';
import { getClient } from '../nucleus-apollo-client';

/**
 * Middleware for use with GraphQL data source.
 * Not to be confused with user settings state on Nucleus
 */
const userProfileMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return async (action: PayloadAction<string, object>) => {
        if (action.type === UserProfileActionType.FETCH_USER_PROFILE) {
          try {
            const uuid = _get(action, 'payload.userUuid');
            const client = await getClient(store.dispatch);
            const response = await client.query({
              query: getUserByUUID,
              fetchPolicy: 'no-cache',
              variables: { uuid: uuid }
            });
            await next(actions.fetchUserProfileSuccess(_get(response, 'data.userByUUID', {})));
            const successHandlerFunction = _get(action, 'payload.successHandler');
            if (successHandlerFunction) {
              // Optional callback action to perform after fetch of user profile data
              // Allows middleware re-use for other purposes
              successHandlerFunction();
            }
          } catch (error) {
            const errorDescription = _get(error, 'errorDescription', JSON.stringify(error));
            Logger.info(`Load user profile from GraphQL error :  ${errorDescription}`);
            next(actions.fetchUserProfileFailure(error));
          }
        }
        return next(action);
      };
    };
  };

export default (userProfileMiddleware as Middleware);
