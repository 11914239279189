import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { selectLocale } from 'selectors/languageSelectors';
import { Moment } from 'moment';
import actions from 'actions/dashboardActions';
import { actions as LiveStreamActions } from 'actions/liveStreamActions';
import { liveStreamV2Cutoff } from 'utils/liveStreamUtils';
import _get from 'lodash/get';
import DashboardTab from 'components/DashboardNew/DashboardTab/DashboardTab';
import { dataWarehouseSelectors, accountSelectors, featureSelectors } from '@lifesize/nucleus';
import {
  LIVE_STREAM_URL,
} from 'constants/dashboard';
import { FEATURE_FLAG_ENABLE_PAST_MEETINGS } from 'constants/featureFlags';
import * as dashboardSelectors from 'selectors/dashboardSelectors';
import { currentOldLiveStreamsSelector } from 'selectors/liveStreamHistorySelector';
import { ACCOUNT_FEATURE_STREAMING } from 'constants/accountFeatureConstants';

const mapStateToProps = (state: Global) => {
  const accountFeatures = accountSelectors.getAccountFeatures(state) || {};
  const showLiveStreaming = _get(accountFeatures, ACCOUNT_FEATURE_STREAMING, false); // turned on by ???
  const showCallHistoryWidget = !featureSelectors.getFeatureFlag(state)(FEATURE_FLAG_ENABLE_PAST_MEETINGS);
  const dataState = dataWarehouseSelectors.streamsByList;
  const isFetching = dataState(state).isFetching;
  const startDate = dashboardSelectors.selectStartDateMoment;
  const endDate = dashboardSelectors.selectEndDateMoment;
  const selectedStartDate = startDate(state);

  const currentData = selectedStartDate.isBefore(liveStreamV2Cutoff) ? currentOldLiveStreamsSelector(state) : [];

  const liveStream = showLiveStreaming && {
    liveStreamHistoryUrl: LIVE_STREAM_URL,
    loading: isFetching,
    data: {
      liveStreamHistory: currentData
    },
    error: ''
  };

  const dateStart = dashboardSelectors.selectStartDateMoment(state);
  const selectedEndDate = endDate(state);
  const hasNewData = !selectedEndDate.isBefore(liveStreamV2Cutoff);

  return {
    currentLocale: selectLocale(state),
    dateStart,
    dateEnd: dashboardSelectors.selectEndDateMoment(state),
    hasOldData: dateStart.isBefore(liveStreamV2Cutoff),
    liveStream,
    hasNewData,
    showLiveStreaming,
    showCallHistoryWidget
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onDateChange: (dateStart: Moment, dateEnd: Moment) => {
    dispatch(actions.handleSetDateRangeMoment(
      {
        startDate: dateStart,
        endDate: dateEnd
      }
    ));
  },
  fetchListData: () => {
    dispatch(LiveStreamActions.fetchLiveStreamHistory());
  },
});

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DashboardTab);
