import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import {
  currentUserSelectors,
  authActions,
  nucleusConstants
} from '@lifesize/nucleus';
import _get from 'lodash/get';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';

const MergeAccountMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return (action: PayloadAction<string, string>) => {
        if (action.type !== nucleusConstants.GALAXY_WAMP_SUBSCRIPTION_EVENT_CALL) {
          return next(action);
        }

        const state = store.getState();
        const userUUID = _get(currentUserSelectors.myInfo(state), 'userUuid');
        if (
          !userUUID ||
          _get(action, 'payload.userUUID') !== userUUID ||
          _get(action, 'payload.eventType') !== 'USER_MERGED') {
            return next(action);
        }

        return store.dispatch(authActions.logout());
      };
    };

export default (MergeAccountMiddleware as Middleware);
