import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const selectWallPaperPanelState = (state: Global) => state.app?.wallpaper;

const selectIsCustomWallPaperEnabled = () => createSelector(
  selectWallPaperPanelState,
  (wallpaper) => wallpaper.isEnabled
);

const selectImagePreviewURL = () => createSelector(
  selectWallPaperPanelState,
  (wallpaper) => wallpaper.imagePreviewURL
);

const selectHasTypeError = () => createSelector(
  selectWallPaperPanelState,
  (wallpaper) => wallpaper.hasTypeError
);

const selectHasDimensionsError = () => createSelector(
  selectWallPaperPanelState,
  (wallpaper) => wallpaper.hasDimensionsError
);

const selectSizeError = () => createSelector(
  selectWallPaperPanelState,
  (wallpaper) => wallpaper.hasSizeError
);

const selectFilename = () => createSelector(
  selectWallPaperPanelState,
  (wallpaper) => wallpaper.filename
);

export {
  selectIsCustomWallPaperEnabled,
  selectImagePreviewURL,
  selectHasTypeError,
  selectHasDimensionsError,
  selectSizeError,
  selectFilename,
};
