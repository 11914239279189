import { Global } from 'state/Global';

export const getDownloadNotificationToastId = (state: Global) => {
  return state.app?.downloadNotifications?.notificationId;
};

export const getDownloadNotificationCount = (state: Global) => {
  return state.app?.downloadNotifications?.downloadCounter;
};

export const getPendingUserExports = (state: Global) => {
  return state.app?.downloadNotifications?.pendingDownloads;
};
