import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { VideoProperties } from 'interfaces/VideoProperties';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import EditRecordingForm from './EditRecordingForm';
import { FormValues } from './FormFields';
import * as formStyles from 'components/Modals/ModalForms.scss';

export interface UserByUUIDType {
  UUID: string;
  email: string;
  displayName: string;
  loading: boolean;
}

export interface Props {
  closeModal: () => void;
  currentUserName: string;
  error?: object;
  ownerId: string;
  meetingName: string;
  meetingId: string;
  fetchMeetingsAndOwners: () => void;
  isAdminOrSuperUser: boolean;
  isBusy: boolean;
  meetingsAndOwners: [];
  recordingId: string;
  resetErrorState: () => void;
  setVideoProperties: (recordingId: string, videoProps: VideoProperties) => void;
  videoProperties: VideoProperties | null;
  ownerData: UserByUUIDType;
}

const EditRecordingModal = (props: Props) => {
  const {
    recordingId,
    setVideoProperties,
    fetchMeetingsAndOwners,
    closeModal,
    currentUserName,
    error,
    isBusy,
    meetingsAndOwners,
    resetErrorState,
    videoProperties,
    meetingName,
    meetingId,
    ownerData,
  } = props;

  useEffect(
    () => {
      fetchMeetingsAndOwners();
    },
    []
  );

  const handleSubmit = (values: FormValues) => {
    if (recordingId && videoProperties) {
      setVideoProperties(recordingId, {
        default_meeting: videoProperties.default_meeting,
        description: values.description,
        dry_run: false, // vals.dry_run - If we need to do permissions warning modal, uncomment
        is_default: videoProperties.is_default,
        meeting: values.meeting,
        name: values.name,
        owner: values.owner
      });
    }
  };

  return (
    <>
      {!ownerData.loading &&
      <Modal
          className={formStyles.containerLarge}
          closeModal={closeModal}
          hideFooter={true}
          titleText={intl.get('editRecordingTitle')}
      >
        <EditRecordingForm
            closeModal={closeModal}
            currentUserName={currentUserName}
            data={videoProperties}
            error={error}
            meetingName={meetingName}
            meetingId={meetingId}
            isBusy={isBusy}
            meetingsAndOwners={meetingsAndOwners}
            onSubmit={handleSubmit}
            resetErrorState={resetErrorState}
        />
      </Modal>
      }
    </>
  );
};

export default EditRecordingModal;
