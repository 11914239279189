import Icon from 'components/Common/Icon';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import * as styles from './DownloadsButton.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import exportThunks from 'thunks/exportThunks';
import { getDownloadNotificationCount, getPendingUserExports } from 'selectors/downloadNotificationSelectors';
import { useInterval } from 'hooks/useInterval';
import { exportsLoadingSelector, exportsSelector } from 'selectors/exportSelectors';
import { DirectoryExportStatus, ExportStatus, ExportType } from 'interfaces/Downloads';
import downloadNotificationActions from 'actions/downloadNotificationActions';
import Badge from 'components/Badge/Badge';

export const DownloadsButton = () => {
  const dispatch = useDispatch();
  const completeDownloadsCount = useSelector(getDownloadNotificationCount);
  const pendingUserExports = useSelector(getPendingUserExports);
  const exports = useSelector(exportsSelector);
  const exportsLoading = useSelector(exportsLoadingSelector);

  useEffect(
    () => {
      dispatch(exportThunks.getExports());
    },
    []
  );

  useInterval(
    () => {
      const completedDownloads: string[] = [];
      const failedDownloads: string[] = [];
      let hasPendingConference: boolean = false;

      pendingUserExports.forEach((id: string) => {
        const existingExport = exports.find(exportInstance => exportInstance.id === id);
        if (existingExport?.type === ExportType.CONFERENCE) {
          hasPendingConference = true;
        }
        if (existingExport?.status === ExportStatus.SUCCESS || existingExport?.status === DirectoryExportStatus.COMPLETED) {
          completedDownloads.push(id);
        } else if (existingExport?.status === ExportStatus.ERROR || existingExport?.status === DirectoryExportStatus.FAILED) {
          failedDownloads.push(id);
        }
      });
      if (failedDownloads.length) {
        dispatch(downloadNotificationActions.removeFailedDownloads(failedDownloads));
      }
      if (completedDownloads.length && !exportsLoading) {
        dispatch(downloadNotificationActions.removePendingDownloads(completedDownloads));
      }
      if (hasPendingConference) {
        dispatch(exportThunks.getExports());
      }
    },
    6000,
    [pendingUserExports, exports, exportsLoading]
  );

  return (
    <NavLink
      className={styles.downloads}
      to={'/downloads'}
      {...{ ['data-test']: 'navLink.downloads' }}
    >
      <Icon classes={['icon-download']} />
      {!!completeDownloadsCount && <Badge className={styles.pendingBadge} count={completeDownloadsCount} />}
    </NavLink>
  );
};

export default withRouter(DownloadsButton);
