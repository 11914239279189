import { nucleusConstants } from '@lifesize/nucleus';
import { Reducer } from 'redux';
import { actions } from 'actions/maxInactiveDaysActions';
import { MaxInactiveDaysState, initialMaxInactiveDaysState } from 'state/MaxInactiveDaysState';

const maxInactiveDaysReducer: Reducer<MaxInactiveDaysState> =
  (state = initialMaxInactiveDaysState, action) => {
    switch (action.type) {
      case nucleusConstants.GET_MEETING_INACTIVE_DAYS_SUCCESS:
        return {
          ...state,
          maxInactiveDays: action?.payload?.maxInactiveDays || 0
        };
      case actions.getItemsToBeDeleted.pending:
        return {
          ...state,
          isBusyFetchingMeetingsToDelete: true
        };
      case actions.getItemsToBeDeleted.fulfilled:
      case actions.getItemsToBeDeleted.rejected:
        return {
          ...state,
          isBusyFetchingMeetingsToDelete: false
        };
      default:
        return state;
    }
  };

export default maxInactiveDaysReducer;
