import _memoize from 'lodash/memoize';
import React from 'react';
import { getOnlineStatusDisplayText, isOfflineStatus } from 'utils/roomSystemUtils';
import * as styles from './OnlineStatus.scss';

interface Props {
  regStatus?: string;
}

/**
 * There are only 3 real states for this component, so
 * memoizing since this can be rendered a lot in the table
 */
const getClassName = _memoize((regStatus: string | undefined) => {
  if (isOfflineStatus(regStatus)) {
    return [styles.statusIndicator, styles.offline].join(' ');
  }
  return [styles.statusIndicator, styles.online].join(' ');
});

export const OnlineStatus: React.FunctionComponent<Props> = ({regStatus}) => {
  return (
    <div className={styles.statusContainer}>
      <div
        className={getClassName(regStatus)}
      />
      <div className={styles.statusText}>
        {getOnlineStatusDisplayText(regStatus)}
      </div>
    </div>
  );
};
