import { Checkbox, Form as uiForm } from 'semantic-ui-react';
import * as styles from 'components/Settings/Sections.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export interface LayoutProps {
  images: string[];
  layoutType: number;
  setFieldValue: (key: string, value: number) => void;
  meetingLayout: number;
  label: string;
}

const LayoutOption = ({ setFieldValue, meetingLayout, images, label, layoutType }: LayoutProps) => {
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  return (
    <>
      <uiForm.Field className={styles.optionGroup}>
        <Checkbox
          checked={meetingLayout === layoutType}
          label={label}
          name="meetingLayout"
          onChange={() => {
            if (!canEditSettings) { return; }
            setFieldValue('meetingLayout', layoutType);
          }}
          radio={true}
          value={layoutType}
          disabled={!canEditSettings}
        />
      </uiForm.Field>
      <div className={styles.layoutImages}>
        {images.map((image) => <img src={image} key={image}/>)}
      </div>
    </>
  );
};

export default LayoutOption;
