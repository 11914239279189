import classes from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import styles from './WaitButton.scss';

interface Props {
  buttonText?: string;
  className?: string;
  ['data-test']?: string;
  handleButtonAction?: (args: object) => void;
  id?: string;
  isBusy: boolean;
  isDisabled?: boolean;
  isSubmitButton?: boolean;
  onSave?: (args: object) => void;
  type: ButtonType;
}

export enum ButtonType {
  button = 'button',
  submit = 'submit'
}

const WaitButton: React.FunctionComponent<Props> = (props) => {
  const { type, isBusy, isDisabled, isSubmitButton, onSave, buttonText, className, id } = props;
  const testProp = props['data-test'] ? { ['data-test']: props['data-test'] } : {};
  return (
    <Button
      className={classes([isBusy && styles.busy, className])}
      disabled={isSubmitButton ? isDisabled : false}
      id={id ? id : 'primaryFooterBtn'}
      loading={isBusy && !isDisabled}
      name={id ? id : 'primaryFooterBtn'}
      onClick={
        (event: React.MouseEvent<HTMLButtonElement>) => {
          if (onSave) {
            onSave(event);
          }
        }
      }
      primary={true}
      role="button"
      size={'medium'}
      type={type}
      {...testProp}
    >
      {isBusy ? null : buttonText || intl.get('modalSave')}
    </Button>
  );
};

export { WaitButton };
