import { Reducer } from 'redux';
import { nucleusConstants } from '@lifesize/nucleus';
import { PermissionsState, initialPermissionsState } from 'state/Permissions';
import { PermissionTypes } from 'constants/permissionsConstants';
import { ROLE_PERMISSION } from '../interfaces/Role';

const elevatedPermissions = {
  [PermissionTypes.ANY_DASHBOARD]: true,
  [ROLE_PERMISSION.VIEW_USERS]: true,
  [ROLE_PERMISSION.VIEW_MEETINGS]: true,
  [PermissionTypes.VIEW_PROFILE]: true,
  [ROLE_PERMISSION.VIEW_OWN_ACCOUNT_GENERAL_SETTINGS]: true,
  [ROLE_PERMISSION.VIEW_ROOMS]: true,
  [PermissionTypes.IS_SUPER_USER]: true,
};

const PermissionsReducer: Reducer<PermissionsState> =
  (state = initialPermissionsState, action) => {
    switch (action.type) {
      case nucleusConstants.GET_SESSION_SUCCESS:
        const role = action?.payload?.session?.role;
        switch (role) {
          case nucleusConstants.USER_ROLE_USER:
            return {
              ...state,
              [PermissionTypes.ANY_DASHBOARD]: false,
              [ROLE_PERMISSION.VIEW_USERS]: false,
              [ROLE_PERMISSION.VIEW_MEETINGS]: false,
              [PermissionTypes.VIEW_PROFILE]: true,
              [ROLE_PERMISSION.VIEW_OWN_ACCOUNT_GENERAL_SETTINGS]: false,
              [ROLE_PERMISSION.VIEW_ROOMS]: false,
              [PermissionTypes.IS_SUPER_USER]: false,
              [PermissionTypes.IS_ADMIN]: false,
              [PermissionTypes.IS_USER]: true
            };
          case nucleusConstants.USER_ROLE_SUPER_USER:
            return {
              ...state,
              ...elevatedPermissions,
              [PermissionTypes.IS_ADMIN]: false,
            };
          case nucleusConstants.USER_ROLE_ADMIN:
            return {
              ...state,
              ...elevatedPermissions,
              [PermissionTypes.IS_ADMIN]: true,
            };
          default:
            return state;
        }
      default:
        return state;
    }
  };

export default PermissionsReducer;
