import React, { SyntheticEvent } from 'react';
import { nucleusConstants, accountSelectors, featureSelectors } from '@lifesize/nucleus';
import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import { DUPLICATE_MEETING_NAME_ERROR, OTHER_MEETING_NAME_ERROR, MEETING_NAME_MAX_LENGTH } from 'constants/meetingConstants';
import RadioButton from 'components/RadioButton/RadioButton';
import RadioButtonGroup from 'components/RadioButton/RadioButtonGroup';
import * as formStyles from 'components/Modals/ModalForms.scss';
import { MEETING_FORM_FIELD } from 'components/Modals/MeetingsModal/FormFields';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { Tooltip } from 'components/tooltip/Tooltip';
import Icon from 'components/Common/Icon';
import { useSelector } from 'react-redux';
import {
  defaultHideOTMeetingsSetting,
  defaultHidePermanentMeetingsSetting
} from 'selectors/accountSelectors';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { FEATURE_FLAG_WAITING_ROOM } from 'constants/featureFlags';

interface Props {
  canCreateOneTimeMeeting: boolean;
  errors: FormikErrors<FormikValues>;
  handleChange: Function;
  isAdd: boolean;
  setFieldValue: Function;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  visible: boolean;
}

// With HTML formatting for line wrap
const getNameError = (error: string, duplicate: string = '') => {
  if (error === DUPLICATE_MEETING_NAME_ERROR) {
    return intl.getHTML('duplicateMeetingNameError', { value: duplicate });
  }
  if (error === OTHER_MEETING_NAME_ERROR) {
    return intl.getHTML('nameMeetingError');
  }
  return error;
};

const GeneralTab = (props: Props) => {
  const {
    canCreateOneTimeMeeting,
    errors,
    handleChange,
    isAdd,
    setFieldValue,
    touched,
    values,
    visible
  } = props;

  const showWaitingRoom = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_WAITING_ROOM);
  const accountSettings = useSelector(accountSelectors.getAccountSettings);
  const defaultHideOTMeetings = useSelector(defaultHideOTMeetingsSetting);
  const defaultHidePermanentMeetings = useSelector(defaultHidePermanentMeetingsSetting);
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  const meetingsRequirePasscode = accountSettings?.requireMeetingPasscode;
  const meetingTypeTouched = touched.meetingType as boolean; // _get(touched, 'meetingType',  false);
  const meetingTypeError = errors.meetingType; // _get(errors, 'meetingType',  undefined);
  const nameTouched = touched.name;
  const nameError = errors.name;
  const pinTouched = touched.pin;
  const pinError = errors.pin;
  const descriptionTouched = touched.description;
  const descriptionError = errors.description;

  const handleMeetingTypeChange = (event: SyntheticEvent) => {
    if (!canEditMeetings) { return; }
    const input = event.currentTarget as HTMLInputElement;
    if (values.meetingType !== input.value) {
      const hiddenValue = input.value === nucleusConstants.MEETING_TYPE_ONE_TIME ? defaultHideOTMeetings : defaultHidePermanentMeetings;
      setFieldValue(MEETING_FORM_FIELD.HIDDEN, hiddenValue);
      handleChange({ target: { name: 'meetingType', value: input.value }});
    }
  };

  return (
    <>
      <div className={formStyles.logicalGroup}>
        {isAdd &&
        <div className={formStyles.formGroup}>
          <label className={formStyles.labelLarge}>{intl.get('meetingType')}</label>
          <RadioButtonGroup
              className={formStyles.inputContainer}
              id="meetingType"
              value={values.meetingType}
              error={meetingTypeError as string}
              touched={meetingTypeTouched}
          >
            {canCreateOneTimeMeeting &&
              <Field
                component={RadioButton}
                hidenow={!visible}
                id={nucleusConstants.MEETING_TYPE_ONE_TIME}
                label={intl.get('oneTimeMeeting')}
                name={MEETING_FORM_FIELD.MEETING_TYPE}
                disabled={!canEditMeetings}
                onChange={handleMeetingTypeChange}
              />}
            <Field
                component={RadioButton}
                hidenow={!visible}
                id={nucleusConstants.MEETING_TYPE_VMR}
                label={intl.get('permanentMeeting')}
                name={MEETING_FORM_FIELD.MEETING_TYPE}
                disabled={!canEditMeetings}
                onChange={handleMeetingTypeChange}
            />
          </RadioButtonGroup>
        </div>
        }

        <div id="name" className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
          <label className={formStyles.labelLarge}>{intl.get('meetingName')}</label>
          <div className={formStyles.inputContainer}>
            <Field
              className={formStyles.input}
              maxLength={MEETING_NAME_MAX_LENGTH}
              name={MEETING_FORM_FIELD.NAME}
              disabled={!canEditMeetings}
              type="text"
            />
            <ul className={formStyles.inputMessages}>
              {!!(nameTouched && nameError) && (
                <li className={formStyles.error}>{getNameError(nameError.toString(), values.name)}</li>
              )}
            </ul>
          </div>
        </div>

        <div id="passcode" className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
          <label className={formStyles.labelLarge}>{meetingsRequirePasscode ? intl.get('passcode_required') : intl.get('passcode_optional')}</label>
          <div className={formStyles.inputContainer}>
            <Field
              className={formStyles.input}
              type="text"
              name={MEETING_FORM_FIELD.PIN}
              disabled={!canEditMeetings}
            />
            <ul className={formStyles.inputMessages}>
              {!!(pinTouched && pinError) && (
                <li className={formStyles.error}>{errors.pin}</li>
              )}
            </ul>
          </div>
        </div>

        <div id="description" className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
          <label className={formStyles.labelLarge}>{intl.get('description_optional')}</label>
          <div className={formStyles.inputContainer}>
            <Field
              className={formStyles.textarea}
              component="textarea"
              name={MEETING_FORM_FIELD.DESCRIPTION}
              disabled={!canEditMeetings}
            />
            <ul className={formStyles.inputMessages}>
              {!!(descriptionTouched && descriptionError) && (
                <li className={formStyles.error}>{descriptionError}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className={formStyles.logicalGroup}>
        {showWaitingRoom && (
          <div className={formStyles.formGroup}>
            <Field
              classNameLabel={formStyles.toggleLabelLarge}
              component={ToggleButton}
              id={MEETING_FORM_FIELD.WAITING_ROOM}
              disabled={!canEditMeetings}
              label={intl.get('waitingRoom')}
              leftLabel={true}
              name={MEETING_FORM_FIELD.WAITING_ROOM}
            />
          </div>
        )}
        <div className={formStyles.formGroup}>
          <Field
              classNameLabel={formStyles.toggleLabelLarge}
              component={ToggleButton}
              id={MEETING_FORM_FIELD.HIDDEN}
              disabled={!canEditMeetings}
              label={(
                <>
                  <span>{intl.get('inputLabelHideMeeting')}</span>
                  <Tooltip text={intl.get('tooltipHideMeeting')} stylesWrapper={{ lineHeight: 1, marginLeft: '8px' }}>
                    <Icon classes={['icon-info', formStyles.tooltipButtonIcon]}/>
                  </Tooltip>
                </>
              )}
              leftLabel={true}
              name={MEETING_FORM_FIELD.HIDDEN}
          />
        </div>
      </div>
    </>
  );
};

export default GeneralTab;
