import React from 'react';
import { useSelector } from 'react-redux';
import { selectPayload } from 'selectors/modalSelector';
import { MeetingUserPayload } from 'interfaces/User';
import ContactSearchSelection from 'components/ContactSearchSelection/ContactSearchSelection';
import intl from 'react-intl-universal';
import listAllUsersAndRoomsQuery from 'queries/joined/listAllUsersAndRoomSystemsQuery.gql';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { DummyField } from './DummyField';

export interface Props {
  isEditingExistingMeeting?: boolean;
  handleChange: Function;
}

export const LecturerField = (props: Props) => {
  const { handleChange, isEditingExistingMeeting } = props;
  const selectedMeeting = useSelector(selectPayload) as MeetingUserPayload;
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  const { lecturer } = selectedMeeting;

  const currentSelection = isEditingExistingMeeting ?
    { id: lecturer?.UUID || '', title: lecturer?.displayName || '' } :
    { id: '', title: '' };

  if (!canEditMeetings) {
    return (
      <DummyField
        value={currentSelection.title}
      />
    );
  }

  return (
    <ContactSearchSelection
      placeholder={intl.get('meetingLecturer')}
      onChange={(selection: object) => handleChange({ target: { name: 'lecturer', value: selection }})}
      currentSelection={currentSelection}
      isOptional={true}
      query={listAllUsersAndRoomsQuery}
    />
  );
};
