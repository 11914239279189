import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as modalFormStyles from 'components/Modals/ModalForms.scss';
import { useDispatch } from 'react-redux';
import { deleteAccount } from 'actions/accountActions';
import { useMount } from 'hooks/useMount';
import { isSuccessfulAction } from 'utils/actionUtils';
import Logger from 'utils/logger';
import { redirectToSignUp } from 'utils/url-utils';

interface Props {
  closeModal: () => void;
}

export const DeleteAccountConfirmationModal = (props: Props) => {
  const { closeModal } = props;
  const [ error, setError ] = useState<boolean | null>(null);
  const [ isBusy, setBusy ] = useState(false);
  const dispatch = useDispatch();
  const isMounted = useMount();

  const handleDeleteAccount = async () => {
    setBusy(true);
    const res = await dispatch(deleteAccount());
    // @ts-ignore
    if (isSuccessfulAction(res, deleteAccount.fulfilled.type)) {
      // Redirect to signup even if the user navigated away, since their account is gone!
      redirectToSignUp();
    } else {
      Logger.info(`ERROR: unable to delete account. service response: ${JSON.stringify(res)}`);
      if (isMounted.current) {
        setBusy(false);
        setError(true);
      }
    }
  };

  return (
    <Modal
      className={modalFormStyles.containerSmall}
      closeModal={closeModal}
      closeText={intl.get('no')}
      onSave={handleDeleteAccount}
      saveText={intl.get('yes')}
      isSaveButtonBusy={isBusy}
      errorMessage={error ? intl.get('deleteAccountError') : undefined}
    >
      <div style={{padding: 20, fontSize: 20}}>{intl.get('deleteAccountConfirmation')}</div>
    </Modal>
  );
};

export default DeleteAccountConfirmationModal;
