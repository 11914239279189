import { FormikActions, FormikComputedProps, FormikHandlers, FormikState } from 'formik';
import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as formStyles from 'components/Modals/ModalForms.scss';
import ProfileModalForm from './ProfileModalForm';

interface Props extends FormikState<object>,
  FormikComputedProps<object>,
  FormikHandlers,
  FormikActions<object> {
  userInfo: object;
  localeCode: string;
  isSSO: boolean;
  updateLocale: (locale: string) => void;
  closeModal: () => void;
  isSubmitting: boolean;
  isResettingPassword: boolean;
  fetchGroupSettings: () => void;
  groupDomains: string;
  domainsFilter: string;
  resetPasswordHandler: () => void;
}

/**
 * Please refer to Git history if this needs to include multiple vertical tabs in the future.
 * Code already there, but un-used and removed on 10-04-2018 in Git history
 */
const ProfileModal = (props: Props) => {
  const { fetchGroupSettings, closeModal } = props;
  useEffect(
    fetchGroupSettings,
    []
  );

  return (
    <Modal
      className={formStyles.containerSmall}
      closeModal={closeModal}
      closeText={intl.get('cancel')}
      hideFooter={true}
      saveText={intl.get('save')}
      titleText={intl.get('myProfile')}
    >
      <ProfileModalForm
        {...props}
      />
    </Modal>
  );
};

export default ProfileModal;
