import { MutableRefObject, useRef } from 'react';
import useEventListener from 'hooks/useEventListener';

/*  Any click outside the associated ref will trigger the function passed into the hook.
    IMPORTANT: If you open the associated component with a click on the same route, that click will trigger
    the hasClickedOutside function. Use stopPropagation() to prevent initial clicks from triggering the hook.
*/

export function useClickOutsideHandler(hasClickedOutside?: () => void): MutableRefObject<HTMLDivElement | null> {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const clickListener = (event: Event) => {
    const domNode = modalRef.current;
    const node = event.target as Node;
    if (hasClickedOutside && domNode && !domNode.contains(node) && document.contains(node)) {
      hasClickedOutside();
    }
  };

  const keydownListener = (evt: KeyboardEvent) => {
    if (evt.code !== 'Escape') {
      return;
    }
    if (hasClickedOutside) {
      hasClickedOutside();
    }
  };

  useEventListener('keydown', keydownListener);
  useEventListener('click', clickListener);

  return modalRef;
}

export default {
  useClickOutsideHandler
};
