import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import Icon from 'components/Common/Icon';
import LiveStreamsToolbar from 'containers/Meetings/LiveStreamsTab/LiveStreamsToolbarContainer';
import * as styles from './LiveStreamDetails.scss';
import { DWHLiveStreamQuestionType } from 'interfaces/Dashboard';
import { ExportName } from 'actions/exportToCsvActions';
import TableNoData from 'components/Common/TableNoData';
import { useMount } from 'hooks/useMount';

interface Props {
  data: DWHLiveStreamQuestionType[];
  error: string | null;
  fetchData: Function;
  isFetching: boolean;
}

const LiveStreamDetailsViewers = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const { fetchData, data, error, isFetching } = props;
  const isMounted = useMount();
  useEffect(
    () => {
      const fetchThenSetLoading = async () => {
        await fetchData();
        if (isMounted.current) {
          setLoading(false);
        }
      };
      fetchThenSetLoading();
    },
    []
  );

  const renderData = () => {
    if (error) {
      return (
        <li>
          <h4 className={classnames(styles.noResults, 'error')}>
            <Icon classes={['icon-exclamation']} />
            <span>{intl.get('genericErrorMessage')}</span>
          </h4>
          <h6 className={classnames(styles.noResults, 'error')}>
            <em>{String(error)}</em>
          </h6>
        </li>
      );
    }
    if (!data.length) {
      return <TableNoData text={intl.get('noResults')} />;
    }
    return data.map((detail, i) => {
      return (
        <li key={`detail${i}`}>
          <p className={styles.title}>
            <span className={styles.time}>{detail.timeFormatted}</span>
            {detail.author}
          </p>
          <p className={styles.detail}>{detail.message}</p>
        </li>
      );
    });
  };

  const isLoading = isFetching || loading;
  return (
    <ListViewLayout
      hideHeader={true}
      isFetching={isLoading}
      numberOfPages={0}
    >
      <>
      {
        !isLoading && [
          (
            <div className="toolbar" key="toolbar" data-test="toolbar">
              <LiveStreamsToolbar
                activeTab={ExportName.LIVE_STREAM_QUESTIONS}
                data={data}
              />
            </div>
          ),
          <ul className={styles.details} key="data">{renderData()}</ul>
        ]
      }
      </>
    </ListViewLayout>
  );
};
export default LiveStreamDetailsViewers;
