import React from 'react';
import intl from 'react-intl-universal';
import * as styles from './AlexaRegisterModal.scss';
import { ProfileData } from 'interfaces/AlexaIntegration';
import { manageAlexaProfilesLink } from 'constants/alexaIntegrationConstants';
import RadioButton from 'components/RadioButton/RadioButton';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

interface Props {
  loading: boolean;
  selectedProfile: string | null;
  currentProfiles: Array<ProfileData>;
  setSelectedProfile: (value: string) => void;
}

const CLICK_HERE = 'clickHere';

const getNoProfileInstructions = () => {
  const splitNoProfiles = intl.get('alexaNoProfiles').split(/{|}/g);

  return splitNoProfiles.map((instruction: string) => {
    if (instruction === CLICK_HERE) {
      return <a href={manageAlexaProfilesLink} key={instruction} target={'_blank'}>{intl.get(CLICK_HERE)}</a>;
    }

    return <span key={instruction}>{instruction}</span>;
  });
};

export const AlexaProfileList = (props: Props) => {
  const { loading, currentProfiles, selectedProfile, setSelectedProfile } = props;

  if (loading) { return <LoadingIndicator wrapperCssModule={styles.loader} />; }

  if (currentProfiles.length) {
    return (
      <React.Fragment>
        {
          currentProfiles.map((profile: ProfileData) => {
            return (<RadioButton
              key={profile.profileArn}
              containerClassName={styles.profileRow}
              className={''}
              id={profile.profileArn}
              label={profile.name}
              field={{
                value: selectedProfile || '',
                name: 'profile',
                onBlur: () => { return; },
                onChange: (e: React.FormEvent<HTMLInputElement>) => {
                  setSelectedProfile(e.currentTarget.value);
                }
              }}
              hidenow={false}
            />);
          })
        }
      </React.Fragment>
    );
  }

  return (<div className={styles.emptyList}>{getNoProfileInstructions()}</div>);

};

export default AlexaProfileList;
