import React from 'react';
import { ScaleLoader } from 'react-spinners';
import { branch, ComponentEnhancer, predicate, renderComponent, withProps } from 'recompose';
import styles from './LoadingIndicator.scss';

interface Props {
  wrapperCssModule?: string;
}

const LoadingIndicator: React.FunctionComponent<Props> = ({wrapperCssModule}) => {
  // The user might want to change the outer container of the loader.
  // If the user does not, theres no reason to render an extra Div.
  const color = '#4b4f54';

  if (wrapperCssModule) {
    return (
      <div className={wrapperCssModule}>
        <div className={styles.container}>
          <ScaleLoader color={color} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <ScaleLoader color={'#4b4f54'} />
      </div>
    );
  }
};

export default LoadingIndicator;

export const showLoaderIfTrue: <TOuter extends object>(
  test: predicate<TOuter>, wrapperCssModule?: string
) => // tslint:disable-next-line
  ComponentEnhancer<any, TOuter>
  = (test, wrapperCssModule?) => {
  const wrappedIndicator = renderComponent(
    withProps({wrapperCssModule: wrapperCssModule})
    (LoadingIndicator)
  );

  return branch(test, wrappedIndicator);
};
