export interface DeviceCustomizationState {
  phoneHdSystem: string;
  language: string;
  url: string;
}

const phoneHdSystem = '';
const language = '';
const url = '';

export const initialDeviceCustomizationState: DeviceCustomizationState = {
  phoneHdSystem: phoneHdSystem,
  language: language,
  url: url
};
