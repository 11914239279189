import { localizationConstants } from '@lifesize/clients.localization';

const trimToLower = (value: string = '') => {
  return value.trim().toLocaleLowerCase();
};

const CZECH_ISO = 'cs';
const CZECH_LOCALE = 'cz';
const SINO_TIBETAN_ISO = 'zh';
const SINO_TIBETAN_LOCALE = 'zh-hans';

/**
 * Converts to ISO standard from locale codes used for translations
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
const convertLocaleToISO = (locale: string = 'en') => {
  if (trimToLower(locale) === CZECH_LOCALE) {
    return CZECH_ISO;
  }
  if (trimToLower(locale) === SINO_TIBETAN_LOCALE) {
    return SINO_TIBETAN_ISO;
  }
  return locale;
};

/**
 * Converts from ISO standard to locale codes used for translations
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
const convertLocaleFromISO = (locale: string = 'en') => {
  if (trimToLower(locale) === CZECH_ISO) {
    return CZECH_LOCALE;
  }
  if (trimToLower(locale) === SINO_TIBETAN_ISO) {
    return SINO_TIBETAN_LOCALE;
  }
  return locale;
};

const getLocaleOptions = () => {
  return localizationConstants.supportedLanguages.map((supportedLanguage) => {
    return {
      text: supportedLanguage.displayName,
      value: supportedLanguage.language,
      id: supportedLanguage.id,
      key: supportedLanguage.id,
    };
  });
};

export {
  convertLocaleToISO,
  convertLocaleFromISO,
  getLocaleOptions
};
