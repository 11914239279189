import _get from 'lodash/get';
import { Logger } from 'logger';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import intl from 'react-intl-universal';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import unPairRoomSystemsMutation from 'queries/roomSystems/unPairRoomSystemsMutation.gql';

interface Props {
  closeModal: () => void;
  selectionArray: string[];
}

export class UnPairRoomSystemsModal extends Component<Props> {
  render() {
    const {
      selectionArray,
      closeModal
    } = this.props;
    return (
      <Mutation mutation={unPairRoomSystemsMutation}>
        {(unPairRoomSystems, { loading, error }) => {
          let errorMessage = undefined;
          if (error) {
            errorMessage = intl.get('unPairTabletFailed');
          }
          return (
            <ConfirmModal
              confirmTitle={intl.get('removeFromCloudConfirmation')}
              confirmButtonText={intl.get('removeFromCloud')}
              isBusy={loading}
              errorMessage={errorMessage}
              closeModal={closeModal}
              confirmAction={async () => {
                try {
                  await unPairRoomSystems({
                    variables: {
                      serialNumbers: selectionArray,
                    }
                  });
                  closeModal();
                } catch (e) {
                  Logger.info(`Error attempting to unpair room system(s): ${e}`);
                  const apolloNetworkError = _get(e, 'networkError');
                  if (apolloNetworkError) {
                    // Additional Apollo error info if available
                    Logger.info(`Network error: ${_get(apolloNetworkError, 'result.errorMessage', '')}`);
                  }
                }
              }}
            />
          );
        }}
      </Mutation>
    );
  }
}

export default UnPairRoomSystemsModal;
