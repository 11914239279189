import { Global } from 'state/Global';
import { createSelector } from 'reselect';

const selectAlexaState = (state: Global) => state.app?.alexa;

const selectAuthenticationState = createSelector(
  selectAlexaState,
  (alexa) => alexa.invalid === true ? false : alexa.authenticated
);

const selectRoleArn = createSelector(
  selectAlexaState,
  (alexa) => alexa.invalid === true ? '' : alexa.roleArn
);

const selectExternalId = createSelector(
  selectAlexaState,
  (alexa) => alexa.invalid === true ? '' : alexa.externalId
);

const selectInvalidAuth = createSelector(
  selectAlexaState,
  (alexa) => alexa.invalid === true
);

const selectIntegrationStatus = createSelector(
  selectAlexaState,
  (alexa) => alexa.integrationStatus
);

const selectAlexaDevices = createSelector(
  selectAlexaState,
  (alexa) => alexa.devices
);

const selectAlexaProfiles = createSelector(
  selectAlexaState,
  (alexa) => alexa.profileData?.profiles
);

export {
  selectAlexaState,
  selectAuthenticationState,
  selectRoleArn,
  selectExternalId,
  selectIntegrationStatus,
  selectAlexaDevices,
  selectAlexaProfiles,
  selectInvalidAuth
};
