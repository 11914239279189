import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import intl from 'react-intl-universal';

const getExportOptions = (title: string): object => {
  return  {
    filename: title,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: title,
    useBom: true,
    useKeysAsHeaders: true
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
};

export const exportToCsv = (title: string, exportData: {}[]): void => {
  if (exportData.length) {
    const date = moment().format(intl.get('dateFormat'));
    const exportTitle = `${title} ${date}`;
    const exportInstance = new ExportToCsv(getExportOptions(exportTitle));
    exportInstance.generateCsv(exportData);
  }
};
