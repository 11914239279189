import { Global } from 'state/Global';
import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { ACCOUNT_STATUS, ACCOUNT_SETTING_AUTO_RECORD, ACCOUNT_SETTING_RECORDING_ENABLED } from 'constants/accountSettingsConstants';
import { accountSelectors, currentUserSelectors } from '@lifesize/nucleus';
import { isAdminUser, isSuperUser } from 'utils/userProfileUtils';
import { selectEcommerceSupported } from 'selectors/ecommerceSelector';
import { ACCOUNT_FEATURE_HIDE_DASHBOARD } from 'constants/accountFeatureConstants';

const accountStatusState = (state: Global) => _get(state, 'nucleus.account.status');

export const expiresAtSelector = (state: Global) => _get(state, 'nucleus.account.expiresAt');

export const accountExpiresAt = createSelector(
  selectEcommerceSupported,
  expiresAtSelector,
  (ecommerceSupported, expiresAt) => {
    if (!ecommerceSupported && expiresAt) {
      return parseInt(expiresAt, 10);
    }
    return undefined;
  }
);

export const isFreemiumOrTrial = createSelector(
  accountStatusState,
  (status) => [ACCOUNT_STATUS.FREEMIUM, ACCOUNT_STATUS.TRIAL].includes(status)
);

export const isFreemium = createSelector(
  accountStatusState,
  (status) => status === ACCOUNT_STATUS.FREEMIUM
);

export const isTrial = createSelector(
  accountStatusState,
  (status) => status === ACCOUNT_STATUS.TRIAL
);

export const selectShowUpgradeOptions = createSelector(
  selectEcommerceSupported,
  isTrial,
  (isEcommerceSupported, isTrialAccount) => isEcommerceSupported && !isTrialAccount
);

export const requiresPasscodesSetting = createSelector(
  accountSelectors.getAccountSettings,
  (accountSettings) => accountSettings?.requireMeetingPasscode
);

export const waitingRoom = createSelector(
  accountSelectors.getAccountSettings,
  (accountSettings) => !!accountSettings?.waitingRoom
);

export const recordingEnabledSetting = createSelector(
  accountSelectors.getAccountSettings,
  (accountSettings) => accountSettings?.[ACCOUNT_SETTING_RECORDING_ENABLED]
);

export const autoRecordMeetingsSetting = createSelector(
  accountSelectors.getAccountSettings,
  (accountSettings) => accountSettings?.[ACCOUNT_SETTING_AUTO_RECORD]
);

export const allowPermanentMeetingsSetting = createSelector(
  accountSelectors.getAccountSettings,
  (accountSettings) => accountSettings?.allowPermanentMeetings
);

export const defaultHidePermanentMeetingsSetting = createSelector(
  accountSelectors.getAccountSettings,
  (accountSettings) => typeof accountSettings?.createPermVmrHidden === 'boolean' ? accountSettings.createPermVmrHidden : false
);

export const defaultHideOTMeetingsSetting = createSelector(
  accountSelectors.getAccountSettings,
  (accountSettings) => typeof accountSettings?.createOtmVmrHidden === 'boolean' ? accountSettings.createOtmVmrHidden : true
);

export const isAdmin = createSelector(
  currentUserSelectors.myInfo,
  (currentUser) => isAdminUser(currentUser)
);

export const isAdminOrSuperUser = createSelector(
  currentUserSelectors.myInfo,
  (currentUser) => {
    return isAdminUser(currentUser) || isSuperUser(currentUser);
  }
);

export const demoDashboardSelector = createSelector(
  accountSelectors.getAccountFeature,
  isFreemium,
  (getAccountFeature, isAccountFreemium) =>
    getAccountFeature(ACCOUNT_FEATURE_HIDE_DASHBOARD) &&
    isAccountFreemium
);

export const hideDashboard = createSelector(
  accountSelectors.getAccountFeature,
  (getAccountFeature) => getAccountFeature(ACCOUNT_FEATURE_HIDE_DASHBOARD)
);

export const hideUsers = createSelector(
  accountSelectors.getAccountFeature,
  (getAccountFeature) => getAccountFeature('adminHideUsers')
);

export const hideMeetings = createSelector(
  accountSelectors.getAccountFeature,
  (getAccountFeature) => getAccountFeature('adminHideMeetings')
);

export const hideRooms = createSelector(
  accountSelectors.getAccountFeature,
  (getAccountFeature) => getAccountFeature('adminHideRooms')
);

export const sfuAccount = createSelector(
  accountSelectors.getAccountFeature,
  (getAccountFeature) => getAccountFeature('sfu')
);
