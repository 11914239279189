import omit from 'lodash/omit';
import moment, { Moment } from 'moment';
import React from 'react';
import { CalendarInfoPositionShape, DateRangePicker, FocusedInputShape, } from 'react-dates';
import 'react-dates/initialize';
import Icon from 'components/Common/Icon';
import { defaultDateRange } from 'state/Dashboard';
import { areDatesEqual, momentToEndOfDay, momentToStartOfDay } from 'utils/date-time-utils';
import {
  ANCHOR_RIGHT,
  START_DATE_ID,
  END_DATE_ID,
  DEFAULT_FORMAT,
  END_DATE,
  START_DATE
} from './date_range_constants';
import * as styles from './DateRangePickerWrapper.scss';
import { OLD_DASHBOARD_END } from 'constants/dashboard';

interface Props {
  id?: string;
  shouldShowDatePickerPanel: boolean;
  presetDateRangeSelected: boolean;
  startDate: Moment | null;
  endDate: Moment | null;
  calendarInfoPosition?: CalendarInfoPositionShape;
  renderCalendarInfo?: () => (string | JSX.Element);
  /** These dates only for internal consumption by component */
  onDatesChange: (arg: {
    startDate: Moment,
    endDate: Moment
  }) => void;
  /** These dates are the final selection broadcast out to parent component */
  onDatesSelected: (arg: {
    startDate: Moment,
    endDate: Moment
  }) => void;
  /** Callback if one of start or end selected. 2nd panel not visible if null */
  onFocusedInputChange: (arg: FocusedInputShape | null) => void;
  onClose: (dateRange: { startDate: Moment, endDate: Moment }) => void;
}

interface State {
  focusedInput: FocusedInputShape | null;
  startDate: Moment;
  endDate: Moment | null;
  pickerSaysComponentIsOpen: boolean;
  // These two values are used to reset display if no date changes made
  previousStartDate: Moment;
  previousEndDate: Moment | null;
}

class DateRangePickerWrapper extends React.Component<Props, State> {

  readonly state: State = {
    startDate: this.props.startDate || defaultDateRange.startDate,
    endDate: this.props.endDate || defaultDateRange.endDate,
    previousStartDate: this.props.startDate || defaultDateRange.startDate,
    previousEndDate: this.props.endDate || defaultDateRange.endDate,
    focusedInput: null,
    pickerSaysComponentIsOpen: false,
  };

  /**
   * Watch for updates to the result collection to set on state
   * @param nextProps
   * @param currentState
   * @return {any}
   */
  static getDerivedStateFromProps(nextProps: Props, currentState: State) {

    if (nextProps.startDate && nextProps.endDate) {
      if (nextProps.shouldShowDatePickerPanel && !nextProps.presetDateRangeSelected) {
        // In this block, the user is hovering over preset date ranges
        return {
          startDate: nextProps.startDate,
          endDate: nextProps.endDate
        };
      }
      // This block means a selection has been made
      if (nextProps.presetDateRangeSelected) {
        return {
          previousStartDate: currentState.startDate,
          startDate: nextProps.startDate,
          previousEndDate: currentState.endDate,
          endDate: nextProps.endDate
        };
      }
    }
    // This sets the display dates back to the previous range, since no new date range was selected
    if (!areDatesEqual(currentState.startDate, currentState.previousStartDate)
      || (currentState.endDate && currentState.previousEndDate
        && !areDatesEqual(currentState.endDate, currentState.previousEndDate))) {
      return {
        startDate: currentState.previousStartDate,
        endDate: currentState.previousEndDate
      };
    }

    return {
      startDate: nextProps.startDate,
      endDate: nextProps.endDate
    };

  }

  /**
   * Selected date range has changed, but final selection has not been made
   * until an end date has been selected, and shouldShowDatePickerPanel === false
   * @param dates {{startDate: Moment endDate: Moment}}
   */
  onDatesChange = (dates: {
                     startDate: Moment,
                     endDate: Moment
                   }
  ) => {
    if (dates.startDate) {
      this.setState(
        {
          startDate: momentToStartOfDay(dates.startDate),
          endDate: dates.endDate ? momentToEndOfDay(dates.endDate) : null,
          previousStartDate: this.state.startDate,
          previousEndDate: this.state.endDate
        },
        () => {
          // Let the parent component know about the date selections
          this.props.onDatesChange(dates);
          if (!this.props.shouldShowDatePickerPanel) {
            // When shouldShowDatePickerPanel is false,
            // we know a valid start and end date have been selected by the user
            // Re-run the query based on new date range
            this.props.onDatesSelected(dates);
          }
        }
      );
    }
  }

  /**
   * Panel will close when a valid start and end date are set by user.
   * Send the final date range selection when picker closes.
   * @param dateRange {{startDate: Moment endDate: Moment}}
   */
  onClose = (dateRange: { startDate: Moment, endDate: Moment }) => {
    // Panel is now closed - set focusedInput to null to determine if panel is closed
    const {props, state} = this;
    // If the user picked a start date, but not and end date,
    // and clicked out of the panel (to close it)
    // reset dates back to previous, or in other words,
    // make sure the dates are in sync with the displayed data.
    if (props.startDate && state.startDate && !props.startDate.isSame(state.startDate)) {
      this.setState({startDate: props.startDate, pickerSaysComponentIsOpen: false, focusedInput: null});
    } else {
      this.setState({pickerSaysComponentIsOpen: false, focusedInput: null});
    }

    this.props.onClose(dateRange);
  }

  /**
   * Handles focus change between start and end dates in picker header
   * @param {ReactDates.FocusedInputShape} focusedInput
   */
  onFocusChange = (focusedInput: FocusedInputShape) => {
    if (!this.isOpened() && focusedInput === END_DATE) {
      focusedInput = START_DATE;
    }
    this.setState({focusedInput: focusedInput, pickerSaysComponentIsOpen: true});
    this.props.onFocusedInputChange(focusedInput);
  }

  /**
   * Note: Returning null form this function will close the
   * Date range picker panel
   */
  getFocusedInput = (): FocusedInputShape | null => {
    if (this.state.focusedInput === null) {
      this.props.onFocusedInputChange(null);
      return null;
    }

    if (this.props.shouldShowDatePickerPanel
      && this.state.pickerSaysComponentIsOpen) {
      this.props.onFocusedInputChange(this.state.focusedInput);
      return this.state.focusedInput;
    }

    if (!this.props.shouldShowDatePickerPanel) {
      this.props.onFocusedInputChange(null);
      return null;
    }

    return this.state.focusedInput;
  }

  /**
   * Test if panel is open by checking focusedInput value
   * @return {boolean}
   */
  isOpened() {
    return this.state.focusedInput !== null;
  }

  render() {
    // This prevents custom props from being set on the DateRangePicker
    const props = omit(this.props, [
      'shouldShowDatePickerPanel',
      'onFocusedInputChange',
      'onDatesSelected',
      'presetDateRangeSelected',
      'id'
    ]);

    return (
      <div className={styles.container} id="dr_picker">
        <DateRangePicker
          {...props}
          daySize={40}
          isRTL={false}
          readOnly={true}
          anchorDirection={ANCHOR_RIGHT}
          initialVisibleMonth={() => moment(OLD_DASHBOARD_END, 'MM-DD-YYYY').subtract(1, 'months')}
          hideKeyboardShortcutsPanel={true}
          keepOpenOnDateSelect={false}
          showClearDates={false}
          renderCalendarInfo={this.props.renderCalendarInfo}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={this.getFocusedInput()}
          startDate={this.state.startDate}
          startDateId={START_DATE_ID}
          endDate={this.state.endDate}
          endDateId={END_DATE_ID}
          showDefaultInputIcon={true}
          isOutsideRange={(day) => moment(day).isSameOrAfter(moment(OLD_DASHBOARD_END, 'MM-DD-YYYY').endOf('day'))}
          // tslint:disable-next-line
          maxDate={moment(OLD_DASHBOARD_END, 'MM-DD-YYYY')}
          customArrowIcon={<Icon classes={['icon-start']}/>}
          displayFormat={DEFAULT_FORMAT}
          onClose={this.onClose}
          withFullScreenPortal={false}
          minimumNights={0}
        />
      </div>
    );
  }
}

export default DateRangePickerWrapper;
