import { createAction } from '@lifesize/typescript-react-redux-utils';
import { Moment } from 'moment';

export enum DashboardActionType {
  DASHBOARD_FETCH_ALL = 'DASHBOARD_FETCH_ALL',
  DASHBOARD_SET_DATE_RANGE = 'DASHBOARD_SET_DATE_RANGE',
  DASHBOARD_SET_DATE_RANGE_MOMENT = 'DASHBOARD_SET_DATE_RANGE_MOMENT',
  VIEW_ALL_SELECTED = 'VIEW_ALL_SELECTED'
}

export enum ViewName {
  CallHistory = 'CallHistory',
  LiveStreamHistory = 'LiveStreamHistory',
  UserCalls = 'UserCalls',
  RoomSystems = 'RoomSystems'
}

export const actions = {
  handleFetchDashboardData: () =>
    createAction(DashboardActionType.DASHBOARD_FETCH_ALL),
  handleSetDateRange: (payload: { startDate: string, endDate: string }) =>
    createAction(DashboardActionType.DASHBOARD_SET_DATE_RANGE, payload),
  handleSetDateRangeMoment: (payload: { startDate: Moment, endDate: Moment }) =>
    createAction(DashboardActionType.DASHBOARD_SET_DATE_RANGE_MOMENT, payload),
};

export default actions;
