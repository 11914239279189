import { connect } from 'react-redux';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import { selectResetPasswordState } from '../../selectors/resetPasswordSelector';
import { Global } from '../../state/Global';

const mapStateToProps = (state: Global) => {
  const resetPasswordState = selectResetPasswordState(state);
  return {
    isResettingPassword: resetPasswordState.isResettingPassword,
    isPasswordReset: resetPasswordState.isPasswordReset,
    resetPasswordFailure: resetPasswordState.resetPasswordFailure
  };
};

export default connect(mapStateToProps)(ResetPassword);
