import React from 'react';
import intl from 'react-intl-universal';

import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as modalFormStyles from 'components/Modals/ModalForms.scss';
import * as styles from 'components/Settings/AccountDetailsTab/AccountSupport.scss';

type Props = {
  code: string | null;
  error: string | null;
  onClose: () => void;
};

export default function ImpersonationCodeModal(props: Props) {
  return (
    <Modal
      className={modalFormStyles.containerSmall}
      closeModal={props.onClose}
      hideCloseButton={true}
      onSave={props.onClose}
      saveText={intl.get('modalClose')}
      titleText={intl.get('supportCodeTitle')}
    >
      <div className={styles.modalContainer}>
        { props.code && 
          <>
            <span>
              {intl.get('supportCodeShare')}
            </span>
            <h2 className={styles.impersonationCode}>
              {props.code}
            </h2>
          </>
          }
        { props.error &&
          <span className={styles.errorMessage}>
            {intl.get('supportCodeError')}
          </span>
        }
      </div>
    </Modal>
  );
}
