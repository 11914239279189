import { createSelector } from 'reselect';
import { Global } from 'state/Global';

/**
 * Direct selector to the drilldown state domain
 */
const selectFilterState = (state: Global) => state.app?.filter;

const selectFilterString = createSelector(
  selectFilterState,
  (filter) => {
    return filter.filterString;
  }
);

export {
  selectFilterState,
  selectFilterString,
};
