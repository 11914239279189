import ClickableCell from 'components/ClickableCell/ClickableCell';
import TooltipFixedPosition from 'components/TooltipFixedPosition/TooltipFixedPosition';
import { showExpiringSoonLabelWithinDays } from 'constants/meetingConstants';
import { SHIFT } from 'constants/tooltipFixedPositionConstants';
import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import { ListMeetingsQuery } from 'typings/types';
import { getMeetingTypeLabel } from 'utils/meetingsUtils';
import * as styles from './MeetingsTab.scss';
import { formatIsoUtcDateTimeForDisplayDate } from 'utils/date-time-utils';
import CopyMeetingDetails from 'components/Meetings/MeetingsTab/CopyMeetingDetails/CopyMeetingDetails';
import MeetingInlineFragment = ListMeetingsQuery.MeetingInlineFragment;

export const meetingsTabColumnConfig = (editMeeting: (contact: MeetingInlineFragment) => void) => {
  return [
    {
      Header: intl.get('meetingName'),
      accessor: 'displayName',
      id: 'displayName',
      Cell: (row: RowInfo) => {
        const {displayName, daysUntilExpiration} = row.original;
        return (
          <div>
            <ClickableCell
              id={`settings-${escape(displayName)}`}
              title={intl.get('meetingDetails')}
              isClickable={true}
              displayText={displayName}
              clickHandler={() => {
                editMeeting(row.original);
              }}
            />
            {daysUntilExpiration && daysUntilExpiration <= showExpiringSoonLabelWithinDays ? (
              <div className={styles.expiresSoonContainer}>
                <span className={styles.expiresSoonText}>
                  {intl.get('expiresSoon')}
                </span>
                <TooltipFixedPosition
                  fixPosition={false}
                  shift={SHIFT.RIGHT}
                  offset={{bottom: 36, left: 37}}
                  label={
                    <span style={{whiteSpace: 'pre-line'}}>
                      {intl.get('expiresSoonTooltip', {days: daysUntilExpiration})}
                    </span>}
                />
              </div>
            ) : null}
          </div>
        );
      }
    },
    {
      Header: intl.get('meetingType'),
      accessor: 'type',
      id: 'tempMeeting',
      Cell: (row: RowInfo) => {
        const type = _get(row.original, 'type');
        if (type) {
          return getMeetingTypeLabel(type);
        }
        return null;
      }
    },
    {
      Header: intl.get('passCode'),
      accessor: 'pin',
      id: 'pin',
      maxWidth: 120,
    },
    {
      Header: intl.get('extension'),
      accessor: 'extension',
      id: 'extension',
      maxWidth: 120,
    },
    {
      Header: intl.get('owner'),
      accessor: 'ownerEmail',
      id: 'ownerEmail',
      Cell: (row: RowInfo) => {
        return _get(row.original, 'owner.email', _get(row.original, 'ownerEmail', ''));
      },
      sortable: false,
      minWidth: 160,
    },
    {
      Header: intl.get('dateCreated'),
      accessor: 'createdAt',
      id: 'createdAt',
      Cell: (row: RowInfo) => {
        const date = _get(row.original, 'dateCreated');
        if (!date) {
          return null;
        }
        return formatIsoUtcDateTimeForDisplayDate(date);
      }
    },
    {
      Header: intl.get('lastUsed'),
      accessor: 'lastUsedAt',
      id: 'lastUsedAt',
      Cell: (row: RowInfo) => {
        const date = _get(row.original, 'lastUsedAt');
        if (!date) {
          return null;
        }
        return formatIsoUtcDateTimeForDisplayDate(date);
      }
    },
    {
      id: 'copyDetails',
      maxWidth: 50,
      Cell: (row: RowInfo) => <CopyMeetingDetails extension={_get(row.original, 'extension')} />
    }
  ];
};
