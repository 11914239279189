import { Form, Formik } from 'formik';
import _get from 'lodash/get';
import React, { PureComponent } from 'react';
import intl from 'react-intl-universal';
import { Dropdown } from 'semantic-ui-react';
import MeetingRecordingShareSettings from 'components/MeetingRecordingShareSettings/MeetingRecordingShareSettingsComponent';
import MultiSearchUsers
  from 'components/Search/SearchMultiSelect';
import {
  VideoShareProperties,
  VideoShareUpdateProperties,
  VideoShareViewer
} from 'interfaces/VideoShareProperties';
import Icon from 'components/Common/Icon';
import CopyToClipboard from 'components/CopyToClipboardButton/CopyToClipboardButton';
import { ModalFooter } from 'components/Modal/ModalComponent/ModalFooter';
import { Tooltip } from 'components/tooltip/Tooltip';
import * as styles from './ShareRecordingModal.scss';
import {
  getMeetingShareSetting,
  getMeetingAttributes,
  getVideoShareSetting,
  getVideoAttributes
} from 'utils/recordingsUtils';
import { SHARE_WITH } from 'constants/recordingsConstants';

interface Props {
  closeModal: () => void;
  isBusy: boolean;
  isPublicUrlBusy: boolean;
  isGroupUrlBusy: boolean;
  data: VideoShareProperties | null;
  onSubmit: (args: object, meetingShareProps: object) => void;
  refreshPublic: () => void;
  refreshGroup: () => void;
  vcSettingsAllowPublic: boolean;
}

interface FormValues {
  selectedShare: {
    id: string;
    name: string;
  };
  viewers: VideoShareViewer[];
  meetingSelectedShare: {
    id: string;
    name: string;
  };
  meetingViewers: VideoShareViewer[];
}

export class ShareRecordingForm extends PureComponent<Props> {
  getPublicUrl = (data: VideoShareProperties | null) => {
    if (data) {
      const playbackHostname = process.env.REACT_APP_PLAYBACK_URL;
      const publicUrlString = _get(data, 'publicUrl', '') || '';
      // @ts-ignore
      return publicUrlString.replace(window.location.hostname, playbackHostname);
    }
    return '';
  }

  render(): React.ReactNode {

    const {
      data,
      isBusy,
      isPublicUrlBusy,
      onSubmit,
      closeModal,
      refreshGroup,
      refreshPublic,
      vcSettingsAllowPublic
    } = this.props;
    const protocolDomain = process.env.REACT_APP_MAIN_URL;
    const groupUrl = `${protocolDomain}/singleRecording/${_get(data, 'share_urls.auth', '')}?authToken=${_get(data, 'share_urls.noAuth', '')}`;
    let publicUrl: string;
    if (vcSettingsAllowPublic) {
      publicUrl = this.getPublicUrl(data);
    }
    const sharedWithEntireAccount = _get(data, 'meetingProps.is_public', false);
    const shareWithOptions = [
      {
        text: intl.get('specificUsers'),
        value: SHARE_WITH.VIEWERS
      },
      {
        text: intl.get('entireAccount'),
        value: SHARE_WITH.PUBLIC
      }
    ];

    return (
      <Formik
        initialValues={{
          viewers: _get(data, 'viewers', []),
          // @ts-ignore
          selectedShare: getVideoShareSetting(data),
          // @ts-ignore
          meetingSelectedShare: getMeetingShareSetting(_get(data, 'meetingProps', {})),
          meetingViewers: _get(data, 'meetingProps.viewers', [])
        }}
        onSubmit={(values: FormValues) => {
          // Calls handleSubmit passed in from wrapping component
          // NOTE: apply_to_all will change the share settings for all recordings associated to this meeting
          const videoAttributes = getVideoAttributes(values.selectedShare.id);
          // @ts-ignore
          const shareProperties: VideoShareUpdateProperties = {
            ...data,
            ...{
              viewers: values.viewers,
              selectedShare: values.selectedShare
            },
            ...videoAttributes,
            ...{ apply_to_all: false }
          };
          const currentShareProps = _get(data, 'meetingProps', {});
          const meetingProperties = getMeetingAttributes(values.meetingSelectedShare.id);
          const meetingRecordingShareProps = {
            ...currentShareProps,
            ...{
              selectedShare: values.meetingSelectedShare,
              viewers: values.meetingViewers
            },
            ...meetingProperties
          };
          delete shareProperties.publicUrl;
          delete shareProperties.groupUrl;
          onSubmit(shareProperties, meetingRecordingShareProps);
        }}
        render={({ dirty, setFieldValue, values }) => (
          <Form>
            <div className={styles.container}>

              <div className={styles.horizontalDivider}/>

              <div className={styles.topRowContainer}>
                <MeetingRecordingShareSettings
                  disabled={true}
                  values={values}
                  setFieldValue={setFieldValue}
                  shareField="meetingSelectedShare"
                  viewersField="meetingViewers"
                />
              </div>

              {/* Share with */}
              {
                !sharedWithEntireAccount ? (
                  <>
                    <div className={styles.horizontalDashedDivider}/>

                    <div className={styles.formGroupHorizontal}>
                      <label className="label">{intl.get('addMoreViewers')}</label>
                      <Dropdown
                        className={styles.shareWithInput}
                        closeOnBlur={true}
                        fluid={false}
                        name={'selectedShare'}
                        onChange={(evt, selection) => {
                          const option = shareWithOptions.find(o => o.value === selection.value);
                          if (option) {
                            setFieldValue('selectedShare', {
                              id: option.value,
                              name: option.text
                            });
                          }
                        }}
                        options={shareWithOptions}
                        selection={true}
                        value={_get(values, 'selectedShare.id')}
                      />
                      <div className={styles.optionalContainer}>{intl.get('optional')}</div>
                    </div>
                    {_get(values, 'selectedShare.id') === SHARE_WITH.VIEWERS ? (
                      <MultiSearchUsers
                        onSearchSelectionMade={(selectedUsers: Array<{ name: string, uuid: string}>) => {
                          setFieldValue('viewers', selectedUsers);
                        }}
                        noResultsText={intl.get('noResults')}
                        initialSelection={data ? data.viewers : []}
                      />
                    ) : null}
                  </>
                ) : null}

              <div className={styles.horizontalDashedDivider}/>

              <div className={styles.columnContainer}>
                <div>{intl.get('shareableLinkForGroup')}</div>
                <div className={styles.inviteLinkContainer}>
                  <div className={styles.inviteLink}>
                    {groupUrl}
                  </div>
                  <div className={styles.buttonContainer}>
                    <Tooltip text={intl.get('copyLink')}>
                      <CopyToClipboard text={groupUrl || ''}/>
                    </Tooltip>
                    <Tooltip text={intl.get('refreshLink')}>
                      <button
                        onClick={refreshGroup}
                        className={styles.button}
                        name="btnRefresh"
                        role="button"
                        type="button"
                      >
                        <Icon classes={['icon-loader']}/>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {vcSettingsAllowPublic && publicUrl.length ? (
                <>
                  <div className={styles.horizontalDashedDivider}/>

                  <div className={styles.publicLinkContainer}>
                    <div className={styles.publicLinkRowContainer}>
                      <div className={styles.publicLinkLabel}>{intl.get('publicLink')}</div>
                      <div className={styles.publicLink}>{isBusy || isPublicUrlBusy ? '....' : publicUrl}</div>
                    </div>
                    <div className={styles.buttonContainer}>
                      <Tooltip text={intl.get('copyLink')}>
                        <CopyToClipboard text={publicUrl || ''}/>
                      </Tooltip>
                      <Tooltip text={intl.get('refreshLink')}>
                        <button
                          onClick={refreshPublic}
                          className={styles.button}
                          name="btnRefresh"
                          role="button"
                          type="button"
                        >
                          <Icon classes={['icon-loader']}/>
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <ModalFooter
              useSaveAsFormSubmit={true}
              closeModal={closeModal}
              closeText={intl.get('cancel')}
              isSaveButtonBusy={isBusy}
              isSaveButtonDisabled={isBusy || !dirty}
            />
          </Form>
        )}
      />
    );
  }

}

export default ShareRecordingForm;
