import { Global } from '../state/Global';
import { createSelector } from 'reselect';
import { mapEcommercePlanType } from 'utils/ecommerceUtils';

const ecommerceState = (state: Global) => state.app?.ecommerce;

const selectEcommercePlan = createSelector(
  ecommerceState,
  (ecommerce) => mapEcommercePlanType(ecommerce?.account?.plan || '')
);

const selectEcommerceSupported = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce?.account?.isEcommerceSupported || false
);

const selectEcommerceLoading = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce.loading
);

const isEcommerceSubscriptionLoading = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce.subscriptionLoading
);

const selectSubscription = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce?.subscription
);

const hasSubscriptionError = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce?.subscriptionError || false
);

const isSubscriptionCancelBusy = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce?.subscriptionCancelBusy || false
);

const hasSubscriptionCancelError = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce?.subscriptionCancelError || false
);

const isSubscriptionReactivateBusy = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce?.subscriptionReactivateBusy
);

const hasSubscriptionReactivateError = createSelector(
  ecommerceState,
  (ecommerce) => ecommerce.subscriptionReactivateError
);

export {
  selectEcommercePlan,
  selectEcommerceSupported,
  selectEcommerceLoading,
  isEcommerceSubscriptionLoading,
  selectSubscription,
  hasSubscriptionError,
  isSubscriptionCancelBusy,
  hasSubscriptionCancelError,
  isSubscriptionReactivateBusy,
  hasSubscriptionReactivateError
};
