import { Recording, UploadStatusResponse } from 'interfaces/Recording';
import { Moment } from 'moment';

export interface RecordingsState extends Recording {
  isGridLayout: boolean;
  searchResults: Array<object>;
  pending: boolean;
  uploadsStatus: UploadStatusResponse[];
  updating: boolean;
  isFetchingVcCookie: boolean;
  isVcCookieSet: boolean;
  startDate?: Moment;
  endDate?: Moment;
  failedToFetchVcCookie: boolean;
}

export const initialRecordingsState: RecordingsState = {
  isFetching: false,
  isGridLayout: true,
  recordingData: null,
  searchResults: [],
  pending: false,
  uploadsStatus: [],
  updating: false,
  isFetchingVcCookie: false,
  isVcCookieSet: false,
  failedToFetchVcCookie: false,
  startDate: undefined,
  endDate: undefined,
};
