import { proxyActions } from '@lifesize/nucleus';
import { Logger } from '../logger';
import { Methods } from 'interfaces/HTTP';

export const dispatchProxyApiCall = async (dispatch: Function, queryParams: object, inputParams: object, endpoint: string, uriSuffix: string, method: Methods, successCallback: Function, errorCallback: Function) => {
  try {
    const jsonResponse = await dispatch(
      proxyActions.proxyApiCall(
        endpoint,
        uriSuffix,
        method,
        inputParams,
        queryParams
      )
    );

    const response = JSON.parse(jsonResponse);
    if (response.statusCode && response.statusCode < 300) {
      return successCallback(response);
    } else {
      return errorCallback(response);
    }
  } catch (e) {
    Logger.info(`Error issuing command:  ${e.toString()}`);
  }
};

export default {
  dispatchProxyApiCall
};
