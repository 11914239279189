import React from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import * as styles from 'components/Settings/Sections.scss';
import { localizationSelectors } from '@lifesize/nucleus';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { Button } from 'semantic-ui-react';
import { DPA_VERSION } from 'constants/logDpaConstants';
import { amendmentDownload } from 'utils/securityAndPrivacyUtils';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export interface Props {
  loading: boolean;
  hasServiceError: boolean;
  userAccepted: boolean;
  setShowSecurityReviewModal: (setting: boolean) => void;
  email: string | null;
  date: string | null;
}

const SecurityAndPrivacyContent = (
  {
    loading,
    hasServiceError,
    userAccepted,
    setShowSecurityReviewModal,
    email,
    date
  }: Props
) => {
  const localeCode = useSelector(localizationSelectors.localeCode) as unknown as string;
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  if (loading) {
    return <LoadingIndicator/>;
  }

  if (hasServiceError) {
    return (
      <div className={styles.description}>
        {intl.get('securityStateUnavailable')}
      </div>
    );
  }

  return (
    <>
      <div className={styles.description}>
        {intl.get('reviewSecurityTerms')}
      </div>
      <div className={styles.formSection}>
        <div className={styles.statusBar}>
          <span>{intl.get('statusLabel')}</span>
          {userAccepted ? <i className={`icon icon-check-on ${styles.green}`} /> : <i className={`icon icon-info ${styles.yellow}`} />}
          <span>{userAccepted ? intl.get('amendmentAcceptedBy', { email, date }) : intl.get('notAccepted')}</span>
        </div>
        <div style={{ marginTop: '21px' }}>
          {userAccepted && <a href={amendmentDownload(localeCode)} download={`${intl.get('dataProcessingAddendum', { version: DPA_VERSION })}.pdf`}>{intl.get('downloadAmendment')}</a>}
        </div>
        <div className={styles.buttonContainer}>
          {!userAccepted && <Button
              onClick={() => {
                if (!canEditSettings) { return; }
                setShowSecurityReviewModal(true);
              }}
              role="button"
              type="button"
              loading={loading}
              disabled={!canEditSettings}
          >
            {intl.get('reviewAndAccept')}
          </Button>}
        </div>
      </div>
    </>
  );
};

export default SecurityAndPrivacyContent;
