import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { DWHLiveStreamResultType, DWHLiveStreamResultTypeV2  } from 'interfaces/Dashboard';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import DrilldownHeader from 'components/Drilldowns/Common/DrilldownHeader2';
import { Moment } from 'moment';
import { liveStreamHistoryColumnConfig } from './LiveStreamHistoryColumnConfig';
import ListViewComponent from 'components/ListView/ListViewComponent';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

interface Props {
  title: string;
  data: Array<DWHLiveStreamResultType | DWHLiveStreamResultTypeV2>;
  error: string | undefined;
  isFetching: boolean;
  hasNewData: boolean;
  fetchListData: (startDate: Moment, meetingName?: string) => void;
  fetchMore?: () => Promise<{}>;
  refetch: (opts: object) => void;
  hasMoreRecords: boolean;
  startDate: Moment;
  endDate: Moment;
  meetingName: string;
  meetingId: string;
  singleMeeting: boolean;
  handleExportToCsv: Function;
  breadcrumbs: JSX.Element[] | undefined;
}

export const LiveStreamHistoryList = (props: RouteComponentProps<void> & Props) => {
  const {
    isFetching,
    title,
    error,
    hasMoreRecords,
    fetchListData,
    startDate,
    singleMeeting,
    meetingName,
    breadcrumbs,
    handleExportToCsv,
    data,
    fetchMore,
    history
  } = props;
  const conditionalFetchListData = (fetchStart: Moment = startDate) => fetchListData(fetchStart, singleMeeting ? meetingName : undefined);

  useEffect(() => {
    if (hasMoreRecords && fetchMore) {
      fetchMore();
    }
  },        [hasMoreRecords]);

  useEffect(() => {
    conditionalFetchListData(startDate);
  },        []);

  return (
    <ListViewLayout
      breadcrumbs={breadcrumbs}
      title={title}
      error={error}
    >
      <>
      <DrilldownHeader
        fetchData={(formattedStartDate: Moment) => {
          conditionalFetchListData(formattedStartDate);
        }}
        onExportClick={() => { handleExportToCsv(data); }}
      />
        {isFetching ?
          <LoadingIndicator /> :
          <ListViewComponent
            columns={liveStreamHistoryColumnConfig(history.push)}
            showPagination={false}
            maxRows={data.length}
            data={data}
            sortable={true}
            defaultSorted={[
              {
                id: 'startDateFormatted',
                desc: false
              }
            ]}
            getTableDataProps={() => ({
              style: {
                alignItems: 'flex-start',
                paddingTop: '16px',
                paddingBottom: '16px',
                lineHeight: '12px'
              }
            })}
            getTableRowProps={() => ({
              style: {
                paddingLeft: '5px'
              }
            })}
            loading={isFetching}
          />
        }
      </>
    </ListViewLayout>
  );
};

export default withRouter(LiveStreamHistoryList);
