import { Reducer } from 'redux';
import { Actions, DeviceState } from 'constants/deviceManagementConstants';
import { DeviceManagementState, initialDeviceManagementState } from 'state/DeviceManagementState';

const deviceCustomizationReducer: Reducer<DeviceManagementState> =
  (state: DeviceManagementState = initialDeviceManagementState, action) => {
    switch (action.type) {
      case Actions.REBOOT_DEVICE_SUCCESS:
        return {
          ...state,
          devices: { ...state?.devices, [action.payload]: DeviceState.success }
        };
      case Actions.REBOOT_DEVICE_FAILURE:
        return {
          ...state,
          devices: { ...state?.devices, [action.payload]: DeviceState.failed }
        };
      case Actions.REBOOT_DEVICE:
        return {
          ...state,
          devices: { ...state?.devices, [action.payload]: DeviceState.pending }
        };
      case Actions.UPLOAD_CORONER_STATUS:
        return {
          ...state,
          coroner: { ...state?.coroner, [action.payload?.serialNumber]: action.payload }
        };
      default:
        return state;
    }
  };

export default deviceCustomizationReducer;
