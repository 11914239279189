import React from 'react';
import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import { BASE_ROOM_SYSTEM_ROUTE, PairingStatus } from 'constants/roomSystemConstants';
import { getSystemNameDisplayText } from 'utils/roomSystemUtils';
import { Link } from 'react-router-dom';
import { Sort } from 'interfaces/Meeting';

interface Props {
  row: RowInfo;
  sort: Sort;
  filterString: string | null;
}

const DisplayNameCell = ({ row, sort, filterString }: Props) => {
  const { displayName, pairingStatus } = row.original;
  if (pairingStatus === PairingStatus.DELETED) {
    return displayName;
  }
  return (
    <span>
      {
        displayName &&
        <Link
          id={`settings-${displayName}`}
          title={intl.get('system-details')}
          to={{
            pathname: `${BASE_ROOM_SYSTEM_ROUTE}/${row.original.serialNumber}`,
            state: {
              sort,
              filterString
            }
          }}
        >
          {getSystemNameDisplayText(displayName)}
        </Link>
      }
    </span>
  );
};

export default DisplayNameCell;
