import { SET_MERGE_REQUEST_STATUS } from 'constants/mergeAccountConstants';
import { initialMergeAccountState, MergeAccountState } from 'state/MergeAccount';
import { Reducer } from 'redux';
import { getRequest } from 'actions/mergeAccountActions';
import { Logger } from 'logger';

const MergeAccountReducer: Reducer<MergeAccountState> =
  (state: MergeAccountState = initialMergeAccountState, action) => {
    switch (action.type) {
      case SET_MERGE_REQUEST_STATUS:
        return {
          ...state,
          request: action.payload
        };
      case getRequest.pending.type:
        return {
          ...state,
          loading: true,
          error: false
        };
      case getRequest.fulfilled.type:
        return {
          ...state,
          loading: false
        };
      case getRequest.rejected.type:
        Logger.info(`Unable to fetch merge request: ${JSON.stringify(action.error)}`);
        return {
          ...state,
          loading: false,
          error: true
        };
      default:
        return state;
    }
  };

export default MergeAccountReducer;
