import { connect } from 'react-redux';
import actions from 'actions/dashboardActions';
import { Dashboard } from 'components/Dashboard/Dashboard';
import { Global } from 'state/Global';

const mapStateToProps = (state: Global) => ({});

const mapDispatchToProps = (dispatch: Function) => ({
  fetchData: () => {
    dispatch(actions.handleFetchDashboardData());
  }
});

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
