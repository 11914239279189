import {
  RecordingLimits,
  RecordingRequestObjectFeed,
  RecordingRequestObjectSort,
  RecordingRequestObjectTimeLine,
  UNKNOWN_MEETING_NAME
} from 'constants/recordingsConstants';
import { recordingActions as nucleusRecordingActions } from '@lifesize/nucleus';
import actions from 'actions/recordingsActions';
import Pagination from 'containers/Pagination/PaginationContainer';
import RecordingsToolbar from 'components/Recordings/RecordingsToolbar';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { RecordingDataArray } from 'interfaces/RecordingDataItem';
import { RecordingListingParamsType } from 'interfaces/Recording';
import * as styles from '../Recordings.scss';
import RecordingsListItemView from '../RecordingsListItemView';
import RecordingsTileView from '../RecordingsTileView';
import { getUrlParameter } from 'utils/url-utils';
import classnames from 'classnames';
import Icon from 'components/Common/Icon';
import { useMount } from 'hooks/useMount';
import { RecordingRequest } from 'interfaces/RecordingRequest';
import useEventListener from 'hooks/useEventListener';
import { getUploadUpdatingStatus } from 'selectors/recordingsSelectors';
import { selectEndDate, selectStartDate } from 'selectors/recordingsSelectors';

interface SelectedMeetingInterface {
  name: string | null;
}

export interface Props {
  isGridLayout: boolean;
  isLoading: boolean;
  recordingData: RecordingDataArray;
  selectedMeeting: SelectedMeetingInterface;
  totalCount: number;
  pageType: RecordingRequestObjectFeed;
  error: object;
  canUpload: boolean;
  processingUploadCount: number;
  getUploadsStatus: () => void;
  startUploadUpdates: () => void;
  stopUploadUpdates: () => void;
  initialListingParams: RecordingListingParamsType;
  currentUserRole: string;
}

const Recordings = (props: Props) => {
  const {
    isLoading,
    pageType,
    recordingData,
    error,
    canUpload,
    processingUploadCount,
    getUploadsStatus,
    startUploadUpdates,
    stopUploadUpdates,
    isGridLayout,
    totalCount,
    selectedMeeting,
    initialListingParams,
    currentUserRole,
  } = props;
  const [isProcessingUpload, setIsProcessingUpload] = useState(processingUploadCount > 0);
  const [uploadIsComplete, setUploadIsComplete] = useState(false);
  const [previousProcessingCount, setPreviousProcessingCount] = useState(processingUploadCount);
  const [showPagination, setShowPagination] = useState(false);
  const isMounted = useMount();
  const dispatch = useDispatch();
  const uploadsUpdating = useSelector(getUploadUpdatingStatus);

  // Old admin console links use 'meeting' URL parameter
  const initialSearchId = getUrlParameter('meeting') || undefined;

  // initial fetch settings
  const initialPage = _get(initialListingParams, 'page', 1);
  const initialSort = _get(initialListingParams, 'sort', RecordingRequestObjectSort.SORT_DATE);
  const initialMeetingId = _get(initialListingParams, 'meetingId', initialSearchId);
  const initialMeetingName = _get(initialListingParams, 'meetingName');

  // Fetch criteria
  const [page, setPage] = useState(initialPage);
  const [sort, setSort] = useState<RecordingRequestObjectSort>(initialSort);
  const [meetingId, setMeetingId] = useState<string | undefined>(initialMeetingId);
  const [meetingName, setMeetingName] = useState<string | undefined>(initialMeetingName);

  const startTimestamp = useSelector(selectStartDate);
  const endTimestamp = useSelector(selectEndDate);

  useEffect(
    () => {
      if (canUpload) {
        startUploadUpdates();
        getUploadsStatus();
      }
      return () => {
        stopUploadUpdates();
      };
    },
    [canUpload]
  );

  useEventListener('blur', () => {
    stopUploadUpdates();
  });
  useEventListener('focus', () => {
    if (canUpload && !uploadsUpdating) {
      startUploadUpdates();
      getUploadsStatus();
    }
  });

  useEffect(
    () => {
      if (selectedMeeting && _get(selectedMeeting, 'name') && _get(selectedMeeting, 'name') !== UNKNOWN_MEETING_NAME) {
        setMeetingName(_get(selectedMeeting, 'name') || undefined);
      }
    },
    [selectedMeeting]
  );

  const fetchFeed = async () => {
    const vcShareToken = getUrlParameter('msharetoken');
    const requestObject: RecordingRequest = {
      feed: pageType,
      page,
      sort,
      timeline: RecordingRequestObjectTimeLine.TIMELINE_ANY,
      offset: (page - 1) * RecordingLimits.PAGE_SIZE,
      limit: RecordingLimits.PAGE_SIZE,
    };

    if (startTimestamp) {
      requestObject.startTimestamp = startTimestamp.unix();
    }

    if (endTimestamp) {
      requestObject.endTimestamp = endTimestamp.unix();
    }

    if (totalCount > 0) {
      requestObject.count = totalCount;
    }
    if (meetingId) {
      requestObject.meeting = meetingId;
    }
    if (vcShareToken) {
      requestObject.msharetoken = vcShareToken;
    }
    // @ts-ignore
    await dispatch(actions.safeNucleusRecordingAction(nucleusRecordingActions.fetchFeed(requestObject)));
    setShowPagination(true);
  };

  useEffect(
    () => {
      fetchFeed();
    },
    [pageType, page, sort, startTimestamp, endTimestamp, meetingId]
  );

  useEffect(
    () => {
      if (isProcessingUpload && processingUploadCount === 0) {
        setUploadIsComplete(true);
        setIsProcessingUpload(false);
        setTimeout(
          () => {
            if (isMounted.current) {
              setUploadIsComplete(false);
            }
          },
          5000
        );
      }
      if (processingUploadCount) {
        setIsProcessingUpload(true);
      }
    },
    [processingUploadCount, isProcessingUpload]
  );

  useEffect(
    () => {
      if (canUpload && processingUploadCount < previousProcessingCount) {
        fetchFeed();
      }
      if (processingUploadCount !== previousProcessingCount) {
        setPreviousProcessingCount(processingUploadCount);
      }
    },
    [canUpload, processingUploadCount, previousProcessingCount]
  );

  const handlePageChange = (pageNum: number) => {
    setPage(pageNum + 1);
  };

  const handleSortChange = (sortType: RecordingRequestObjectSort) => {
    setSort(sortType);
    setShowPagination(false); // remount pagination to reset state
    setPage(1);
  };

  const handleSearchSelect = (searchId?: string, searchText?: string)  => {
    setMeetingId(searchId ? searchId : undefined);
    setMeetingName(searchText ? searchText : undefined);
    setShowPagination(false); // remount pagination to reset state
    setPage(1);
  };

  const getUploadStatus = () => {
    if (uploadIsComplete) {
      return (
        <div className={classnames(styles.uploadStatus, styles.uploadSuccess)}>
          {intl.get('uploadStatusComplete')}
        </div>
      );
    }
    if (processingUploadCount) {
      return (
        <div className={styles.uploadStatus}>
          {processingUploadCount === 1
            ? intl.get('uploadStatusProcessingVideo')
            : intl.get('uploadStatusProcessingVideos', { count: processingUploadCount })}
        </div>
      );
    }
    return null;
  };

  const listingParams = {
    page,
    sort,
    meetingId,
    meetingName
  };
  return (
    <div className={styles.container}>
      <RecordingsToolbar
        pageType={pageType}
        initialSort={sort}
        canUpload={canUpload}
        uploadStatus={getUploadStatus()}
        handleSortChange={handleSortChange}
        handleSearchSelect={handleSearchSelect}
        meetingId={meetingId}
        meetingName={meetingName}
        currentUserRole={currentUserRole}
      />
      {_get(error, 'message') === 'FORBIDDEN' ? (
        <div className="contentBody">
          <div className={classnames(styles.forbidden, styles.loading)}>
            <Icon classes={['icon-record']} />
            <h4>{intl.get('recordingsNoAccess')}</h4>
          </div>
        </div>
      ) : (
        <div className="contentBody">
          {
            (isGridLayout) ?
              <RecordingsTileView isLoading={isLoading} pageType={pageType} recordingData={recordingData || []} listingParams={listingParams}/> :
              <RecordingsListItemView isLoading={isLoading} pageType={pageType} recordingData={recordingData || []} listingParams={listingParams}/>
          }
        </div>
      )}
      {showPagination && (
        <Pagination
          numOfPages={Math.max(Math.ceil(totalCount / RecordingLimits.PAGE_SIZE), 1)}
          onPageChange={handlePageChange}
          initialPage={page - 1}
        />
      )}
    </div>
  );
};

export default Recordings;
