import React, { SyntheticEvent, useState } from 'react';
import intl from 'react-intl-universal';
import * as styles from 'components/Settings/Sections.scss';
import * as passcodeStyles from './RequireMeetingPasscodes.scss';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { useDispatch, useSelector } from 'react-redux';
import { requiresPasscodesSetting } from 'selectors/accountSelectors';
import { setMeetingSettings } from 'thunks/groupSettingsThunks';
import { useMount } from 'hooks/useMount';
import _get from 'lodash/get';
import { selectLocale } from 'selectors/languageSelectors';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const meetingSettingsHelp = (locale: string = 'en') => `https://www.lifesize.com/${locale}/help/admin-console/account-settings/meeting-settings`;

const RequireMeetingPasscodes = () => {
  const meetingsRequirePasscode = useSelector(requiresPasscodesSetting);
  const [ requiresPasscodeState, setRequiresPasscodeState ] = useState(meetingsRequirePasscode);
  const [ settingsChangeError, setSettingsChangeError ] = useState(false);
  const locale = useSelector(selectLocale);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const dispatch = useDispatch();
  const isMounted = useMount();
  return (
    <section className={styles.container} data-test="settingsSection.chat">
      <article>
        <h6 className={styles.title}>{intl.get('meetingPasscodes')}</h6>
        <div className={styles.warning}>
          <span>{intl.get('passcodeDisabledOneTimeFeatures')} </span>
          <span><a href={meetingSettingsHelp(locale)} target="_blank">{intl.get('learnMore')}</a></span>
        </div>
        <ToggleButton
          classNameLabel={''}
          id={'requireMeetingPasscode'}
          label={intl.get('requirePasscodes')}
          leftLabel={false}
          hidenow={false}
          topLabel={false}
          disabled={!canEditSettings}
          field={{
            name: 'requireMeetingPasscode',
            value: requiresPasscodeState,
            onChange: async (newValue: SyntheticEvent) => {
              const currentState = requiresPasscodeState;
              setRequiresPasscodeState(!currentState);
              const result = await dispatch(setMeetingSettings({ 'requireMeetingPasscode': !meetingsRequirePasscode }));
              if (_get(result, 'type') !== String(setMeetingSettings.fulfilled)) {
                if (isMounted.current) {
                  setSettingsChangeError(true);
                  setRequiresPasscodeState(currentState);
                }
              }
            },
            onBlur: () => {return; }
          }}
        />
        {settingsChangeError && <div className={passcodeStyles.error}>{intl.get('meetingSettingFailure')}</div>}
      </article>
    </section>
  );
};

export default RequireMeetingPasscodes;
