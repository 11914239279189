import ToolBarButton from 'components/Common/ToolbarButton';
import React from 'react';
import intl from 'react-intl-universal';
import ExportButton from 'components/Common/ExportButton';
import { ButtonType, WaitButton } from 'components/Modal/ModalComponent/WaitButton';
import useExport from 'hooks/useExport';
import { ExportType } from 'interfaces/Downloads';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';

interface Props {
  showAddUserModal: () => void;
  showDeleteConfirmModal: () => void;
  canDelete: boolean;
  hasSelected: boolean;
}

const GroupContactsToolbar = (props: Props) => {
  const { showAddUserModal, canDelete, showDeleteConfirmModal, hasSelected } = props;
  const { handleExportData, loading } = useExport(ExportType.GROUP);

  const modifyUserPermission = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_USERS];
  const rolesFeatureFlag = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const canModifyUsers = rolesFeatureFlag ? modifyUserPermission : true;

  return (
    <div className="toolbar" data-test="toolbar">
      <div>
        <ExportButton
          disabled={false}
          onExportClick={handleExportData}
          isExporting={loading}
        />
        <WaitButton
          buttonText={intl.get('addGroupContact')}
          isBusy={false}
          isDisabled={!canModifyUsers}
          isSubmitButton={true}
          onSave={showAddUserModal}
          type={ButtonType.button}
        />
        <ToolBarButton
          clickHandler={showDeleteConfirmModal}
          disabledTooltipText={canModifyUsers ? intl.get('tooltipNoneSelectedGroupContacts') :  intl.get('cannotModifyGroupContacts')}
          isEnabled={canDelete && canModifyUsers}
          label={intl.get('delete')}
          showWarningText={!canDelete && hasSelected}
          tooltipIsOnRight={true}
          warningTooltipText={intl.get('tooltipDelete')}
        />
      </div>
    </div>
  );
};

export default GroupContactsToolbar;
