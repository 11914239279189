import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from '../../actions/modalActions';
import DeviceCustomization from '../../components/Modals/DeviceCustomization/DeviceCustomization';
import * as phoneHdPrefsSelector from '../../selectors/deviceCustomizationSelector';

const mapStateToProps = (state: Global) => {
  const urlSelector = phoneHdPrefsSelector.selectConfigUrl(state);
  return {
    url: urlSelector
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCustomization);
