import { Reducer } from 'redux';
import {
  DISMISS_IMPERSONATION_CODE,
  GENERATE_IMPERSONATION_CODE_SUCCESS,
  GENERATE_IMPERSONATION_CODE_FAILURE,
  SET_IMPERSONATION_SESSION_ACTIVE
} from 'actions/impersonationActions';
import { ImpersonationState, initialImpersonationState } from 'state/ImpersonationState';

const reducer: Reducer<ImpersonationState> = (state = initialImpersonationState, action) => {
  switch (action.type) {
    case DISMISS_IMPERSONATION_CODE:
      return {
        ...state,
        code: null,
        error: null
      };
    case GENERATE_IMPERSONATION_CODE_SUCCESS:
      return {
        ...state,
        code: action.payload.code
      };
    case GENERATE_IMPERSONATION_CODE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };
    case SET_IMPERSONATION_SESSION_ACTIVE:
      return {
        ...state,
        activeSession: true
      };
    default:
      return state;
  }
};

export default reducer;
