import { connect } from 'react-redux';
import { DataProps } from 'react-apollo';
import { LiveMeetingParticipantList } from 'components/LiveMeetings/LiveMeetingParticipantList/LiveMeetingParticipantList';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import actions from 'actions/sortActions';
import { withRouter } from 'react-router';
import { SetSortInterface } from 'interfaces/Tables';
import { Global } from 'state/Global';
import { selectSort } from 'selectors/sortSelectors';
import { ParticipantEntry } from 'interfaces/Dashboard';
import { SortDirection } from 'constants/queryConstants';
import { selectMappedParticipants } from 'selectors/liveMeetingParticipantsSelector';
import { LiveMeetingData } from 'interfaces/LiveMeeting';

function mapStateToProps(state: Global, ownProps: DataProps<LiveMeetingData>) {
  const sort = selectSort(state);
  const data = ownProps.data;
  const sortBy = _get(sort, 'sortBy', 'startTime');
  const order = _get(sort, 'order', SortDirection.DESC);
  const presenter = _get(data, 'liveMeetingByUUID.conferenceInfo.presentation.callId', null);
  const formattedOrder = order === SortDirection.DESC ? 'desc' : 'asc';
  const mappedParticipants = selectMappedParticipants(data as LiveMeetingData);
  const participants = _orderBy(mappedParticipants, (participant: ParticipantEntry) => [participant[sortBy]], [formattedOrder]);

  return {
    ...ownProps,
    error: _get(data, 'error'),
    presenter,
    participants,
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  setSort: (sort: SetSortInterface) => {
    dispatch(actions.setSort(sort));
  }
});

export default
  withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
  )
(LiveMeetingParticipantList));
