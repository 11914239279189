import _isEmpty from 'lodash/isEmpty';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { isTabletDevice } from 'utils/roomSystemUtils';
import { actions } from '../../actions/modalActions';
import UnlinkTabletConfirmModal from '../../components/Modals/UnlinkTabletConfirmModal/UnlinkTabletConfirmModal';
import * as modalSelector from '../../selectors/modalSelector';
import { Global } from '../../state/Global';
import { RoomSystem } from '../../typings/types';
import _memoize from 'lodash/memoize';
import _get from 'lodash/get';
import { remotingActions, remotingSelectors } from '@lifesize/clients.remoting';

interface LinkedDevice {
  UUID: string;
  type: string;
}

const getTablet = _memoize((linkedDevices: LinkedDevice[]) => {
  return linkedDevices.find((device: LinkedDevice) => isTabletDevice(device.type));
});

const mapStateToProps = (state: Global) => {
  const objectState = { ...state, app: state.app.toJS() };
  const roomSystem = modalSelector.selectPayload(state) as RoomSystem;
  const linkedDevices = _get(roomSystem, 'linkedDevices.records', []);
  const tablet = getTablet(linkedDevices);
  const mutationVariables = {
    deviceUUID: _get(tablet, 'deviceUUID', '') || _get(roomSystem, 'serialNumber', ''),
    UUID: _get(tablet, 'linkedDeviceUUID', ''),
    serialNumber: _get(roomSystem, 'serialNumber', '')
  };
  const pairingState = remotingSelectors.pairing.pairingState(objectState);
  const hasRoomName = !_isEmpty(roomSystem.displayName) && roomSystem.displayName.length;
  const confirmButtonText = intl.get('disconnect');
  let confirmTitle: string;

  if (hasRoomName) {
    confirmTitle = intl.get('disconnectTabletConfirmation', {
      roomName: roomSystem.displayName
    });
  } else {
    confirmTitle = intl.get('disconnectTabletConfirmationNoRoomName');
  }

  if (pairingState.linkingError) {
    // Unlink failed
    // console.log(JSON.stringify(_get(pairingState.linkingError, 'error.body', {})));
    return {
      mutationVariables,
      confirmTitle,
      confirmButtonText: confirmButtonText,
      errorMessage: intl.get('disconnectTabletFailed'),
      isBusy: false
    };
  }

  return {
    mutationVariables,
    roomSystem,
    confirmTitle,
    confirmButtonText: confirmButtonText,
    isBusy: pairingState.isUnlinking,
    errorMessage: null
  };

};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    closeModal: () => {
      // Remove an error state of pairing on close
      dispatch(remotingActions.pairing.resetPairingState());
      dispatch(actions.closeModal());
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UnlinkTabletConfirmModal);
