import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import * as styles from './MeetingFeatures.scss';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import {
  ROLE_SETTING_MODERATE_ALL_MEETINGS,
  ROLE_SETTING_RECORD_MEETINGS
} from 'constants/role';
import { Role, ROLE_PERMISSION } from 'interfaces/Role';
import useRoleChange from 'hooks/useRoleChange';
import { isAdminRole } from 'utils/permissionsUtils';
import { useSelector } from 'react-redux';
import {
  selectAccountPermissions
} from 'selectors/permissionsSelector';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_MODERATE_ALL_MEETINGS, FEATURE_FLAG_RECORD_ALL_MEETINGS } from 'constants/featureFlags';

export interface Props {
  role: Role;
}

export const MeetingFeatures = (props: Props) => {
  const {
    role
  } = props;

  const { permissions } = role;
  const isAdmin = isAdminRole(role);

  const { savePermissions } = useRoleChange(role);

  const hasModerateAllMeetingsSetting = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_MODERATE_ALL_MEETINGS);
  const hasRecordMeetingsSetting = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_RECORD_ALL_MEETINGS);

  const moderateAllMeetingsPermission = permissions && permissions[ROLE_PERMISSION.MODERATE_OWN_ACCOUNT_CONFERENCES];
  const recordMeetingsPermission = permissions && permissions[ROLE_PERMISSION.RECORD_OWN_ACCOUNT_CONFERENCES];

  const [ moderateAllMeetingsSetting, setModerateAllMeetingsSetting ] = useState(moderateAllMeetingsPermission);
  const [ recordMeetingsSetting, setRecordMeetingsSetting ] = useState(recordMeetingsPermission);

  const canEditRoles = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROLES];

  useEffect(
    () => {
      setModerateAllMeetingsSetting(moderateAllMeetingsPermission);
    },
    [moderateAllMeetingsPermission]
  );

  useEffect(
    () => {
      setRecordMeetingsSetting(recordMeetingsPermission);
    },
    [recordMeetingsPermission]
  );

  return (
    <div className={styles.container}>
      <h4 className={styles.header}>{intl.get('meetingFeatures')}</h4>
      {hasModerateAllMeetingsSetting && <>
        <div className={styles.toggleContainer}>
          <ToggleButton
            id={ROLE_SETTING_MODERATE_ALL_MEETINGS}
            leftLabel={false}
            disabled={isAdmin || !canEditRoles}
            hidenow={false}
            topLabel={false}
            field={{
              name: ROLE_SETTING_MODERATE_ALL_MEETINGS,
              value: moderateAllMeetingsSetting,
              onChange: () => {
                if (!canEditRoles) { return; }
                setModerateAllMeetingsSetting(!moderateAllMeetingsSetting);
                savePermissions({
                  [ROLE_PERMISSION.MODERATE_OWN_ACCOUNT_CONFERENCES]: !moderateAllMeetingsSetting
                });
              },
              onBlur: () => { return; }
            }}
          />
          <div className={styles.toggleLabel}>{intl.get('moderateAllMeetings')}</div>
        </div>
        <div className={styles.toggleSubLabel}>{intl.get('moderateAllMeetingsTip')}</div>
      </>}
      {hasRecordMeetingsSetting && <>
        <div className={styles.toggleContainer}>
          <ToggleButton
              id={ROLE_SETTING_RECORD_MEETINGS}
              leftLabel={false}
              hidenow={false}
              topLabel={false}
              disabled={isAdmin || !canEditRoles}
              field={{
                name: ROLE_SETTING_RECORD_MEETINGS,
                value: recordMeetingsSetting,
                onChange: () => {
                  if (!canEditRoles) { return; }
                  setRecordMeetingsSetting(!recordMeetingsSetting);
                  savePermissions({
                    [ROLE_PERMISSION.RECORD_OWN_ACCOUNT_CONFERENCES]: !recordMeetingsSetting
                  });
                },
                onBlur: () => { return; }
              }}
          />
          <div className={styles.toggleLabel}>{intl.get('recordMeetings')}</div>
        </div>
        <div className={styles.toggleSubLabel}>{intl.get('recordMeetingsTip')}</div>
      </>}
    </div>
  );
};
