import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import unlinkTabletMutation from 'queries/roomSystems/unlinkTabletMutation.gql';
import { RoomSystem } from '../../../typings/types';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';

interface Props {
  closeModal: () => void;
  confirmTitle: string;
  confirmButtonText: string;
  roomSystem: RoomSystem;
  errorMessage: string;
  mutationVariables: {
    deviceUUID: string;
    UUID: string;
    serialNumber: string;
  };
}

export class UnlinkTabletConfirmModal extends Component<Props> {
  render() {
    const {
      errorMessage,
      closeModal,
      confirmTitle,
      confirmButtonText,
      mutationVariables,
    } = this.props;
    return (
      <Mutation mutation={unlinkTabletMutation} refetchQueries={['listRoomsQuery']}>
        {(unlinkTablet, { loading, error }) => (
          <ConfirmModal
            confirmTitle={confirmTitle}
            confirmButtonText={confirmButtonText}
            isBusy={loading}
            errorMessage={(error) ? errorMessage : undefined}
            closeModal={closeModal}
            confirmAction={async () => {
              if (
                mutationVariables &&
                mutationVariables.deviceUUID &&
                mutationVariables.UUID &&
                mutationVariables.serialNumber
              ) {
                try {
                  await unlinkTablet({
                    variables: mutationVariables
                  });
                  closeModal();
                } catch (e) {
                  // console.log(e);
                }
              }
            }}
          />
        )}
      </Mutation>
    );
  }

}

export default UnlinkTabletConfirmModal;
