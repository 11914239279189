import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import _get from 'lodash/get';
import { RouteComponentProps } from 'react-router';
import { dataWarehouseSelectors, vcApiActions } from '@lifesize/nucleus';
import { mapV2ViewerList, parseV2DataForDisplay, parseQuestionsForDisplay } from 'utils/liveStreamUtils';
import liveStreamDetailsQuery from 'queries/dashboard/LiveStreamDetailsQuery.gql';
import LiveStreamSummary from 'components/Meetings/LiveStreamsTab/LiveStreamSummary';

const getStreamId = (match: {}) => {
  return _get(match, 'params.id');
};

const mapStateToProps = (state: Global) => {
  const streamDetails = dataWarehouseSelectors.streamDetails(state);
  return {
    ...streamDetails,
    questions: parseQuestionsForDisplay(streamDetails.questions)
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  fetchQuestionData: (id: string) => {
    dispatch(vcApiActions.getQnATranscript(id));
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(liveStreamDetailsQuery, {
    options: (props: RouteComponentProps<{}>) => {
      const streamId = getStreamId(props.match);
      return {
        fetchPolicy: 'cache-and-network',
        variables : {
          uuid: streamId
        }
      };
    },
    // tslint:disable-next-line
    props: ({ data, ownProps }: any) => {
      const { loading, error } = data;
      const summary = _get(data, 'liveStreamDetails.summary');
      const metadata = parseV2DataForDisplay([summary ? summary : {}])[0];
      const viewers = mapV2ViewerList(_get(data, 'liveStreamDetails.data', []), metadata.startDate);

      return {
        ...ownProps,
        viewers,
        viewersError: error,
        loading,
        metadata: summary ? metadata : {},
        error
      };
    },
  }),
)(LiveStreamSummary);
