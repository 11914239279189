import { proxyActions } from '@lifesize/nucleus';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ResetPasswordActions, actions } from 'actions/resetPasswordActions';
import { Methods } from 'interfaces/HTTP';

const ADMIN_RESET_PASSWORD_URI_SUFFIX = 'admin/requestUserResetPassword';
const USER_RESET_PASSWORD_URI_SUFFIX = 'requestResetPassword';

const searchCloudUsersMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return async (action: PayloadAction<string, object>) => {
        let queryParams = {};
        let uriSuffix;
        if (action.type === ResetPasswordActions.RESET_PASSWORD && action.payload) {
          uriSuffix = ADMIN_RESET_PASSWORD_URI_SUFFIX;
          queryParams = { UUID: action.payload };
        } else if (action.type === ResetPasswordActions.RESET_MY_PASSWORD) {
          uriSuffix = USER_RESET_PASSWORD_URI_SUFFIX;
        } else {
          return next(action);
        }

        // Handle RESET_PASSWORD and RESET_MY_PASSWORD API calls.
        try {
          store.dispatch(actions.resetPasswordStart());
          const jsonResponse = await store.dispatch(
            proxyActions.proxyApiCall(
              'lifesize.login',
              uriSuffix,
              Methods.POST,
              queryParams
            )
          );

          const response = JSON.parse(jsonResponse);
          if (response.statusCode && response.statusCode < 300) {
            return store.dispatch(actions.resetPasswordSuccess());
          } else {
            return store.dispatch(actions.resetPasswordFail());
          }
        } catch (e) {
          return store.dispatch(actions.resetPasswordFail());
        }
      };
    };
  };

export default (searchCloudUsersMiddleware as Middleware);
