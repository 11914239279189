import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { recordingsAuthActions } from '@lifesize/nucleus';
import { FETCH_RECORDINGS_AUTH } from '../actions/recordingsAuthActions';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';

const searchMeetingsMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return (action: PayloadAction<string, object>) => {
        if (action.type !== FETCH_RECORDINGS_AUTH) {
          return next(action);
        }
        return store.dispatch(recordingsAuthActions.getVCSettings());
      };
    };
  };

export default (searchMeetingsMiddleware as Middleware);
