import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Mutation } from 'react-apollo';
import { featureSelectors } from '@lifesize/nucleus';
import { useSelector } from 'react-redux';
import { ButtonType, WaitButton } from 'components/Modal/ModalComponent/WaitButton';
import DeviceSubscriptionPane from 'components/DeviceSubscriptionPane/DeviceSubscriptionPane';
import AlexaRegistrationSetting from 'containers/AlexaRegistrationSetting/AlexaRegistrationSettingContainer';
import { Button } from 'semantic-ui-react';
import { RoomSystem } from 'typings/types';
import { formatUnixDateLongDisplay } from 'utils/date-time-utils';
import classes from './SettingsTabComponent.scss';
import updateRoomSystemMutation from 'queries/roomSystems/updateRoomSystemMutation.gql';
import listRoomsQuery from 'queries/roomSystems/listRoomsQuery.gql';
import _get from 'lodash/get';
import { Formik } from 'formik';
import _isArray from 'lodash/isArray';
import { Logger } from 'logger';
import { ICON_300 } from 'constants/roomSystemConstants';
import { FEATURE_FLAG_HIDE_DEVICE_SUBSCRIPTION, FEATURE_FLAG_LIFESIZE_CONNECT, FEATURE_FLAG_LIFESIZE_CONNECT_LINK } from 'constants/featureFlags';
import { isConnectSupported } from 'utils/roomSystemUtils';
import ConnectComponent from './Connect/ConnectComponent';
import HideFromDirectoryForm from './HideFromDirectoryForm';
import { roomSystemSupportsDSS } from 'utils/roomSystemUtils';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface  Props {
  roomSystem: RoomSystem;
  isBusy: boolean;
  getSubscription: (extension: string) => void;
  onSubscribeClick: (extension: string) => void;
  onUnSubscribeClick: (extension?: string | null) => void;
  getCredentials: () => Promise<void>;
  subscriptionEmail: string | null;
  subscriptionLookupFailed: boolean;
  unsubscribeFailed: boolean;
  showSerialNumber: boolean;
  showFirmwareVersion: boolean;
  showConnectionDate: boolean;
  showHideFromDirectory: boolean;
  hasCalendarCapability: boolean;
  hasAlexaIntegration: boolean;
  needsAlexaAuthentication: boolean;
  hasOverview: boolean;
  noSettings: boolean;
}

interface RoomSystemUpdate {
 data: {
   updateRoomSystem: {
     hidden: boolean,
     UUID: string
   }
 };
}

const SettingsTabComponent = (props: Props) => {
  const hasLifesizeConnect = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_LIFESIZE_CONNECT);
  const hasSoftwareConnectOption = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_LIFESIZE_CONNECT_LINK);
  useEffect(
    () => {
      if (props.hasCalendarCapability) {
        props.getSubscription(_get(props, 'roomSystem.extension', ''));
      }
    },
    []
  );

  const {
    roomSystem,
    onSubscribeClick,
    onUnSubscribeClick,
    subscriptionEmail,
    isBusy,
    subscriptionLookupFailed,
    unsubscribeFailed,
    showConnectionDate,
    showFirmwareVersion,
    showHideFromDirectory,
    showSerialNumber,
    hasCalendarCapability,
    hasAlexaIntegration,
    needsAlexaAuthentication,
    getCredentials,
    hasOverview,
    noSettings,
  } = props;

  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];
  const deviceSubscriptionHidden = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_HIDE_DEVICE_SUBSCRIPTION);
  const canLifesizeConnect = isConnectSupported(roomSystem) && hasLifesizeConnect;
  const dssSupported = roomSystemSupportsDSS(roomSystem);

  const showLifesizeConnect = () => {
    if (canLifesizeConnect) {
      // Icon300 only has the software option
      return roomSystem.platform === ICON_300 ? hasSoftwareConnectOption : true;
    }
    return false;
  };
  useEffect(() => {
    if (needsAlexaAuthentication) {
      getCredentials();
    }
  },        [needsAlexaAuthentication]);

  return (
    <div className={classes.container}>
      {noSettings && <div className={classes.pane}>
        <div className={classes.contentContainer}>
          <div className={classes.paneTitle}>
            {intl.get('notConfigurable')}
          </div>
        </div>
      </div>}
      {hasOverview && <div className={classes.pane}>
        <div className={classes.contentContainer}>
          <div className={classes.paneTitle} data-test="overview">
            {intl.get('overview')}
           
          </div>
          <div className={classes.overviewContainer}>
            {/*Serial Number*/}
            {showSerialNumber &&
            <div className={classes.item} >
              <div className={classes.itemLabel} data-test="serialNumber">{intl.get('serialNumber')}</div>
              <div>{roomSystem.serialNumber}</div>
            </div>
            }

            {/*Current Firmware Version*/}
            {showFirmwareVersion &&
            <div className={classes.item}>
              <div className={classes.itemLabel} data-test="firmwareVersion" >{intl.get('firmwareVersion')}</div>
              <div>{roomSystem.firmware}</div>
            </div>
            }

            {/*Connection Date*/}
            {showConnectionDate &&
            <div className={classes.item}>
              <div className={classes.itemLabel} data-test="connectionDate">{intl.get('connectionDate')}</div>
              <div>{formatUnixDateLongDisplay(parseInt(_get(roomSystem, 'pairingTimeMillis') || '0', 10))}</div>
            </div>
            }

            {/*Hide from Directory*/}
            {showHideFromDirectory &&
            <Mutation
              mutation={updateRoomSystemMutation}
              // refetchQueries={['listRoomsQuery']} takes a long time, so the change will not yet be realized if the modal is immediately reopened
              // update forces an immediate mutation of the cache with the result of the mutation
              update={(cache: { readQuery: Function, writeQuery: Function }, { data: { updateRoomSystem } }: RoomSystemUpdate ) => {
                // tslint:disable-next-line
                const response: any = cache.readQuery({
                  query: listRoomsQuery
                });
                const directoryListRoomSystem = response.directoryListRoomSystem;
                directoryListRoomSystem.records = directoryListRoomSystem.records.map((r: RoomSystem) => {
                  if (updateRoomSystem.UUID === r.userUUID) {
                    return { ...r, hidden: updateRoomSystem.hidden };
                  }
                  return r;
                });

                cache.writeQuery({
                  query: listRoomsQuery,
                  data: { directoryListRoomSystem: directoryListRoomSystem }
                });
              }}
            >
              {(updateRoomSystem) => {
                const isHidden = !!_get(roomSystem, 'hidden');
                return (
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      hidden: isHidden
                    }}
                    onSubmit={async (values, { resetForm, setFieldError, setFieldValue }) => {
                      if (!canEditRoomSystems) { return; }
                      try {
                        const { hidden } = values;
                        await updateRoomSystem(
                          {
                            variables: {
                              device: {
                                hidden,
                                UUID: _get(roomSystem, 'userUUID')
                              }
                            }
                          });
                        resetForm(values); // This will clear errors and touched, set isSubmitting to false, isValidating to false
                      } catch (error) {
                        // {extensions: {code: "INTERNAL_SERVER_ERROR", exception: {…}}, locations: [{…}], message: "Room not found.", path: ["updateRoomSystem"]}
                        const defaultMessage = 'Unknown Failure';
                        const errorObj = _isArray(_get(error, 'graphQLErrors')) ? error.graphQLErrors[0] : {};
                        const message = _get(errorObj, 'message') || defaultMessage;
                        Logger.info(`Error hiding/unhiding a room system: ${JSON.stringify(error)}`);
                        // revert the toggle and show the error
                        await setFieldValue('hidden', isHidden, false);
                        setFieldError('hidden', `${intl.get('genericErrorMessage')} (${message})`);
                      }
                    }}
                    render={({ errors, handleChange, submitForm }) => (
                      <HideFromDirectoryForm
                        errors={errors}
                        submitForm={submitForm}
                        handleChange={handleChange}
                      />
                    )}
                  />
                );
              }}
            </Mutation>
            }
          </div>
        </div>
      </div>}
      {!deviceSubscriptionHidden && dssSupported && <div className={classes.pane} >
        <DeviceSubscriptionPane deviceSubscription={roomSystem?.deviceSubscription} />
      </div>}
      {hasCalendarCapability &&
        <div className={classes.pane}>
          <div className={classes.contentContainer}>
            <div className={classes.paneTitle} data-test="calendar">
              {intl.get('calendar')}
            </div>
            {subscriptionEmail ? (
              <div className={classes.calendarContainer}>
                <div className={classes.iconNotSubscribed}>
                  {intl.get('calendarSubscribedMessageV2', { email: subscriptionEmail })}
                </div>
                <div className={classes.buttonContainer}>
                  <WaitButton
                    buttonText={intl.get('unsubscribe')}
                    id="unsubscribe"
                    isBusy={isBusy}
                    isDisabled={isBusy || !canEditRoomSystems}
                    onSave={(onUnSubscribeClick && canEditRoomSystems) ? () => onUnSubscribeClick(roomSystem.extension || '') : undefined}
                    type={ButtonType.button}
                  />
                </div>
                {unsubscribeFailed &&
                <div className={classes.error}>{intl.get('calendarUnsubscribeError')}</div>
                }
              </div>
            ) : (
              <div className={classes.calendarContainer}>
                <div className={classes.iconNotSubscribed}>{intl.get('noCalendarMessage')}</div>
                <div className={classes.buttonContainer} data-test="subscribe">
                  <Button
                    id="subscribe"
                    name="btnSubscribe"
                    onClick={() => canEditRoomSystems ? onSubscribeClick(roomSystem.extension || '') :  undefined}
                    role="button"
                    type="button"
                    disabled={!canEditRoomSystems}
                  >
                    {intl.get('subscribe')}
                  </Button>
                </div>
                {subscriptionLookupFailed &&
                <div className={classes.error}>{intl.get('calendarSubscriptionLookupError')}</div>
                }
              </div>
            )}
          </div>
        </div>
      }
      {showLifesizeConnect() && (
        <ConnectComponent roomSystem={roomSystem} />
      )}
      {hasAlexaIntegration &&
        (<div className={classes.pane}>
          <AlexaRegistrationSetting serialNumber={roomSystem.serialNumber}/>
        </div>)
      }
    </div>
  );
};

export default SettingsTabComponent;
