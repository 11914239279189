export const FEATURE_FLAG_ENABLE_PAST_MEETINGS = 'ADMIN_EnablePastMeetings';
export const FEATURE_FLAG_LIFESIZE_CONNECT = 'ADMIN_LifesizeConnect';
export const FEATURE_FLAG_LIFESIZE_CONNECT_LINK = 'ADMIN_LifesizeConnectLink';
export const FEATURE_FLAG_BILLING_PORTAL = 'ADMIN_BillingPortal';
export const FEATURE_FLAG_ADVANCED_SFU_LAYOUTS = 'ADMIN_AdvancedSFULayouts';
export const FEATURE_FLAG_DEFAULT_HIDE_MEETINGS = 'ADMIN_DefaultHideMeetings';
export const FEATURE_FLAG_AUTO_RECORD = 'ADMIN_AutoRecord';
export const FEATURE_FLAG_MULTIPLE_MODERATORS = 'ADMIN_MultiModerator';
export const FEATURE_FLAG_REMOVE_OLD_DASHBOARD = 'ADMIN_RemoveOldDashboard';
export const FEATURE_FLAG_ROLE_MANAGEMENT = 'ADMIN_RoleManagement';
export const FEATURE_FLAG_MODERATE_ALL_MEETINGS = 'ADMIN_ModerateAllMeetings';
export const FEATURE_FLAG_RECORD_ALL_MEETINGS = 'ADMIN_RecordAllMeetings';
export const FEATURE_FLAG_WAITING_ROOM = 'ADMIN_WaitingRoom2';
export const FEATURE_FLAG_EXPORT_RECORDING = 'ADMIN_EnableExportRecording';
export const FEATURE_FLAG_HIDE_DEVICE_SUBSCRIPTION = 'ADMIN_HideDeviceSubscription';
