import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { InfiniteTable } from 'components/InfiniteTable/InfiniteTable';
import { selectFilterString } from 'selectors/filterSelectors';
import { selectPageNumber } from 'selectors/paginationSelectors';
import { selectIsSelectAll, selectSelection } from 'selectors/selectionSelectors';

const mapStateToProps = (state: Global) => {
  const hasSearchString = !!selectFilterString(state).length;
  return {
    allRowsSelected: selectIsSelectAll(state),
    hasSearchString: hasSearchString,
    pageNumber: selectPageNumber(state),
    selectionArray: selectSelection(state),
  };
};

export default connect(mapStateToProps, () => ({}))(InfiniteTable);
