import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { CallHistory } from 'components/Drilldowns/CallHistory/CallHistory2';
import { selectFilterString } from 'selectors/filterSelectors';
import { selectPageNumber } from 'selectors/paginationSelectors';
import { selectSort } from 'selectors/sortSelectors';
import { Global } from 'state/Global';
import actions from 'actions/sortActions';
import _get from 'lodash/get';
import CallHistoryQuery from 'queries/dashboard/CallHistoryQuery.gql';
import { selectDashboardState } from 'selectors/dashboardSelectors';

interface ComponentProps {
  startTimestamp: number;
  endTimestamp: number;
  searchString: string;
  sort: {
    order: string;
    sortBy: string;
  };
}

interface SetSortInterface {
  sortBy: string;
  order: string;
}

function mapStateToProps(state: Global) {
  const dashboardState = selectDashboardState(state);
  const pageNumber = selectPageNumber(state);
  const searchString = selectFilterString(state);
  const sort = selectSort(state);

  const dateStart = new Date(dashboardState.startDateMoment.format('YYYY/MM/DD'));
  dateStart.setHours(0);
  dateStart.setMinutes(0);
  dateStart.setSeconds(0);
  const dateEnd = new Date(dashboardState.endDateMoment.format('YYYY/MM/DD'));
  dateEnd.setHours(23);
  dateEnd.setMinutes(59);
  dateEnd.setSeconds(59);
  return {
    startTimestamp: dateStart.getTime() / 1000,
    endTimestamp: dateEnd.getTime() / 1000,
    searchString,
    sort,
    pageNumber
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  setSort: (sort: SetSortInterface) => dispatch(actions.setSort(sort))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(CallHistoryQuery, {
    options: (props: ComponentProps) => ({
      fetchPolicy: 'network-only',
      variables: {
        startTimestamp: props.startTimestamp,
        endTimestamp: props.endTimestamp,
        sortBy: props.sort.sortBy ? props.sort.sortBy : undefined,
        order: props.sort.order ? props.sort.order : undefined,
        search: props.searchString ? props.searchString : undefined
      }
    }),
    // tslint:disable-next-line
    props: ({ data, ownProps }: any) => {
      return {
        ...ownProps,
        data,
        fetchMoreRows: () => {
          // If directory list isn't set our initial load hasn't finished yet, so we
          // can't fetch more. We also bail if there are no more records to load.
          if (!_get(data, 'paginatedCallHistory.metadata.cursor', false)) {
            return Promise.resolve({});
          }

          return data.fetchMore({
            variables: {
              cursor: _get(data, 'paginatedCallHistory.metadata.cursor')
            }, // Pass in the previous cursor to our query.
            // tslint:disable-next-line
            updateQuery: (prev: any, {fetchMoreResult}: any) => {
              // Merge our new results into previous cached results.
              if (_get(fetchMoreResult, 'errors', false)) {
                throw _get(fetchMoreResult, 'errors', false);
              }
              const previousDirectoryList = _get(prev, 'paginatedCallHistory', {});
              const fetchMoreResultDirectoryList = _get(fetchMoreResult, 'paginatedCallHistory', {});
              return {
                ...prev,
                paginatedCallHistory: {
                  ...previousDirectoryList,
                  metadata: _get(fetchMoreResultDirectoryList, 'metadata', {}),
                  records: [
                    ..._get(previousDirectoryList, 'records', []),
                    ..._get(fetchMoreResultDirectoryList, 'records', []),
                  ],
                },
              };
            },
          });
        },
      };
    },
  }),
)(CallHistory);
