import React, { useMemo } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import { DeviceMonitoringStatus, RoomSystem } from 'typings/types';
import { formatDateTimeFromUnix, secondsToMilliseconds } from 'utils/date-time-utils';
import _get from 'lodash/get';
import MonitoringRow from './MonitoringRow';
import classes from './MaintenanceTabComponent.scss';
import UpgradeManagementComponent from './UpgradeManagementComponent';
import UploadCoronerComponent from './UploadCoronerComponent';
import Icon from 'components/Common/Icon';
import ResetDevicePasswordPane from 'components/RoomSystem/MaintenanceTab/ResetDevicePasswordPane/ResetDevicePasswordPane';
import { MONITORING_LINE_ITEMS } from 'constants/roomSystemConstants';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  canReboot: boolean;
  showReboot: boolean;
  showUpload: boolean;
  canReset: boolean;
  showReset: boolean;
  canPerformUpgradeManagement: boolean;
  roomSystem: RoomSystem;
  hasMonitoringData: boolean;
  deviceMonitoring: DeviceMonitoringStatus;
  showResetPassword: boolean;
  reboot: () => void;
  reset: () => void;
}

const showMetric = (metric: object) => {
  return !!metric && _get(metric, 'value') !== null;
};

const MaintenanceTabComponent = (props: Props) => {
  const {
    deviceMonitoring,
    reboot,
    reset,
    hasMonitoringData,
    canPerformUpgradeManagement,
    showReboot,
    showUpload,
    canReboot,
    showReset,
    canReset,
    showResetPassword,
    roomSystem
  } = props;

  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  const monitoringStatusUpdatedTimestamp: number | null = useMemo(
    () => hasMonitoringData
      ? MONITORING_LINE_ITEMS.reduce(
        (acc, cur) => {
          const timestamp = _get(deviceMonitoring, `metrics[${cur}].timestamp`, 0);
          return timestamp > acc ? timestamp : acc;
        },
        0)
      : null,
    [hasMonitoringData, deviceMonitoring]
  );
  const errorCount: number = useMemo(
    () => hasMonitoringData
      ? MONITORING_LINE_ITEMS.reduce(
        (acc, cur) => {
          if (_get(deviceMonitoring, `metrics[${cur}].value`) === false) {
            return acc + 1;
          }
          return acc;
        },
        0)
      : 0,
    [hasMonitoringData, deviceMonitoring]
  );

  return (
    <div className={classes.container}>
      {hasMonitoringData && (
        <div className={classes.pane}>
          <div className={classes.contentContainer}>
            <div className={classes.paneHeader}>
              <div className={classes.paneTitle}>
                {intl.get('monitoring')}
              </div>
              {monitoringStatusUpdatedTimestamp && (
                <div className={classes.paneHeaderStatusItalic}>
                  {intl.get('statusUpdated', { dateAndTime: formatDateTimeFromUnix(secondsToMilliseconds(monitoringStatusUpdatedTimestamp)) })}
                </div>
              )}
            </div>
            <div className={classes.paneBody}>
              <div className={classes.healthContainer}>
                <div className={classes.healthStatusCol}>
                  <div className={classes.heading}>
                    {intl.get('healthStatusWithColon')}
                  </div>
                  <div className={`${classes.healthIconContainer} ${errorCount ? classes.orange : classes.green}`}>
                    {errorCount ? <Icon classes={['icon-exclamation-outline']}/> : <Icon classes={['icon-good']}/>}
                  </div>
                  {errorCount ? (
                    <div className={classes.flexColCenter}>
                      <div>{intl.get('warning')}</div>
                      <div
                        className={`${classes.errorCountContainer} ${classes.smallGray} ${classes.italic}`}
                      >
                        {errorCount === 1 ? intl.get('error1Found') : intl.get('errorManyFound', { errorCount })}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {intl.get('good')}
                    </div>
                  )}
                </div>
                <div className={classes.healthDetailsCol}>
                  <div className={classes.tableContainer}>
                    <table className={classes.table}>
                      <thead>
                      <tr>
                        <th className={classes.itemColumn}/>
                        <th className={`${classes.statusColumn} ${classes.heading}`}>
                          {intl.get('status')}
                        </th>
                        <th className={`${classes.dateColumn} ${classes.heading}`}>
                          {intl.get('tableHeaderDate')}
                        </th>
                        <th className={classes.heading}>
                          {intl.get('details')}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                        {showMetric(_get(deviceMonitoring, 'metrics.online')) && (
                          <MonitoringRow
                            icon={<Icon classes={['icon-room-system']}/>}
                            label={intl.get('availability')}
                            isOk={_get(deviceMonitoring, 'metrics.online.value')}
                            timestamp={_get(deviceMonitoring, 'metrics.online.timestamp')}
                            errorTitle={intl.get('systemDisconnected')}
                            errorDescription={intl.get('systemDisconnectedMoreThan5Minutes')}
                          />
                        )}
                        {showMetric(_get(deviceMonitoring, 'metrics.displayConnected')) && (
                          <MonitoringRow
                            icon={<Icon classes={['icon-ic-display']}/>}
                            label={intl.get('display')}
                            isOk={_get(deviceMonitoring, 'metrics.displayConnected.value')}
                            timestamp={_get(deviceMonitoring, 'metrics.displayConnected.timestamp')}
                            errorTitle={intl.get('displayDisconnected')}
                            errorDescription={intl.get('displayDisconnectedMoreThan5Minutes')}
                          />
                        )}
                        {showMetric(_get(deviceMonitoring, 'metrics.cameraConnected')) && (
                          <MonitoringRow
                            icon={<Icon classes={['icon-video']}/>}
                            label={intl.get('camera')}
                            isOk={_get(deviceMonitoring, 'metrics.cameraConnected.value')}
                            timestamp={_get(deviceMonitoring, 'metrics.cameraConnected.timestamp')}
                            errorTitle={intl.get('cameraDisconnected')}
                            errorDescription={intl.get('cameraDisconnectedMoreThan5Minutes')}
                          />
                        )}
                        {showMetric(_get(deviceMonitoring, 'metrics.micConnected')) && (
                          <MonitoringRow
                            icon={<Icon classes={['icon-mic']}/>}
                            label={intl.get('microphone')}
                            isOk={_get(deviceMonitoring, 'metrics.micConnected.value')}
                            timestamp={_get(deviceMonitoring, 'metrics.micConnected.timestamp')}
                            errorTitle={intl.get('microphoneDisconnected')}
                            errorDescription={intl.get('microphoneDisconnectedMoreThan5Minutes')}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showReboot && <div className={classes.pane}>
        <div className={classes.contentContainer}>
          <div className={classes.paneTitle} data-test="rebootTitle">
            {intl.get('rebootSystem')}
          </div>
          <div className={classes.paneBody}>
            <div className={classes.textContainer}>{intl.get('rebootYourRoomSystem')}</div>
            <div className={classes.buttonContainer}>
              <Button
                id="rebootBtn"
                name="rebootBtn"
                onClick={reboot}
                role="button"
                type="button"
                disabled={!canReboot || !canEditRoomSystems}
              >
                {intl.get('reboot')}
              </Button>
            </div>
          </div>
        </div>
      </div>}
      {showUpload && <UploadCoronerComponent roomSystem={roomSystem} />}

      {showResetPassword && <ResetDevicePasswordPane roomSystem={roomSystem}/>}

      {showReset && <div className={classes.pane}>
        <div className={classes.contentContainer}>
          <div className={classes.paneTitle} data-test="resetTitle">
            {intl.get('resetSystemSettings')}
          </div>
          <div className={classes.paneBody}>
            <div className={classes.textContainer}>{intl.get('resetYourSystem')}</div>
            <div className={classes.buttonContainer}>
              <Button
                id="resetBtn"
                name="resetBtn"
                onClick={reset}
                role="button"
                type="button"
                disabled={!canReset || !canEditRoomSystems}
              >
                {intl.get('reset')}
              </Button>
            </div>
          </div>
        </div>
      </div>}

      {canPerformUpgradeManagement && <UpgradeManagementComponent roomSystem={roomSystem} />}
    </div>
  );
};

export default MaintenanceTabComponent;
