import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import * as styles from './CopyToClipboardButton.scss';
import classnames from 'classnames';
import logger from 'utils/logger';
import { useTimeout } from 'hooks/useTimeout';

interface Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onCopy?: (result: boolean) => void;
  getTextOnClick?: () => Promise<string>;
  text?: string;
}

const fallbackCopyTextToClipboard = (text: string, confirmResult: (result: boolean) => void) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  // IE11: if you focus on an element out of view, the page goes blank and does not recover when the element is removed,
  // so position it absolutely, with no opacity or size
  textArea.style.opacity = '0';
  textArea.style.position = 'absolute';
  textArea.style.top = '0px';
  textArea.style.left = '0px';
  textArea.style.height = '0px';
  textArea.style.width = '0px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    confirmResult(true);
  } catch (err) {
    logger.warn('Fallback failed to copy to clipboard', err);
    confirmResult(false);
  }
  document.body.removeChild(textArea);
};

let confirmationTimeout: number | undefined;

const CopyToClipboardButton = (props: Props) => {
  const { text, onCopy, children, disabled, className, getTextOnClick } = props;
  const [ showConfirmation, setShowConfirmation ] = useState(false);

  useEffect(
    () => {
      // reset state if text changes
      setShowConfirmation(false);
    },
    [text]
  );

  const resetConfirmation = useTimeout(() => setShowConfirmation(false), 2000);

  const confirmResult = (wasSuccessful: boolean) => {
    window.clearTimeout(confirmationTimeout); // cancel the previous timeout if one was still running
    setShowConfirmation(true);
    resetConfirmation();
    if (onCopy) {
      onCopy(wasSuccessful);
    }
  };

  const copyTextToClipboard = async (e: SyntheticEvent) => {
    e.preventDefault();
    let textToCopy = text || '';
    if (getTextOnClick) {
      textToCopy = await getTextOnClick();
    }
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(textToCopy, confirmResult);
      return;
    }
    try {
      await navigator.clipboard.writeText(textToCopy);
      confirmResult(true);
    } catch (err) {
      logger.warn('Async: Could not copy text: ', err);
      confirmResult(false);
    }
  };

  return (
    <button
      className={classnames(styles.button, className)}
      disabled={!!disabled}
      name="btnCopyToClipboard"
      onClick={copyTextToClipboard}
      role="button"
      type="button"
    >
      <div className={classnames('absolute', styles.confirmation, showConfirmation && styles.visible)}>{intl.get('copied')}!</div>
      {children
        ? children
        : <Icon classes={[showConfirmation ? 'icon-copy' : 'icon-copy-outline']} />
      }
    </button>
  );
};

export default CopyToClipboardButton;
