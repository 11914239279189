import React from 'react';
import * as styles from './RoleFields.scss';

export interface Props {
  value: string;
}

export const DummyField = ({ value }: Props) =>
  (
    <input
      disabled={true}
      className={styles.disabledInput}
      value={value}
    />
  );
