import React from 'react';
import intl from 'react-intl-universal';
import SearchBar from '../../../containers/Search/SearchBar';
import { DWHCallHistoryResponseType } from '../../../interfaces/Dashboard';
import { exportToCsv } from '../../../utils/csv-utils';
import { parseCallHistoryDataForExport } from '../../../utils/exportUtils';
import ListViewLayout from '../../ListViewLayout/ListViewLayout';
import DrilldownHeader from '../Common/DrilldownHeader';
import { CallHistoryTable } from './CallHistoryTable';

interface Props {
  title: string;
  data: Array<DWHCallHistoryResponseType>;
  error: string;
  isFetching: boolean;
  pageNumber: number;
  numberOfPages: number;
  fetchListData: Function;
}

class CallHistory extends React.Component<Props> {

  handleExportToCsv = () => {
    const exportData = [];
    const sort = this.props.data.sort(
      (a: DWHCallHistoryResponseType, b: DWHCallHistoryResponseType) => {
        return b.durationMinutes - a.durationMinutes;
      });
    for (let user of sort) {
      exportData.push(parseCallHistoryDataForExport(user));
    }
    exportToCsv(intl.get('callHistory'), exportData);
  }

  render() {
    const {title, isFetching, numberOfPages, pageNumber, error, fetchListData, data} = this.props;
    return (
        <ListViewLayout
          isFetching={isFetching}
          title={title}
          numberOfPages={numberOfPages}
          error={error}
          fetchListData={fetchListData}
          hideHeader={false}
        >
          <SearchBar/>

          <DrilldownHeader
            fetchData={fetchListData}
            onExportClick={this.handleExportToCsv}
          />

          {isFetching ? null : <CallHistoryTable data={data} pageNumber={pageNumber}/>}

        </ListViewLayout>
    );

  }
}

export { CallHistory };
