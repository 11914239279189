import React, { SyntheticEvent, useState, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react';
import { getByPlatform, getByDevice } from 'actions/upgradeManagementActions';
import Icon from 'components/Common/Icon';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { Tooltip } from 'components/tooltip/Tooltip';
import { RoomSystem } from 'typings/types';
import { isPlatformGems, channelToDisplayText, getUpgradeManagementPlatform, getFirmwareVersion } from 'utils/roomSystemUtils';
import { useMount } from 'hooks/useMount';
import { DeviceChannelType, getReleaseNotesLink, STATUS_PAGE_URL } from 'constants/roomSystemConstants';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { Logger } from 'logger';
import { isSuccessfulAction } from 'utils/actionUtils';
import classnames from 'classnames';
import { UPGRADE_MANAGEMENT_CHANNEL } from 'constants/upgradeManagementConstants';
import ReleaseCycleConfirmModal from './ReleaseCycleConfirmModal/ReleaseCycleConfirmModal';
import classes from './MaintenanceTabComponent.scss';
import * as styles from './UpgradeManagementComponent.scss';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  roomSystem: RoomSystem;
}

const LOCKED_OPTION = 'locked';

const UpgradeManagementComponent = (props: Props) => {
  const { roomSystem } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [serviceDown, setServiceDown] = useState(false);
  const [channelOptions, setChannelOptions] = useState<DeviceChannelType[]>([]);
  const [deviceChannel, setDeviceChannel] = useState<DeviceChannelType | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const isMounted = useMount();
  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  const currentChannel = useMemo(
    () => {
      if (deviceChannel?.channel) {
        return channelOptions.find(c => c.channel === deviceChannel.channel);
      }
      if (deviceChannel?.version) {
        return channelOptions.find(c => c.version === deviceChannel.version);
      }
      return channelOptions.find(c => c.channel === UPGRADE_MANAGEMENT_CHANNEL.GENERAL_AVAILABILITY);
    },
    [channelOptions, deviceChannel]
  );
  const firmwareChannel = useMemo(
    () => {
      let channel;
      const firmware = getFirmwareVersion(roomSystem);
      if (deviceChannel?.channel) {
        // If two channels have the same version
        channel = channelOptions.find(c => c.version === firmware && c.channel === deviceChannel.channel);
      }
      if (!channel) {
        channel = channelOptions.find(c => c.version === firmware);
      }
      return channel;
    },
    [channelOptions, deviceChannel, roomSystem]
  );
  const selectedChannel = useMemo(
    () => channelOptions.find(c => c.channel === selected),
    [channelOptions, selected]
  );
  const isVersionLocked: boolean = useMemo(
    () => !!deviceChannel && !!deviceChannel.locked && !!deviceChannel.version,
    [deviceChannel]
  );
  const nextVersion = useMemo(
    () => deviceChannel?.version || currentChannel?.version,
    [currentChannel, deviceChannel]
  );
  const nextChannel = useMemo(
    () => isVersionLocked
      ? intl.get('locked')
      : channelToDisplayText(currentChannel ? currentChannel.channel : ''),
    [currentChannel, isVersionLocked]
  );
  const hasVersionMismatch = useMemo(
    () => {
      const firmware = getFirmwareVersion(roomSystem);
      return firmware !== nextVersion;
    },
    [nextVersion, roomSystem]
  );

  const getChannelOptions = async () => {
    // @ts-ignore
    const res: AnyAction = await dispatch(getByPlatform({
      platform: getUpgradeManagementPlatform(roomSystem.platform)
    }));
    if (isSuccessfulAction(res, getByPlatform.fulfilled.type)) {
      if (isMounted.current) {
        setChannelOptions(_get(res, 'payload.body'));
      }
    } else {
      Logger.info(`ERROR: unable to get channel options. service response: ${JSON.stringify(res)}`);
      if (isMounted.current) {
        setServiceDown(true);
      }
    }
  };
  const getDeviceChannel = async () => {
    // @ts-ignore
    const res: AnyAction = await dispatch(getByDevice({
      platform: getUpgradeManagementPlatform(roomSystem.platform),
      serialNumber: roomSystem.serialNumber
    }));
    if (!serviceDown && isSuccessfulAction(res, getByDevice.fulfilled.type)) {
      if (isMounted.current) {
        setDeviceChannel(_get(res, 'payload.body'));
      }
    } else {
      Logger.info(`ERROR: unable to get device channel. service response: ${JSON.stringify(res)}`);
      if (isMounted.current) {
        setServiceDown(true);
      }
    }
  };

  useEffect(
    () => {
      const initialize = async () => {
        // perform these sequentially so if there is no response for 'device',
        // then we will set it to GA
        await getChannelOptions();
        await getDeviceChannel();
        if (isMounted.current) {
          setLoading(false);
        }
      };
      initialize();
    },
    []
  );

  useEffect(
    () => {
      if (!serviceDown && isMounted.current) {
        // Device Channel Precedence (highest to lowest): device locked, device channel, general availability
        if (deviceChannel) {
          if (deviceChannel?.locked && deviceChannel?.version) {
            setSelected(LOCKED_OPTION);
          } else if (deviceChannel?.channel) {
            setSelected(deviceChannel.channel);
          } else {
            setSelected(UPGRADE_MANAGEMENT_CHANNEL.GENERAL_AVAILABILITY);
          }
        }
      }
    },
    [isMounted, serviceDown, deviceChannel]
  );

  const handleChange = (evt: SyntheticEvent, selection: DropdownProps) => {
    if (!canEditRoomSystems) { return; }
    setSelected(String(selection.value));
  };
  const isDirty = isVersionLocked ? selected !== LOCKED_OPTION : currentChannel?.channel !== selected;

  const getIcon = (isLocked: boolean | undefined, willUpgradeTonight: boolean) => {
    if (willUpgradeTonight) {
      return <Icon classes={['icon-exclamation-outline', classes.orange]}/>;
    }
    if (isLocked) {
      return <Icon classes={['icon-lock2', classes.red]}/>;
    }
    return <Icon classes={['icon-check-on']}/>;
  };
  const getDescription = (isLocked: boolean | undefined, willUpgradeTonight: boolean) => {
    if (willUpgradeTonight) {
      return intl.get('upgradeManagementUpdateScheduled', {
        version: nextVersion,
        channel: nextChannel
      });
    }
    if (isLocked) {
      return intl.get('upgradeManagementDeviceLocked');
    }
    return intl.get('upgradeManagementDeviceUpToDate');
  };
  const getContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }
    if (serviceDown) {
      return (
        <div className={styles.deviceSoftwareStatusRow}>
          <div className={styles.softwareInfoContainer}>
            <div className={classes.textContainer}>
              {intl.get('upgradeManagementServiceDownError')}
            </div>
          </div>
        </div>
      );
    }
    const options = channelOptions.map(c => ({ text: channelToDisplayText(c.channel), value: c.channel }));
    if (isVersionLocked) {
      options.unshift({
        text: intl.get('locked'),
        value: LOCKED_OPTION
      });
    }
    return (
      <>
        <div className={styles.deviceSoftwareStatusRow}>
          <div className={`${classes.upgradeIcon} ${classes.green} ${styles.softwareStatusIconContainer}`}>
            {getIcon(isVersionLocked , hasVersionMismatch)}
          </div>
          <div className={styles.softwareInfoContainer}>
            <div className={classes.textContainer}>
              {getDescription(isVersionLocked, hasVersionMismatch)}
            </div>
            <div className={classes.textContainer}>
              {intl.get('upgradeManagementCurrentVersion', {
                version: roomSystem.firmware
              })}
              {!hasVersionMismatch && isVersionLocked
                ? ` (${intl.get('locked')})`
                : !!firmwareChannel && ` (${channelToDisplayText(firmwareChannel.channel)})`}
            </div>
            {!isVersionLocked && (
              <div className={styles.releaseNotesLink}>
                <a href={getReleaseNotesLink(isPlatformGems(roomSystem.platform))} target="_blank">{intl.get('releaseNotes')}</a>
              </div>
            )}
            <div className={classes.textContainer}>
              {intl.getHTML('upgradeManagementSubscribe', { url: STATUS_PAGE_URL })}
            </div>
          </div>
        </div>
        <div className={styles.releaseCycleRow}>
          <div className={styles.formGroupHorizontal}>
            <label className="label">{intl.get('releaseCycle')}</label>
            <Dropdown
              className={styles.shareWithInput}
              closeOnBlur={true}
              fluid={false}
              name="releaseCycle"
              onChange={handleChange}
              options={options}
              selection={true}
              value={selected}
              disabled={!canEditRoomSystems}
            />
            <Tooltip
              text={[(
                <div key="upgradeManagementTooltip" className={styles.tooltipContainer}>
                  <div>{intl.getHTML('upgradeManagementGAInfo', { label: intl.get('upgradeManagementChannelGeneralAvailability') })}</div>
                  <br/>
                  <div>{intl.getHTML('upgradeManagementBetaInfo', { label: intl.get('upgradeManagementChannelBeta') })}</div>
                  <br/>
                  <div>{intl.getHTML('upgradeManagementAlphaInfo', { label: intl.get('upgradeManagementChannelAlpha') })}</div>
                </div>
              )]}
            >
              <Icon classes={['icon-info', styles.iconStyle]} />
            </Tooltip>
          </div>
        </div>
        <div className={styles.flexEndRow}>
          <div className={classes.buttonContainer}>
            <Button
              id="updateManagementButton"
              name="btnUpdate"
              onClick={() => {
                if (!canEditRoomSystems) { return; }
                setIsOpen(true);
              }}
              role="button"
              type="button"
              disabled={!isDirty || !canEditRoomSystems}
            >
              {intl.get('update')}
            </Button>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={classnames(classes.pane, styles.additionalBottomMargin)}>
      <div className={classes.contentContainer}>
        <div className={classes.paneHeader}>
          <div className={classes.paneTitle}>
            {intl.get('upgradeManagement')}
          </div>
        </div>
        {getContent()}
      </div>
      {isOpen && selectedChannel && (
        <ReleaseCycleConfirmModal
          onClose={() => {
            setSelected(isVersionLocked ? LOCKED_OPTION : currentChannel?.channel);
            setIsOpen(false);
          }}
          onSuccess={async () => {
            await getDeviceChannel();
            setIsOpen(false);
          }}
          roomSystem={roomSystem}
          channel={selectedChannel}
          isLocked={isVersionLocked}
        />
      )}
    </div>
  );
};

export default UpgradeManagementComponent;
