import { Reducer } from 'redux';
import { ResetPasswordState, initialResetPasswordState } from 'state/ResetPasswordState';
import { ResetPasswordActions } from 'actions/resetPasswordActions';

const resetPasswordReducer: Reducer<ResetPasswordState> =
  (state = initialResetPasswordState, action) => {
    switch (action.type) {
      case ResetPasswordActions.RESET_PASSWORD_BEGIN:
        return {
          ...state,
          isResettingPassword: true,
          isPasswordReset: false,
          resetPasswordFailure: false
        };
      case ResetPasswordActions.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          isResettingPassword: false,
          isPasswordReset: true,
          resetPasswordFailure: false
        };
      case ResetPasswordActions.RESET_PASSWORD_FAIL:
        return {
          ...state,
          isResettingPassword: false,
          isPasswordReset: false,
          resetPasswordFailure: true,
        };
      case ResetPasswordActions.CLEAR_RESET_PASSWORD_STATE:
        return {
          ...state,
          isResettingPassword: false,
          isPasswordReset: false,
          resetPasswordFailure: false
        };
      default:
        return state;
    }
  };

export default resetPasswordReducer;
