import { useEffect } from 'react';

export function useInterval(callback: () => void, delay: number, dependencies: Array<string | number | object | boolean | Function | null | undefined> = []): void {
  // Set up the interval.
  useEffect(() => {
    if (callback && delay !== null) {
      let id = setInterval(callback, delay);
      return () => clearInterval(id);
    }
    return;
  },        [delay, ...dependencies]);
}

export default {
  useInterval
};
