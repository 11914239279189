import React from 'react';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import intl from 'react-intl-universal';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { RowInfo } from 'react-table';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ROLE_SETTINGS_ROUTE } from 'constants/role';
import { useSelector } from 'react-redux';
import { selectRoleState } from 'selectors/roleSelectors';

const userRoleTabColumnConfig = () => [
  {
    Header: intl.get('roleName'),
    id: 'name',
    sortable: true,
    Cell: (row: RowInfo) => {
      const { name, id } = row.original;
      return (
        <Link
          id={`users-${escape(name)}`}
          title={intl.get('name')}
          to={`${ROLE_SETTINGS_ROUTE}/${id}`}
        >
          {name}
        </Link>
      );
    },
  },
  {
    Header: intl.get('description'),
    id: 'description',
    accessor: 'description',
    sortable: true,
    minWidth: 500,
    height: 40
  },
  {
    Header: intl.get('numberOfUsers'),
    id: 'count',
    accessor: 'count',
    sortable: true
  }
];

export interface Props extends RouteComponentProps {}

const UserRolesTab = () => {
  const roleState = useSelector(selectRoleState);
  const { loading, error, roles } = roleState;

  return (
    <ListViewLayout
      hideHeader={true}
      numberOfPages={0}
      error={error ? intl.get('getRolesError') : undefined}
    >
      <InfiniteTable
        columnConfig={userRoleTabColumnConfig()}
        data={roles || []}
        defaultSort={[
          {
            id: 'name',
            desc: true
          },
        ]}
        enableSelect={false}
        hasMoreRecords={false}
        keyField={'id'}
        loading={loading}
        getTheadTrProps={() => ({
          style: {
            height: '50px'
          }
        })}
        renderHeader={() => <div style={{ height: '40px' }}/>}
        sortable={true}
      />
    </ListViewLayout>
  );
};

export default UserRolesTab;
