import '@lifesize/react-components/dist/css/button.min.css';
import '@lifesize/react-components/dist/css/tab.min.css';
import '@lifesize/react-components/dist/css/input.min.css';
import '@lifesize/react-components/dist/css/menu.min.css';
import '@lifesize/react-components/dist/css/search.min.css';
import '@lifesize/react-components/dist/css/dropdown.min.css';
import '@lifesize/react-components/dist/css/transition.min.css';
import '@lifesize/react-components/dist/css/loader.min.css';
import '@lifesize/react-components/dist/css/checkbox.min.css';
import './assets/styles/core.scss';
import 'react-table/react-table.css';
import './overrides.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { render } from 'react-dom';
import { Store } from 'redux';
import App from './components/App/App';
import configureStore from './store';
import { Global } from './state/Global';
import initializeNucleus from './utils/init-utils';
import { getClient } from './nucleus-apollo-client';
import { BugReporter } from '@lifesize/web-clients-core';
import { get } from 'lodash';

type Environment = 'development' | 'staging' | 'production';

const version = process.env.REACT_APP_VERSION || '1.0.0';
const environment = process.env.REACT_APP_ENV as Environment || 'development';
const bugReporter = new BugReporter('2e58ee55f681476463ebfe52ad0d5a71', version, environment);

const store: Store<Global> = configureStore();

initializeNucleus(store);

const clientPromise = getClient(store.dispatch).then(client => {
  window.client = client;
  return client;
});

window.store = store;

const renderApp = async () => {

  const ErrorBoundary = bugReporter.getReactErrorBoundary(React);

  // tslint:disable-next-line
  console.log(`Lifesize Admin Console v${version}`);
  const client = await clientPromise;

  // tslint:disable
  // Walkme window-level variables
  window['subscriptionTier'] = get(store.getState().nucleus,'account.tier');
  window['hasGalaxy'] = get(store.getState().nucleus,'account.features.galaxy');
  window['has220s'] = get(store.getState().nucleus,'account.features.supports220');
  window['userType'] = get(store.getState().nucleus, 'userProfile.role');
  window['uuid'] = get(store.getState().nucleus, 'userProfile.uuid');
  // tslint:enable

  // Loading the google maps script AFTER core-js https://github.com/zloirock/core-js/issues/567
  const googleMapsScript = document.createElement('script');
  googleMapsScript.setAttribute('async', 'true');
  googleMapsScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${process.env.MAPS_API_KEY}`);
  document.head.appendChild(googleMapsScript);

  // Wait for response before render
  render(
    (
    <ErrorBoundary>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Provider>
    </ErrorBoundary>
    ),
    document.getElementById('root'),
  );
};

renderApp();
