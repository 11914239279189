export enum ExportStatus {
  ERROR = 'Error',
  IN_PROGRESS = 'In progress',
  SUCCESS = 'Success',
}

export enum DirectoryExportStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED'
}

export enum ExportType {
  MEETING = 'meeting',
  USER = 'user',
  ROOM = 'room',
  GROUP = 'group',
  CONFERENCE = 'conference',
  RECORDING = 'recording'
}

export interface Download {
  id: string;
  generationStartedAt: number;
  generatedBy: string;
  userId: string;
  generatedAt: number;
  startTimestamp: number;
  endTimestamp: number;
  filename: string;
  status: ExportStatus | DirectoryExportStatus;
  downloadUrl: string;
  expiresAt: number;
  type: string;
}

export interface UpdatedExport {
  downloadId: string;
  downloadUrl: string;
}

export interface AllExports {
  data: {
    getAllExports: Download[];
  };
}
