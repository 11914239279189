import { connect } from 'react-redux';
import { Global } from '../../state/Global';
import GroupContactsToolbar from '../../components/Users/GroupContacts/GroupContactsToolbar';
import { actions as ModalActions } from '../../actions/modalActions';
import { ModalTypes } from 'constants/modalConstants';
import { selectSelection } from 'selectors/selectionSelectors';

const mapDispatchToProps = (dispatch: Function) => ({
  showAddUserModal: () => dispatch(ModalActions.openModal({ modalType: ModalTypes.ADD_GROUP_CONTACT })),
  showDeleteConfirmModal: () => dispatch(ModalActions.openModal({ modalType: ModalTypes.DELETE_GROUP_CONTACT })),
});

const mapStateToProps = (state: Global) => {
  const selectionArray = selectSelection(state);
  return {
    hasSelected: !!selectionArray.length,
    canDelete: !!selectionArray.length,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupContactsToolbar);
