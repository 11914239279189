import React from 'react';
import intl from 'react-intl-universal';
import { useQuery } from '@apollo/react-hooks';
import { getBreadcrumbs } from 'utils/nav-utils';
import getRoleQuery from 'queries/roles/getRole.gql';
import { Tab } from 'semantic-ui-react';
import * as styles from './RoleDetails.scss';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import ListViewTabLayout, { Pane } from 'components/ListViewTabLayout/ListViewTabLayout';
import { Role, ROLE_PERMISSION } from 'interfaces/Role';
import { RoleSettings } from 'components/RoleSettings/RoleSettings';
import { RoleMembers } from 'components/RoleMembers/RoleMembers';
import { NoPermissionsBanner } from 'components/NoPermissionsBanner/NoPermissionsBanner';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { mapRoleFromBase } from 'utils/permissionsUtils';

const renderBreadCrumbs = (roleName: string) => getBreadcrumbs([
  {
    url: `/users/roles`,
    label: intl.get('roleManagement')
  },
  {
    label: roleName
  }
]);

const renderPanes = (role: Role, canViewUsers: boolean) => ([
  {
    menuItem: (
      {
        key: 'roleSettingsTab',
        content: (<div data-test="roleSettingsTab">{intl.get('settings')}</div>)
      }),
    render: () => (
      <Tab.Pane key={'0'} attached={false}>
        <RoleSettings role={role} />
      </Tab.Pane>
    )
  },
  (canViewUsers && {
    menuItem: (
      {
        key: 'roleMembersTab',
        content: (<div data-test="roleMembersTab">{intl.get('roleMembers')}</div>)
      }),
    render: () => (
      <Tab.Pane key={'0'} attached={false}>
        <RoleMembers role={role} />
      </Tab.Pane>
    )
  })
]) as Pane[];

export interface Props {
  match: {
    params: {
      id: string;
      name: string;
    }
  };
}

export const RoleDetails = (props: Props) => {
  const roleId = props?.match?.params?.id;
  const name = props?.match?.params?.name;
  const canEditRoles = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROLES];
  const canViewUsers = useSelector(selectAccountPermissions)[ROLE_PERMISSION.VIEW_USERS];

  const { data, loading } = useQuery(getRoleQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      roleId: roleId
    }
  });

  const role = mapRoleFromBase(data?.getRole);
  const roleName = role?.name || name;
  const description = role?.description || '';

  if (loading || !role) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.structure}>
      {!canEditRoles && <NoPermissionsBanner text={intl.get('cannotModifyRoles')} />}
      <ListViewTabLayout
        title={roleName}
        breadcrumbs={renderBreadCrumbs(roleName)}
        subtitle={description}
        panes={renderPanes(role, canViewUsers)}
      />
    </div>
  );
};
