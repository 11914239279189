import classnames from 'classnames';
import TableNoData from 'components/Common/TableNoData';
import React from 'react';
import ReactTable, {
  Column,
  ComponentPropsGetter0,
  ComponentPropsGetterR,
  ComponentPropsGetterRC,
  ExpandedChangeFunction,
  SortedChangeFunction,
  RowInfo,
  SortingRule
} from 'react-table';
import * as styles from './ListViewComponent.scss';
import { genericColumnConfig, genericListData } from 'components/InfiniteTable/GenericColumnConfig';

interface Props {
  NoDataComponent?: React.ReactType;
  columns: Array<Column>;
  data: Array<{}>;
  defaultPageSize?: number;
  defaultSorted?: SortingRule[];
  expandedRows?: {};
  getTableDataProps?: ComponentPropsGetterRC | ComponentPropsGetterR;
  getTableRowProps?: ComponentPropsGetterR | ComponentPropsGetter0;
  hoverHighlight?: boolean;
  loading?: boolean;
  maxRows?: number;
  minRows?: number;
  onExpandedChange?: ExpandedChangeFunction;
  onSortedChange?: SortedChangeFunction;
  pageNumber?: number;
  showPagination?: boolean;
  showAllResults?: boolean;
  sortable?: boolean;
  // If subComponent function supplied, drilldowns are supported
  subComponent?: (rowInfo: RowInfo) => React.ReactNode;
}

/**
 * Updates to styling for views less than 25 rows (dashboard tables)
 * @param minRows
 */
const getClassName = (minRows: number) => {
  if (minRows < 25) {
    return [styles.container, styles.smallTable];
  }
  return [styles.container];
};

const ListViewComponent = ({
                             NoDataComponent,
                             columns,
                             data,
                             defaultPageSize,
                             defaultSorted,
                             getTableDataProps,
                             getTableRowProps,
                             hoverHighlight = false,
                             loading,
                             maxRows = 25,
                             minRows = 25,
                             pageNumber,
                             showPagination = true,
                             showAllResults = false,
                             subComponent,
                             onExpandedChange,
                             onSortedChange,
                             expandedRows,
                             sortable = true
                           }: Props) => {

  const tableData = loading ? genericListData(1000) : data;
  const loadingColumnConfig = genericColumnConfig(columns);
  const tableColumnConfig = loading ? loadingColumnConfig : columns;

  const numRows = (tableData.length && (tableData.length < maxRows || showAllResults)) ? tableData.length : maxRows;

  const classes = getClassName(minRows);
  if (hoverHighlight) {
    classes.push('-highlight');
  }
  if (subComponent) {
    tableColumnConfig.unshift({
      expander: true,
      show: true,
    });
  } else {
    tableColumnConfig.push({
      expander: true,
      show: false,
    });
  }
  const columnsConfig = tableColumnConfig.map(col =>
    ({
      ...col,
      headerClassName: classnames(col.headerClassName, styles.tableHeaderTitle),
      className: classnames(col.className, styles.tableCell)
    })
  );
  return (
    <ReactTable
      NoDataComponent={NoDataComponent || TableNoData}
      SubComponent={subComponent}
      className={classes.join(' ')}
      columns={columnsConfig}
      data={tableData}
      defaultPageSize={defaultPageSize || numRows}
      defaultSorted={defaultSorted}
      expanded={expandedRows && pageNumber ? expandedRows[pageNumber] : {}}
      getTdProps={getTableDataProps}
      getTrProps={getTableRowProps}
      minRows={0}
      onExpandedChange={onExpandedChange}
      onSortedChange={onSortedChange}
      page={pageNumber}
      showPagination={showPagination}
      sortable={sortable}
    />
  );
};

export default ListViewComponent;
