import { initActions, networkActions, nucleusConstants } from '@lifesize/nucleus';
import { ClientTypes } from '@lifesize/constants';
import { Store } from 'redux';
import { Global } from 'state/Global';
import { getDomain } from './url-utils';
import { getDefaultLocale } from './i18nUtils';

// ========================================================
// Initialize Nucleus
// ========================================================

const getCNUCInitOptions = (languageId: string) => {
  return {
    callHistoryEnabled: false,
    callsEnabled: false,
    gqlChatEnabled: false,
    clientInfo: {
      clientType: ClientTypes.ADMIN
    },
    xmppEnabled: false,
    psemsEnabled: false,
    cssEnabled: false,
    loadContactsOnLogin: false,
    directMediaEnabled: false,
    enableH264: false,
    enablePrimary1080Rx: false,
    enableSecondary1080Rx: false,
    enableTransportCC: false,
    environment: getDomain(),
    languageId,
    platform: nucleusConstants.PLATFORM_WEB,
    version: process.env.REACT_APP_VERSION,
  };
};

async function initializeNucleus(store: Store<Global>) {
  const dispatch = store.dispatch;
  if (!dispatch) { throw new Error('No dispatch initialized'); }

  const state = store.getState();
  const languageId = getDefaultLocale(state);
  const webOptions = getCNUCInitOptions(languageId);

  const finishInitialization = async () => {
    await dispatch(initActions.initialize(webOptions));
    dispatch(navigator && navigator.onLine ? networkActions.online() : networkActions.offline());
  };

  await finishInitialization();
}

export default initializeNucleus;
