import { meetingsSelectors } from '@lifesize/nucleus';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from '../../actions/modalActions';
import { actions as selectionActions } from '../../actions/selectionActions';
import { DeleteGroupContactModal } from '../../components/Modals/DeleteGroupContactModal';
import { selectSelection } from '../../selectors/selectionSelectors';

const mapStateToProps = (state: Global) => {

  const selectionArray = selectSelection(state);
  
  return {
    errorMessage: undefined,
    selectionArray,
    isBusy: meetingsSelectors.isBusySelector(state)
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async () => {
    dispatch(selectionActions.resetSelection());
    dispatch(actions.closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteGroupContactModal);
