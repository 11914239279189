import React from 'react';
import intl from 'react-intl-universal';
import { ParticipantEntry } from 'interfaces/Dashboard';
import { formatUnixTimeStampForDisplay } from 'utils/date-time-utils';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import _get from 'lodash/get';
import InfiniteTable from 'containers/InfiniteTable/InfiniteTableContainer';
import Icon from 'components/Common/Icon';
import { CellInfo, RowInfo } from 'react-table';
import { RouteComponentProps } from 'react-router-dom';
import styles from './LiveMeetingParticipantList.scss';
import { ApolloError } from 'apollo-client';
import { SortDirection } from 'constants/queryConstants';
import { SetSort, FetchArgs } from 'interfaces/Tables';
import { LIVE_MEETING_PARTICIPANT_ROUTE } from 'constants/liveMeetings';
import { LiveMeetingParticipantDuration } from 'components/LiveMeetings/LiveMeetingParticipantDuration/LiveMeetingParticipantDuration';
import ClickableCell from 'components/ClickableCell/ClickableCell';

interface Props extends RouteComponentProps {
  error?: ApolloError;
  presenter: string;
  conferenceId: string;
  participants: Array<ParticipantEntry>;
  setSort: (sort: SetSort) => void;
}

const liveMeetingParticipantListConfiguration = ({ conferenceId, history }: Props) =>
  [{
    Header: intl.get('name'),
    accessor: 'displayName',
    Cell: (row: RowInfo) => {
      const {displayName} = row.original;
      return (
        <ClickableCell
          id={`liveMeetingParticipant-${displayName}`}
          title={displayName}
          isClickable={true}
          displayText={displayName}
          clickHandler={() => {
            history.push(`${LIVE_MEETING_PARTICIPANT_ROUTE}/${conferenceId}/${row.original.callId}`);
          }}
        />
      );
    }
  }, {
    Header: intl.get('location'),
    accessor: 'location',
  }, {
    Header: intl.get('joinTime'),
    accessor: 'startedAt',
    Cell: (row: CellInfo) => row.value ? formatUnixTimeStampForDisplay(row.value) : '',
  }, {
    Header: intl.get('leaveTime'),
    accessor: 'endedAt',
    Cell: (row: CellInfo) => row.value ? formatUnixTimeStampForDisplay(row.value) : '',
  }, {
    Header: intl.get('duration'),
    accessor: 'duration',
    Cell: (row: CellInfo) => <LiveMeetingParticipantDuration row={row} />
  }, {
    id: 'raisedHand', // Required because our accessor is not a string
    Header: intl.get('raisedHand'),
    maxWidth: 200,
    headerClassName: styles.narrowColumn,
    className: styles.narrowColumn,
    accessor: (d: ParticipantEntry) => d?.handRaisedData?.handRaised,
    Cell: (row: CellInfo) => <>{!!row.value && <Icon classes={['icon-raise-hand', styles.raiseHand]}/>}</>
  }, {
    Header: intl.get('presenting'),
    maxWidth: 200,
    headerClassName: styles.narrowColumn,
    className: styles.narrowColumn,
    accessor: 'isPresenting',
    Cell: (row: CellInfo) => <>{!!row.value && <Icon classes={['icon-share-screen', styles.presenting]}/>}</>
  }
];

const LiveMeetingParticipantList = (props: Props) => {
  const {
    error,
    participants,
    setSort,
  } = props;

  const onFetchData = (args: FetchArgs) => {
    const column = args.sorted[0];
    if (!column) {
      return;
    }
    setSort({
      sortBy: column.id,
      order: column.desc ? SortDirection.DESC : SortDirection.ASC,
    });
  };

  return (
    <ListViewLayout
      hideHeader={true}
      isFetching={false}
      numberOfPages={0}
      error={_get(error, 'message', undefined)}
    >
      <InfiniteTable
        columnConfig={liveMeetingParticipantListConfiguration(props)}
        enableSelect={false}
        data={participants}
        defaultSort={[
          {
            id: 'startedAt',
            desc: true
          }
        ]}
        getTdProps={() => ({
          style: {
            alignItems: 'flex-start',
            paddingTop: '16px',
            paddingBottom: '16px',
            lineHeight: '12px'
          }
        })}
        getTheadTrProps={() => ({
          style: {
            paddingLeft: '5px'
          }
        })}
        getTrProps={() => ({
          style: {
            paddingLeft: '5px'
          }
        })}
        keyField={'startTime'}
        hasMoreRecords={false}
        renderHeader={() => (
          <div className={styles.spacer}/>
        )}
        rowHeightOverride={44}
        onFetchData={onFetchData}
      />
    </ListViewLayout>
  );
};

export { LiveMeetingParticipantList };
