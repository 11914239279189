import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Button } from 'semantic-ui-react';
import classnames from 'classnames';
import * as Yup from 'yup';
import * as formStyles from 'components/Forms.scss';
import * as styles from 'components/Settings/Sections.scss';
import _memoize from 'lodash/memoize';
import _get from 'lodash/get';
import intl from 'react-intl-universal';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { ScaleLoader } from 'react-spinners';
import ssoUpdateMutation from 'queries/sso/ssoUpdateMutation.gql';
import ssoQuery from 'queries/sso/ssoQuery.gql';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { accountSelectors } from '@lifesize/nucleus';
import { ACCOUNT_FEATURE_SSO } from 'constants/accountFeatureConstants';
import { selectEcommerceLoading, selectEcommerceSupported } from 'selectors/ecommerceSelector';
import EcommercePrompt from 'components/EcommercePrompt/EcommercePrompt';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

/**
 * Cached to evaluate only once
 * TODO: Setup validation rules and messages
 */
const ssoTabSchema = _memoize(() => {
  return Yup.object().shape({
    entityId: Yup.string().trim().required(intl.get('SsoIdentityProviderIssuerRequired')),
    loginUrl: Yup.string().trim().url(intl.get('SsoLoginUrlInvalid')).required(intl.get('SsoLoginUrlRequired')),
    ssoX509cert: Yup.string().trim().notRequired(),
  });
});

const validateSettings = (e: React.MouseEvent<HTMLButtonElement>, testURL: string) => {
  e.preventDefault();
  window.open(testURL, '_blank');
};

const Sso = () => {
  const hasSsoAddOn = useSelector(accountSelectors.getAccountFeatures)[ACCOUNT_FEATURE_SSO];
  const isEcommerceSupported = useSelector(selectEcommerceSupported);
  const ecommerceAccountLoading = useSelector(selectEcommerceLoading);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const { data, loading } = useQuery(ssoQuery, {
    fetchPolicy: 'cache-and-network'
  });
  const [ saveConfig ] = useMutation(ssoUpdateMutation);
  const [gqlError, setGqlError] = useState<string | null>(null);

  if (ecommerceAccountLoading) {
    return <LoadingIndicator />;
  }

  if (!hasSsoAddOn && isEcommerceSupported) {
    return (
      <EcommercePrompt
        buttonId="btnSsoUpgrade"
        message={intl.get('ssoUpgradeMessage')}
      />
    );
  }

  const settings = _get(data, 'me.group.settings.SSO') || {};
  const { samlAttributeMapping, testURL } = settings;
  const initialValues = {
    ...settings,
    ...samlAttributeMapping,
  };

  // Set null values from api to empty string so form components don't complain
  Object.entries(initialValues).forEach(([key, value]) => {
    if (value === null) { initialValues[key] = ''; }
  });

  return (
    <Formik
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      validationSchema={ssoTabSchema}
      onSubmit={(
        {
          enableSso,
          entityId,
          loginUrl,
          ssoX509cert,
          firstName,
          lastName,
          email,
        },
        actions,
      ) => {
        if (!canEditSettings) { return; }
        setGqlError(null);
        saveConfig({
          variables: {
            input: {
              SSOEnabled: !!enableSso,
              // Convert empty strings back to null for saving;
              identityProviderIssuerId: entityId || null,
              loginUrl: loginUrl || null,
              ssoX509cert: ssoX509cert || null,
              firstName: firstName || null,
              lastName: lastName || null,
              email: email || null,
            },
          },
        })
        .catch(e => {
          setGqlError(e.message && e.message.includes('NOT_FOUND')
            ? intl.get('SSOEntityNotFound')
            : intl.get('SSOSomethingWentWrong'));
          actions.setSubmitting(false);
        });
      }}
      render={({ values, handleChange, errors, touched, dirty, isSubmitting }) => (
        <Form>
          <pre style={{ display: 'none' }}>{JSON.stringify(data, null, 2)}</pre>
          <h5 className={styles.subheader}>{intl.get('SsoPageTitle')}</h5>
          <section className={styles.container}>
            {loading && !Object.keys(initialValues).length
              ? <LoadingIndicator />
              : (
                <>
                  <article>
                    <h6 className={styles.title}>{intl.get('SsoIdentityProviderSettings')}</h6>
                    <p className={styles.description}>{intl.get('SsoEnterProviderSettings')}</p>
                    <div className={styles.formSection}>
                      <div className={classnames(styles.inputGroup, styles.inset, styles.wide)}>
                        <label>{intl.get('SsoIdentityProviderIssuer')}</label>
                        <div>
                          <Field
                            className={styles.input}
                            type="text"
                            name="entityId"
                            disabled={!canEditSettings}
                          />
                          {(touched && errors && touched.entityId && errors.entityId) || gqlError && (
                            <div className={formStyles.messageContainer}>
                              <div
                                className={formStyles.error}
                              >
                                {errors.entityId || gqlError}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={classnames(styles.inputGroup, styles.inset, styles.wide)}>
                        <label>{intl.get('SsoLoginUrl')}</label>
                        <div>
                          <Field
                            className={styles.input}
                            type="text"
                            name="loginUrl"
                            disabled={!canEditSettings}
                          />
                          {touched && errors && touched.loginUrl && errors.loginUrl && (
                            <div className={formStyles.messageContainer}>
                              <div className={formStyles.error}>{errors.loginUrl}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={classnames(styles.inputGroup, styles.inset, styles.wide)}>
                        <label>{intl.get('SsoX509Certificate')}</label>
                        <div>
                          <Field
                            className={styles.textarea}
                            component="textarea"
                            name="ssoX509cert"
                            disabled={!canEditSettings}
                          />
                          {touched && errors && touched.ssoX509cert && errors.ssoX509cert && (
                            <div className={formStyles.messageContainer}>
                              <div
                                className={formStyles.error}
                              >
                                {errors.ssoX509cert}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <p className={styles.description}>{intl.get('SsoEnterSamlAttributeMappings')}</p>
                    <div className={styles.formSection}>
                      <div className={classnames(styles.inputGroup, styles.inset, styles.wide)}>
                        <label>{intl.get('SsoFirstName')}</label>
                        <div>
                          <Field
                            className={styles.input}
                            type="text"
                            name="firstName"
                            disabled={!canEditSettings}
                          />
                          {touched && errors && touched.firstName && errors.firstName && (
                            <div className={formStyles.messageContainer}>
                              <div className={formStyles.error}>{errors.firstName}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={classnames(styles.inputGroup, styles.inset, styles.wide)}>
                        <label>{intl.get('SsoLastName')}</label>
                        <div>
                          <Field
                            className={styles.input}
                            type="text"
                            name="lastName"
                            disabled={!canEditSettings}
                          />
                          {touched && errors && touched.lastName && errors.lastName && (
                            <div className={formStyles.messageContainer}>
                              <div className={formStyles.error}>{errors.lastName}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={classnames(styles.inputGroup, styles.inset, styles.wide)}>
                        <label>{intl.get('SsoEmail')}</label>
                        <div>
                          <Field
                            className={styles.input}
                            type="text"
                            name="email"
                            disabled={!canEditSettings}
                          />
                          {touched && errors && touched.email && errors.email && (
                            <div className={formStyles.messageContainer}>
                              <div className={formStyles.error}>{errors.email}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </article>
                  <article>
                    <h6 className={styles.title}>{intl.get('SsoCheckYourConfiguration')}</h6>
                    <p className={styles.description}>{intl.get('SsoValidateAgainstIdP')}</p>
                    <div className={styles.formSection}>
                      <div className={styles.buttonContainer}>
                        <Button
                          disabled={!testURL || !canEditSettings}
                          id="btnValidateSettings"
                          name="btnValidateSettings"
                          onClick={(e) => validateSettings(e, testURL)}
                          role="button"
                          type="button"
                        >
                          {intl.get('SsoValidateSettingsButtonLabel')}
                        </Button>
                      </div>
                    </div>
                  </article>
                  <article>
                    <h6 className={styles.title}>{intl.get('SsoRelayState')}</h6>
                    <p className={styles.description}>{intl.get('SsoCopyRelayState')}</p>
                    <div className={styles.formSection}>
                      <div className={classnames(styles.inputGroup, styles.inset, styles.wide)}>
                        <div>
                          <Field
                            className={styles.input}
                            disabled={true}
                            type="text"
                            name="relayState"
                          />
                        </div>
                      </div>
                    </div>
                  </article>
                  <article>
                    <h6 className={styles.title}>{intl.get('SsoEnableSsoTitle')}</h6>
                    <div className={styles.formSection}>
                      <Field
                        component={ToggleButton}
                        name="enableSso"
                        id="enableSso"
                        disabled={!canEditSettings}
                        label={intl.get('SsoEnableSsoLabel')}
                      />
                      <div className={styles.buttonContainer}>
                        <Button
                          disabled={isSubmitting || !dirty || !canEditSettings}
                          id="btnSubmit"
                          name="btnSubmit"
                          role="button"
                          type="submit"
                        >
                          {isSubmitting ? <ScaleLoader color="white" height={15} /> : intl.get('SsoUpdateButton')}
                        </Button>
                      </div>
                    </div>
                  </article>
                </>
              )}
          </section>
        </Form>
      )}
    />
  );
};

export default Sso;
