import { Reducer } from 'redux';
import EcommerceActions from 'actions/ecommerceActions';
import {
  ECOMMERCE_SUBSCRIPTION_CANCEL_CLEAR_ERROR,
  ECOMMERCE_SUBSCRIPTION_REACTIVATE_CLEAR_ERROR
} from 'constants/ecommerceConstants';
import EcommerceState, { EcommerceInterface } from 'state/EcommerceState';

const ecommerceReducer: Reducer<EcommerceInterface> =
  (state: EcommerceInterface = EcommerceState, action) => {
    switch (action.type) {
      case EcommerceActions.getEcommerceAccount.pending.type:
        return { ...state, loading: true };
      case EcommerceActions.getEcommerceAccount.fulfilled.type:
        return { ...state, loading: false, account: action.payload };
      case EcommerceActions.getEcommerceAccount.rejected.type:
        return { ...state, loading: false };
      case EcommerceActions.getEcommerceSubscription.pending.type:
        return { ...state, subscriptionLoading: true, subscriptionError: false };
      case EcommerceActions.getEcommerceSubscription.fulfilled.type:
        return { ...state, subscriptionLoading: false, subscription: action.payload };
      case EcommerceActions.getEcommerceSubscription.rejected.type:
        return { ...state, subscriptionLoading: false, subscriptionError: true };
      case EcommerceActions.cancelEcommerceSubscription.pending.type:
        return { ...state, subscriptionCancelBusy: true, subscriptionCancelError: false };
      case EcommerceActions.cancelEcommerceSubscription.fulfilled.type:
        return { ...state, subscriptionCancelBusy: false, subscription: action.payload };
      case EcommerceActions.cancelEcommerceSubscription.rejected.type:
        return { ...state, subscriptionCancelBusy: false, subscriptionCancelError: true };
      case ECOMMERCE_SUBSCRIPTION_CANCEL_CLEAR_ERROR:
        return { ...state, subscriptionCancelError: false };
      case EcommerceActions.reactivateEcommerceSubscription.pending.type:
        return { ...state, subscriptionReactivateBusy: true, subscriptionReactivateError: false };
      case EcommerceActions.reactivateEcommerceSubscription.fulfilled.type:
        return { ...state, subscriptionReactivateBusy: false, subscription: action.payload };
      case EcommerceActions.reactivateEcommerceSubscription.rejected.type:
        return { ...state, subscriptionReactivateBusy: false, subscriptionReactivateError: true };
      case ECOMMERCE_SUBSCRIPTION_REACTIVATE_CLEAR_ERROR:
        return { ...state, subscriptionReactivateError: false };
      default:
        return state;
    }
  };

export default ecommerceReducer;
