import React from 'react';
import Stripe from 'stripe';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions/ecommerceActions';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as modalFormStyles from 'components/Modals/ModalForms.scss';
import * as styles from 'components/Settings/AccountDetailsTab/AccountSupport.scss';
import { hasSubscriptionReactivateError, isSubscriptionReactivateBusy } from 'selectors/ecommerceSelector';
import { formatUnixDateForDisplay, secondsToMilliseconds } from 'utils/date-time-utils';

type Props = {
  onClose: () => void;
  subscription: Stripe.Subscription | undefined;
};

export default function ReactivateSubscriptionConfirmModal(props: Props) {
  const { onClose, subscription } = props;
  const dispatch = useDispatch();
  const isReactivateSubscriptionBusy = useSelector(isSubscriptionReactivateBusy);
  const hasReactivateSubscriptionError = useSelector(hasSubscriptionReactivateError);
  return (
    <Modal
      className={modalFormStyles.containerSmall}
      closeModal={() => {
        dispatch(actions.clearEcommerceSubscriptionReactivateError());
        onClose();
      }}
      onSave={() => {
        // reactivate subscription
        dispatch(actions.reactivateEcommerceSubscription({}));
      }}
      isSaveButtonBusy={isReactivateSubscriptionBusy}
      hideSaveButton={!subscription?.cancel_at}
      saveText={intl.get('yes')}
      closeText={subscription?.cancel_at ? intl.get('no') : intl.get('modalClose')}
      titleText={intl.get('reactivateSubscription')}
      errorMessage={hasReactivateSubscriptionError ? intl.get('reactivateSubscriptionErrorMessage') : undefined}
    >
      <div className={styles.modalContainer}>
        {subscription?.cancel_at
          ? intl.get('reactivateSubscriptionConfirmMessage')
          : intl.get('reactivateSubscriptionSuccessMessage', {
            date: formatUnixDateForDisplay(secondsToMilliseconds(subscription?.current_period_end || 0))
          })}
      </div>
    </Modal>
  );
}
