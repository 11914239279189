import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import { Link } from 'react-router-dom';
import { DWHUserCallsResponse } from '../../../interfaces/Dashboard';
import ListViewComponent from '../../ListView/ListViewComponent';
import { DashboardLoader } from '../DashboardLoader';
import * as styles from './Tables.scss';
import { TableTitle } from './TableTitle';
import { sortData, ViewName } from './TableUtils';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import { connect } from 'react-redux';
import { Global } from '../../../state/Global';

interface Props {
  iconCallData: DWHUserCallsResponse;
}

const IconCalls = ({iconCallData}: Props) => {
  const divId = 'iconCalls';
  const columns = [{
    Header: intl.get('name'),
    accessor: 'name'
  }, {
    Header: intl.get('minutes'),
    accessor: 'totalMinutes',
  }, {
    Header: intl.get('calls'),
    accessor: 'totalCalls'
  }];

  if (iconCallData.isFetching) {
    return (
      <DashboardLoader divId={divId} title={intl.get('headerRoomSystemTotals')}/>
    );
  } else {
    const data = _get(iconCallData, 'data.data', []);
    return (
      <div className={styles.container} id={divId}>
        <TableTitle title={intl.get('headerRoomSystemTotals')}/>
        <ListViewComponent
          data={data}
          columns={columns}
          showPagination={false}
          minRows={5}
          maxRows={5}
          pageNumber={0}
        />
        <div className={styles.footer}>
          <div className={styles.footer}>
            <Link id="view_all" to={'/drilldown/usersVideos/rooms'}>
              <span>{intl.get('viewAll')}</span>
              <Icon classes={['icon-back']} rotate={180} />
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default IconCalls;

function mapStateToProps(state: Global) {
  return {
    iconCallData: sortData(dataWarehouseSelectors.usersGroupByCallsRoom(state), ViewName.UserCalls),
  };
}

const mapDispatchToProps = (dispatch: Function) => ({});

export const IconCallsContainer = connect(mapStateToProps, mapDispatchToProps)(IconCalls);
