import React from 'react';
import { Loader } from 'semantic-ui-react';
import Icon from 'components/Common/Icon';
import * as commonStyles from '../Common.scss';
import _memoize from 'lodash/memoize';

interface Props {
  isSubmitting: boolean;
  hasErrors: boolean;
  isAssociatedFieldTouched: boolean;
  handleSubmit?: () => void;
  handleReset?: () => void;
}

/**
 * Div containing check mark icon for submit, 'X' for reset, and animated spin wait icon
 * Visibility and behavior of each depends on state passed in via props
 */
class OkResetWaitButtonGroup extends React.Component<Props> {

  /** Spinny wait loader definition */
  inlineLoader = _memoize((isTouched: boolean, isFormSubmitting: boolean) => {
    if (isTouched && isFormSubmitting) {
      return (<Loader size={'mini'} active={true} inline={true}/>);
    }
    return null;
  },                      (...args) => {
    // Key for memoization
    return JSON.stringify(args);
  });

  /** Check mark definition */
  inlineCheckMark = (
    isFormSubmitting: boolean,
    hasErrors: boolean,
    handleSubmitForm?: (e?: React.FormEvent<HTMLFormElement>) => void) => {

    if (isFormSubmitting || hasErrors) {
      return null;
    }
    return (
      <div
        id="checkmark-div"
        onClick={() => {
          if (handleSubmitForm) {
            handleSubmitForm();
          }
        }}
      >
        <Icon classes={['icon-checkmark']} />
      </div>
    );
  }

  /** Cancel 'X' definition */
  inlineCancel = (isFormSubmitting: boolean, handleResetForm?: (() => void)) => {
    if (isFormSubmitting) {
      return null;
    }
    return (
      <div
        id="cancel-div"
        onClick={() => {
          if (handleResetForm) {
            handleResetForm();
          }
        }}
      >
        <Icon classes={['icon-cancel']} />
      </div>
    );
  }

  render() {
    const {isSubmitting, isAssociatedFieldTouched, handleSubmit, hasErrors, handleReset} = this.props;
    return (
      <div className={commonStyles.inputIconButtons}>
        {this.inlineCheckMark(isSubmitting, hasErrors, handleSubmit)}

        {this.inlineLoader(isAssociatedFieldTouched, isSubmitting)}

        {this.inlineCancel(isSubmitting, handleReset)}
      </div>
    );
  }
}

export default OkResetWaitButtonGroup;
