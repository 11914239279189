export const APP_STORE_ROOM_CONTROLLER_DOWNLOAD_LINK = 'https://apps.apple.com/us/app/lifesize-room-controller/id1391915508';
export const GOOGLE_PLAY_ROOM_CONTROLLER_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=com.lifesize.graphite&hl=en_US';

export const DOWNLOADS_PATH = '/downloads';

export const HANDLE_CONFERENCE_EXPORT_DELETION = 'HANDLE_CONFERENCE_EXPORT_DELETION';
export const SET_EXPORTS = 'SET_EXPORTS';
export const GET_EXPORT_ERROR = 'GET_EXPORT_ERROR';
export const EXPORTS_LOADING = 'EXPORTS_LOADING';
export const UPDATE_EXPORTS = 'UPDATE_EXPORTS';
