import { dataWarehouseSelectors } from '@lifesize/nucleus';
import TableNoData from 'components/Common/TableNoData';
import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DWHLiveStreamHistoryResponse, DWHLiveStreamResultType, } from '../../../interfaces/Dashboard';
import { Global } from '../../../state/Global';
import { formatSecondsForDisplay, formatUnixDateAtTimeForDisplay } from '../../../utils/date-time-utils';
import ListViewComponent from '../../ListView/ListViewComponent';
import * as dashStyles from '../Dashboard.scss';
import { DashboardLoader } from '../DashboardLoader';
import * as styles from './Tables.scss';
import { TableTitle } from './TableTitle';
import { sortData, ViewName } from './TableUtils';

interface Props {
  liveStreamsByList: DWHLiveStreamHistoryResponse;
}

/**
 * Add formatted duration and start date data for display
 */
const parseDataForDisplay = (data: Array<DWHLiveStreamResultType>): Array<DWHLiveStreamResultType> => {
  for (let call of data) {
    call.durationFormatted = formatSecondsForDisplay(call.durationSeconds);
    call.startDateFormatted = formatUnixDateAtTimeForDisplay(call.startDate);
  }
  return data;
};

export const LiveStreamHistoryTable = ({ liveStreamsByList }: Props) => {
  const divId = 'liveStreamHistory';
  if (liveStreamsByList.isFetching) {
    return (
      <DashboardLoader divId={divId} title={intl.get('liveStreamHistory')}/>
    );
  }

  const data = _get(liveStreamsByList, 'data.data', []) as Array<DWHLiveStreamResultType>;

  if (!liveStreamsByList.isFetching && data) {
    const parsedData = data.length > 0 ? parseDataForDisplay(data) : [];
    return (
      <div className={styles.container} id={divId}>
        <TableTitle title={intl.get('liveStreamHistory')}/>
        <ListViewComponent
          data={parsedData}
          columns={
            [{
              Header: intl.get('name'),
              accessor: 'streamName'
            }, {
              Header: intl.get('date'),
              accessor: 'startDateFormatted',
            }, {
              Header: intl.get('duration'),
              accessor: 'durationSeconds',
              Cell: (row) => {
                return row.original.durationFormatted;
              },
            }, {
              Header: intl.get('viewers'),
              accessor: 'viewerCount'
            }]
          }
          showPagination={false}
          minRows={5}
          maxRows={5}
          pageNumber={0}
        />
        <div className={styles.footer}>
          <Link id="view_all" to={'/drilldown/livestream'}>
            {intl.get('viewAll')}
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container} id={divId}>
      <div className={dashStyles.title}>
        {intl.get('liveStreamHistory')}
      </div>
      <div className={dashStyles.noResultsContainer}>
        <TableNoData/>
      </div>
    </div>
  );
};

const mapStateToProps = (state: Global) => {
  return {
    liveStreamsByList: sortData(dataWarehouseSelectors.streamsByList(state), ViewName.UserCalls)
  };
};

// Exported for tests
export default LiveStreamHistoryTable;
export const LiveStreamHistoryTableContainer = connect(mapStateToProps)(LiveStreamHistoryTable);
