import { usersActions, usersSelectors, } from '@lifesize/nucleus';
import _get from 'lodash/get';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from '../../actions/modalActions';
import { ConfirmModal } from '../../components/Modals/ConfirmModal/ConfirmModal';
import { selectSelection } from '../../selectors/selectionSelectors';

const mapStateToProps = (state: Global) => {
  const selectionArray = selectSelection(state);
  const confirmTitle = (selectionArray.length > 1) ?
    intl.get('deleteSelectedUsers') :
    intl.get('deleteSelectedUser');
  const confirmButtonText = intl.get('delete');
  const closeText = intl.get('modalCancel');
  const { error, isPending } = usersSelectors.userService(state);
  return {
    confirmTitle: confirmTitle,
    confirmButtonText: confirmButtonText,
    closeMessage: closeText,
    errorMessage: error ? intl.get('deleteSelectedUserFailed') : null,
    data: selectionArray,
    isBusy: error ? false : isPending
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async () => {
    // Remove any error state on close, so client can re-attempt
    await dispatch(usersActions.resetUserErrorState());
    dispatch(actions.closeModal());
  },
  confirmAction: async (users: string[]) => {
    if (users.length) {
      const usersToDelete = users.map((userUUID) => {
        return { uuid: userUUID };
      });
      try {
        await dispatch(usersActions.deleteUserList(usersToDelete));
        dispatch(actions.closeModal());
      } catch (error) {
        const errorDescription = _get(error, 'errorDescription', JSON.stringify(error));
        console.warn(errorDescription);
      }
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
