import React, { useState, useEffect, useMemo } from 'react';
import intl from 'react-intl-universal';
import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { RoomSystem } from 'typings/types';
import { Logger } from 'logger';
import { isSuccessfulAction } from 'utils/actionUtils';
import classes from './MaintenanceTabComponent.scss';
import { WaitButton, ButtonType } from 'components/Modal/ModalComponent/WaitButton';
import { deviceManagementCoronerSelector } from 'selectors/deviceManagementSelector';
import { DeviceCoronerState } from 'constants/deviceManagementConstants';
import deviceManagementActions, { getDeviceCoronerStatus, triggerDeviceCoroner } from 'actions/deviceManagementActions';
import { isRoomSystemOffline } from 'utils/roomSystemUtils';
import { formatStringDateTimeForDisplay } from 'utils/date-time-utils';

interface Props {
  roomSystem: RoomSystem;
}

const CoronerUploadComponent = (props: Props) => {
   const dispatch = useDispatch();
   const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
   const { roomSystem } = props;

   const canUpload = !isRoomSystemOffline(roomSystem);

   const coronerState = useSelector(deviceManagementCoronerSelector(roomSystem.serialNumber));

   const coronerStatus = _get(coronerState, 'status');
   const coronerMessage = _get(coronerState, 'message', '');
   const coronerLast = _get(coronerState, 'lastCoroner', '');

   const coronerComplete = useMemo(
      () => coronerStatus === DeviceCoronerState.UPLOAD_COMPLETE || coronerStatus === DeviceCoronerState.ERROR || coronerStatus === DeviceCoronerState.COLLECTING,
      [coronerStatus]
   );
   const coronerError = useMemo(
      () => coronerStatus === DeviceCoronerState.ERROR,
      [coronerStatus]
   );

   const coronerStateMessage = coronerStatus === DeviceCoronerState.ERROR ? intl.get('coronerDefaultError') : coronerStatus === DeviceCoronerState.COLLECTING ? intl.get('coronerProcessing') : intl.get('coronerSuccess');

   const notProcessingStates = [ DeviceCoronerState.IDLE, DeviceCoronerState.UPLOAD_COMPLETE, DeviceCoronerState.ERROR ];
   const coronerProcessing = coronerStatus && !notProcessingStates.includes(coronerStatus);

   useEffect(() => {
      getUploadStatus();
      return () => {
         if (timer) {
         clearTimeout(timer);
         }
      };
   },        []);

   useEffect(() => {
      if (coronerMessage) {
         Logger.info(`Error when collecting coroner: ${coronerMessage}`);
      }
   },        [coronerMessage]);

   useEffect(() => {
      if (coronerProcessing && !timer) {
         setTimer(
         setTimeout(() => {
            if (!coronerComplete) {
               const status = DeviceCoronerState.ERROR;
               const message = 'Timeout';
               const payload = {serialNumber: roomSystem.serialNumber, status: status, message: message};
   
               dispatch(deviceManagementActions.deviceCoronerStatus(payload));
            }
         },         300000)
         );
      } else if (timer && !coronerProcessing) {
         clearTimeout(timer);
         setTimer(null);
      }
   },        [timer, coronerProcessing]);

   const triggerUpload = async () => {
      const res: AnyAction = await dispatch(triggerDeviceCoroner(_get(roomSystem, 'serialNumber')));
      if (!isSuccessfulAction(res, triggerDeviceCoroner.fulfilled.type)) {
         Logger.info(`ERROR: triggering device coroner collection and upload failed: ${JSON.stringify(res)}`);
      }
   };

   const getUploadStatus = async () => {
      const res: AnyAction = await dispatch(getDeviceCoronerStatus(_get(roomSystem, 'serialNumber')));
      if (!isSuccessfulAction(res, getDeviceCoronerStatus.fulfilled.type)) {
         Logger.info(`ERROR: failed to get device coroner state: ${JSON.stringify(res)}`);
      }
   };

   return (
      <div className={classes.pane}>
         <div className={classes.contentContainer}>
            <div className={classes.paneHeader}>
              <div className={classes.paneTitle}>
                {intl.get('coronerTitle')}
              </div>
              {coronerLast && (
                <div className={classes.paneHeaderStatusItalic}>
                  {intl.get('coronerLastSentTime', { dateAndTime: formatStringDateTimeForDisplay(coronerLast.updated)})}
                </div>
              )}
            </div>
               <div className={classes.paneBody}>
               <div className={classes.textContainer}>
                  <div className={classes.shortContainer}>
                  {intl.get('coronerBody')}
                  </div>
               </div>
               <div className={classes.buttonContainerLog}>
               <WaitButton
                  data-test="uploadCoronerButton"
                  buttonText={intl.get('modalSend')}
                  isBusy={coronerProcessing && canUpload}
                  isDisabled={!canUpload}
                  isSubmitButton={true}
                  onSave={() => {
                     triggerUpload();
                  }}
                  type={ButtonType.submit}
               />
               {coronerComplete && <div className={coronerProcessing ? classes.buttonNotificationText : coronerError ? classes.buttonErrorNotificationText : classes.buttonSuccessNotificationText}> {coronerStateMessage} </div>}
               </div>
            </div>
         </div>
      </div>
   );
};

export default CoronerUploadComponent;
