import { pairedDevicesActions, pairedDevicesSelectors } from '@lifesize/nucleus';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from 'actions/modalActions';
import { actions as selectionActions } from 'actions/selectionActions';
import { selectSelection } from 'selectors/selectionSelectors';
import DeleteRoomSystemsModal from 'components/Modals/DeleteRoomSystems/DeleteRoomSystems';

const mapStateToProps = (state: Global) => {
  const selectionArray = selectSelection(state);
  const pairedDeviceSelector = pairedDevicesSelectors.pairedDevicesSelector(state);
  const serialNumber = selectionArray && selectionArray.length ? selectionArray[0] : undefined;
  const confirmTitle = selectionArray.length > 1 ? intl.get('deleteRoomSystems') : intl.get('deleteRoomSystem');
  const confirmButtonText = intl.get('delete');
  if (pairedDeviceSelector.removeDeviceError) {
    // Delete failed
    return {
      confirmTitle: confirmTitle,
      confirmButtonText: confirmButtonText,
      errorMessage: intl.get('deleteTabletFailed'),
      isBusy: false,
      selectionArray,
    };
  }

  return {
    confirmTitle: confirmTitle,
    confirmButtonText: intl.get('delete'),
    errorMessage: null,
    data: {
      serialNumber: serialNumber
    },
    isBusy: (pairedDeviceSelector.isRemovingDevice || !serialNumber || serialNumber === ''),
    selectionArray,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async () => {
    // Remove any error state on close, so client can re-attempt
    await dispatch(pairedDevicesActions.resetPairedDevicesErrorState());
    dispatch(actions.closeModal());
  },
  resetSelection: () => {
    dispatch(selectionActions.resetSelection());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRoomSystemsModal);
