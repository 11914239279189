import _get from 'lodash/get';
import { hasMoreRecords } from 'utils/directoryUtils';
import { Sort } from 'interfaces/Meeting';
import { DocumentNode } from 'graphql';
import { Logger } from 'logger';

type FetchMoreProperty = object | string | null | Sort | boolean | Function;

class ListFetcher {
  constructor() {
    this.properties = new Map<string, FetchMoreProperty>();
  }
  properties: Map<string, FetchMoreProperty>;
  get = (key: string) => {
    return this.properties.get(key);
  }
  set = (key: string, value: FetchMoreProperty) => {
    this.properties.set(key, value);
  }
  delete = (key: string) => {
    this.properties.delete(key);
  }
  clear = () => {
    this.properties.clear();
  }
  fetchMoreRows = async (query: DocumentNode, fetchPolicy = 'network-only', directoryName = 'directoryList') => {
    const directoryList = _get(this.get('data'), directoryName);
    // If directory list isn't set our initial load hasn't finished yet, so we
    // can't fetch more. We also bail if there are no more records to load.
    if (
      !this.get('isMounted') ||
      !directoryList ||
      !hasMoreRecords(this.get('data') as object)
    ) {
      return () => ({});
    }
    const fetchMore = this.get('fetchMore') as Function;
    if (!fetchMore) { return Promise.resolve({}); }
    const staticVariables = this.get('queryVariables') as object;
    const variables = {
      ...staticVariables,
      query: this.get('filterString'),
      cursor: this.get('cursor'),
      sort: this.get('sort'),
    };
    try {
      return fetchMore({
        query: query,
        fetchPolicy: fetchPolicy,
        variables: variables,
        // tslint:disable-next-line
        updateQuery: (prev: any, {fetchMoreResult}: any) => {
          // Merge our new results into previous cached results.
          const previousDirectoryList = _get(prev, directoryName, {});
          const fetchMoreResultDirectoryList = _get(fetchMoreResult, directoryName, {});
          this.set('cursor', _get(fetchMoreResultDirectoryList, 'cursor', {}));
          return {
            ...prev,
            [directoryName]: {
              ...previousDirectoryList,
              hasMoreRecords: _get(fetchMoreResultDirectoryList, 'hasMoreRecords', true),
              cursor: this.get('cursor'),
              records: [
                ..._get(previousDirectoryList, 'records', []),
                ..._get(fetchMoreResultDirectoryList, 'records', []),
              ],
            },
          };
        },
      });
    } catch (e) {
      Logger.info(`Unable to fetch more lists: ${e}`);
    }
  }
}

export default ListFetcher;
