import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const userState = (state: Global) => state.app?.userState;

const selectUserActive = createSelector(
  userState,
  (state) => {
    return state ? state.active : true;
  }
);

export {
  selectUserActive
};
