import { connect } from 'react-redux';
import {
  accountSelectors,
  customInviteActions,
  groupSettingsActions,
  groupSettingsSelectors,
  schedulingSelectors
} from '@lifesize/nucleus';
import { Global } from 'state/Global';
import { startCustomInvitesOff } from 'constants/localStorageConsts';
import { selectLocale } from 'selectors/languageSelectors';
import MeetingInvites from 'components/Settings/MeetingSettingsTab/MeetingInvites';

const mapDispatchToProps = (dispatch: Function) => ({
  updateMeetingInvitePreview: (config: object) => {
    dispatch(customInviteActions.getInvitationPreview(config));
  },
  updateMeetingInvites: async (settings: object, callback: Function) => {
    await dispatch(groupSettingsActions.setMeetingInvites(settings));
    callback();
  }
});

const mapStateToProps = (state: Global) => {
  const groupSettings = groupSettingsSelectors.groupSettings(state) || {};
  const accountFeatures = accountSelectors.getAccountFeatures(state) || {};
  // make sure the locale provided to the component is supported or undefined

  const invitationPreview = schedulingSelectors.meetingInvitePreview(state);
  const locale = selectLocale(state);

  return {
    customInviteShowAdditionalNumbers: startCustomInvitesOff
      ? false
      : groupSettings.customInviteShowAdditionalNumbers,

    customInviteShowConference: startCustomInvitesOff
      ? false
      : groupSettings.customInviteShowConference,

    customInviteShowOtherWays: startCustomInvitesOff
      ? false
      : groupSettings.customInviteShowOtherWays,

    customInviteShowPin: startCustomInvitesOff
      ? false
      : groupSettings.customInviteShowPin,

    customInviteShowSkype: startCustomInvitesOff
      ? false
      : groupSettings.customInviteShowSkype,

    invitationPreview,
    locale,
    hasPSTNAudio: accountFeatures.pstnNumbers !== 'NONE',
    hasSkypeForBusiness: groupSettings.customInviteShowSkype !== null, // property will not be null (initialState) if not enabled for account
    hasShowConference: groupSettings.customInviteShowConference !== null,
    hasOtherWays: groupSettings.customInviteShowOtherWays !== null,
    hasShowPin: groupSettings.customInviteShowPin !== null
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingInvites);
