import { Global } from 'state/Global';
import { createSelector } from 'reselect';
import { selectSelection } from './selectionSelectors';
import { ExportType } from 'interfaces/Downloads';

export const exportsSelector = (state: Global) => {
  return state.app?.exports?.exports || [];
};

export const exportsLoadingSelector = (state: Global) => {
  return state.app?.exports?.loading || false;
};

export const exportsErrorSelector = (state: Global) => {
  return state.app?.exports?.error || false;
};

export const selectSelectedConferenceExports = createSelector(
  selectSelection,
  (selections) => selections
    ?.filter((selection) => selection.split(',')[1] === ExportType.CONFERENCE)
    ?.map((selection) => selection.split(',')[0]) || []
);

export const selectSelectedDirectoryExports = createSelector(
  selectSelection,
  (selections) => selections
    ?.filter((selection) => selection.split(',')[1] !== ExportType.CONFERENCE)
    ?.map((selection) => selection.split(',')[0]) || []
);
