export enum SHIFT  {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum LOCATION {
  TOP = 'TOP',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM'
}
