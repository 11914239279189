import { createAction } from '@lifesize/typescript-react-redux-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UploadStatusResponse } from 'interfaces/Recording';
import { getUploadsStatus } from 'utils/uploadRecordingUtils';
import logger from 'utils/logger';
import _get from 'lodash/get';
import { Action } from 'redux';
import { Global } from 'state/Global';
import { getUploadUpdatingStatus } from 'selectors/recordingsSelectors';
import { Moment } from 'moment';

export enum RecordingsActionType {
  RECORDINGS_LAYOUT_CHANGE = 'RECORDINGS_LAYOUT_CHANGE',
  RECORDINGS_ADD_TO_WATCHLIST = 'RECORDINGS_ADD_TO_WATCHLIST',
  RECORDINGS_DELETE_RECORDING = 'RECORDINGS_DELETE_RECORDING',
  RECORDINGS_START_UPLOAD_UPDATES = 'RECORDINGS_START_UPLOAD_UPDATES',
  SET_DATE_RANGE_MOMENT = 'SET_DATE_RANGE_MOMENT',
  RECORDINGS_STOP_UPLOAD_UPDATES = 'RECORDINGS_STOP_UPLOAD_UPDATES',
  RECORDINGS_SET_SINGLE_ID = 'RECORDINGS_SET_SINGLE_ID',
  RECORDINGS_CLEAR_SEARCH_RESULTS = 'RECORDINGS_CLEAR_SEARCH_RESULTS',
  RECORDINGS_PENDING_RESULTS = 'RECORDINGS_PENDING_RESULTS',
  RECORDINGS_ADD_PROCESSING_UPLOAD = 'RECORDINGS_ADD_PROCESSING_UPLOAD',
  RECORDINGS_SET_UPLOADS_STATUS = 'RECORDINGS_SET_UPLOADS_STATUS',
}

const actions = {
  recordingPendingResults: () =>
    createAction(RecordingsActionType.RECORDINGS_PENDING_RESULTS),
  clearSearchResults: () =>
    createAction(RecordingsActionType.RECORDINGS_CLEAR_SEARCH_RESULTS),
  handleLayoutChange: (payload: boolean) => createAction(RecordingsActionType.RECORDINGS_LAYOUT_CHANGE, payload),
  handleAddToWatchList: (payload: string) =>
    createAction(RecordingsActionType.RECORDINGS_ADD_TO_WATCHLIST, payload),
  handleDeleteRecording: (payload: string) =>
    createAction(RecordingsActionType.RECORDINGS_DELETE_RECORDING, payload),
  setRecordingId: (id: string) => {
    return createAction(RecordingsActionType.RECORDINGS_SET_SINGLE_ID, id);
  },
  addProcessingUpload: (status: UploadStatusResponse) => {
    return createAction(RecordingsActionType.RECORDINGS_ADD_PROCESSING_UPLOAD, status);
  },
  getUploadsStatus: () => {
    return async (dispatch: Function, getState: () => Global) => {
      const isUpdating = getUploadUpdatingStatus(getState());
      if (!isUpdating) {
        return;
      }
      const res = await getUploadsStatus();
      return dispatch({
        type: RecordingsActionType.RECORDINGS_SET_UPLOADS_STATUS,
        payload: _get(res, 'uploads', [])
      });
    };
  },
  stopUploadUpdates: () =>
    createAction(RecordingsActionType.RECORDINGS_STOP_UPLOAD_UPDATES),
  startUploadUpdates: () =>
    createAction(RecordingsActionType.RECORDINGS_START_UPLOAD_UPDATES),
  handleSetDateRangeMoment: (payload: { startDate?: Moment, endDate?: Moment }) =>
    createAction(RecordingsActionType.SET_DATE_RANGE_MOMENT, payload),
  safeNucleusRecordingAction: (action: Action) => {
    return async (dispatch: Function) => {
      try {
        return await dispatch(action);
      } catch (e) {
        logger.info(`Unable to complete recording action: ${JSON.stringify(e)}`);
      }
    };
  },
  getVcCookie: createAsyncThunk(
    'getVcCookie',
    async (isImpersonating: boolean, thunkAPI) => {
      // If not in an impersonation session, then we try to obtain a VC Cookie
      if (!isImpersonating) {
        try {
          const vcCookieURL = process.env.REACT_APP_VCCOOKIE_URL || '';
          const resp = await fetch(vcCookieURL, { credentials: 'include' });
          if (resp.ok) {
            return resp;
          }
          return thunkAPI.rejectWithValue(resp);
        } catch (e) {
          logger.error(`Unable to fetch VC cookie: ${e}`);
          return thunkAPI.rejectWithValue(e);
        }
      }
    }
  )
};

export default actions;
