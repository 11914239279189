import { connect } from 'react-redux';
import { actions as exportToCsvActions } from 'actions/exportToCsvActions';
import { actions as ModalActions } from 'actions/modalActions';
import MeetingsTab from 'components/Meetings/MeetingsTab/MeetingsTab';
import { ModalTypes } from 'constants/modalConstants';
import { selectSelection } from 'selectors/selectionSelectors';
import { Global } from 'state/Global';
import { ListMeetingsQuery } from 'typings/types';
import MeetingInlineFragment = ListMeetingsQuery.MeetingInlineFragment;

const mapStateToProps = (state: Global) => {
  const selectionArray = selectSelection(state);
  return {
    title: '',
    selectionArray: selectionArray,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  addMeetingModalOpen: () => {
    dispatch(ModalActions.openModal({modalType: ModalTypes.ADD_MEETING}));
  },
  editMeeting: async (contact: MeetingInlineFragment) => {
    dispatch(
      ModalActions.openModal({
        modalType: ModalTypes.EDIT_MEETING,
        payload: contact,
      }),
    );
  },
  deleteMeetings: (meetings: Array<MeetingInlineFragment>) => {
    if (meetings.length > 0) {
      dispatch(
        ModalActions.openModal({
          modalType: ModalTypes.MEETINGS_CONFIRM_DELETE,
          payload: meetings,
        }),
      );
    }
  },
  resetExportState: () => {
    dispatch(exportToCsvActions.fetchCsvDataReset());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeetingsTab);
