import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import * as styles from '../Sections.scss';

export interface Props {
  label: string;
  isBold?: boolean;
  used?: number | null;
  total?: number | null;
  unlimited?: boolean;
  children?: React.ReactNode;
}

export const LabelData = ({
  label,
  isBold,
  used,
  total,
  unlimited,
  children,
}: Props) => {
  const renderData = () => {
    if (children) {
      return children;
    }
    if (total === null || (total === 0 && !unlimited)) {
      return intl.get('notIncluded');
    }
    if (total !== undefined && !isNaN(total) && !used) {
      return total === 0 ? intl.get('unlimited') : total;
    }
    if (total === 0) {
      return `${used} / ${intl.get('unlimited')}`;
    }
    return `${used} / ${total === 0 ? intl.get('unlimited') : total}`;
  };

  return (
    <dl>
      <dt className={classnames(isBold && styles.bold)}>
        {label}:
      </dt>
      <dd>
        {renderData()}
      </dd>
    </dl>
  );
};
