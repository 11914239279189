import { meetingsActions, meetingsSelectors } from '@lifesize/nucleus';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from 'actions/modalActions';
import DeleteMeetingsModal from 'components/Modals/DeleteMeetings/DeleteMeetings';
import { selectSelection } from 'selectors/selectionSelectors';
import { actions as selectionActions } from 'actions/selectionActions';

const mapStateToProps = (state: Global) => {

  const selectionArray = selectSelection(state);
  const confirmTitle = (selectionArray.length > 1) ?
    intl.get('deleteSelectedMeetings') :
    intl.get('deleteSelectedMeeting');

  const confirmButtonText = intl.get('delete');
  const closeText = intl.get('modalCancel');

  const hasError = meetingsSelectors.getErrorSelector(state);
  if (hasError) {
    // Delete failed
    return {
      confirmTitle: confirmTitle,
      confirmButtonText: confirmButtonText,
      closeMessage: closeText,
      errorMessage: intl.get('deleteMeetingFailed'),
      selectionArray,
      isBusy: false
    };
  }

  return {
    confirmTitle: confirmTitle,
    confirmButtonText: confirmButtonText,
    closeMessage: closeText,
    errorMessage: null,
    selectionArray,
    isBusy: meetingsSelectors.isBusySelector(state)
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: async () => {
    // Remove any error state on close, so client can re-attempt
    await dispatch(meetingsActions.resetMeetingErrorState());
    dispatch(actions.closeModal());
  },
  resetSelection: () => {
    dispatch(selectionActions.resetSelection());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMeetingsModal);
