import React from 'react';
import { Button } from 'semantic-ui-react';
import { Tooltip } from 'components/tooltip/Tooltip';
import _snakeCase from 'lodash/snakeCase';

interface Props {
  className?: string;
  clickHandler: () => void;
  ['data-test']?: string;
  disabledTooltipText?: string;
  id?: string;
  isEnabled: boolean;
  label: string;
  showWarningText?: boolean;
  tooltipIsOnRight?: boolean;
  warningTooltipText?: string;
}

const ToolBarButton: React.FunctionComponent<Props> = (props) => {
  const {
    className,
    clickHandler,
    disabledTooltipText = '',
    id,
    isEnabled,
    label,
    showWarningText,
    tooltipIsOnRight,
    warningTooltipText = ''
  } = props;
  const buttonId = id || 'btn_' + _snakeCase(label);
  const testProp = props['data-test'] ? { ['data-test']: props['data-test'] } : {};
  const renderButton = () => {
    return (
      <Button
        className={className}
        disabled={!isEnabled}
        id={buttonId}
        name={buttonId}
        onClick={(evt: React.SyntheticEvent) => {
          if (evt) {
            evt.stopPropagation();
          }
          clickHandler();
        }}
        role="button"
        type="button"
        {...testProp}
      >
        {label}
      </Button>
    );
  };

  if (isEnabled) {
    return (
      <div>
        {renderButton()}
      </div>
    );
  }
  return (
    <Tooltip
      text={(showWarningText) ? warningTooltipText : disabledTooltipText}
      isOnRight={tooltipIsOnRight}
    >
      {renderButton()}
    </Tooltip>
  );
};

export default ToolBarButton;
