import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { dataWarehouseActions } from '@lifesize/nucleus';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import { selectStartDate, selectEndDate } from 'selectors/dashboardSelectors';
import { liveStreamV2Cutoff } from 'utils/liveStreamUtils';
import {
  DRILLDOWN_FETCH_LIVE_STREAM_HISTORY,
  DRILLDOWN_FETCH_LIVE_STREAM_HISTORY_BY_NAME
} from 'actions/liveStreamActions';
import { Global } from 'state/Global';

const checkDates = (state: Global, callback: (startDate: string, endDate: string) => void) => {
  const selectedStartDate = selectStartDate(state);
  const selectedEndDate = selectEndDate(state);
  if (selectedStartDate < liveStreamV2Cutoff) {
    const endDate = selectedEndDate > liveStreamV2Cutoff ? liveStreamV2Cutoff : selectedEndDate;
    callback(selectedStartDate, endDate);
  }
  return;
};

const DrilldownLiveStreamHistoryMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: PayloadAction<string, string>) => {
  const state = store.getState();
  switch (action.type) {
      case DRILLDOWN_FETCH_LIVE_STREAM_HISTORY_BY_NAME:
        checkDates(state, (startDate: string, endDate: string) => {
          store.dispatch(dataWarehouseActions.fetchStreamHistoryByName(startDate, endDate, action.payload));
        });
        break;
      case DRILLDOWN_FETCH_LIVE_STREAM_HISTORY:
        checkDates(state, (startDate: string, endDate: string) => {
          store.dispatch(dataWarehouseActions.fetchStreamsFilteredByList(startDate, endDate));
        });
        break;
      default:
        break;
    }
  return next(action);
};

export default (DrilldownLiveStreamHistoryMiddleware as Middleware);
