import { DEFAULT_LOCALE } from '../constants/languageConstants';

export interface LanguageState {
  locale: string;
}

let locale = localStorage.getItem('locale') || DEFAULT_LOCALE;
if (locale.trim().toLocaleLowerCase() === 'cz'.trim().toLocaleLowerCase()) {
  locale = 'cs';
}
if (locale.trim().toLocaleLowerCase() === 'zh-hans'.trim().toLocaleLowerCase()) {
  locale = 'zh';
}

export const initialLanguageState: LanguageState = {
  locale
};
