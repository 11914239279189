import { DWHCallHistoryResponseType } from '../../../interfaces/Dashboard';
import React from 'react';
import intl from 'react-intl-universal';
import ListViewComponent from '../../ListView/ListViewComponent';
import { CallHistoryDrillDownTable } from './CallHistoryDrillDownTable';
import { formatStringDateTimeForDisplay, formatMinutesForDisplay } from 'utils/date-time-utils';

interface Props {
  data: Array<DWHCallHistoryResponseType>;
  pageNumber: number;
}

interface State {
  expandedRows: object;
}

class CallHistoryTable extends React.Component<Props, State> {

  readonly state = {
    expandedRows: {}
  };

  /**
   * Keep track of the expanded row # as well as the page #
   * @param column - Target sent by react-table on row expand
   */
  onExpandedChange = (column: object) => {
    this.setState({ expandedRows: {...this.state.expandedRows, ...{[this.props.pageNumber] : column}}});
  }

  render(): React.ReactNode {
    const { data, pageNumber } = this.props;
    const { expandedRows } = this.state;
    return (
      <ListViewComponent
        data={data}
        subComponent={CallHistoryDrillDownTable}
        onExpandedChange={this.onExpandedChange}
        expandedRows={expandedRows}
        columns={
          [{
            Header: intl.get('name'),
            accessor: 'conferenceName'
          }, {
            Header: intl.get('date'),
            accessor: 'startDate',
            // Example of sorting by number, but displaying formatted text
            Cell: (row) => {
              return (row.original.startDate) ? formatStringDateTimeForDisplay(row.original.startDate) : '';
            }
          }, {
            Header: intl.get('duration'),
            accessor: 'durationMinutes',
            Cell: (row) => {
              return (row.original.durationMinutes) ? formatMinutesForDisplay(row.original.durationMinutes) : '';
            }
          },
            {
              Header: intl.get('participants'),
              accessor: 'participantsCount'
            }
          ]
        }
        showPagination={false}
        pageNumber={pageNumber}
      />
    );
  }
}

export { CallHistoryTable };
