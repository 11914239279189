import ListViewComponent from 'components/ListView/ListViewComponent';
import _memoize from 'lodash/memoize';
import React from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import SearchBar from '../../../containers/Search/SearchBar';
import { DWHUserCallsResultType } from '../../../interfaces/Dashboard';
import { exportToCsv } from '../../../utils/csv-utils';
import { parseUserDrillDownDataForExport } from '../../../utils/exportUtils';
import ListViewLayout from '../../ListViewLayout/ListViewLayout';
import DrilldownHeader from '../Common/DrilldownHeader';
import * as styles from './UsersTable.scss';

interface Props {
  error: string;
  fetchListData: Function;
  isFetching: boolean;
  listData: Array<DWHUserCallsResultType>;
  numberOfPages: number;
  onSelect: Function;
  pageNumber: number;
}

class UsersTable extends React.Component<Props> {
  getTitle = _memoize(() => {
    return (
      <>
        <Icon classes={['icon-room-system']} />
        <span>{intl.get('users')}</span>
      </>
    );
  });

  handleExportToCsv = () => {
    const exportData = [];
    const sort = this.props.listData.sort(
      (a: DWHUserCallsResultType, b: DWHUserCallsResultType) => {
        return b.totalMinutes - a.totalMinutes;
      });
    for (let user of sort) {
      exportData.push(parseUserDrillDownDataForExport(user));
    }
    exportToCsv(intl.get('users'), exportData);
  }

  render() {
    const { error, fetchListData, isFetching, listData, numberOfPages, onSelect, pageNumber} = this.props;
    return (
      <ListViewLayout
        error={error}
        fetchListData={fetchListData}
        hideHeader={true}
        isFetching={isFetching}
        numberOfPages={numberOfPages}
        title={this.getTitle()}
      >
        <SearchBar/>

        <DrilldownHeader
          fetchData={this.props.fetchListData}
          onExportClick={this.handleExportToCsv}
        />

        {isFetching ? null :
          <ListViewComponent
            data={listData ? listData : []}
            columns={
              [{
                Header: intl.get('tableHeaderName'),
                accessor: 'name',
                Cell: (row: { value: string }) => (
                  <a
                    className={styles.editButton}
                    onClick={() => onSelect(row.value)} // TODO: use an ID (uuid) rather than a name string
                  >
                    {row.value}
                  </a>
                )
              }, {
                Header: intl.get('minutes'),
                accessor: 'totalMinutes',
              }, {
                Header: intl.get('calls'),
                accessor: 'totalCalls'
              }]
            }
            showPagination={false}
            maxRows={25}
            pageNumber={pageNumber}
          />
        }
      </ListViewLayout>
    );
  }
}

export default UsersTable;
