import * as React from 'react';
import { SyntheticEvent } from 'react';
import * as styles from './RadioButton.scss';
import { ArrowPosition, Tooltip } from 'components/tooltip/Tooltip';
import classnames from 'classnames';

interface Props {
  className: string;
  containerClassName?: string;
  disabled?: boolean;
  field: {
    name: string,
    value: string | number,
    onChange?: (event: SyntheticEvent) => void,
    onClick?: (event: SyntheticEvent) => void,
    onBlur: (event: SyntheticEvent) => void
  };
  hidenow: boolean;
  id: string;
  label: string;
  toolTip?: string;
}

class RadioButton extends React.PureComponent<Props> {
  renderRadioButton = () => {
    const {
      className,
      disabled = false,
      field: {name, value, onChange, onClick, onBlur},
      hidenow,
      id,
      label,
      toolTip = '',
      containerClassName,
      ...props
    } = this.props;
    return (
      <label className={classnames(styles.container, disabled && styles.disabled, containerClassName && containerClassName)}>
        <input
          checked={id === value}
          className={className}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          type="radio"
          value={id}
          // could be something else for output?
          {...props}
        />
        <i className={hidenow ? styles.hideNow : undefined} data-test="profile"/>
        <span>{label}</span>
      </label>
    );
  }

  render() {
    const { toolTip } = this.props;
    if (toolTip) {
      return (
        <Tooltip
          arrowPosition={ArrowPosition.left}
          isBottomTip={true}
          isRadioButtonTip={true}
          text={toolTip}
        >
          {this.renderRadioButton()}
        </Tooltip>
      );
    }
    return this.renderRadioButton();
  }
}

export default RadioButton;
