import { connect } from 'react-redux';
import { monitoringActions, groupSettingsSelectors, iconActions } from '@lifesize/nucleus';
import IconSettings from 'components/Settings/IconSettingsTab/IconSettings';
import { Global } from 'state/Global';
import { selectIsCustomWallPaperEnabled, selectImagePreviewURL, selectHasTypeError, selectHasDimensionsError, selectSizeError } from 'selectors/wallpaperSelectors';
import { setIsCustomWallPaperEnabled } from 'actions/wallpaperActions';

const mapStateToProps = (state: Global) => {
  const groupSettings = groupSettingsSelectors.groupSettings(state) || {};
  const typeError = selectHasTypeError()(state);
  const dimensionsError = selectHasDimensionsError()(state);
  const sizeError = selectSizeError()(state);
  return {
    alertNotificationsEmail: groupSettings.alertNotificationsEmail || '',
    alertNotificationsEnabled: groupSettings.alertNotificationsEnabled || false,
    isCustomWallPaperEnabled: selectIsCustomWallPaperEnabled()(state),
    imagePreviewURL: selectImagePreviewURL()(state),
    isUpdateEnabled: !typeError && !dimensionsError && !sizeError
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  enableCustomBackground: (isEnabled: boolean) => {
    dispatch(setIsCustomWallPaperEnabled(isEnabled));
  },
  fetchData: () =>
    Promise.all([
      dispatch(monitoringActions.getGroupSettings())
    ]),
  updateEventAlerts: async (alertNotificationsEmail: string, alertNotificationsEnabled: boolean, callback: Function) => {
    await dispatch(monitoringActions.putGroupSettings({ alertNotificationsEmail, alertNotificationsEnabled }));
    callback();
  },
  saveWallpaper: (imagePreviewURL: string, isCustomWallPaperEnabled: boolean) => {
    if (!isCustomWallPaperEnabled) {
      dispatch(iconActions.sendWallPaperURL(''));  
    } else {
      dispatch(iconActions.sendWallPaperURL(imagePreviewURL));
    }
    
  }
});

export const IconSettingsContainer = connect(mapStateToProps, mapDispatchToProps)(IconSettings);
