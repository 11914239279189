import { dataWarehouseSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { actions as LiveStreamActions } from 'actions/liveStreamActions';
import LiveStreamHistory from 'components/Drilldowns/LiveStreamHistory/LiveStreamHistory';
import { DWHLiveStreamResultType } from 'interfaces/Dashboard';
import * as dashboardSelectors from 'selectors/dashboardSelectors';
import { Moment } from 'moment';
import { liveStreamV2Cutoff, fetchMoreLiveStreamRows } from 'utils/liveStreamUtils';
import { Global } from 'state/Global';
import { currentOldLiveStreamsSelector } from 'selectors/liveStreamHistorySelector';

interface OwnProps {}

interface StateProps {
  meetingName?: string;
  title: string;
  meetingId: string;
  startDate: Moment;
  hasNewData: boolean;
  fetchMoreRows: Function;
  endDate: Moment;
  data: Array<DWHLiveStreamResultType>;
  isFetching: boolean;
  error: string;
}

interface DispatchProps {
  fetchListData: (startDate?: Moment, meetingName?: string) => void;
}

function mapStateToProps(state: Global, ownProps: RouteComponentProps<{}> & OwnProps) {
  const startDate = dashboardSelectors.selectStartDateMoment;
  const endDate = dashboardSelectors.selectEndDateMoment;
  const selectedStartDate = startDate(state);
  const selectedEndDate = endDate(state);
  const hasNewData = !selectedEndDate.isBefore(liveStreamV2Cutoff);
  const meetingName = _get(ownProps, 'match.params.name');
  const dataState = dataWarehouseSelectors.streamsByList;
  const isFetching = dataState(state).isFetching;

  const currentData = currentOldLiveStreamsSelector(state);

  return {
    title: meetingName ? `${meetingName} ${intl.get('breadcrumbHistory')}` : intl.get('liveStreamHistory'),
    meetingId: _get(ownProps, 'match.params.meetingId'),
    startDate: selectedStartDate,
    hasNewData,
    singleMeeting: !!meetingName,
    meetingName: meetingName ? decodeURIComponent(meetingName) : undefined,
    fetchMoreRows: fetchMoreLiveStreamRows,
    endDate: endDate(state),
    data: currentData,
    isFetching,
    error: dataState(state).data.error,
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchListData: (startDate?: Moment, meetingName?: string) => {
    if (startDate && startDate.isBefore(liveStreamV2Cutoff)) {
      if (meetingName) {
        dispatch(LiveStreamActions.fetchLiveStreamHistoryByName(meetingName));
      } else {
        dispatch(LiveStreamActions.fetchLiveStreamHistory());
      }
    }
  }
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => (
  {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchListData: (startDate: Moment) => dispatchProps.fetchListData(startDate, _get(stateProps, 'meetingName'))
  }
);

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LiveStreamHistory);
