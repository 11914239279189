import classnames from 'classnames';
import OkResetWaitButtonGroup from 'components/Common/OkResetWaitButtonGroup';
import TooltipFixedPosition from 'components/TooltipFixedPosition/TooltipFixedPosition';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { LOCATION } from 'constants/tooltipFixedPositionConstants';
import { useTimeout, UseTimeoutResponse } from 'hooks/useTimeout';
import * as styles from './MaxInactiveDays.scss';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  inputId: string;
  error?: string;
  label: string;
  tooltip: string;
  isBusy: boolean;
  lastSavedValue: number | undefined;
  handleSave: (payload: object) => void;
}
const numberCheck = (num: number | undefined) => {
  return _isNumber(num) && !isNaN(num);
};

const getInputString = (input: number | undefined) =>
  numberCheck(input)
    ? input?.toString() || ''
    : '';

const getInputNumber = (input: string) => {
  const num = parseInt(input, 10);
  return (
    numberCheck(num)
      ? num
      : 0
  );
};

const isDirty = (maxInactiveDays: string, lastSavedValue: number | undefined) => {
  return maxInactiveDays !== getInputString(lastSavedValue);
};

const isValid = (value: string) => {
  const maxInactiveDays = parseInt(value, 10);
  return numberCheck(maxInactiveDays) && (maxInactiveDays <= 365);
};

const MaxInactiveDays = (props: Props) => {
  const {
    label,
    tooltip,
    isBusy,
    lastSavedValue,
    handleSave,
    error,
    inputId
  } = props;
  const [validationError, setValidationError] = useState<string | undefined>(undefined);
  const [maxInactiveDays, setMaxInactiveDays] = useState(getInputString(lastSavedValue));
  const [warnTimeout, setWarnTimeout] = useState<UseTimeoutResponse | null>(null);
  const warnNotSaved = useTimeout(() => setValidationError(intl.get('maxInactiveDaysNotSaved')), 200);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  useEffect(
    () => {
      setMaxInactiveDays(getInputString(lastSavedValue));
    },
    [lastSavedValue]
  );

  const canSave = () => !!(canEditSettings && !isBusy && isDirty(maxInactiveDays, lastSavedValue) && isValid(maxInactiveDays));
  const handleBlur = () => {
    if (dirty) {
      setWarnTimeout(warnNotSaved());
    }
  };

  const handleMaxInactiveDaysChange = (event: SyntheticEvent<HTMLInputElement>) => {
    if (!canEditSettings) { return; }
    const newValue = (_get(event, 'target.value') || '').replace(/[^\d]/g, '');
    const changeError = isValid(newValue)
      ? undefined
      : intl.get('maxInactiveDaysValidation');
    setValidationError(changeError);
    setMaxInactiveDays(newValue);
  };

  const clearTimeoutWarning = () => {
    if (warnTimeout) {
      warnTimeout.clear();
      setWarnTimeout(null);
    }
  };

  const handleReset = () => {
    clearTimeoutWarning();
    const lastSavedNumber = Number(lastSavedValue);
    setMaxInactiveDays(isNaN(lastSavedNumber) ? '0' : lastSavedNumber.toString());
    setValidationError(undefined);
  };

  const handleSubmit = () => {
    clearTimeoutWarning();
    if (!canEditSettings) { return; }
    setValidationError(undefined);
    handleSave({
      requestedNumberOfDays: getInputNumber(maxInactiveDays),
      cancelCallback: handleReset
    });
  };

  const dirty = isDirty(maxInactiveDays, lastSavedValue);
  return (
    <div className={classnames(styles.container, isBusy && styles.isBusy)}>
      <label className="label">{label}</label>
      <div className={styles.inputField}>
        <div>
          <input
            id={inputId}
            maxLength={3}
            name="expiration"
            onBlur={handleBlur}
            onChange={handleMaxInactiveDaysChange}
            value={maxInactiveDays}
            disabled={!canEditSettings}
          />
          <TooltipFixedPosition
            location={LOCATION.RIGHT}
            label={tooltip}
          />
        </div>
        {dirty &&
        <OkResetWaitButtonGroup
          isSubmitting={isBusy}
          hasErrors={!canSave()}
          isAssociatedFieldTouched={true}
          handleReset={handleReset}
          handleSubmit={handleSubmit}
        />
        }
        {error && !validationError &&
        <div className={styles.error}>{intl.get('maxInactiveDaysAPIError')}</div>
        }
        {validationError &&
        <div className={styles.error}>{validationError}</div>
        }
      </div>
    </div>
  );
};

export default MaxInactiveDays;
