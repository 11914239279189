import { Global } from 'state/Global';
import { currentUserSelectors, nucleusConstants, proxyActions } from '@lifesize/nucleus';
import { Logger } from 'logger';
import _get from 'lodash/get';
import { LS_ADMIN_TOPIC } from 'constants/wampConstants';

const thunks = {
  subscribeToMerge: () => {
    return async (dispatch: Function, getState: () => Global) => {
      const state = getState();
      const userUUID = _get(currentUserSelectors.myInfo(state), 'userUuid');
      if (!userUUID) { return; }
      try {
        const topic = LS_ADMIN_TOPIC;
        dispatch({ type: nucleusConstants.WAMP_ADD_CONFERENCE_TOPIC, payload: topic });
        dispatch(proxyActions.subscribe(topic));
      } catch (err) {
        Logger.info(`Unable to subscribe to admin topic: ${JSON.stringify(err)}`);
      }
    };
  },
  unsubscribeToMerge: () => {
    return async (dispatch: Function, getState: () => Global) => {
      const state = getState();
      const userUUID = _get(currentUserSelectors.myInfo(state), 'userUuid');
      if (!userUUID) { return; }
      try {
        const topic = `${LS_ADMIN_TOPIC}.${userUUID}`;
        dispatch({ type: 'WAMP_REMOVE_CONFERENCE_TOPIC', payload: topic });
        dispatch(proxyActions.unsubscribe(topic));
      } catch (err) {
        Logger.info(`Unable to unsubscribe to admin topic: ${JSON.stringify(err)}`);
      }
    };
  }
};

export default thunks;
