import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import {
  CREATE_DOWNLOAD_NOTIFICATION,
  DISMISS_DOWNLOAD_NOTIFICATION,
  REMOVE_FAILED_DOWNLOADS,
  REMOVE_PENDING_DOWNLOADS,
} from 'constants/downloadNotificationConstants';
import downloadNotificationActions from 'actions/downloadNotificationActions';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import React from 'react';
import { cssTransition, toast } from 'react-toastify';
import DownloadStartedToast from 'components/DownloadToasts/DownloadStartedToast';
import DownloadCompletedToast from 'components/DownloadToasts/DownloadCompletedToast';
import DownloadFailedToast from 'components/DownloadToasts/DownloadFailedToast';
import * as styles from 'components/DownloadToasts/DownloadToasts.scss';
import { getDownloadNotificationToastId } from 'selectors/downloadNotificationSelectors';
import { SET_EXPORTS } from 'constants/downloadConstants';
import { Download, ExportStatus } from 'interfaces/Downloads';
import { currentUserSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';

const transitionProps = {
  enter: styles.hideTransition,
  exit: styles.hideTransition,
  duration: 0,
  collapse: false
};

const transition = cssTransition(transitionProps);
const setFalse: false = false;
const downloadToastOptions = {
  autoClose: setFalse,
  closeButton: false,
  draggable: false,
  className: styles.toastContainer,
  closeOnClick: false,
  transition: transition
};

const downloadStartedNotification = () => {
  return toast(<DownloadStartedToast />, downloadToastOptions);
};

const downloadCompletedNotification = () => {
  return toast(<DownloadCompletedToast />, downloadToastOptions);
};

const downloadFailedNotification = () => {
  return toast(<DownloadFailedToast />, downloadToastOptions);
};

const dismissToast = (store: MiddlewareAPI) => {
  const toastId = getDownloadNotificationToastId(store.getState());
  if (toastId) {
    toast.dismiss(toastId);
  }
};

const setToast = (generateToast: () => React.ReactText, store: MiddlewareAPI) => {
  dismissToast(store);
  const id = generateToast();
  return store.dispatch(downloadNotificationActions.setDownloadNotificationId(id));
};

const deviceManagementMiddleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (action: PayloadAction<string, Download[]>) => {
  switch (action.type) {
    case CREATE_DOWNLOAD_NOTIFICATION:
      setToast(downloadStartedNotification, store);
      break;
    case DISMISS_DOWNLOAD_NOTIFICATION:
      dismissToast(store);
      break;
    case SET_EXPORTS:
      const exports = action.payload || [];
      const userProfile = currentUserSelectors.myInfo(store.getState());
      const userId = _get(userProfile, 'userUuid');
      const pendingDownloadIds = exports.reduce(
        (acc, curr) => {
          const isUserExport = curr.userId === userId;
          return (curr.status === ExportStatus.IN_PROGRESS) && isUserExport ? [...acc, curr.id] : acc;
        },
        []
      );
      if (pendingDownloadIds.length) {
        store.dispatch(downloadNotificationActions.addPendingDownloads(pendingDownloadIds));
      }
      break;
    case REMOVE_PENDING_DOWNLOADS:
      setToast(downloadCompletedNotification, store);
      break;
    case REMOVE_FAILED_DOWNLOADS:
      setToast(downloadFailedNotification, store);
      break;
    default:
      break;
  }

  return next(action);
};

export default deviceManagementMiddleware as Middleware;
