import { PermissionTypes } from 'constants/permissionsConstants';
import React from 'react';
import { connect } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { Global } from 'state/Global';
import { PermissionsState } from 'state/Permissions';
import { hasAccess } from './canUtil';
import { ROLE_PERMISSION } from 'interfaces/Role';

const mapStateToProps = (state: Global) => {
  const permissions = selectAccountPermissions(state);

  return {
    permissionsObject: permissions
  };
};

interface Props {
  permissionsObject: PermissionsState;
  userAccess: PermissionTypes[] | ROLE_PERMISSION[];
  children?: JSX.Element | boolean;
}
class CanComponent extends React.PureComponent<Props>  {

  render() {
    const {
      permissionsObject,
      userAccess,
      children
    } = this.props;
    if (hasAccess(userAccess, permissionsObject)) {
      return (
        <>
          {!!children && children}
        </>
      );
    }
    return <React.Fragment />;
  }
}

export default connect(mapStateToProps)(CanComponent);
