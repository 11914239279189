import { createAsyncThunk } from '@reduxjs/toolkit';
import { proxyActions } from '@lifesize/nucleus';
import { Methods } from 'interfaces/HTTP';
import { ACCOUNT_GET_DPA, ACCOUNT_SET_DPA, DPA_LOG_SERVICE } from 'constants/logDpaConstants';
import { DPA_STATUS } from 'interfaces/logDPA';

export const getDpaStatus = createAsyncThunk(
  'logDPA-getAccount',
  async (version: string, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      DPA_LOG_SERVICE,
      ACCOUNT_GET_DPA,
      Methods.GET,
      {
        version
      }
    ));
    return JSON.parse(res);
  }
);

export const setDpaStatus = createAsyncThunk(
  'logDPA-setAccount',
  async (payload: { version: string, status: DPA_STATUS }, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      DPA_LOG_SERVICE,
      ACCOUNT_SET_DPA,
      Methods.POST,
      payload
    ));
    return JSON.parse(res);
  }
);
