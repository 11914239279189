import React, { useEffect } from 'react';
import ExportButton from 'components/Common/ExportButton';
import { DWHLiveStreamQuestionType, DWHLiveStreamViewerType } from 'interfaces/Dashboard';
import * as styles from './LiveStreamsToolbar.scss';

// props from Redux Store (mapStateToProps)
export interface StateProps {
  isExporting: boolean;
}

// props from Actions (mapDispatchToProps)
export interface DispatchProps {
  handleExportData: () => void;
  resetExportState: () => void;
}

// props passed in
export interface OwnProps {
  activeTab: string;
  data: DWHLiveStreamQuestionType[] | DWHLiveStreamViewerType[];
}

type Props = StateProps & DispatchProps & OwnProps;

const LiveStreamsToolbar = (props: Props) => {
  const { resetExportState, handleExportData, isExporting } = props;
  useEffect(
      () => {
        resetExportState();
      },
      []
  );

  return (
      <div className={styles.container}>
        <ExportButton
          disabled={false}
          isExporting={isExporting}
          onExportClick={handleExportData}
          tooltipProps={{ isOnRight: true }}
        />
      </div>
  );
};

export default LiveStreamsToolbar;
