import React from 'react';
import classnames from 'classnames';
import _memoize from 'lodash/memoize';
import * as styles from './Icon.scss';

/*
 * this component requires that the global classes 'icon' along with the icon-class passed in, exist
 * be sure to import the IcoMoon generated css or scss globally
 */

// props passed in
export interface OwnProps {
  classes: string[];
  rotate?: number; // accepts 90, 180, 270
}

const getRotationStyle = _memoize((rotation: number) => {
  switch (rotation) {
    case 90:
      return styles.rotate90;
    case 180:
      return styles.rotate180;
    case 270:
      return styles.rotate270;
    default:
      return '';
  }
});

class Icon extends React.PureComponent<OwnProps> {
  render(): React.ReactNode {
    const { classes, rotate } = this.props;
    const classNames = classnames(
      styles.container,
      'icon',
      classes,
      rotate && getRotationStyle(rotate)
    );

    return (
      <i className={classNames} />
    );
  }
}

export default Icon;
