import React from 'react';
import * as styles from './ClickableCell.scss';

interface Props {
  id: string;
  isClickable: boolean;
  title?: string;
  displayText: string;
  clickHandler?: Function;
}

const ClickableCell = ({ id, title, isClickable, clickHandler, displayText}: Props) => (
  <div
    id={id}
    title={title ? title : undefined}
    className={isClickable ? styles.clickableElement : ''}
    onClick={(evt) => {
      if (clickHandler && isClickable) {
        clickHandler(evt);
      }
    }}
  >
    {displayText}
  </div>
);

export default ClickableCell;
