import { PermissionTypes } from 'constants/permissionsConstants';
import { PermissionsState } from 'state/Permissions';
import { ROLE_PERMISSION } from 'interfaces/Role';

export const hasAccess = (userAccess: PermissionTypes[] | ROLE_PERMISSION[], permissionsObject: PermissionsState) => {
  let doesUserHaveAccess = true;
  for (let x = 0; x < userAccess.length; x++) {
    const permissionNeeded = userAccess[x];
    if (permissionsObject[permissionNeeded] === false) {
      doesUserHaveAccess = false;
      break;
    }
  }
  return doesUserHaveAccess;
};
