import React, { useEffect, useState } from 'react';
import { DateRangePicker } from '@lifesize/react-components';
import { getRecordingDateRangePresets } from 'utils/date-time-utils';
import recordingActions from 'actions/recordingsActions';
import intl from 'react-intl-universal';
import { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectEndDate, selectStartDate } from 'selectors/recordingsSelectors';

export const RecordingsDateRangePicker = () => {
  const startTime = useSelector(selectStartDate);
  const endTime = useSelector(selectEndDate);
  const dispatch = useDispatch();

  const [showDateRangePicker, setShowDateRangePicker] = useState(true);

  useEffect(
    () => {
      if (!showDateRangePicker) {
        setShowDateRangePicker(true);
      }
    },
    [showDateRangePicker]
  );

  const DateRangePickerWrapper = (
    <DateRangePicker
      // @ts-ignore
      initialStart={startTime}
      // @ts-ignore
      initialEnd={endTime}
      presets={getRecordingDateRangePresets(() => {
        dispatch(recordingActions.handleSetDateRangeMoment(
          {
            startDate: undefined,
            endDate: undefined
          }
        ));
        setShowDateRangePicker(false);
      })}
      startDatePlaceholderText={intl.get('beginning')}
      endDatePlaceholderText={intl.get('today')}
      onRangeChanged={(dateStart: Moment, dateEnd: Moment) => {
        dispatch(recordingActions.handleSetDateRangeMoment(
          {
            startDate: dateStart,
            endDate: dateEnd
          }
        ));
      }}
    />
  );

  // hack to close the calendar on clicking "all"
  return (
    <>
      {showDateRangePicker ? DateRangePickerWrapper : <div style={{ width: '296px' }} />}
    </>
  );
};
