import _sortBy from 'lodash/sortBy';

// tslint:disable-next-line
export const sortByStartDateDesc = (data: Array<any>) => {
  if (!data) {
    return [];
  }
  return _sortBy(data, (o) => {
    return -(new Date(o.startDate).getTime());
  });
};

// tslint:disable-next-line
export const sortByTotalMinutesDesc = (data: Array<any>) => {
  if (!data) {
    return [];
  }
  return _sortBy(data, (o) => {
    return -(o.totalMinutes || 0);
  });
};
