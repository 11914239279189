import { authActions, currentUserSelectors } from '@lifesize/nucleus';
import { actions as ModalActions } from 'actions/modalActions';
import { actions as resetPasswordActions } from 'actions/resetPasswordActions';
import { actions as userProfileActions } from 'actions/userProfileActions';
import Icon from 'components/Common/Icon';
import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { Dropdown } from 'semantic-ui-react';
import { ModalTypes } from '../../constants/modalConstants';
import { PermissionTypes } from '../../constants/permissionsConstants';
import { Global } from '../../state/Global';
import { withAuth } from '../../utils/hocs/withAuth';
import Can from '../Can/CanComponent';
import * as styles from './UserProfileButton.scss';

interface Props {
  userUuid: string;
  logout?: () => void;
  openModal: (userUuid: string) => void;
}

const mapStateToProps = (state: Global) => {
  const userProfile = currentUserSelectors.myInfo(state);
  return {
    userUuid: _get(userProfile, 'userUuid'),
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    logout: () => dispatch(authActions.logout()),
    openModal: async (userUuid: string) => {
      // Optional callback action to perform after fetch of user profile data
      const successHandler = () => dispatch(ModalActions.openModal({ modalType: ModalTypes.USER_PROFILE }));
      await dispatch(resetPasswordActions.clearResetPasswordState());
      await dispatch(userProfileActions.fetchUserProfile({
        userUuid,
        successHandler
      }));
    },
  };
};

const UserProfileComponent: React.FunctionComponent<Props> = ({ logout, openModal, userUuid }) => {

  const handleLogoutClicked = () => {
    if (logout) {
      logout();
    }
  };

  return (
    <div className={styles.container}>
      <Dropdown
        pointing="top right"
        className="link item"
        direction="left"
        icon={<div className={styles.menuIcon}><Icon classes={['icon-avatar', styles.icon]}/></div>}
      >
        <Dropdown.Menu
          className={styles.menuOverride}
        >
          <Can
            userAccess={[PermissionTypes.VIEW_PROFILE]}
          >
            <>
              <Dropdown.Item
                className="userProfile"
                onClick={() => {
                  if (userUuid) {
                    openModal(userUuid);
                  }
                }}
              >
                <Icon classes={['icon-contact-profile-outline']}/>
                <span>{intl.get('myProfile')}</span>
              </Dropdown.Item>
              <Dropdown.Divider/>
            </>
          </Can>
          <Dropdown.Item
            className="userProfile"
            onClick={handleLogoutClicked}
          >
            <Icon classes={['icon-leave-call']}/>
            <span>{intl.get('signOut')}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const UserProfileButton = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuth,
  pure
)(UserProfileComponent);

export { UserProfileButton };
