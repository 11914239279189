import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import _get from 'lodash/get';
import { accountSelectors } from '@lifesize/nucleus';
import { Query, QueryResult } from 'react-apollo';
import accountDetailsQuery from 'queries/group/accountDetails.gql';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import * as styles from 'components/Settings/Sections.scss';
import AccountSupport from './AccountSupport';
import MergeAccounts from './RequestMergeAccounts';
import FreemiumUpgradeLink from 'components/FreemiumUpgradeLink/FreemiumUpgradeLink';
import DeleteFreemiumAccount from 'components/Settings/AccountDetailsTab/DeleteFreemiumAccount/DeleteFreemiumAccount';
import { formatUnixDateForDisplay } from 'utils/date-time-utils';
import {
  selectEcommerceLoading,
  selectEcommercePlan,
  selectEcommerceSupported,
  isEcommerceSubscriptionLoading,
  selectSubscription,
  hasSubscriptionError,
  isSubscriptionCancelBusy,
  hasSubscriptionCancelError
} from 'selectors/ecommerceSelector';
import {
  ACCOUNT_FEATURE_SSO,
  ACCOUNT_FEATURE_PSTN_NUMBERS,
  ACCOUNT_FEATURE_STREAMING,
  ACCOUNT_FEATURE_ONE_TIME_MEETING,
  ACCOUNT_FEATURE_MAX_PARTICIPANTS,
  ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS,
  ACCOUNT_FEATURE_PERMANENT_MEETING,
  PSTN_NUMBERS_OPTIONS,
  ACCOUNT_FEATURE_LYNC_ENABLED,
  ACCOUNT_FEATURE_STREAMING_VIEWERS,
  ACCOUNT_FEATURE_WHITEBOARDING
} from 'constants/accountFeatureConstants';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions/ecommerceActions';
import CancelSubscriptionConfirmModal from './CancelSubscriptionConfirmModal';
import ReactivateSubscriptionConfirmModal from './ReactivateSubscriptionConfirmModal';
import { dispatchProxyApiCall } from 'utils/nucleusProxyUtils';
import { Methods } from 'interfaces/HTTP';
import { Logger } from 'logger';
import { userCountUri, userManagementEndpoint } from 'constants/userConstants';
import {
  accountExpiresAt,
  isFreemium,
  selectShowUpgradeOptions
} from 'selectors/accountSelectors';
import SecurityAndPrivacy from './SecurityAndPrivacy/SecurityAndPrivacyPane';
import { EcommerceDetails } from './EcommerceDetails';
import { LabelData } from './LabelData';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

type UsageStats = {
  used: number | null;
  total: number | null;
};

type Props = {
  fetchData: () => Promise<{}>;
  getCalendarFeature: (groupUUID: string) => {};
  usersTotal: number;
  meetingsTotal: number;
  recordingHours: UsageStats;
  status: string | null;
  isImpersonating: boolean;
  canMergeAccounts: boolean;
  groupId: string;
  connectPlusGatewayStatus: boolean;
};

const AccountDetails = (props: Props) => {
  const {
    fetchData,
    status,
    usersTotal,
    meetingsTotal,
    recordingHours,
    canMergeAccounts,
    isImpersonating,
    connectPlusGatewayStatus
  } = props;
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);
  const [isReactivateConfirmOpen, setIsReactivateConfirmOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [accountType, setAccountType] = useState(status);
  const dispatch = useDispatch();
  const [usersCount, setUsersCount] = useState(1);
  const [usersCountLoading, setUsersCountLoading] = useState(true);
  const ecommercePlan = useSelector(selectEcommercePlan);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const isEcommerceSupported = useSelector(selectEcommerceSupported);
  const ecommerceAccountLoading = useSelector(selectEcommerceLoading);
  const accountFeatures = useSelector(accountSelectors.getAccountFeatures);
  const loadingSubscription = useSelector(isEcommerceSubscriptionLoading);
  const subscription = useSelector(selectSubscription);
  const hasSubscriptionErr = useSelector(hasSubscriptionError);
  const isCancelSubscriptionBusy = useSelector(isSubscriptionCancelBusy);
  const hasCancelSubscriptionError = useSelector(hasSubscriptionCancelError);
  const accountExpiration = useSelector(accountExpiresAt);
  const isFreemiumAccount = useSelector(isFreemium);
  const showUpgradeOptions = useSelector(selectShowUpgradeOptions);
  const hasStreaming = _get(accountFeatures, ACCOUNT_FEATURE_STREAMING);
  const totalStreamViewers = _get(accountFeatures, ACCOUNT_FEATURE_STREAMING_VIEWERS);

  useEffect(() => {
    const getUserTotals = async () => {
      await dispatchProxyApiCall(
        dispatch,
        {},
        {},
        userManagementEndpoint,
        userCountUri,
        Methods.GET,
        (res: { userCount: number }) => {
          setUsersCount(_get(res, 'body.userCount', 1));
          setUsersCountLoading(false);
        },
        (e: {}) => {
          Logger.info(`Error getting users count:  ${JSON.stringify(e)}`);
          setUsersCountLoading(false);
        }
      );
    };

    getUserTotals();
  },        []);

  useEffect(
    () => {
      if (!isCancelSubscriptionBusy && !hasCancelSubscriptionError) {
        setIsCancelConfirmOpen(false);
      }
    },
    [isCancelSubscriptionBusy, hasCancelSubscriptionError]
  );

  useEffect(() => {
    if (ecommercePlan && isEcommerceSupported) {
      setAccountType(ecommercePlan);
    }
  },        [ecommercePlan, isEcommerceSupported]);

  useEffect(
    () => {
      const handleDataFetch = async () => {
        await fetchData();
        setDataLoading(false);
      };
      handleDataFetch();
    },
    []
  );

  useEffect(
    () => {
      dispatch(actions.getEcommerceSubscription({}));
    },
    []
  );

  const now = new Date();
  const queryVariables = {
    dateStart: now.toISOString().split('T')[0],
    dateEnd: now.toISOString().split('T')[0]
  };

  const pstnLabelText = {
    [PSTN_NUMBERS_OPTIONS.ALL]: intl.get('includedWorldwide'),
    [PSTN_NUMBERS_OPTIONS.US_ONLY]: intl.get('includedUSCanada')
  };
  let pstnPlan = pstnLabelText[_get(accountFeatures, ACCOUNT_FEATURE_PSTN_NUMBERS)] || intl.get('notIncluded');

  return (
    <>
      <Query query={accountDetailsQuery} variables={{ ...queryVariables }} fetchPolicy="network-only">
        {({ loading, data }: QueryResult<object>) => {
          return loading || dataLoading || ecommerceAccountLoading || usersCountLoading ? (
            <LoadingIndicator />
          ) : (
            <section className={styles.container} data-test="settingsSection.plan">
              <article>
                <div className={styles.flexColumn}>
                  <LabelData label={intl.get('accountType')} isBold={true}>
                    {accountType}
                  </LabelData>
                  {accountExpiration &&
                    <dl className={styles.expireLabel}>
                      <dt>
                        {intl.get('currentPlanExpiresOn')}
                      </dt>
                      <dd>
                        {formatUnixDateForDisplay(accountExpiration)}
                      </dd>
                    </dl>
                  }
                  {showUpgradeOptions && <FreemiumUpgradeLink />}
                </div>
              </article>
              {ecommercePlan && !loadingSubscription && !hasSubscriptionErr &&
                <EcommerceDetails setIsCancelConfirmOpen={setIsCancelConfirmOpen} setIsReactivateConfirmOpen={setIsReactivateConfirmOpen} />
              }
              <article>
                <h6 className={classnames(styles.title, styles.headerRow)} data-test="sectionTitle">
                  {intl.get('features')}
                  <div className={styles.legend}>{intl.get('activeOfTotal')}</div>
                </h6>
                <div className={styles.flexRow}>
                  <LabelData
                    label={intl.get('users')}
                    used={usersCount}
                    total={usersTotal}
                    unlimited={true}
                  />
                  <LabelData
                    label={intl.get('permanentMeetings')}
                    used={_get(data, 'myGroup.settings.meetings.currentMeetingCount', 0)}
                    total={_get(accountFeatures, ACCOUNT_FEATURE_PERMANENT_MEETING) ? meetingsTotal : null}
                    unlimited={true}
                  />
                  <LabelData
                    label={intl.get('recordingHours')}
                    {...recordingHours}
                    unlimited={true}
                  />
                </div>
                <div className={styles.flexRow}>
                  <LabelData label={intl.get('maxParticipantsPerMeeting')}>
                    {_get(accountFeatures, ACCOUNT_FEATURE_MAX_PARTICIPANTS)}
                  </LabelData>
                  <LabelData label={intl.get('oneTimeMeetings')}>
                    {_get(accountFeatures, ACCOUNT_FEATURE_ONE_TIME_MEETING) ? intl.get('unlimited') : intl.get('notIncluded')}
                  </LabelData>
                  <LabelData label={intl.get('liveStreaming')}>
                    {hasStreaming ? intl.get('included') : intl.get('notIncluded')}
                  </LabelData>
                </div>
                <div className={styles.flexRow}>
                  <LabelData label={intl.get('audioCalling')}>
                    {pstnPlan}
                  </LabelData>
                  <LabelData label={intl.get('SsoPageTitle')}>
                    {_get(accountFeatures, ACCOUNT_FEATURE_SSO) ? intl.get('included') : intl.get('notIncluded')}
                  </LabelData>
                  <LabelData label={intl.get('liveCallStats')}>
                    {_get(accountFeatures, ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS) ? intl.get('included') : intl.get('notIncluded')}
                  </LabelData>
                </div>
                <div className={styles.flexRow}>
                  <LabelData
                    label={intl.get('skypeForBusinessInteroperability')}
                  >
                    {_get(accountFeatures, ACCOUNT_FEATURE_LYNC_ENABLED) ? intl.get('included') : intl.get('notIncluded')}
                  </LabelData>
                  <LabelData label={intl.get('whiteboard')}>
                    {_get(accountFeatures, ACCOUNT_FEATURE_WHITEBOARDING) ? intl.get('included') : intl.get('notIncluded')}
                  </LabelData>
                  {hasStreaming &&
                    <LabelData
                      label={intl.get('maxStreamingViewers')}
                      total={totalStreamViewers}
                      unlimited={true}
                    />
                  }
                </div>
                <div className={styles.flexRow}>
                  <LabelData label={'Connect Plus Gateway'}>
                    {connectPlusGatewayStatus ? intl.get('included') : intl.get('notIncluded')}
                  </LabelData>
                </div>
              </article>
              {isFreemiumAccount && canEditSettings && <DeleteFreemiumAccount />}
            </section>
          );
        }}
      </Query>
      <SecurityAndPrivacy />
      {canMergeAccounts && <MergeAccounts />}
      {isCancelConfirmOpen && (
        <CancelSubscriptionConfirmModal
          onClose={() => {
            setIsCancelConfirmOpen(false);
          }}
        />
      )}
      {isReactivateConfirmOpen && (
        <ReactivateSubscriptionConfirmModal
          subscription={subscription}
          onClose={() => {
            setIsReactivateConfirmOpen(false);
          }}
        />
      )}
      {!isImpersonating && <AccountSupport />}
    </>
  );
};

export default AccountDetails;
