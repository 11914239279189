import classnames from 'classnames';
import Icon from 'components/Common/Icon';
import { useDispatch, useSelector } from 'react-redux';
import * as styles from 'components/Settings/Sections.scss';
import { FieldArray, Form, Formik } from 'formik';
import { PhoneNumber } from 'interfaces/PhoneNumber';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Dropdown } from 'semantic-ui-react';
import { getCountryName } from 'utils/i18nUtils';
import { pstnNumbersActions, pstnNumbersSelectors } from '@lifesize/nucleus';
import { MAX_PHONE_NUMBERS_ALLOWED } from 'constants/meetingConstants';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const defaultCountryCode = 'US';
const defaultOptionId = 'default-none';

interface DropdownOption {
  key: string;
  sort?: string;
  text: JSX.Element | string;
  value: string;
}

interface DropdownProps {
  currentValue: string;
  firstOption?: DropdownOption;
  disabled?: boolean;
  index: number;
  label: string;
  name: string;
  onChange: Function;
  onRemove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  options: PhoneNumber[];
}

const mapOptionsToDropdown = (options: PhoneNumber[], firstOption?: DropdownOption) => {
  const mappedOptions = options.map(opt => {
    const countryName = getCountryName(opt.countryCode);
    return {
      key: opt.id,
      sort: `${countryName}${opt.phoneNumber}`,
      text: (
        <div className={styles.dropdownOptionText}>
          <span className={styles.country}>{countryName}</span>
          <span className={styles.phoneNumber}>&nbsp;{opt.phoneNumber}</span>
        </div>
      ),
      value: opt.id
    };
  });
  if (firstOption) {
    return [firstOption, ...sortBy(mappedOptions, ['sort'])];
  }
  return sortBy(mappedOptions, ['sort']);
};

const SelectValueInput = ({ currentValue, firstOption, index, label, name, onChange, onRemove, options, disabled }: DropdownProps) => {
  return (
    <div className={classnames(styles.inputGroup, styles.compact)} data-test="inputGroup">
      <label>{label}</label>
      <Dropdown
        className={styles.input}
        closeOnBlur={true}
        fluid={false}
        name={name}
        disabled={disabled}
        onChange={(e, { value }) => onChange({ target: { name, value }})}
        options={mapOptionsToDropdown(options, firstOption)}
        selection={true}
        value={currentValue}
        {...{ ['data-test']: `settingsSectionDropDown.${index === 0 ? 'main' : 'opt'}` }}
      />
      {(index !== 0) ? (
        <button
          className={classnames(styles.buttonIcon, styles.remove)}
          data-test={`sectionBtnRemovePhoneNum.${index}`}
          name={`btnRemovePhoneNumber${index}`}
          onClick={onRemove}
          role="button"
          type="button"
          disabled={disabled}
        >
          <Icon classes={['icon-cancel', disabled ? styles.disabled : '']}/>
        </button>
      ) : null}
    </div>
  );
};

const defaultOptionObject = {
  key: defaultOptionId,
  text: (
    <div className={styles.dropdownOptionText}>
      <span className={styles.country}>{intl.get('inputDefaultValueNone')}</span>
    </div>
  ),
  value: defaultOptionId
};

const getDefaultOption = (options: PhoneNumber[]) => {
  const defaultOption = options.find(opt => opt.countryCode === defaultCountryCode) || { id: defaultOptionId };
  return defaultOption.id;
};

const PhoneNumbers = () => {
  const dispatch = useDispatch();
  const phoneNumbers = useSelector(pstnNumbersSelectors.pstnNumbersWithCountryName);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const [allPstnNumbers, setAllPstnNumbers] = useState([]);
  // tslint:disable-next-line:no-any
  const formik = React.createRef<any>();

  useEffect(
    () => {
      const asyncFetchData = async () => {
        const newPstnNumbers = await dispatch(pstnNumbersActions.getAll());
        setAllPstnNumbers(newPstnNumbers);
      };
      asyncFetchData();
    },
    []
  );

  useEffect(
    () => {
      const initialValues = get(formik.current.initialValues, 'phoneNumberSelections') || [defaultOptionId];
      // if there is no mainNumber selected, auto-select the first US number after the first render so the form is 'dirty'
      if (initialValues[0] === defaultOptionId) {
        formik.current.setValues({
          phoneNumberSelections: [getDefaultOption(allPstnNumbers)]
        });
      }
    },
    []
  );

  const onSubmit = async (formData: object, callback: Function) => {
    const submittedIds = (get(formData, 'phoneNumberSelections') || []) as string[];
    const ids = submittedIds.filter(id => id !== defaultOptionId);
    await dispatch(pstnNumbersActions.updatePreferredPstnNumbers(ids));
    callback();
  };

  const phoneNumberOptions = allPstnNumbers || [];
  const phoneNumberSelections = (phoneNumbers || [{ id: defaultOptionId }]).map((o: PhoneNumber) => o.id);
  return (
    <section className={styles.container} data-test="settingsSection.phoneNum">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitlePhoneNumbers')}</h6>
        <p className={styles.description}>{intl.get('settingsSectionDescriptionPhoneNumbers1')}</p>
        <p className={styles.description}>{intl.get('settingsSectionDescriptionPhoneNumbers2')}</p>
        <Formik
          ref={formik}
          enableReinitialize={true}
          initialValues={{ phoneNumberSelections }}
          onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => setSubmitting(false))}
          render={({ dirty, handleChange, isSubmitting, values }) => (
            <Form>
              <div className={styles.formSection}>
                <FieldArray
                  name="phoneNumberSelections"
                  render={arrayHelpers => (
                    <div className={styles.phoneFieldArray}>
                      {values.phoneNumberSelections.map((numberId: string, index: number) => (
                        <SelectValueInput
                          currentValue={numberId}
                          firstOption={(index === 0) ? undefined : defaultOptionObject}
                          index={index}
                          key={index}
                          label={(index === 0) ? intl.get('inputLabelMainNumber') : intl.get('inputLabelOptionalNumber')}
                          name={`phoneNumberSelections.${index}`}
                          onChange={handleChange}
                          disabled={!canEditSettings}
                          onRemove={(index === 0) ? () => null : () => arrayHelpers.remove(index)}
                          options={phoneNumberOptions}
                        />
                      ))}
                      {values.phoneNumberSelections.length < MAX_PHONE_NUMBERS_ALLOWED && [
                        <div className={classnames(styles.inputGroup, styles.compact)} key="addButtonDivider">
                          <label/>
                          <hr/>
                        </div>,
                        <div className={classnames(styles.inputGroup, styles.compact)} key="addButton">
                          <label/>
                          <button
                            className={styles.buttonIcon}
                            data-test="sectionBtnAddPhoneNum"
                            name="btnAddPhoneNumber"
                            onClick={() => {
                              if (!canEditSettings) { return; }
                              arrayHelpers.push(defaultOptionId);
                            }}
                            role="button"
                            type="button"
                            disabled={!canEditSettings}
                          >
                            <Icon classes={['icon-zoom-in']}/>
                            <span>Add Number (Optional)</span>
                          </button>
                        </div>
                      ]}
                    </div>
                  )}
                />
                <div className={styles.buttonContainer}>
                  <Button
                    data-test="sectionBtnSubmit"
                    disabled={isSubmitting || !dirty || !canEditSettings}
                    name="btnSubmitPhoneNumbers"
                    role="button"
                    type="submit"
                  >
                    {intl.get('settingsButtonLabelUpdate')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
      </article>
    </section>
  );
};

export default PhoneNumbers;
