import actions, { RecordingsActionType } from 'actions/recordingsActions';
import { Reducer } from 'redux';
import { RecordingsState, initialRecordingsState } from 'state/Recordings';

const recordingsReducer: Reducer<RecordingsState> =
  (state = initialRecordingsState, action) => {
    const { endDate, startDate } = action?.payload || {};
    switch (action.type) {
      case RecordingsActionType.RECORDINGS_LAYOUT_CHANGE:
        return {
          ...state,
          isGridLayout: !!action.payload
        };
      case RecordingsActionType.RECORDINGS_CLEAR_SEARCH_RESULTS:
        return {
          ...state,
          searchResults: [],
        };
      case RecordingsActionType.RECORDINGS_PENDING_RESULTS:
        return {
          ...state,
          pending: true
        };
      case 'VIDEO_GET_CLOUD_MEETINGS_SUCCESS':
        return {
          ...state,
          searchResults: action.payload,
          pending: false,
        };
      case RecordingsActionType.RECORDINGS_ADD_PROCESSING_UPLOAD:
        return {
          ...state,
          uploadsStatus: [...state.uploadsStatus, action.payload],
          updating: true,
        };
      case RecordingsActionType.RECORDINGS_SET_UPLOADS_STATUS:
        return {
          ...state,
          uploadsStatus: action.payload
        };
      case RecordingsActionType.RECORDINGS_STOP_UPLOAD_UPDATES:
        return {
          ...state,
          updating: false,
        };
      case RecordingsActionType.RECORDINGS_START_UPLOAD_UPDATES:
        return {
          ...state,
          updating: true,
        };
      case RecordingsActionType.SET_DATE_RANGE_MOMENT:
        return {
          ...state,
          startDate: startDate,
          endDate: endDate,
        };
      case actions.getVcCookie.pending.type:
        return {
          ...state,
          isFetchingVcCookie: true,
          isVcCookieSet: false,
          failedToFetchVcCookie: false
        };
      case actions.getVcCookie.fulfilled.type:
        if (action.payload) {
          return {
            ...state,
            isFetchingVcCookie: false,
            isVcCookieSet: true,
          };
        } else {
          return {
            ...state,
            isFetchingVcCookie: false
          };
        }
      case actions.getVcCookie.rejected.type:
        return {
          ...state,
          isFetchingVcCookie: false,
          failedToFetchVcCookie: true
        };
      default:
        return state;
    }
  };

export default recordingsReducer;
