import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Logger } from 'logger';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './AlexaDisconnectModal.scss';

interface Props {
  closeModal: () => void;
  disconnectAlexa: () => void;
}

export const AlexaDisconnectModal = (props: Props) => {
  const [isBusy, setBusy] = useState(false);
  const { closeModal, disconnectAlexa } = props;

  return (
    <Modal
      className={styles.container}
      onSave={
        async () => {
          setBusy(true);
          try {
            await disconnectAlexa();
            closeModal();
          } catch (e) {
            setBusy(false);
            Logger.info(`Error disconnecting Alexa device:  ${e.toString()}`);
          }
        }
      }
      closeModal={closeModal}
      saveText={intl.get('disconnect')}
      closeText={intl.get('cancel')}
      isSaveButtonBusy={isBusy}
      isSaveButtonDisabled={isBusy}
      hideSaveButton={false}
    >
      <div>
        <div className={styles.title}>{intl.get('alexaDisconnectTitle')}</div>
        <div className={styles.body}>{intl.get('alexaDisconnectBody')}</div>
      </div>
    </Modal>
  );
};

export default AlexaDisconnectModal;
