import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { setByDevice } from 'actions/upgradeManagementActions';
import { useMount } from 'hooks/useMount';
import { AnyAction } from 'redux';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import { DeviceChannelType } from 'constants/roomSystemConstants';
import * as styles from './ReleaseCycleConfirmModal.scss';
import * as modalStyles from 'components/Modal/ModalComponent/ModalComponent.scss';
import { RoomSystem } from 'typings/types';
import { isSuccessfulAction } from 'utils/actionUtils';
import { Logger } from 'logger';
import { channelToDisplayText, getUpgradeManagementPlatform } from 'utils/roomSystemUtils';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  roomSystem: RoomSystem;
  channel: DeviceChannelType;
  isLocked: boolean;
};

export default function CancelSubscriptionConfirmModal(props: Props) {
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const isMounted = useMount();
  const { channel, roomSystem, onClose, onSuccess, isLocked } = props;
  return (
    <Modal
      className={styles.containerLarge}
      closeModal={onClose}
      onSave={async () => {
        // update release cycle
        setIsBusy(true);
        try {
          // @ts-ignore
          const res: AnyAction = await dispatch(setByDevice({
            platform: getUpgradeManagementPlatform(roomSystem.platform),
            serialNumber: roomSystem.serialNumber,
            channel: channel.channel
          }));
          if (isSuccessfulAction(res, setByDevice.fulfilled.type)) {
            onSuccess();
          } else {
            Logger.info(`ERROR: unable to update device channel. service response: ${JSON.stringify(res)}`);
            if (isMounted.current) {
              setError(intl.get('upgradeManagementUpdateError'));
              setIsBusy(false);
            }
          }
        } catch (e) {
          Logger.info(`ERROR: unable to update device channel: ${JSON.stringify(e)}`);
          if (isMounted.current) {
            setError(intl.get('upgradeManagementUpdateError'));
            setIsBusy(false);
          }
        }
      }}
      isSaveButtonBusy={isBusy}
      saveText={intl.get('update')}
      titleText={intl.get('releaseCycleChangeConfirmation')}
      errorMessage={error}
      closeText={intl.get('cancel')}
    >
      <div className={modalStyles.contextContainer}>
        {isLocked && `${intl.get('upgradeManagementUnlockWarning')} `}
        {intl.get('upgradeManagementWillBeScheduled', {
          version: channel.version,
          channel: channelToDisplayText(channel.channel)
        })}
      </div>
    </Modal>
  );
}
