import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { actions as ModalActions } from 'actions/modalActions';
import { actions as SelectionActions } from 'actions/selectionActions';
import { Actions as ActionTypes } from 'constants/deviceCustomizationConstants';
import { ModalTypes } from 'constants/modalConstants';
import { getClient } from 'nucleus-apollo-client';
import { RoomSystem } from 'typings/types';
import getRoomSystemQuery from 'queries/roomSystems/getRoomSystemQuery.gql';
import roomSystemFragment from 'queries/roomSystems/roomSystemFragment.gql';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { generateURLForPhoneHD, isConnectEnabled, isConnectSupported } from 'utils/roomSystemUtils';
import { selectAlexaDevices } from 'selectors/alexaIntegrationSelector';
import { DeviceRegistration } from 'constants/alexaIntegrationConstants';
import { setDeviceCustomizationUrl } from 'thunks/deviceCustomizationThunks';
import { selectSelectionState } from 'selectors/selectionSelectors';

const deviceCustomizationMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return async (action: PayloadAction<ActionTypes.OPEN_DEVICE_CUSTOMIZATION, { serialNumber: string }>) => {
        if (ActionTypes.OPEN_DEVICE_CUSTOMIZATION !== action.type) {
          return next(action);
        }
        const state = store.getState();
        const selectionState = selectSelectionState(state);
        let selectionArray = [];
        const devices: RoomSystem[] = [];

        if (action.payload && action.payload.serialNumber) {
          selectionArray.push(action.payload.serialNumber);
        } else {
          selectionArray = selectionState.selection;
        }

        const client = await getClient(store.dispatch);
        const deviceQueries: {}[] = [];

        selectionArray.forEach((selection) => {
          const getSelectionInfo = async () => {
            let data;

            try {
              data = client.readFragment({
                id: selection,
                fragment: roomSystemFragment,
              }) as RoomSystem;
            } catch {
              // Data is not in the cache
              const response = await client.query({
                query: getRoomSystemQuery,
                fetchPolicy: 'network-only',
                variables: { serialNumber: selection },
              });

              data = response?.data?.getRoomSystem;
            }

            if (data) {
              const alexaIntegrationDeviceState = selectAlexaDevices(state)[data.serialNumber];
              data.alexaIntegration = _get(alexaIntegrationDeviceState, 'state') === DeviceRegistration.CONNECTED;
              data.lifesizeConnect = isConnectSupported(data) && isConnectEnabled(data);
              devices.push(data);
            }
          };
          deviceQueries.push(getSelectionInfo());
        });

        await Promise.all(deviceQueries);
        const dialStringArray = devices.map((d) => d.dialstring || '');

        const deviceName = (_isEmpty(devices) || devices.length > 1) ? 'Lifesize Phone HD' : devices[0].displayName || '';
        const alexaIntegration = _isEmpty(devices) ? false : devices.every((device) => device.alexaIntegration);
        const lifesizeConnect = _isEmpty(devices) ? false : devices.every((device) => device.lifesizeConnect);

        const features = {
          thirdPartyButtons: true,
          alexaIntegration,
          lifesizeConnect
        };

        const url = generateURLForPhoneHD(dialStringArray, deviceName, features);
        if (url.length) {
          next(setDeviceCustomizationUrl(url) as unknown as AnyAction);
          next(ModalActions.openModal({ modalType: ModalTypes.DEVICE_CUSTOMIZATION }));
          next(SelectionActions.resetSelection());
          return null;
        }
        return null;
      };
    };

export default (deviceCustomizationMiddleware as Middleware);
