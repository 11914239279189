import React from 'react';
import classnames from 'classnames';
import { Formik, Field, Form } from 'formik';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import get from 'lodash/get';
import _memoize from 'lodash/memoize';
import * as styles from 'components/Settings/Sections.scss';
import * as formStyles from 'components/Forms.scss';
import intl from 'react-intl-universal';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export interface Props {
  alertNotificationsEmail: string;
  alertNotificationsEnabled: boolean;
  updateSection: Function;
}

const iconAlertsSchema = _memoize(() => {
  return Yup.object().shape({
    alertNotificationsEmail: Yup.string()
      .trim()
      .email(intl.get('emailAddressRequired'))
      .required(intl.get('emailAddressRequired'))
  });
});

const IconAlerts = (props: Props) => {
  const { updateSection, alertNotificationsEmail, alertNotificationsEnabled } = props;
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const onSubmit = (formData: object, callback: Function) => {
    if (!canEditSettings) { return; }
    updateSection(get(formData, 'alertNotificationsEmail'), get(formData, 'alertNotificationsEnabled'), callback);
  };

  return (
    <section className={styles.container} data-test="settingsSection.iconAlerts">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('iconAlerts')}</h6>
        <p className={styles.description}>{intl.get('iconAlertsHelper')}</p>
        <Formik
          enableReinitialize={true}
          initialValues={{
            alertNotificationsEmail,
            alertNotificationsEnabled
          }}
          onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => setSubmitting(false))}
          validationSchema={iconAlertsSchema}
          render={({ dirty, errors, handleChange, isSubmitting, touched, values }) => (
            <Form>
              <div className={styles.formSection}>
                <Field
                  component={ToggleButton}
                  id="alertNotificationsEnabled"
                  label={intl.get('enableAlerts')}
                  name="alertNotificationsEnabled"
                  value={values.alertNotificationsEnabled}
                  data-test="settingsSectionToggle.alerts"
                  disabled={!canEditSettings}
                />
              </div>
              <div className={`${styles.formSection} ${styles.horizontal}`}>
                <div className={styles.inputContainer}>
                  <Field
                    className={styles.inputEmail}
                    name="alertNotificationsEmail"
                    placeholder={intl.get('enterEmail')}
                    title={intl.get('enterEmail')}
                    type="text"
                    data-test="settingsSectionInput.email"
                    disabled={!canEditSettings}
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    data-test="sectionBtnSubmit"
                    disabled={!!errors.alertNotificationsEmail || isSubmitting || !dirty || !canEditSettings}
                    name="btnSubmitIconAlertSettings"
                    role="button"
                    type="submit"
                  >
                    {intl.get('settingsButtonLabelUpdate')}
                  </Button>
                </div>
              </div>
              {(touched && errors && touched.alertNotificationsEmail && errors.alertNotificationsEmail) && (
                <div className={formStyles.messageContainer}>
                  <div
                    title={errors.alertNotificationsEmail}
                    className={classnames(formStyles.error, formStyles.tightError)}
                  >
                    {errors.alertNotificationsEmail}
                  </div>
                </div>
              )}
            </Form>
          )}
        />
      </article>
    </section>
  );
};

export default IconAlerts;
