import { groupSettingsActions, groupSettingsSelectors, nucleusConstants, ssoSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectUserProfile } from 'selectors/userProfileSelector';
import { Global } from 'state/Global';
import { changeLocale } from 'actions/languageActions';
import { actions } from 'actions/modalActions';
import ProfileModal from 'components/Modals/ProfileModal/ProfileModal';
import { SSO } from 'interfaces/SSO';
import { Logger } from '../../logger';
import { selectLocale } from 'selectors/languageSelectors';
import { selectResetPasswordState } from 'selectors/resetPasswordSelector';
import { withAuth } from 'utils/hocs/withAuth';
import { actions as resetPasswordActions } from '../../actions/resetPasswordActions';

const mapStateToProps = (state: Global) => {
  const ssoState: SSO = ssoSelectors.ssoStatus(state);
  const userProfile = selectUserProfile(state);
  const resetPasswordState = selectResetPasswordState(state);
  const locale = selectLocale(state);
  const groupSettings = groupSettingsSelectors.groupSettings(state) || {};

  if (ssoState.error) {
    Logger.info('Error getting SSO info ' + ssoState.error.toString());
  }

  return {
    isSSO: ssoState.error ? false : (ssoState.loginSupportType === nucleusConstants.LOGIN_SUPPORT_TYPE_SSO),
    userInfo: userProfile,
    localeCode: locale || 'en',
    isResettingPassword: resetPasswordState.isResettingPassword,
    groupDomains: _get(groupSettings, 'domains', ''),
    domainsFilter: groupSettings.domainsFilter || '', // the value may be null
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  updateLocale: async (localeCode: string) => {
    try {
      dispatch(changeLocale(localeCode || 'en'));
    } catch (error) {
      const errorDescription = _get(error, 'errorDescription', JSON.stringify(error));
      Logger.info(`Profile Modal Change Locale Error:  ${errorDescription}`);
    }
  },
  fetchGroupSettings: () => {
    // Need to get domain filters for email validation
    dispatch(groupSettingsActions.getGroupSettings());
  },
  resetPasswordHandler: () => dispatch(resetPasswordActions.resetMyPassword())
});

export const ProfileModalContainer = compose(
  withAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(ProfileModal);
