import React from 'react';
import intl from 'react-intl-universal';
import { connect, useSelector } from 'react-redux';
import { RowInfo } from 'react-table';
import phoneHdActions from 'actions/deviceCustomizationActions';
import { actions as ModalActions } from 'actions/modalActions';
import ClickableCell from 'components/ClickableCell/ClickableCell';
import { ModalTypes } from 'constants/modalConstants';
import { MUSTANGHD, TABLET, PairingStatus } from 'constants/roomSystemConstants';
import _get from 'lodash/get';
import { isPhoneHD } from 'utils/roomSystemUtils';
import Controller from './Controller';
import { RoomSystem } from 'typings/types';
import { remotingConstants } from '@lifesize/clients.remoting';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  row: RowInfo;
  openModal: (type: ModalTypes, payload: object) => void;
  openHdPhonePreferences: (payload: object) => void;
}

const getControllers = (device: RoomSystem) => {
  const {phoneModelNumber, systemName, linkedDevices, pairingStatus} = device;
  let controllers = [];
  if (pairingStatus === PairingStatus.DELETED) {
    return [];
  }
  // if phone hd
  if (isPhoneHD(phoneModelNumber)) {
    controllers.push(new Controller(systemName, MUSTANGHD, phoneModelNumber));
  }
  // add ALL linked devices
  if (linkedDevices && linkedDevices.records) {
    linkedDevices.records.forEach((linkedDevice) => {
      if (_get(linkedDevice, 'type') === remotingConstants.pairing.linkedDeviceTypes.presenter) { return; }
      controllers.push(new Controller(systemName, TABLET, phoneModelNumber));
    });
  }
  return controllers;
};

let modalBehavior = ((modal, device, openHdPhonePreferences, openModal) => {
  switch (modal) {
    case TABLET:
      openModal(ModalTypes.TABLET_INFO, device);
      break;
    case MUSTANGHD:
      openHdPhonePreferences({'serialNumber': device.serialNumber});
      break;
    default:
    // Do nothing.
  }
});

const ControllerCell = ({row, openHdPhonePreferences, openModal}: Props) => {
  const device = row.original;
  const controllers = getControllers(device);
  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  let subCells = [];
  controllers.map((controller, index) => {
    const { id, label, isClickable, modal } = controller;
    subCells.push(
      canEditRoomSystems ? (
        <ClickableCell
          title={intl.get('controller-settings')}
          id={id}
          key={index}
          displayText={label}
          isClickable={isClickable}
          clickHandler={(evt: Event) => {
            evt.stopPropagation();
            modalBehavior(modal, device, openHdPhonePreferences, openModal);
          }}
        />
      ) : (
        <div
          id={id}
          key={index}
        >
          {label}
        </div>
      )
    );
  });
  return subCells;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Function) => ({
  openModal: (type: ModalTypes, payload: object) => {
    dispatch(ModalActions.openModal({modalType: type, payload: payload}));
  },
  openHdPhonePreferences: (payload: object) => {
    dispatch(phoneHdActions.openDeviceCustomizationModal(payload));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ControllerCell);
