import { Form, Formik } from 'formik';
import _get from 'lodash/get';
import React, { PureComponent } from 'react';
import intl from 'react-intl-universal';
import MeetingRecordingShareSettings from 'components/MeetingRecordingShareSettings/MeetingRecordingShareSettingsComponent';
import {
  MeetingShareProperties,
  VideoShareViewer
} from '../../../interfaces/VideoShareProperties';
import Icon from '../../Common/Icon';
import CopyToClipboard from '../../CopyToClipboardButton/CopyToClipboardButton';
import { ModalFooter } from '../../Modal/ModalComponent/ModalFooter';
import { Tooltip } from '../../tooltip/Tooltip';
import * as styles from './MeetingShareRecordingModal.scss';
import {
  getMeetingShareSetting,
  getMeetingAttributes
} from '../../../utils/recordingsUtils';

interface Props {
  closeModal: () => void;
  data: MeetingShareProperties | null;
  isBusy: boolean;
  onSubmit: (meetingShareProps: object) => void;
  meetingId: string;
  refreshMeetingUrls: () => void;
}

interface FormValues {
  meetingSelectedShare: {
    id: string;
    name: string;
  };
  meetingViewers: VideoShareViewer[];
}

export class ShareRecordingForm extends PureComponent<Props> {
  render(): React.ReactNode {

    const {
      meetingId,
      data,
      isBusy,
      onSubmit,
      closeModal,
      refreshMeetingUrls
    } = this.props;
    const protocolDomain = process.env.REACT_APP_MAIN_URL;
    const groupUrl = `${protocolDomain}/recordings/recordingsFeed?meeting=${meetingId}&msharetoken=${_get(data, 'share_urls.noAuth', '')}`;

    return (
      <Formik
        initialValues={{
          // @ts-ignore
          meetingSelectedShare: getMeetingShareSetting(data || {}),
          meetingViewers: _get(data, 'viewers', [])
        }}
        onSubmit={(values: FormValues) => {
          // Calls handleSubmit passed in from wrapping component
          // NOTE: apply_to_all will change the share settings for all recordings associated to this meeting
          const currentShareProps = data || {};
          const meetingProperties = getMeetingAttributes(values.meetingSelectedShare.id);
          const meetingRecordingShareProps = {
            ...currentShareProps,
            ...{
              selectedShare: values.meetingSelectedShare,
              viewers: values.meetingViewers
            },
            ...meetingProperties
          };
          onSubmit(meetingRecordingShareProps);
        }}
        render={({ dirty, setFieldValue, values }) => (
          <Form>
            <div className={styles.container}>

              <div className={styles.horizontalDivider}/>

              <div className={styles.topRowContainer}>
                <MeetingRecordingShareSettings
                  disabled={false}
                  values={values}
                  setFieldValue={setFieldValue}
                  shareField="meetingSelectedShare"
                  viewersField="meetingViewers"
                />
              </div>

              <div className={styles.horizontalDashedDivider}/>

              <div className={styles.columnContainer}>
                <div>{intl.get('shareableLinkForGroup')}</div>
                <div className={styles.inviteLinkContainer}>
                  <div className={styles.inviteLink}>
                    {isBusy ? '....' : groupUrl}
                  </div>
                  <div className={styles.buttonContainer}>
                    <Tooltip text={intl.get('copyLink')}>
                      <CopyToClipboard text={groupUrl || ''} disabled={isBusy} />
                    </Tooltip>
                    <Tooltip text={intl.get('refreshLink')}>
                      <button
                        onClick={() => {
                          // how to handle refresh
                          refreshMeetingUrls();
                        }}
                        className={styles.button}
                        name="btnRefresh"
                        role="button"
                        type="button"
                      >
                        <Icon classes={['icon-loader']}/>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>

            <ModalFooter
              useSaveAsFormSubmit={true}
              closeModal={closeModal}
              isSaveButtonBusy={isBusy}
              isSaveButtonDisabled={isBusy || !dirty}
            />
          </Form>
        )}
      />
    );
  }

}

export default ShareRecordingForm;
