export enum Actions {
  REBOOT_DEVICE_SUCCESS = 'REBOOT_DEVICE_SUCCESS',
  REBOOT_DEVICE_FAILURE = 'REBOOT_DEVICE_FAILURE',
  REBOOT_DEVICE = 'REBOOT_DEVICE',
  RESET_DEVICE_SUCCESS = 'RESET_DEVICE_SUCCESS',
  RESET_DEVICE_FAILURE = 'RESET_DEVICE_FAILURE',
  RESET_DEVICE = 'RESET_DEVICE',
  UPLOAD_CORONER_STATUS = 'UPLOAD_CORONER_STATUS',
}

export enum DeviceState {
  failed = 'failed',
  success = 'success',
  pending = 'pending'
}

export enum DeviceCoronerState {
   IDLE = 'IDLE',
   COMPLETE = 'COMPLETE',
   ERROR = 'ERROR',
   COLLECTING = 'COLLECTING',
   SENT = 'SENT',
   UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
   UPLOADING = 'UPLOADING'
}

export const deviceManagementTopic = 'ls.device';

export const deviceManagementEndpoint = 'lifesize.deviceInfo';
export const deviceManagementRebootUri = 'device/reboot';
export const deviceManagementResetUri = 'device/reset';
export const deviceManagementResetPasswordUri = 'device/passwordReset';
export const deviceManagementUploadUri = 'device/uploadCoroner';
export const deviceManagementConnectUri = 'pairing/deviceSettings';

export const deviceHealthEndpoint = 'lifesize.deviceHealth';
export const deviceHealthUploadUri = 'health/triggerUpload';
export const deviceHealthGetUploadStatusUri = 'health/getUploadStatus';
export const deviceHealthSetUploadStatusUri = 'health/setUploadStatus';
