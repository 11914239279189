
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SSOSettingsFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"GroupSSOSettings"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"enableSso"},"name":{"kind":"Name","value":"SSOEnabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"relayState"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"entityId"},"name":{"kind":"Name","value":"identityProviderIssuerId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"loginUrl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ssoX509cert"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"testURL"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"samlAttributeMapping"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":227}};
    doc.loc.source = {"body":"fragment SSOSettingsFragment on GroupSSOSettings {\n  enableSso: SSOEnabled\n  relayState\n  entityId: identityProviderIssuerId\n  loginUrl\n  ssoX509cert\n  testURL\n  samlAttributeMapping {\n    firstName\n    lastName\n    email\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
