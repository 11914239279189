import { createAction } from '@lifesize/typescript-react-redux-utils';
import { Actions } from '../constants/deviceCustomizationConstants';

const actions = {
  openDeviceCustomizationModal: (payload?: object) =>
    createAction(Actions.OPEN_DEVICE_CUSTOMIZATION, payload),
  setDeviceCustomizationUrl: (payload: {url: string}) => 
    createAction(Actions.SET_DEVICE_CUSTOMIZATION_URL, payload),
};

export default actions;
