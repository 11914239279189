import classnames from 'classnames';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import ParticipantsTab from './ParticipantsTab/ParticipantsTab';
import { DWHCallHistoryPaginatedType } from '../../../interfaces/Dashboard';
import _compact from 'lodash/compact';
import _isNumber from 'lodash/isNumber';
import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Tab, TabProps } from 'semantic-ui-react';
import * as styles from './ConferenceDetails.scss';

type Props = RouteComponentProps<void> & {
  location: {
    conference: DWHCallHistoryPaginatedType;
  };
};

export const ConferenceDetails = (props: Props) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  if (!_get(props, 'location.conference')) {
    // location.conference is passed in from the CallHistory drilldown table
    // If it is not provided, then redirect the viewer to the dashboard
    props.history.push('/');
  }

  const conference = _get(props, 'location.conference', {});

  const panes = _compact([
    {
      menuItem: {
        key: '/conferenceDetails/participants',
        content: intl.get('participants')
      },
      render: () => {
        return (
          <Tab.Pane attached={false}>
            <ParticipantsTab participants={_get(conference, 'participants', [])}/>
          </Tab.Pane>
        );
      }
    }
  ]);

  function getBreadcrumbs() {
    return _compact([
      intl.get('dashboard'),
      intl.get('callHistory'),
      conference.conferenceName
    ]).join(' > ');
  }

  function handleTabChange(event: React.MouseEvent<HTMLDivElement>, tabData: TabProps) {
    if (_isNumber(tabData.activeIndex)) {
      setSelectedTab(tabData.activeIndex);
    }
  }

  return (
    <div className={styles.container}>
      <ListViewLayoutHeader title={conference.conferenceName} breadcrumbs={getBreadcrumbs()} />
      <div className={classnames(styles.tabsContainer, 'tabset')}>
        <Tab
          activeIndex={selectedTab}
          menu={{secondary: true, pointing: true}}
          panes={panes}
          onTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default withRouter(ConferenceDetails);
