import { connect } from 'react-redux';
import ModalConductor from '../../components/ModalConductor/ModalConductor';
import { selectModalType } from '../../selectors/modalSelectors';
import { selectPayload } from '../../selectors/modalSelector';
import { Global } from 'state/Global';

function mapStateToProps(state: Global) {
  const modalType = selectModalType(state);
  const modalIsOpen = !!(modalType);
  const modalProps = selectPayload(state);
  return { modalType, modalIsOpen, modalProps };
}

export default connect(mapStateToProps)(ModalConductor);
