import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import intl from 'react-intl-universal';
import * as formStyles from 'components/Modals/ModalForms.scss';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import createGroupContactMutation from 'queries/groupContacts/createGroupContactMutation.gql';
import updateGroupContactMutation from 'queries/groupContacts/updateGroupContactMutation.gql';
import classnames from 'classnames';
import logger from 'utils/logger';

interface Props {
  isEditingExisting: boolean;
  existingContactUuid?: string;
  existingContactName?: string;
  existingContactDetails?: string;
  closeModal: () => void;
}

interface GroupContactInput {
  uuid?: string;
  displayName?: string;
  dialstring?: string;
}

export const EditOrAddGroupContactModal = (props: Props) => {
  const {
    existingContactName,
    existingContactDetails,
    isEditingExisting,
    closeModal,
    existingContactUuid
  } = props;
  const [ submitDisabled, setSubmitDisabled ] = useState(true);
  const [ name, setName ] = useState(existingContactName);
  const [ details, setDetails ] = useState(existingContactDetails);
  const [ displayNameLengthError, setDisplayNameLengthError ] = useState<string | null>(null);
  const [ dialStringLengthError, setDialStringLengthError ] = useState<string | null>(null);

  useEffect(
    () => {
     if (name === existingContactName && details === existingContactDetails) {
       setSubmitDisabled(true);
     } else if (!name || name?.trim() === '' || !details || details?.trim() === '') {
       setSubmitDisabled(true);
     } else {
       setSubmitDisabled(false);
     }
    },
    [name, details, existingContactDetails, existingContactName]
  );

  const onNameValueChange = async (nameValue: string) => {
    if (nameValue.trim().length < 32) {
      setName(nameValue);
      setDisplayNameLengthError(null);
    } else {
      setDisplayNameLengthError(intl.get('displayNameLengthError'));
    }
  };

  const onDetailsValueChange = (detailsValue: string) => {
    if (detailsValue.trim().length < 255) {
      setDetails(detailsValue);
      setDialStringLengthError(null);
    } else {
      setDialStringLengthError(intl.get('dialStringLengthError'));
    }
  };

  const [ mutateGroupContact, { loading } ] = useMutation(
    isEditingExisting ? updateGroupContactMutation : createGroupContactMutation,
    { refetchQueries: !isEditingExisting ? ['listGroupContactsQuery'] : undefined }
  );

  return (
    <Modal
      className={formStyles.containerSmall}
      closeModal={closeModal}
      closeText={intl.get('cancel')}
      isSaveButtonBusy={loading}
      isSaveButtonDisabled={submitDisabled || loading}
      onSave={async () => {
        const contact: GroupContactInput = {
          displayName: name,
          dialstring: details,
        };
        if (existingContactUuid) {
          contact.uuid = existingContactUuid;
        }
        try {
          await mutateGroupContact({
            variables: {
              contact
            }
          });
          closeModal();
        } catch (e) {
          // handle error
          // tslint:disable-next-line
          logger.error('Failure to save group contact: ', e);
        }
      }}
      saveText={intl.get('save')}
      titleText={isEditingExisting ? intl.get('editGroupContact') : intl.get('addGroupContact')}
      useSaveAsFormSubmit={true}
    >
      <div className={formStyles.formControl}>
        <form>
          <div className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
            <label className={formStyles.labelLarge}>{intl.get('name')}</label>
            <div className={classnames(formStyles.inputContainer, formStyles.medium)}>
              <input
                className={formStyles.input}
                onChange={evt => onNameValueChange(evt.currentTarget.value)}
                type="text"
                value={name}
                data-test="groupContactNameInput"
              />
              <ul className={formStyles.inputMessages}>
                {displayNameLengthError && (
                  <li className={formStyles.error}>{displayNameLengthError}</li>
                )}
                <li className={formStyles.description}>{intl.get('editGroupContactNameDescription')}</li>
              </ul>
            </div>
          </div>
          <div className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
            <label className={formStyles.labelLarge}>{intl.get('callingDetails')}</label>
            <div className={classnames(formStyles.inputContainer, formStyles.medium)}>
              <input
                className={formStyles.input}
                onChange={evt => onDetailsValueChange(evt.currentTarget.value)}
                type="text"
                value={details}
                data-test="groupContactCallingDetailsInput"
              />
              <ul className={formStyles.inputMessages}>
                {dialStringLengthError && (
                  <li className={formStyles.error}>{dialStringLengthError}</li>
                )}
                <li className={formStyles.description}>{intl.get('editGroupContactCallingDetailsDescription')}</li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
