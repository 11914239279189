import { FormInput } from 'interfaces/Recording';
import { UPLOAD_API_ENDPOINT_LIST, UPLOAD_API_ENDPOINT_SINGLETON } from 'constants/recordingsConstants';
import { Methods } from 'interfaces/HTTP';
import { checkHttpStatus } from './http-utils';
import logger from 'utils/logger';

export const getS3UploadCredentials = async (values: FormInput) => {
  if (!values.videoFile) { return; }
  const post = {
    title: values.title,
    fileName: values.videoFile?.name,
    fileSize: values.videoFile?.size,
    contentType: 'multipart/form-data'
  };

  // Initiate upload and get S3 upload URL
  if (!process.env.REACT_APP_RECORDER_API) {
    throw Error('REACT_APP_RECORDER_API is not defined');
  }
  const response = await fetch(`${process.env.REACT_APP_RECORDER_API}${UPLOAD_API_ENDPOINT_SINGLETON}`, {
    credentials: 'include',
    method: Methods.POST,
    body: JSON.stringify(post)
  });
  if (response.status ) {
    return checkHttpStatus(response).json();
  }
};

export const getUploadStatus = async (uuid: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_RECORDER_API}${UPLOAD_API_ENDPOINT_SINGLETON}/${uuid}`, {
      credentials: 'include',
      method: Methods.GET
    });
    return checkHttpStatus(response).json();
  } catch (e) {
    logger.info(`Unable to get upload status: ${JSON.stringify(e)}`);
    return null;
  }
};

export const cancelUpload = async (uuid: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_RECORDER_API}${UPLOAD_API_ENDPOINT_SINGLETON}/${uuid}`, {
      credentials: 'include',
      method: Methods.DELETE
    });
    return response;
  } catch (e) {
    logger.info(`Unable to cancel upload: ${JSON.stringify(e)}`);
    return null;
  }
};

export const getUploadsStatus = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_RECORDER_API}${UPLOAD_API_ENDPOINT_LIST}`, {
      credentials: 'include',
      method: Methods.GET
    });
    return checkHttpStatus(response).json();
  } catch (e) {
    logger.info(`Unable to get uploads status: ${JSON.stringify(e)}`);
    return [];
  }
};
