import _get from 'lodash/get';
import _map from 'lodash/map';
import _debounce from 'lodash/debounce';
import React from 'react';
import intl from 'react-intl-universal';
import { Query } from 'react-apollo';
import * as styles from './ContactSearchSelection.scss';
import { ContactSelection } from './ContactSelection';
import Search from '../Search/Search';
import { Meeting } from 'typings/types';
import { DocumentNode } from 'graphql';
import { SearchInterface } from 'state/Search';

interface SearchUser {
  id: string | null;
  title: string;
}

interface Props {
  query: DocumentNode;
  currentSelection: ContactSelection;
  queryVariables?: object;
  placeholder?: string;
  onChange: (user: SearchUser) => void;
  onBlur?: (user: SearchUser) => void;
  isOptional?: boolean;
}

interface State {
  selected: ContactSelection;
  searching: boolean;
}

class ContactSearchSelection extends React.Component<Props> {

  readonly state: State = {
    selected: this.props.currentSelection,
    searching: false,
  };

  /**
   * Optional callback to onBlur will be called with the new state value after update
   * @param event
   * @param data
   */
  setSelected = (id: string | null, title: string) => {
    const { onChange } = this.props;
    const realSelectedOption = { id, title };
    if (onChange) {
      onChange(realSelectedOption);
    }
    this.setState({ selected: realSelectedOption }, () => {
      const { onBlur } = this.props;
      if (realSelectedOption && onBlur) {
        onBlur(realSelectedOption);
      }
    });
  }

  mappedUsers = (args: Array<Meeting>): Array<SearchInterface> => {
    if (!args.length) {
      return [];
    }

    return _map(args, (contact: Meeting) => {
      return {
        id: contact.UUID,
        title: contact.displayName || '',
        key: contact.UUID // include key to prevent react error in the event of identical titles
      } as ContactSelection;
    }) as SearchInterface[];
  }

  debouncedSearch = _debounce(
    async (refetch, searchString) => {
      try {
        await refetch({
          query: searchString
        });
      } finally {
        this.setState({
          searching: false
        });
      }
    },
    250);

  render() {
    const { placeholder, isOptional, query, queryVariables = {} } = this.props;
    const { selected, searching } = this.state;
    return (
      <div className={styles.container}>
        <Query query={query} variables={queryVariables} fetchPolicy="no-cache">
          {({ data, refetch, loading }: { data: [], refetch: () => void, loading: boolean }) => {
            return (
              <Search
                isFetching={loading}
                onSearchSelectionMade={this.setSelected}
                onSearchCleared={() => this.setSelected(null, '')}
                search={
                  (searchString) => {
                    this.setState({
                      searching: true
                    });
                    this.debouncedSearch(refetch, searchString);
                  }
                }
                results={searching ? [] : this.mappedUsers(_get(data, 'directoryList.records', []))}
                placeholderText={placeholder}
                noResultsText={searching ? intl.get('loading') : intl.get('noResults')}
                initialSelectionId={selected.id}
                initialSelectionText={selected.title}
                showResetButton={isOptional}
                clearOnFocus={true}
              />
            );
          }}
        </Query>
      </div>

    );
  }
}

export default ContactSearchSelection;
