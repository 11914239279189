import React from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import { User } from 'typings/types';
import { PairedDevices } from './PairedDevices';
import * as styles from './DevicesTab.scss';
import { selectPayload } from 'selectors/modalSelector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'actions/modalActions';

const DevicesTab = () => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(actions.closeModal());
  const data = useSelector(selectPayload) as User;

  if (data) {
    data[`uuid`] = data?.UUID;
  }

  return (
    <div className={styles.container}>
      <PairedDevices />
      <footer>
        <Button
          className={styles.button}
          id="btnCancel"
          name="btnCloseModal"
          onClick={closeModal}
          role="button"
          type="button"
        >
          {intl.get('modalCancel')}
        </Button>
      </footer>
    </div>
  );
};

export default DevicesTab;
