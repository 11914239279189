import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions/maxInactiveDaysActions';
import { accountSelectors, meetingsSelectors, nucleusConstants } from '@lifesize/nucleus';
import { isBusyFetchingMeetingsToDelete } from 'selectors/maxInactiveDaysSelector';
import intl from 'react-intl-universal';
import _get from 'lodash/get';
import * as styles from 'components/Settings/Sections.scss';
import { GetItemsToBeDeletedPayload } from 'interfaces/Meeting';
import {
  ACCOUNT_FEATURE_ONE_TIME_MEETING,
  ACCOUNT_FEATURE_PERMANENT_MEETING
} from 'constants/accountFeatureConstants';
import MaxInactiveDays from './MaxInactiveDays/MaxInactiveDays';

const InactiveMeetings = () => {
  const dispatch = useDispatch();
  const meetingSettingsState = useSelector(meetingsSelectors.meetingSettingsSelector);
  const graphQlIsBusy = useSelector(isBusyFetchingMeetingsToDelete);
  const accountFeatures = useSelector(accountSelectors.getAccountFeatures);
  const oneTimeMeetingsEnabled = _get(accountFeatures, ACCOUNT_FEATURE_ONE_TIME_MEETING);
  const permanentMeetingsEnabled = _get(accountFeatures, ACCOUNT_FEATURE_PERMANENT_MEETING);

  useEffect(
    () => {
      dispatch(actions.getMeetingSettings());
    },
    []
  );

  return (
    <section className={styles.container} data-test="settingsSection.inactiveMeetings">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('inactiveMeetings')}</h6>
        <p className={styles.description}>
          {intl.get('maxInactiveDaysDescription')}
        </p>
        <div className={styles.flexColumn}>
          {permanentMeetingsEnabled && (
            <MaxInactiveDays
              inputId="inputMaxInactiveDays"
              label={intl.get('permanentMeetings')}
              tooltip={intl.getHTML('maxInactiveDaysPermTooltip')}
              isBusy={_get(meetingSettingsState, 'isMaxInactiveDaysBusy', false) || graphQlIsBusy}
              lastSavedValue={_get(meetingSettingsState, 'maxInactiveDays', 0)}
              error={_get(meetingSettingsState, 'maxInactiveDaysError', undefined)}
              handleSave={(payload: GetItemsToBeDeletedPayload) => {
                dispatch(actions.getItemsToBeDeleted(payload));
              }}
            />
          )}
          {oneTimeMeetingsEnabled && (
            <MaxInactiveDays
              inputId="inputMaxInactiveDaysOTM"
              label={intl.get('oneTimeMeetings')}
              tooltip={intl.getHTML('maxInactiveDaysOTMTooltip')}
              isBusy={_get(meetingSettingsState, 'isMaxInactiveDaysOTMBusy', false) || graphQlIsBusy}
              lastSavedValue={_get(meetingSettingsState, 'maxInactiveDaysOTM', 0)}
              error={_get(meetingSettingsState, 'maxInactiveDaysOTMError', undefined)}
              handleSave={(payload: GetItemsToBeDeletedPayload) => {
                dispatch(actions.getItemsToBeDeleted({ ...payload, meetingType: nucleusConstants.MEETING_TYPE_ONE_TIME }));
              }}
            />
          )}
        </div>
      </article>
    </section>
  );
};

export default InactiveMeetings;
