import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { currentUserSelectors, recordingsSelectors as nucleusRecordingsSelectors } from '@lifesize/nucleus';
import Recordings from 'components/Recordings/RecordingsTab/RecordingsTab';
import { RecordingRequestObjectFeed } from 'constants/recordingsConstants';
import actions from 'actions/recordingsActions';
import * as RS from 'selectors/recordingsSelectors';
import { Global } from 'state/Global';
import _get from 'lodash/get';
import { defaultProps } from 'recompose';
import { UploadRecordingStatus } from 'constants/recordingsConstants';
import { selectAccountPermissions } from '../../selectors/permissionsSelector';
import { ROLE_PERMISSION } from '../../interfaces/Role';

const viewerCanUpload = createSelector(
  selectAccountPermissions,
  (permissions) => permissions[ROLE_PERMISSION.MODIFY_OWN_RECORDINGS] || permissions[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_RECORDINGS]
);

export interface OwnPropsInterface {
  pageType: RecordingRequestObjectFeed;
}

const mapStateToProps = (state: Global, ownProps: OwnPropsInterface) => {
  const recordingsFeedState = nucleusRecordingsSelectors.recordingsFeed(state);
  const isGridLayout = RS.selectIsGridLayout(state);
  const currentUserRole = currentUserSelectors.myInfo(state).role;
  const canUpload = viewerCanUpload(state) && ownProps.pageType === RecordingRequestObjectFeed.FEED_ALL;
  return {
    isGridLayout: isGridLayout,
    isLoading: _get(recordingsFeedState, 'isFetching'),
    pageType: ownProps.pageType,
    recordingData: _get(recordingsFeedState, 'data'),
    selectedMeeting: _get(recordingsFeedState, 'selected_meeting'),
    totalCount: _get(recordingsFeedState, 'count'),
    error: _get(recordingsFeedState, 'error'),
    canUpload,
    processingUploadCount: RS.getUploadsStatus(state).filter(upload => [UploadRecordingStatus.PROCESSING].includes(upload.status)).length,
    currentUserRole
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  getUploadsStatus: () => dispatch(actions.getUploadsStatus()),
  startUploadUpdates: () => dispatch(actions.startUploadUpdates()),
  stopUploadUpdates: () => dispatch(actions.stopUploadUpdates()),
});

const RecordingsContainer = connect(mapStateToProps, mapDispatchToProps)(Recordings);

export const RecordingsFeedWithFeedAll =
  defaultProps({pageType: RecordingRequestObjectFeed.FEED_ALL})(RecordingsContainer);
export const RecordingsFeedWithMyRecordings =
  defaultProps({pageType: RecordingRequestObjectFeed.FEED_MY_RECORDINGS})(RecordingsContainer);
export const RecordingsFeedWithWatchLater =
  defaultProps({pageType: RecordingRequestObjectFeed.FEED_WATCH_LATER})(RecordingsContainer);
