import React from 'react';
import intl from 'react-intl-universal';
import * as styles from './Footer.scss';

export const FooterComponent = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.row}>
        <span>
          {`© ${new Date().getFullYear()} Lifesize, Inc. ${intl.get('rights')}`}
        </span>
        <a
          href={`https://www.lifesize.com/${intl.getInitOptions().currentLocale}/company/legal-notices/privacy`}
          target="_blank"
        >
          {intl.get('privacyPolicy')}
        </a>
        |
        <a href="https://www.lifesize.com/en/company/legal-notices/terms-of-service" target="_blank">
          {intl.get('TOS')}
        </a>
        |
        <a href="https://status.lifesizecloud.com" target="_blank">
          {intl.get('statusLink')}
        </a>
      </div>
    </footer>
  );
};

export default FooterComponent;
