import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Users from 'components/Users/Users';
import NewDashboard from 'components/DashboardNew/Dashboard';
import { DashboardContainer } from 'containers/Dashboard/DashboardContainer';
import RoomSystems from 'components/RoomSystems/RoomSystems';
import RoomSystemContainer from 'containers/RoomSystem/RoomSystemContainer';
import { SingleRecordingViewContainer } from 'components/Recordings/SingleRecording/SingleRecordingView';
import MeetingsContainer from 'containers/Meetings/MeetingsContainer';
import Recordings from 'components/Recordings/Recordings';
import { RECORDINGS_TABS } from 'constants/recordingsConstants';
import UsersVideosComp from 'components/Drilldowns/UsersAndVideo/UsersAndVideos';
import CallHistoryContainer from 'containers/Drilldowns/CallHistoryContainer';
import ConferenceDetails from 'components/Drilldowns/ConferenceDetails/ConferenceDetails';
import CallHistoryContainer2 from 'containers/Drilldowns/CallHistoryContainer2';
import LiveMeetingDetails from 'components/LiveMeetings/LiveMeetingDetails/LiveMeetingDetails';
import LiveStreamHistoryContainer from 'containers/Drilldowns/LiveStreamHistoryContainer';
import LiveStreamDetailsContainer from 'containers/Meetings/LiveStreamsTab/LiveStreamDetailsContainer';
import LiveStreamSummaryContainer from 'containers/Meetings/LiveStreamsTab/LiveStreamSummaryContainer';
import PastMeetingDetails from 'components/PastMeetingDetails/PastMeetingDetails';
import Settings from 'components/Settings/Settings';
import { Layout } from 'components/Layout/Layout';
import { ROLE_SETTINGS_ROUTE } from 'constants/role';
import { BASE_ROOM_SYSTEM_ROUTE } from 'constants/roomSystemConstants';
import { NEW_DASHBOARD, OLD_DASHBOARD } from 'constants/dashboard';
import LiveMeetingParticipantPage from 'components/LiveMeetings/LiveMeetingParticipantPage/LiveMeetingParticipantPage';
import {
  LIVE_MEETING_ROUTE,
  LIVE_MEETING_PARTICIPANT_ROUTE,
  LIVE_MEETING_PARTICIPANTS,
  LIVE_MEETING_GEO
} from 'constants/liveMeetings';
import {
  PAST_MEETING_GEO,
  PAST_MEETING_PARTICIPANT_ROUTE,
  PAST_MEETING_PARTICIPANTS,
  PAST_MEETING_ROUTE
} from 'constants/pastMeetings';
import PastMeetingParticipantPage from 'containers/PastMeetings/PastMeetingParticipantPageContainer';
import { DOWNLOADS_PATH } from 'constants/downloadConstants';
import Downloads from 'components/Downloads/Downloads';
import { RoleDetails } from 'components/RoleDetails/RoleDetails';

// @ts-ignore
export const PrivateRoutes = (config) => {
  const {
    defaultRoute,
    hideDashboardRoute,
    hideUsersRoute,
    hideMeetingsRoute,
    hideRoomsRoute,
    hideDownloadsRoute,
    showDemoDashboard,
    removeOldDashboard,
    showRolesTab,
    showRecordings,
    showRoles,
    showSettings
  } = config;

  const publicURL = process.env.REACT_APP_BRANCH_NAME || undefined;

  const roomRoutes = [
    <Route key={'baseRoomSystem'} exact={true} path={BASE_ROOM_SYSTEM_ROUTE} component={RoomSystems}/>,
    <Route key={'baseRoomTab'} path={`${BASE_ROOM_SYSTEM_ROUTE}/:id/:tab(settings|controller|maintenance)?`} component={RoomSystemContainer}/>,
  ];

  const dashboardRoutes = [
    <Route key={'newDashboard'} exact={true} path={'/'} component={NewDashboard}/>,
    <Route key={'callHistory'} path={'/drilldown/callhistory'} component={CallHistoryContainer}/>,
    <Route key={'callHistory2'} path={'/drilldown/callhistory2'} component={CallHistoryContainer2}/>,
    <Route key={'conferenceDetails'} path={'/drilldown/conferenceDetails'} component={ConferenceDetails}/>,
    <Route key={'liveMeetingParticipants'} path={`${LIVE_MEETING_ROUTE}/:id/(${LIVE_MEETING_PARTICIPANTS}|${LIVE_MEETING_GEO})?`} component={LiveMeetingDetails}/>,
    <Route key={'liveMeetingDetails'} path={`${LIVE_MEETING_PARTICIPANT_ROUTE}/:id/:callId`} component={LiveMeetingParticipantPage}/>,
    <Route key={'pastMeetingDetails'} path={`${PAST_MEETING_PARTICIPANT_ROUTE}/:id/:callId`} component={PastMeetingParticipantPage}/>,
    <Route key={'usersAndVideos'} path={'/drilldown/usersVideos/:tab(users|rooms|users2|rooms2)/:id?'} component={UsersVideosComp}/>,
    <Route key={'pastMeetingParticipants'} path={`${PAST_MEETING_ROUTE}/:id/(${PAST_MEETING_PARTICIPANTS}|${PAST_MEETING_GEO})?`} component={PastMeetingDetails}/>,
    <Route key={'liveStream'} exact={true} path={'/drilldown/livestream'} component={LiveStreamHistoryContainer} />,
    <Route key={'newDashboardDirect'} path={NEW_DASHBOARD} component={NewDashboard}/>,
  ];
  if (!removeOldDashboard) {
    dashboardRoutes.push(<Route key={'oldDashboard'} path={OLD_DASHBOARD} component={DashboardContainer}/>);
  }

  const demoDashboardRoutes = [
    <Route key={'newDashboard'} exact={true} path={'/'} component={NewDashboard}/>,
    <Route key={'newDashboardDirect'} path={NEW_DASHBOARD} component={NewDashboard}/>,
  ];

  const downloadRoutes = [
    <Route key={'downloads'} path={DOWNLOADS_PATH} component={Downloads} />,
  ];

  const meetingRoutes = [
    <Route key={'meetingsTab'} path={'/meetings/:tab(meet|livestreams)'} component={MeetingsContainer}/>,
    <Route key={'liveStreamsTab'} path={'/meetings/:tab(meet|livestreams)'} component={MeetingsContainer}/>,
    <Route key={'liveStreamByName'} exact={true} path={'/meetings/livestreamByName/:name/:meetingId?'} component={LiveStreamHistoryContainer}/>,
    (
      <Route
        key={'viewersAndQuestions'}
        exact={true}
        path={'/meetings/livestream/:id/:tab(viewers|questions)'}
        component={LiveStreamDetailsContainer}
      />
    ),
    (
      <Route
        exact={true}
        key={'newViewersAndQuestions'}
        path={'/meetings/livestreamSummary/:id/:tab(viewers|questions)'}
        component={LiveStreamSummaryContainer}
      />
    ),
  ];

  const usersRoutes = showRolesTab ? [
      <Route key={'users'} path={'/users/:tab(users|roles|group)'} component={Users}/>,
    ] :
    <Route key={'users'} path={'/users/:tab(users|group)'} component={Users}/>;

  const recordingsRoutes = [
    <Route key={'recordingsTab'} path={'/recordings/:tab(recordingsFeed|myRecordings|watchListRecordings)?'} component={Recordings}/>,
    <Route key={'singleRecording'} exact={true} path={'/singleRecording/:id'} component={SingleRecordingViewContainer}/>,
    <Route key={'recordingsFeed'} exact={true} path={'/recordingsFeed'} render={() => (<Redirect to={RECORDINGS_TABS.RECORDINGS_FEED}/>)}/>,
    <Route key={'myRecordings'} exact={true} path={'/myRecordings'} render={() => (<Redirect to={RECORDINGS_TABS.MY_RECORDINGS}/>)}/>,
    <Route key={'watchListRecordings'} exact={true} path={'/watchListRecordings'} render={() => (<Redirect to={RECORDINGS_TABS.WATCHLIST}/>)}/>,
  ];

  const roleRoutes = [
    <Route key={'role'} path={`${ROLE_SETTINGS_ROUTE}/:id`} component={RoleDetails}/>,
    (hideUsersRoute && <Route key={'users'} path={'/users/:tab(roles)'} component={Users}/>)
  ];

  const settingsRoutes = [
    <Route key={'settings'} path={'/settings/:tab(group|icon|sso|meeting|details|downloads)?'} component={Settings}/>,
  ];

  const routes = [
    ...[!hideUsersRoute && usersRoutes],
    ...[showRecordings && recordingsRoutes],
    ...[showDemoDashboard && demoDashboardRoutes],
    ...[!hideDashboardRoute && dashboardRoutes],
    ...[!hideMeetingsRoute && meetingRoutes],
    ...[!hideRoomsRoute && roomRoutes],
    ...[!hideDownloadsRoute && downloadRoutes],
    ...[showRoles && roleRoutes],
    ...[showSettings && settingsRoutes],
    // The defaultRoute MUST come last, or routing will be broken.
    (
      <Route
        path="/"
        key={'defaultRoute'}
        render={() => <Redirect to={defaultRoute} />}
      />
    )
  ];

  return (
    <Router basename={publicURL}>
      <Layout>
        <Switch>
          {routes}
        </Switch>
      </Layout>
    </Router>
  );
};
