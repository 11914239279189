import {
  alexaCredentialsEndpoint,
  alexaDeviceEndpoint,
  alexaIntegrationEndpoint,
  alexaProfileEndpoint,
  alexaRegisterEndpoint
} from 'constants/alexaIntegrationConstants';
import { dispatchProxyApiCall } from 'utils/nucleusProxyUtils';
import actions from 'actions/alexaIntegrationActions';
import { Methods } from 'interfaces/HTTP';
import { GetCredentialsResponse, GetDeviceResponse, GetProfilesResponse } from 'interfaces/AlexaIntegration';

const thunks = {
  getAlexaCredentials: () => {
    return async (dispatch: Function) => {
      return await dispatchProxyApiCall(
        dispatch,
        {},
        {},
        alexaIntegrationEndpoint,
        alexaCredentialsEndpoint,
        Methods.GET,
        (response: GetCredentialsResponse) => {
          dispatch(actions.getAlexaCredentialsSuccess(response.body));
          return response;
        },
        (response: GetCredentialsResponse) => {
          dispatch(actions.getAlexaCredentialsError(response.errorId ? response.errorId : ''));
          throw response.errorId;
        });
    };
  },
  authenticateAlexa: (roleArn: string, externalId: string) => {
    return async (dispatch: Function) => {
      const inputParams = { roleArn, externalId };
      return await dispatchProxyApiCall(
        dispatch,
        {},
        inputParams,
        alexaIntegrationEndpoint,
        alexaCredentialsEndpoint,
        Methods.POST,
        () => {
          dispatch(actions.authenticateAlexaSuccess(roleArn, externalId));
          return { roleArn, externalId };
        },
        (response: { errorId: string }) => {
          dispatch(actions.authenticateAlexaError(externalId, response.errorId));
          throw (response.errorId);
        }
      );
    };
  },
  disconnectAlexa: () => {
    return async (dispatch: Function) => {
      return await dispatchProxyApiCall(
        dispatch,
        {},
        {},
        alexaIntegrationEndpoint,
        alexaCredentialsEndpoint,
        Methods.DELETE,
        () => dispatch(actions.alexaDisconnectSuccess()),
        (response: { errorId: string }) => {
          dispatch(actions.alexaDisconnectFailure(response.errorId));
          throw (response.errorId);
        }
      );
    };
  },
  getProfiles: () => {
    return async (dispatch: Function) => {
      return await dispatchProxyApiCall(
        dispatch,
        {},
        {},
        alexaIntegrationEndpoint,
        alexaProfileEndpoint,
        Methods.GET,
        (response: GetProfilesResponse) => {
          dispatch(actions.createAlexProfileSuccess(response.body));
          return response.body;
        },
        (response: GetProfilesResponse) => {
          dispatch(actions.getAlexaProfileFailure(response.errorId));
          throw response.errorId;
        }
      );
    };
  },
  registerDevice: (profileArn: string, serialNumber: string) => {
    return async (dispatch: Function) => {
      return await dispatchProxyApiCall(
        dispatch,
        {},
        {
          profileArn,
          serialNumber
        },
        alexaIntegrationEndpoint,
        alexaRegisterEndpoint,
        Methods.POST,
        (response: GetDeviceResponse) => {
          dispatch(actions.getDeviceStateSuccess({ [serialNumber]: response.body }));
          return response.body;
        },
        (response: { errorId: string }) => {
          dispatch(actions.alexaDeviceStateFailure(serialNumber));
          throw response.errorId;
        }
      );
    };
  },
  unregisterDevice: (serialNumber: string) => {
    return async (dispatch: Function) => {
      return await dispatchProxyApiCall(
        dispatch,
        {},
        {},
        alexaIntegrationEndpoint,
        alexaDeviceEndpoint(serialNumber),
        Methods.DELETE,
        (response: GetDeviceResponse) => {
          dispatch(actions.removeDeviceStateSuccess(serialNumber));
          return response.body;
        },
        (response: { errorId: string }) => {
          throw response.errorId;
        }
      );
    };
  },
  getDeviceRegistration: (serialNumber: string) => {
    return async (dispatch: Function) => {
      return await dispatchProxyApiCall(
        dispatch,
        {},
        {},
        alexaIntegrationEndpoint,
        alexaDeviceEndpoint(serialNumber),
        Methods.GET,
        (response: GetDeviceResponse) => {
          dispatch(actions.getDeviceStateSuccess({[serialNumber]: response.body}));
          return response;
        },
        (response: GetDeviceResponse) => {
          throw response.errorId;
        }
      );
    };
  },
};

export default thunks;
