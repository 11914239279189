import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import Icon from 'components/Common/Icon';
import LiveStreamDetailsViewers from 'components/Meetings/LiveStreamsTab/LiveStreamDetailsViewers';
import LiveStreamDetailsQuestions from 'components/Meetings/LiveStreamsTab/LiveStreamDetailsQuestions';
import * as styles from './LiveStreamDetails.scss';
import { Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import { TabProps } from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import { DWHLiveStreamQuestionType, DWHLiveStreamResultType, DWHLiveStreamViewerType } from 'interfaces/Dashboard';
import { getBreadcrumbs } from 'utils/nav-utils';
import { MEETING_LIVE_STREAMS } from 'constants/liveStreamHistory';

interface Props {
  fetchMetaData: Function;
  fetchQuestionData: Function;
  fetchViewerData: Function;
  metadata: DWHLiveStreamResultType;
  metadataError: string | null;
  metadataIsFetching: boolean;
  questions: DWHLiveStreamQuestionType[];
  questionsError: string | null;
  questionsIsFetching: boolean;
  viewers: DWHLiveStreamViewerType[];
  viewersError: string | null;
  viewersIsFetching: boolean;
}

const getTabIndex = (tab: string = '') => {
  return tab === 'questions' ? 1 : 0; // default to 'viewers'
};

const getRouteData = (match: {} ) => {
  const { id, tab } =  _get(match, 'params', {}) as { id: string, tab: string };
  return {
    id: decodeURIComponent(id),
    tab,
    tabIndex: getTabIndex(tab)
  };
};

const renderBreadcrumbs = (streamName: string) => {
  return getBreadcrumbs([
    {
      url: MEETING_LIVE_STREAMS,
      label: intl.get('navMeetings')
    },
    {
      url: `/meetings/livestreamByName/${encodeURIComponent(streamName)}`,
      label: intl.get('breadcrumbHistory')
    },
    {
      label: intl.get('breadcrumbDetails')
    }
  ]);
};

const renderSubtitle = (id: string, metadata: DWHLiveStreamResultType) => {
  return [
    (
      <div key="metadata">
        {[
          `${intl.get('dataLabelDate')}: ${metadata.startDateFormatted}`,
          `${intl.get('dataLabelViewers')}: ${metadata.viewerCount}`,
          `${intl.get('dataLabelDuration')}: ${metadata.durationSecondsFormatted}`,
        ].join(' | ')}
      </div>
    ),
    (
      <Link id="view_all" to={`/singleRecording/${encodeURIComponent(id)}`} key="recording">
        <Icon classes={['icon-record']} />
        <span>{intl.get('buttonLabelViewRecording')}</span>
      </Link>
    )
  ];
};

const LiveStreamDetails = (props: RouteComponentProps<{}> & Props) => {
  const {
    match,
    viewers,
    viewersError,
    fetchViewerData,
    viewersIsFetching,
    questions,
    questionsError,
    fetchQuestionData,
    questionsIsFetching,
    fetchMetaData,
    history,
    metadata
  } = props;

  const [ selectedTab, setSelectedTab ] = useState(_get(match, 'params.tab'));
  const { id, tabIndex } = getRouteData(match);

  useEffect(
    () => {
      const fetchMore = async () => {
        await fetchMetaData(id);
      };
      fetchMore();
    },
    []
  );

  const panes = [
    {
      menuItem: (
        {
          key: 'viewersTab',
          content: (
            intl.get('tabViewers')
          )
        }),
      render: () => {
        return (
          <Tab.Pane key={'0'} attached={false}>
            <LiveStreamDetailsViewers
              data={viewers}
              error={viewersError}
              fetchData={async () => fetchViewerData(id)}
              isFetching={viewersIsFetching}
            />
          </Tab.Pane>
        );
      }
    },
    {
      menuItem: (
        {
          key: 'questionsTab',
          content: (
            intl.get('tabQuestions')
          )
        }
      ),
      render: () => {
        return (
          <Tab.Pane key={'1'} attached={false}>
            <LiveStreamDetailsQuestions
              data={questions}
              error={questionsError}
              fetchData={() => fetchQuestionData(id)}
              isFetching={questionsIsFetching}
            />
          </Tab.Pane>
        );
      }
    }
  ];

  const onTabChange = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
    if (_isNumber(data.activeIndex) && selectedTab !== data.activeIndex) {
      setSelectedTab(data.activeIndex);
      if (data.activeIndex === 0) {
        history.push(`/meetings/livestream/${id}/viewers`);
      } else {
        history.push(`/meetings/livestream/${id}/questions`);
      }
    }
  };

  const title = `${metadata.streamName} ${intl.get('breadcrumbDetails')}`.trim();

  return (
    <div className={styles.container}>
      <ListViewLayoutHeader
        breadcrumbs={renderBreadcrumbs(metadata.streamName)}
        key={'header'}
        subtitle={renderSubtitle(id, metadata)}
        title={title}
      />
      <Tab
        className={classnames(styles.tabsContainer, 'tabset')}
        defaultActiveIndex={tabIndex}
        key={'tabSet'}
        menu={{secondary: true, pointing: true}}
        onTabChange={onTabChange}
        panes={panes}
      />
    </div>
  );
};

export default withRouter(LiveStreamDetails);
