import React, { useEffect, useRef } from 'react';
import * as styles from './GeoDistribution.scss';
import { GeoLocation } from 'interfaces/Dashboard';
import MarkerClusterer from '@google/markerclusterer';
import mapPin from '@lifesize/ux-assets/images/admin/PNG/mapPin.png';
import inactiveMapPin from '@lifesize/ux-assets/images/admin/PNG/inactiveMapPin.png';
import clusterTeal from '@lifesize/ux-assets/images/admin/PNG/clusterTeal.png';
import _isEqual from 'lodash/isEqual';

interface Props {
  geoDistribution: Array<GeoLocation>;
}

const cluster = {
  height: 30,
  width: 30,
  url: clusterTeal,
};

const createMap = (elt: HTMLDivElement) => {
  const google = window.google;
  return new google.maps.Map(elt, {
    center: new google.maps.LatLng(0, 0),
    zoom: 20,
    minZoom: 1,
    maxZoom: 14
  });
};

const updateMap = (currentMap: GoogleMap, geoDistribution: Props['geoDistribution'], currentMarkers: Array<GoogleMarker>) => {
  const google = window.google;

  if (currentMarkers) {
    currentMarkers.forEach((marker) => {
      marker.setMap(null);
    });
    currentMarkers.length = 0;
  }

  const newMarkers = geoDistribution.map((geoLocation: GeoLocation, index) => {
    const {latitude, longitude, displayName, isActive} = geoLocation;
    const position = new google.maps.LatLng(latitude, longitude);
    return new google.maps.Marker({
      position,
      title: displayName,
      map: currentMap,
      icon: isActive ? mapPin : inactiveMapPin,
      zIndex: isActive ? 5000 - index : 0,
    });
  });

  const bounds = new google.maps.LatLngBounds(null);
  newMarkers.forEach((newMarker) => {
    bounds.extend(newMarker.getPosition());
  });

  currentMap.setCenter(bounds.getCenter());
  currentMap.fitBounds(bounds, 20);
  currentMap.panToBounds(bounds, 20);

  // Add a marker clusterer to manage the markers.
  const markerOptions = {
    styles: [
      cluster,
      cluster,
      cluster,
      cluster,
      cluster
    ]
  };
  const markerCluster = new MarkerClusterer(currentMap, newMarkers, markerOptions);
  return newMarkers;
};

let googleMap: null | GoogleMap = null;
let markers: Array<GoogleMarker>;

export const GeoDistribution = React.memo(
  (props: Props) => {
    const {geoDistribution} = props;
    const mapDiv = useRef<HTMLDivElement>(null);

    useEffect(
      () => {
        if (mapDiv && mapDiv.current) {
          googleMap = createMap(mapDiv.current);
        }
      },
      []);

    useEffect(
      () => {
        if (googleMap) {
          markers = updateMap(googleMap, geoDistribution, markers);
        }
      },
      [geoDistribution]
    );

    return (
      <div
        ref={mapDiv}
        id={'map'}
        className={styles.geoContainer}
      />
    );
  },
  (prevProps, nextProps) => {
    return _isEqual(prevProps.geoDistribution, nextProps.geoDistribution);
  }
);

export default { GeoDistribution };
