import { useMutation } from 'react-apollo';
import updateRoleMutation from 'queries/roles/updateRole.gql';
import { PermissionSetting, Role } from 'interfaces/Role';
import getRoleQuery from 'queries/roles/getRole.gql';

export function useRoleChange(role: Role) {
  const { id, name } = role;
  const [ mutateRole, { loading, error } ] = useMutation(
    updateRoleMutation,
    {
      update(cache: { readQuery: Function, writeQuery: Function }, { data: { updateRole } }: { data: {  updateRole: Role } }) {
        const response: { getRole: Role } = cache.readQuery({
          query: getRoleQuery,
          variables: {
            roleId: id
          }
        });
        const existingRole = response.getRole;

        cache.writeQuery({
          query: getRoleQuery,
          variables: { roleId: id },
          data: { getRole: {...existingRole, ...updateRole } }
        });
      }
    }
  );

  const savePermissions = async (permissionsToSave: PermissionSetting) => {
    await mutateRole({
      variables: {
        id,
        name,
        permissions: JSON.stringify(permissionsToSave)
      }
    });
  };

  return { savePermissions, loading, error };
}

export default useRoleChange;
