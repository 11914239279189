import { createSelector } from 'reselect';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { PastMeetingDetailsData } from '../interfaces/PastMeeting';
import _filter from 'lodash/filter';
import { ParticipantEntry } from '../interfaces/Dashboard';

/**
 * Selector to map past conference participants
 */
const selectParticipants = (data: PastMeetingDetailsData) => _get(data, 'pastConferenceDetails.participants', []);

const selectGeoDistribution = createSelector(
  selectParticipants,
  (participants) => {
    const participantsToMap = _filter(participants,
                                      (participant: ParticipantEntry) => _get(participant, 'geoLocation.latitude') && _get(participant, 'geoLocation.longitude'));
    return (
      _map(participantsToMap, (participant) => {
        const { geoLocation, displayName } = participant;
        const { latitude, longitude } = geoLocation;
        return { latitude, longitude, displayName, isActive: true };
      })
    );
  }
);

export {
  selectGeoDistribution
};
