import React from 'react';
import intl from 'react-intl-universal';
import { defaultRowCount } from '../../../constants/listViewLayoutConstants';
import SearchBar from '../../../containers/Search/SearchBar';
import { DWHUserCallsResultType } from '../../../interfaces/Dashboard';
import { exportToCsv } from '../../../utils/csv-utils';
import { parseRoomSystemDrillDownDataForExport } from '../../../utils/exportUtils';
import ListViewComponent from '../../ListView/ListViewComponent';
import ListViewLayout from '../../ListViewLayout/ListViewLayout';
import DrilldownHeader from '../Common/DrilldownHeader';
import * as styles from './RoomSystemsTable.scss';

interface Props {
  error: string;
  fetchListData: Function;
  isFetching: boolean;
  listData: Array<DWHUserCallsResultType>;
  numberOfPages: number;
  onSelect: Function;
  pageNumber: number;
}

class RoomSystemsTable extends React.Component<Props> {

  handleExportToCsv = () => {
    const exportData = [];
    const sort = this.props.listData.sort(
      (a: DWHUserCallsResultType, b: DWHUserCallsResultType) => {
        return b.totalMinutes - a.totalMinutes;
      });
    for (let user of sort) {
      exportData.push(parseRoomSystemDrillDownDataForExport(user));
    }
    exportToCsv(intl.get('roomSystems'), exportData);
  }

  render() {
    const { error, fetchListData, isFetching, listData, numberOfPages, onSelect, pageNumber} = this.props;
    return (
      <ListViewLayout
        error={error}
        fetchListData={fetchListData}
        hideHeader={true}
        isFetching={isFetching}
        numberOfPages={numberOfPages}
        title={intl.get('headerRoomSystemTotals')}
      >
        <SearchBar/>

        <DrilldownHeader
          fetchData={fetchListData}
          onExportClick={this.handleExportToCsv}
        />

        {isFetching ? null :
          <ListViewComponent
            data={listData ? listData : []}
            columns={
              [{
                Header: intl.get('name'),
                accessor: 'name',
                Cell: (row: { value: string }) => (
                  <a
                    className={styles.editButton}
                    onClick={() => onSelect(row.value)} // TODO: use an ID (uuid) rather than a name string
                  >
                    {row.value}
                  </a>
                )
              }, {
                Header: intl.get('minutes'),
                accessor: 'totalMinutes',
              }, {
                Header: intl.get('calls'),
                accessor: 'totalCalls'
              }]
            }
            showPagination={false}
            maxRows={defaultRowCount}
            pageNumber={pageNumber}
          />}
      </ListViewLayout>
    );
  }
}

export default RoomSystemsTable;
