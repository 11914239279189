import { dataWarehouseActions, dataWarehouseSelectors, vcApiActions } from '@lifesize/nucleus';
import { connect } from 'react-redux';
import LiveStreamDetails from 'components/Meetings/LiveStreamsTab/LiveStreamDetails';
import { parseQuestionsForDisplay, parseMetadataForDisplay } from 'utils/liveStreamUtils';
import { Global } from 'state/Global';

function mapStateToProps(state: Global) {
  const streamDetails = dataWarehouseSelectors.streamDetails(state);
  return {
    ...streamDetails,
    metadata: parseMetadataForDisplay(streamDetails.metadata),
    questions: parseQuestionsForDisplay(streamDetails.questions)
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchMetaData: (id: string) => {
    dispatch(dataWarehouseActions.fetchStreamMetaData(id));
  },
  fetchQuestionData: (id: string) => {
    dispatch(vcApiActions.getQnATranscript(id));
  },
  fetchViewerData: (id: string) => {
    dispatch(dataWarehouseActions.fetchStreamViewers(id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamDetails);
