import Icon from 'components/Common/Icon';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { actions } from '../../../actions/modalActions';
import ModalComponent from '../../Modal/ModalComponent/ModalComponent';
import * as styles from './PermissionConfirmationModal.scss';

interface Props {
  closeModal: () => void;
}

const PermissionConfirmationModal: React.FunctionComponent<Props> = ({closeModal}) => {
  return (
    <ModalComponent
      closeModal={closeModal}
      hideCloseButton={false}
      hideSaveButton={false}
      onSave={closeModal}
      saveText={intl.get('modalOk')}
      closeText={intl.get('modalCancel')}
    >
      <div className={styles.container}>
        <div className={styles.item1}>
          <Icon classes={['icon-exclamation']} />
          <>{intl.get('resetViewingPermissionText')}</>
        </div>

        <div className={styles.item2}>
            {intl.get('addViewers')}
            SOME DATA GOES HERE?
        </div>

        <div className={styles.item3}>
          {intl.get('areYouSureContinue')}
        </div>
      </div>
    </ModalComponent>
  );
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
});

const composed = compose(
  connect(null, mapDispatchToProps),
)(PermissionConfirmationModal);

export { composed as PermissionConfirmationModal };
