/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { remotingReducers } from '@lifesize/clients.remoting';
import userProfileReducer from 'reducers/userProfileReducer';
import * as Redux from 'redux';
import { combineReducers } from 'redux';
import alexaIntegrationReducer from './reducers/alexaIntegrationReducer';
import calendarSubscriptionReducer from './reducers/calendarSubscriptionReducer';
import dashboardReducer from './reducers/dashboardReducer';
import deviceCustomizationReducer from './reducers/deviceCustomizationReducer';
import deviceManagementReducer from './reducers/deviceManagementReducer';
import ecommerceReducer from './reducers/ecommerceReducer';
import exportToCsvReducer from './reducers/exportToCsvReducer';
import filterReducer from './reducers/filterReducer';
import impersonationCodeReducer from './reducers/impersonationReducer';
import languageProviderReducer from './reducers/languageReducer';
import liveStreamHistoryReducer  from './reducers/liveStreamHistoryReducer';
import liveStreams from './reducers/liveStreamsReducer';
import maxInactiveDaysReducer from './reducers/maxInactiveDaysReducer';
import meetings from './reducers/meetingsReducer';
import mergeAccountReducer from './reducers/mergeAccountReducer';
import modalReducer from './reducers/modalReducer';
import paginationReducer from './reducers/paginationReducer';
import permissions from './reducers/permissionsReducer';
import recordingsReducer from './reducers/recordingsReducer';
import resetPasswordReducer from './reducers/resetPasswordReducer';
import selectionReducer from './reducers/selectionReducer';
import singleRecordingsReducer from './reducers/singleRecordingReducer';
import sortReducer from './reducers/sortReducer';
import userStateReducer from './reducers/userStateReducer';
import wallpaper from './reducers/wallpaperReducer';
import downloadNotificationReducer from './reducers/downloadNotificationReducer';
import exportReducer from './reducers/exportReducer';
import roleReducer from './reducers/roleReducer';
import calendarServiceReducer from './reducers/calendarServiceReducer';

/**
 * Creates the main reducer
 */
export default function createReducer(): Redux.Reducer {
  return combineReducers({
    alexa: alexaIntegrationReducer,
    calendarService: calendarServiceReducer,
    calendarSubscription: calendarSubscriptionReducer,
    exports: exportReducer,
    dashboard: dashboardReducer,
    deviceCustomization: deviceCustomizationReducer,
    deviceManagement: deviceManagementReducer,
    downloadNotifications: downloadNotificationReducer,
    ecommerce: ecommerceReducer,
    exportToCsv: exportToCsvReducer,
    filter: filterReducer,
    impersonation: impersonationCodeReducer,
    language: languageProviderReducer,
    liveStreamHistory: liveStreamHistoryReducer,
    liveStreams,
    maxInactiveDays: maxInactiveDaysReducer,
    meetings: meetings,
    modal: modalReducer,
    pagination: paginationReducer,
    permissions: permissions,
    recordings: recordingsReducer,
    recordingsSingle: singleRecordingsReducer,
    remoting: combineReducers(remotingReducers),
    resetPassword: resetPasswordReducer,
    role: roleReducer,
    selection: selectionReducer,
    sort: sortReducer,
    userProfile: userProfileReducer,
    userState: userStateReducer,
    wallpaper: wallpaper,
    mergeAccount: mergeAccountReducer
  });
}
