import { createSelector } from 'reselect';
import { Global } from 'state/Global';

/**
 * Direct selector to the drilldown state domain
 */
const selectPaginationState = (state: Global) => state.app?.pagination;

const selectPageNumber = createSelector(
  selectPaginationState,
  (pagination) => pagination?.pageNumber
);

export {
  selectPageNumber,
};
