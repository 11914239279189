import React, { useEffect, useRef, useState } from 'react';
import Icon from 'components/Common/Icon';
import * as styles from './SearchMultiSelect.scss';
import { VideoShareViewer } from 'interfaces/VideoShareProperties';
import _get from 'lodash/get';
import { Query } from 'react-apollo';
import listUsersQuery from 'queries/users/listUsersQuery.gql';
import _debounce from 'lodash/debounce';
import { Loader } from 'semantic-ui-react';

interface Props {
  /** Setter for data */
  initialSelection?: VideoShareViewer[];
  placeholderText?: string;
  noResultsText?: string;
  showResetButton?: boolean;
  onSearchSelectionMade: (searchSelection: VideoShareViewer[]) => void;
  searchQuery?: string;
}

interface User {
  displayName: string;
  UUID: string;
}

interface DirectoryResult {
  directoryList: { records: Array<User> };
}

const search = _debounce(
  (searchString: string, setQuery: Function, setSearching: Function) => {
    setQuery(searchString);
    setSearching(false);
  },                 
  250
);

const SearchMultiSelectComponent = (props: Props) => {
  const { initialSelection, onSearchSelectionMade, noResultsText, searchQuery } = props;
  const [ searchSelection, setSearchSelection ] = useState(initialSelection || []);
  const [ searchString, setSearchString ] = useState('');
  const [ query, setQuery ] = useState('');
  const [ searching, setSearching ] = useState(false);

  const searchContainer = useRef<HTMLDivElement>(null);
  const searchInput = useRef<HTMLInputElement>(null);

  const queryVars = {
    sort: null,
    query,
    includeRoles: false
  };

  useEffect(
    () => {
      setSearchSelection(initialSelection || searchSelection);
    },
    [JSON.stringify(initialSelection)]
  );
  
  const onKeyDown = (e: React.KeyboardEvent) => {
    if (!searchString.length && searchSelection.length && (e.which === 8 || e.which === 46)) {
      handleRemoveUser(searchSelection[searchSelection.length - 1].uuid);
    }
  };

  /**
   * User has made a selection of a meeting to re-query the list
   */
  const handleResultSelect = (user: VideoShareViewer) => {
    const newSearchSelection = [...searchSelection];
    newSearchSelection.push(user);
    setSearchString('');
    setSearchSelection(newSearchSelection);
    if (searchInput.current) {
      searchInput.current.focus();
    }
    const currentSearchContainer = searchContainer.current;
    if (currentSearchContainer) {
      setTimeout(() => {
        currentSearchContainer.scrollTo(0, currentSearchContainer.scrollHeight);
      });
    }
    onSearchSelectionMade(newSearchSelection);
  };

  const handleRemoveUser = (uuid: string) => {
    const newSearchSelection = searchSelection.filter(user => user.uuid !== uuid);
    setSearchSelection(newSearchSelection);
    onSearchSelectionMade(newSearchSelection);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchString: string = event.target.value || '';
    setSearchString(newSearchString);
    setSearching(true);
    search(newSearchString, setQuery, setSearching);
  };

  const generateResults = (data: DirectoryResult, loading: boolean) => {
    const { directoryList } = data;
    const results = _get(directoryList, 'records');

    const filteredResults = results.filter((user: User) => (
      !searchSelection.find((selection) => selection.name === user.displayName)
    ));

    if (!loading && !filteredResults.length) { return noResultsText; }
    if (loading) {
      return (
        <div className={styles.loaderContainer}>
          <Loader size={'mini'} active={true} inline={true} />
        </div>
      );
    }

    return filteredResults.map((user: User) => (
        <div
          key={user.UUID}
          className={styles.searchResult}
          onClick={() => handleResultSelect({ name: user.displayName, uuid: user.UUID })}
        >
          {user.displayName}
        </div>
      )
    );
  };
  
  return (
    <Query query={searchQuery || listUsersQuery} variables={{...queryVars}} fetchPolicy="network-only">
      {({ data, refetch, loading }: { data: DirectoryResult, refetch: Function, loading: boolean}) => {
        return (<div className={styles.container}>
          <div className={styles.selectionsContainer} ref={searchContainer}>
            {searchSelection.map((user) => {
              return (
                <div className={styles.pill} key={user.uuid}>
                  <div>{user.name}</div>
                  <div onClick={() => handleRemoveUser(user.uuid)}>
                    <Icon classes={['icon-cancel', styles.iconPillCancel]} />
                  </div>
                </div>
              );
            })}
            <input
              type="text"
              ref={searchInput}
              value={searchString}
              onChange={handleSearchChange}
              onKeyDown={onKeyDown}
              className={styles.searchInput}
            />
          </div>
          {searchString && searchString.length && (
            <div className={styles.searchResultsContainer}>
              {generateResults(data, searching || loading)}
            </div>
          )}
        </div>);
      }}
    </Query>
  );
};

export default SearchMultiSelectComponent;
