import { accountSelectors, dataWarehouseActions } from '@lifesize/nucleus';
import { DashboardActionType } from 'actions/dashboardActions';
import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { liveStreamV2Cutoff } from 'utils/liveStreamUtils';
import { ACCOUNT_FEATURE_STREAMING } from 'constants/accountFeatureConstants';
import { selectDashboardState } from 'selectors/dashboardSelectors';

const dashboardDataLoadMiddleware =
  (store: MiddlewareAPI) =>
    (next: Dispatch) => {
      return (action: Action) => {
        if (DashboardActionType.DASHBOARD_FETCH_ALL !== action.type) {
          return next(action);
        }

        const state = store.getState();
        const dashboardState = selectDashboardState(state);
        next(dataWarehouseActions.fetchConferenceData(dashboardState.startDate, dashboardState.endDate));
        next(dataWarehouseActions.fetchCallsGroupedByDaysDevices(dashboardState.startDate, dashboardState.endDate));
        next(dataWarehouseActions.fetchSummaryData(dashboardState.startDate, dashboardState.endDate));
        next(dataWarehouseActions.fetchUsersFilteredByActiveTotal(
          dashboardState.startDate,
          dashboardState.endDate
        ));
        // TODO - Commented out Call Quality until needed in Prod
        // next(dataWarehouseActions.fetchCallsGroupedByPlan(dashboardState.startDate, dashboardState.endDate));
        // TODO - Commented out Live Stream calls until we need them in Prod
        // next(dataWarehouseActions.fetchStreamsFilteredByUsage());
        next(dataWarehouseActions.fetchUsersGroupByCallsPersonal(dashboardState.startDate, dashboardState.endDate));
        next(dataWarehouseActions.fetchUsersGroupByCallsRoom(dashboardState.startDate, dashboardState.endDate));
        // Only fetch if account has LiveStreaming enabled
        if (!!accountSelectors.getAccountFeature(state)(ACCOUNT_FEATURE_STREAMING)) {
          if (dashboardState.startDate < liveStreamV2Cutoff) {
            // old DWH
            const endDate = dashboardState.endDate > liveStreamV2Cutoff ? liveStreamV2Cutoff : dashboardState.endDate;
            next(dataWarehouseActions.fetchStreamsFilteredByList(dashboardState.startDate, endDate));
          }
        }
        return null;
      };
    };

export default (dashboardDataLoadMiddleware as Middleware);
