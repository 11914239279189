import React from 'react';
import { formatDateAtTimeFromUnix, secondsToMilliseconds } from 'utils/date-time-utils';
import classes from './MaintenanceTabComponent.scss';
import Icon from 'components/Common/Icon';

interface Props {
  icon: JSX.Element;
  label: string;
  isOk: boolean;
  timestamp: number;
  errorTitle: string;
  errorDescription: string;
}

const MonitoringRow = (props: Props) => {
  const { icon, label, isOk, timestamp, errorTitle, errorDescription } = props;
  return (
    <tr>
      <td className={classes.itemContainer}>
        <div className={classes.itemIconStyle}>
          {icon}
        </div>
        <div data-test="monitoringLabel">
          {label}
        </div>
      </td>
      <td className={`${classes.statusIconContainer} ${isOk ? classes.green : classes.orange}`}>
        {isOk ? <Icon classes={['icon-check-on']}/> : <Icon classes={['icon-status-alert']}/>}
      </td>
      {isOk ? (
        <td colSpan={2} />
      ) : [
        <td key="date" className={classes.heading}>
          {formatDateAtTimeFromUnix(secondsToMilliseconds(timestamp))}
        </td>,
        (
          <td key="details">
            <div className={classes.heading}>{errorTitle}</div>
            <div className={classes.detailsDescription}>{errorDescription}</div>
          </td>
        )
      ]}
    </tr>
  );
};

export default MonitoringRow;
