import React from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as modalFormStyles from 'components/Modals/ModalForms.scss';
import * as styles from 'components/Settings/AccountDetailsTab/RequestMergeAccountsConfirmationModal/RequestMergeAccountsConfirmationModal.scss';
import { ConfirmationObject } from '../RequestMergeAccounts';

type Props = {
  confirm: ConfirmationObject;
  onSend: (confirm: ConfirmationObject) => void;
  onClose: (confirm: ConfirmationObject) => void;
};

export default function MergeAccountsConfirmationModal(props: Props) {
  const { confirm, onClose, onSend } = props;
  return (
    <Modal
      className={modalFormStyles.containerSmall}
      closeModal={() => onClose(confirm)}
      closeText={intl.get('modalCancel')}
      onSave={() => onSend(confirm)}
      saveText={intl.get('modalSend')}
      titleText={intl.get('mergeAccounts')}
    >
      <div className={styles.modalContainer}>
        <div className={styles.description}>
          {intl.get('mergeAccountsConfirmationSendRequestTo')}
        </div>
        <div className={styles.emailContainer}>
          {confirm.email}
        </div>
        <div className={styles.description}>
          {intl.get('mergeAccountsConfirmationIfAccepted')}
        </div>
        <ul className={styles.list}>
          <li>{intl.get('mergeAccountsConfirmationYouAndUserWillBeMerged')}</li>
          <li>{intl.get('mergeAccountsConfirmationLossOfAdminPriv')}</li>
        </ul>
      </div>
    </Modal>
  );
}
