import React from 'react';
import intl from 'react-intl-universal';
import * as styles from './DateRangePresetsPanel.scss';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import { NEW_DASHBOARD_ROUTE } from 'constants/dashboard';

const DateRangePresetsPanel = (props: RouteComponentProps<{}>) => {
  return (
    <div className={styles.dateRangePicker}>
      <div className={[styles.dateRangePicker, styles.ranges].join(' ')}>
        <ul>
          <li
            id="after"
            onClick={() => props.history.push(NEW_DASHBOARD_ROUTE)}
          >
            {intl.get('afterMay2019')}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(DateRangePresetsPanel);
