import Icon from 'components/Common/Icon';
import React, { ComponentClass, FunctionComponent, ReactElement, SFC } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import actions from '../../../actions/modalActions';
import styles from './SimpleModal.scss';

export interface SimpleModalProps {
  closeModal: () => void;
  onClose?: () => void;
  // tslint:disable-next-line
  component?: ComponentClass<any> | FunctionComponent<any>;
  // tslint:disable-next-line
  renderAs?: (props: SimpleModalProps) => ReactElement<any>;
  // tslint:disable-next-line
  children: ReactElement<any>;
  titleText?: string;
  contentClassName?: string;
}

export interface ConnectedModalProps {
  closeModal?: () => void;
  onClose?: () => void;
  // tslint:disable-next-line
  component?: ComponentClass<any> | SFC<any>;
  // tslint:disable-next-line
  renderAs?: (props: SimpleModalProps) => ReactElement<any>;
  // tslint:disable-next-line
  children?: ReactElement<any>;
  titleText?: string;
  contentClassName?: string;
}

class SimpleModalBase extends React.Component<SimpleModalProps> {

  onClose = () => {
    const { onClose, closeModal } = this.props;
    if (onClose) {
      onClose();
    }

    if (closeModal) {
      closeModal();
    }
  }

  renderComponent() {
    const { component, children, renderAs, ...rest } = this.props;
    if (component) {
      const Component = component;
      return <Component {...rest} />;
    } else if (renderAs) {
      return renderAs(this.props);
    } else if (children) {
      return children;
    }
    // Should never get here.
    throw new Error('Render component in Modal did not have child content to render.');
  }

  render() {
    const { titleText, contentClassName } = this.props;
    return (
      <div className={styles.container}>
        <div className={contentClassName || styles.content}>
          <button
            id="closeButton"
            className={styles.closeButton}
            name="btnClose"
            onClick={this.onClose}
            role="button"
            type="button"
          >
            <Icon classes={['icon-cancel']}/>
          </button>
          {titleText && <div className={styles.title}>{titleText}</div>}
          {this.renderComponent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
});

const SimpleModal = compose<SimpleModalProps, ConnectedModalProps>(
  connect(null, mapDispatchToProps)
)(SimpleModalBase);

export { SimpleModal };
