import { createAction } from '@lifesize/typescript-react-redux-utils';

export enum UserProfileActionType {
  FETCH_USER_PROFILE = 'FETCH_USER_PROFILE',
  FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE',
  FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS'
}

export const actions = {
  fetchUserProfile: (payload: object) =>
    createAction(UserProfileActionType.FETCH_USER_PROFILE, payload),
  fetchUserProfileSuccess: (payload: object) =>
    createAction(UserProfileActionType.FETCH_USER_PROFILE_SUCCESS, payload),
  fetchUserProfileFailure: (error: object) =>
    createAction(UserProfileActionType.FETCH_USER_PROFILE_FAILURE, error)
};

export default actions;
