import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import deviceManagementActions from 'actions/deviceManagementActions';
import {
  Actions,
  deviceManagementEndpoint,
  deviceManagementRebootUri,
  deviceManagementResetUri,
} from 'constants/deviceManagementConstants';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import { Logger } from 'logger';
import { dispatchProxyApiCall } from 'utils/nucleusProxyUtils';
import { Methods } from 'interfaces/HTTP';
import { nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';

const deviceManagementMiddleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => async (action: PayloadAction<string, string>) => {
  const result = next(action);
  if (action.type === Actions.REBOOT_DEVICE) {
    try {
      const serialNumber = action.payload;
      let inputParams = {serialNumber};
      await dispatchProxyApiCall(
        store.dispatch,
        {},
        inputParams,
        deviceManagementEndpoint,
        deviceManagementRebootUri,
        Methods.POST,
        () => store.dispatch(deviceManagementActions.rebootDeviceSuccess(serialNumber.toString())),
        () => store.dispatch(deviceManagementActions.rebootDeviceFailure(serialNumber.toString()))
      );
    } catch (e) {
      Logger.info(`Error issuing reboot command:  ${e.toString()}`);
    }
  } else if (action.type === Actions.RESET_DEVICE) {
    try {
      const serialNumber = action.payload;
      let inputParams = {serialNumber};
      await dispatchProxyApiCall(
        store.dispatch,
        {},
        inputParams,
        deviceManagementEndpoint,
        deviceManagementResetUri,
        Methods.POST,
        () => store.dispatch(deviceManagementActions.resetDeviceSuccess(serialNumber.toString())),
        () => store.dispatch(deviceManagementActions.resetDeviceFailure(serialNumber.toString()))
      );
    } catch (e) {
      Logger.info(`Error issuing reset command:  ${e.toString()}`);
    }
  } else if (action.type === nucleusConstants.WAMP_SUBSCRIPTION_DEVICE_EVENT) {
    const serialNumber = _get(action, 'payload.serialNumber');
    const status = _get(action, 'payload.status');
    const message = _get(action, 'payload.message');
    const lastCoroner = _get(action, 'payload.lastCoroner');
    const payload = {serialNumber, status, message, lastCoroner};

    store.dispatch(deviceManagementActions.deviceCoronerStatus(payload));
  }
  return result;
};

export default deviceManagementMiddleware as Middleware;
