import { meetingsActions, nucleusConstants } from '@lifesize/nucleus';
import { createAsyncThunk } from '@reduxjs/toolkit';
import listInactiveMeetingsQuery from 'queries/meetings/listInactiveMeetingsQuery.gql';
import _get from 'lodash/get';
import { getClient } from 'nucleus-apollo-client';
import { Dispatch } from 'redux';
import moment from 'moment';
import { Meeting } from 'typings/types';
import { actions as ModalActions } from './modalActions';
import { ModalTypes } from 'constants/modalConstants';
import { Logger } from 'logger';
import { GetItemsToBeDeletedPayload } from 'interfaces/Meeting';

const getMeetingSettings = createAsyncThunk(
  'getMeetingSettings',
  async (_, thunkAPI) => {
    await thunkAPI.dispatch(meetingsActions.resetMeetingErrorState());
    return thunkAPI.dispatch(meetingsActions.getMeetingSettings());
  }
);

const getItemsToBeDeleted = createAsyncThunk(
  'getItemsToBeDeleted',
  async (payload: GetItemsToBeDeletedPayload, thunkAPI) => {
    const meetingType = _get(payload, 'meetingType', nucleusConstants.MEETING_TYPE_VMR);
    if (![nucleusConstants.MEETING_TYPE_VMR, nucleusConstants.MEETING_TYPE_ONE_TIME].includes(meetingType)) {
      // If meeting type is not VMR or ONE TIME
      Logger.info(`Unknown meetingType when attempting to change max inactive days: ${meetingType}`);
      return;
    }
    const requestedNumberOfDays = _get(payload, 'requestedNumberOfDays', 0);
    if (requestedNumberOfDays === 0) {
      // Just save the change of days to expiration - no modal needed.
      return thunkAPI.dispatch(meetingType === nucleusConstants.MEETING_TYPE_VMR
        ? meetingsActions.putMeetingsMaxInactiveDays(0)
        : meetingsActions.putMeetingsMaxInactiveDaysOTM(0)
      );
    }
    // If # of days > 0, check to see if any meetings need deletion
    const client = await getClient(window.store as Dispatch);
    const endDate = moment().subtract(requestedNumberOfDays, 'days');
    const response = await client.query({
      query: listInactiveMeetingsQuery,
      fetchPolicy: 'no-cache',
      // Queries from epoch 1/1/1970, to today minus new # of days
      variables: {endDate: endDate.format('YYYY-MM-DD'), sortField: 'displayName'}
    });
    const records = _get(response, 'data.directoryListMeetingsMultiField.records', []);
    const toBeDeleted = records.filter((o: Meeting) => {
      // For one-time meetings, we use createdAt otherwise lastUsedAt
      const compareDate = meetingType === nucleusConstants.MEETING_TYPE_ONE_TIME ? o.createdAt : o.lastUsedAt;
      return o.type === meetingType && compareDate !== null && endDate.isSameOrAfter(moment(compareDate));
    });

    if (toBeDeleted.length) {
      // If here - a list of meetings that will be deleted is returned
      return thunkAPI.dispatch(
        ModalActions.openModal({
          modalType: ModalTypes.MEETINGS_CONFIRM_EXPIRATION_DELETE,
          payload: { ...payload, itemsToBeDeleted: toBeDeleted }
        }),
      );
    }
    // If here, no modal needed, there are no meetings to be deleted
    // Just update the # of days before expiration
    return thunkAPI.dispatch(meetingType === nucleusConstants.MEETING_TYPE_VMR
      ? meetingsActions.putMeetingsMaxInactiveDays(requestedNumberOfDays)
      : meetingsActions.putMeetingsMaxInactiveDaysOTM(requestedNumberOfDays)
    );
  }
);

export const actions = {
  getMeetingSettings,
  getItemsToBeDeleted
};

export default actions;
