import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { dataWarehouseActions } from '@lifesize/nucleus';
import { selectStartDate, selectEndDate } from '../selectors/dashboardSelectors';
import { DRILLDOWN_FETCH_CALL_HISTORY } from '../actions/callHistoryActions';

const DrilldownCallHistoryMiddleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: Action) => {
  const state = store.getState();
  switch (action.type) {
    case DRILLDOWN_FETCH_CALL_HISTORY:
      return next(dataWarehouseActions.fetchConferenceData(selectStartDate(state), selectEndDate(state)));
    default:
      return next(action);
  }
};

export default (DrilldownCallHistoryMiddleware as Middleware);
