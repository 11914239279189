import { Middleware, MiddlewareAPI, Dispatch } from 'redux';
import { directoryActions, nucleusConstants } from '@lifesize/nucleus';
import actions from '../actions/searchActions';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils/src/createAction';
import { SEARCH_MEETINGS, DIRECTORY_SEARCH_RESULTS } from '../actions/searchActions';

const searchMeetingsMiddleware =
  (store: MiddlewareAPI) => {
    return (next: Dispatch) => {
      return (action: PayloadAction<string, object>) => {
        if (action.type === DIRECTORY_SEARCH_RESULTS) {
          store.dispatch(actions.searchComplete());
          return next(action);
        }
        if (action.type !== SEARCH_MEETINGS) {
          return next(action);
        }
        store.dispatch(actions.searchStart());
        return store.dispatch(directoryActions.search(action.payload || '', [nucleusConstants.CONTACT_TYPE_MEETING]));
      };
    };
  };

export default (searchMeetingsMiddleware as Middleware);
