import ConnectPlusGateway from 'components/Settings/ConnectPlusTab/ConnectPlusGateway';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import {
    connectPlusActions,
    connectPlusGatewaySelectors,
} from '@lifesize/nucleus';

const mapDispatchToProps = (dispatch: Function) => ({
    fetchData: () => 
        Promise.all([dispatch(connectPlusActions.getConnectPlusGatewaySettings())]),
    updateConnectPlusSettings: async (newSettings: object, callback: Function) => {
        await dispatch(connectPlusActions.setConnectPlusGatewaySettings(newSettings));
    }
});

const mapStateToProps = (state: Global) => {

    return {
        connectPlusSettings:  connectPlusGatewaySelectors.connectPlusGatewaySettings(state) || {},
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectPlusGateway);
