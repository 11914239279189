import { Role } from 'interfaces/Role';

export interface RoleState {
  roles: Role[];
  loading: boolean;
  error: boolean;
}

/**
 * Properties to determine password reset process state
 */
export const initialRoleState: RoleState = {
  roles: [],
  loading: false,
  error: false
};
