import { getLifesizeDomain, getDomain } from './url-utils';
import { PLAN_TYPE_FREEMIUM } from 'constants/ecommerceConstants';
import { checkHttpStatus } from './http-utils';

export const buildEcommerceUrl = (jwt?: string, extraParams?: object) => {
  const ecommerceUrl = new URL(`https://shop.${getLifesizeDomain()}`);
  if (jwt) {
    ecommerceUrl.searchParams.append('a', jwt);
  }
  for (const param in extraParams) {
    if (extraParams.hasOwnProperty(param)) {
      ecommerceUrl.searchParams.append(param, extraParams[param]);
    }
  }
  ecommerceUrl.searchParams.append('next', 'manage');

  return ecommerceUrl.toString();
};

const getEcommerceBackendUrlConstructor = (route: string, jwt?: string) => {
  const ecommerceBackendUrl = new URL(route, `https://ecommerce.${getDomain()}`);
  if (jwt) {
    ecommerceBackendUrl.searchParams.append('jwt', jwt);
  }

  return ecommerceBackendUrl;
};

export const getEcommerceBackendUrl = (route: string, jwt?: string) => {
  const ecommerceBackendUrl = getEcommerceBackendUrlConstructor(route, jwt);
  return ecommerceBackendUrl.toString();
};

export const handlePortalRedirect = async (jwt: string, newTab: boolean = true) => {
    const ecommercePortalUrl = getEcommerceBackendUrlConstructor('customer_portal', jwt);
    ecommercePortalUrl.searchParams.append('return_url', window.location.href);
    const ecommercePortalRequest = await fetch(ecommercePortalUrl.toString(), {
      credentials: 'include'
    });

    const ecommercePortalRedirectResponse: { redirectUrl: string } = await checkHttpStatus(ecommercePortalRequest).json();

    if (ecommercePortalRedirectResponse?.redirectUrl) {
      window.open(ecommercePortalRedirectResponse.redirectUrl, '_blank');
    } else {
      throw new Error('No redirect URL provided');
    }
};

export const mapEcommercePlanType = (plan: string) => {
  return (plan === PLAN_TYPE_FREEMIUM ? 'FREE' : plan);
};
