import { createSelector } from 'reselect';
import { Global } from '../state/Global';

/**
 * Selector to the search state
 */
const appMeetingsState = (state: Global) => state.app?.meetings;

const meetingsAndOwnersSelector = createSelector(
  appMeetingsState,
  (state) => {
    return state ? state.meetingsAndOwnersList : [];
  }
);

export {
  meetingsAndOwnersSelector
};
