import React from 'react';
import intl from 'react-intl-universal';
import styles from './MeetingParticipantProperties.scss';
import _get from 'lodash/get';
import { MeetingParticipantDetails } from 'interfaces/MeetingParticipant';

interface Props {
  meetingParticipant: MeetingParticipantDetails;
}

const MeetingParticipantProperties = (props: Props) => {
  const { meetingParticipant } = props;
  const {
    meetingExtension = '',
    meetingName = '',
    location = '',
    endpointIdentity
  } = meetingParticipant;

  const browser = _get(endpointIdentity, 'platform.name', '');
  const browserVersion = _get(endpointIdentity, 'platform.version', '');
  const operatingSystem = _get(endpointIdentity, 'platform.operatingSystem', '');
  let device = _get(endpointIdentity, 'product.name', '');
  const deviceVersion = _get(endpointIdentity, 'product.version', '');
  if (device.length) {
    device = intl.get(device.split('.')[0].toLowerCase()) || device;
  }

  return (
    <div className={styles.flexCol}>
      <div className={styles.summaryColumn}>
        <dl>
          <dt>{intl.get('meetingLabel')}</dt>
          <dd>{meetingName}</dd>
        </dl>
        {meetingExtension && <dl>
          <dt>{intl.get('meetingExtensionLabel')}</dt>
          <dd>{meetingExtension}</dd>
        </dl>}
      </div>
      <div className={styles.summaryColumn}>
        <dl>
          <dt>{intl.get('locationWithColon')}</dt>
          <dd>{location}</dd>
        </dl>
        <dl>
          <dt>{intl.get('operatingSystemWithColon')}</dt>
          <dd>{operatingSystem}</dd>
        </dl>
      </div>
      <div className={styles.summaryColumn}>
        <dl>
          <dt>{intl.get('browserLabel')}</dt>
          <dd>{browser} {browserVersion}</dd>
        </dl>
        <dl>
          <dt>{intl.get('deviceWithColon')}</dt>
          <dd>{device} {deviceVersion && `(${deviceVersion})`}</dd>
        </dl>
      </div>
    </div>
  );
};

export { MeetingParticipantProperties };
