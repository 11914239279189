import {
  IS_CUSTOM_ICON_WALLPAPER_ENABLED,
  SET_URL,
  SET_MD5,
  ON_DROP,
  TYPE_ERROR,
  SIZE_ERROR,
  SET_FILENAME,
  DIMENSION_ERROR,
} from '../constants/wallpaperConstants';

export interface FileTypeErrorAction {
  type: TYPE_ERROR;
  payload: boolean;
}

export function setIsCustomWallPaperEnabled(payload: boolean) {
  return {
    type: IS_CUSTOM_ICON_WALLPAPER_ENABLED,
    payload: payload,
  };
}

export function setFileDimensionError(payload: boolean): FileTypeErrorAction {
  return {
    type: DIMENSION_ERROR,
    payload: payload,
  };
}

export function setFileSizeError(payload: boolean): FileTypeErrorAction {
  return {
    type: SIZE_ERROR,
    payload: payload,
  };
}

export function onDrop(
  accepted: File[],
  rejected: File[],
  event: React.DragEvent<HTMLDivElement>
) {
  return {
    type: ON_DROP,
    payload: accepted,
  };
}

export function setURL(payload: string) {
  return {
    type: SET_URL,
    payload: payload,
  };
}

export function setMD5(payload: string) {
  return {
    type: SET_MD5,
    payload: payload,
  };
}

export function setFileTypeError(payload: boolean) {
  return {
    type: TYPE_ERROR,
    payload: payload,
  };
}

export function setFilename(payload: string) {
  return {
    type: SET_FILENAME,
    payload: payload,
  };
}

export function clearErrors() {
  return (dispatch: Function) => {
    dispatch(setFileTypeError(false));
    dispatch(setFileSizeError(false));
    dispatch(setFileDimensionError(false));
  };
}
