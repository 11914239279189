import * as styles from 'components/Modals/MaxInactiveDaysExpirationModal/MaxInactiveDaysExpirationModal.scss';
import _get from 'lodash/get';
import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import deleteMeetingsMutation from 'queries/meetings/deleteMeetingsMutation.gql';
import listMeetingsQuery from 'queries/meetings/listMeetingsQuery.gql';
import { ListAllMeetingsForCountQuery, Meeting } from 'typings/types';
import ListViewComponent from '../../ListView/ListViewComponent';
import Modal from '../../Modal/ModalComponent/ModalComponent';
import MeetingInlineFragment = ListAllMeetingsForCountQuery.MeetingInlineFragment;

interface Props {
  maxInactiveDays: number | undefined;
  meetingType: string | undefined;
  requestedNumberOfDays: number;
  cancelAction: () => void;
  // Payload arg is an object to allow additional info to be added
  confirmAction: (payload?: object) => void;
  itemsToBeDeleted?: Array<MeetingInlineFragment>;
  errorMessage?: string;
  isBusy: boolean;
  closeModal: () => void;
}

interface State {
  deleteError?: string;
  isDeleteBusy: boolean;
}

export class MaxInactiveDaysExpirationModal extends Component<Props, State> {
  readonly state: State = {
    deleteError: undefined,
    isDeleteBusy: false
  };

  render() {
    const {
      itemsToBeDeleted,
      isBusy,
      requestedNumberOfDays,
      confirmAction,
      cancelAction,
      errorMessage,
      closeModal
    } = this.props;
    const {deleteError, isDeleteBusy} = this.state;
    if (itemsToBeDeleted && (itemsToBeDeleted.length === 0)) {
      // No items to be deleted. Update # of days immediately
      confirmAction({ requestedNumberOfDays: requestedNumberOfDays });
      return null;
    }
    return (
      <Mutation
        mutation={deleteMeetingsMutation}
        update={(cache, { data: { deleteListOfMeetings } }) => {
          // tslint:disable-next-line
          const response: any = cache.readQuery({
            query: listMeetingsQuery
          });
          const directoryList = response.directoryList;
          directoryList.records = directoryList.records.filter((r: Meeting) => {
            return !deleteListOfMeetings.find((uuid: string) => {
              return uuid === r.UUID;
            });
          });

          cache.writeQuery({
            query: listMeetingsQuery,
            data: { directoryList }
          });
        }}
      >
        {(deleteMeetings) => (
          <Modal
            className={styles.container}
            onSave={
              async () => {
                this.setState({'deleteError': undefined, 'isDeleteBusy' : true} );
                // @ts-ignore
                const uuidMap = itemsToBeDeleted.map((item) => {
                  return _get(item, 'UUID');
                });
                try {
                  if (uuidMap.length) {
                    await deleteMeetings({
                      variables: {
                        uuids: uuidMap
                      }
                    });
                    // Now update # of days and close modal in container handler...
                    confirmAction({ requestedNumberOfDays: requestedNumberOfDays });
                  }
                } catch (e) {
                  this.setState({'deleteError': intl.get('deleteInactiveMeetingsFailed'), 'isDeleteBusy' : false} );
                }
              }
            }
            onCancel={() => cancelAction()}
            saveText={intl.get('usersButtonLabelDelete')}
            closeText={intl.get('cancel')}
            closeModal={closeModal}
            isSaveButtonBusy={isBusy || isDeleteBusy}
            isSaveButtonDisabled={isBusy || isDeleteBusy}
            hideSaveButton={false}
            errorMessage={errorMessage || deleteError}
          >

            <>
              <div className={styles.header}>{intl.get('inactiveMeetings')}</div>

              <div className={styles.body}>

                {itemsToBeDeleted && itemsToBeDeleted.length > 0 &&
                <>
                  <div className={styles.subTitle}>
                    {itemsToBeDeleted.length > 1 ? intl.get('inactiveMeetingsToBeRemoved', { count: itemsToBeDeleted.length }) : intl.get('inactiveMeetingToBeRemoved')}
                  </div>
                  <div className={styles.list}>
                    <ListViewComponent
                      data={itemsToBeDeleted}
                      showAllResults={true}
                      columns={
                        [{
                          Header: intl.get('meetingName'),
                          accessor: 'displayName'
                        }, {
                          Header: intl.get('owner'),
                          id: 'owner',
                          Cell: (row: RowInfo) => {
                            return _get(row.original, 'owner.displayName', '');
                          },
                        }]
                      }
                      showPagination={false}
                      minRows={1}
                      pageNumber={0}
                    />
                  </div>
                </>
                }

                <div className={styles.message}>{intl.getHTML('inactiveMeetingsCancelInstructions')}</div>
                <div className={styles.message}>{intl.getHTML('inactiveMeetingsDeleteInstructions')}</div>

              </div>
            </>
          </Modal>)}
      </Mutation>
    );
  }

}

export default MaxInactiveDaysExpirationModal;
