import { RecordingsActionType } from 'actions/recordingsActions';
import { Reducer } from 'redux';
import { initialSingleRecordingState } from 'state/SingleRecording';
import SingleRecordingInterface from 'interfaces/SingleRecording';

const SingleRecordingsReducer: Reducer<SingleRecordingInterface> =
  (state = initialSingleRecordingState, action) => {
    if (action.type === RecordingsActionType.RECORDINGS_SET_SINGLE_ID) {
      return {
        ...state,
        recordingId: action.payload
      };
    }
    return state;
  };

export default SingleRecordingsReducer;
