import { ModalTypes } from 'constants/modalConstants';

export interface ModalState {
  modalType?: ModalTypes;
  payload?: object;
  pendingClosedModal: boolean;
}

export const initialModalState: ModalState = {
  modalType: undefined,
  payload: undefined,
  pendingClosedModal: false
};
