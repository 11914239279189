import { useDispatch } from 'react-redux';
import { setMeetingSettings } from 'thunks/groupSettingsThunks';
import _get from 'lodash/get';
import { useMount } from './useMount';

export function useSettingsChange() {
  const dispatch = useDispatch();
  const isMounted = useMount();

  return async (
    settingName: string,
    setState: Function,
    currentState: boolean,
    setSettingsChangeError: Function
  ) => {
    setState(!currentState);
    const result = await dispatch(setMeetingSettings({ [settingName]: !currentState }));
    if (isMounted.current) {
      if (_get(result, 'type') !== setMeetingSettings.fulfilled.type) {
        setSettingsChangeError(true);
        setState(currentState);
      } else {
        setSettingsChangeError(false);
      }
    }
  };
}

export default useSettingsChange;
