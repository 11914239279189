/*
Alternative to loading the entire moment library
 */

import _memoize from 'lodash/memoize';
import moment, { Moment } from 'moment';
import intl from 'react-intl-universal';
import { DateRangePreset } from '@lifesize/react-components';

// Function used by date range picker to determine what dates are not allowed
export function isOutsideRange(day: Moment) {
  return day.isBefore(moment('2019-06-01')) || day.isAfter(moment().endOf('day'));
}

export function getDateRangePresets(): DateRangePreset[] {
  return [
    {
      id: 'today',
      label: intl.get('today'),
      start: moment(),
      end: moment(),
    }, {
      id: 'yesterday',
      label: intl.get('yesterday'),
      start: moment().subtract(1, 'days'),
      end: moment().subtract(1, 'days'),
    }, {
      id: 'last_7',
      label: intl.get('last_7_days'),
      start: moment().subtract(6, 'days').endOf('day'),
      end: moment().startOf('day'),
    }, {
      id: 'last_30',
      label: intl.get('last_30_days'),
      start: moment().subtract(29, 'days').endOf('day'),
      end: moment().startOf('day'),
    }, {
      id: 'this_month',
      label: intl.get('month'),
      start: moment().startOf('month').endOf('day'),
      end: moment().startOf('day'),
    }, {
      id: 'last_month',
      label: intl.get('last_month'),
      start: moment().subtract(1, 'months').startOf('month').endOf('day'),
      end: moment().subtract(1, 'months').endOf('month').startOf('day'),
    }
  ];
}

export function getRecordingDateRangePresets(clickHandler: Function): DateRangePreset[] {
  return [
    {
      id: 'today',
      label: intl.get('today'),
      start: moment(),
      end: moment(),
    }, {
      id: 'last_7',
      label: intl.get('last_7_days'),
      start: moment().subtract(6, 'days').endOf('day'),
      end: moment().startOf('day'),
    }, {
      id: 'last_30',
      label: intl.get('last_30_days'),
      start: moment().subtract(29, 'days').endOf('day'),
      end: moment().startOf('day'),
    }, {
      id: 'this_month',
      label: intl.get('month'),
      start: moment().startOf('month').endOf('day'),
      end: moment().startOf('day'),
    }, {
      id: 'last_month',
      label: intl.get('last_month'),
      start: moment().subtract(1, 'months').startOf('month').endOf('day'),
      end: moment().subtract(1, 'months').endOf('month').startOf('day'),
    }, {
      id: 'all',
      label: intl.get('all'),
      onClick: clickHandler
    }
  ];
}

export function getNewDWHDateRangePresets(clickHandler: Function, removeOldDashboard: boolean): DateRangePreset[] {
  if (removeOldDashboard) {
    return getDateRangePresets();
  }

  return getDateRangePresets().concat([{
    id: 'oldDWH',
    label: intl.get('beforeJune2019'),
    // @ts-ignore
    onClick: clickHandler
  }]);
}

/**
 * Equivalent output to moment().format('YYYY-MM-DD HH:mm:ss')
 * 2018-03-02 15:01:03
 * @return {string}
 */
export function getNowFormattedString(): string {
  const dt = new Date();
  return (`${dt.getFullYear()}-${(dt.getMonth() + 1)}-${dt.getDate()} ` +
    `${dt.getUTCHours()}:${dt.getUTCMinutes()}:${dt.getUTCSeconds()}`);
}

export function secondsToHms(d: number) {
  d = Number(d);
  const h = `0${Math.floor(d / 3600)}`.slice(-2);
  const m = `0${Math.floor(d % 3600 / 60)}`.slice(-2);
  const s = `0${Math.floor(d % 3600 % 60)}`.slice(-2);
  return `${h}:${m}:${s}`;
}

export function secondsToMilliseconds(s: number) {
  return s * 1000;
}

/**
 * Formats seconds to '1 hrs 4 min 29 sec' format
 * Drops hours and minutes if less than an hour or minute (respectively)
 * @param {number} seconds
 */
export const formatSecondsForDisplay = _memoize(
  (seconds: number) => {
    const minutesUtc = moment.utc(seconds * 1000).format('m');
    const secondsUtc = moment.utc(seconds * 1000).format('s');
    if (seconds < 60) {
      return intl.get('secondsWithLabel', { secs: secondsUtc });
    } else if (seconds < 3600) {
      return intl.get('minsSecondsWithLabels', { mins: minutesUtc, secs: secondsUtc });
    } else {
      const hoursUtc = moment.utc(seconds * 1000).format('h');
      return intl.get('hoursMinsSecondsWithLabels', { hours: hoursUtc, mins: minutesUtc, secs: secondsUtc });
    }
  },
  (...args) => {
    // Key for memoization
    return JSON.stringify(args);
  });

export const formatSecondsForExport = _memoize(
  (seconds: number) => {
    return moment.utc(seconds * 60).format('m');
  },
  (...args) => {
    // Key for memoization
    return JSON.stringify(args);
  });

/**
 * Formats minutes to '1 hrs 4 min' format
 * Drops hours if less than an hour
 * @param {number} minutes
 */
export const formatMinutesForDisplay = _memoize(
  (minutes: number) => {
    if (minutes < 60) {
      return intl.get('minutesWithLabel', { mins: minutes });
    } else {
      const hoursFormatted = Math.floor(moment.duration(minutes, 'minutes').asHours());
      const minutesFormatted = moment().minute(minutes % 60).format('m');
      if (hoursFormatted === 1) {
        return intl.get('hourMinsWithLabels', { hours: hoursFormatted, min: minutesFormatted });
      }
      return intl.get('hoursMinsWithLabels', { hours: hoursFormatted, min: minutesFormatted });
    }
  },
  (...args) => {
    // Key for memoization
    return JSON.stringify(args);
  });

/**
 * Formats minutes to plain number format
 * Drops hours if less than an hour
 * @param {number} minutes
 */
export const formatMinutesForExport = _memoize(
  (minutes: number) => {
    return moment.utc(minutes * 60 * 1000).format('m');
  },
  (...args) => {
    // Key for memoization
    return JSON.stringify(args);
  });

/**
 * Returns string in '4/12/2018 at 12:36 PM' format (notice the 'at')
 */
export const formatStringDateAtTimeForDisplay = _memoize((datetime: string): string => {
  if (datetime && datetime.length) {
    const date = intl.get('doNotTranslateDate', { dateObj: moment(datetime).toDate() });
    const dateToday = intl.get('doNotTranslateDate', { dateObj: moment().toDate() });
    const time = intl.get('doNotTranslateTimeShort', { timeObj: moment(datetime).toDate() });
    return (dateToday === date
      ? intl.get('todayAtTime', { time: time })
      : intl.get('dateAtTime', { date: date, time: time })
    );
  }
  return '';
});

/**
 * Returns string in '4/12/2018 at 12:36 PM' format (notice the 'at')
 */
export const formatDateAtTimeFromUnix = _memoize((unixTime: number): string => {
  if (unixTime) {
    const momentDateObj = moment(unixTime).toDate();
    const date = intl.get('doNotTranslateDate', { dateObj: momentDateObj });
    const dateToday = intl.get('doNotTranslateDate', { dateObj: moment().toDate() });
    const time = intl.get('doNotTranslateTimeShort', { timeObj: momentDateObj });
    return (dateToday === date
      ? intl.get('todayAtTime', { time: time })
      : intl.get('dateAtTime', { date: date, time: time })
    );
  }
  return '';
});

/**
 * Returns string in '4/12/2018 12:36 PM' format (notice no 'at')
 */
export const formatDateTimeFromUnix = _memoize((unixTime: number): string => {
  if (unixTime) {
    const momentDateObj = moment(unixTime).toDate();
    const date = intl.get('doNotTranslateDate', { dateObj: momentDateObj });
    const dateToday = intl.get('doNotTranslateDate', { dateObj: moment().toDate() });
    const time = intl.get('doNotTranslateTimeShort', { timeObj: momentDateObj });
    return (dateToday === date
        ? time
        : `${date} ${time}`
    );
  }
  return '';
});

/**
 * Returns string in '4/12/2018 12:36 PM' format (notice NO 'at')
 */
export const formatStringDateTimeForDisplay = _memoize((datetime: string): string => {
  if (datetime && datetime.length) {
    const date = intl.get('doNotTranslateDate', { dateObj: moment(datetime).toDate() });
    const time = intl.get('doNotTranslateTimeShort', { timeObj: moment(datetime).toDate() });
    return `${date} ${time}`;
  }
  return '';
});

/**
 * Returns string in '12:36 PM' format
 * @param {number} unixTime - seconds from epoch
 */
export const formatUnixTimeOfDayForDisplay = _memoize((unixTime: number): string => {
  return intl.get('doNotTranslateTimeShort', { timeObj: moment(unixTime).toDate() });
});
/**
 * Returns string in '4/12/2018' format
 * @param {number} unixTime - seconds from epoch
 */
export const formatUnixDateForDisplay = _memoize((unixTime: number): string => {
  return intl.get('doNotTranslateDate', { dateObj: moment(unixTime).toDate() });
});

/**
 * Returns string in 'April 19, 2017' format
 */
export const formatDateForLongDisplay = _memoize((datetime: string): string => {
  if (datetime && datetime.length) {
    const date = intl.get('doNotTranslateDateLong', { dateObj: moment(datetime).toDate() });
    return `${date}`;
  }
  return '';
});

/**
 * Returns string in '4/12/2018 12:36 PM' format (notice NO 'at')
 * @param {number} unixTime - seconds from epoch
 */
export const formatUnixDateTimeForDisplay = _memoize((unixTime: number): string => {
  return formatStringDateTimeForDisplay(moment(unixTime).toISOString());
});

export const formatUnixTimeStampForDisplay = _memoize((unixTime: number): string => {
  return formatStringDateTimeForDisplay(moment.unix(unixTime).toISOString());
});

/**
 * Returns string in '4/12/2018 at 12:36 PM' format (notice the 'at')
 * @param {number} unixTime - seconds from epoch
 */
export const formatUnixDateAtTimeForDisplay = _memoize((unixTime: number | undefined): string => {
  return formatStringDateAtTimeForDisplay(moment(unixTime).toISOString());
});

export const formatUnixDateLongDisplay = _memoize((unixTime: number | undefined): string => {
  return unixTime ? formatDateForLongDisplay(moment(unixTime).toISOString()) : '';
});

export const formatIsoUtcDateTimeForDisplayDate = _memoize((IsoUtcDateTime: string | undefined): string => {
  const date = (IsoUtcDateTime
    ? intl.get('doNotTranslateDate', { dateObj: moment(IsoUtcDateTime).toDate() })
    : ''
  );

  return date;
});

/**
 * formatElapsedTime
 * Formats time to hh:mm:SS
 * @param {int} secondsElapsed
 */
export const formatElapsedTime = (secondsElapsed: number = 0) => {
  const hours = Math.floor(secondsElapsed / 3600);
  const minutes = Math.floor((secondsElapsed - (hours * 3600)) / 60);
  const seconds = secondsElapsed % 60;

  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

/**
 * Compare two dates (Moment) - Ignores HH:MM:SS
 */
export const areDatesEqual = (firstMoment: Moment, secondMoment: Moment): boolean => {
  return firstMoment.isSame(secondMoment, 'day')
    && firstMoment.isSame(secondMoment, 'month')
    && firstMoment.isSame(secondMoment, 'year');
};

/**
 * Returns translated text for hours and minutes
 * Will drop any that are zero (0)
 * Examples of return values:
 * '2 hrs 23 mins'
 * '5 hrs'
 * '12 mins'
 */
export const getFormattedTimeText = _memoize(
  (hours: number = 0, minutes: number = 0) => {
    if (hours && minutes) {
      return intl.get('hoursMinutesTooltip', {
        minutes: minutes,
        hours: hours
      });
    } else if (hours && !minutes) {
      return intl.get('hoursTooltip', {
        hours: hours
      });
    } else if (!hours && minutes) {
      return intl.get('minutesTooltip', {
        minutes: minutes
      });
    } else {
      return intl.get('minutesTooltip', {
        minutes: 0
      });
    }
  },
  (...args) => {
    // Key for memoization
    return JSON.stringify(args);
  });

/**
 * Sets hours, mins, seconds to midnight of today.
 * Used for starting date of date range
 * @param date
 */
export const momentToStartOfDay = (date: Moment) => {
  // return date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return date.startOf('day');
};

/**
 * Sets hours, mins, seconds to 1ms before midnight of next day
 * Used for ending date of date range
 * @param date
 */
export const momentToEndOfDay = (date: Moment) => {
  return date.endOf('day');
};

/**
 * Defaults to today's date, and sets moment start time to midnight
 * @param date
 * @param daysToAddOrSubtract - if you need to adjust from today
 */
export const getStartDate = (date: Moment, daysToAddOrSubtract: number = 0) => {
  if (daysToAddOrSubtract > 0) {
    return momentToStartOfDay(date.add(daysToAddOrSubtract, 'day'));
  }
  if (daysToAddOrSubtract < 0) {
    return momentToStartOfDay(date.subtract(Math.abs(daysToAddOrSubtract), 'day'));
  }
  return momentToStartOfDay(date);
};

/**
 * Defaults to today's date, and sets moment to end of day
 * @param date
 * @param daysToAddOrSubtract - if you need to adjust from today
 */
export const getEndDate = (date: Moment, daysToAddOrSubtract: number = 0) => {
  if (daysToAddOrSubtract > 0) {
    return momentToEndOfDay(date.add(daysToAddOrSubtract, 'day'));
  }
  if (daysToAddOrSubtract < 0) {
    return momentToEndOfDay(date.subtract(Math.abs(daysToAddOrSubtract), 'day'));
  }
  return momentToEndOfDay(date);
};
