import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const exportToCsvState = (state: Global) => state.app?.exportToCsv;

const selectExportState = createSelector(
  exportToCsvState,
  (csvState) => csvState
);

export {
  selectExportState
};
