import { Link } from 'react-router-dom';
import React from 'react';
import { BreadcrumbInterface } from 'interfaces/Breadcrumb';

export const getBreadcrumbs = (links: Array<BreadcrumbInterface>) => {
  const breadCrumbs: Array<JSX.Element> = [];
  links.forEach((link, index) => {
    if (link.url) {
      breadCrumbs.push(<Link to={link.url} key={`breadcrumb${index}`}>{link.label}</Link>);
    } else if (link.to) {
      breadCrumbs.push(<Link to={link.to} key={`breadcrumb${index}`}>{link.label}</Link>);
    } else {
      breadCrumbs.push(<span key={`title${index}`}>{link.label}</span>);
    }
    if (index !== links.length - 1) {
      breadCrumbs.push(<span className="arrow" key={`arrow${index}`}>&gt;</span>, );
    }
  });
  return breadCrumbs;
};
