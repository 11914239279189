import classnames from 'classnames';
import { RecordingRequestObjectSort } from 'constants/recordingsConstants';
import Search from 'containers/SearchCloudMeetings/SearchCloudMeetingsContainer';
import React from 'react';
import intl from 'react-intl-universal';
import { Button, Dropdown } from 'semantic-ui-react';
import * as styles from './RecordingsToolbar.scss';
import Icon from 'components/Common/Icon';
import { RecordingRequestObjectFeed } from 'constants/recordingsConstants';
import { recordingsSelectors as nucleusRecordingsSelectors } from '@lifesize/nucleus';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ModalActions } from 'actions/modalActions';
import { ModalTypes } from 'constants/modalConstants';
import { RecordingsDateRangePicker } from './RecordingsDateRangePicker';
import ExportButton from 'components/Common/ExportButton';
import useExport from 'hooks/useExport';
import { ExportType } from 'interfaces/Downloads';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_EXPORT_RECORDING } from 'constants/featureFlags';
import { nucleusConstants } from '@lifesize/nucleus';
export interface OwnPropsInterface {
  pageType: RecordingRequestObjectFeed;
}

export interface Props {
  handleSearchSelect: (id?: string, name?: string) => void;
  handleSortChange: (sortType: string) => void;
  initialSort: string;
  meetingId: string | undefined;
  meetingName: string | undefined;
  canUpload: boolean;
  uploadStatus: JSX.Element | null;
  currentUserRole: string;
}
const RecordingsToolbar = (props: Props & OwnPropsInterface) => {
  const {
    initialSort,
    meetingId,
    meetingName,
    pageType,
    canUpload,
    uploadStatus,
    handleSearchSelect,
    handleSortChange,
    currentUserRole
  } = props;

  const recordingsFeedState = useSelector(nucleusRecordingsSelectors.recordingsFeed);
  const canUserModifyRecording = recordingsFeedState?.selected_meeting?.can_edit;
  const isLoading = recordingsFeedState?.isFetching as boolean;

  const { handleExportData, loading } = useExport(ExportType.RECORDING, currentUserRole);

  const dispatch = useDispatch();

  const openUpload = () => {
    dispatch(ModalActions.openModal({ modalType: ModalTypes.UPLOAD_RECORDING }));
  };

  const openMeetingShareModal = (payload: Object) => {
    dispatch(ModalActions.openModal({modalType: ModalTypes.MEETING_SHARE_RECORDING_MODAL, payload}));
  };

  const hasExportAccess = (userType: string) => {
    if (
      userType &&
      (userType === nucleusConstants.USER_ROLE_ADMIN ||
        userType === nucleusConstants.USER_ROLE_SUPER_USER)
     ) {
      return true;
    }
    return false;
  };

  const canExportRecording = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_EXPORT_RECORDING);

  const sortOptions = [
    {
      text: intl.get('date'),
      value: RecordingRequestObjectSort.SORT_DATE,
    },
    {
      text: intl.get('views'),
      value: RecordingRequestObjectSort.SORT_VIEWS,
    }
  ];

  if (meetingId && isLoading && !meetingName) {
    // Wait until we found the meeting to render the search input
    return (
      <div className={classnames(styles.container, 'toolbar')} />
    );
  }

  return (
    <div className={classnames(styles.container, 'toolbar')}>
      <div className={styles.searchSection}>
        <Search
          onSearchSelectionMade={(id: string, title: string) => {
            handleSearchSelect(id, title);
          }}
          onSearchCleared={() => {
            handleSearchSelect();
          }}
          placeholderText={intl.get('searchPlaceholder')}
          noResultsText={intl.get('noResults')}
          defaultMeetingText={intl.get('defaultMeeting')}
          initialSelectionId={meetingId}
          initialSelectionText={meetingName}
          showResetButton={true}
          clearOnFocus={true}
        />
        {pageType === RecordingRequestObjectFeed.FEED_ALL && (
          <div>
            <button
              className={`${styles.iconButton} iconButton`}
              disabled={!meetingId || !canUserModifyRecording}
              name="btnShare"
              onClick={() => {
                // open share modal
                openMeetingShareModal({
                  meetingId,
                  meetingName
                });
              }}
              role="button"
              type="button"
            >
              <Icon classes={['icon-share-alt']} />
            </button>
          </div>
        )}
      </div>

      <div className={styles.toolSection}>
      {pageType === RecordingRequestObjectFeed.FEED_ALL && canExportRecording && hasExportAccess(currentUserRole) && (
        <ExportButton
          disabled={false}
          isExporting={loading}
          onExportClick={handleExportData}
        />
      )}
        {canUpload && (
          <div className={styles.buttonContainer}>
            <Button
              id="uploadRecording"
              name="uploadRecording"
              onClick={openUpload}
              role="button"
              type="button"
            >
              {intl.get('upload')}
            </Button>
            {uploadStatus}
          </div>
        )}
        <div className={styles.inputGroup}>
          <label>{`${intl.get('inputLabelSort')}:`}</label>
          <Dropdown
            data-test="sortDropdown"
            className={styles.input}
            closeOnBlur={true}
            defaultValue={initialSort}
            onChange={(e, args) => {
              handleSortChange(args.value as string);
            }}
            options={sortOptions}
            selection={true}
            selectOnBlur={false}
          />
        </div>
        {/*Hack to close the date range picker on "all" click*/}
        <RecordingsDateRangePicker />
      </div>
    </div>
  );
};

export default RecordingsToolbar;
