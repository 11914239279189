/**
 * Constants returned in accountSettings, but not defined in Nucleus
 */
export enum ACCOUNT_STATUS {
  PAID = 'PAID',
  TRIAL = 'TRIAL',
  FREEMIUM = 'FREEMIUM',
  EXPIRED = 'EXPIRED'
}

export enum ACCOUNT_TIER {
  ENTERPRISE = 'ENTERPRISE',
  TRIAL = 'TRIAL'
}

export enum ACTIVE_FEATURE_STATUS {
  PAID = 'PAID',
  TRIAL = 'TRIAL',
  ACTIVATED = 'ACTIVATED'
}

export const accountTier = [
  ACCOUNT_TIER.ENTERPRISE,
  ACCOUNT_TIER.TRIAL
];

export const activeFeatureStatus = [
  ACTIVE_FEATURE_STATUS.ACTIVATED,
  ACTIVE_FEATURE_STATUS.PAID,
  ACTIVE_FEATURE_STATUS.TRIAL
];

export const ACCOUNT_SETTING_RECORDING_ENABLED = 'recordingEnabled';
export const ACCOUNT_SETTING_ALLOW_PERMANENT_MEETINGS = 'allowPermanentMeetings';
export const ACCOUNT_SETTING_HIDE_ONE_TIME_MEETINGS = 'createOtmVmrHidden';
export const ACCOUNT_SETTING_HIDE_PERMANENT_MEETINGS = 'createPermVmrHidden';
export const ACCOUNT_SETTING_AUTO_RECORD = 'autoRecord';
