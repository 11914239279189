import { createAction } from '@lifesize/typescript-react-redux-utils';

export enum UserStateActionType {
  SET_ACTIVITY = 'SET_ACTIVITY',
}

export const actions = {
  setActivity: (payload: boolean) =>
    createAction(UserStateActionType.SET_ACTIVITY, payload)
};

export default actions;
