import React, { useState } from 'react';
import intl from 'react-intl-universal';
import * as styles from 'components/Settings/Sections.scss';
import * as meetingCreationStyles from './MeetingCreationSettings.scss';
import { useSelector } from 'react-redux';
import AllowPermanentMeetings from './AllowPermanentMeetings';
import DefaultHideOTMeetingsButton from './HideOneTimeMeetings';
import DefaultHidePermanentMeetingsButton from './HidePermanentMeetings';
import { ACCOUNT_FEATURE_ONE_TIME_MEETING } from 'constants/accountFeatureConstants';
import { accountSelectors, featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_DEFAULT_HIDE_MEETINGS } from 'constants/featureFlags';

const MeetingCreationSettings = () => {
  const [ settingsChangeError, setSettingsChangeError ] = useState(false);
  const oneTimeMeetingsEnabled = useSelector(accountSelectors.getAccountFeatures)[ACCOUNT_FEATURE_ONE_TIME_MEETING];
  const defaultHideMeetingsEnabled = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_DEFAULT_HIDE_MEETINGS);
  const showHideOneTimeMeetings = defaultHideMeetingsEnabled && oneTimeMeetingsEnabled;

  return (
    <section className={styles.container} data-test="settingsSection.allowPermanentMeetings">
      <article className={meetingCreationStyles.buttonRow}>
        <h6 className={styles.title}>{intl.get('meetingCreation')}</h6>
        <AllowPermanentMeetings setSettingsChangeError={setSettingsChangeError} />
        {showHideOneTimeMeetings && <DefaultHideOTMeetingsButton setSettingsChangeError={setSettingsChangeError} />}
        {defaultHideMeetingsEnabled && <DefaultHidePermanentMeetingsButton setSettingsChangeError={setSettingsChangeError} />}
        {settingsChangeError && <div className={meetingCreationStyles.error}>{intl.get('meetingSettingFailure')}</div>}
      </article>
    </section>
  );
};

export default MeetingCreationSettings;
