import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classes from './AlexaRegistrationSetting.scss';
import classNames from 'classnames';
import { useInterval } from 'hooks/useInterval';
import _get from 'lodash/get';
import {
  DEVICE_REGISTRATION_MAX_ATTEMPTS,
  DEVICE_REGISTRATION_REFRESH_TIME,
  DeviceRegistration
} from 'constants/alexaIntegrationConstants';
import { DeviceState, GetDeviceResponse } from 'interfaces/AlexaIntegration';
import { Logger } from 'logger';
import { ButtonType, WaitButton } from 'components/Modal/ModalComponent/WaitButton';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  openRegisterModal: () => void;
  openUnregisterModal: () => void;
  deviceTimeoutFailure: () => void;
  getDevice: () => Promise<GetDeviceResponse>;
  device: DeviceState;
}

interface DeviceGetAsyncParams {
  getDevice: Function;
  setTrying: (tryingState: boolean) => void;
  setDeviceUpdateAttempts: (attemptNumber: number) => void;
  setRegisterSuccess: (registerSuccessState: boolean) => void;
  setLoading: (loadingState: boolean) => void;
  trying: boolean;
}

const deviceGetAsync = async (params: DeviceGetAsyncParams) => {
  const { getDevice, setTrying, setDeviceUpdateAttempts, setRegisterSuccess, trying, setLoading } = params;
  try {
    const deviceResponse = await getDevice();
    const deviceState = deviceResponse.body;
    const stateType = _get(deviceState, 'state');
    if (stateType === DeviceRegistration.TRYING || stateType === DeviceRegistration.REGISTERED) {
      setTrying(true);
    } else {
      setDeviceUpdateAttempts(0);
      if (trying && stateType === DeviceRegistration.CONNECTED) {
        setRegisterSuccess(true);
      }
      setTrying(false);
      setLoading(false);
    }
  } catch (e) {
    Logger.info(`Unable to get device state: ${e.toString()}`);
  }
};

const AlexaRegistrationSetting = (props: Props) => {
  const { openRegisterModal, openUnregisterModal, getDevice, device, deviceTimeoutFailure } = props;
  const [loading, setLoading] = useState(true);
  const [deviceUpdateAttempts, setDeviceUpdateAttempts] = useState(0);
  const [trying, setTrying] = useState(_get(device, 'state') === DeviceRegistration.TRYING);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  useEffect(() => {
    deviceGetAsync({
      getDevice,
      setRegisterSuccess,
      trying,
      setTrying,
      setDeviceUpdateAttempts,
      setLoading
    });
  },        []);

  useInterval(() => {
    const deviceState = _get(device, 'state');
    if (deviceState === DeviceRegistration.TRYING || deviceState === DeviceRegistration.REGISTERED) {
      if (deviceUpdateAttempts <= DEVICE_REGISTRATION_MAX_ATTEMPTS) {
        setDeviceUpdateAttempts(attempts => attempts + 1);
        setLoading(true);
        deviceGetAsync({
          getDevice,
          setRegisterSuccess,
          trying,
          setTrying,
          setDeviceUpdateAttempts,
          setLoading
        });
      } else {
        deviceTimeoutFailure();
      }
    } else {
      if (!device || deviceState === DeviceRegistration.UNKNOWN) {
        setRegisterSuccess(false);
      }
      if (trying) {
        setTrying(false);
        setLoading(false);
      }
      setDeviceUpdateAttempts(attempts => attempts + 1);
    }
  },          DEVICE_REGISTRATION_REFRESH_TIME, [device, trying]);

  const registered = device && device.state === DeviceRegistration.CONNECTED;

  return (
    <div className={classNames(classes.container)}>
      <div className={classes.alexaInstructions}>
        <h2 className={classes.settingsTitle}>{intl.get('alexaForBusiness')}</h2>
        <span className={classes.instructions}>{intl.get('alexaRegister')}</span>
      </div>
      <div className={classes.alexaSettings}>
        <div className={classes.alexaRegistration}>
          {registerSuccess && <div className={classes.authSuccess}>{intl.get('alexaRegistrationSuccess')}</div>}
          <WaitButton
            buttonText={registered ? intl.get('unregister') : intl.get('register')}
            className={classes.registerButton}
            data-test="modalFooterBtn.save"
            isBusy={trying}
            isDisabled={!canEditRoomSystems || (!trying ? loading : false)}
            isSubmitButton={true}
            onSave={() => {
              if (!canEditRoomSystems) { return; }
              if (registered) {
                openUnregisterModal();
              } else {
                setDeviceUpdateAttempts(0);
                openRegisterModal();
              }
            }}
            type={ButtonType.submit}
          />
        </div>
      </div>
    </div>
  );
};

export default AlexaRegistrationSetting;
