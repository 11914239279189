import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import * as styles from './RecordingShareSettings.scss';
import Icon from 'components/Common/Icon';
import { Tooltip } from 'components/tooltip/Tooltip';
import { VideoShareViewer } from 'interfaces/VideoShareProperties';
import { SELECTED_SHARE } from 'constants/meetingConstants';
import { SHARE_WITH } from 'constants/recordingsConstants';
import { RecordingState } from 'interfaces/Meeting';
import RecordingViewers from 'components/RecordingViewers/RecordingViewers';

interface Props {
  recordingSettings: RecordingState;
  handleChange: (settings: object) => void;
  disabled?: boolean;
}

const RecordingShareSettings = (props: Props) => {
  const {recordingSettings, handleChange, disabled} = props;
  const { selectedShare, recordingViewers } = recordingSettings;

  const getTooltip = (currentSelection: string) => {
    switch (currentSelection) {
      case SHARE_WITH.MEETING_OWNER:
        return intl.get(`meetingWhoCanViewRecordingTooltip-${currentSelection}`);
      case SHARE_WITH.VIEWERS:
        return intl.get(`meetingWhoCanViewRecordingTooltip-${currentSelection}`);
      case SHARE_WITH.PUBLIC:
        return intl.get(`meetingWhoCanViewRecordingTooltip-${currentSelection}`);
      default:
        return intl.get('meetingWhoCanViewRecordingTooltip-default');
    }
  };

  const shareWithOptions = [
    {
      text: intl.get('meetingOwner'),
      value: SHARE_WITH.MEETING_OWNER
    },
    {
      text: intl.get('meetingParticipants'),
      value: SHARE_WITH.MEETING_PARTICIPANTS
    },
    {
      text: intl.get('specificUsers'),
      value: SHARE_WITH.VIEWERS
    },
    {
      text: intl.get('entireAccount'),
      value: SHARE_WITH.PUBLIC
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.formGroupHorizontal}>
        <label className="label">{intl.get('whoCanViewRecording')}</label>
          <Dropdown
            disabled={disabled}
            className={disabled ? styles.readOnlyMeetingViewSetting : styles.shareWithInput}
            closeOnBlur={true}
            fluid={false}
            name={SELECTED_SHARE}
            onChange={(evt, selection) => {
              const option = shareWithOptions.find(o => o.value === selection.value);
              if (option) {
                handleChange({ selectedShare: {
                  id: option.value,
                  name: option.text
                }});
              }
            }}
            options={shareWithOptions}
            selection={true}
            value={selectedShare.id}
          />
        <Tooltip text={getTooltip(selectedShare.id)}>
          <Icon classes={['icon-info', styles.iconStyle]} />
        </Tooltip>
      </div>
      {selectedShare.id === SHARE_WITH.VIEWERS &&
        <RecordingViewers
          viewers={recordingViewers}
          handleChange={(searchSelection: Array<VideoShareViewer>) => {
            handleChange({recordingViewers: searchSelection});
          }}
          disabled={disabled}
        />
      }
    </div>
  );
};

export default RecordingShareSettings;
