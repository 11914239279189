import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import { Link } from 'react-router-dom';
import { PAST_MEETING_PARTICIPANTS, PAST_MEETING_ROUTE } from 'constants/pastMeetings';
import { formatMinutesForDisplay, formatUnixTimeStampForDisplay } from 'utils/date-time-utils';
import React from 'react';
import blurStyles from 'components/BlurredCell/BlurredCell.scss';
import blurredCell from 'components/BlurredCell/BlurredCell';

interface PastMeetingRow {
  value: number;
}

export default (sort: {}, search: string, blurDashboard: boolean) => [{
  Header: blurredCell(intl.get('name'), blurDashboard),
  accessor: 'meetingName',
  Cell: (row: RowInfo) => {
    const { meetingName } = row.original;
    if (blurDashboard) {
      return blurredCell(meetingName, blurDashboard);
    }
    return (
      <Link
        id={`liveMeeting-${meetingName}`}
        title={meetingName}
        to={{
          pathname: `${PAST_MEETING_ROUTE}/${row.original.conferenceId}/${PAST_MEETING_PARTICIPANTS}`,
          state: {
            sort,
            search
          }
        }}
      >
        {meetingName}
      </Link>
    );
  }
}, {
  Header: blurredCell(intl.get('meetingType'), blurDashboard),
  accessor: 'conferenceType',
  sortable: false,
  className: blurDashboard ? blurStyles.blurred : undefined,
}, {
  Header: blurredCell(intl.get('extension'), blurDashboard),
  accessor: 'extension',
  sortable: false,
  className: blurDashboard ? blurStyles.blurred : undefined,
}, {
  Header: blurredCell(intl.get('startTime'), blurDashboard),
  accessor: 'startedAt',
  Cell: (row: PastMeetingRow) => row.value && formatUnixTimeStampForDisplay(row.value),
  className: blurDashboard ? blurStyles.blurred : undefined,
}, {
  Header: blurredCell(intl.get('duration'), blurDashboard),
  accessor: 'duration',
  Cell: (row: PastMeetingRow) => row.value && formatMinutesForDisplay(row.value),
  className: blurDashboard ? blurStyles.blurred : undefined,
}, {
  Header: blurredCell(intl.get('participants'), blurDashboard),
  accessor: 'totalParticipants',
  className: blurDashboard ? blurStyles.blurred : undefined,
}];
