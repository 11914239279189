import { createAction } from '@lifesize/typescript-react-redux-utils';

export enum CalendarServiceActions {
    HANDLE_CALENDAR_SERVICE_FEATURE = 'HANDLE_CALENDAR_SERVICE_FEATURE'
}

const actions = {
    handleCalendarServiceFeature: (feature: object) => createAction(CalendarServiceActions.HANDLE_CALENDAR_SERVICE_FEATURE, feature),
};

export default actions;
