import { FormikValues } from 'formik';

/**
 * Interface of data passed around by formik.
 * i.e. fields on 'values', 'errors', and 'touched' objects
 */
interface FormValues extends FormikValues {
  description?: string;
  lecturer?: object;
  meetingType: string;
  moderator?: object;
  name: string | undefined;
  owner: object;
  pin: string | null | undefined;
  streamingEnabled: boolean;
  streamingPermissions: string; // Enum of 3 items actually
  streamingPermissionsPasscode: string;
  streamingQuestionsAutoApprove: boolean;
  streamingQuestionsEnabled: boolean;
  streamingToken: string;
  streamingUrl: string;
}

interface FormErrorValues extends FormValues {
  general?: string;
}

/**
 * Enum values used by Field 'name' attributes
 * as getters/setters of form field values
 */
enum MEETING_FORM_FIELD {
  DESCRIPTION = 'description',
  WAITING_ROOM = 'waitingRoom',
  HIDDEN = 'hiddenMeeting',
  LECTURER = 'meetingLecturer',
  MEETING_TYPE = 'meetingType',
  MEETING_ID = 'meeting_id',
  MODERATOR = 'meetingModerator',
  NAME = 'name',
  OWNER = 'owner',
  PIN = 'pin',
  STREAMING_STATE = 'streamingState',
  RECORDING_STATE = 'recordingState',
  STREAMING_ENABLED = 'streamingEnabled',
  STREAMING_PERMISSIONS = 'streamingPermissions',
  STREAMING_PERMISSIONS_PASSCODE = 'streamingPermissionsPasscode',
  STREAMING_QUESTIONS_AUTO_APPROVE = 'streamingQuestionsAutoApprove',
  STREAMING_QUESTIONS_ENABLED = 'streamingQuestionsEnabled',
  STREAMING_TOKEN = 'streamingToken',
  STREAMING_URL = 'streamingUrl',
  STREAMING_EMBED_TOKEN = 'streamingEmbedToken',
  STREAMING_EMBED_URL = 'streamingEmbedUrl',
}

export {
  FormValues,
  FormErrorValues,
  MEETING_FORM_FIELD
};
