import { ExportType } from 'interfaces/Downloads';

export const MAX_PAGE_COUNT: number = 2000;

export const fullDownloadPermissions = {
  [ExportType.MEETING]: true,
  [ExportType.ROOM]: true,
  [ExportType.GROUP]: true,
  [ExportType.CONFERENCE]: true
};
