import React from 'react';
import intl from 'react-intl-universal';
import { Logger } from 'logger';
import Modal from '../../Modal/ModalComponent/ModalComponent';
import * as styles from './ResetDeviceModal.scss';

interface Props {
  isBusy: boolean;
  closeModal: () => void;
  reset: (serialNumber: string) => void;
  serialNumber: string;
  error?: string;
}

export const ResetDeviceModal = (props: Props) => {
  const { isBusy, error, closeModal, reset, serialNumber } = props;

  return (
    <Modal
      className={styles.container}
      onSave={
        async () => {
          try {
            await reset(serialNumber);
            closeModal();
          } catch (e) {
            Logger.info(`Error submitting reset command:  ${e.toString()}`);
          }
        }
      }
      closeModal={closeModal}
      saveText={intl.get('reset')}
      closeText={intl.get('cancel')}
      isSaveButtonBusy={isBusy}
      isSaveButtonDisabled={isBusy}
      hideSaveButton={false}
      errorMessage={error}
    >
      <div>
        <div className={styles.title}>{intl.get('resetConfirmTitle')}</div>
        <div className={styles.body}>{intl.get('resetConfirmBody')}</div>
      </div>
    </Modal>
  );
};

export default ResetDeviceModal;
