import { connect } from 'react-redux';
import { Global } from '../../state/Global';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import actions from '../../actions/dashboardActions';
import { selectStartDate, selectEndDate } from '../../selectors/dashboardSelectors';
import { Moment } from 'moment';

export interface OwnPropsInterface {
  fetchData?: Function;
}

const mapStateToProps = (state: Global, ownProps: OwnPropsInterface) => {
  const startDate = selectStartDate(state);
  const endDate = selectEndDate(state);
  return {
    startDate: startDate,
    endDate: endDate,
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: OwnPropsInterface) => ({
  onDateChange: (dates: {
    startDate: Moment,
    endDate: Moment
  }) => {
    const formattedStartDate = dates.startDate.format('YYYY-MM-DD');
    dispatch(actions.handleSetDateRange(
      {
        startDate: dates.startDate.format('YYYY-MM-DD'),
        endDate: dates.endDate.format('YYYY-MM-DD')
      }
    ));
    if (ownProps.fetchData) {
      ownProps.fetchData(formattedStartDate);
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRangePicker);
