import React from 'react';
import { Mutation } from 'react-apollo';
import { ConfirmModal } from 'components/Modals/ConfirmModal/ConfirmModal';
import DeleteGroupContactMutation from 'queries/groupContacts/deleteGroupContactMutation.gql';
import listGroupContactsQuery from 'queries/groupContacts/listGroupContactsQuery.gql';
import { GroupContact } from 'typings/types';
import intl from 'react-intl-universal';

interface Props {
  closeModal: () => void;
  errorMessage?: string;
  selectionArray?: string[];
  isBusy: boolean;
}

const DeleteGroupContactModal = ({
  closeModal,
  selectionArray,
  isBusy,
  errorMessage
}: Props) => {
  return (
    <Mutation
      mutation={DeleteGroupContactMutation}
      update={(cache, { data: { deleteGroupContacts } }) => {
        // tslint:disable-next-line
        const response: any = cache.readQuery({
          query: listGroupContactsQuery
        });
        const directoryList = response.directoryList;
        const dataToWrite = directoryList.records.filter(
          (r: GroupContact) => {
            return !deleteGroupContacts.find((UUID: string) => {
              return UUID === r.UUID;
            });
          }
        );
        directoryList.records = dataToWrite;

        cache.writeQuery({
          query: listGroupContactsQuery,
          data: { directoryList: directoryList }
        });
      }}
    >
      {(deleteGroupContacts, { loading, error }) => (
        <ConfirmModal
          confirmButtonText={intl.get('delete')}
          confirmTitle={
            selectionArray && selectionArray.length > 1
              ? intl.get('deleteSelectedGroupContacts')
              : intl.get('deleteSelectedGroupContact')
          }
          closeMessage={intl.get('modalCancel')}
          isBusy={loading}
          errorMessage={errorMessage}
          closeModal={closeModal}
          confirmAction={async () => {
            try {
              await deleteGroupContacts({
                variables: {
                  UUIDs: selectionArray
                }
              });
              closeModal();
            } catch (e) {
              // console.log('error', e);
            }
          }}
        />
      )}
    </Mutation>
  );
};

export { DeleteGroupContactModal };
