import { Reducer } from 'redux';
import {
  EXPORTS_LOADING,
  GET_EXPORT_ERROR,
  HANDLE_CONFERENCE_EXPORT_DELETION,
  SET_EXPORTS,
  UPDATE_EXPORTS,
} from 'constants/downloadConstants';
import ExportState, { ExportInterface } from 'state/ExportState';
import _pullAllWith from 'lodash/pullAllWith';

const exportReducer: Reducer<ExportInterface> =
  (state: ExportInterface = ExportState, action) => {
    switch (action.type) {
      case HANDLE_CONFERENCE_EXPORT_DELETION:
        const filteredExports = _pullAllWith([...state.exports], action.payload as string[], (arrVal, otherVal) => arrVal.id === otherVal);
        return { ...state, exports: filteredExports };
      case SET_EXPORTS:
        return { ...state, exports: action.payload, loading: false };
      case EXPORTS_LOADING:
        return { ...state, loading: true };
      case GET_EXPORT_ERROR:
        return { ...state, loading: false, error: action.payload };
      case UPDATE_EXPORTS: {
        const exports = state.exports;
        const updatedExportindex = exports.findIndex(val => val.id === action.payload.downloadId);
        exports[updatedExportindex].downloadUrl = action.payload.downloadUrl;
        return { ...state, exports};
      }
      default:
        return state;
    }
  };

export default exportReducer;
