export const CUSTOM_BG: string = 'CUSTOM_BG';

export const IS_CUSTOM_ICON_WALLPAPER_ENABLED: string = 'IS_CUSTOM_ICON_WALLPAPER_ENABLED';
export type IS_CUSTOM_ICON_WALLPAPER_ENABLED = typeof IS_CUSTOM_ICON_WALLPAPER_ENABLED;

export const SET_URL: string = 'SET_URL';
export type SET_URL = typeof SET_URL;

export const SET_MD5: string = 'SET_MD5';
export type SET_MD5 = typeof SET_MD5;

export const ON_DROP: string = 'ON_DROP';
export type ON_DROP = typeof ON_DROP;

export const TYPE_ERROR: string = 'TYPE_ERROR';
export type TYPE_ERROR = typeof TYPE_ERROR;

export const SET_FILENAME: string = 'SET_FILENAME';
export type SET_FILENAME = typeof SET_FILENAME;

export const DIMENSION_ERROR: string = 'DIMENSION_ERROR';
export type DIMENSION_ERROR = typeof DIMENSION_ERROR;

export const SIZE_ERROR: string = 'SIZE_ERROR';
export type SIZE_ERROR = typeof SIZE_ERROR;

export const ACCEPTABLE_MIME_TYPES = ['image/png'];
export const MAX_FILE_SIZE = 1000000;
