import { Ecommerce } from '@lifesize/types';
import {
  ECOMMERCE_SUBSCRIPTION_CANCEL_CLEAR_ERROR,
  ECOMMERCE_SUBSCRIPTION_REACTIVATE_CLEAR_ERROR,
  ECOMMERCE_SUBSCRIPTION_CANCEL_ROUTE,
  ECOMMERCE_SUBSCRIPTION_ME_ROUTE,
  ECOMMERCE_SUBSCRIPTION_REACTIVATE_ROUTE,
  ECOMMERCE_ACCOUNT_ROUTE
} from 'constants/ecommerceConstants';
import { authSelectors } from '@lifesize/nucleus';
import { getEcommerceBackendUrl } from 'utils/ecommerceUtils';
import { checkHttpStatus } from 'utils/http-utils';
import { Logger } from 'logger';
import { Methods } from 'interfaces/HTTP';
import { createAsyncThunk } from '@reduxjs/toolkit';

const actions = {
  getEcommerceAccount: createAsyncThunk(
    'getEcommerceAccount',
    async (payload: object, thunkAPI) => {
      try {
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), 10000);
        const state =  thunkAPI.getState();
        const jwt = authSelectors.getPrimaryJwt(state);
        const checkUrl = await fetch(getEcommerceBackendUrl(ECOMMERCE_ACCOUNT_ROUTE, jwt), {
          credentials: 'include',
          signal: controller.signal
        });
        clearTimeout(id);
        const account: Ecommerce.Account = await checkHttpStatus(checkUrl).json();
        return account;
      } catch (e) {
        Logger.info(`Error attempting to get ecommerce account: ${e}`);
        throw e;
      }
    }
  ),
  getEcommerceSubscription: createAsyncThunk(
    'getEcommerceSubscription',
    async (payload: object, thunkAPI) => {
      try {
        const state =  thunkAPI.getState();
        const jwt = authSelectors.getPrimaryJwt(state);
        const checkUrl = await fetch(getEcommerceBackendUrl(ECOMMERCE_SUBSCRIPTION_ME_ROUTE, jwt), {
          credentials: 'include'
        });
        const subscription: object = await checkHttpStatus(checkUrl).json();
        return subscription;
      } catch (e) {
        Logger.info(`Error attempting to get subscription: ${e}`);
        throw e;
      }
    }
  ),
  cancelEcommerceSubscription: createAsyncThunk(
    'cancelEcommerceSubscription',
    async (payload: object, thunkAPI) => {
      try {
        const state =  thunkAPI.getState();
        const jwt = authSelectors.getPrimaryJwt(state);
        const checkUrl = await fetch(getEcommerceBackendUrl(ECOMMERCE_SUBSCRIPTION_CANCEL_ROUTE, jwt), {
          method: Methods.POST,
          credentials: 'include'
        });
        const subscription: object = await checkHttpStatus(checkUrl).json();
        return subscription;
      } catch (e) {
        Logger.info(`Error attempting to cancel subscription: ${e}`);
        throw e;
      }
    }
  ),
  clearEcommerceSubscriptionCancelError: () => ({
    type: ECOMMERCE_SUBSCRIPTION_CANCEL_CLEAR_ERROR
  }),
  reactivateEcommerceSubscription: createAsyncThunk(
    'reactivateEcommerceSubscription',
    async (payload: object, thunkAPI) => {
      try {
        const state = thunkAPI.getState();
        const jwt = authSelectors.getPrimaryJwt(state);
        const checkUrl = await fetch(getEcommerceBackendUrl(ECOMMERCE_SUBSCRIPTION_REACTIVATE_ROUTE, jwt), {
          method: Methods.POST,
          credentials: 'include'
        });
        const subscription: object = await checkHttpStatus(checkUrl).json();
        return subscription;
      } catch (e) {
        Logger.info(`Error attempting to reactivate subscription: ${e}`);
        throw e;
      }
    }
  ),
  clearEcommerceSubscriptionReactivateError: () => ({
    type: ECOMMERCE_SUBSCRIPTION_REACTIVATE_CLEAR_ERROR
  }),
};

export default actions;
