import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/Common/Icon';
import { RecordingDataItem } from 'interfaces/RecordingDataItem';
import { RecordingListingParamsType } from 'interfaces/Recording';
import { DEFAULT_MEETING_TITLE } from 'utils/recordingsUtils';
import * as styles from './RecordingsTile.scss';
import { formatDateAtTimeFromUnix, secondsToHms } from 'utils/date-time-utils';
import { getUrlParameter } from 'utils/url-utils';

interface Props {
  recording: RecordingDataItem;
  isLatest?: boolean;
  hideDescription?: boolean;
  listingParams: RecordingListingParamsType;
}

export const RecordingsTile = ({ recording, listingParams }: Props) => {
  const vcShareToken = getUrlParameter('authToken');
  return (
    <div className={styles.container}>
      <Link
        className={styles.tile}
        to={{
          pathname: vcShareToken ? `/singleRecording/${recording.id}?authToken=${vcShareToken}` : `/singleRecording/${recording.id}`,
          state: {
            ...listingParams,
            from: location.pathname
          }
        }}
      >
        <div
          className={styles.videoPreview}
          style={{ backgroundImage: `url(${recording.thumbnails.medium})` }}
        >
          <div className={styles.duration}>{secondsToHms(recording.duration_seconds)}</div>
        </div>
        <ul>
          <li className={styles.title}>
            {recording.name}
          </li>
          <li className={styles.owner}>
            {recording.owner}
          </li>
          <li className={styles.meeting}>
            {(recording.meeting !== DEFAULT_MEETING_TITLE) && recording.meeting}
          </li>
          <li className={styles.stats}>
            <span>{formatDateAtTimeFromUnix(recording.date_created * 1000)}</span>
            <span className={styles.divider}>|</span>
            <Icon classes={['icon-view', styles.icon]} />
            <span>{recording.views}</span>
          </li>
        </ul>
      </Link>
    </div>
  );
};

export default RecordingsTile;
