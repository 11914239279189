import { createAsyncThunk } from '@reduxjs/toolkit';
import { proxyActions } from '@lifesize/nucleus';
import { Methods } from 'interfaces/HTTP';

export const setMeetingSettings = createAsyncThunk(
  'set-meeting-settings',
  async (settings: { [setting: string]: string | boolean | null | undefined | object }, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      'lifesize.account.settings',
      'admin/',
      Methods.POST,
      settings
    ));
    return res;
  }
);
