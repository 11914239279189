import React, { useEffect, useState } from 'react';
import RecordingShareSettings
  from 'components/RecordingShareSettings/RecordingShareSettings';
import * as styles from './RecordingTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Methods } from 'interfaces/HTTP';
import { recordingActions as nucleusRecordingActions, recordingsSelectors } from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import { Dispatch } from 'redux';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import classnames from 'classnames';
import { useMount } from 'hooks/useMount';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { getMeetingShareSetting } from 'utils/recordingsUtils';
import { VideoShareViewer } from 'interfaces/VideoShareProperties';
import { RecordingState } from 'interfaces/Meeting';
import { Field } from 'formik';
import logger from 'utils/logger';
import { MEETING_FORM_FIELD } from 'components/Modals/MeetingsModal/FormFields';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  uuid?: string;
  resetState: Function;
  setFieldValue: (recordingSettings: object) => void;
}

const getMeetingShareProperties = async (dispatch: Dispatch, uuid: string) => {
  const VCCreateMeetingURL = process.env.REACT_APP_VC_CREATE_MEETING_URL;
  if (VCCreateMeetingURL) {
    try {
      await fetch(VCCreateMeetingURL, {
        method: Methods.POST,
        credentials: 'include',
        body: JSON.stringify(
          {
            feed: 'all',
            page: 1,
            sort: 'date',
            timeline: 'any',
            offset: -1,
            limit: -1,
            meeting: uuid,
            msharetoken: null,
            timestamp: Math.round((new Date()).getTime() / 1000),
          }
        )
      });
      // @ts-ignore
      await dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getMeetingShareProperties(uuid)));
    } catch (e) {
      logger.error(`Unable to access recording URL: ${JSON.stringify(e)}`);
    }
  } else {
    logger.error('No Create Meeting URL defined.');
  }
};

const RecordingTab = (props: Props) => {
  const {setFieldValue, uuid, resetState} = props;
  const [isCreatingVCMeeting, setCreatingVCMeeting] = useState(true);
  const dispatch = useDispatch();
  const isMounted = useMount();

  const meetingRecordingShareProperties = useSelector(recordingsSelectors.meetingShareProperties);
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  const isFetching = _get(meetingRecordingShareProperties, 'isFetching');
  const recordingViewers: Array<VideoShareViewer> = _get(meetingRecordingShareProperties, 'data.viewers', []);
  const selectedShare = getMeetingShareSetting(_get(meetingRecordingShareProperties, 'data', { id: '', name: '' }));
  const recordingStateSettings = {
    recordingViewers,
    selectedShare
  };
  const [recordingSettings, setRecordingSettings] = useState<RecordingState>(recordingStateSettings);

  useEffect(() => {
    const getMeeting = async () => {
      if (uuid) {
        await getMeetingShareProperties(dispatch, uuid);
        if (isMounted.current) {
          setCreatingVCMeeting(false);
        }
      }
    };

    getMeeting();
  },        [uuid]);

  useEffect(
    () => {
      if (!isCreatingVCMeeting && !isFetching) {
        setRecordingSettings(recordingStateSettings);
      }
    },
    [isCreatingVCMeeting, isFetching]
  );

  const handleChange = (changedSetting: RecordingState) => {
    if (!canEditMeetings) { return; }
    const newRecordingSettings = {...recordingSettings, ...changedSetting };
    setRecordingSettings({...recordingSettings, ...newRecordingSettings });
    const isDirty = !_isEqual(newRecordingSettings, recordingStateSettings);
    if (isDirty) {
      setFieldValue(newRecordingSettings);
      setRecordingSettings(newRecordingSettings);
    } else {
      resetState();
    }
  };

  if (isCreatingVCMeeting || isFetching) {
    return (
      <div className={classnames(styles.container, styles.loadingContainer)}>
        <LoadingIndicator/>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <RecordingShareSettings
        recordingSettings={recordingSettings}
        handleChange={handleChange}
        disabled={!canEditMeetings}
      />
      <Field
        type="hidden"
        name={MEETING_FORM_FIELD.RECORDING_STATE}
      />
    </div>
  );
};

export default RecordingTab;
