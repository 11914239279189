import { createAction } from '@lifesize/typescript-react-redux-utils';
import { ModalActionType, ModalProps } from '../constants/modalConstants';

export const actions = {
  openModal: (payload: ModalProps) =>
    createAction(ModalActionType.MODAL_OPEN, payload),
  closeModal: () =>
    createAction(ModalActionType.MODAL_CLOSE),
  closePendingSave: () =>
    createAction(ModalActionType.MODAL_PENDING_CLOSE)
};

export default actions;
