import { Actions } from 'constants/alexaIntegrationConstants';
import { DeviceState, ProfileList } from 'interfaces/AlexaIntegration';

export interface AlexaIntegrationInterface {
    roleArn: string;
    externalId: string;
    authenticated: boolean;
    invalid: boolean;
    integrationStatus: Actions.ALEXA_AUTH_SUCCESS | Actions.ALEXA_AUTH_ERROR | null;
    profileData: ProfileList;
    profileError: string | null;
    devices: {
      [serial: string]: DeviceState
    };
}

export const initialProfileState = {
  profiles: [],
  metadata: {
    cursor: '',
    search: '',
    count: '',
  }
};

export const initialAlexaIntegrationState: AlexaIntegrationInterface = {
  roleArn: '',
  externalId: '',
  invalid: false,
  authenticated: false,
  integrationStatus: null,
  profileData: initialProfileState,
  profileError: null,
  devices: {}
};
