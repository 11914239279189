import React from 'react';
import { Field, Form, Formik } from 'formik';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import _memoize from 'lodash/memoize';
import { useMutation } from 'react-apollo';
import * as Yup from 'yup';
import ResetPassword from 'containers/ResetPassword/ResetPasswordContainer';
import { Logger } from 'logger';
import { User } from 'typings/types';
import { getUserType, isAdminUser } from 'utils/userProfileUtils';
import Icon from 'components/Common/Icon';
import { ModalFooter } from 'components/Modal/ModalComponent/ModalFooter';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import Dropdown from 'components/Dropdown/Dropdown';
import { Tooltip } from 'components/tooltip/Tooltip';
import * as formStyles from 'components/Modals/ModalForms.scss';
import updateUserMutation from 'queries/users/updateUserMutation.gql';
import {
  checkCanEditName,
  checkCanHideUser,
  checkCanResetPassword,
  checkCanSetAdminAccess
} from 'utils/permissionsUtils';
import { featureSelectors, nucleusConstants } from '@lifesize/nucleus';
import { useSelector } from 'react-redux';
import { roleDropdownSelector, selectRoleState } from 'selectors/roleSelectors';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  closeModal: () => void;
  currentUser: object;
  selectedUser: User;
  resetPasswordHandler: (uuid: string) => void;
}

const getHideUserLabel = (labelText: string, toolTipText: string) => {
  return (
    <>
      <span>{labelText}</span>
      <Tooltip text={toolTipText} stylesWrapper={{ lineHeight: 1, marginLeft: '8px', zIndex: '1' }}>
        <Icon classes={['icon-info', formStyles.tooltipButtonIcon]} />
      </Tooltip>
    </>
  );
};

/**
 * Cached to evaluate only once
 */
export const infoTabSchema = _memoize(() => {
  return Yup.object().shape({
    displayName: Yup.string().trim().required(intl.get('userNameRequired'))
      .max(32, intl.get('userNameMaxLength')),
  });
});

const InfoTab = (props: Props) => {
  const {
    closeModal,
    currentUser,
    selectedUser,
    resetPasswordHandler
  } = props;

  const rolesFeatureFlag = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const permissions = useSelector(selectAccountPermissions);
  const userType = getUserType(selectedUser);
  const canViewRoles = permissions[ROLE_PERMISSION.VIEW_ROLES];
  const canEditRoles = permissions[ROLE_PERMISSION.MODIFY_ROLES];

  const canEditName = rolesFeatureFlag ? false : checkCanEditName(selectedUser, currentUser);
  const canHideUser = rolesFeatureFlag ? permissions[ROLE_PERMISSION.MODIFY_USERS] : checkCanHideUser(selectedUser, currentUser);
  const canSeeHiddenStatus = rolesFeatureFlag ? true : canHideUser;

  const canSetAdminAccess = rolesFeatureFlag ? permissions[ROLE_PERMISSION.MODIFY_USERS] : checkCanSetAdminAccess(selectedUser, currentUser);
  const canResetPassword = rolesFeatureFlag ? permissions[ROLE_PERMISSION.MODIFY_USERS] : checkCanResetPassword(selectedUser, currentUser);

  // User can't do anything if they can't edit any form fields
  const isReadOnly = (!canEditName && !canSetAdminAccess && !canHideUser);
  const [ updateUser ] = useMutation(updateUserMutation);
  const roles = useSelector(roleDropdownSelector);
  const loading = useSelector(selectRoleState)?.loading;

  const legacyRolesForm = (
    <div className={formStyles.formGroup}>
      <Field
        classNameLabel={classnames(formStyles.toggleLabelLarge, formStyles.disabledToggle)}
        component={ToggleButton}
        name="userType"
        id="isAdmin"
        label={intl.get('userIsAdminLabel')}
        leftLabel={true}
        disabled={!canSetAdminAccess || loading}
      />
    </div>
  );

  const permissionRolesForm = !!(roles.length && rolesFeatureFlag && canViewRoles) &&
    (
      <div className={formStyles.formGroup}>
        <Field
          component={Dropdown}
          name="role"
          id="role"
          label={intl.get('role')}
          options={roles}
          disabled={!canSetAdminAccess || !canEditRoles || loading}
          closeOnBlur={true}
          fluid={false}
          selection={true}
        />
      </div>
    );

  return (
    <Formik
      initialValues={{
        displayName: selectedUser?.displayName || '',
        hidden: selectedUser?.hidden || false,
        role: selectedUser?.roles && selectedUser?.roles[0] || [],
        userType: userType === nucleusConstants.USER_ROLE_SUPER_USER
      }}
      validationSchema={infoTabSchema}
      onSubmit={async (values, { setSubmitting, setFieldError }) => {
        const user: { uuid: string; displayName: string; hidden: boolean; userType?: string | null; roles?: string[] | null } = {
          uuid: selectedUser.UUID,
          displayName: values.displayName as string,
          hidden: values.hidden as boolean,
        };

        if (rolesFeatureFlag) {
          user.roles = [values.role as string];
        } else {
          let inputUserType = values.userType ? nucleusConstants.USER_ROLE_SUPER_USER : nucleusConstants.USER_ROLE_USER;
          if (isAdminUser(selectedUser)) { // You cannot change admin user roles
            inputUserType = nucleusConstants.USER_ROLE_ADMIN;
          }
          user.userType = inputUserType;
        }

        try {
          await updateUser({
            variables: {
              user,
              includeRoles: rolesFeatureFlag
            }
          });
          closeModal();
        } catch (e) {
          if (e.message && e.message.toLowerCase().trim() === 'graphql error: displaynameexisterror') {
            setFieldError('displayName', intl.get('duplicateUserNameError', { value: values.displayName }));
          }
          // TODO: need to update with error code that service provides
          if (e.message && e.message.toLowerCase().trim() === 'graphql error: noadminremaining') {
            // If service failed to save due to the role change resulting in no 'admin' accounts
            setFieldError('userType', intl.get('roleChangeFailure'));
          }
          Logger.info(`Failure to save user: ${e} `);
          setSubmitting(false);
        }
      }}
      render={({ isSubmitting, isValid, errors, touched, dirty }) => (
        <Form className={formStyles.wideLabels}>
          <div className={classnames(formStyles.formGroup, canEditName && formStyles.hasValidation)}>
            <label className={classnames(formStyles.labelLarge, formStyles.favorField)}>
              {intl.get('userNameLabel')}
            </label>
            {canEditName ? (
              <div className={classnames(formStyles.inputContainer, formStyles.favorField)}>
                <Field
                  className={formStyles.input}
                  type="text"
                  name="displayName"
                  maxLength={32}
                />
                <ul className={formStyles.inputMessages}>
                  {!!(touched && errors && touched.displayName && errors.displayName) && (
                    <li className={formStyles.error}>{errors.displayName}</li>
                  )}
                </ul>
              </div>
            ) : (
              <div className={classnames(formStyles.inputContainer, formStyles.settingValue)}>
                {selectedUser?.displayName || ''}
              </div>
            )}
          </div>
          {rolesFeatureFlag ? permissionRolesForm : legacyRolesForm}
          {canSeeHiddenStatus &&
          <div className={formStyles.formGroup}>
            <Field
              classNameLabel={classnames(formStyles.toggleLabelLarge, formStyles.disabledToggle)}
              component={ToggleButton}
              id="hidden"
              label={getHideUserLabel(intl.get('inputLabelHideUser'), intl.get('tooltipHideUser'))}
              leftLabel={true}
              disabled={!canHideUser}
              name="hidden"
            />
          </div>
          }
          {
            canResetPassword &&
            <div className={classnames(formStyles.formGroup, formStyles.vertical)}>
              <div className={classnames(formStyles.inputContainer, formStyles.center)}>
                <ResetPassword onClickHandler={() => resetPasswordHandler(selectedUser?.UUID)} />
              </div>
            </div>
          }
          <ModalFooter
            useSaveAsFormSubmit={true}
            closeText={intl.get('modalCancel')}
            closeModal={closeModal}
            isBusy={isSubmitting}
            hideSaveButton={isReadOnly}
            isSaveButtonDisabled={!dirty || isSubmitting || !isValid}
          />
        </Form>
      )}
    />
  );
};

export default InfoTab;
