import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';
import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import * as formStyles from 'components/Modals/ModalForms.scss';
import { MEETING_FORM_FIELD } from 'components/Modals/MeetingsModal/FormFields';
import { OwnerField } from 'components/Modals/MeetingsModal/Tabs/RoleFields/OwnerField';
import { ModeratorField } from 'components/Modals/MeetingsModal/Tabs/RoleFields/ModeratorField';
import { LecturerField } from 'components/Modals/MeetingsModal/Tabs/RoleFields/LecturerField';

enum RoleType {
  OWNER = 'owner',
  MODERATOR = 'moderator',
  LECTURER = 'lecturer'
}

export interface Props {
  errors: FormikErrors<FormikValues>;
  handleChange: Function;
  isEditingExistingMeeting?: boolean;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
}

const RolesTab = (props: Props) => {
  const {
    isEditingExistingMeeting,
    touched,
    errors,
    handleChange
  } = props;

  const ownerTouched = touched?.[RoleType.OWNER] ?? false;
  const ownerErrors = errors?.[RoleType.OWNER] ?? null;
  const moderatorTouched = touched?.[RoleType.MODERATOR] ?? false;
  const moderatorErrors = errors?.[RoleType.MODERATOR] ?? null;

  return (
    <div>
      <div id="owner" className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
        <label className={formStyles.labelLarge}>{intl.get('meetingOwner')}</label>
        <div className={formStyles.inputContainer}>
          <div className={formStyles.selectContainer}>
            <Field
              name={MEETING_FORM_FIELD.OWNER}
              render={() => <OwnerField isEditingExistingMeeting={isEditingExistingMeeting} handleChange={handleChange} />}
            />
          </div>
          <ul className={formStyles.inputMessages}>
            {!!(touched && errors && ownerTouched && ownerErrors) && (
              <li className={formStyles.error}>{ownerErrors}</li>
            )}
          </ul>
        </div>
      </div>

      <div id="moderator" className={classnames(formStyles.formGroup, formStyles.hasValidation)}>
        <label className={formStyles.labelLarge}>{intl.get('moderator')}</label>
        <div className={formStyles.inputContainer}>
          <div className={formStyles.selectContainer}>
            <Field
              name={MEETING_FORM_FIELD.MODERATOR}
              render={() => <ModeratorField isEditingExistingMeeting={isEditingExistingMeeting} handleChange={handleChange} />}
            />
          </div>
          <ul className={formStyles.inputMessages}>
            {!!(touched && errors && moderatorTouched && moderatorErrors) && (
              <li className={formStyles.error}>{moderatorErrors}</li>
            )}
          </ul>
        </div>
      </div>

      <div id="lecturer" className={formStyles.formGroup}>
        <label className={formStyles.labelLarge}>{intl.get('lecturer_optional')}</label>
        <div className={formStyles.inputContainer}>
          <div className={formStyles.selectContainer}>
            <Field
              name={MEETING_FORM_FIELD.LECTURER}
              render={() => <LecturerField isEditingExistingMeeting={isEditingExistingMeeting} handleChange={handleChange} />}
            />
          </div>
        </div>
      </div>

    </div>
  );
};

export default RolesTab;
