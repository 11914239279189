import { proxyActions } from '@lifesize/nucleus';
import { createAction } from '@lifesize/typescript-react-redux-utils';
import { Dispatch } from 'redux';

export const GENERATE_IMPERSONATION_CODE_SUCCESS = 'GENERATE_IMPERSONATION_CODE_SUCCESS';
export const GENERATE_IMPERSONATION_CODE_FAILURE = 'GENERATE_IMPERSONATION_CODE_FAILURE';
export const DISMISS_IMPERSONATION_CODE = 'DISMISS_IMPERSONATION_CODE';
export const SET_IMPERSONATION_SESSION_ACTIVE = 'SET_IMPERSONATION_SESSION_ACTIVE';

const generateImpersonationCodeSuccess = (code: string) => createAction(GENERATE_IMPERSONATION_CODE_SUCCESS, { code });
const generateImpersonationCodeFailure = (error: Error) => createAction(GENERATE_IMPERSONATION_CODE_FAILURE, { error });
export const setImpersonationSessionActive = () => createAction(SET_IMPERSONATION_SESSION_ACTIVE);

export function generateImpersonationCode() {
  return async (dispatch: Dispatch) => {
    try {
      const rawResponse = await dispatch(proxyActions.proxyApiCall('lifesize.impersonate', '', 'POST'));
      const jsonResponse = JSON.parse(rawResponse);
      const { code } = jsonResponse;
      dispatch(generateImpersonationCodeSuccess(code));
    } catch (error) {
      dispatch(generateImpersonationCodeFailure(error));
    }
  };
}

export const dismissImpersonationCode = () => createAction(DISMISS_IMPERSONATION_CODE);
