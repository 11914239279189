import {
  currentUserSelectors,
  recordingActions as nucleusRecordingActions,
  recordingsSelectors
} from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions as meetingActions } from 'actions/meetingsActions';
import { actions } from 'actions/modalActions';
import EditRecording, { Props } from 'components/Modals/EditRecordingModal/EditRecording';
import { VideoProperties } from 'interfaces/VideoProperties';
import getUserByUUID from 'queries/users/getUserProfileByUUIDQuery.gql';
import { meetingsAndOwnersSelector } from 'selectors/meetingsSelector';
import * as singleRecordingSelectors from 'selectors/SingleRecordingSelectors';
import logger from 'utils/logger';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';

const mapStateToProps = (state: Global) => {
  const recordingId = singleRecordingSelectors.selectRecordingId(state);
  const meetings = meetingsAndOwnersSelector(state);
  const userProfile = currentUserSelectors.myInfo(state);
  const videoProps = recordingsSelectors.videoProperties(state);
  const videoProperties = _get(videoProps, 'data');
  const ownerId = _get(videoProperties, 'owner.uuid');
  const meetingId = _get(videoProperties, 'meeting.uuid');
  const meetingName = _get(videoProperties, 'meeting.name');

  return {
    recordingId: recordingId,
    videoProperties,
    meetingId,
    meetingName,
    ownerId,
    error: _get(videoProps, 'error'),
    isBusy: _get(videoProps, 'isUpdating'),
    meetingsAndOwners: meetings,
    currentUserName: _get(userProfile, 'name'),
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  setVideoProperties: async (arg: string, videoProps: VideoProperties) => {
    try {
      await dispatch(nucleusRecordingActions.setVideoProperties(arg, videoProps));
      await dispatch(nucleusRecordingActions.fetchSingleRecording(arg));
    } catch (e) {
      logger.info(`Unable to set video recording properties: ${JSON.stringify(e)}`);
    }
    dispatch(actions.closeModal());
  },
  fetchMeetingsAndOwners: async () => {
    await dispatch(meetingActions.fetchMeetingsAndOwners());
  },
  resetErrorState: async () => {
    await dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.resetVideoPropsErrorState()));
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(getUserByUUID, {
    options: (props: Props) => {
      return {
        fetchPolicy: 'network-only',
        variables : { uuid: props.ownerId }
      };
    },
    // tslint:disable-next-line
    props: ({ data, ownProps }: any) => {
      return {
        ...ownProps,
        videoProperties: { ...ownProps.videoProperties, owner: { uuid: ownProps.ownerId, name: _get(data, 'userByUUID.displayName') }},
        ownerData: data
      };
    },
  }),
)(EditRecording);
