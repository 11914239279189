import React, { MutableRefObject, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './AlexaRegisterModal.scss';
import { DeviceState, ProfileData, ProfileList } from 'interfaces/AlexaIntegration';
import { DeviceRegistration, GET_PROFILES_INTERVAL } from 'constants/alexaIntegrationConstants';
import { useInterval } from 'hooks/useInterval';
import classNames from 'classnames';
import AlexaProfileList from './AlexaProfileList';
import { useMount } from 'hooks/useMount';

interface Props {
  devices: {
    [index: string]: DeviceState
  };
  serialNumber: string;
  closeModal: () => void;
  getProfiles: () => Promise<ProfileList>;
  profiles: Array<ProfileData>;
  registerDevice: (profileArn: string) => Promise<void>;
}

const asyncGetProfiles = async (
  isMounted: MutableRefObject<boolean>,
  getProfiles: () => Promise<ProfileList>,
  setCurrentProfiles: Function,
  setLoading: (loadingState: boolean) => void
) => {
  const newProfiles = await getProfiles();
  if (isMounted.current && newProfiles) {
    // Use a cache-and-network strategy to avoid loading times
    setCurrentProfiles(newProfiles.profiles);
    setLoading(false);
  }
};

export const AlexaRegisterModal = (props: Props) => {
  const { devices, closeModal, getProfiles, registerDevice, profiles, serialNumber } = props;

  const device = devices[serialNumber] || {};

  const [ loading, setLoading ] = useState(!profiles.length);
  const [ busy, setBusy ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ error, setError ] = useState<string | undefined>(undefined);
  const [ currentProfiles, setCurrentProfiles ] = useState<Array<ProfileData>>(profiles);
  const [ selectedProfile, setSelectedProfile ] = useState<string | null>(null);
  const isMounted = useMount();

  useEffect(() => {
    asyncGetProfiles(isMounted, getProfiles, setCurrentProfiles, setLoading);
  },        []);

  useInterval(() => {
    if (!currentProfiles.length) {
      asyncGetProfiles(isMounted, getProfiles, setCurrentProfiles, setLoading);
    }
  },          GET_PROFILES_INTERVAL, [currentProfiles]);

  useEffect(() => {
    // Device is getting updated on an interval by AlexaRegistrationSetting.tsx
    if (device.state === DeviceRegistration.CONNECTED) {
      closeModal();
    }
    if (device.state === DeviceRegistration.FAILED) {
      setError('Registration Failed');
    }
    if (device.state !== DeviceRegistration.TRYING && device.state !== DeviceRegistration.REGISTERED) {
      setBusy(false);
    }
  },        [device.state]);

  return (
    <Modal
      className={styles.container}
      errorMessage={showError && error ? intl.get('alexaRegistrationError') : undefined}
      isSaveButtonDisabled={!selectedProfile || loading}
      useSaveAsFormSubmit={true}
      isSaveButtonBusy={device.state === DeviceRegistration.TRYING || device.state === DeviceRegistration.REGISTERED || busy}
      onSave={async () => {
        if (selectedProfile) {
          setBusy(true);
          try {
            await registerDevice(selectedProfile);
            setShowError(true);
          } catch (e) {
            setError(e);
            setShowError(true);
            setBusy(false);
          }
        }
      }}
      closeModal={closeModal}
      saveText={intl.get('register')}
      hideSaveButton={false}
      hideCloseButton={true}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>{intl.get('alexaForBusiness')}</div>
        <div className={styles.body}>{intl.get('alexaProfile')}</div>
        <ul className={classNames(styles.profileList, loading && styles.loading)}>
          <AlexaProfileList
            currentProfiles={currentProfiles}
            loading={loading}
            selectedProfile={selectedProfile}
            setSelectedProfile={setSelectedProfile}
          />
        </ul>
        <div className={styles.footnote}>{intl.get('alexaProfileExtra')}</div>
      </div>
    </Modal>
  );
};

export default AlexaRegisterModal;
