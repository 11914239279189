
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"myRS"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RoomSystem"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"serialNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"dialstring"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"platform"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firmware"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"connectInfo"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"connectType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"tpAppsEnabled"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"chromeInstalled"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":201}};
    doc.loc.source = {"body":"fragment myRS on RoomSystem {\n  serialNumber\n  dialstring\n  displayName\n  platform\n  firmware\n  connectInfo {\n    connectType\n    displayNumber\n    tpAppsEnabled\n    chromeInstalled\n  }\n  __typename\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
