export const showExpiringSoonLabelWithinDays = 7;

export const MEETING_NAME_MAX_LENGTH = 200;
export const MEETING_NAME_SHORT_BREAK = 32;
export const MEETING_NAME_FONT_SIZE_CHAR_BREAK = 42;

export const GRAPHQL_MEETING_LIMIT_ERROR = 'GRAPHQL ERROR: MEETINGLIMITERROR';
export const GRAPHQL_DISPLAYNAME_EXISTS_ERROR = 'GRAPHQL ERROR: DISPLAYNAMEEXISTERROR';
export const GRAPHQL_REQUIRED_PASSCODE_ERROR = 'GRAPHQL ERROR: UNCAUGHT EXCEPTION: MEETING MUST CONTAIN A PIN.';
export const MEETING_LIMIT_ERROR = 'MEETING_LIMIT_ERROR';
export const DUPLICATE_MEETING_NAME_ERROR = 'DUPLICATE_MEETING_NAME_ERROR';
export const OTHER_MEETING_NAME_ERROR = 'OTHER_MEETING_NAME_ERROR';

export enum MEETING_TYPES {
  NORMAL = 'NORMAL',
  ONE_TIME = 'TEMP'
}

export const MEETING_PATH = '/meetings/meet';
export const SELECTED_SHARE = 'selectedShare';
export const RECORDING_VIEWERS = 'recordingViewers';
export const MAX_PHONE_NUMBERS_ALLOWED = 4;
