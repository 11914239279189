import _get from 'lodash/get';

/**
 * Determine if more data records are still being loaded.
 * Defaults to true until data load is complete
 */
export const hasMoreRecords = (data: object) => {
  const directoryList = _get(data, 'directoryList');
  if (directoryList) {
    return _get(directoryList, 'hasMoreRecords', true);
  }
  return true;
};
