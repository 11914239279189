import * as styles from './RecordingViewers.scss';
import { VideoShareViewer } from 'interfaces/VideoShareProperties';
import MultiSearchUsers from 'components/Search/SearchMultiSelect';
import intl from 'react-intl-universal';
import React from 'react';
import { Maybe } from 'typings/types';

interface Props {
  viewers: Array<VideoShareViewer>;
  handleChange: Function;
  disabled: Maybe<boolean>;
}

const RecordingViewers = (props: Props) => {
  const {viewers, handleChange, disabled} = props;
  if (disabled) {
    return (
      <div className={styles.viewerContainer}>
        {viewers.map((user: VideoShareViewer) => {
          return (
            <div className={styles.pill} key={user.uuid}>{user.name}</div>
          );
        })}
      </div>
    );
  }
  return (
    <MultiSearchUsers
      onSearchSelectionMade={(searchSelection) => {
        handleChange(searchSelection);
      }}
      initialSelection={viewers}
      noResultsText={intl.get('noResults')}
    />
  );
};

export default RecordingViewers;
