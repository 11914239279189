import { connect } from 'react-redux';
import { actions } from 'actions/modalActions';
import { AlexaRegisterModal } from 'components/Modals/AlexaRegisterModal/AlexaRegisterModal';
import alexaIntegrationThunks from 'thunks/alexaIntegrationThunks';
import { Global } from 'state/Global';
import { selectAlexaDevices, selectAlexaProfiles } from 'selectors/alexaIntegrationSelector';

interface Props {
  serialNumber: string;
}

const mapStateToProps = (state: Global, ownProps: Props) => ({
  devices: selectAlexaDevices(state),
  profiles: selectAlexaProfiles(state)
});

const mapDispatchToProps = (dispatch: Function, ownProps: Props) => ({
  closeModal: async () => dispatch(actions.closeModal()),
  getProfiles: async () => dispatch(alexaIntegrationThunks.getProfiles()),
  registerDevice: async (profileArn: string) => dispatch(alexaIntegrationThunks.registerDevice(profileArn, ownProps.serialNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlexaRegisterModal);
