import { createSelector } from 'reselect';
import { Global } from 'state/Global';
import { Role } from 'interfaces/Role';
import { mapRoleFromBase } from 'utils/permissionsUtils';

export const mapUserRoles = (roles: Role[]) => roles.map((role: Role) => (
  {
    text: role.name,
    value: role.id,
  }
));

const selectRoleState = (state: Global) => {
  const { roles, ...otherStates } = state.app?.role;
  const renamedRoles = roles.map(role => mapRoleFromBase(role));
  return {...otherStates, roles: renamedRoles};
};

const roleDropdownSelector = createSelector(
  selectRoleState,
  (role) => mapUserRoles(role?.roles || [])
);

export {
  selectRoleState,
  roleDropdownSelector,
};
