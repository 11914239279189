import { connect } from 'react-redux';
import { Global } from 'state/Global';
import intl from 'react-intl-universal';
import deviceManagementActions from 'actions/deviceManagementActions';
import RebootModal from 'components/Modals/RebootDeviceModal/RebootDeviceModal';
import { deviceManagementState } from 'selectors/deviceManagementSelector';
import { DeviceState } from 'constants/deviceManagementConstants';
import { actions as ModalActions } from 'actions/modalActions';

interface Props {
  serialNumber: string;
}

const mapStateToProps = (state: Global, ownProps: Props) => {
  const rebootState = deviceManagementState(state, ownProps.serialNumber);
  const isBusy = rebootState === DeviceState.pending;
  const error = rebootState === DeviceState.failed ? intl.get('rebootFailure') : undefined;
  return ({
    rebootState,
    isBusy,
    error,
  });
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => dispatch(ModalActions.closeModal()),
  reboot: (serialNumber: string) => dispatch(deviceManagementActions.rebootDevice(serialNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(RebootModal);
