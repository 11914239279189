/**
 * Checks if a single email domain is within the domain filter list
 * @param emailAddress
 * @param domainsFilter
 */
const isEmailInDomainFilter = (emailAddress: string, domainsFilter: string) => {
  if (emailAddress && domainsFilter && emailAddress.length && domainsFilter.length) {
    // Check if the email domain is allowed
    let separator = ';';
    if (domainsFilter.indexOf(',') > 0) {
      // In case the data has a comma-delimited list
      separator = ',';
    }
    const domains: string[] = domainsFilter.split(separator).map( name => name.trim().toLowerCase());
    const emailParts: string[] = emailAddress.split('@');
    // @ts-ignore
    return emailParts.length ? domains.includes(emailParts.pop().trim().toLowerCase()) : false;
  }
  return false;
};

export {
  isEmailInDomainFilter
};
