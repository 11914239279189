import React, { FormEvent, useState } from 'react';
import intl from 'react-intl-universal';
import { Button, Dropdown } from 'semantic-ui-react';
import { Formik, Form } from 'formik';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import * as styles from 'components/Settings/Sections.scss';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

/* note: normally, the multiple dropdown will ensure uniqueness of new entries, but because we also allow the input of
 * semicolon-separated lists of domains, we must ensure uniqueness of each parsed value */

const getCleanValues = (valueArray: string[]) => {
  return uniq(compact(valueArray.join(';').split(';').map(str => str.trim())));
};

const getCleanOptions = (valueString: string, currentValues: {}[] = []) => {
  // new values should be unique to each other and have a non-zero length after trim()
  const addedValues = uniq(compact(valueString.split(';').map(str => str.trim())));
  // the added values must be unique to the current values
  return compact(addedValues.map(cleanStr => {
    if (currentValues.find(elt => get(elt, 'value') === cleanStr)) { return null; }
    return { key: uniqueId('domain_'), text: cleanStr, value: cleanStr };
  }));
};

// props passed in
export interface Props {
  domainsFilter: string;
  updateSection: Function;
}

const DomainsFilter  = (props: Props) => {
  const { domainsFilter, updateSection } = props;
  const [options, setOptions] = useState(getCleanOptions(domainsFilter));
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  const handleAddition = (e: FormEvent, { value }: { value: string }) => {
    // a new value may be an individual domain or a semicolon-separated list of domains
    const newValues = getCleanOptions(value, options);
    setOptions([...newValues, ...options]);
  };

  const onSubmit = (formData: object, callback: Function) => {
    updateSection(compact(get(formData, 'domainsFilter')).join(';'), callback);
  };

  return (
    <section className={styles.container} data-test="settingsSection.domains">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitleDomainsFilter')}</h6>
        <p className={styles.description}>{intl.get('settingsSectionDescriptionDomainsFilter')}</p>
        <Formik
          enableReinitialize={true}
          initialValues={{
            domainsFilter: getCleanValues(domainsFilter.split(';')),
          }}
          onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => setSubmitting(false))}
          render={({dirty, errors, handleChange, isSubmitting, touched, values}) => (
            <Form>
              <div className={`${styles.formSection} ${styles.horizontal}`}>
                <div className={styles.inputContainer}>
                  <Dropdown
                    allowAdditions={true}
                    className={styles.input}
                    fluid={false}
                    multiple={true}
                    name={'domainsFilter'}
                    disabled={!canEditSettings}
                    onAddItem={handleAddition}
                    onChange={(e, { value }: { value: string[] }) => {
                      if (!canEditSettings) { return; }
                      handleChange({
                        target: { name: 'domainsFilter', value: getCleanValues(value) }
                      });
                    }}
                    options={options}
                    placeholder={intl.get('inputPlaceholderDomainsFilter')}
                    search={true}
                    selection={true}
                    value={values.domainsFilter}
                    {...{ ['data-test']: 'settingsSectionCombobox.domains' }}
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    id="btnSubmitDomainsFilter"
                    data-test="sectionBtnSubmit"
                    disabled={isSubmitting || !dirty || !canEditSettings}
                    name="btnSubmitDomainsFilter"
                    role="button"
                    type="submit"
                  >
                    {intl.get('settingsButtonLabelUpdate')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
      </article>
    </section>
  );
};

export default DomainsFilter;
