import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'actions/ecommerceActions';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as modalFormStyles from 'components/Modals/ModalForms.scss';
import * as styles from 'components/Settings/AccountDetailsTab/AccountSupport.scss';
import { hasSubscriptionCancelError, isSubscriptionCancelBusy } from 'selectors/ecommerceSelector';

type Props = {
  onClose: () => void;
};

export default function CancelSubscriptionConfirmModal(props: Props) {
  const dispatch = useDispatch();
  const isCancelSubscriptionBusy = useSelector(isSubscriptionCancelBusy);
  const hasCancelSubscriptionError = useSelector(hasSubscriptionCancelError);
  return (
    <Modal
      className={modalFormStyles.containerSmall}
      closeModal={() => {
        dispatch(actions.clearEcommerceSubscriptionCancelError());
        props.onClose();
      }}
      onSave={() => {
        // cancel subscription
        dispatch(actions.cancelEcommerceSubscription({}));
      }}
      isSaveButtonBusy={isCancelSubscriptionBusy}
      saveText={intl.get('yes')}
      closeText={intl.get('no')}
      titleText={intl.get('cancelSubscription')}
      errorMessage={hasCancelSubscriptionError ? intl.get('cancelSubscriptionErrorMessage') : undefined}
    >
      <div className={styles.modalContainer}>
        {intl.get('cancelSubscriptionConfirmMessage')}
      </div>
    </Modal>
  );
}
