import React from 'react';
import * as styles from './RecordingsListItemView.scss';
import { RecordingDataArray, RecordingDataItem } from 'interfaces/RecordingDataItem';
import { RecordingListingParamsType } from 'interfaces/Recording';
import RecordingsListItem from './RecordingsListItem';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import intl from 'react-intl-universal';
import { RecordingRequestObjectFeed } from 'constants/recordingsConstants';

export interface Props {
  isLoading: boolean;
  pageType: RecordingRequestObjectFeed;
  recordingData: RecordingDataArray;
  listingParams: RecordingListingParamsType;
}

const renderItems = (recordings: Array<RecordingDataItem>, listingParams: RecordingListingParamsType) => {
  const pages: Array<JSX.Element> = [];
  recordings.map((item: RecordingDataItem, index: number) => {
    pages.push(<RecordingsListItem recording={item} key={index} showAdditionalDetails={true} listingParams={listingParams}/>);
  });
  return pages;
};

const RecordingsListItemView = ({recordingData, isLoading, listingParams}: Props) => {
  if (isLoading) {
    return (
      <div className={styles.container}>
        <LoadingIndicator/>
      </div>
    );
  }

  if (!isLoading && recordingData && recordingData.length === 0) {
    return (
      <div className={styles.container}>
        {intl.get('noResults')}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerLegend}>
        <span/>
        {intl.get('name')}
        {intl.get('date')}
        {intl.get('owner')}
        {intl.get('duration')}
      </div>
      {
        renderItems(recordingData, listingParams)
      }
    </div>
  );
};

export default RecordingsListItemView;
