import {
  recordingActions as nucleusRecordingActions,
  recordingsAuthSelectors,
  recordingsSelectors
} from '@lifesize/nucleus';
import recordingActions from 'actions/recordingsActions';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions } from 'actions/modalActions';
import ShareRecording from 'components/Modals/ShareRecordingModal/ShareRecording';
import { VideoShareProperties, MeetingShareProperties, VideoShareViewer } from 'interfaces/VideoShareProperties';
import * as singleRecordingSelectors from 'selectors/SingleRecordingSelectors';
import logger from 'utils/logger';

const mapStateToProps = (state: Global) => {
  const singleRecordingState = recordingsSelectors.singleRecording(state);
  const videoProps = recordingsSelectors.shareProperties(state);
  const recordingId = singleRecordingSelectors.selectRecordingId(state);
  const recordingsAuthSettings = recordingsAuthSelectors.vcSettingsSelector(state) || {};
  const data = _get(videoProps, 'data');
  const mappedViewers = _get(data, 'viewers').filter((viewer: VideoShareViewer) => !!viewer.name);
  const videoProperties: VideoShareProperties = {..._get(videoProps, 'data'), viewers: mappedViewers, meetingProps: { ...data.meetingProps, viewers: mappedViewers }};

  return {
    recordingId: recordingId,
    videoProperties,
    isBusy: _get(videoProps, 'isFetching'),
    isPublicUrlBusy: _get(videoProps, 'isFetchingPublicUrl'),
    isGroupUrlBusy: _get(videoProps, 'isFetchingGroupUrl'),
    vcSettingsAllowPublic: _get(recordingsAuthSettings.data, 'allow_public', false),
    meetingId: _get(singleRecordingState, 'data.meeting_id')
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => {
    dispatch(actions.closeModal());
  },
  getShareProperties: (recordingId: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getShareProperties(recordingId)));
  },
  getShareLinksForCloudGroup: (recordingId: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getShareLinksForCloudGroup(recordingId)));
  },
  getShareLinksGlobal: (recordingId: string) => {
    dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.getShareLinksGlobal(recordingId)));
  },
  setVideoProperties: async (arg: string, videoProps: VideoShareProperties) => {
    try {
      await dispatch(nucleusRecordingActions.setShareProperties(arg, videoProps));
      await dispatch(nucleusRecordingActions.fetchSingleRecording(arg));
    } catch (e) {
      logger.info(`Unable to set video properties: ${JSON.stringify(e)}`);
    }
    dispatch(actions.closeModal());
  },
  setMeetingShareProperties: async (id: string, props: MeetingShareProperties) => {
    await dispatch(recordingActions.safeNucleusRecordingAction(nucleusRecordingActions.setMeetingShareProperties(id, props)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShareRecording);
