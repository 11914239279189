import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions as CalendarActions } from 'actions/calendarSubscriptionActions';
import { calendarSubscriptionSelector } from 'selectors/calendarSubscriptionSelector';
import _get from 'lodash/get';
import { RoomSystem } from 'typings/types';
import {
  isPaired,
  isPlatformDash,
  isValidFirmwareVersion,
  hasCalendarCapability,
  canConnectDevice,
  isRoomSystemOffline
} from 'utils/roomSystemUtils';
import { RoomSystemUsageType } from 'constants/roomSystemConstants';
import SettingsTabComponent from 'components/RoomSystem/SettingsTab/SettingsTabComponent';
import { selectAuthenticationState } from 'selectors/alexaIntegrationSelector';
import alexaIntegrationThunks from 'thunks/alexaIntegrationThunks';

interface Props {
  roomSystem: RoomSystem;
}

const mapStateToProps = (state: Global, ownProps: Props) => {
  const calendarState = calendarSubscriptionSelector(state);
  const isGraphite = isPlatformDash(_get(ownProps, 'roomSystem.platform'));
  const isRoomPaired = isPaired(_get(ownProps, 'roomSystem.pairingStatus'));
  const isAlexaAuthenticated = selectAuthenticationState(state);
  const showFirmwareVersion = isRoomPaired && !isGraphite && isValidFirmwareVersion(_get(ownProps, 'roomSystem.firmware'));
  const showHideFromDirectory = isRoomPaired && _get(ownProps, 'roomSystem.usageType') !== RoomSystemUsageType.PERSONAL;
  const showConnectionDate = isRoomPaired && _get(ownProps, 'roomSystem.pairingTimeMillis');
  const showCalendarCapability = hasCalendarCapability(_get(ownProps, 'roomSystem.platform')) && isRoomPaired;
  const showSerialNumber = isRoomPaired && !isGraphite && _get(ownProps, 'roomSystem.serialNumber');
  const hasOverview = showSerialNumber || showFirmwareVersion || showConnectionDate || showHideFromDirectory;
  const hasAlexaIntegration = canConnectDevice(ownProps.roomSystem) && !isRoomSystemOffline(ownProps.roomSystem) && isAlexaAuthenticated;
  const noSettings = !hasOverview && !showCalendarCapability && !hasAlexaIntegration;

  return {
    hasAlexaIntegration: canConnectDevice(ownProps.roomSystem) && !isRoomSystemOffline(ownProps.roomSystem) && isAlexaAuthenticated,
    needsAlexaAuthentication: !isAlexaAuthenticated,
    isBusy: calendarState.isBusy,
    subscriptionEmail: calendarState.subscriptionEmail,
    subscriptionLookupFailed: calendarState.subscriptionLookupFailed,
    unsubscribeFailed: calendarState.unsubscribeFailed,
    showSerialNumber,
    showFirmwareVersion,
    showConnectionDate,
    showHideFromDirectory,
    hasCalendarCapability: showCalendarCapability,
    hasOverview,
    noSettings
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  getCredentials: async () => dispatch(alexaIntegrationThunks.getAlexaCredentials()),
  onSubscribeClick: (id: string) => {
    if (id && id.length) {
      dispatch(CalendarActions.subscribe(id));
    }
  },
  onUnSubscribeClick: (id: string) => {
    if (id && id.length) {
      dispatch(CalendarActions.unsubscribe(id));
    }
  },
  getSubscription: (id: string) => {
    if (id && id.length) {
      dispatch(CalendarActions.getCalendarSubscription(id));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTabComponent);
