import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import moment from 'moment';
import { DWHCallDataResultType } from '../interfaces/Dashboard';

/**
 * Sorts collection of DWHCallDataResultType by period ascending
 * Will remove data points that do not have a populated 'period' property
 * @param data
 */
const sortCallDataByDateAsc = (data: Array<DWHCallDataResultType>) => {
  const filtered = _filter(data, (date: DWHCallDataResultType) => !!date.period);
  return _sortBy(filtered, [(date: DWHCallDataResultType) => moment(date.period, 'DD-MMM-YYYY')]);
};

export {
  sortCallDataByDateAsc
};
