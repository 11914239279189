export interface MaxInactiveDaysState {
  maxInactiveDays: number;
  isBusyFetchingMeetingsToDelete: boolean;
  meetingsToBeDeleted?: Array<object>;
}

export const initialMaxInactiveDaysState: MaxInactiveDaysState = {
  maxInactiveDays: 0,
  isBusyFetchingMeetingsToDelete: false,
  meetingsToBeDeleted: undefined
};
