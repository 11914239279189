import React, { useState } from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { Tab } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import { TabProps } from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import Icon from 'components/Common/Icon';
import LiveStreamDetailsViewers from 'components/Meetings/LiveStreamsTab/LiveStreamDetailsViewers';
import LiveStreamDetailsQuestions from 'components/Meetings/LiveStreamsTab/LiveStreamDetailsQuestions';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import { liveQuestionsV2, liveViewersV2, MEETING_LIVE_STREAMS, meetingLiveStreamSummary } from 'constants/liveStreamHistory';
import { DWHLiveStreamQuestionType, DWHLiveStreamResultTypeV2, DWHLiveStreamViewerType } from 'interfaces/Dashboard';
import * as styles from './LiveStreamDetails.scss';
import { getBreadcrumbs } from '../../../utils/nav-utils';

interface Props {
  fetchQuestionData: Function;
  metadata: DWHLiveStreamResultTypeV2;
  questions: DWHLiveStreamQuestionType[];
  questionsError: string | null;
  questionsIsFetching: boolean;
  viewers: DWHLiveStreamViewerType[];
  viewersError: string | null;
  loading: boolean;
}

const getTabIndex = (tab: string = '') => {
  return tab === 'questions' ? 1 : 0; // default to 'viewers'
};

const getRouteData = (match: RouteComponentProps['match']) => {
  const { id, tab } =  _get(match, 'params', {}) as { id: string, tab: string };
  return {
    id: decodeURIComponent(id),
    tab,
    tabIndex: getTabIndex(tab)
  };
};

const panes = (props: RouteComponentProps<{}> & Props) => {
  const { viewers, viewersError, loading, questions, questionsError, fetchQuestionData, questionsIsFetching, match } = props;
  return [
    {
      menuItem: (
        {
          key: 'viewersTab',
          content: (
            intl.get('tabViewers')
          )
        }),
      render: () => {
        return (
          <Tab.Pane key={'0'} attached={false}>
            <LiveStreamDetailsViewers
              data={viewers}
              error={viewersError}
              fetchData={async () => ({})}
              isFetching={loading}
            />
          </Tab.Pane>
        );
      }
    },
    {
      menuItem: (
        {
          key: 'questionsTab',
          content: (
            intl.get('tabQuestions')
          )
        }
      ),
      render: () => {
        const { id } = getRouteData(match);
        return (
          <Tab.Pane key={'1'} attached={false}>
            <LiveStreamDetailsQuestions
              data={questions}
              error={questionsError}
              fetchData={() => fetchQuestionData(id)}
              isFetching={questionsIsFetching}
            />
          </Tab.Pane>
        );
      }
    }
  ];
};

const renderBreadCrumbs = (metadata: Props['metadata']) => {
  const { meetingName, meetingUUID } = metadata;
  return getBreadcrumbs([
    {
      url: MEETING_LIVE_STREAMS,
      label: intl.get('navMeetings')
    },
    {
      url: meetingLiveStreamSummary(meetingUUID, meetingName),
      label: intl.get('breadcrumbHistory')
    },
    {
      label: intl.get('breadcrumbDetails')
    }
  ]);
};

const onTabChange = (event: React.MouseEvent<HTMLDivElement>, data: TabProps, selectedTab: number, setSelectedTab: (tab: number) => void, props: RouteComponentProps<{}> & Props) => {
  const { history, match } = props;
  if (_isNumber(data.activeIndex) && selectedTab !== data.activeIndex) {
    const { id } = getRouteData(match);
    setSelectedTab(data.activeIndex);
    history.push(data.activeIndex === 0 ? liveViewersV2(id) : liveQuestionsV2(id));
  }
};

const renderSubtitle = (id: string, metadata: DWHLiveStreamResultTypeV2) => {
  return [
    (
      <div key="metadata">
        {[
          `${intl.get('dataLabelDate')}: ${metadata.startDateFormatted}`,
          `${intl.get('dataLabelViewers')}: ${metadata.viewerCount}`,
          `${intl.get('dataLabelDuration')}: ${metadata.durationSecondsFormatted}`,
        ].join(' | ')}
      </div>
    ),
    (
      <Link id="view_all" to={`/singleRecording/${encodeURIComponent(id)}`} key="recording">
        <Icon classes={['icon-record']} />
        <span>{intl.get('buttonLabelViewRecording')}</span>
      </Link>
    )
  ];
};

const LiveStreamDetails = (props: RouteComponentProps<{}> & Props) => {
  const { metadata, loading, match } = props;
  const [selectedTab, setSelectedTab] = useState(getTabIndex(_get(props.match, 'params.tab')));

  const { id, tabIndex } = getRouteData(match);
  const title = `${metadata.streamName} ${intl.get('breadcrumbDetails')}`.trim();

  return (
    <div className={styles.container}>
      {!loading ? <ListViewLayoutHeader
        breadcrumbs={renderBreadCrumbs(metadata)}
        key={'header'}
        subtitle={renderSubtitle(id, metadata)}
        title={title}
      /> : <div className={styles.headerPlaceholder} />}
      <Tab
        className={classnames(styles.tabsContainer, 'tabset')}
        defaultActiveIndex={tabIndex}
        key={'tabSet'}
        menu={{secondary: true, pointing: true}}
        onTabChange={(e, data) => onTabChange(e, data, selectedTab, setSelectedTab, props)}
        panes={panes(props)}
      />
    </div>
  );

};
export default withRouter(LiveStreamDetails);
