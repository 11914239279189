import { nucleusConstants } from '@lifesize/nucleus';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

const getUserType = (userProfile: object): string => {
  // The user type is on different attributes depending on the system
  return _get(userProfile, 'role', _get(userProfile, 'userType'));
};

const getUUID = (userProfile: object ) => {
  // The uuid is on different attributes depending on the system
  return _get(userProfile, 'UUID', _get(userProfile, 'userUuid'));
};

const isAdminUser = (userProfile: object ) => {
  const isAdmin = _includes([nucleusConstants.USER_ROLE_ADMIN], getUserType(userProfile));
  return !!isAdmin;
};

const isSuperUser = (userProfile: object ) => {
  const isSuper = _includes([nucleusConstants.USER_ROLE_SUPER_USER], getUserType(userProfile));
  return !!isSuper;
};

const isAdminOrSuperUser = (userProfile: object): boolean => {
  const isSuperUserOrAdmin = _includes([nucleusConstants.USER_ROLE_ADMIN, nucleusConstants.USER_ROLE_SUPER_USER], getUserType(userProfile));
  return !!isSuperUserOrAdmin;
};

const isSameUser = (userProfileA: object, userProfileB: object): boolean => {
  return getUUID(userProfileA) === getUUID(userProfileB);
};

export {
  getUserType,
  isAdminUser,
  isSuperUser,
  isAdminOrSuperUser,
  getUUID,
  isSameUser
};
