import React from 'react';
import { RowInfo } from 'react-table';
import intl from 'react-intl-universal';

const groupContactsTabColumnConfig = (data: object, showEditUserModal: (contact: Object) => void, allowEdit: boolean) =>
  [{
    Header: intl.get('name'),
    accessor: 'displayName',
    id: 'displayName',
    Cell: ((row: RowInfo) => {
      return (
        <div data-test="groupContactCell">
          {allowEdit ?
            <a
              onClick={() => {
                showEditUserModal(row.original);
              }}
            >
              {row.row.displayName}
            </a> :
            <span>{row.row.displayName}</span>
          }
        </div>
      );
    })
  }, {
    Header: intl.get('callingDetails'),
    accessor: 'dialstring',
    id: 'signalingAddress'
  }];

export {
  groupContactsTabColumnConfig,
};
