import moment, { Moment } from 'moment';
import React from 'react';
import { FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { getStartDate, momentToEndOfDay, momentToStartOfDay } from '../../utils/date-time-utils';
import { INFO_POSITION_AFTER } from './date_range_constants';
import DateRangePickerWrapper from './DateRangePickerWrapper';
import DateRangePresetsPanel from './DateRangePresetsPanel';
import './overrides.css';
import _isEmpty from 'lodash/isEmpty';

interface Props {
  /** Callback function for consumers of this component */
  onDateChange: (dates: {
    startDate: Moment,
    endDate: Moment
  }) => void;
  startDate: string;
  endDate: string;
}

interface State {
  startDate: Moment | null;
  endDate: Moment | null;
  focusedInput: FocusedInputShape | null;
  showDatePicker: boolean;
  presetDateRangeSelected: boolean;
}

class DateRangePickerComponent extends React.Component<Props, State> {

  readonly state = {
    startDate: momentToStartOfDay(moment(this.props.startDate)),
    endDate: momentToEndOfDay(moment(this.props.endDate)),
    focusedInput: null,
    showDatePicker: false,
    presetDateRangeSelected: false
  };

  onDatePickerPanelChange = (dates: {
    startDate: Moment,
    endDate: Moment,
  }) => {
    if (dates.startDate) {
      this.onDatesSelected(dates);
    }
  }

  onFocusedInputChange = (arg: FocusedInputShape | null) => {
    if (this.state.focusedInput !== arg) {
      this.setState({ focusedInput: arg, showDatePicker: arg !== null });
    }
  }

  /**
   * User has made a final selection. Send date range to parent component,
   * and close date picker
   * @param dates {{startDate: Moment endDate: Moment}}
   */
  onBroadcastSelectedDates = (dates: {
    startDate: Moment,
    endDate: Moment
  }) => {
    if (this.props.onDateChange && dates.startDate && dates.endDate) {
      this.props.onDateChange(dates);

      // Reset date state for it to display properly on header
      this.setState(
        {
          startDate: getStartDate(dates.startDate),
          endDate: dates.endDate,
          showDatePicker: false,
          presetDateRangeSelected: true
        });
    }
  }

  /**
   * Continue to update date ranges and leave picker open
   * @param dates {{startDate: Moment endDate: Moment}}
   */
  onDatesSelected = (dates: {
    startDate: Moment,
    endDate: Moment
  }) => {
    this.setState(
      { startDate: dates.startDate, endDate: dates.endDate, showDatePicker: true }
    );
  }

  onClose = (dateRange: { startDate: Moment, endDate: Moment }) => {
    // Panel is now closed - set focusedInput to null to determine if panel is closed
    const { props, state } = this;
    const newState = {};
    if (props.startDate && state.startDate) {
      if (!moment(props.startDate).isSame(state.startDate)) {
        // @ts-ignore
        newState.startDate = moment(props.startDate);
      }
    }
    if (props.endDate) {
      if (!moment(props.endDate).isSame(state.endDate)) {
        // @ts-ignore
        newState.endDate = moment(props.endDate);
      }
    }

    if (!_isEmpty(newState)) {
      this.setState(newState);
    }
  }

  render() {
    const { startDate, endDate, presetDateRangeSelected, showDatePicker } = this.state;

    return (
      <DateRangePickerWrapper
        calendarInfoPosition={INFO_POSITION_AFTER}
        endDate={endDate}
        onClose={this.onClose}
        onDatesChange={this.onDatePickerPanelChange}
        onDatesSelected={this.onBroadcastSelectedDates}
        onFocusedInputChange={this.onFocusedInputChange}
        presetDateRangeSelected={presetDateRangeSelected}
        renderCalendarInfo={() => (
          <DateRangePresetsPanel />
        )}
        shouldShowDatePickerPanel={showDatePicker}
        startDate={startDate}
      />
    );
  }
}

export default DateRangePickerComponent;
