import { Reducer } from 'redux';
import {
  SET_DOWNLOAD_NOTIFICATION_ID,
  DISMISS_DOWNLOAD_NOTIFICATION,
  CLEAR_DOWNLOAD_COUNTER,
  REMOVE_PENDING_DOWNLOADS,
  ADD_PENDING_DOWNLOADS, REMOVE_FAILED_DOWNLOADS,
} from 'constants/downloadNotificationConstants';
import DownloadNotificationState, { DownloadNotificationInterface } from 'state/DownloadNotificationState';
import { HANDLE_CONFERENCE_EXPORT_DELETION } from 'constants/downloadConstants';

const downloadNotificationReducer: Reducer<DownloadNotificationInterface> =
  (state: DownloadNotificationInterface = DownloadNotificationState, action) => {
    let trimmedPendingDownloads: Set<string>;
    switch (action.type) {
      case SET_DOWNLOAD_NOTIFICATION_ID:
        return { ...state, notificationId: action.payload };
      case DISMISS_DOWNLOAD_NOTIFICATION:
        return { ...state, notificationId: undefined };
      case CLEAR_DOWNLOAD_COUNTER:
        return { ...state, downloadCounter: 0 };
      case REMOVE_PENDING_DOWNLOADS:
        trimmedPendingDownloads = state.pendingDownloads;
        action.payload.forEach((completedDownload: string) => {
          trimmedPendingDownloads.delete(completedDownload);
        });
        return { ...state, pendingDownloads: trimmedPendingDownloads, downloadCounter: state.downloadCounter + action.payload.length };
      case ADD_PENDING_DOWNLOADS:
        const addedPendingDownloads = state.pendingDownloads;
        action.payload.forEach((pendingDownload: string) => {
          addedPendingDownloads.add(pendingDownload);
        });
        return { ...state, pendingDownloads: addedPendingDownloads };
      case REMOVE_FAILED_DOWNLOADS:
        trimmedPendingDownloads = state.pendingDownloads;
        action.payload.forEach((failedDownload: string) => {
          trimmedPendingDownloads.delete(failedDownload);
        });
        return { ...state, pendingDownloads: trimmedPendingDownloads };
      case HANDLE_CONFERENCE_EXPORT_DELETION:
        let pendingDownloadsAfterDeletion: Set<string> = state.pendingDownloads;
        action.payload.forEach((deletedDownload: string) => {
          pendingDownloadsAfterDeletion.delete(deletedDownload);
        });
        return { ...state, pendingDownloads: pendingDownloadsAfterDeletion };
      default:
        return state;
    }
  };

export default downloadNotificationReducer;
