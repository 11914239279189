import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Global } from 'state/Global';
import { DeviceMonitoringStatus, RoomSystem } from 'typings/types';
import MaintenanceTabComponent from 'components/RoomSystem/MaintenanceTab/MaintenanceTabComponent';
import {
  isPlatformGems,
  isPlatformGIcon,
  isRoomSystemOffline,
  isRebootSupported,
  isUploadSupported,
  isResetSupported,
  isResetPasswordSupported,
} from 'utils/roomSystemUtils';
import { actions as ModalActions } from 'actions/modalActions';
import { ModalTypes } from 'constants/modalConstants';

interface Props {
  hasMonitoringData: boolean;
  deviceMonitoring: DeviceMonitoringStatus;
  roomSystem: RoomSystem;
}
const mapStateToProps = (state: Global, ownProps: Props) => {
  const canPerformUpgradeManagement = isPlatformGIcon(ownProps.roomSystem.platform)
    || isPlatformGems(ownProps.roomSystem.platform);
  const canUploadCoroner = isUploadSupported(ownProps.roomSystem);

  return {
    showUpload: canUploadCoroner,
    showReboot: isRebootSupported(ownProps.roomSystem),
    showResetPassword: isResetPasswordSupported(ownProps.roomSystem),
    canReboot: !isRoomSystemOffline(ownProps.roomSystem),
    showReset: isResetSupported(ownProps.roomSystem),
    canReset: !isRoomSystemOffline(ownProps.roomSystem),
    canPerformUpgradeManagement
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: Props) => ({
  reboot: () => {
    dispatch(
      ModalActions.openModal({
        modalType: ModalTypes.REBOOT_DEVICE,
        payload: { serialNumber: _get(ownProps, 'roomSystem.serialNumber') }
      }),
    );
  },
  reset: () => {
    dispatch(
      ModalActions.openModal({
        modalType: ModalTypes.RESET_DEVICE,
        payload: { serialNumber: _get(ownProps, 'roomSystem.serialNumber') }
      }),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceTabComponent);
