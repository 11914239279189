import React, { useEffect, useState } from 'react';
import { List, AutoSizer, ListRowRenderer } from 'react-virtualized';
import useEventListener from 'hooks/useEventListener';
import { useMount } from 'hooks/useMount';

const ROW_BUFFER = 40; // Number of rows to load ahead to ensure smooth scroll
const MOCK_DEFAULT_SIZE = 400;  // These dummy heights/widths are necessary for tests where dom sizing doesn't work.
const REACT_TABLE_ROW_HEIGHT = 36;  // Pixel height of table rows so that react-virtualized can offset appropriately.

type Props = {
  /**
   * React Table passes an array of 'rows', and a footer node to this body component. (Currently ignoring the footer)
   */
  children: Array<Array<React.ReactNode>>;
};

// Copied from react-table types.
type RowsRenderedInfo = {
  overscanStartIndex: number;
  overscanStopIndex: number;
  startIndex: number;
  stopIndex: number;
};

let stopIndex: number = 0;
let tableBody: HTMLDivElement | null;

// Exports a function that takes a fetch more function, and returns a component
// that calls that function to load more rows.
export const getTableBody = (
  fetchMore?: () => Promise<{}>,
  rowHeight: number = REACT_TABLE_ROW_HEIGHT,
  reportTableBody?: (tableBody: HTMLDivElement) => void) =>
  (props: Props) => {
    const {
      children,
      ...rest
    } = props;

    const {
      children: [rows],
    } = props;

    const [isFetching, setIsFetching] = useState(false);
    const isMounted = useMount();

    const checkAfterResize = () => {
      if (reportTableBody && tableBody) {
        reportTableBody(tableBody);
      }
    };

    useEventListener('resize', () => {
      // On mount, we setup a interval to fetchMore rows for the table if we're
      // approaching the stopIndex
      if (reportTableBody) {
        checkAfterResize();
      }
    });

    useEffect(
      () => {
        return () => {
          stopIndex = 0;
          tableBody = null;
        };
      },
      []
    );

    const renderRow: ListRowRenderer = ({ index, style }) =>
      React.cloneElement(children[0][index] as JSX.Element, {
        style,
      });

    const onRowsRendered: (args: RowsRenderedInfo) => void = (rowInfo: { stopIndex: number }) =>
      (stopIndex = rowInfo.stopIndex);

    const onScroll = async () => {
      if (isMounted.current && fetchMore && rows.length - stopIndex < ROW_BUFFER && !isFetching) {
        setIsFetching(true);
        await fetchMore();
        setIsFetching(false);
      }
    };

    /* fix the scrollbar push-out for Chrome, IE, and Edge in Windows (Mac already renders the scrollbar as overlay)
     * note: Firefox for Windows does not have this option */
    const listStyle = {overflowY: 'auto', overflowX: 'hidden', msOverflowStyle: '-ms-autohiding-scrollbar'};

    return (
      <div
        {...rest}
        className="rt-tbody"
        ref={(elt) => {
          if (elt && reportTableBody) {
            tableBody = elt;
            reportTableBody(elt);
          }
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              data={rows}  // We pass data here to force rerender when row selection state changes.
              height={height || MOCK_DEFAULT_SIZE}
              onRowsRendered={onRowsRendered}
              onScroll={onScroll}
              rowCount={rows.length}
              rowHeight={rowHeight ? rowHeight : REACT_TABLE_ROW_HEIGHT}
              rowRenderer={renderRow}
              // @ts-ignore
              style={listStyle}
              width={width || MOCK_DEFAULT_SIZE}
            />
          )}
        </AutoSizer>
      </div>
    );
  };
