import classnames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import ModalConductorContainer from 'containers/ModalConductor/ModalConductorContainer';
import { CurrentUserObjectType } from 'interfaces/CurrentUser';
import { withAuth } from 'utils/hocs/withAuth';
import { withIntl } from 'utils/hocs/withIntl';
import Footer from '../Footer/Footer';
import { Header } from '../Header/Header';
import { Navbar } from '../Navbar/Navbar';
import * as styles from './Layout.scss';
import ToastComponent from '../Toast/ToastComponent';

interface Props {
  isAuthenticated?: boolean;
  isLoggedIn?: boolean;
  currentUser?: CurrentUserObjectType;
  location: Location;
}

const LayoutSFC: React.FunctionComponent<Props>
  = ({ location, isLoggedIn, isAuthenticated, children }) => {
  return (
    <>
      <ToastComponent/>
      <div className={styles.container}>
        {/* App Header */}
        <Header/>
        <div className={styles.mainContainer}>

          {/* Side Nav */}
          <div>
            <Navbar/>
          </div>

          {/* Main Vertical Scroll Area */}
          <div className={classnames(styles.scrollContainer, 'mainContent')}>
            {isLoggedIn &&
            <>
              {children}
              <Footer/>
            </>}
          </div>

        </div>
      </div>

      {/* Launch conductor for modals */}
      <ModalConductorContainer/>
    </>
  );
};

export const Layout = compose(
  withRouter,
  withAuth,
  withIntl,
)(LayoutSFC);
