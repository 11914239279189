import { Logger } from '../interfaces/Logger';

const levels = ['log', 'dir', 'error', 'count', 'info'];
export const logger: Logger = {};
let messageStack: Array<object> = [];

// http://codegists.com/snippet/typescript/throttlets_kraaden_typescript
/**
 * Typescript function to throttle frequently occurring events (resize, keydown etc...).
 * Example: document.getElementById("inputBox").addEventListener("keyup", throttle(eventHandler, 300));
 */
function throttle(callback: () => void, limit: number) {
  var wait = false;
  return () => {
    if (!wait) {
      callback();
      wait = true;
      setTimeout(() => { wait = false; }, limit);
    }
  };
}

function sendMessages() {
  // Trigger send messages to nucleus.
  if (console) {
    /* tslint:disable */
    console.log('sendMessages', messageStack);
    /* tslint:enable */
  }
  messageStack = [];
}

const logMessage = throttle(sendMessages, 5000);

for (let x = 0; x < levels.length; x++) {
  const level = levels[x];
  logger[level] = (message: string) => {
    messageStack.push({ level, message });
    if (console) {
      console[level](message);
    }
    logMessage();
  };
}

export default logger;
