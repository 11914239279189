import { createAction } from '@lifesize/typescript-react-redux-utils';
import {
  Actions
} from 'constants/alexaIntegrationConstants';
import { DeviceState } from 'interfaces/AlexaIntegration';

const actions = {
  getDeviceStateSuccess: (response: { [serial: string]: DeviceState }) =>
    createAction(Actions.ALEXA_DEVICE_STATE_SUCCESS, response),
  getDeviceStateFailure: (serialNumber: string) =>
    createAction(Actions.ALEXA_DEVICE_STATE_FAILURE, serialNumber),
  removeDeviceStateSuccess: (serialNumber: string) =>
    createAction(Actions.ALEXA_DEVICE_REMOVAL_SUCCESS, serialNumber),
  alexaDeviceStateFailure: (serialNumber: string) =>
    createAction(Actions.ALEXA_DEVICE_STATE_FAILURE, serialNumber),
  alexaDisconnectSuccess: () =>
    createAction(Actions.ALEXA_DISCONNECT_SUCCESS),
  alexaDisconnectFailure: (errorId: string) =>
    createAction(Actions.ALEXA_DISCONNECT_FAILURE, errorId),
  createAlexProfileSuccess: (body: object) =>
    createAction(Actions.ALEXA_GET_PROFILE_SUCCESS, body),
  getAlexaProfileFailure: (errorId?: string) =>
    createAction(Actions.ALEXA_GET_PROFILE_FAILURE, errorId),
  getAlexaCredentialsSuccess: (response: object) =>
    createAction(Actions.ALEXA_CREDENTIAL_SUCCESS, response),
  getAlexaCredentialsError: (error: string) =>
    createAction(Actions.ALEXA_CREDENTIAL_FAILURE, error),
  authenticateAlexaSuccess: (roleArn: string, externalId: string) =>
    createAction(Actions.ALEXA_AUTH_SUCCESS, { roleArn, externalId }),
  authenticateAlexaError: (externalId: string, error: string) =>
    createAction(Actions.ALEXA_AUTH_ERROR, { externalId, error }),
};

export default actions;
