import { createSelector } from 'reselect';
import { Global } from 'state/Global';

const calenderService = (state: Global) => state.app?.calendarService;

const selectConnectPlusGatewayActive = createSelector(
    calenderService,
    (r) => r.connectPlusGatewayStatus || false
);

export {
    selectConnectPlusGatewayActive
};
