import React from 'react';
import { useMutation } from 'react-apollo';
import deleteRoomSystemsMutation from 'queries/roomSystems/DeleteRoomSystemsMutation.gql';
import listRoomsQuery from 'queries/roomSystems/listRoomsQuery.gql';
import { ConfirmModal } from 'components/Modals/ConfirmModal/ConfirmModal';
import { RoomSystem } from 'typings/types';

interface Props {
  closeModal: () => void;
  resetSelection: () => void;
  confirmTitle: string;
  confirmButtonText: string;
  errorMessage: string;
  uuid: string;
  serialNumber: string;
  selectionArray: string[];
}

const DeleteRoomSystemsModal = (props: Props) => {
  const {
    selectionArray,
    closeModal,
    confirmTitle,
    confirmButtonText,
    errorMessage,
    resetSelection
  } = props;

  const [doDeleteRooms, { loading }] = useMutation(
    deleteRoomSystemsMutation,
    {
      update(cache: { writeQuery: Function, readQuery: Function }, { data: { deleteRooms } }: { data: { deleteRooms: Array<{}> } }) {
        const response: { directoryListRoomSystem: { records: {}[] } } | null = cache.readQuery({
          query: listRoomsQuery
        });
        const directoryListRoomSystem = response?.directoryListRoomSystem;
        if (directoryListRoomSystem) {
          directoryListRoomSystem.records = directoryListRoomSystem.records.filter((r: RoomSystem) => {
            return !deleteRooms.find((room: RoomSystem) => {
              return room.serialNumber === r.serialNumber;
            });
          });
        }

        cache.writeQuery({
          query: listRoomsQuery,
          data: { directoryListRoomSystem: directoryListRoomSystem }
        });
      }
    });

  return (
    <ConfirmModal
      confirmTitle={confirmTitle}
      confirmButtonText={confirmButtonText}
      isBusy={loading}
      errorMessage={errorMessage}
      closeModal={closeModal}
      confirmAction={async () => {
        try {
          await doDeleteRooms({
            variables: {
              serialNumbers: selectionArray,
            }
          });
          resetSelection();
          closeModal();
        } catch (e) {
          // console.log('error', e);
        }
      }}
    />
  );
};

export default DeleteRoomSystemsModal;
