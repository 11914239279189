import { createAction } from '@lifesize/typescript-react-redux-utils';
import { SelectionActionType } from '../constants/selectionConstants';

export const actions = {
  toggleSelection: (key: number) =>
    createAction(SelectionActionType.SELECTION_TOGGLE_SELECTION, key),
  toggleAll: (dataUUIDs: string[]) =>
    createAction(SelectionActionType.SELECTION_TOGGLE_ALL, dataUUIDs),
  resetSelection: () =>
    createAction(SelectionActionType.SELECTION_RESET),
};

export default actions;
