import { createSelector } from 'reselect';
import { Global } from 'state/Global';

/**
 * Direct selector to the dashboard state domain
 */
const selectDashboardState = (state: Global) => state.app?.dashboard;

const selectStartDate = createSelector(
  selectDashboardState,
  (dashboard) => {
    return dashboard.startDate;
  }
);
const selectEndDate = createSelector(
  selectDashboardState,
  (dashboard) => {
    return dashboard.endDate;
  }
);

const selectStartDateMoment = createSelector(
  selectDashboardState,
  (dashboard) => {
    return dashboard.startDateMoment;
  }
);
const selectEndDateMoment = createSelector(
  selectDashboardState,
  (dashboard) => {
    return dashboard.endDateMoment;
  }
);

export {
  selectDashboardState,
  selectStartDate,
  selectEndDate,
  selectStartDateMoment,
  selectEndDateMoment
};
