import { Reducer } from 'redux';
import { Actions, DeviceRegistration } from 'constants/alexaIntegrationConstants';
import { AlexaIntegrationInterface, initialProfileState, initialAlexaIntegrationState } from 'state/AlexaIntegrationState';

const alexaIntegrationReducer: Reducer<AlexaIntegrationInterface> =
  (state = initialAlexaIntegrationState, action) => {
    const { roleArn, externalId, invalid } = action?.payload || {};
    const integrationStatus = action.type;
    switch (action.type) {
      case Actions.ALEXA_CREDENTIAL_SUCCESS:
        return {
          ...state,
          externalId,
          roleArn,
          invalid,
          authenticated: !!(roleArn && externalId),
          integrationStatus: null,
        };
      case Actions.ALEXA_DISCONNECT_SUCCESS:
        return initialAlexaIntegrationState;
      case Actions.ALEXA_AUTH_SUCCESS:
        return {
          ...state,
          roleArn,
          externalId,
          invalid,
          integrationStatus,
          authenticated: true,
        };
      case Actions.ALEXA_AUTH_ERROR:
        return {
          ...state,
          authenticated: false,
          integrationStatus,
        };
      case Actions.ALEXA_DEVICE_STATE_SUCCESS:
        return {
          ...state,
          devices: {...state.devices, ...action.payload}
        };
      case Actions.ALEXA_DEVICE_STATE_FAILURE:
        const failedDevice = state.devices[action.payload];
        if (!failedDevice) {
          return state;
        }
        failedDevice.state = DeviceRegistration.FAILED;
        return {
          ...state,
          devices: {...state.devices, ...{[action.payload]: failedDevice }}
        };
      case Actions.ALEXA_DEVICE_REMOVAL_SUCCESS:
        const devices = state.devices;
        delete devices[action.payload];
        return { ...state, devices };
      case Actions.ALEXA_GET_PROFILE_SUCCESS:
        return {
          ...state,
          profileData: action.payload,
          profileError: null
        };
      case Actions.ALEXA_GET_PROFILE_FAILURE:
        return {
          ...state,
          profileData: initialProfileState,
          profileError: action.payload
        };
      case Actions.ALEXA_CREDENTIAL_FAILURE:
      case Actions.ALEXA_DISCONNECT_FAILURE:
      default:
        return state;
    }
  };

export default alexaIntegrationReducer;
