import { useSelector } from 'react-redux';
import { currentUserSelectors, featureSelectors } from '@lifesize/nucleus';
import { selectPayload } from 'selectors/modalSelector';
import { MeetingUserPayload } from 'interfaces/User';
import intl from 'react-intl-universal';
import React from 'react';
import SearchMultiSelectComponent from 'components/Search/SearchMultiSelect';
import { CurrentUserObjectType } from 'interfaces/CurrentUser';
import { CreateMeetingMutation } from 'typings/types';
import MeetingInlineFragment = CreateMeetingMutation.MeetingInlineFragment;
import getFeatureFlag = featureSelectors.getFeatureFlag;
import { FEATURE_FLAG_MULTIPLE_MODERATORS } from 'constants/featureFlags';
import ContactSearchSelection from 'components/ContactSearchSelection/ContactSearchSelection';
import listAllUsersAndRoomsQuery from 'queries/joined/listAllUsersAndRoomSystemsQuery.gql';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { DummyField } from './DummyField';

export interface Props {
  isEditingExistingMeeting?: boolean;
  handleChange: Function;
}

export interface Moderator {
  UUID: string;
  displayName: string;
}

const mapModerators = (
  moderator: MeetingInlineFragment,
  currentUser: CurrentUserObjectType,
  isEditingExistingMeeting?: boolean,
  moderators?: MeetingInlineFragment[]
) => {
  const selfModeratorEntry = [{ uuid: currentUser?.id || '', name: currentUser?.name || '' }];
  if (!isEditingExistingMeeting) {
    return selfModeratorEntry;
  }

  let moderatorsList = moderators;
  if (!moderators) {
    moderatorsList = [moderator];
  }

  return moderatorsList?.filter(m => !!m?.UUID).map((moderatorEntry) => (
    { uuid: moderatorEntry?.UUID || '', name: moderatorEntry?.displayName || '' }
  )) || selfModeratorEntry;
};

export const ModeratorField = (props: Props) => {
  const { isEditingExistingMeeting, handleChange } = props;
  const currentUser = useSelector(currentUserSelectors.myInfo);
  const multipleModeratorsFeature = useSelector(getFeatureFlag)(FEATURE_FLAG_MULTIPLE_MODERATORS);
  const { moderator, moderators } = useSelector(selectPayload) as MeetingUserPayload;
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  if (multipleModeratorsFeature) {
    const mappedModerators = mapModerators(moderator, currentUser, isEditingExistingMeeting, moderators);
    if (!canEditMeetings) {
      return (
        <DummyField
          value={mappedModerators.map(({ name }) => name).join(', ')}
        />
      );
    }
    
    return (
      <SearchMultiSelectComponent
        placeholderText={intl.get('meetingModerator')}
        onSearchSelectionMade={(selection: object) => handleChange({ target: { name: 'moderator', value: selection }})}
        initialSelection={mappedModerators}
        searchQuery={listAllUsersAndRoomsQuery}
      />
    );
  }
  
  const currentSelection =
    isEditingExistingMeeting ?
      { id: moderator?.UUID || null, title: moderator?.displayName || '' } :
      { id: currentUser?.id || null, title: currentUser?.name || '' };

  if (!canEditMeetings) {
    return (
      <DummyField
        value={currentSelection.title}
      />
    );
  }

  return (
    <ContactSearchSelection
      placeholder={intl.get('meetingModerator')}
      onChange={(selection: object) => handleChange({ target: { name: 'moderator', value: selection }})}
      currentSelection={currentSelection}
      query={listAllUsersAndRoomsQuery}
    />
  );
};
