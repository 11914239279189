export enum ModalActionType {
  MODAL_OPEN = 'MODAL_OPEN',
  MODAL_CLOSE = 'MODAL_CLOSE',
  MODAL_PENDING_CLOSE = 'MODAL_PENDING_CLOSE'
}

export enum ModalTypes {
  ADD_GROUP_CONTACT = 'ADD_GROUP_CONTACT',
  ADD_MEETING = 'ADD_MEETING',
  ALEXA_DISCONNECT = 'ALEXA_DISCONNECT',
  ALEXA_REGISTER = 'ALEXA_REGISTER',
  ALEXA_UNREGISTER = 'ALEXA_UNREGISTER',
  CONNECT_TABLET = 'CONNECT_TABLET',
  CONVERT_TO_PAID = 'CONVERT_TO_PAID',
  DELETE_FROM_CLOUD = 'DELETE_FROM_CLOUD',
  DELETE_GROUP_CONTACT = 'DELETE_GROUP_CONTACT',
  DEVICE_CUSTOMIZATION = 'DEVICE_CUSTOMIZATION',
  EDIT_GROUP_CONTACT = 'EDIT_GROUP_CONTACT',
  EDIT_MEETING = 'EDIT_MEETING',
  EDIT_RECORDING_MODAL = 'EDIT_RECORDING_MODAL',
  MEETING_SHARE_RECORDING_MODAL = 'MEETING_SHARE_RECORDING_MODAL',
  MEETINGS_CONFIRM_DELETE = 'MEETINGS_CONFIRM_DELETE',
  MEETINGS_CONFIRM_EXPIRATION_DELETE = 'MEETINGS_CONFIRM_EXPIRATION_DELETE',
  REBOOT_DEVICE = 'REBOOT_DEVICE',
  RECORDINGS_CONFIRM_DELETE = 'RECORDINGS_CONFIRM_DELETE',
  REMOVE_FROM_CLOUD = 'REMOVE_FROM_CLOUD',
  RESET_DEVICE = 'RESET_DEVICE',
  ROOM_SYSTEM_INFO = 'ROOM_SYSTEM_INFO',
  SHARE_RECORDING_MODAL = 'SHARE_RECORDING_MODAL',
  TABLET_DELETE = 'TABLET_DELETE',
  TABLET_INFO = 'TABLET_INFO',
  UPLOAD_RECORDING = 'UPLOAD_RECORDING',
  USER_EDIT = 'USER_EDIT',
  USER_INVITE = 'USER_INVITE',
  USER_PROFILE = 'USER_PROFILE',
  USERS_CONFIRM_DELETE = 'USERS_CONFIRM_DELETE'
}
export interface ModalProps {
  modalType: ModalTypes;
  payload?: object;
}
