import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { featureSelectors } from '@lifesize/nucleus';
import { Global } from '../../state/Global';
import { DateRangePicker } from '@lifesize/react-components';
import actions from '../../actions/dashboardActions';
import { Moment } from 'moment';
import * as dashboardSelectors from 'selectors/dashboardSelectors';
import { getNewDWHDateRangePresets, isOutsideRange } from 'utils/date-time-utils';
import { OLD_DASHBOARD } from 'constants/dashboard';
import { FEATURE_FLAG_REMOVE_OLD_DASHBOARD } from 'constants/featureFlags';

export interface OwnPropsInterface {
  fetchData: Function;
  history: string[];
}

const mapStateToProps = (state: Global, ownProps: OwnPropsInterface) => {
  const removeOldDashboard = featureSelectors.getFeatureFlag(state)(FEATURE_FLAG_REMOVE_OLD_DASHBOARD);
  return {
    initialStart: dashboardSelectors.selectStartDateMoment(state),
    initialEnd: dashboardSelectors.selectEndDateMoment(state),
    presets: getNewDWHDateRangePresets(() => ownProps.history.push(OLD_DASHBOARD), removeOldDashboard),
    isOutsideRange
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: OwnPropsInterface) => ({
  onRangeChanged: async (dateStart: Moment, dateEnd: Moment) => {
    await dispatch(actions.handleSetDateRangeMoment(
      {
        startDate: dateStart,
        endDate: dateEnd
      }
    ));
    if (ownProps.fetchData) {
      ownProps.fetchData(dateStart, dateEnd);
    }
  },
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DateRangePicker));
