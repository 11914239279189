import _get from 'lodash/get';
import React from 'react';
import { Icon, Loader, Search } from 'semantic-ui-react';
import { SearchProps, SearchResultData } from 'semantic-ui-react/dist/commonjs/modules/Search/Search';
import { SearchInterface } from 'state/Search';
import * as styles from './Search.scss';
import classnames from 'classnames';

interface Props {
  className?: string;
  /** Setter for data */
  results: Array<SearchInterface>;
  placeholderText?: string;
  noResultsText?: string;
  /** Callback functions for consumers of this component */
  search: (arg: string) => void;
  onSearchCleared?: () => void;
  clearResults?: () => void;
  awaitResults?: () => void;
  onSearchSelectionMade: (id: string, selection?: string) => void;
  isFetching: boolean;
  value?: string;
  initialSelectionId?: string;
  initialSelectionText?: string;
  showResetButton?: boolean;
  clearOnFocus?: boolean;
  icon?: object;
}

const SearchLoader = () => (
  <div className={styles.loaderContainer}>
    <Loader size={'mini'} active={true} inline={true} />
  </div>
);

const SearchComponent = (props: Props) => {
  const {
    value,
    results,
    initialSelectionId,
    initialSelectionText,
    isFetching,
    onSearchCleared,
    search,
    noResultsText,
    onSearchSelectionMade,
    className,
    placeholderText,
    showResetButton,
    clearOnFocus,
    clearResults,
    awaitResults,
    icon
  } = props;

  const hasSelection = initialSelectionId && initialSelectionText;

  const [searchSelection, setSearchSelection] = React.useState(hasSelection ? initialSelectionText : '');
  const [currentValue, setCurrentValue] = React.useState(hasSelection ? initialSelectionText : value);
  const [prevSelectionText, setPrevSelectionText] = React.useState(hasSelection ? initialSelectionText : '');
  const [isSearching, setIsSearching] = React.useState(isFetching);

  const resetComponent = () => {
    setCurrentValue(undefined);
    setSearchSelection('');
  };

  const makeSearchRequest = async (searchString: string) => {
    const hasUserClearedText: boolean = ((searchSelection || 0) < 1 && searchString === '');
    if (searchString.length === 0) {
      if (!hasUserClearedText && onSearchCleared) {
        // Re-query the entire list from scratch
        onSearchCleared();
      }
      resetComponent();
    }
    // Only searches meetings to display to user
    setIsSearching(true);
    await search(searchString);
    setIsSearching(false);
  };

  const handleSearchChange = async (e: React.MouseEvent<HTMLElement>, searchProps: SearchProps) => {
    const searchString: string = searchProps.value || '';
    if (clearResults && awaitResults) {
      clearResults();
      awaitResults();
    }
    await setCurrentValue(searchString);
    await makeSearchRequest(searchString);
  };

  const getResultsText = () => {
    if (!isFetching && results.length === 0) {
      return noResultsText || '';
    }
    return '';
  };

  /**
   * User has made a selection of a meeting to re-query the list
   */
  const handleResultSelect = (event: React.MouseEvent<HTMLDivElement>, result: SearchResultData) => {
    // These can happen in parallel for efficiency
    const selection = _get(result, 'result', {});
    setCurrentValue(selection.title);
    setSearchSelection(selection.title);
    setPrevSelectionText(selection.title);
    onSearchSelectionMade(selection.id, selection.title);
  };

  /**
   * No reason to display spinner if Nucleus has not responded on
   * empty search string
   */
  const shouldShowLoadingIndicator = () => {
    return isFetching && !currentValue === null && currentValue !== '';
  };

  const clearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    onSearchSelectionMade('');
    setCurrentValue(undefined);
    setSearchSelection('');
  };

  const clearSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentValue(undefined);
  };

  const onBlur = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentValue(prevSelectionText);
  };

  return (
    <div className={styles.container}>
      <Search
        className={classnames(styles.style, className)}
        loading={shouldShowLoadingIndicator()}
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        results={isFetching ? [] : results}
        value={currentValue}
        noResultsMessage={isFetching || isSearching ? <SearchLoader /> : getResultsText()}
        placeholder={placeholderText}
        onFocus={clearOnFocus ? clearSearch : undefined}
        onBlur={clearOnFocus ? onBlur : undefined}
        icon={icon ? icon : undefined}
      />
      {currentValue && showResetButton && (
        <button
          className={classnames(styles.resetButton, 'clearSearch')}
          onClick={clearSelection}
          name="btnClearSelection"
          role="button"
          type="button"
        >
          <Icon className={'icon-cancel'}/>
        </button>
      )}
    </div>
  );
};

export default SearchComponent;
