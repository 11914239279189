import _get from 'lodash/get';
import React from 'react';
import intl from 'react-intl-universal';
import {
  DWHActiveUserResponse,
  DWHActiveUserResultType,
  DWHSummaryResponse,
  DWHSummaryResultType
} from 'interfaces/Dashboard';
import { DashboardLoader } from '../DashboardLoader';
import SummaryBox from './SummaryBox';
import * as styles from './SummaryBoxContainer.scss';
import _assign from 'lodash/assign';
import { accountSelectors, dataWarehouseSelectors } from '@lifesize/nucleus';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import getAccountFeatures = accountSelectors.getAccountFeatures;
import { ACCOUNT_FEATURE_PURCHASED_USERS } from 'constants/accountFeatureConstants';

interface Props {
  summaryData: DWHSummaryResponse;
  activeUserData: DWHActiveUserResponse;
}

const SummaryBoxComp = ({summaryData, activeUserData}: Props) => {
  const divId = 'SummaryBox';
  if (summaryData.isFetching || activeUserData.isFetching) {
    return (
      <DashboardLoader divId={divId} title={intl.get('accountSummary')} isWide={true}/>
    );
  }

  let data = _get(summaryData, 'data.data', null);
  let userData = _get(activeUserData, 'data.data', null);
  if (data && userData && data.length && userData.length) {
    data = data[0] as DWHSummaryResultType;
    userData = userData[0] as DWHActiveUserResultType;
    if (userData.totalUsers === 0) {
      userData.totalUsers = intl.get('unlimited');
    }
    return (
      <div className={styles.container}>
        <div className={styles.label}>
          {intl.get('accountSummary')}
        </div>
        <SummaryBox
          type={'calls'}
          dataPoint={data.calls.toString()}
          label={intl.get('totalCalls')}
        />
        <SummaryBox
          type={'minutes'}
          dataPoint={data.callMinutes.toString()}
          label={intl.get('totalMinutes')}
        />
        <SummaryBox
          type={'active'}
          dataPoint={`${userData.activeUsers}/${userData.totalUsers}`}
          label={intl.get('activeUsers')}
        />
        <SummaryBox
          type={'avg'}
          dataPoint={data.averageCallLength.toString()}
          label={intl.get('averageCall')}
        />
      </div>
    );
  }
  return <div/>;
};

export default SummaryBoxComp;

function mapStateToProps(state: Global) {
  let activeUserData = dataWarehouseSelectors.usersFilteredByActiveTotal(state);
  const userData = _get(activeUserData, 'data.data', null);
  const totalPurchasedUsers = getAccountFeatures(state)?.[ACCOUNT_FEATURE_PURCHASED_USERS];
  if (totalPurchasedUsers && userData && userData[0]) {
    // Override the totalUsers from DWH with purchased users
    activeUserData.data.data[0] = _assign(userData[0], { totalUsers: totalPurchasedUsers });
  }
  return {
    activeUserData: activeUserData,
    summaryData: dataWarehouseSelectors.summaryDataSelector(state),
  };
}

const mapDispatchToProps = (dispatch: Function) => ({});

export const SummaryBoxContainer = connect(mapStateToProps, mapDispatchToProps)(SummaryBoxComp);
