import { proxyActions } from '@lifesize/nucleus';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Methods } from 'interfaces/HTTP';
import { GetByDeviceInput, GetByPlatformInput, GetByGroupInput, SetByDeviceInput } from 'interfaces/UpgradeManagement';
import { UPGRADE_MANAGEMENT_SERVICE } from 'constants/upgradeManagementConstants';

export const getByPlatform = createAsyncThunk(
  'upgradeManagement-getByPlatform',
  async (input: GetByPlatformInput, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      UPGRADE_MANAGEMENT_SERVICE,
      'channels/getByPlatform',
      Methods.GET,
      input
    ));
    return JSON.parse(res);
  }
);

export const getByGroup = createAsyncThunk(
  'upgradeManagement-getByGroup',
  async (input: GetByGroupInput, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      UPGRADE_MANAGEMENT_SERVICE,
      'channels/getByGroup',
      Methods.GET,
      input
    ));
    return JSON.parse(res);
  }
);

export const getByDevice = createAsyncThunk(
  'upgradeManagement-getByDevice',
  async (input: GetByDeviceInput, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      UPGRADE_MANAGEMENT_SERVICE,
      'channels/getByDevice',
      Methods.GET,
      input
    ));
    return JSON.parse(res);
  }
);

export const setByDevice = createAsyncThunk(
  'upgradeManagement-setByDevice',
  async (input: SetByDeviceInput, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      UPGRADE_MANAGEMENT_SERVICE,
      'channels/setByDevice',
      Methods.POST,
      input
    ));
    return JSON.parse(res);
  }
);
