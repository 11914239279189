import { User } from 'typings/types';
import { useMutation } from 'react-apollo';
import removeDeviceFromUserMutation from 'queries/users/removeDeviceFromUserMutation.gql';
import intl from 'react-intl-universal';
import { ICON_DESCRIPTION } from 'constants/roomSystemConstants';
import { formatUnixDateAtTimeForDisplay } from 'utils/date-time-utils';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPayload } from 'selectors/modalSelector';
import { actions } from 'actions/modalActions';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_ROLE_MANAGEMENT } from 'constants/featureFlags';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export const PairedDevices = () => {
  const dispatch = useDispatch();
  const rolesFeatureFlag = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_ROLE_MANAGEMENT);
  const permissions = useSelector(selectAccountPermissions);
  const canModifyUser = rolesFeatureFlag ? permissions[ROLE_PERMISSION.MODIFY_USERS] : true;
  const closeModal = () => dispatch(actions.closeModal());
  const data = useSelector(selectPayload) as User;
  const pairedDevice = data?.pairedDevice;
  const [removePairedDeviceUser] = useMutation(removeDeviceFromUserMutation);
  if (!pairedDevice) {
    return <p>{intl.get('userDeviceNoDevicesAssociated')}</p>;
  }

  const iconPlatform = pairedDevice?.platform ? pairedDevice.platform.trim() : '';

  return (
    <>
      <dl key="deviceData">
        <dt>{intl.get('userDeviceLabelDevice')}:</dt>
        <dd>{ICON_DESCRIPTION[iconPlatform]}</dd>
        <dt>{intl.get('userDeviceLabelActivated')}:</dt>
        <dd>{formatUnixDateAtTimeForDisplay(parseInt(pairedDevice?.pairingTimeMillis || Date.now().toString(), 10))}</dd>
        <dt>{intl.get('userDeviceLabelVersion')}:</dt>
        <dd>{pairedDevice.firmware}</dd>
        <dt>{intl.get('userDeviceLabelSerial')}:</dt>
        <dd>{pairedDevice.serialNumber}</dd>
      </dl>
      {canModifyUser && <a
        onClick={async () => {
          try {
            await removePairedDeviceUser({
              variables: {
                UUID: data.UUID
              }
            });
            closeModal();
          } catch (e) {
            // not sure yet...
          }
        }}
      >
        {intl.get('disassociateDevice')}
      </a>}
    </>
  );
};
