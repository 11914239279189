import actions from 'actions/recordingsActions';
import SingleRecordingsContainer from 'containers/Recordings/SingleRecordingsContainer';
import React, { useEffect } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { match as Match } from 'react-router';
import { Link, RouteComponentProps } from 'react-router-dom';
import intl from 'react-intl-universal';
import { selectRecordingId } from 'selectors/SingleRecordingSelectors';
import { Global } from 'state/Global';
import { RECORDINGS_TABS } from 'constants/recordingsConstants';
import styles from './SingleRecordingView.scss';
import { recordingsSelectors } from '@lifesize/nucleus';
import { RecordingDataItem } from 'interfaces/RecordingDataItem';

const mapStateToProps = (state: Global) => {
  const singleRecordingState = recordingsSelectors.singleRecording(state);
  return {
    lastSelectedRecordingId: selectRecordingId(state),
    singleRecordingData: singleRecordingState.data,
    isFetching: singleRecordingState.isFetching
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  setRecordingId: (id: string) => {
    dispatch(actions.setRecordingId(id));
  }
});

interface Props {
  match: Match<{ id: string }>;
  lastSelectedRecordingId: string;
  setRecordingId: (id: string) => void;
  singleRecordingData: RecordingDataItem | null;
  isFetching: boolean;
}

const SingleRecordingViewSFC = (props: RouteComponentProps<{}> & Props) => {
  const { setRecordingId, match, singleRecordingData, isFetching } = props;
  const recordingId = _get(match, 'params.id');
  useEffect(
    () => {
      setRecordingId(recordingId);
      return () => setRecordingId('');
    },
    [recordingId]
  );
  const getBreadcrumbLabel = (from: string) => {
    switch (from) {
      case RECORDINGS_TABS.MY_RECORDINGS:
        return intl.get('headerMyRecordings');
      case RECORDINGS_TABS.WATCHLIST:
        return intl.get('headerRecordingsWatch');
      default:
        return intl.get('headerRecordingsFeed');
    }
  };
  return (
    <>
      {_get(props, 'history.location.state.from') && (
        <div className={'heading'}>
          <div className={'breadcrumbs'}>
            <Link to={{ pathname: _get(props, 'history.location.state.from'), state: _get(props, 'location.state', {})}}>
              {getBreadcrumbLabel(_get(props, 'history.location.state.from'))}
            </Link>
            {' > '}
            {!isFetching && _get(singleRecordingData, 'name')}
          </div>
        </div>
      )}
      <div className={styles.container}>
        <SingleRecordingsContainer/>
      </div>
    </>
  );
};

export const SingleRecordingView = SingleRecordingViewSFC;
// @ts-ignore
export const SingleRecordingViewContainer = connect(mapStateToProps, mapDispatchToProps)(SingleRecordingViewSFC);
