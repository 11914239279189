import { useEffect } from 'react';

export function useEventListener(type: keyof WindowEventMap, callback: (evt: Event) => void, options: EventListenerOptions = { capture: false }) {
  useEffect(
    () => {
      window.addEventListener(
        type,
        callback,
        options);
      return () => {
        window.removeEventListener(
          type,
          callback,
          options);
      };
    },
    []);
}

export default useEventListener;
