import * as styles from './RoleManagement.scss';
import intl from 'react-intl-universal';
import { Dropdown } from 'semantic-ui-react';
import React, { useCallback } from 'react';
import useRoleChange from 'hooks/useRoleChange';
import { PermissionSelection, Role } from 'interfaces/Role';

export interface Props {
  role: Role;
  options: PermissionSelection[];
  disabled?: boolean;
}

export const RoleDropdown = (props: Props) => {
  const { role, options, disabled } = props;

  const { savePermissions, loading } = useRoleChange(role);

  const selectedSetting = JSON.stringify(options?.find(option => option.selected)?.setting);
  const mappedOptions = useCallback(
    () => options.map((option) => ({
      text: intl.get(option.label),
      value: JSON.stringify(option.setting),
      id: option.label,
      key: option.label,
      disabled: option.disabled
    })),
    [JSON.stringify(options)]
  );

  const savePermissionFromSelection = useCallback(
    (option, {value}) => {
      savePermissions(JSON.parse(value));
    },
    [savePermissions]
  );

  return (
    <Dropdown
      className={styles.dropdown}
      closeOnBlur={true}
      disabled={loading || disabled}
      fluid={false}
      onChange={savePermissionFromSelection}
      options={mappedOptions()}
      search={false}
      selection={true}
      value={selectedSetting}
    />
  );
};
