import React, { SyntheticEvent } from 'react';
import classes from './Dropdown.scss';
import classnames from 'classnames';
import * as formStyles from 'components/Modals/ModalForms.scss';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';

export default ({
                  field: { name, value },
                  form: { setFieldValue },
                  id,
                  label,
                  options,
                  ...props
                }: {
  id: string,
  label?: string | JSX.Element | JSX.Element[],
  options: DropdownItemProps[],
  form: {
    setFieldValue: (name: string, newValue: unknown) => void
  },
  field: {
    name: string,
    value: boolean | number,
    onChange: (newValue: SyntheticEvent) => void
  }
}) => (
  <>
    {!!label && (
      <label className={classnames(formStyles.favorField, classes.label)}>
        {label}
      </label>
    )}
    <Dropdown
      id={id}
      name={name}
      onChange={(evt, data) => {
        setFieldValue(name, data.value);
      }}
      options={options}
      value={value}
      {...props}
    />
  </>
);
