import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelectors, localizationSelectors } from '@lifesize/nucleus';
import { ExportType } from 'interfaces/Downloads';
import { useMutation } from 'react-apollo';
import createDirectoryExport from 'queries/directoryExports/createDirectoryExport.gql';
import downloadNotificationActions from 'actions/downloadNotificationActions';
import { selectEndDate, selectStartDate } from 'selectors/recordingsSelectors';
import { string } from 'prop-types';

export function useExport(type: ExportType, role?: string) {
  const locale = useSelector(localizationSelectors.localeCode);
  const user = useSelector(currentUserSelectors.myInfo);
  const startTimestamp = useSelector(selectStartDate);
  const endTimestamp = useSelector(selectEndDate);
  const dispatch = useDispatch();
  const [ createExport, { loading }] = useMutation(createDirectoryExport);
  const handleExportData = async () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const variables =  {
      type,
      locale,
      timezone,
      pageSize: '10000',
      exportedBy: user.name,
      accountType: role,
      startTimestamp: startTimestamp ? String(startTimestamp.unix()) : '' ,
      endTimestamp: endTimestamp ? String(endTimestamp.unix()) : '' ,
    };
  const exportNotification = await createExport({
      variables
    });
  const downloadId = exportNotification?.data?.createDirectoryExport?.downloadId;
  if (downloadId) {
      dispatch(downloadNotificationActions.createDownloadNotification());
      dispatch(downloadNotificationActions.addPendingDownloads([downloadId]));
    }
  };

  return  { handleExportData, loading };
}

export default useExport;
