import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './MergeRequestModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelection } from 'selectors/selectionSelectors';
import { Methods } from 'interfaces/HTTP';
import { actions as selectionActions } from 'actions/selectionActions';
import { dispatchProxyApiCall } from 'utils/nucleusProxyUtils';
import { MergeRequestResponse } from 'interfaces/MergeRequest';
import _get from 'lodash/get';
import {
  MERGE_REQUEST_ERROR_EMAIL_NOT_FOUND,
  MERGE_REQUEST_ERROR_LIMIT_REACHED
} from 'constants/mergeAccountConstants';

interface Props {
  accept: boolean;
  closeModal: () => void;
  onMergeSuccess: () => void;
}

export const MergeRequestModal = (props: Props) => {
  const { accept, closeModal, onMergeSuccess } = props;
  const dispatch = useDispatch();
  const selectionArray = useSelector(selectSelection);
  const [ error, setError ] = useState<null | string>(null);
  const [ loading, setLoading ] = useState(false);

  let title: string;
  const isMultiple = selectionArray.length > 1;
  if (accept) {
    title = isMultiple ?
      intl.get('mergeConfirmationMulti') :
      intl.get('mergeConfirmationSingle');
  } else {
    title = isMultiple ?
      intl.get('mergeDeclineMulti') :
      intl.get('mergeDeclineSingle');
  }

  const respondToMergeRequest = async () => {
    const inputParams = selectionArray.map((item) => ({ action: accept ? 'ACCEPT' : 'DENY', requestUUID: item }));
    setLoading(true);
    setError(null);
    try {
      await dispatchProxyApiCall(
        dispatch,
        {},
        inputParams,
        'lifesize.user.merge',
        'merge',
        Methods.POST,
        () => {
          setLoading(false);
          dispatch(selectionActions.resetSelection());
          onMergeSuccess();
          closeModal();
        },
        (response: MergeRequestResponse) => {
          setError(intl.get('mergeFailure'));
          if (response.statusCode === 400) {
            switch (_get(response, 'body[0].errorCode')) {
              case MERGE_REQUEST_ERROR_LIMIT_REACHED:
                setError(intl.get('mergeLimitFailure'));
                break;
              case MERGE_REQUEST_ERROR_EMAIL_NOT_FOUND:
                setError(intl.get('mergeFailureEmailNotFound'));
                break;
              default:
                // do nothing
            }
          }
          setLoading(false);
        }
      );
    } catch (e) {
      setError(intl.get('mergeFailure'));
      setLoading(false);
    }
  };

  return (
    <Modal
      className={styles.container}
      onSave={respondToMergeRequest}
      closeModal={closeModal}
      saveText={intl.get('yes')}
      closeText={intl.get('no')}
      hideSaveButton={false}
      isSaveButtonBusy={loading}
    >
      <>
        <div>
          <div className={styles.title}>{title}</div>
        </div>
        {error && <div className={styles.error}>{error}</div>}
        </>
    </Modal>
  );
};

export default MergeRequestModal;
