import ClickableCell from 'components/ClickableCell/ClickableCell';
import React from 'react';
import intl from 'react-intl-universal';
import { RowInfo } from 'react-table';
import moment from 'moment';

export const liveStreamHistoryColumnConfig = (updateHistory: (url: string) => void) => {
  return (
    [{
      Header: intl.get('name'),
      accessor: 'streamName',
      Cell: (row: RowInfo) => {
        const streamName = encodeURIComponent(row.original.streamName);
        return (
          <ClickableCell
            id={`settings-${streamName}`}
            title={intl.get('liveStreamDetails')}
            isClickable={true}
            displayText={row.original.streamName}
            clickHandler={() => {
              if (!!row.original.recordingUUID) {
                // Navigate to live stream details view
                updateHistory(row.original.v2Data ?  `/meetings/livestreamSummary/${row.original.recordingUUID}/viewers` : `/meetings/livestream/${row.original.recordingUUID}/viewers`);
              }
            }}
          />
        );
      }
    }, {
      Header: intl.get('date'),
      accessor: 'startDateFormatted',
      sortMethod: (a: string, b: string) => {
        return moment(a) - moment(b);
      }
    }, {
      Header: intl.get('duration'),
      accessor: 'durationSeconds',
      Cell: (row: { original: { durationFormatted: string }}) => {
        return row.original.durationFormatted;
      }
    }, {
      Header: intl.get('viewers'),
      accessor: 'viewerCount'
    }]
  );
};
