import React from 'react';
import Pagination from 'containers/Pagination/PaginationContainer';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import * as styles from './ListViewLayout.scss';
import { ListViewLayoutHeader } from './ListViewLayoutHeader';

interface Props {
  breadcrumbs?: string | JSX.Element | JSX.Element[];
  error?: string;
  fetchListData?: Function;
  hideHeader?: boolean;
  isFetching?: boolean;
  numberOfPages?: number;
  title?: string | JSX.Element;
  headerComponent?: JSX.Element;
  toolbarComponent?: JSX.Element;
  children?: string | JSX.Element | JSX.Element[];
}

const getHeader = (props: Props) => {
  const {headerComponent, hideHeader, title, breadcrumbs} = props;
  if (hideHeader) { return null; }
  if (headerComponent) { return headerComponent; }
  return <ListViewLayoutHeader title={(title) ? title : ''} breadcrumbs={breadcrumbs} hide={false} />;
};

const ListViewLayout = (props: Props) => {
  const { fetchListData, breadcrumbs, children, hideHeader, title, isFetching, numberOfPages } = props;
  React.useEffect(
      () => {
        if (fetchListData) {
          fetchListData();
        }
      },
      []
  );

  if (!isFetching) {
    return (
        <div className={styles.container}>

          {getHeader(props)}

          <div className={styles.content}>
            {children}
          </div>
          {!!(numberOfPages && numberOfPages > 0) &&
            <Pagination
              numOfPages={numberOfPages}
            />
          }
        </div>
    );
  }

  return (
    <div className={styles.container}>
        {!hideHeader && <ListViewLayoutHeader title={title || ''} breadcrumbs={breadcrumbs} hide={false} />}
        {children}
        <LoadingIndicator />
    </div>
  );
};

export default ListViewLayout;
