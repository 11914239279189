import { connect } from 'react-redux';
import { currentUserSelectors } from '@lifesize/nucleus';
import { ModalTypes } from 'constants/modalConstants';
import { actions as ModalActions } from 'actions/modalActions';
import { Global } from 'state/Global';
import UsersTab from 'components/Users/Users/UsersTab';
import { Contact } from 'interfaces/Contact';
import { actions as resetPasswordActions } from 'actions/resetPasswordActions';

const mapDispatchToProps = (dispatch: Function) => ({
  editUser: async (contact: Contact) => {
    await dispatch(resetPasswordActions.clearResetPasswordState());
    dispatch(
      ModalActions.openModal({
        modalType: ModalTypes.USER_EDIT,
        payload: contact,
      }),
    );
  },
});

const mapStateToProps = (state: Global) => {
  return {
    currentUser: currentUserSelectors.myInfo(state)
  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UsersTab);
