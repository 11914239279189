export const generateCallChartConfig =
  (categories: Array<string>, series: Array<number>, dataColor: string, title: string, yAxisTitle: string) => {
    return {
      chart: {
        style: {
          fontFamily: 'ls-text-font'
        }
      },
      title: {
        text: title,
        style: {
          color: '#555555',
          fontSize: 16
        }
      },
      legend: { enabled: false },
      yAxis: {
        title: {
          text: yAxisTitle
        }
      },
      plotOptions: {
        area: {
          animation: false,
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 1,
            states: {
              hover: {
                enabled: true
              }
            }
          }
        }
      },
      xAxis: {
        categories: categories,
        tickInterval: Math.round(categories.length / 4),
        tickmarkPlacement: 'on',
      },
      series: [{
        color: dataColor,
        name: yAxisTitle,
        type: 'area',
        data: series
      }],
      credits: {
        enabled: false
      }
    };
  };

export const generateStackedBarChartConfig =
  (
    categories: Array<string>,
    series: Array<{ name: string, data: Array<number> }>,
    title: string,
    yAxisTitle: string
  ) => {
    return {
      chart: {
        style: {
          fontFamily: 'ls-text-font',
        },
        type: 'column',
      },
      title: {
        text: title,
        style: {
          color: '#555555',
          fontSize: 16
        }
      },
      yAxis: {
        title: {
          text: yAxisTitle
        }
      },
      plotOptions: {
        animation: false,
        series: {
          stacking: 'normal'
        }
      },
      xAxis: {
        categories: categories,
        tickInterval: Math.round(categories.length / 6),
        tickmarkPlacement: 'on'
      },
      series: series,
      credits: {
        enabled: false
      }
    };
  };

export interface SeriesType {
  name: string;
  data: Array<SeriesDataType>;
}

export interface SeriesDataType {
  name: string;
  y: number;
  key?: string;
  color?: string;
  altToolTipMessage?: string;
}

export const generateDonutChartConfig = (
  series: Array<SeriesType>,
  title: string,
  legendLabelFormatter: () => string,
  tooltipLabelFormatter: () => string,
  plotPointLabelFormatter: () => string,
) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      style: {
        fontFamily: 'ls-text-font'
      }
    },
    title: {
      text: title,
      style: {
        color: '#555555',
        fontSize: 16
      }
    },
    legend: {
      itemStyle: {
        fontWeight: 'normal' // This forces the font weight to normal so the <strong> tags are used
      },
      labelFormatter: legendLabelFormatter,
    },
    tooltip: {
      pointFormatter: tooltipLabelFormatter,
    },
    plotOptions: {
      animation: false,
      pie: {
        showInLegend: true,
        innerSize: '30%',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          formatter: plotPointLabelFormatter,
        },
      }
    },
    series: series,
    'credits': { 'enabled': false },
    'exporting': { 'enabled': false }
  };
};
