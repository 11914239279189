import React, { useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { Button, Dropdown } from 'semantic-ui-react';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import * as styles from 'components/Settings/Sections.scss';
import { getLocaleOptions } from 'utils/languageUtils';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const defaultLocale = 'en';
const defaultOptionId = 'default-none';

// props passed in
export interface OwnProps {
  selectedLocale: string;
  updateSection: Function;
}

const GroupLanguage = (props: OwnProps) => {
  const { updateSection, selectedLocale } = props;
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  // tslint:disable-next-line
  const formik = useRef<any>();

  useEffect(
    () => {
      const initialValues = formik?.current?.initialValues;
      // if there is no groupLocale selected, auto-select the defaultLocale after the first render so the form is 'dirty'
      if (initialValues?.groupLocale === defaultOptionId) {
          formik?.current?.setValues({
            groupLocale: defaultLocale
          });
        }
      },
    []
  );

  const onSubmit = (formData: object, callback: Function) => {
    updateSection(get(formData, 'groupLocale'), callback);
  };

  return (
    <section className={styles.container} data-test="settingsSection.language">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('settingsSectionTitleLanguage')}</h6>
        <Formik
          ref={formik}
          enableReinitialize={true}
          initialValues={{
            groupLocale: selectedLocale || defaultOptionId
          }}
          onSubmit={(formData, { setSubmitting }) => onSubmit(formData, () => setSubmitting(false))}
          render={({dirty, handleChange, isSubmitting, values}) => (
            <Form>
              <div className={`${styles.formSection} ${styles.horizontal}`}>
                <div className={`${styles.inputContainer} ${styles.inputGroup}`}>
                  <Dropdown
                    className={styles.inputLanguage}
                    closeOnBlur={true}
                    fluid={false}
                    name={'groupLocale'}
                    disabled={!canEditSettings}
                    onChange={(e, { value }) => {
                      if (!canEditSettings) { return; }
                      handleChange({ target: { name: 'groupLocale', value }});
                    }}
                    options={getLocaleOptions()}
                    selection={true}
                    value={values.groupLocale}
                    {...{ ['data-test']: 'settingsSectionDropDown.lang' }}
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <Button
                    id="btnSubmitGroupLanguage"
                    data-test="sectionBtnSubmit"
                    disabled={isSubmitting || !dirty || !canEditSettings}
                    name="btnSubmitGroupLanguage"
                    role="button"
                    type="submit"
                  >
                    {intl.get('settingsButtonLabelUpdate')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        />
      </article>
    </section>
  );
};

export default GroupLanguage;
