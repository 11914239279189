import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import paneClasses from 'components/RoomSystem/MaintenanceTab/MaintenanceTabComponent.scss';
import classes from './ResetDevicePasswordPane.scss';
import { isRoomSystemOffline } from 'utils/roomSystemUtils';
import { RoomSystem } from 'typings/types';
import ResetDevicePasswordModal from './ResetDevicePasswordModal/ResetDevicePasswordModal';

export interface Props {
  roomSystem: RoomSystem;
}

const ResetDevicePasswordPane = ({ roomSystem }: Props) => {
  const [ passwordResetSuccess, setPasswordResetSuccess ] = useState(false);
  const [ isResetModalOpen, setResetModalOpen ] = useState(false);
  const isOffline = isRoomSystemOffline(roomSystem);
  return (
    <>
    <div className={paneClasses.pane}>
      <div className={paneClasses.contentContainer}>
        <div className={paneClasses.paneTitle}>
          {intl.get('adminResetPassword')}
        </div>
        <div className={classes.paneBody}>
          <div className={paneClasses.textContainer}>
            <div className={classes.shortContainer}>
              {intl.get('resetIconPassword')}
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
                id="resetIconPw"
                name="resetIconPw"
                onClick={() => setResetModalOpen(true)}
                role="button"
                type="button"
                disabled={isOffline}
            >
              {intl.get('reset')}
            </Button>
            <span className={classes.success}>{passwordResetSuccess ? intl.get('resetDevicePasswordSuccess') : ''}</span>
          </div>
        </div>
      </div>
    </div>
      {isResetModalOpen &&
        <ResetDevicePasswordModal
          serialNumber={roomSystem.serialNumber}
          resetDevicePasswordSuccess={() => setPasswordResetSuccess(true)}
          closeModal={() => setResetModalOpen(false)}
        />
      }
    </>
  );
};

export default ResetDevicePasswordPane;
