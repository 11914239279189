import React from 'react';
import intl from 'react-intl-universal';
import { Column, RowInfo } from 'react-table';
import {
  hasKnownDeviceSubscription,
  isControllerConnected,
  roomSystemSupportsDSS
} from 'utils/roomSystemUtils';
import ControllerCell from './CustomCells/ControllerCell';
import DisplayNameCell from './CustomCells/DisplayNameCell';
import { UNKNOWN } from 'constants/roomSystemConstants';
import { OnlineStatus } from './OnlineStatus/OnlineStatus';
import { Sort } from 'interfaces/Meeting';
import DeviceSubscriptionStatusPill from 'components/DeviceSubscriptionStatusPill/DeviceSubscriptionStatusPill';
import { DeviceSubscriptionState } from 'constants/deviceSubscriptionConstants';
import { RoomSystem } from 'typings/types';

/**
 * If pairingStatus is DELETED (unpaired) or null, then display nothing for Controller.
 * If tablet, and no linked devices, also return null
 */
export const getControllerCell = (row: RowInfo) => {
  const { phoneModelNumber, linkedDevices } = row.original;
  if (!isControllerConnected(phoneModelNumber, linkedDevices)) {
    return null;
  }
  return (
    <div>
      <ControllerCell
        row={row}
      />
    </div>
  );
};

export const roomSystemColumnConfig = (sort: Sort, filterString: string | null, isDeviceSubscriptionHidden: boolean): Column[] => {
  let columnConfig = [
    {
      Header: intl.get('name'),
      accessor: 'systemName',
      Cell: (row: RowInfo) => {
        return (
          <DisplayNameCell
            row={row}
            sort={sort}
            filterString={filterString}
          />
        );
      },
    }, {
      Header: intl.get('status'),
      accessor: 'availability.status',
      Cell: (row: RowInfo) => {
        const { systemName, availability } = row.original;
        if (!systemName) {
          return null;
        }

        return (
          <OnlineStatus regStatus={(availability) ? availability.status : UNKNOWN} />
        );
      },
    }, {
      Header: intl.get('deviceSubscription'),
      // @ts-ignore
      accessor: (d: RoomSystem) => {
        const sortMap = {
          [DeviceSubscriptionState.CURRENT]: 1,
          [DeviceSubscriptionState.EXPIRED]: 2
        };
        if (!roomSystemSupportsDSS(d)) {
          return 3;
        } else if (!sortMap[d?.deviceSubscription?.licenseStatus]) {
          return 2;
        }
        return sortMap[d?.deviceSubscription?.licenseStatus];
      },
      id: 'licenseStatus',
      minWidth: 120,
      Cell: (row: RowInfo) => {
        const licenseStatus = row.original?.deviceSubscription?.licenseStatus;
        const licenseExpiration = row.original?.deviceSubscription?.licenseExpiration;
        const deviceSubscriptionExists = hasKnownDeviceSubscription(licenseStatus);

        // Must be a paired GICON or GEMS device.
        if (!roomSystemSupportsDSS(row.original)) {
          return null;
        }

        return (
          <div style={{ width: '120px', display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '120px' }}>
              <DeviceSubscriptionStatusPill
                deviceIsActive={licenseStatus === DeviceSubscriptionState.CURRENT}
                licenseExpiration={licenseExpiration}
                showExpirationInDays={false}
                deviceSubscriptionExists={deviceSubscriptionExists}
              />
            </div>
          </div>
        );
      },
    }, {
      Header: intl.get('extension'),
      accessor: 'extension',
    }, {
      Header: intl.get('usage'),
      accessor: 'usageText',
    }, {
      Header: intl.get('videoType'),
      accessor: 'videoType',
    }, {
      Header: intl.get('controllerType'),
      accessor: 'controllerText',
      Cell: (row: RowInfo) => {
        return getControllerCell(row);
      },
    }
  ];

  if (isDeviceSubscriptionHidden) {
    columnConfig.splice(2, 1);
  }

  return columnConfig;
};
