import { Reducer } from 'redux';
import {
  UserStateActionType
} from 'actions/userStateActions';
import { UserState, initialUserState } from 'state/UserState';

const userStateReducer: Reducer<UserState> =
  (state = initialUserState, action) => {
    switch (action.type) {
      case UserStateActionType.SET_ACTIVITY:
        return {
          ...state,
          active: action.payload
        };
      default:
        return state;
    }
  };

export default userStateReducer;
