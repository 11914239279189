import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { vcApiActions, dataWarehouseSelectors } from '@lifesize/nucleus';
import LiveStreamsTab from 'components/Meetings/LiveStreamsTab/LiveStreamsTab';
import { defaultRowCount } from 'constants/listViewLayoutConstants';
import { selectPageNumber } from 'selectors/paginationSelectors';
import { liveStreamsSelector } from 'selectors/liveStreamsSelector';
import { Global } from 'state/Global';
import { selectFilterString } from 'selectors/filterSelectors';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchListData: () => {
    dispatch(vcApiActions.getAllStreams());
  }
});

function mapStateToProps(state: Global) {
  const allStreams = dataWarehouseSelectors.streamsAll(state);
  const liveStreams = liveStreamsSelector(state);
  const filterString = selectFilterString(state);
  let listData = liveStreams.data;
  const pageSize = defaultRowCount;

  if (filterString && filterString.length) {
    listData = listData.filter((data) => {
      return (data && data.displayName && data.displayName.includes(filterString));
    });
  }

  const numberOfPages = Math.ceil(listData.length / pageSize);

  return {
    error: allStreams.error,
    isFetching: allStreams.isFetching || liveStreams.isFetching,
    listData,
    numberOfPages,
    pageNumber: selectPageNumber(state),
    pageSize,
    filterString
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamsTab);
