import React, { useEffect } from 'react';
import * as styles from './CustomWallPaperUploader.scss';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export interface Props {
  onDrop: DropFilesEventHandler;
  hasTypeError: boolean;
  hasDimensionsError: boolean;
  hasSizeError: boolean;
  filename?: string;
  imagePreviewURL?: string;
  fetchExistingUrl: Function;
  isCustomWallPaperEnabled: boolean;
  clearErrors: () => void;
}

const CustomWallPaperUploader = (props: Props) => {
  const {
    onDrop,
    hasTypeError,
    hasDimensionsError,
    hasSizeError,
    filename,
    imagePreviewURL,
    isCustomWallPaperEnabled,
    fetchExistingUrl,
    clearErrors
  } = props;

  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  useEffect(
    () => {
      fetchExistingUrl();
      return clearErrors;
    },
    []
  );

  return (
    <div className={styles.container}>
      <div className={styles.uploaderContainer}>
        <Dropzone
          onDrop={
            (accepted: File[], rejected: File[], event: React.DragEvent<HTMLDivElement>) =>
              onDrop(accepted, rejected, event)
          }
          disabled={!canEditSettings}
          multiple={false}
          className={styles.dropzone}
        >
          {(!imagePreviewURL || !isCustomWallPaperEnabled) ?
            <>
              <Icon classes={['icon-upload', styles.icon]} />
              {intl.get('dropUpload')}
              <div className={styles.browse}>
                {intl.get('dropBrowse')}
              </div>
            </> :
            <img src={imagePreviewURL} />
          }

        </Dropzone>
        {
          (hasTypeError) &&
            <div className={styles.error}>
              <span>{filename} </span>
              {intl.get('typeError')}
            </div>
        }
        {
          (hasDimensionsError) &&
            <div className={styles.error}>
              <span>{filename} </span>
              {intl.get('dimensionError')}
            </div>
        }
        {
          (hasSizeError) &&
            <div className={styles.error}>
              <span>{filename} </span>
              {intl.get('sizeError')}
            </div>
        }
      </div>
    </div>
  );
};

export default CustomWallPaperUploader;
