import { nucleusConstants } from '@lifesize/nucleus';
import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import _get from 'lodash/get';
import gql from 'graphql-tag';
import { getClient } from '../nucleus-apollo-client';
import { ListUsersQuery } from '../typings/types';

const updateQuery = gql`
  query {
    directoryList(filter: USER)
      @connection(key: "userDirectory", filter: ["query", "filter"]) {
      records {
        UUID
        __typename
      }
    }
  }
`;

const usersMiddleware = (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) =>  async (action: Action) => {
  const result = next(action);
  switch (action.type) {
    case nucleusConstants.CONTACT_NOTIFICATION_DELETED:
    case nucleusConstants.USER_SERVICE_DELETE_SUCCESS:
    case nucleusConstants.USER_SERVICE_DELETE_LIST_SUCCESS:
      const uuids =
        action.type === nucleusConstants.CONTACT_NOTIFICATION_DELETED ?
          [_get(action, 'payload')] :
          _get(action, 'payload.body.uuids', []).map((uuidObj: { uuid: string }) => uuidObj.uuid);
      const client = await getClient(store.dispatch);
      const data = client.readQuery({ query: updateQuery }) as ListUsersQuery.Query;
      if (data && data.directoryList && data.directoryList.records) {

        const filteredRecords = data.directoryList.records.filter(
          (record) =>
            record && !uuids.includes(record.UUID)
        );

        data.directoryList.records = filteredRecords;
      }
      client.writeQuery({ query: updateQuery, data });
      break;
    default:
    // no default
  }
  return result;
};

export default usersMiddleware as Middleware;
