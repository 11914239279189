import ListViewComponent from 'components/ListView/ListViewComponent';
import _memoize from 'lodash/memoize';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Icon from 'components/Common/Icon';
import { Link } from 'react-router-dom';
import { DWHUserCallsResultType } from 'interfaces/Dashboard';
import { exportToCsv } from 'utils/csv-utils';
import { parseUserDrillDownDataForExport } from 'utils/exportUtils';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import DrilldownHeader from 'components/Drilldowns/Common/DrilldownHeader2';
import { useQuery } from 'react-apollo';
import UserTotalsQuery from 'queries/dashboard/UserTotalsQuery.gql';
import { defaultRowCount } from 'constants/listViewLayoutConstants';
import _get from 'lodash/get';
import { RowInfo } from 'react-table';
import { Moment } from 'moment';
import SearchBar from 'components/Search/SearchBar';
import { SortDirection } from 'constants/queryConstants';
import { SetSortInterface } from 'interfaces/Tables';

interface Props {
  dateStart: Moment;
  dateEnd: Moment;
  searchString: string;
  pageNumber: number;
  onSelect: Function;
  sort?: SetSortInterface;
  search?: string;
}

const UsersTable = (props: Props) => {
  const defaultSort: SetSortInterface = {
    sortBy: 'totalMinutes',
    order: SortDirection.DESC
  };
  const initialSort = props.sort || defaultSort;
  const initialFilterString = props.search;
  const [sort, setSort] = useState<SetSortInterface>(initialSort);
  const [search, setSearch] = useState(initialFilterString);
  const { data, loading, refetch, error } = useQuery(UserTotalsQuery, {
    variables: {
      dateStart: props.dateStart.format('YYYY-MM-DD'),
      dateEnd: props.dateEnd.format('YYYY-MM-DD')
    },
    fetchPolicy: 'network-only'
  });

  const getTitle = _memoize(() => {
    return (
      <>
        <Icon classes={['icon-room-system']} />
        <span>{intl.get('users')}</span>
      </>
    );
  });

  const handleExportToCsv = (eData: DWHUserCallsResultType[]) => {
    const exportData = eData.sort(
      (a: DWHUserCallsResultType, b: DWHUserCallsResultType) => {
        return b.totalMinutes - a.totalMinutes;
      }).map(parseUserDrillDownDataForExport);
    exportToCsv(intl.get('users'), exportData);
  };

  const getFilteredData = (fData: DWHUserCallsResultType[]) => {
    if (search) {
      return fData.filter(record => record.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
    return fData;
  };

  const allRows = _get(data, 'userTotalsTable', []);
  const records = getFilteredData(allRows);
  return (
    <ListViewLayout
      error={_get(error, 'message', undefined)}
      hideHeader={true}
      isFetching={loading}
      title={getTitle()}
    >
      <SearchBar
        setFilterString={(newString) => {
          setSearch(newString);
        }}
        initialValue={initialFilterString}
      />

      <DrilldownHeader
        fetchData={refetch}
        onExportClick={() => handleExportToCsv(records)}
      />

      {loading ? <div /> :
        <ListViewComponent
          data={records}
          columns={
            [{
              Header: intl.get('tableHeaderName'),
              accessor: 'name',
              Cell: (row: RowInfo) => (
                <Link
                  title={row.original.name}
                  to={{
                    pathname: `/drilldown/usersVideos/users2/${encodeURIComponent(row.original.userId)}`,
                    state: {
                      sort,
                      search
                    }
                  }}
                >
                  {row.original.name}
                </Link>
              )
            }, {
              Header: intl.get('minutes'),
              accessor: 'totalMinutes',
            }, {
              Header: intl.get('calls'),
              accessor: 'totalCalls'
            }]
          }
          showPagination={false}
          maxRows={defaultRowCount}
          pageNumber={props.pageNumber}
          defaultSorted={[
            {
              id: sort.sortBy,
              desc: sort.order === SortDirection.DESC
            }
          ]}
          onSortedChange={(newSorted) => {
            setSort({
              sortBy: newSorted[0].id,
              order: newSorted[0].desc ? SortDirection.DESC : SortDirection.ASC
            });
          }}
          defaultPageSize={allRows.length}
        />
      }
    </ListViewLayout>
  );
};

export default UsersTable;
