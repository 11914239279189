import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import classnames from 'classnames';
import * as styles from 'components/Settings/Sections.scss';
import WallPaperPanel from 'containers/WallPaperPanel/WallPaperPanelContainer';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

interface Props {
  enableCustomBackground: Function;
  isCustomWallPaperEnabled: boolean;
  imagePreviewURL: string;
  isUpdateEnabled: boolean;
  saveWallpaper: Function;
}

const IconBackground = (props: Props) => {
  const {
    isCustomWallPaperEnabled,
    enableCustomBackground,
    imagePreviewURL,
    saveWallpaper,
    isUpdateEnabled
  } = props;

  const [ updateDisabled, setUpdateDisabled ] = useState(true);
  const [ isCustomWallPaperEnabledSetting ] = useState(isCustomWallPaperEnabled);
  const [ imagePreviewURLSetting ] = useState(imagePreviewURL);
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  useEffect(
    () => {
      if ((isCustomWallPaperEnabled !== isCustomWallPaperEnabledSetting) || (imagePreviewURL !== imagePreviewURLSetting)) {
        setUpdateDisabled(false);
      }
    },
    [isCustomWallPaperEnabled, isCustomWallPaperEnabledSetting, imagePreviewURL, imagePreviewURLSetting]
  );

  return (
    <section className={styles.container} data-test="settingsSection.iconBackgrounds">
      <article className={styles.columns}>
        <div>
          <h6 className={styles.title} data-test="sectionTitle">{intl.get('iconBackgrounds')}</h6>
          <p className={styles.description}>{intl.get('iconBackgroundsHelper')}</p>
          <p className={classnames(styles.description, styles.em)}>{intl.get('iconBackgroundsHelper2')}</p>
          <Form>
            <div className={styles.formSection}>
              <Form.Field className={styles.optionGroup}>
                <Checkbox
                  radio={true}
                  label={intl.get('noBackground')}
                  name="wallPaperGroup"
                  checked={!isCustomWallPaperEnabled}
                  disabled={!canEditSettings}
                  onChange={() => {
                    if (!canEditSettings) { return; }
                    enableCustomBackground(false);
                  }}
                />
              </Form.Field>
              <Form.Field className={styles.optionGroup}>
                <Checkbox
                  radio={true}
                  label={intl.get('customBackground')}
                  name="wallPaperGroup"
                  checked={isCustomWallPaperEnabled}
                  disabled={!canEditSettings}
                  onChange={() => {
                    if (!canEditSettings) { return; }
                    enableCustomBackground(true);
                  }}
                />
              </Form.Field>
            </div>
          </Form>
        </div>
        <div className={styles.formSection}>
          <WallPaperPanel />
          <div className={styles.buttonContainer}>
            <Button
              data-test="sectionBtnSubmit"
              disabled={!isUpdateEnabled || updateDisabled || !canEditSettings}
              name="btnUpdateCustomBackground"
              onClick={
                (event: React.MouseEvent<HTMLButtonElement>) => {
                  if (!canEditSettings) { return; }
                  saveWallpaper(imagePreviewURL, isCustomWallPaperEnabled);
                }
              }
              role="button"
              type="button"
            >
              {intl.get('settingsButtonLabelUpdate')}
            </Button>
          </div>
        </div>
      </article>
    </section>
  );
};

export default IconBackground;
