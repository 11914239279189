import _isNumber from 'lodash/isNumber';

interface TabChangeProperties {
  selectedTab: number;
  setSelectedTab: (tab: number) => void;
  keyToActiveIndex: {};
  activeIndex: string | number | undefined;
  push: Function;
}

export const getRouteForIndex = (keyToActiveIndex: {}, index: number, defaultTab: string) => {
  for (const key in keyToActiveIndex) {
    if (keyToActiveIndex.hasOwnProperty(key) && keyToActiveIndex[key] === index) {
      return key;
    }
  }
  return defaultTab;
};

export const handleTabChange = ({selectedTab, setSelectedTab, keyToActiveIndex, activeIndex, push}: TabChangeProperties) => {
  if (_isNumber(activeIndex) && selectedTab !== activeIndex) {
    setSelectedTab(activeIndex);
  }
  const newActiveIndex = activeIndex ? parseInt(activeIndex as string, 0) : 0;
  const url = getRouteForIndex(keyToActiveIndex, newActiveIndex, keyToActiveIndex[0]);
  push(url);
};
