import React from 'react';

interface Props {
  breadcrumbs?: string | JSX.Element | JSX.Element[];
  hide?: boolean;
  subtitle?: string | JSX.Element | JSX.Element[];
  title: string | JSX.Element;
  titleRight?: false | string | JSX.Element | JSX.Element[];
}

export const ListViewLayoutHeader: React.FunctionComponent<Props> = ({ breadcrumbs, hide, subtitle, title, titleRight}) => {
  // In some cases we don't want to include a header on list view layout
  // @See ListViewLayout.tsx
  if (hide) {
    return <React.Fragment/>;
  }
  return (
    <div className={'heading'}>
      {!!breadcrumbs && (
        <div className={'breadcrumbs'}>{breadcrumbs}</div>
      )}
      <h1 className={'title'} data-test="headingTitle">
        {title}
      </h1>
      {titleRight && (
        <div className={'titleRightContainer'}>
          {titleRight}
        </div>
      )}
      <div className={'subtitle'}>{subtitle}</div>
    </div>
  );
};
