import { Reducer } from 'redux';
import { SortState, initialSortState } from 'state/Sort';
import { SortActionType } from 'constants/sortConstants';

const sortReducer: Reducer<SortState> =
  (state = initialSortState, action) => {
    switch (action.type) {
      case SortActionType.SET_SORT:
        const { sortBy, order } = action.payload;
        return {
          ...state,
          sortBy,
          order
        };
      default:
        return state;
    }
  };

export default sortReducer;
