import React, { useState } from 'react';
import intl from 'react-intl-universal';
import { Logger } from 'logger';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './ResetDevicePasswordModal.scss';
import { useDispatch } from 'react-redux';
import { dispatchProxyApiCall } from 'utils/nucleusProxyUtils';
import {
  deviceManagementEndpoint,
  deviceManagementResetPasswordUri,
} from 'constants/deviceManagementConstants';
import { Methods } from 'interfaces/HTTP';

interface Props {
  serialNumber: string;
  resetDevicePasswordSuccess: () => void;
  closeModal: () => void;
}

export const ResetDevicePasswordModal = (props: Props) => {
  const { serialNumber, resetDevicePasswordSuccess, closeModal } = props;
  const [ error, setError ] = useState(false);
  const [ resettingPassword, setResettingPassword ] = useState(false);
  const dispatch = useDispatch();
  const resetDevicePassword = async () => {
    try {
      setResettingPassword(true);
      let inputParams = {serialNumber};
      await dispatchProxyApiCall(
        dispatch,
        {},
        inputParams,
        deviceManagementEndpoint,
        deviceManagementResetPasswordUri,
        Methods.POST,
        () => {
          resetDevicePasswordSuccess();
          setResettingPassword(false);
          closeModal();
        },
        (e: string) => {
          Logger.info(`Failed to reset device password: ${JSON.stringify(e)}`);
          setResettingPassword(false);
          setError(true);
        }
      );
    } catch (e) {
      Logger.info(`Error issuing password reset command:  ${e.toString()}`);
      setResettingPassword(false);
      setError(true);
    }
  };

  return (
    <Modal
      className={styles.container}
      onSave={resetDevicePassword}
      closeModal={closeModal}
      saveText={intl.get('reset')}
      closeText={intl.get('cancel')}
      hideSaveButton={false}
      isSaveButtonBusy={resettingPassword}
      errorMessage={error ? intl.get('resetDevicePasswordFailure') : undefined}
    >
      <div>
        <div className={styles.title}>{intl.get('resetDevicePasswordConfirmation')}</div>
      </div>
    </Modal>
  );
};

export default ResetDevicePasswordModal;
