import React from 'react';
import { RecordingDataArray, RecordingDataItem } from '../../interfaces/RecordingDataItem';
import { RecordingRequestObjectFeed } from 'constants/recordingsConstants';
import { RecordingListingParamsType } from 'interfaces/Recording';
import Icon from 'components/Common/Icon';
import RecordingsTile from './RecordingsTile';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import * as styles from './RecordingsTileView.scss';

export interface Props {
  isLoading: boolean;
  pageType: RecordingRequestObjectFeed;
  recordingData: RecordingDataArray;
  listingParams: RecordingListingParamsType;
}

const renderNoResults = (pageType: RecordingRequestObjectFeed) => {
  switch (pageType) {
    case RecordingRequestObjectFeed.FEED_ALL:
      return intl.get('noResultsRecordingsFeed');
    case RecordingRequestObjectFeed.FEED_MY_RECORDINGS:
      return intl.get('noResultsMyRecordings');
    default:
      return intl.get('noResultsRecordingsWatch');
  }
};

const renderItems = (recordings: Array<RecordingDataItem>, listingParams: RecordingListingParamsType) => {
  return recordings.map( (item: RecordingDataItem, index) => {
    return <RecordingsTile recording={item} key={index} listingParams={listingParams}/>;
  }) ;
};

class RecordingsTileView extends React.PureComponent<Props> {
  render() {
    const { isLoading, pageType, recordingData, listingParams } = this.props;

    if (isLoading) {
      return (
        <div className={classnames(styles.container, styles.loading)}>
          <LoadingIndicator/>
        </div>
      );
    }

    if (!isLoading && recordingData && recordingData.length === 0) {
      return (
        <div className={classnames(styles.container, styles.loading)}>
          <Icon classes={['icon-record']} />
          <h4>{renderNoResults(pageType)}</h4>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        {renderItems(recordingData, listingParams)}
      </div>
    );
  }
}

export default RecordingsTileView;
