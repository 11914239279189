import { connect } from 'react-redux';
import deviceManagementActions from 'actions/deviceManagementActions';
import ResetModal from 'components/Modals/ResetDeviceModal/ResetDeviceModal';
import { actions as ModalActions } from 'actions/modalActions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => dispatch(ModalActions.closeModal()),
  reset: (serialNumber: string) => dispatch(deviceManagementActions.resetDevice(serialNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetModal);
