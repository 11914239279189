import React from 'react';
import SearchBar from 'components/Search/SearchBar';
import UsersHeaderToolbar from 'containers/Users/UsersToolbarContainer';

interface Props {
  onFilterStringChange: (filterValue: string) => void;
  hideToolbar?: boolean;
}

class UsersHeader extends React.PureComponent<Props> {
  render() {
    return [
      <SearchBar setFilterString={this.props.onFilterStringChange} key="searchbar" />,
      (!this.props.hideToolbar && <UsersHeaderToolbar key="toolbar" />)
    ];
  }
}

export default UsersHeader;
