import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Input } from 'semantic-ui-react';
import _debounce from 'lodash/debounce';
import * as styles from './SearchBar.scss';
import classnames from 'classnames';

interface Props {
  setFilterString: (filterString: string) => void;
  initialValue?: string;
}

const SearchBar = (props: Props) => {
  const { setFilterString, initialValue } = props;
  const handleOnChange = _debounce(
    (searchString: string) => {
      setFilterString(searchString);
    },
    250
  );

  useEffect(
    () => () => setFilterString(initialValue || ''),
    []
  );

  return (
    <div className={classnames(styles.container, 'searchbar')} id={'searchComponent'}>
      <Input
        id={'searchContainer'}
        className={'icon-search-alt'}
        iconPosition="left"
        placeholder={intl.get('searchPlaceholder')}
        onChange={(event: React.SyntheticEvent<HTMLInputElement>) => handleOnChange(event.currentTarget.value)}
        type="text"
        transparent={false}
        defaultValue={initialValue || ''}
      />
    </div>
  );
};

export default SearchBar;
