import Can from 'components/Can/CanComponent';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { PermissionTypes } from 'constants/permissionsConstants';
import React, { useEffect, useState } from 'react';
import Chat from './Chat';
import ChatIntegrations from './ChatIntegrations';
import DomainsFilter from './DomainsFilter';
import Language from './Language';
import RecordAndShare from './RecordAndShare';

// props from Redux Store (mapStateToProps)
export interface StateProps {
  chatEnabled: boolean;
  domainsFilter: string;
  groupLocale: string;
  recordingEnabled: boolean;
  recordingPublicSharing: boolean;
  isChatFeatureEnabled: boolean;
  isFetchingRecordingSettings: boolean;
}

// props from Actions (mapDispatchToProps)
export interface DispatchProps {
  fetchData: Function;
  updateChatEnabled: Function;
  updateDomainsFilter: Function;
  updateGroupLocale: Function;
  updateRecording: Function;
  updateRecordingPublicSharing: Function;
}

type Props = StateProps & DispatchProps;

export const GeneralSettings = (props: Props) => {
  const {
    chatEnabled,
    domainsFilter,
    groupLocale,
    recordingEnabled,
    recordingPublicSharing,
    updateChatEnabled,
    updateDomainsFilter,
    updateGroupLocale,
    updateRecording,
    updateRecordingPublicSharing,
    isChatFeatureEnabled,
    isFetchingRecordingSettings,
    fetchData
  } = props;

  const [loading, setLoading] = useState(true);

  const asyncFetchData = async () => {
    await fetchData();
    setLoading(false);
  };

  useEffect(() => {
    asyncFetchData();
  },        []);

  if (loading) {
    return <LoadingIndicator/>;
  }

  return (
    <>
      {
        [
          (
            <Language
              key="language"
              selectedLocale={groupLocale}
              updateSection={updateGroupLocale}
            />
          ),
          isChatFeatureEnabled && (
            <Chat
              key="chat"
              chatEnabled={chatEnabled}
              updateSection={updateChatEnabled}
            />
          ),
          (
            <Can key="canRecordAndShare" userAccess={[PermissionTypes.ACCOUNT_RECORDINGS]}>
              {!isFetchingRecordingSettings && <RecordAndShare
                key="recordAndShare"
                recordingEnabled={recordingEnabled}
                recordingPublicSharing={recordingPublicSharing}
                updateRecording={updateRecording}
                updateRecordingPublicSharing={updateRecordingPublicSharing}
              />}
            </Can>
          ),
          (
            <DomainsFilter
              key="domainsFilter"
              domainsFilter={domainsFilter}
              updateSection={updateDomainsFilter}
            />
          ),
          (
            <ChatIntegrations
              key="chatIntegrations"
            />
          )
        ]
      }
    </>
  );
};

export default GeneralSettings;
