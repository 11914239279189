import { Download } from 'interfaces/Downloads';
import { ApolloError } from 'apollo-client';

export interface ExportInterface {
  exports: Download[];
  loading: boolean | undefined;
  error: ApolloError | undefined;
}

export const initialState: ExportInterface = {
  exports: [],
  loading: false,
  error: undefined
};

export default initialState;
