import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'semantic-ui-react';
import { RoomSystem, LinkedDevice } from 'typings/types';
import { Mutation } from 'react-apollo';
import unLinkTabletMutation from 'queries/roomSystems/unlinkTabletMutation.gql';
import { Logger } from 'logger';
import _get from 'lodash/get';
import classes from './ControllerTabComponent.scss';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';
import { ApolloError } from 'apollo-client';

interface  Props {
  roomSystem: RoomSystem;
  tablet?: LinkedDevice;
  isCustomizable: boolean;
  openHdPhoneCustomization: () => void;
  canConnectTablet: boolean;
  canDisconnectTablet: boolean;
  openConnectTablet: () => void;
  hasConnectedTablet: boolean;
  disconnect: () => void;
  getDevice: () => Promise<void>;
  linkedDevices: LinkedDevice[]; // Needed to trigger re-render after pairing event
  hasAlexaIntegration: boolean;
  refetch: Function;
}

const ControllerTabComponent = (props: Props) => {
  const {
    openHdPhoneCustomization,
    openConnectTablet,
    isCustomizable,
    canConnectTablet,
    canDisconnectTablet,
    hasConnectedTablet,
    roomSystem,
    tablet,
    getDevice,
    hasAlexaIntegration,
    refetch
  } = props;

  const [deviceLoading, setDeviceLoading] = useState(false);
  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  const deviceGetAsync = async () => {
    try {
      await getDevice();
      setDeviceLoading(false);
    } catch (e) {
      Logger.info(`Unable to get device state: ${e.toString()}`);
      setDeviceLoading(false);
    }
  };

  useEffect(() => {
    if (hasAlexaIntegration) {
      setDeviceLoading(true);
      deviceGetAsync();
    }
  },        [hasAlexaIntegration]);

  useEffect(() => {
    refetch();
  },        []);

  return (
    <div className={classes.container}>
      {(canConnectTablet || hasConnectedTablet) && (
        <div className={classes.pane}>
          <div className={classes.contentContainer}>
            <div className={classes.paneTitle}>
              {intl.get('tablet')}
            </div>
            {hasConnectedTablet ? (
              <Mutation mutation={unLinkTabletMutation}>
                {(unLinkTablet, { loading, error }: { loading: boolean, error?: ApolloError }) => {
                  return (
                    <div className={classes.paneBody}>
                      <div className={classes.textContainer}>{intl.get('tabletAlreadyConnected')}</div>
                      <div className={classes.buttonContainer}>
                        <Button
                          id="connect"
                          name="btnConnect"
                          onClick={async () => {
                            if (!canEditRoomSystems) { return; }
                            try {
                              await unLinkTablet({
                                variables: {
                                  serialNumber: roomSystem.serialNumber,
                                  deviceUUID: _get(tablet, 'deviceUUID') || roomSystem.serialNumber,
                                  UUID: _get(tablet, 'UUID')
                                }
                              });
                            } catch (e) {
                              Logger.info(`Error attempting to unpair room system(s): ${e}`);
                              const apolloNetworkError = _get(e, 'networkError');
                              if (apolloNetworkError) {
                                // Additional Apollo error info if available
                                Logger.info(`Network error: ${_get(apolloNetworkError, 'result.errorMessage', '')}`);
                              }
                            }
                          }}
                          role="button"
                          type="button"
                          disabled={loading || !canDisconnectTablet || !canEditRoomSystems}
                          loading={loading}
                        >
                          {intl.get('disconnect')}
                        </Button>
                      </div>
                      {error &&
                        <div className={classes.error}>{intl.get('unPairTabletFailed')}</div>
                      }
                    </div>
                  );
                }}
              </Mutation>
            ) : (
              <div className={classes.paneBody}>
                <div className={classes.textContainer}>{intl.get('tabletNotConnected')}</div>
                <div className={classes.buttonContainer}>
                  <Button
                    id="connect"
                    name="btnConnect"
                    onClick={openConnectTablet}
                    role="button"
                    type="button"
                    disabled={!canEditRoomSystems}
                  >
                    {intl.get('connect')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {isCustomizable && (
        <div className={classes.pane}>
          <div className={classes.contentContainer}>
            <div className={classes.paneTitle}>
              {intl.get('phoneHd')}
            </div>
            <div className={classes.paneBody}>
              <div className={classes.textContainer}>{intl.get('customizePhoneHomeScreen')}</div>
              <div className={classes.buttonContainer}>
                <Button
                  disabled={deviceLoading || !canEditRoomSystems}
                  id="customize"
                  name="btnCustomize"
                  onClick={openHdPhoneCustomization}
                  role="button"
                  type="button"
                >
                  {intl.get('customize')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControllerTabComponent;
