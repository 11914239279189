import React, { useEffect } from 'react';
import intl from 'react-intl-universal';
import { Logger } from 'logger';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './Downloads.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelection } from 'selectors/selectionSelectors';
import { useLazyQuery } from 'react-apollo';
import DeleteConferenceExport from 'queries/conferenceExports/deleteConferenceExportsQuery.gql';
import DeleteDirectoryExport from 'queries/directoryExports/deleteDirectoryExportsQuery.gql';
import exportThunks from 'thunks/exportThunks';
import { selectSelectedConferenceExports, selectSelectedDirectoryExports } from 'selectors/exportSelectors';
import { currentUserSelectors } from '@lifesize/nucleus';
import { actions as selectionActions } from '../../actions/selectionActions';

interface Props {
  closeModal: () => void;
}

export const DeleteDownloadModal = (props: Props) => {
  const { closeModal } = props;
  const selectionArray = useSelector(selectSelection);
  const selectedConferenceExports = useSelector(selectSelectedConferenceExports);
  const selectedDirectoryExports = useSelector(selectSelectedDirectoryExports);
  const userName = useSelector(currentUserSelectors.myInfo)?.name;
  const dispatch = useDispatch();
  const multipleSelections = selectionArray.length > 1;
  const [requestConferenceExportDeletion, conferenceDeletion] = useLazyQuery(DeleteConferenceExport, { fetchPolicy: 'network-only' });
  const [requestDirectoryExportDeletion, directoryDeletion] = useLazyQuery(DeleteDirectoryExport, { fetchPolicy: 'network-only' });

  const hasConferenceExports = selectedConferenceExports.length;
  const hasDirectoryExports = selectedDirectoryExports.length;

  const hasDeletedConferenceExports = selectedConferenceExports && conferenceDeletion.data;
  const hasDeletedDirectoryExports = selectedDirectoryExports && directoryDeletion.data;

  useEffect(
    () => {
      if (hasConferenceExports && !hasDirectoryExports && hasDeletedConferenceExports) {
        dispatch(exportThunks.handleExportDeletion(conferenceDeletion?.data?.deleteConferenceExports));
        dispatch(selectionActions.resetSelection());
        closeModal();
      } else if (hasDirectoryExports && !hasConferenceExports && hasDeletedDirectoryExports ) {
        dispatch(exportThunks.handleExportDeletion(directoryDeletion?.data?.deleteExports));
        dispatch(selectionActions.resetSelection());
        closeModal();
      } else if (hasDeletedDirectoryExports && hasDeletedConferenceExports) {
        dispatch(exportThunks.handleExportDeletion(conferenceDeletion?.data?.deleteConferenceExports));
        dispatch(selectionActions.resetSelection());
        closeModal();
      }
    },
    [hasConferenceExports, hasDirectoryExports, hasDeletedConferenceExports, hasDeletedDirectoryExports]
  );

  const deleteErrorMessage = multipleSelections ? intl.get('deleteDownloadsError') : intl.get('deleteDownloadError');

  return (
    <Modal
      className={styles.deleteModalContainer}
      onSave={
        async () => {
          try {
            await Promise.all([
              selectedConferenceExports.length && requestConferenceExportDeletion({ variables: { downloadIds: selectedConferenceExports }}),
              selectedDirectoryExports.length && requestDirectoryExportDeletion({ variables: { downloadIds: selectedDirectoryExports, deletedBy: userName }})
            ]);
          } catch (e) {
            Logger.info(`Error deleting conference export:  ${e.toString()}`);
          }
        }
      }
      closeModal={closeModal}
      errorMessage={(conferenceDeletion.error || directoryDeletion.error) && deleteErrorMessage}
      saveText={intl.get('delete')}
      closeText={intl.get('cancel')}
      isSaveButtonBusy={conferenceDeletion.loading || directoryDeletion.loading}
      isSaveButtonDisabled={conferenceDeletion.loading || directoryDeletion.loading}
      hideSaveButton={false}
    >
      <div>
        <div className={styles.title}>{multipleSelections ? intl.get('confirmDownloadsDelete') : intl.get('confirmDownloadDelete')}</div>
      </div>
    </Modal>
  );
};

export default DeleteDownloadModal;
