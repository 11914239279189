import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './AlexaUnregisterModal.scss';

interface Props {
  closeModal: () => void;
  unregisterDevice: () => Promise<void>;
}

export const AlexaUnregisterModal = (props: Props) => {
  const { closeModal, unregisterDevice } = props;
  const [error, setError] = useState(false);
  const [busy, setBusy] = useState(false);

  return (
    <Modal
      className={styles.container}
      isSaveButtonBusy={busy}
      onSave={async() => {
        setBusy(true);
        try {
          setError(false);
          await unregisterDevice();
          setBusy(false);
          closeModal();
        } catch (e) {
          setBusy(false);
          setError(true);
        }
      }}
      errorMessage={error ? intl.get('alexaUnregistrationError') : undefined}
      closeModal={closeModal}
      closeText={intl.get('cancel')}
      saveText={intl.get('unregister')}
      hideSaveButton={false}
      hideCloseButton={false}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>{intl.get('alexaUnregisterConfirmation')}</div>
        <div className={styles.body}>{intl.get('alexaUnregisterInstructions')}</div>
      </div>
    </Modal>
  );
};

export default AlexaUnregisterModal;
