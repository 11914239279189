import { connect } from 'react-redux';
import UsersTable from '../../components/Drilldowns/UsersAndVideo/UsersTable';
import { dataWarehouseSelectors } from '@lifesize/nucleus';
import actions from '../../actions/drilldownActions';
import { Global } from '../../state/Global';
import { filteredUserCalls } from '../../selectors/datawarehouseSelectors';
import { defaultRowCount } from '../../constants/listViewLayoutConstants';
import { selectPageNumber } from '../../selectors/paginationSelectors';

function mapStateToProps(state: Global) {
  const usersData = dataWarehouseSelectors.usersGroupByCallsPersonal(state);
  const filteredUsers = filteredUserCalls(state);
  const pageNumber = selectPageNumber(state);

  return {
    isFetching: usersData.isFetching,
    error: usersData.error,
    numberOfPages: Math.ceil(filteredUsers.length / defaultRowCount),
    listData: filteredUsers,
    pageNumber: pageNumber
  };
}

const mapDispatchToProps = (dispatch: Function) => ({
  fetchListData: () => {
    dispatch(actions.handleFetchDrilldownVideoUsers());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
