import { Reducer } from 'redux';
import { FilterState, initialFilterState } from 'state/Filter';
import { FilterActionType } from 'constants/filterConstants';

const dashboardReducer: Reducer<FilterState> =
  (state = initialFilterState, action) => {
    switch (action.type) {
      case FilterActionType.DRILLDOWN_SET_FILTER_STRING:
        return { ...state, filterString: action.payload };
      default:
        return state;
    }
  };

export default dashboardReducer;
