import { createSelector } from 'reselect';
import _get from 'lodash/get';
import { ParticipantEntry } from 'interfaces/Dashboard';
import { LiveMeetingData } from 'interfaces/LiveMeeting';

/**
 * Selector to map live conference participants
 */
const selectParticipants = (data: LiveMeetingData) => _get(data, 'liveMeetingByUUID.participants', []);
const selectPresenter =  (data: LiveMeetingData) => _get(data, 'liveMeetingByUUID.conferenceInfo.presentation.callId', null);

const selectMappedParticipants = createSelector(
  selectParticipants,
  selectPresenter,
  (participants, presenter) => {
    return participants.map((participant: ParticipantEntry) => {
      const isPresenting = participant.callId === presenter;
      if (participant.hasOwnProperty('endedAt') && participant.endedAt !== 0) {
        return {...participant, duration: participant.endedAt - participant.startedAt, isPresenting};
      }
      return {...participant, duration: '', isPresenting};
    });
  }
);

export {
  selectMappedParticipants,
};
