import React from 'react';
import { Tab } from 'semantic-ui-react';
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import _compact from 'lodash/compact';
import {
  formatUnixDateForDisplay,
  formatUnixTimeOfDayForDisplay,
  formatMinutesForDisplay,
  secondsToMilliseconds
} from 'utils/date-time-utils';
import * as styles from './PastMeetingDetails.scss';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import ParticipantsTab from './ParticipantsTab/ParticipantsTab';
import {
  PAST_MEETING_PARTICIPANTS,
  PAST_MEETING_TAB,
  PAST_MEETING_GEO
} from 'constants/pastMeetings';
import Timer = NodeJS.Timer;
import { PAST_MEETING_REFRESH_TIME } from 'constants/pastMeetings';
import ListViewTabLayout from 'components/ListViewTabLayout/ListViewTabLayout';
import { GeoDistribution } from 'components/GeoDistribution/GeoDistribution';
import MissingConferenceError from 'components/MissingConferenceError/MissingConferenceError';
import {
  DWHPastConferenceModeratorType,
  DWHPastConferenceRecordingType,
  GeoLocation,
  ParticipantEntry
} from 'interfaces/Dashboard';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import PastConferenceDetailsQuery from 'queries/pastMeetings/PastConferenceDetailsQuery.gql';
import _get from 'lodash/get';
import { selectGeoDistribution } from 'selectors/pastMeetingParticipantsSelector';
import { useSelector } from 'react-redux';
import { accountSelectors, featureSelectors } from '@lifesize/nucleus';
import { ACCOUNT_FEATURE_ENABLE_GEO_DISTRIBUTION } from 'constants/accountFeatureConstants';
import { FEATURE_FLAG_MULTIPLE_MODERATORS } from 'constants/featureFlags';

const indexKeys = {
  [PAST_MEETING_PARTICIPANTS]: 0,
  [PAST_MEETING_GEO]: 1,
};

let refetchInterval: Timer | null;

const panes = (
  participants: Array<ParticipantEntry>,
  conferenceId: string,
  geoDistribution: Array<GeoLocation>,
  showGeoDistribution: boolean,
  meetingName: string,
) => {
  const paneList =
    [
      {
        menuItem: (
          {
            key: 'participantsTab',
            content: (
              intl.get('participants')
            )
          }),
        render: () => (
          <Tab.Pane key={'0'} attached={false}>
            <ParticipantsTab
              data={participants}
              conferenceId={conferenceId}
              meetingName={meetingName}
            />
          </Tab.Pane>
        )
      }
    ];

  if (showGeoDistribution) { paneList.push({
    menuItem: (
      {
        key: 'geoTab',
        content: (
          intl.get('geoDistribution')
        )
      }),
    render: () => (
      <Tab.Pane key={'1'} attached={false}>
        <GeoDistribution geoDistribution={geoDistribution}/>
      </Tab.Pane>
    )
  });
  }

  return _compact(paneList);
};

const pastMeetingBreadCrumbs = (meetingName: string, listingState: object) => (
  <React.Fragment>
    <Link key="pastMeetingsBreadcrumb" to={{pathname: PAST_MEETING_TAB, state: listingState}}>
      {intl.get('pastMeetings')}
    </Link>
    {' > '}
    {meetingName}
  </React.Fragment>
);

const PastMeetingDetails = (props: RouteComponentProps<{}>) => {
  const {
    match,
  } = props;
  const conferenceId = _get(match, 'params.id');

  const { data, loading, refetch, error } = useQuery(PastConferenceDetailsQuery, {
    variables : { conferenceId },
    fetchPolicy: 'network-only'
  });

  const pastConferenceDetails = _get(data, 'pastConferenceDetails');
  const geoDistribution = selectGeoDistribution(data);
  const multipleModeratorsEnabled = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_MULTIPLE_MODERATORS);
  const enableGeoDistribution = useSelector(accountSelectors.getAccountFeature)(ACCOUNT_FEATURE_ENABLE_GEO_DISTRIBUTION);

  const moderators = _get(pastConferenceDetails, 'moderators', []);
  const recordings = _get(pastConferenceDetails, 'recording', []);
  const meetingName = _get(pastConferenceDetails, 'meetingName');
  const extension = _get(pastConferenceDetails, 'extension');
  const conferenceType = _get(pastConferenceDetails, 'conferenceType');
  const quality = _get(pastConferenceDetails, 'quality');
  const startedAt = _get(pastConferenceDetails, 'startedAt');
  const totalParticipants = _get(pastConferenceDetails, 'totalParticipants');
  const endedAt = _get(pastConferenceDetails, 'endedAt');
  const duration = _get(pastConferenceDetails, 'duration');
  const participants = _get(pastConferenceDetails, 'participants');
  const showGeoDistribution = geoDistribution.length && enableGeoDistribution;

  if (error) {
    return (
      <MissingConferenceError
        backRoute={PAST_MEETING_TAB}
        breadcrumbs={pastMeetingBreadCrumbs(conferenceId)}
      />
    );
  }

  if (loading) {
    return <LoadingIndicator/>;
  }

  if (!loading && !pastConferenceDetails) {
    if (!refetchInterval && refetch) {
      refetchInterval = setInterval(refetch, PAST_MEETING_REFRESH_TIME);
    }
    return <LoadingIndicator/>;
  }

  if (refetchInterval) {
    clearInterval(refetchInterval);
    refetchInterval = null;
  }

  const moderatorList = moderators
    .reduce(
      (acc: Array<string>, cur: DWHPastConferenceModeratorType) => {
        if (cur.displayName) {
          acc.push(cur.displayName);
        }
        return acc;
      },
      []
    );

  const recordingsList = recordings
    .reduce(
      (acc: Array<JSX.Element>, cur: DWHPastConferenceRecordingType) => {
        if (cur.recordingId) {
          acc.push(
            <Link to={{ pathname: `/singleRecording/${cur.recordingId}` }}>
              {intl.get('clickToView')}
            </Link>
          );
        }
        return acc;
      },
      []
    );

  const row1 = [];
  row1.push(`${intl.get('extensionWithColon')} ${extension}`);
  row1.push(`${intl.get('meetingTypeWithColon')} ${conferenceType}`);
  if (quality) {
    row1.push(`${intl.get('qualityWithColon')} ${intl.get('minimal')}`);
  }
  row1.push(
    <>{intl.get('recordingWithColon')} {recordingsList?.[0] || intl.get('none')}</>
  );
  if (multipleModeratorsEnabled) {
    row1.push(`${moderatorList.length > 1 ? intl.get('moderatorsWithColon') : intl.get('moderatorWithColon')} ${moderatorList.join(', ')}`);
  }

  const row2 = [
    `${intl.get('dateWithColon')} ${formatUnixDateForDisplay(secondsToMilliseconds(startedAt))}`,
    `${intl.get('totalParticipantsWithColon')} ${totalParticipants}`,
    `${intl.get('startTimeWithColon')} ${formatUnixTimeOfDayForDisplay(secondsToMilliseconds(startedAt))}`,
    `${intl.get('endTimeWithColon')} ${formatUnixTimeOfDayForDisplay(secondsToMilliseconds(endedAt))}`,
    `${intl.get('durationWithColon')} ${formatMinutesForDisplay(duration)}`
  ];

  const headerRows = [row1, row2];
  return (
    <div className={styles.container}>
      <ListViewTabLayout
        title={meetingName}
        breadcrumbs={pastMeetingBreadCrumbs(meetingName, _get(props, 'location.state', {}))}
        subtitle={(
          <div>
            <div className={styles.flexCol}>
              {headerRows.map((row, rowIndex) => {
                return (
                  <div key={`row${rowIndex}`}>
                    {row
                      .map((item, index) => <span key={`item${index}`}>{item}</span>)
                      .reduce((acc, x, i) => acc === null ? x : <>{acc}<span key={`sep${i}`} className={styles.separator}>|</span>{x}</>, null)
                    }
                  </div>
                );
              })}
            </div>
          </div>
        )}
        key={'header'}
        panes={panes(participants, conferenceId, geoDistribution, showGeoDistribution, meetingName)}
        indexKeys={indexKeys}
      />
    </div>
  );
};

export default PastMeetingDetails;
