import { actions as exportToCsvActions, ExportName } from 'actions/exportToCsvActions';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { handleExportLiveSteamQuestionsToCsv, handleExportLiveSteamViewersToCsv, } from 'utils/exportUtils';
import LiveStreamsToolbar from 'components/Meetings/LiveStreamsTab/LiveStreamsToolbar';
import { selectExportState } from 'selectors/exportToCsvSelector';
import _get from 'lodash/get';

const mapStateToProps = (state: Global) => {
  const exportState = selectExportState(state);
  return {
    isExporting: exportState.isBusy
  };
};

const mapDispatchToProps = (dispatch: Function, ownProps: object) => ({
  handleExportData: () => {
    const activeTab = _get(ownProps, 'activeTab');
    const data = _get(ownProps, 'data');
    if (activeTab === ExportName.LIVE_STREAM_QUESTIONS) {
      handleExportLiveSteamQuestionsToCsv(data);
    } else {
      handleExportLiveSteamViewersToCsv(data);
    }
  },
  resetExportState: () => {
    dispatch(exportToCsvActions.fetchCsvDataReset());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveStreamsToolbar);
