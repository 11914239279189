import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import UsersTable from 'containers/Drilldowns/UserCallDataContainer';
import {
  UsersTableContainer as UsersTable2,
  RoomSystemsTableContainer as RoomSystemsTable2,
  CallDetailsContainer
} from 'containers/Drilldowns/DrilldownContainer';
import RoomSystemsTable from 'containers/Drilldowns/RoomSystemCallDataContainer';
import RoomUsageDetails from 'containers/Drilldowns/RoomUsageDetailsContainer';
import UserUsageDetails from 'containers/Drilldowns/UserUsageDetailsContainer';
import * as styles from './UsersAndVideo.scss';
import { Tab } from 'semantic-ui-react';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import { TabProps } from 'semantic-ui-react/dist/commonjs/modules/Tab/Tab';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { ListViewLayoutHeader } from 'components/ListViewLayout/ListViewLayoutHeader';
import { getBreadcrumbs } from 'utils/nav-utils';
import { BreadcrumbInterface } from 'interfaces/Breadcrumb';

interface Props {
  match: object;
}

interface State {
  newDashboard: boolean;
  selectedTab: number;
}

class UsersAndVideo extends React.Component<RouteComponentProps<{}> & Props, State> {
  public state: State;

  panes = [
    {
      menuItem: (
        {
          key: 'usersTab',
          content: (
            intl.get('users')
          )
        }),
      render: () => (
        <Tab.Pane key={'0'} attached={false}>
          <UsersTable
            onSelect={this.onSelectUsageDetails}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        {
          key: 'systemsTab',
          content: (
            intl.get('roomSystems')
          )
        }
      ),
      render: () => (
        <Tab.Pane key={'1'} attached={false}>
          <RoomSystemsTable
            onSelect={this.onSelectUsageDetails}
          />
        </Tab.Pane>
      )
    }
  ];

  panes2 = [
    {
      menuItem: (
        {
          key: 'usersTab',
          content: (
            intl.get('users')
          )
        }),
      render: () => (
        <Tab.Pane key={'0'} attached={false}>
          <UsersTable2
            onSelect={this.onSelectUsageDetails}
            sort={_get(this.props, 'location.state.sort')}
            search={_get(this.props, 'location.state.search')}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: (
        {
          key: 'systemsTab',
          content: (
            intl.get('roomSystems')
          )
        }
      ),
      render: () => (
        <Tab.Pane key={'1'} attached={false}>
          <RoomSystemsTable2
            onSelect={this.onSelectUsageDetails}
            sort={_get(this.props, 'location.state.sort')}
            search={_get(this.props, 'location.state.search')}
          />
        </Tab.Pane>
      )
    }
  ];

  constructor(props: RouteComponentProps<{}> & Props) {
    super(props);

    this.state = {
      newDashboard: ['users2', 'rooms2'].includes(_get(this.props.match, 'params.tab')),
      selectedTab: ['rooms', 'rooms2'].includes(_get(this.props.match, 'params.tab')) ? 1 : 0
    };
  }

  getRouteData = () => {
    const { id, tab } =  _get(this.props.match, 'params', {}) as { tab: string, id: string };
    return {
      id,
      tab,
      tabIndex: ['users', 'users2'].includes(tab) ? 0 : 1
    };
  }

  renderBreadcrumbs = ({ id, tab }: { id: string, tab: string }) => {
    const breadcrumbOptions: Array<BreadcrumbInterface> = [
      {
        label: intl.get('dashboard'),
        url: '/dashboard/new'
      }
    ];
    if (id) {
      if (tab === 'users2') {
        breadcrumbOptions.push({
          label: intl.get('headerUsageDetails'),
          to: {
            pathname: '/drilldown/usersVideos/users2',
            state: _get(this.props, 'location.state', {})
          }
        });
        breadcrumbOptions.push({ label: intl.get('headerCallDetails') });
      } else {
        breadcrumbOptions.push({
          label: intl.get('headerUsageDetails'),
          to: {
            pathname: '/drilldown/usersVideos/rooms2',
            state: _get(this.props, 'location.state', {})
          }
        });
        breadcrumbOptions.push({ label: intl.get('headerCallDetails') });
      }
    } else {
      breadcrumbOptions.push({ label: intl.get('headerUsageDetails') });
    }
    return getBreadcrumbs(breadcrumbOptions);
  }

  onSelectUsageDetails = (id: string) => {
    const tab = _get(this.props.match, 'params.tab');
    this.props.history.push(`/drilldown/usersVideos/${tab}/${encodeURIComponent(id)}`);
  }

  onTabChange = (event: React.MouseEvent<HTMLDivElement>, data: TabProps) => {
    if (_isNumber(data.activeIndex) && this.state.selectedTab !== data.activeIndex) {
      this.setState({selectedTab: data.activeIndex});
      if (data.activeIndex === 0) {
        this.props.history.push(this.state.newDashboard ? '/drilldown/usersVideos/users2' : '/drilldown/usersVideos/users');
      } else {
        this.props.history.push(this.state.newDashboard ? '/drilldown/usersVideos/rooms2' : '/drilldown/usersVideos/rooms');
      }
    }
  }

  renderContent = ({ id, tab, tabIndex }: { id: string, tab: string, tabIndex: number }) => {
    const title = intl.get(id ? 'headerCallDetails' : 'headerUsageDetails');
    if (['users2', 'rooms2'].includes(tab) && id) {
      return (
        <CallDetailsContainer
          breadcrumbs={this.renderBreadcrumbs({id, tab})}
          key={'usageDetails'}
          title={title}
          selectedId={id}
          exportTitle={tab === 'users2' ? intl.get('csvExportUsageDetailsUser') : intl.get('csvExportUsageDetailsRoom')}
        />
      );
    } else if (tab === 'users' && id) {
      return (
        <UserUsageDetails
          breadcrumbs={this.renderBreadcrumbs({id, tab})}
          key={'usageDetails'}
          selectedId={id}
          title={title}
        />
      );
    } else if (tab === 'rooms' && id) {
      return (
        <RoomUsageDetails
          breadcrumbs={this.renderBreadcrumbs({id, tab})}
          key={'usageDetails'}
          selectedId={id}
          title={title}
        />
      );
    }
    return [
      <ListViewLayoutHeader title={title} breadcrumbs={this.renderBreadcrumbs({id, tab})} key={'header'} />,
      (
        <Tab
          className={classnames(styles.tabsContainer, !id && 'tabset')}
          activeIndex={tabIndex}
          key={'tabSet'}
          menu={{secondary: true, pointing: true}}
          onTabChange={this.onTabChange}
          panes={this.state.newDashboard ? this.panes2 : this.panes}
        />
      )
    ];
  }

  render() {
    const { id, tab, tabIndex } =  this.getRouteData();
    return (
      <div className={styles.container}>
        {this.renderContent({ id: !!id ? decodeURIComponent(id) : id, tab, tabIndex })}
      </div>
    );
  }
}

export default withRouter(UsersAndVideo);
