import SearchBar from 'containers/Search/SearchBar';
import React from 'react';
import ExportButton from '../../Common/ExportButton';
import DateRangePickerComponent from '../../../containers/DateRangePicker/DateRangePickerContainer2';

interface Props {
  handleExportData: () => void;
  isExporting: boolean;
}

const CallHistoryHeader = (props: Props) => {
  const { handleExportData, isExporting } = props;
  return (
    <div>
      <SearchBar key="searchbar" />
      <div className="toolbar" key="toolbar" data-test="toolbar">
        <div>
          <ExportButton
            data-test="toolbarBtn.exportAll"
            disabled={false}
            isExporting={isExporting}
            onExportClick={handleExportData}
          />
          <DateRangePickerComponent />
        </div>
      </div>
    </div>
  );
};

export default CallHistoryHeader;
