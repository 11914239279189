import { Reducer } from 'redux';
import { Actions } from 'constants/languageConstants';
import { PayloadAction } from '@lifesize/typescript-react-redux-utils';
import { LanguageState, initialLanguageState } from 'state/Language';
import { convertLocaleToISO } from 'utils/languageUtils';

const languageProviderReducer: Reducer<LanguageState> =
  (state = initialLanguageState, action: PayloadAction<Actions, { locale: string }>) => {
    switch (action.type) {
      case Actions.CHANGE_LOCALE:
        let locale = action?.payload?.locale;
        localStorage.setItem('locale', convertLocaleToISO(locale));
        return { ...state, locale };
      default:
        return state;
    }
  };

export default languageProviderReducer;
