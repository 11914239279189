import React from 'react';
import intl from 'react-intl-universal';
import ListViewComponent from 'components/ListView/ListViewComponent';
import ListViewLayout from 'components/ListViewLayout/ListViewLayout';
import { PAST_MEETING_PARTICIPANT_ROUTE } from 'constants/pastMeetings';
import {
  formatMinutesForDisplay,
  formatUnixDateTimeForDisplay,
  secondsToMilliseconds
} from 'utils/date-time-utils';
import { withRouter } from 'react-router';
import * as styles from './ParticipantsTab.scss';
import { RowInfo } from 'react-table';
import { RouteComponentProps } from 'react-router-dom';
import ClickableCell from 'components/ClickableCell/ClickableCell';
import { ParticipantEntry } from 'interfaces/Dashboard';
import ParticipantsToolbar from 'components/PastMeetingDetails/ParticipantsTab/ParticipantsToolbar';

interface Props extends RouteComponentProps {
  data: Array<ParticipantEntry>;
  conferenceId: string;
  meetingName: string;
}

const ParticipantsTab = (props: Props) => {
  const { data, meetingName } = props;

  return (
    <ListViewLayout
      error={undefined}
      hideHeader={true}
      isFetching={false}
      title={undefined}
    >
      <div className={styles.container}>
        <ParticipantsToolbar
          meetingName={meetingName}
          data={data}
        />
        <ListViewComponent
          columns={[
            {
              Header: intl.get('name'),
              accessor: 'displayName',
              Cell: (row: RowInfo) => {
                const {displayName} = row.original;
                return (
                  <ClickableCell
                    id={`pastMeetingParticipant-${displayName}`}
                    title={displayName}
                    isClickable={!!row.original.callId}
                    displayText={displayName}
                    clickHandler={() => {
                      props.history.push(`${PAST_MEETING_PARTICIPANT_ROUTE}/${props.conferenceId}/${row.original.callId}`);
                    }}
                  />
                );
              }
            },
            {
              Header: intl.get('location'),
              accessor: 'location'
            },
            {
              Header: intl.get('joinTime'),
              accessor: 'startedAt',
              Cell: (row) => formatUnixDateTimeForDisplay(secondsToMilliseconds(row.value)),
            },
            {
              Header: intl.get('leaveTime'),
              accessor: 'endedAt',
              Cell: (row) => formatUnixDateTimeForDisplay(secondsToMilliseconds(row.value)),
            },
            {
              Header: intl.get('duration'),
              accessor: 'duration',
              Cell: (row) => formatMinutesForDisplay(row.value),
            }
          ]}
          defaultSorted={[
            {
              id: 'startedAt',
              desc: false
            }
          ]}
          data={data}
          pageNumber={0}
          showPagination={false}
          showAllResults={true}
        />
      </div>
    </ListViewLayout>
  );
};

export default withRouter(ParticipantsTab);
