import { dataWarehouseActions, dataWarehouseSelectors } from '@lifesize/nucleus';
import _get from 'lodash/get';
import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import {
  DRILLDOWN_FETCH_USAGE_DETAILS_ROOM,
  DRILLDOWN_FETCH_USAGE_DETAILS_USER
} from 'actions/drilldownActions';
import { DrilldownActionType } from 'constants/drilldownConstants';
import {
  selectDashboardState,
  selectEndDate,
  selectStartDate
} from 'selectors/dashboardSelectors';

const DrilldownUsersVideoDataLoadMiddleware =
  (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: Action) => {
    const state = store.getState();
    const startDate = selectStartDate(state);
    const endDate = selectEndDate(state);
    switch (action.type) {
      case DrilldownActionType.DRILLDOWN_FETCH_USERS_AND_VIDEO:
        const dashboardState = selectDashboardState(state);
        next(dataWarehouseActions.fetchUsersGroupByCallsPersonal(dashboardState.startDate, dashboardState.endDate));
        next(dataWarehouseActions.fetchUsersGroupByCallsRoom(dashboardState.startDate, dashboardState.endDate));
        return null;
      case DRILLDOWN_FETCH_USAGE_DETAILS_ROOM:
        const roomConfig = _get(action, 'payload', {});
        return store.dispatch(dataWarehouseActions.fetchUsageDetailsRoom({
          dateEnd: endDate,
          dateStart: startDate,
          id: roomConfig.selectedId || dataWarehouseSelectors.usageDetailsForUser(state).selectedId
        }));
      case DRILLDOWN_FETCH_USAGE_DETAILS_USER:
        const userConfig = _get(action, 'payload', {});
        return store.dispatch(dataWarehouseActions.fetchUsageDetailsUser({
          dateEnd: endDate,
          dateStart: startDate,
          id: userConfig.selectedId || dataWarehouseSelectors.usageDetailsForUser(state).selectedId
        }));
      default:
        return next(action);
    }
  };

export default (DrilldownUsersVideoDataLoadMiddleware as Middleware);
