import React from 'react';
import intl from 'react-intl-universal';
import { useQuery } from 'react-apollo';
import thirdPartyDevicesQuery from 'queries/roomSystems/thirdPartyDevicesQuery.gql';
import { exportToCsv } from 'utils/csv-utils';
import { parse220CredentialsForExport } from 'utils/exportUtils';
import * as styles from 'components/Settings/Sections.scss';
import { useSelector } from 'react-redux';
import { featureSelectors } from '@lifesize/nucleus';
import { FEATURE_FLAG_LIFESIZE_CONNECT_LINK } from 'constants/featureFlags';
import { getLifesizeDomain } from 'utils/url-utils';

const Downloads = () => {
  const { data } = useQuery(thirdPartyDevicesQuery, {
    fetchPolicy: 'cache-and-network'
  });

  const featureFlagLifesizeConnect = useSelector(featureSelectors.getFeatureFlag)(FEATURE_FLAG_LIFESIZE_CONNECT_LINK);

  return (
    <section className={styles.container} data-test="settingsSection.downloads">
      <article>
        <h6 className={styles.title} data-test="sectionTitle">{intl.get('downloadsPageTitle')}</h6>
        <ul className={styles.linkList}>
          <li>
            <a href="https://download.lifesizecloud.com/download?platform=msi">
              {intl.get('downloadsWindowsInstallationPackage')}
            </a>
          </li>
          <li>
            <a href="https://download.lifesizecloud.com/download?platform=win">
              {intl.get('downloadsLifesizeAppForWindows')}
            </a>
          </li>
          <li>
            <a href="https://download.lifesizecloud.com/download?platform=osx">
              {intl.get('downloadsLifesizeAppForOSX')}
            </a>
          </li>
          {featureFlagLifesizeConnect &&
            <li>
              <a href={`https://getconnect.${getLifesizeDomain()}/Lifesize%20Connect.exe`}>
                {intl.get('lifesizeConnectInstaller')}
              </a>
            </li>
          }
          {(!!data?.get220DevicesByGroup?.length) &&
            <li>
              <a
                onClick={
                  () => {
                    if (data && data.get220DevicesByGroup) {
                      const exportData = [];
                      for (let device of data.get220DevicesByGroup) {
                        exportData.push(parse220CredentialsForExport(device));
                      }
                      exportToCsv(intl.get('creds220'), exportData);
                    }
                  }
                }
              >
                {intl.get('creds220')}
              </a>
            </li>
          }
        </ul>
      </article>
      <article>
        <ul className={styles.linkList}>
          <li>
            {intl.getHTML('downloadsScheduleWithCalendars')}
          </li>
          <li>
            <a href="https://gsuite.google.com/marketplace/app/lifesize/120044848800" target={'_blank'}>
              {intl.get('downloadsGoogleWorkspaceAddon')}
            </a>
          </li>
          <li>
            <a href="https://appsource.microsoft.com/product/office/wa200000175" target={'_blank'}>
              {intl.getHTML('downloadsOutlookAddIn')}
            </a>
          </li>
        </ul>
      </article>
    </section>
  );
};

export default Downloads;
