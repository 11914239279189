import React, { SyntheticEvent } from 'react';
import { Field, Form } from 'formik';
import _memoize from 'lodash/memoize';
import { Tooltip } from 'components/tooltip/Tooltip';
import Icon from 'components/Common/Icon';
import * as formStyles from 'components/Modals/ModalForms.scss';
import * as styles from './SettingsTabComponent.scss';
import classnames from 'classnames';
import ToggleButton from 'components/ToggleButton/ToggleButton';
import intl from 'react-intl-universal';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const getHideRoomLabel = _memoize((labelText: string, toolTipText: string) => (
  <>
    <Tooltip text={toolTipText} stylesWrapper={{ lineHeight: 1, marginRight: '6px', marginTop: '1px' }}>
      <Icon classes={['icon-info', formStyles.tooltipButtonIcon]}/>
    </Tooltip>
    <span>{labelText}</span>
  </>
));

interface Props {
  errors: {
    hidden?: string | undefined | boolean
  };
  handleChange: Function;
  submitForm: Function;
}

const HideFromDirectoryForm = (props: Props) => {
  const {
    errors,
    handleChange,
    submitForm
  } = props;

  const canEditRoomSystems = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_ROOMS];

  return (
    <Form className={formStyles.continued}>
      <div className={styles.formContainer}>
        <div className={formStyles.logicalGroup}>
          <div className={classnames(formStyles.formGroup, formStyles.hasValidation, formStyles.vertical)}>
            <div>
              <Field
                classNameLabel={formStyles.toggleLabelLarge}
                component={ToggleButton}
                id="hidden"
                label={getHideRoomLabel(intl.get('inputLabelHide'), intl.get('tooltipHideRoom'))}
                topLabel={true}
                disabled={!canEditRoomSystems}
                name="hidden"
                onChange={async (e: SyntheticEvent) => {
                  if (!canEditRoomSystems) { return; }
                  // important: await handleChange() before submitForm() (or submitForm() will not fire)
                  await handleChange({ target: { name: 'hidden', value: _get(e.target, 'checked') }});
                  submitForm();
                }}
                {...{ ['data-test']: 'roomSystemToggle.hidden' }}
              />
            </div>
            <ul className={formStyles.inputMessages}>
              {errors.hidden && (
                <li className={formStyles.error}>{errors.hidden}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default HideFromDirectoryForm;
