import { EditOrAddGroupContactModal } from 'components/Modals/EditOrAddGroupContactModal/EditOrAddGroupContactModal';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Global } from 'state/Global';
import { actions as ModalActions } from 'actions/modalActions';
import { selectModalState } from 'selectors/modalSelectors';

interface Props {
  isEditingExisting: boolean;
}

const mapStateToProps = (state: Global, { isEditingExisting }: Props) => {
  const existingContact = selectModalState(state)?.payload;
  return {
    existingContactUuid: _get(existingContact, 'UUID'),
    existingContactName: _get(existingContact, 'displayName'),
    existingContactDetails: _get(existingContact, 'dialstring'),
    isEditingExisting
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  closeModal: () => dispatch(ModalActions.closeModal())
});
const EditOrAddGroupContactModalContainer = connect(mapStateToProps, mapDispatchToProps)(EditOrAddGroupContactModal);
export default EditOrAddGroupContactModalContainer;
