import * as screen_main_only from 'images/screen_main_only.svg';
import * as screen_main_plus_4 from 'images/screen_main_plus_4.svg';
import * as screen_main_plus_7 from 'images/screen_main_plus_7.svg';
import * as screen_main_plus_12 from 'images/screen_main_plus_12.svg';
import * as screen_tiles_2 from 'images/screen_tiles_2.svg';
import * as screen_tiles_3 from 'images/screen_tiles_3.svg';
import * as screen_tiles_4 from 'images/screen_tiles_4.svg';
import * as screen_gallery_plus_7 from 'images/screen_gallery_plus_7.svg';
import * as screen_gallery_plus_9 from 'images/screen_gallery_plus_9.svg';
import * as screen_gallery_plus_16 from 'images/screen_gallery_plus_16.svg';
import * as screen_gallery_plus_25 from 'images/screen_gallery_plus_25.svg';
import * as panel_view_1 from 'images/panel_view_1.png';
import * as panel_view_2 from 'images/panel_view_2.png';
import * as panel_view_3 from 'images/panel_view_3.png';
import * as panel_view_4 from 'images/panel_view_4.png';
import * as panel_view_5 from 'images/panel_view_5.png';
import * as talker_view_1 from 'images/talker_view_1.png';
import * as talker_view_2 from 'images/talker_view_2.png';
import * as talker_view_3 from 'images/talker_view_3.png';
import * as talker_view_4 from 'images/talker_view_4.png';

export const SFU_LAYOUT_TYPES = {
  LEGACY: 1,
  GALLERY: 2,
  SPEAKER: 3,
  PANEL: 4
};

export const LEGACY_IMAGES = [
  screen_main_only,
  screen_main_plus_4,
  screen_main_plus_7,
  screen_main_plus_12
];

export const GALLERY_IMAGES = [
  screen_main_only,
  screen_tiles_2,
  screen_tiles_3,
  screen_tiles_4,
  screen_gallery_plus_7,
  screen_gallery_plus_9,
  screen_gallery_plus_16,
  screen_gallery_plus_25
];

export const PANEL_VIEW_IMAGES = [
  screen_main_only,
  panel_view_1,
  panel_view_2,
  panel_view_3,
  panel_view_4,
  panel_view_5
];

export const ACTIVE_SPEAKER_IMAGES = [
  screen_main_only,
  talker_view_1,
  talker_view_2,
  talker_view_3,
  talker_view_4,
];
