import { authSelectors } from '@lifesize/nucleus';
import DeviceActions from 'actions/deviceCustomizationActions';
import { Global } from 'state/Global';

export const setDeviceCustomizationUrl = (url: string) => async (dispatch: Function, getState: () => Global) => {
  const state = getState();
  const jwt = authSelectors.getPrimaryJwt(state);
  const authUrl = new URL(url);
  if (jwt) {
    authUrl.searchParams.append('jwt', jwt);
  }
  dispatch(DeviceActions.setDeviceCustomizationUrl({ url: authUrl.toString() }));
};
