import { Reducer } from 'redux';
import { nucleusConstants } from '@lifesize/nucleus';
import _clone from 'lodash/clone';
import { SelectionState, initialSelectionState } from 'state/Selection';
import { SelectionActionType } from 'constants/selectionConstants';

const selectionReducer: Reducer<SelectionState> =
  (state = initialSelectionState, action) => {
    switch (action.type) {
      case SelectionActionType.SELECTION_TOGGLE_SELECTION:
        let selectionArray = state.selection ? _clone(state.selection) : [];
        const key = action.payload;
        const keyIndex = state.selection.indexOf(key);
        // check to see if the key exists
        if (keyIndex >= 0) {
          // it does exist so we will remove it
          selectionArray = [
            ...state.selection.slice(0, keyIndex),
            ...state.selection.slice(keyIndex + 1)
          ];
        } else {
          // it does not exist so add it
          selectionArray.push(key);
        }
        return {
          ...state,
          selection: selectionArray,
        };
      case SelectionActionType.SELECTION_TOGGLE_ALL:
        const selectAll = !state?.selectAll || false;
        let toggleAllList = [];
        if (selectAll) {
          toggleAllList = action.payload;
        }
        return {
          ...state,
          selection: toggleAllList,
          selectAll
        };
      case SelectionActionType.SELECTION_RESET:
      case nucleusConstants.DELETE_MEETING_SUCCESS:
      case nucleusConstants.USER_SERVICE_DELETE_SUCCESS:
      case nucleusConstants.USER_SERVICE_DELETE_LIST_SUCCESS:
        return {
          ...state,
          selection: [],
          selectAll: false
        };
      default:
        return state;
    }
  };

export default selectionReducer;
