/**
 * Combine all middleware in this file and export the combined middleware.
 */
import { Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { remotingMiddlewares } from '@lifesize/clients.remoting';
import analyticsMiddleware from './middleware/analyticsMiddleware';
import authenticationMiddleware from './middleware/authenticationMiddleware';
import calendarMiddleware from './middleware/calendarSubscriptionMiddleware';
import dashboardDataLoadMiddleware from './middleware/dashboardDataLoadMiddleware';
import deviceCustomizationMiddleware from './middleware/deviceCustomizationMiddleware';
import drilldownCallHistoryMiddleware from './middleware/drilldownCallHistoryMiddleware';
import drilldownLiveStreamHistoryMiddleware from './middleware/drilldownLiveStreamHistoryMiddleware';
import drilldownUsersVideoDataLoadMiddleware from './middleware/drilldownUsersVideoDataLoadMiddleware';
import exportToCsvMiddleware from './middleware/exportToCsvMiddleware';
import hookMiddleware from './middleware/hookMiddleware';
import meetingsMiddleware from './middleware/meetingsMiddleware';
import mergeAccountMiddleware from './middleware/mergeAccountMiddleware';
import exportMiddleware from './middleware/exportMiddleware';
import modalMiddleware from './middleware/modalMiddleware';
import pairingMiddleware from './middleware/pairingMiddleware';
import onFileDropMiddleware from './middleware/onFileDropMiddleware';
import recordingsAuthMiddleware from './middleware/recordingsAuthMiddleware';
import resetPasswordMiddleware from './middleware/resetPasswordMiddleware';
import searchCloudMeetingsMiddleware from './middleware/searchCloudMeetingsMiddleware';
import searchCloudUsersMiddleware from './middleware/searchCloudUsersMiddleware';
import searchMeetingsMiddleware from './middleware/searchMeetingsMiddleware';
import ssoMiddleware from './middleware/ssoMiddleware';
import userProfileMiddleware from './middleware/userProfileMiddleware';
import usersMiddleware from './middleware/usersMiddleware';
import deviceManagementMiddleware from './middleware/deviceManagementMiddleware';
import uploadRecoringMonitorMiddleware from './middleware/uploadRecordingMonitorMiddleware';
import downloadNotificationMiddleware from './middleware/downloadNotificationMiddleware';

export const enableLogging = !!window.localStorage.getItem('reduxLogging') ||
  (process.env.REACT_APP_IS_PRODUCTION !== 'true' && process.env.NODE_ENV !== 'test');

export default function combineMiddleware(): Array<Middleware> {
  const filteredLogger = createLogger({
    predicate: () => enableLogging,
    collapsed: true,
  });

  return [
    authenticationMiddleware,
    dashboardDataLoadMiddleware,
    searchMeetingsMiddleware,
    drilldownLiveStreamHistoryMiddleware,
    drilldownUsersVideoDataLoadMiddleware,
    drilldownCallHistoryMiddleware,
    downloadNotificationMiddleware,
    usersMiddleware,
    hookMiddleware,
    ssoMiddleware,
    recordingsAuthMiddleware,
    deviceCustomizationMiddleware,
    deviceManagementMiddleware,
    calendarMiddleware,
    onFileDropMiddleware,
    exportToCsvMiddleware,
    searchCloudUsersMiddleware,
    searchCloudMeetingsMiddleware,
    meetingsMiddleware,
    mergeAccountMiddleware,
    exportMiddleware,
    modalMiddleware,
    pairingMiddleware,
    resetPasswordMiddleware,
    userProfileMiddleware,
    uploadRecoringMonitorMiddleware,
    remotingMiddlewares.pairing,
    analyticsMiddleware,
    thunk,
    filteredLogger
  ];
}
