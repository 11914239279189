import { createSelector } from 'reselect';
import { Global } from 'state/Global';

/**
 * Selector to the search state
 */
const maxInactiveDaysState = (state: Global) => state.app?.maxInactiveDays;

const maxInactiveDaysSelector = createSelector(
  maxInactiveDaysState,
  (state) => {
    return state ? state.maxInactiveDays : 0;
  }
);

const isBusyFetchingMeetingsToDelete = createSelector(
  maxInactiveDaysState,
  (state) => {
    return state ? state.isBusyFetchingMeetingsToDelete : false;
  }
);

export {
  maxInactiveDaysSelector,
  isBusyFetchingMeetingsToDelete
};
