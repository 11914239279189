import { AddGroupContactModal } from 'components/Modals/AddGroupContactModal';
import { EditGroupContactModal } from 'components/Modals/EditGroupContactModal';
import { PermissionConfirmationModal } from 'components/Modals/PermissionConfirmationModal/PermissionConfirmationModal';
import AlexaDisconnectModalContainer from 'containers/Modals/alexaDisconnectModalContainer';
import ConvertToPaid from 'containers/Modals/convertToPaidModalContainer';
import ConnectTablet from 'containers/Modals/connectTabletModalContainer';
import DeleteFromCloudModal from 'containers/Modals/deleteFromCloudModalContainer';
import DeleteMeeting from 'containers/Modals/deleteMeetingContainer';
import DeleteRecording from 'containers/Modals/deleteRecordingModalContainer';
import DeleteUser from 'containers/Modals/deleteUserContainer';
import DeviceCustomizationModal from 'containers/Modals/deviceCustomizationModalContainer';
import EditRecordingModal from 'containers/Modals/editRecordingModalContainer';
import ShareRecordingModal from 'containers/Modals/shareRecordingModalContainer';
import MeetingShareRecordingModal from 'containers/Modals/meetingShareRecordingModalContainer';
import MeetingsExpirationModal from 'containers/Modals/maxInactiveDaysExpirationModalContainer';
import { AddMeetingModal, EditMeetingModal } from 'containers/Modals/meetingsModalContainer';
import { ProfileModalContainer } from 'containers/Modals/ProfileModalContainer';
import RebootDeviceModalContainer from 'containers/Modals/RebootDeviceModalContainer';
import ResetDeviceModalContainer from 'containers/Modals/ResetDeviceModalContainer';
import tabletConfirmDeleteModalContainer from 'containers/Modals/tabletConfirmDeleteModalContainer';
import tabletInfoModalContainer from 'containers/Modals/tabletInfoModalContainer';
import UnPairFromCloudModal from 'containers/Modals/unPairRoomSystemsModalContainer';
import DeleteGroupContactModal from 'containers/Modals/deleteGroupContactModalContainer';
import AlexaRegisterModalContainer from 'containers/Modals/alexaRegisterModalContainer';
import AlexaUnregisterModalContainer from 'containers/Modals/alexaUnregisterModalContainer';
import InviteUserModal from 'containers/Modals/inviteUserModal';
import { AddUserModal, EditUserModal, } from 'components/Modals/UserModal/UserModal';
import UploadRecording from 'containers/Modals/uploadRecordingModalContainer';

export const ModalContainers = {
  ADD_GROUP_CONTACT: AddGroupContactModal,
  ALEXA_DISCONNECT: AlexaDisconnectModalContainer,
  ALEXA_REGISTER: AlexaRegisterModalContainer,
  ALEXA_UNREGISTER: AlexaUnregisterModalContainer,
  DELETE_GROUP_CONTACT: DeleteGroupContactModal,
  ADD_MEETING: AddMeetingModal,
  CONNECT_TABLET: ConnectTablet,
  CONVERT_TO_PAID: ConvertToPaid,
  DELETE_FROM_CLOUD: DeleteFromCloudModal,
  DEVICE_CUSTOMIZATION: DeviceCustomizationModal,
  EDIT_GROUP_CONTACT: EditGroupContactModal,
  EDIT_MEETING: EditMeetingModal,
  EDIT_RECORDING_MODAL: EditRecordingModal,
  SHARE_RECORDING_MODAL: ShareRecordingModal,
  MEETING_SHARE_RECORDING_MODAL: MeetingShareRecordingModal,
  MEETINGS_CONFIRM_DELETE: DeleteMeeting,
  MEETINGS_CONFIRM_EXPIRATION_DELETE: MeetingsExpirationModal,
  PERMISSION_CONFIRMATION_MODAL: PermissionConfirmationModal,
  REBOOT_DEVICE: RebootDeviceModalContainer,
  RECORDINGS_CONFIRM_DELETE: DeleteRecording,
  REMOVE_FROM_CLOUD: UnPairFromCloudModal,
  RESET_DEVICE: ResetDeviceModalContainer,
  TABLET_DELETE: tabletConfirmDeleteModalContainer,
  TABLET_INFO: tabletInfoModalContainer,
  USERS_CONFIRM_DELETE: DeleteUser,
  USER_CALL_DETAILS: ProfileModalContainer,
  USER_PROFILE: ProfileModalContainer,
  USER_SETTINGS: ProfileModalContainer,
  USER_ADD: AddUserModal,
  USER_EDIT: EditUserModal,
  USER_INVITE: InviteUserModal,
  UPLOAD_RECORDING: UploadRecording,
};
