import _get from 'lodash/get';
import ListFetcher from 'utils/listFetcher';
import { DocumentNode } from 'graphql';
import { FetchPolicy } from 'apollo-client';
import { Sort } from 'interfaces/Meeting';
import { SortDirection } from 'constants/queryConstants';
import { useEffect, useState } from 'react';
import { useMount } from 'hooks/useMount';
import { useQuery } from '@apollo/react-hooks';

interface InitialState {
  sort?: Sort;
  filterString?: string;
  searchQuery?: string;
}

const defaultSort = {
  propertyName: 'displayName',
  direction: SortDirection.ASC
};

// We use a singleton to compensate for InfiniteTable's interval, which ignores react render sequencing
const fetcher = new ListFetcher();
let requestId: number = 0;

export const useListHooks = (query: DocumentNode, initialState: InitialState = {}, variables = {}, fetchPolicy: FetchPolicy = 'network-only') => {
  const [searchQuery, setSearchQuery] = useState<string | null>(initialState.searchQuery || null);
  const [filterString, setFilterString] = useState<string | null>(initialState.filterString || null);
  const [outerLoading, setOuterLoading] = useState(true);
  const [sort, setSort] = useState(initialState.sort || defaultSort);
  const isMounted = useMount();

  const handleComplete = () => {
    if (requestId === 0) {
      setOuterLoading(false);
    }
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(query, {
    variables: { ...variables, query: filterString === '' ? null : filterString, sort },
    onCompleted: handleComplete,
    fetchPolicy
  } as {
    variables: {
      query: string | null,
      sort?: Sort,
    },
    onCompleted: () => void,
    fetchPolicy?: FetchPolicy,
  });

  if (data) {
    fetcher.set('cursor', _get(data, 'directoryList.cursor'));
    fetcher.set('data', data);
    fetcher.set('fetchMore', fetchMore);
    fetcher.set('sort', sort);
    fetcher.set('queryVariables', variables);
  }

  useEffect(
    () => {
      setOuterLoading(loading);
    },
    [loading]
  );

  useEffect(
    () => {
      requestId = 0;
      return fetcher.clear();
    },
    []
  );

  useEffect(() => {
    fetcher.set('isMounted', isMounted.current);
  },        [isMounted]);

  useEffect(
    () => {
      fetcher.set('sort', sort);
    },
    [sort]
  );

  useEffect(
    () => {
      const newSearchQuery = filterString === '' ? null : filterString;
      setSearchQuery(newSearchQuery);
      fetcher.set('filterString', newSearchQuery);
      const refetchAsync = async () => {
        const newRequestId = Math.floor(Math.random() * 100000000);
        requestId = newRequestId;
        await refetch({
          ...variables,
          query: newSearchQuery,
          sort,
        });
        if (newRequestId === requestId) {
          setOuterLoading(false);
        }
      };
      if (isMounted.current) {
        setOuterLoading(true);
        refetchAsync();
      }
    },
    [filterString]
  );

  return {
    searchQuery,
    setSearchQuery,
    filterString,
    setFilterString,
    sort,
    setSort,
    loading: outerLoading,
    error,
    data,
    refetch,
    fetchMoreRows: async () => fetcher.fetchMoreRows(query)
  };
};

export default useListHooks;
