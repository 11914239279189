import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import classes from './AlexaSettings.scss';
import { Button, Loader } from 'semantic-ui-react';
import { GetCredentialsResponse } from 'interfaces/AlexaIntegration';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { localizationSelectors } from '@lifesize/nucleus';
import { AuthorizationState, alexaHelpPage } from 'constants/alexaIntegrationConstants';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

const ROLE_ARN = 'roleArn';
const EXTERNAL_ID = 'externalId';
const AUTHENTICATE_MARKER = 'authenticate';
const EXAMPLE_ARN = 'arn:aws:iam::123456789012';
const EXAMPLE_EXTERNAL_ID = 'AVSExternalId123XYZ';

interface Props {
  authenticated: boolean;
  roleArn: string;
  externalId: string;
  invalid: boolean;
  authState: AuthorizationState;
  authenticate: (roleArn: string, externalId: string) => Promise<void>;
  disconnect: (externalId: string) => void;
  getCredentials: () => Promise<GetCredentialsResponse>;
}

export const AuthMessage = (props: { authError: boolean, credentialError: boolean, authSuccess: boolean }) => {
  const { authError, authSuccess, credentialError } = props;
  if (authError) { return <div className={[classes.authError, classes.authMessage].join(' ')}>{intl.get('authenticationFailure')}</div>; }
  if (credentialError) { return <div className={[classes.authError, classes.authMessage].join(' ')}>{intl.get('alexaCredentialFailure')}</div>; }
  if (authSuccess) { return <div className={[classes.authSuccess, classes.authMessage].join(' ')}>{intl.get('authenticated')}</div>; }
  return <div className={classes.authMessage}/>;
};

const getInstructions = () => {
  // react-intl-universal does not support styled react elements as variables. So, we have to run a replace of marked spots for the translated terms.
  const splitInstructions = intl.get('alexaInstructions').split(/{|}/g);
  return splitInstructions.map((instruction: string) => {
    if (instruction === ROLE_ARN) {
      return <b key={instruction}>Role ARN</b>;
    }

    if (instruction === EXTERNAL_ID) {
      return <b key={instruction}>External ID</b>;
    }

    if (instruction === AUTHENTICATE_MARKER) {
      return <i key={instruction}>{intl.get(instruction)}</i>;
    }

    return <span key={instruction}>{instruction}</span>;
  });
};

const AlexaSettings = (props: Props) => {
  const { roleArn, externalId, invalid, authenticated, authenticate, disconnect, getCredentials, authState } = props;
  const [roleArnValue, setRoleArnValue] = useState(roleArn);
  const [loading, setLoading] = useState(true);
  const [externalIdValue, setExternalIdValue] = useState(externalId);
  const [authSuccessValue, setAuthSuccessValue] = useState(false);
  const [authErrorValue, setAuthErrorValue] = useState(false);
  const [credentialErrorValue, setCredentialErrorValue] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);
  const [roleArnPlaceholder, setRoleArnPlaceholder] = useState(intl.get('eg', { example: EXAMPLE_ARN }));
  const [externalIdPlaceholder, setExternalIdPlaceholder] = useState(intl.get('eg', { example: EXAMPLE_EXTERNAL_ID }));
  const localeCode = useSelector(localizationSelectors.localeCode) as unknown as string;
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];

  useEffect(
    () => {
      if (authState === AuthorizationState.SUCCESS) {
        setAuthSuccessValue(true);
      } else if (authState === AuthorizationState.FAILURE) {
        setRoleArnValue(roleArn);
        setExternalIdValue(externalId);
        setAuthErrorValue(true);
      }
      if (!authenticated) {
        setAuthSuccessValue(false);
      }
    },
    [authState, authenticated]
  );

  useEffect(
    () => {
      const loadCredentials = async () => {
        await getCredentials();
        setLoading(false);
      };

      loadCredentials();
    },
    []
  );

  useEffect(
    () => {
      // If we get new credentials from the server, overwrite local values
      setRoleArnValue(roleArn);
      setExternalIdValue(externalId.trim());
    },
    [roleArn, externalId]
  );

  useEffect(
    () => {
      setCredentialErrorValue(invalid);
    },
    [invalid]
  );

  const validateChanges = () => {
    if (!roleArnValue || !externalIdValue) { return false; }
    return (roleArnValue !== roleArn || externalIdValue !== externalId);
  };

  return (
    <div className={classNames([classes.container, loading ? classes.loadingContainer : ''])}>
      { loading ? <LoadingIndicator /> :
        <>
          <h2 className={classes.settingsTitle}>{intl.get('alexaForBusiness')}</h2>
          <p className={classes.instructions}>
            {getInstructions()}
            {' '}
            <a target="_blank" href={alexaHelpPage(localeCode)}>{intl.get('learnMore')}</a>
          </p>
          <div className={classes.alexaSettings}>
            <div className={classes.alexaInputs}>
              <div className={classes.alexaRow}>
                <span>Role ARN</span>
                <input
                  id={ROLE_ARN}
                  className={classes.alexaFormInput}
                  value={roleArnValue}
                  name={roleArn}
                  placeholder={roleArnPlaceholder}
                  disabled={!canEditSettings}
                  onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                    if (!canEditSettings) { return; }
                    setRoleArnValue(event.currentTarget.value);
                  }}
                  onFocus={() => {
                    setAuthErrorValue(false);
                    setAuthSuccessValue(false);
                    setRoleArnPlaceholder(''); }
                  }
                  onBlur={() => { setRoleArnPlaceholder(intl.get('eg', { example: EXAMPLE_ARN })); }}
                  type="text"
                />
              </div>
              <div className={classes.alexaRow}>
                <span>External ID</span>
                <input
                  id={EXTERNAL_ID}
                  name={externalId}
                  className={classes.alexaFormInput}
                  value={externalIdValue}
                  placeholder={externalIdPlaceholder}
                  disabled={!canEditSettings}
                  onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                    if (!canEditSettings) { return; }
                    setExternalIdValue(event.currentTarget.value);
                  }}
                  onFocus={() => {
                    setAuthErrorValue(false);
                    setAuthSuccessValue(false);
                    setExternalIdPlaceholder('');
                  }}
                  onBlur={() => { setExternalIdPlaceholder(intl.get('eg', { example: EXAMPLE_EXTERNAL_ID } )); }}
                  type="text"
                />
              </div>
              <div className={classes.disconnectRow}>
                <div>
                  <span>{intl.get('authenticationStatus')}</span>
                  <b className={classes.authState}>{authenticated ? intl.get('connected') : intl.get('notConnected')}</b>
                </div>
                {authenticated && <Button
                  className={classes.disconnect}
                  name="disconnectButton"
                  disabled={!canEditSettings}
                  onClick={() => {
                    if (!canEditSettings) { return; }
                    disconnect(externalId);
                  }}
                  role="button"
                  type="button"
                >
                  {intl.get('disconnect')}
                </Button> }
              </div>
            </div>

            <div className={classes.alexaAuthentication}>
              <AuthMessage authSuccess={authSuccessValue} authError={authErrorValue} credentialError={credentialErrorValue}/>
              <div className={classes.buttonContainer}>
                <Button
                  name="authenticateButton"
                  disabled={!validateChanges() || authenticating || !canEditSettings}
                  onClick={async () => {
                    if (!canEditSettings) { return; }
                    setAuthenticating(true);
                    try {
                      await authenticate(roleArnValue, externalIdValue);
                      setAuthenticating(false);
                    } catch (e) {
                      setAuthenticating(false);
                    }
                  }}
                  role="button"
                  type="button"
                >
                  {authenticating ? <Loader size={'tiny'} active={true} inline={true}/> : intl.get('authenticate')}
                </Button>
              </div>
            </div>
          </div>
        </>
      }
    </div>
  );
};

export default AlexaSettings;
