import classNames from 'classnames';
import { FormikErrors, FormikValues } from 'formik';
import * as React from 'react';

// Input feedback
const InputFeedback = (error: FormikErrors<FormikValues>) => {
  return error.error ? <div className={classNames('input-feedback')}>{error.error.toString()}</div> : null;
};

export interface Props {
  value: FormikValues;
  touched: boolean;
  error?: string;
  id: string;
  className?: string;
}

const RadioButtonGroup: React.FunctionComponent<Props> = (props) => {

  const {value, error, touched, className, children} = props;

  const classes = classNames(
    'input-field',
    {
      'is-success': value || (!error && touched), // handle prefilled or user-filled
      'is-error': !!error && touched
    },
    className || null
  );

  return (
    <div className={classes}>
      <fieldset>
        {children}
        {touched && <InputFeedback error={error}/>}
      </fieldset>
    </div>
  );
};

export default RadioButtonGroup;
