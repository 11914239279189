import React from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { dismissImpersonationCode, generateImpersonationCode } from 'actions/impersonationActions';
import { selectImpersonationState } from 'selectors/impersonationSelectors';
import classnames from 'classnames';
import * as styles from 'components/Settings/Sections.scss';
import ImpersonationCodeModal from './ImpersonationCodeModal';
import { selectAccountPermissions } from 'selectors/permissionsSelector';
import { ROLE_PERMISSION } from 'interfaces/Role';

export default function AccountSupport() {
  const dispatch = useDispatch();
  const canEditSettings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_OWN_ACCOUNT_GENERAL_SETTINGS];
  const impersonation = useSelector(selectImpersonationState);
  return (
    <section className={styles.container}>
      <article>
        <h6 className={classnames(styles.title, styles.headerRow)} data-test="sectionTitle">
          {intl.get('accountSupport')}
        </h6>
        <div className={styles.description}>
          {intl.get('supportCodeDescription')}
        </div>
        <div className={styles.formSection}>
          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                if (!canEditSettings) { return; }
                dispatch(generateImpersonationCode());
              }}
              role="button"
              type="button"
              disabled={!canEditSettings}
            >
              {intl.get('supportCodeGenerateText')}
            </Button>
          </div>
        </div>
      </article>
      { ( impersonation && (impersonation.code || impersonation.error)) && 
        <ImpersonationCodeModal
          code={impersonation.code}
          error={impersonation.error}
          onClose={() => dispatch(dismissImpersonationCode())}
        />
      }
    </section>
  );
}
