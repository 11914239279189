import { useEffect, useRef } from 'react';
import Timer = NodeJS.Timer;

export interface UseTimeoutResponse {
  clear: () => void;
}

export function useTimeout(callback: () => void, delay: number = 0, dependencies: Array<string | number | object | boolean | Function> = []): Function {
  const currentTimeout = useRef<Timer | null>(null);

  const clear = () => {
    if (currentTimeout.current) {
      clearTimeout(currentTimeout.current);
      currentTimeout.current = null;
    }
  };

  let timeoutFunction = () => {
    currentTimeout.current = setTimeout(callback, delay);
    return {
      clear
    };
  };

  useEffect(
    () => {
      return clear;
    },
    [currentTimeout]
  );

  return timeoutFunction;
}

export default {
  useTimeout
};
