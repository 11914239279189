import { nucleusConstants, accountSelectors, featureSelectors } from '@lifesize/nucleus';
import { PermissionTypes } from 'constants/permissionsConstants';
import {
  DUPLICATE_MEETING_NAME_ERROR,
  OTHER_MEETING_NAME_ERROR,
  GRAPHQL_DISPLAYNAME_EXISTS_ERROR,
  GRAPHQL_MEETING_LIMIT_ERROR,
  GRAPHQL_REQUIRED_PASSCODE_ERROR,
  MEETING_LIMIT_ERROR,
  MEETING_NAME_FONT_SIZE_CHAR_BREAK
} from 'constants/meetingConstants';
import RolesTab from 'components/Modals/MeetingsModal/Tabs/RolesTab';
import {
  Form,
  Formik,
  FormikActions,
  FormikComputedProps,
  FormikErrors,
  FormikHandlers,
  FormikState,
  FormikTouched,
  FormikValues
} from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import React, { useEffect, useState } from 'react';
import { Mutation } from 'react-apollo';
import intl from 'react-intl-universal';
import { Logger } from 'logger';
import { ListMeetingsQuery, Maybe, Meeting, UpdateMeetingMutation } from 'typings/types';
import { useMount } from 'hooks/useMount';
import { StreamProps } from 'interfaces/Meeting';
import { isOneTimeMeeting } from 'utils/meetingsUtils';
import classnames from 'classnames';
import { getMeetingAttributes } from 'utils/recordingsUtils';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import styles from 'components/Modal/ModalComponent/ModalComponent.scss';
import Can from 'components/Can/CanComponent';
import { ModalFooter } from 'components/Modal/ModalComponent/ModalFooter';
import * as modalFormStyles from '../ModalForms.scss';
import createMeetingMutation from 'queries/meetings/createMeetingMutation.gql';
import updateMeetingMutation from 'queries/meetings/updateMeetingMutation.gql';
import listMeetingsQuery from 'queries/meetings/listMeetingsQuery.gql';
import { FormValues, FormErrorValues, MEETING_FORM_FIELD } from './FormFields';
import * as meetingStyle from './MeetingsModal.scss';
import { meetingsModalSchema } from './MeetingsModalValidationSchema';
import GeneralTab from './Tabs/GeneralTab';
import RecordingTab from './Tabs/RecordingTab';
import StreamingTab from './Tabs/StreamingTab';
import MeetingInlineFragment = ListMeetingsQuery.MeetingInlineFragment;
import { useSelector } from 'react-redux';
import {
  defaultHideOTMeetingsSetting,
  defaultHidePermanentMeetingsSetting,
  waitingRoom
} from 'selectors/accountSelectors';
import RecordInlineFragment = UpdateMeetingMutation.RecordInlineFragment;
import { FEATURE_FLAG_MULTIPLE_MODERATORS } from 'constants/featureFlags';
import Moderator = ListMeetingsQuery.Moderator;
import getFeatureFlag = featureSelectors.getFeatureFlag;
import { selectAccountPermissions } from '../../../selectors/permissionsSelector';
import { ROLE_PERMISSION } from '../../../interfaces/Role';

interface CurrentUser {
  name: string;
  userUuid: string;
}

interface MeetingInput {
  description: string;
  displayName: string;
  waitingRoom?: boolean;
  hiddenMeeting: boolean;
  lecturerUUID?: string;
  meetingType?: string;
  moderatorUUID?: string;
  moderatorUUIDs?: Array<string>;
  ownerUUID: string;
  pin: string;
  uuid?: string;
}

export interface Props extends FormikState<object>,
  FormikComputedProps<object>,
  FormikActions<object>,
  FormikHandlers {
    canCreateOneTimeMeeting: boolean;
    closeModal: () => void;
    currentUser: CurrentUser;
    data: MeetingInlineFragment;
    errorMessage?: string;
    errors: FormikErrors<FormErrorValues>;
    fetchData: Function;
    hasRecordingFeature: boolean;
    isAdd: boolean;
    isSubmitting: boolean;
    setMeetingShareProperties: (id: string, post: object) => void;
    setStreamingSettings: Function;
    touched: FormikTouched<FormValues>;
    values: FormikValues;
  }

const getRole = (user: Maybe<{ UUID: string, displayName: string }>) => {
  if (user) {
    return {
      id: user.UUID,
      title: user.displayName
    };
  }
  return {
    id: null,
    title: ''
  };
};

const MeetingsModal = (props: Props) => {
  const {
    canCreateOneTimeMeeting,
    closeModal,
    currentUser,
    fetchData,
    data,
    errorMessage,
    hasRecordingFeature,
    isAdd,
    setStreamingSettings,
    setMeetingShareProperties
  } = props;

  const { uuid } = data;

  const defaultWaitingRoomSetting = useSelector(waitingRoom);
  const accountSettings = useSelector(accountSelectors.getAccountSettings);
  const multipleModeratorsFeature = useSelector(getFeatureFlag)(FEATURE_FLAG_MULTIPLE_MODERATORS);
  const defaultHideOTMeetings = useSelector(defaultHideOTMeetingsSetting);
  const defaultHidePermanentMeetings = useSelector(defaultHidePermanentMeetingsSetting);
  const meetingsRequirePasscode = accountSettings?.requireMeetingPasscode;
  const [closeLabel, setCloseLabel] = useState(intl.get('modalCancel'));
  const [modalLoading, setModalLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const canEditMeetings = useSelector(selectAccountPermissions)[ROLE_PERMISSION.MODIFY_MEETINGS];

  const isMounted = useMount();
  useEffect(
    () => {
      const getData = async () => {
        await fetchData();
        if (isMounted.current) {
          setModalLoading(false);
        }
      };

      getData();
    },
    [fetchData, uuid]
  );

  const handleTabClick = (tab: React.MouseEvent<HTMLAnchorElement>) => {
    if (selectedTab !== tab.currentTarget.tabIndex) {
      setSelectedTab(tab.currentTarget.tabIndex);
    }
  };

  const defaultCreateMeetingType = _get(data, 'type') || nucleusConstants.MEETING_TYPE_VMR;
  const defaultHiddenMeeting = defaultCreateMeetingType === nucleusConstants.MEETING_TYPE_VMR ? defaultHidePermanentMeetings : defaultHideOTMeetings;

  const defaultOwner = {
    UUID: currentUser.userUuid,
    displayName: currentUser.name,
  };

  if (modalLoading) {
    return (
      <Modal
        className={modalFormStyles.containerSmall}
        closeModal={closeModal}
        closeText={intl.get('modalCancel')}
        hideFooter={true}
        titleText={isAdd ? intl.get('createMeeting') : intl.get('editMeeting')}
      >
        <LoadingIndicator />
      </Modal>
    );
  }

  const resetStreamingFields = (setFieldValue: Function) => {
    setFieldValue(MEETING_FORM_FIELD.STREAMING_STATE, '{}');
    setFieldValue(MEETING_FORM_FIELD.STREAMING_PERMISSIONS, undefined);
    setFieldValue(MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE, undefined);
  };

  const displayName = _get(data, 'displayName') || '';
  let moderator: Moderator | Moderator[];
  if (multipleModeratorsFeature) {
    moderator = data?.moderators || [defaultOwner];
  } else {
    moderator = data?.moderator || defaultOwner;
  }

  return (
    <Mutation
      mutation={isAdd ? createMeetingMutation : updateMeetingMutation}
      refetchQueries={isAdd ? ['listMeetingsQuery'] : undefined}
      update={(cache: { writeQuery: Function, readQuery: Function }, { data: { updateMeeting, createMeeting } }: { data: { updateMeeting?: Meeting, createMeeting?: Meeting }}) => {
        let meetingResponse: Meeting | null = null;
        if (updateMeeting?.UUID) {
          meetingResponse = updateMeeting;
        } else if (createMeeting?.UUID) {
          meetingResponse = createMeeting;
        }
        if (!meetingResponse) {
          return;
        }
        const response: {  directoryList: { records: Array<Meeting | undefined> } } = cache.readQuery({
          query: listMeetingsQuery
        });
        const directoryList = response.directoryList;
        directoryList.records = directoryList.records.map(
          (r: Meeting) => {
            if (r.UUID !== meetingResponse?.UUID) {
              return r;
            } else {
              return updateMeeting;
            }
          }
        );

        cache.writeQuery({
          query: listMeetingsQuery,
          data: { directoryList: directoryList }
        });
      }}
    >
      {(mutateMeeting: Function, { loading }: { loading: boolean }) => (
        <Modal
          className={modalFormStyles.containerSmall}
          closeModal={closeModal}
          closeText={intl.get('modalCancel')}
          hideFooter={true}
          titleText={isAdd ? intl.get('createMeeting') : intl.get('editMeeting')}
        >
          <>
            <div className={displayName.length > MEETING_NAME_FONT_SIZE_CHAR_BREAK ? styles.subTitleSmall : styles.subTitle}>{displayName}</div>
            {!isAdd &&
            <div className={styles.contextContainer}>
              <div className={styles.contextLabel}>{intl.get('meetingType') + `: `}</div>
              <div className={styles.contextText}>{isOneTimeMeeting(data.type)
                ? intl.get('oneTimeMeeting')
                : intl.get('permanentMeeting')}
              </div>
            </div>
            }

            {/* "Tab-flavored Tabs" */}
            <div
              className={meetingStyle.buttonTabsContainer + ' ui pointing secondary menu'}
              data-test="tabMenu.meetingModal"
            >
              <a
                className={selectedTab === 0 ? 'active item' : 'item'}
                data-test="tabItem.general"
                onClick={(e) => handleTabClick(e)}
                tabIndex={0}
              >
                {intl.get('general')}
              </a>
              <a
                className={selectedTab === 1 ? 'active item' : 'item'}
                data-test="tabItem.roles"
                onClick={(e) => handleTabClick(e)}
                tabIndex={1}
              >
                {intl.get('roles')}
              </a>
              {!isAdd &&
              <Can
                userAccess={[PermissionTypes.ANY_RECORDINGS]}
              >
                <a
                  className={selectedTab === 2 ? 'active item' : 'item'}
                  data-test="tabItem.recording"
                  onClick={(e) => handleTabClick(e)}
                  tabIndex={2}
                >
                  {intl.get('recording')}
                </a>
              </Can>
              }
              {!isAdd && // TODO: not shown for new meetings at this time
              <Can userAccess={[PermissionTypes.VIEW_STREAMING]}>
                <a
                  className={selectedTab === 3 ? 'active item' : 'item'}
                  data-test="tabItem.streaming"
                  onClick={(e) => handleTabClick(e)}
                  tabIndex={3}
                >
                  {intl.get('streaming')}
                </a>
              </Can>
              }
            </div>

            <Formik
              initialValues={{
                [MEETING_FORM_FIELD.DESCRIPTION]: _get(data, 'description') || '',
                [MEETING_FORM_FIELD.WAITING_ROOM]: isAdd ? defaultWaitingRoomSetting : !!data?.waitingRoom,
                [MEETING_FORM_FIELD.HIDDEN]: typeof data?.hiddenMeeting === 'boolean' ? data?.hiddenMeeting : defaultHiddenMeeting,
                [MEETING_FORM_FIELD.LECTURER]: getRole(data?.lecturer as RecordInlineFragment),
                [MEETING_FORM_FIELD.MEETING_TYPE]: _get(data, 'type', defaultCreateMeetingType),
                [MEETING_FORM_FIELD.MEETING_ID]: _get(data, 'uuid', ''),
                moderator: multipleModeratorsFeature ? moderator : getRole(moderator as RecordInlineFragment),
                [MEETING_FORM_FIELD.NAME]: _get(data, 'displayName') || '',
                [MEETING_FORM_FIELD.OWNER]: getRole(data?.owner as RecordInlineFragment || defaultOwner),
                [MEETING_FORM_FIELD.STREAMING_STATE]: '{}',
                [MEETING_FORM_FIELD.RECORDING_STATE]: '{}',
                // Must use undefined for optional numeric fields
                [MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE]: undefined,
                [MEETING_FORM_FIELD.PIN]: _isNil(_get(data, 'pin')) || _get(data, 'pin') === '' ? undefined : data.pin,
                selectedTab: selectedTab
              }}
              validationSchema={meetingsModalSchema(meetingsRequirePasscode)}
              onSubmit={async (values: FormikValues, methods: { setSubmitting: Function, setFieldError: Function, setFieldValue: Function }) => {
                if (!canEditMeetings) { return; }
                const { setSubmitting, setFieldError, setFieldValue } = methods;
                const meeting: MeetingInput = {
                  description: values.description,
                  displayName: values.name,
                  waitingRoom: values.waitingRoom,
                  hiddenMeeting: values.hiddenMeeting,
                  ownerUUID: _get(values, 'owner.id') || _get(values, 'owner.UUID'),
                  pin: values.pin ? values.pin : '',
                };
                if (isAdd) {
                  meeting.meetingType = values.meetingType;
                } else {
                  meeting.uuid = data.UUID;
                }
                if (values.moderator) {
                  if (multipleModeratorsFeature) {
                    meeting.moderatorUUIDs = values?.moderator?.map((moderatorEntry: { UUID?: string, uuid?: string }) => moderatorEntry?.UUID || moderatorEntry?.uuid || '');
                  } else {
                    meeting.moderatorUUID = _get(values, 'moderator.id') || _get(values, 'moderator.UUID') || null;
                  }
                }
                if (values.lecturer) {
                  meeting.lecturerUUID = _get(values, 'lecturer.id') || _get(values, 'lecturer.UUID') || null;
                }

                const hasRecordingUpdate = values[MEETING_FORM_FIELD.RECORDING_STATE] !== '{}';
                const recordingData = JSON.parse(values[MEETING_FORM_FIELD.RECORDING_STATE]);
                const meetingProperties = getMeetingAttributes(_get(recordingData, 'selectedShare.id', ''));
                const meetingRecordingShareProps = {
                  ...{
                    selectedShare: recordingData.selectedShare,
                    viewers: recordingData.recordingViewers
                  },
                  ...meetingProperties
                };

                try {
                  const response = await mutateMeeting({
                    variables: {
                      meeting
                    }
                  });
                  // update streaming settings
                  const hasStreamingUpdate = values[MEETING_FORM_FIELD.STREAMING_STATE] !== '{}';
                  const streamingSettings = JSON.parse(values[MEETING_FORM_FIELD.STREAMING_STATE]);
                  if (!isAdd && data.UUID && hasStreamingUpdate) {
                    const apiRequestValues = {
                      extension: _get(data, 'extension', '')
                    };

                    await setStreamingSettings(data.UUID, { ...streamingSettings, ...apiRequestValues });
                  }
                  if (!isAdd && hasRecordingFeature && hasRecordingUpdate) {
                    await setMeetingShareProperties(_get(response, 'data.updateMeeting.UUID'), meetingRecordingShareProps);
                  }
                  if (shouldRefresh) {
                    setShouldRefresh(false);
                    setCloseLabel(intl.get('modalClose'));
                    setSelectedTab(3);
                    resetStreamingFields(setFieldValue); // necessary so that form is not dirty
                  } else {
                    closeModal();
                  }
                  setSubmitting(false);
                } catch (e) {
                  // handle error
                  const message = _get(e, 'message', '');
                  switch (message.toUpperCase().trim()) {
                    case GRAPHQL_DISPLAYNAME_EXISTS_ERROR:
                      setFieldError('name', DUPLICATE_MEETING_NAME_ERROR);
                      break;
                    case GRAPHQL_MEETING_LIMIT_ERROR:
                      setFieldError('general', MEETING_LIMIT_ERROR);
                      break;
                    case GRAPHQL_REQUIRED_PASSCODE_ERROR:
                      setFieldError('pin', intl.get('passcodeRequiredError'));
                      break;
                    default:
                      setFieldError('name', OTHER_MEETING_NAME_ERROR);
                      // Log unexpected error
                      Logger.info(`Failure to save meeting: ${message}`);
                  }
                  // tslint:disable-next-line
                  console.error('Failure to save meeting: ', e);
                  setSubmitting(false);
                }
              }}
              render={({ dirty, errors, handleChange, isSubmitting, touched, values, setFieldValue, setFieldTouched }:
                         { dirty: boolean,
                           errors: FormikErrors<FormErrorValues>,
                           handleChange: Function,
                           isSubmitting: boolean,
                           touched: FormikTouched<FormValues>,
                           values: FormikValues,
                           setFieldValue: Function,
                           setFieldTouched: Function
                         }) => (
                <div className={modalFormStyles.formControl}>
                  <Form className={isAdd ? meetingStyle.isAdd : undefined}>
                    <div className={selectedTab === 0 ? meetingStyle.show : meetingStyle.hide}>
                      <GeneralTab
                        canCreateOneTimeMeeting={canCreateOneTimeMeeting}
                        errors={errors}
                        isAdd={isAdd}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                        visible={selectedTab === 0}
                      />
                    </div>

                    <div className={selectedTab === 1 ? meetingStyle.show : meetingStyle.hide}>
                      <RolesTab
                        errors={errors}
                        handleChange={handleChange}
                        isEditingExistingMeeting={!isAdd}
                        touched={touched}
                        values={values}
                      />
                    </div>
                    {!isAdd && // TODO: not shown for new meetings at this time
                    <Can
                      userAccess={[PermissionTypes.ANY_RECORDINGS]}
                    >
                      <div className={selectedTab === 2 ? meetingStyle.show : meetingStyle.hide}>
                        <RecordingTab
                          setFieldValue={(settings: object) => {
                            const recordingState = JSON.stringify(settings);
                            setFieldValue(MEETING_FORM_FIELD.RECORDING_STATE, recordingState);
                          }}
                          resetState={() => {
                            setFieldValue(MEETING_FORM_FIELD.RECORDING_STATE, '{}');
                          }}
                          uuid={uuid}
                        />
                      </div>
                    </Can>
                    }
                    {!isAdd && // TODO: not shown for new meetings at this time
                    <Can userAccess={[PermissionTypes.VIEW_STREAMING]}>
                      <div className={selectedTab === 3 ? meetingStyle.show : meetingStyle.hide}>
                        <StreamingTab
                          isSubmitting={isSubmitting}
                          passcodeError={touched.streamingPermissionsPasscode && errors.streamingPermissionsPasscode}
                          setFieldValue={(settings: StreamProps, keepOpen: boolean) => {
                            const passcode = settings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE];
                            const permission = settings[MEETING_FORM_FIELD.STREAMING_PERMISSIONS];

                            if (permission) {
                              setFieldValue(MEETING_FORM_FIELD.STREAMING_PERMISSIONS, permission);
                            }

                            if (passcode) {
                              setFieldValue(MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE, passcode);
                              setFieldTouched(MEETING_FORM_FIELD.STREAMING_PERMISSIONS_PASSCODE);
                            }

                            const streamingState = JSON.stringify(settings);
                            setFieldValue(MEETING_FORM_FIELD.STREAMING_STATE, streamingState, false);
                            setShouldRefresh(keepOpen);
                          }}
                          resetState={() => {
                            resetStreamingFields(setFieldValue);
                          }}
                          uuid={uuid}
                        />
                      </div>
                    </Can>
                    }

                    {errors.general && errors.general === MEETING_LIMIT_ERROR && (
                      <div id="general" className={classnames(modalFormStyles.formGroup, modalFormStyles.hasValidation)}>
                        <ul className={modalFormStyles.inputMessages}>
                          <li className={modalFormStyles.error}>{intl.get('tooltipMaxPermanentMeetings')}</li>
                        </ul>
                      </div>
                    )}
                    <ModalFooter
                      useSaveAsFormSubmit={true}
                      closeModal={closeModal}
                      closeText={closeLabel}
                      isSaveButtonBusy={isSubmitting && !errorMessage}
                      isSaveButtonDisabled={(!_isEmpty(errors)) || !dirty || loading}
                    />
                  </Form>
                </div>
              )}
            />

            {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}

          </>
        </Modal>
      )}
    </Mutation>
  );
};

export { MeetingsModal };
