import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as styles from './SecurityAcceptModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { localizationSelectors } from '@lifesize/nucleus';
import { setDpaStatus } from 'actions/logDpaActions';
import { DPA_VERSION } from 'constants/logDpaConstants';
import { DpaResponse } from 'interfaces/logDPA';
import { amendmentDownload } from 'utils/securityAndPrivacyUtils';

type Props = {
  onClose: () => void;
};

export default function SecurityAcceptModal(props: Props) {
  const { onClose } = props;
  const dispatch = useDispatch();
  const localeCode = useSelector(localizationSelectors.localeCode) as unknown as string;
  const [loading, setLoading] = useState(false);
  const [error, setDpaError] = useState(false);

  return (
    <Modal
      closeModal={onClose}
      hideCloseButton={false}
      isSaveButtonBusy={loading}
      isSaveButtonDisabled={loading}
      errorMessage={error ? intl.get('setSecurityAcceptanceError') : undefined}
      onSave={async () => {
        try {
          setLoading(true);
          const dpaResponse = await dispatch(setDpaStatus({ version: DPA_VERSION, status: 'accepted' })) as unknown as DpaResponse;
          if (dpaResponse?.payload?.statusCode < 300 && dpaResponse?.type === setDpaStatus.fulfilled.type) {
            onClose();
          } else {
            setLoading(false);
            setDpaError(true);
          }
        } catch (e) {
          setLoading(false);
          setDpaError(true);
        }
      }}
      saveText={intl.get('personalAccept')}
      closeText={intl.get('cancel')}
      titleText={intl.get('reviewAmendment')}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalSubheader}>
          {intl.get('parentheticalVersion', { version: DPA_VERSION })}
        </div>
        <a href={amendmentDownload(localeCode)} download={`${intl.get('dataProcessingAddendum', { version: DPA_VERSION })}.pdf`}>{intl.get('downloadAmendment')}</a>
        <div className={styles.agreementContainer}>
          <iframe
            src={amendmentDownload(localeCode)}
            style={{width: '100%', height: '100%'}}
            onLoad={() => {
              dispatch(setDpaStatus({ version: DPA_VERSION, status: 'reviewed' }));
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
