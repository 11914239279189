export interface ResetPasswordState {
  isResettingPassword: boolean;
  isPasswordReset: boolean;
  resetPasswordFailure: boolean;
}

/**
 * Properties to determine password reset process state
 */
export const initialResetPasswordState: ResetPasswordState = {
  isResettingPassword: false,
  isPasswordReset: false,
  resetPasswordFailure: false
};
