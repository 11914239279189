import { createAsyncThunk } from '@reduxjs/toolkit';
import { proxyActions } from '@lifesize/nucleus';
import { Methods } from 'interfaces/HTTP';

export const deleteAccount = createAsyncThunk(
  'accountManagement-deleteAccount',
  async (input: undefined, thunkAPI) => {
    const res = await thunkAPI.dispatch(proxyActions.proxyApiCall(
      'lifesize.account',
      'account',
      Methods.DELETE
    ));
    return JSON.parse(res);
  }
);
