import { connect } from 'react-redux';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import pastMeetingParticipantDetailsQuery from 'queries/pastMeetings/PastMeetingParticipantDetailsQuery.gql';
import { flattenCallStats } from 'utils/callStatUtils';
import _get from 'lodash/get';
import { PastMeetingParticipantPage } from 'components/PastMeetingDetails/PastMeetingParticipantPage/PastMeetingParticipantPage';
import { selectLocale } from 'selectors/languageSelectors';
import { Global } from 'state/Global';

interface ComponentProps {
  match: {
    params: {
      id: string,
      callId: string,
    };
  };
}

const mapStateToProps = (state: Global) => ({
  locale: selectLocale(state),
});

const mapDispatchToProps = () => ({});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(pastMeetingParticipantDetailsQuery, {
    options: (props: ComponentProps) => {
      return {
        fetchPolicy: 'network-only',
        variables : { conferenceId: props.match.params.id, callId: props.match.params.callId }
      };
    },
    // tslint:disable-next-line
    props: ({ data, ownProps }: any) => {
      const extension = _get(data, 'pastConferenceParticipantDetails.extension', '');
      const startedAt = _get(data, 'pastConferenceParticipantDetails.startedAt');
      const endedAt = _get(data, 'pastConferenceParticipantDetails.endedAt', null);
      const duration = _get(data, 'pastConferenceParticipantDetails.duration', 0);
      const meetingName = _get(data, 'pastConferenceParticipantDetails.meetingName', '');
      const displayName = _get(data, 'pastConferenceParticipantDetails.displayName', '');
      const pastMeetingParticipant = _get(data, 'pastConferenceParticipantDetails');
      const userExists = !!pastMeetingParticipant;
      const conferenceId = _get(ownProps, 'match.params.id', '');
      const sourceIP = _get(data, 'pastConferenceParticipantDetails.sourceIP', '');
      const mediaStatData = _get(data, 'pastConferenceParticipantDetails.mediaStats');
      const mediaStats = mediaStatData ? flattenCallStats(mediaStatData, ownProps.locale) : [];
      const graphs = _get(data, 'pastConferenceParticipantDetails.graphs');
      return {
        ...ownProps,
        extension,
        startedAt,
        conferenceId,
        userExists,
        mediaStats,
        endedAt,
        duration,
        meetingName,
        displayName,
        sourceIP,
        data,
        graphs,
        pastMeetingParticipant
      };
    },
  }),
)(PastMeetingParticipantPage);
