export const PLAN_TYPE_HOST_PLUS = 'HOST_PLUS';
export const PLAN_TYPE_FREEMIUM = 'FREEMIUM';

export const ECOMMERCE_SUBSCRIPTION_CANCEL_CLEAR_ERROR = 'ECOMMERCE_SUBSCRIPTION_CANCEL_CLEAR_ERROR';
export const ECOMMERCE_SUBSCRIPTION_REACTIVATE_CLEAR_ERROR = 'ECOMMERCE_SUBSCRIPTION_REACTIVATE_CLEAR_ERROR';

export const ECOMMERCE_ACCOUNT_ROUTE = '/account';
export const ECOMMERCE_SUBSCRIPTION_ME_ROUTE = '/subscriptions/me';
export const ECOMMERCE_SUBSCRIPTION_CANCEL_ROUTE = '/subscriptions/me/cancel';
export const ECOMMERCE_SUBSCRIPTION_REACTIVATE_ROUTE = '/subscriptions/me/reactivate';
