import React from 'react';
import intl from 'react-intl-universal';
import Modal from 'components/Modal/ModalComponent/ModalComponent';
import * as modalFormStyles from 'components/Modals/ModalForms.scss';

type Props = {
  handleResponse: (yesDelete: boolean) => void;
};

export default function MergeAccountsCancelConfirmationModal(props: Props) {
  const { handleResponse } = props;
  return (
    <Modal
      className={modalFormStyles.containerSmall}
      closeModal={() => handleResponse(false)}
      closeText={intl.get('no')}
      onSave={() => handleResponse(true)}
      saveText={intl.get('yes')}
      titleText={intl.get('mergeAccounts')}
    >
      <div>
        {intl.get('mergeAccountsCancelConfirmationMessage')}
      </div>
    </Modal>
  );
}
