export const ACCOUNT_FEATURE_ALLOW_LIVE_MEETINGS = 'allowLiveMeetings';
export const ACCOUNT_FEATURE_PAST_MEETINGS_ENABLED = 'isPastMeetingsEnabled';
export const ACCOUNT_FEATURE_MAX_MEETINGS = 'maxMeetings';
export const ACCOUNT_FEATURE_PURCHASED_USERS = 'purchasedUsers';
export const ACCOUNT_FEATURE_ALLOW_DEVICE_PAIRING = 'allowDevicePairing';
export const ACCOUNT_FEATURE_CHAT_ENABLED = 'isChatEnabled';
export const ACCOUNT_FEATURE_OTHERWAYS_ENABLED = 'isOtherWaysToCallEnabled';
export const ACCOUNT_FEATURE_DEVICE_SETTINGS_ENABLED = 'isDeviceSettingsEnabled';
export const ACCOUNT_FEATURE_SSO = 'sso';
export const ACCOUNT_FEATURE_STREAMING = 'streamingEnabled';
export const ACCOUNT_FEATURE_RECORDING_ENABLED = 'recordingEnabled';
export const ACCOUNT_FEATURE_ALLOW_SIP_DIALIN = 'allowSipDialIn';
export const ACCOUNT_FEATURE_PSTN_NUMBERS = 'pstnNumbers';
export const ACCOUNT_FEATURE_ONE_TIME_MEETING = 'oneTimeMeeting';
export const ACCOUNT_FEATURE_MAX_PARTICIPANTS = 'maxParticipants';
export const ACCOUNT_FEATURE_PERMANENT_MEETING = 'permanentMeeting';
export const ACCOUNT_FEATURE_LYNC_ENABLED = 'lyncEnabled';
export const ACCOUNT_FEATURE_STREAMING_VIEWERS = 'strmViewers';
export const ACCOUNT_FEATURE_ENABLE_GEO_DISTRIBUTION = 'enableGeoDistribution';
export const ACCOUNT_FEATURE_WHITEBOARDING = 'whiteboarding';
export const ACCOUNT_FEATURE_HIDE_DASHBOARD = 'adminHideDashboard';

export enum PSTN_NUMBERS_OPTIONS {
  NONE = 'NONE',
  ALL = 'ALL',
  US_ONLY = 'US_ONLY'
}
