import * as React from 'react';
import classes from './TooltipFixedPosition.scss';
import { SHIFT, LOCATION } from 'constants/tooltipFixedPositionConstants';
import { useEffect, useRef, useState } from 'react';

interface OptionalProps {
  fixPosition?: boolean;
  location?: LOCATION;
  overlapCarat?: boolean;
  shift?: SHIFT | null;
  style?: object | null;
  offset?: object;
}

interface Props extends OptionalProps {
  label: string | object;
}

const TooltipFixedPosition = (props: Props) => {
  const {
    fixPosition = true,
    overlapCarat = false,
    offset = {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    },
    location = LOCATION.BOTTOM,
    style = null,
    shift = null,
    label
  } = props;
  const tooltip = useRef<HTMLDivElement | null>(null);
  const [ styleState, setStyleState ] = useState({});

  const onEndTransition = () => {
    if (!tooltip.current || !tooltip.current.parentNode) {
      return;
    }
    const parentNode = tooltip.current.parentNode;

    if (!(parentNode instanceof Element)) {
      return;
    }

    // each endTransition indicates that the tooltip is shown (there is no event on hide)
    const parent = parentNode.getBoundingClientRect();
    const tip = {
      height: tooltip.current.offsetHeight,
      width: tooltip.current.offsetWidth
    };
    const tipPosition = {
      bottom: 'auto',
      left: 'auto',
      position: fixPosition ? 'fixed' : 'absolute',
      right: 'auto',
      top: 'auto',
      transform: 'none'
    };
    const px = 'px';
    const caratSize = 6;
    const caratOffset = overlapCarat ? 0 : caratSize;
    const offsetToUse = {...{top: 0, left: 0, bottom: 0, right: 0}, ...offset };

    switch (location) {
      case LOCATION.TOP:
        if (fixPosition) {
          tipPosition.left = ((parent.left + offsetToUse.left) + ((parent.width - tip.width) / 2)) + px;
          tipPosition.top = ((parent.top + offsetToUse.top) - tip.height - caratOffset) + px;
          if (shift === SHIFT.LEFT) {
            tipPosition.left = (parent.left + (parent.width - tip.width)) + px;
          }
        } else {
          // absolute position
          tipPosition.top = ((tip.height + caratOffset) * -1) + offsetToUse.top + px;
          tipPosition.left = ((parent.width - tip.width) / 2) + offsetToUse.left + px;
          if (shift === SHIFT.LEFT) {
            tipPosition.left = 'auto';
            tipPosition.right = offsetToUse.right + px;
          }
          if (shift === SHIFT.RIGHT) {
            tipPosition.left = offsetToUse.left + px;
          }
        }
        break;
      case LOCATION.RIGHT:
        if (fixPosition) {
          tipPosition.left = ((parent.right + offsetToUse.right) + caratOffset) + px;
          tipPosition.top = ((parent.top + offsetToUse.top) + ((parent.height - tip.height) / 2)) + px;
        } else {
          // absolute position
          tipPosition.top = ((parent.height - tip.height) / 2) + offsetToUse.top + px;
          tipPosition.left = parent.width + offsetToUse.right + caratOffset + px;
        }
        break;
      case LOCATION.LEFT:
        if (fixPosition) {
          tipPosition.left = ((parent.left + offsetToUse.left) - tip.width - caratOffset) + px;
          tipPosition.top = ((parent.top + offsetToUse.top) + ((parent.height - tip.height) / 2)) + px;
        } else {
          // absolute position
          tipPosition.top = ((parent.height - tip.height) / 2) + offsetToUse.top + px;
          tipPosition.left = ((tip.width + offsetToUse.left + caratOffset) * -1) + px;
        }
        break;
      default: // bottom
        if (fixPosition) {
          tipPosition.left = ((parent.left + offsetToUse.left) + ((parent.width - tip.width) / 2)) + px;
          tipPosition.top = ((parent.bottom + offsetToUse.bottom) + caratOffset) + px;
          if (shift === SHIFT.LEFT) {
            tipPosition.left = ((parent.left + offsetToUse.left) + (parent.width - tip.width)) + px;
          }
          if (shift === SHIFT.RIGHT) {
            tipPosition.left = (parent.left + offsetToUse.left) + px;
          }
        } else {
          // absolute position
          tipPosition.top = parent.height + offsetToUse.bottom + caratOffset + px;
          tipPosition.left = ((parent.width - tip.width) / 2) + offsetToUse.left + px;
          if (shift === SHIFT.LEFT) {
            tipPosition.left = 'auto';
            tipPosition.right = offsetToUse.right + px;
          }
          if (shift === SHIFT.RIGHT) {
            tipPosition.left = offsetToUse.left + px;
          }
        }
    }

    setStyleState(tipPosition);
  };

  useEffect(
    () => {
      if (tooltip.current) {
        tooltip.current.addEventListener('transitionend', onEndTransition);
      }
      return () => {
        if (tooltip.current) {
          tooltip.current.removeEventListener('transitionend', onEndTransition);
        }
      };
    },
    [tooltip]
  );

  const cssClasses = [classes.tooltip];

  switch (location) {
    case LOCATION.TOP:
      cssClasses.push(classes.top);
      break;
    case LOCATION.RIGHT:
      cssClasses.push(classes.right);
      break;
    case LOCATION.LEFT:
      cssClasses.push(classes.left);
      break;
    default:
      cssClasses.push(classes.bottom);
  }

  if (shift === SHIFT.LEFT) {
    cssClasses.push(classes['shift-left']);
  }

  if (shift === SHIFT.RIGHT) {
    cssClasses.push(classes['shift-right']);
  }

  if (overlapCarat) {
    cssClasses.push(classes.snug);
  }

  return (
    <div
      className={cssClasses.join(' ')}
      ref={tooltip}
      style={{  ...styleState, ...style }}
    >
      {label}
    </div>
  );
};

export default TooltipFixedPosition;
