import { CsvActionType } from 'actions/exportToCsvActions';
import { Reducer } from 'redux';
import { ExportToCsvState, initialExportToCsvState } from 'state/ExportToCsv';

/**
 * Simple reducer to maintain the waiting state of export functionality
 */
const exportToCsvReducer: Reducer<ExportToCsvState> =
  (state = initialExportToCsvState, action) => {
    switch (action.type) {
      case CsvActionType.FETCH_CSV_DATA_START:
        return {
          ...state,
          isBusy: true
        };
      case CsvActionType.FETCH_CSV_DATA_FINISH:
      case CsvActionType.FETCH_CSV_DATA_RESET:
        return {
          ...state,
          isBusy: false
        };
      default:
        return state;
    }
  };

export default exportToCsvReducer;
