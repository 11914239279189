import { MergeRequest } from 'interfaces/MergeRequest';

export interface MergeAccountState {
  loading: boolean;
  request: MergeRequest | null;
  error: boolean;
}

export const initialMergeAccountState: MergeAccountState = {
  loading: true,
  request: null,
  error: false
};
