import React, { Component } from 'react';
import intl from 'react-intl-universal';
import { VideoShareProperties, MeetingShareProperties } from '../../../interfaces/VideoShareProperties';
import Modal from '../../Modal/ModalComponent/ModalComponent';
import { FormValues } from './FormFields';
import ShareRecordingForm from './ShareRecordingForm';

interface Props {
  recordingId: string;
  isBusy: boolean;
  isPublicUrlBusy: boolean;
  isGroupUrlBusy: boolean;
  canShareRecordingPublicly: boolean;
  vcSettingsAllowPublic: boolean;
  closeModal: () => void;
  videoProperties: VideoShareProperties | null;
  setVideoProperties: (recordingId: string, videoProps: VideoShareProperties) => void;
  setMeetingShareProperties: (recordingId: string, meetingShareProps: MeetingShareProperties) => void;
  getShareProperties: (recordingId: string) => void;
  getShareLinksForCloudGroup: (recordingId: string) => void;
  getShareLinksGlobal: (recordingId: string) => void;
  meetingId: string;
  canUserModifyRecording: boolean;
}

export class ShareRecordingModal extends Component<Props> {

  handleSubmit = (values: FormValues, meetingShareProps: MeetingShareProperties) => {
    if (this.props.canUserModifyRecording) {
      this.props.setMeetingShareProperties(this.props.meetingId, meetingShareProps);
    }
    if (this.props.recordingId && this.props.videoProperties) {
      this.props.setVideoProperties(this.props.recordingId, values);
    }
  }

  handleGetShareLinksForCloudGroup = () => {
    this.props.getShareLinksForCloudGroup(this.props.recordingId);
  }

  handleGetShareLinksGlobal = () => {
    this.props.getShareLinksGlobal(this.props.recordingId);
  }

  render() {

    const {
      closeModal,
      isBusy,
      isPublicUrlBusy,
      isGroupUrlBusy,
      videoProperties,
      vcSettingsAllowPublic
    } = this.props;

    return (
      <Modal
        hideFooter={true}
        titleText={intl.get('shareRecordingTitle')}
        closeModal={closeModal}
      >
        <ShareRecordingForm
          closeModal={closeModal}
          isBusy={isBusy}
          isPublicUrlBusy={isPublicUrlBusy}
          isGroupUrlBusy={isGroupUrlBusy}
          data={videoProperties}
          onSubmit={this.handleSubmit}
          refreshPublic={this.handleGetShareLinksGlobal}
          refreshGroup={this.handleGetShareLinksForCloudGroup}
          vcSettingsAllowPublic={vcSettingsAllowPublic}
        />
      </Modal>
    );
  }

}

export default ShareRecordingModal;
