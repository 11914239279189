import classnames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import { Loader } from 'semantic-ui-react';
import Icon from 'components/Common/Icon';
import { Tooltip } from 'components/tooltip/Tooltip';
import * as styles from './ExportButton.scss';

interface Props {
  ['data-test']?: string;
  disabled: boolean;
  isExporting?: boolean;
  onExportClick: () => void;
  tooltipProps?: Object;
}

/**
 *
 * @param onExportClick - Call back handler on click - not called if component disabled
 * @param disabled - Enabled by default, but can be set to disabled
 * @param toolTipText - Defaults to 'Export to .csv', but can be overridden
 * @constructor
 */
const ExportButton: React.FunctionComponent<Props> = (props) => {
  const { onExportClick, disabled, isExporting, tooltipProps } = props;
  const testProp = props['data-test'] ? { ['data-test']: props['data-test'] } : {};
  const renderButton = () => {
    return (
      <div
        className={classnames(styles.container, disabled && styles.disabled, isExporting && styles.isBusy)}
        id="export"
        onClick={disabled || isExporting ? () => null : onExportClick}
        title={intl.get('export')}
        {...testProp}
      >

        {isExporting && <Loader size={'mini'} active={true} inline={true}/>}

        {!isExporting &&
          <Icon classes={['icon-export', styles.icon]} />
        }

        <span>{intl.get('exportAll')}</span>
      </div>
    );
  };

  return (
    <Tooltip text={intl.get('exportToCsv')} {...tooltipProps}>
      {renderButton()}
    </Tooltip>
  );
};

export default ExportButton;
